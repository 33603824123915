import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
//import "./user-unit-details.css";
import axios from "axios";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RButton from "../../../components/Custom/button/button";
import Notification from "../../../components/Custom/Notification";
import CircularProgress from "@mui/material/CircularProgress";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import mandateImage from "../../../assets/Images/Rectangle 228.svg";
export default function UserUnitsDetails(props) {
  const [done, setDone] = useState(false);
  const [mandateTraining, setMandateTraining] = useState([]);
  const [isLoadTraining, setIsLoadTraining] = useState(false);
  const [isMandateCont, setIsMandateCont] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const id = localStorage.getItem("mandateId");
  let navigate = useNavigate();
  useEffect(() => {
    getAllMandatoryDetails(id);
  }, []);

  const getAllMandatoryDetails = async (Id) => {
    setDone(true);
    const param = `/` + Id;
    await API.getUserTrainingDetails(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setDone(false);
        setMandateTraining(response.data.data);
      } else {
        setMandateTraining([]);
        setDone(false);
      }
      setIsLoadTraining(false);
      setDone(false);
    });
  };

  // const getUserTrackMandatoryTraining = async (Id) => {
  //   const param = `/` + Id;
  //   await API.getUserTrackMandatoryTraining(param).then(({ response }) => {
  //     if (response.data.api_status === 200) {
  //       setMandateTraining(response.data.data);
  //     } else {
  //       setMandateTraining([]);
  //     }
  //     setIsLoadTraining(false);
  //   });
  // };

  const handleContinue = async () => {
    setIsMandateCont(true);
    const param = `/` + id;
    await API.getUserTrackMandatoryTraining(param)
      .then(({ response }) => {
        if (response.data.api_status === 200) {
          setIsMandateCont(false);
          localStorage.setItem(
            "userUnitTrackId",
            response.data.data[0].user_unit_track_id
          );
          navigate(`/user/mandatoryTraning/${id}`);
          // if (response.data.data[0].user_unit_track_id) {

          // }
        } else {
          setIsMandateCont(false);
        }
      })
      .catch(({ error }) => {
        setIsMandateCont(false);
      });
  };
  return (
    <>
      {!done ? (
        <>
          {isLoadTraining ? (
            <>
              <h1 className="training-session-trainee">Mandatory Training</h1>
              <Button
                buttonName="Back"
                className="traineebutton"
                startIcon={<ArrowBackIosIcon />}
                style={{ textTransform: "none", color: "#02215b" }}
                onClick={() => navigate("/user")}
              />
            </>
          ) : (
            <>
              <h1 className="training-session-trainee">Mandatory Training</h1>
              <Button
                buttonName="Back"
                className="traineebutton"
                startIcon={<ArrowBackIosIcon />}
                style={{ textTransform: "none", color: "#02215b" }}
                onClick={() => navigate("/user")}
              />
              {mandateTraining ? (
                <Box
                  sx={{
                    width: "96%",
                    marginLeft: 3,
                    marginRight: 3,
                    marginBottom: 10,
                  }}
                >
                  <Box>
                    <Paper
                      className="paper-box"
                      style={{
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        className="secondd-box"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: "20px",
                        }}
                      >
                        <Box
                          className="top-box-second-box"
                          style={{ paddingBottom: "10px" }}
                        >
                          <span className="container1">
                            {mandateTraining?.topic}
                          </span>
                        </Box>
                        <Box>
                          <div style={{ paddingBottom: "20px" }}>
                            <span className="duration">Duration :</span>
                            <span>&nbsp; {mandateTraining?.duration}</span>
                          </div>
                        </Box>

                        <Box
                          className="inner-div"
                          style={{ marginTop: "0px", textAlign: "center" }}
                        >
                          <img src={mandateImage} alt={mandateImage}></img>
                          {mandateTraining?.continueButton ? (
                            <span
                              className="course-text"
                              style={{ textAlign: "center", width: "100%" }}
                            >
                              {mandateTraining?.description}
                            </span>
                          ) : (
                            <>
                              {mandateTraining?.trainingProgressStatus === 1 ? (
                                <span
                                  className="course-text"
                                  style={{ textAlign: "center", width: "100%" }}
                                >
                                  {/* {mandateTraining?.description}
                                   */}
                                  The Mandatory Training is locked for the Year
                                  please try later.
                                </span>
                              ) : (
                                <span
                                  className="course-text"
                                  style={{ textAlign: "center", width: "100%" }}
                                >
                                  {/* {mandateTraining?.description}
                                   */}
                                 The Mandatory Training is locked for the Day
                                  please try later.
                                </span>
                              )}
                            </>
                          )}
                        </Box>

                        <div
                          className="section3-feedback"
                          style={{ marginRight: "0px" }}
                        >
                          <ReusableButton
                            onClick={() => navigate("/user")}
                            disabled={isMandateCont ? true : false}
                            size="large"
                            variant="outlined"
                            buttonName="Cancel"
                            style={CancelBtn}
                          >
                            Cancel
                          </ReusableButton>
                          {mandateTraining?.continueButton ? (
                            <ReusableButton
                              endIcon={
                                !isMandateCont ? (
                                  ""
                                ) : (
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                )
                              }
                              buttonName={"Continue"}
                              onClick={() => {
                                handleContinue();
                              }}
                              className={"section3-feedback-sub"}
                              disabled={isMandateCont ? true : false}
                              style={{
                                textTransform: "capitalize",
                                padding: "6px 14px",
                              }}
                            />
                          ) : null}
                        </div>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
export const CancelBtn = {
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#000936",
  border: "1px solid #000936",
  padding: "7px 20px",
  borderRadius: "4px",
  marginRight: "10px",
  marginBottom: "-1px",
  textTransform: "none",
};
