import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import CreateUnit from "./createUnit";
import TrainingMaterialNew from "./training-material-new";
import PreRequisite from "./pre-requisite";
import Assignment from "./assignment";
import Quiz from "./Quiz";
import QuestionsUpload from "./quizQuestionsUpload";
import ReactLoading from "react-loading";
import { API } from "../../../../Networking/API";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SxStyles } from "../../../../styles/styles";
import MandatoryIntroduction from "../../mandatory-training/mandatoryIntroduction";

const UnitLayout = (props) => {
  const styles = SxStyles.styles;
  const [tab, setTab] = useState(1);
  const [unitData, setUnitData] = useState({});
  const [dissabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isInitialRendrer, setIsInitialRendere] = useState(false);
  const [unitDescription, setUnitDescription] = useState("");
  const [quizDisabled, setQuizDisabled] = useState(true);
  const [isUnit, setIsUnit] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const UnitId = window.localStorage.getItem("UnitId");
    setIsInitialRendere(true);
    if (UnitId) {
      API.getUnitById(UnitId)
        .then(({ response }) => {
          
          if (response.data.api_status === 200) {
            setUnitData(response.data.data[0].topic);
            //setDisabled(true);
            setLoader(false);
            setQuizDisabled(response.data.data[0].topic?.quiz_mandatory);
            //setQuizDisabled(true);
            setIsUnit(true);
          } else {
            setLoader(true);
          }
        })
        .catch(({ error }) => {
          setLoader(true);
        });
    }
    const MandateId = window.localStorage.getItem("mandateId");
    if (MandateId) {
      API.getMandatoryTrainingById(MandateId)
        .then(({ response }) => {
          
          if (response.data.api_status === 200) {
            setUnitData(response.data.data[0].mandatorytraining);
            setLoader(false);
            setQuizDisabled(
              response.data.data[0].mandatorytraining?.quiz_mandatory
            );
            setIsUnit(false);
          } else {
            setLoader(true);
          }
        })
        .catch(({ error }) => {
          setLoader(true);
        });
    }
  }, [tab]);
  const navigatePage = async () => {
    const ScreenName = await localStorage.getItem("Screen");
    if (ScreenName) {
      localStorage.removeItem("Screen");
      navigate(ScreenName);
    } else navigate("/home/courses");
  };
  return (
    <>
      {loader ? (
        <Box
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box
          style={{
            padding: "20px",
          }}
        >
          <Box styles={styles.headerContainer}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <div>
                    <ReusableButton
                      onClick={() => navigatePage()}
                      size="small"
                      variant="contained"
                      buttonName="Back"
                      startIcon={<ArrowBackIosIcon />}
                      style={styles.backBtn}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {unitData.topic ? (
                  <span style={SxStyles.createCourseStyle.mainCreateCourseSpan}>
                    Edit {isUnit ? "Unit" : "Mandatory Training"}
                  </span>
                ) : (
                  <span style={SxStyles.createCourseStyle.mainCreateCourseSpan}>
                    Create {pageTitle ? pageTitle : "Unit / Mandatory Training"}
                  </span>
                )}
              </Grid>
            </Grid>
          </Box>

          <Card
            style={{
              backgroundColor: "#F0F0F0",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            {unitData.topic ? (
              <Typography
                variant="h3"
                style={{
                  fontSize: "26px",
                  fontWeight: 600,
                  padding: "20px",
                  marginLeft: "15px",
                }}
              >
                {unitData.topic}
              </Typography>
            ) : (
              <Typography
                variant="h3"
                style={{ fontSize: "26px", fontWeight: 600, padding: "20px" }}
              >
                Create {pageTitle ? pageTitle : "Unit / Mandatory Training"}
              </Typography>
            )}

            <Box
              style={{
                backgroundColor: "#F0F0F0",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "0px 20px 0px 30px",
              }}
              className="button-wrapper"
            >
              <Button
                onClick={() => {
                  setTab(1);
                }}
                className={tab === 1 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography">Unit Details</span>
              </Button>

              <Button
                disabled={dissabled}
                onClick={() => {
                  setTab(3);
                }}
                className={tab === 3 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography">Pre-Requisite </span>
              </Button>

              <Button
                disabled={dissabled}
                onClick={() => {
                  setTab(2);
                }}
                className={tab === 2 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography">Training Material</span>
              </Button>

              <Button
                disabled={dissabled}
                onClick={() => {
                  setTab(4);
                }}
                className={tab === 4 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography">Assignment </span>
              </Button>

              <Button
                // disabled={dissabled || !quizDisabled}
                disabled={dissabled}
                onClick={() => {
                  setTab(6);
                }}
                className={tab === 6 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography">Questions Bank </span>
              </Button>

              <Button
                disabled={dissabled}
                onClick={() => {
                  setTab(5);
                }}
                className={tab === 5 ? "clickedTab" : "notClickedTab"}
              >
                <span className="commontypography"> Quiz Configurations </span>
              </Button>
            </Box>
          </Card>
          {tab === 1 && (
            <CreateUnit
              unitData={unitData}
              dissabled={dissabled}
              setDisabled={setDisabled}
              setIsUnit={setIsUnit}
              isUnit={isUnit}
              setPageTitle={setPageTitle}
            />
          )}
          {/* {tab === 2 && <TrainingMaterialNew />} */}
          {tab === 2 && <MandatoryIntroduction isUnit={isUnit} />}
          {tab === 3 && <PreRequisite isUnit={isUnit} unitData={unitData} />}
          {tab === 4 && <Assignment isUnit={isUnit} />}
          {tab === 5 && (
            <Quiz
              dissabled={dissabled}
              setDisabled={setDisabled}
              setQuizDisabled={setQuizDisabled}
              mandateQuiz={quizDisabled}
              isUnit={isUnit}
              unitData={unitData}
            />
          )}
          {tab === 6 && <QuestionsUpload />}
        </Box>
      )}
    </>
  );
};

export default UnitLayout;
