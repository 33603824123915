import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function Dropdown3(props) {
  const {
    name,
    label,
    value,
    size,
    error = null,
    onChange,
    style,
    placeholder,
    options,
    menustyle,
    defaultValue,
    displayEmpty,
  } = props;
  return (
    <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
      <InputLabel>{label} </InputLabel>
      <Select
        label={label}
        name={name}
        defaultValue={defaultValue}
        displayEmpty={displayEmpty}
        value={value}
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
      >
        {options.map((item) => {
          return (
            <MenuItem key={item.role_pk} value={item.role_pk}>
              {item.role_name}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
