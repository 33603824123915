import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import "./user-unit-details.css";
import axios from "axios";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UserUnitsTabs from "./user-units-tab";
import RButton from "../../../components/Custom/button/button";
import Notification from "../../../components/Custom/Notification";
import CircularProgress from "@mui/material/CircularProgress";

export default function UserUnitsDetails(props) {
  const quizValuesafterresults = {
    totalquest: "",
    correctquest: "",
    percentagescored: "",
  };

  const quizValues = {
    count: "",
    noOFques: "",
  };

  const something = 1;
  const jwtToken = Cookies.get("jwt_token");
  const [Unitdata, setUnitdata] = useState();
  const [done, setDone] = useState(false);

  const [tabDone, setTabDone] = useState(true);
  const [assignmentData, setAssignmentData] = useState();
  const [trainerdata, setTrainerdata] = useState("");
  const [quizArray, setQuizArray] = useState("");

  const [prerequiste, setPrerequiste] = useState("");
  const [scheduleType, setScheduleType] = useState();

  const [quizdata, setQuizdata] = useState("");
  const [quizResultStatus, setQuizResultStatus] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState();
  const [totalTasks, setTotalTasks] = useState(0);
  const [userHid, setUserHid] = useState("");
  const [startQuizButton, setStartQuizButton] = useState("");
  const [topicName, setTopicName] = useState("");
  const [quizDetails, setQuizDetails] = useState(quizValues);
  const [quizDetailsAfterResults, setQuizDetailsAfterResults] = useState(
    quizValuesafterresults
  );
  const [isUnitStarted, setIsUnitStarted] = useState(false);
  const [trainermaterial, SetTrainermaterial] = useState("");
  const [isUnitLoaded, setIsUnitLoaded] = useState(false);
  const [viewAfterButtonClick, setViewAfterButtonClick] = useState(false);

  const [resultLoaded, setResultLoaded] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [uploadStatus, setUploadStatus] = useState(1);
  const location = useLocation();
  let unitId;
  let courseId;
  let path;
  
  if (window.localStorage.getItem("UnitId")) {
    unitId = window.localStorage.getItem("UnitId");
    courseId = null;
    path = "/user/";
  } else {
    unitId = location.state.Unitid;
    courseId = location.state.courseId;
    path = "/user/courses";
  }
  
  let navigate = useNavigate();

  useEffect(() => {
    getUnitDetails();
  }, []);

  const getUnitDetails = () => {
    API.getUserUnitDetails(unitId, courseId).then(({ response }) => {
      setDone(true);
      
      if (response.api_status === 200) {
        
        setIsUnitLoaded(false);
        setIsUnitStarted(response.data.isUnitStared);
        const data = response.data.unitDetails;
        
        const unitData = {
          topic: data.topic,
          duration: data.duration,
          description: data.description,
        };
        

        setUnitdata(unitData);
        setAssignmentData(data.topicAssignments);
        const quizToWrite = data.topicQuizz;
        setQuizArray(quizToWrite);
        
        const quizResult = quizToWrite[0]?.userQuizz;
        setQuizResultStatus(quizResult);

        // if userQuizz value present 1 else 0

        const quizResultValues = quizToWrite[0].userQuizz;

        if (quizToWrite.length > 0) {
          const formattedQuizToWrite = quizToWrite.map((eachdata) => ({
            id: eachdata.quizz_pk,
            noOfQuest: eachdata.no_of_questions,
            duration: eachdata.countdown,
            quizNumber: eachdata.quizz_pk,
            questcount: eachdata.no_of_questions,
            topic: eachdata.topic,
            buttonstart: eachdata.button,
          }));
          setStartQuizButton(formattedQuizToWrite[0].buttonstart);
          setQuizdata(formattedQuizToWrite);
          setTopicName(formattedQuizToWrite.topic);
          setQuizDetails({
            ...quizDetails,
            count: quizToWrite[0].countdown,
            noOFques: quizToWrite[0].no_of_questions,
          });
        }
        setQuizDetailsAfterResults({
          ...quizDetailsAfterResults,
          totalquest: quizResultValues ? quizResultValues.total_questions : 0,
          correctquest: quizResultValues
            ? quizResultValues.correct_answers
            : "",
          percentagescored: quizResultValues
            ? Math.ceil(quizResultValues.percentage)
            : "",
          quizQuestions: quizResultValues ? quizResultValues.questions : [],
        });
        // API.TrainingMaterialTrack(unitId, courseId).then(({ response }) => {
        //   if (response.api_status === 200) {
        //     
        //   }
        // });
      }
    });
  };

  const handleStartUnit = () => {
    setIsUnitLoaded(true);
    
    API.handleUnitStart(unitId, courseId).then(({ response }) => {
      
      if (response.api_status === 200) {
        getUnitDetails();
      } else {
        setIsUnitLoaded(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    });
  };

  return (
    <>
      {done ? (
        <>
          {isUnitStarted ? (
            <>
              <h1 className="training-session-trainee">{Unitdata.topic}</h1>
              <Button
                buttonName="Back"
                className="traineebutton"
                startIcon={<ArrowBackIosIcon />}
                style={{ textTransform: "none", color: "#02215b" }}
                onClick={() => navigate(path)}
              />
              <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                <Box>
                  <Paper className="paper-box" style={{ borderRadius: "12px" }}>
                    <Box className="unit-card-background"></Box>
                    <Box className="second-box">
                      <Box
                        className="top-box-second-box"
                        style={{ paddingBottom: "20px" }}
                      >
                        <span className="container1">{Unitdata.topic}</span>
                        <div>
                          <span className="duration">Duration :</span>
                          <span>&nbsp; {Unitdata.duration}</span>
                        </div>
                      </Box>
                      {Unitdata.description !== "" &&
                      Unitdata.description !== null ? (
                        <>
                          <Box className="inner-div">
                            <div>
                              <p
                                style={{ marginTop: "3px" }}
                                className="course-heading"
                              >
                                <b>About Course</b>
                              </p>
                            </div>
                            <span className="course-text">
                              {Unitdata.description}
                            </span>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Paper>
                </Box>

                <Box>
                  <UserUnitsTabs
                    unitId={unitId}
                    courseId={courseId}
                    feedbackStatus={feedbackStatus}
                    // scheduledata={scheduledata}
                    assignmentData={assignmentData}
                    // tasksData={tasksData}
                    trainerdata={trainerdata}
                    quizArray={quizArray}
                    prerequiste={prerequiste}
                    quizdata={quizdata}
                    quizResultStatus={quizResultStatus}
                    startQuizButton={startQuizButton}
                    topicName={topicName}
                    quizDetails={quizDetails}
                    done={tabDone}
                    quizDetailsAfterResults={quizDetailsAfterResults}
                    trainermaterial={trainermaterial}
                    resultLoaded={resultLoaded}
                    setResultLoaded={setResultLoaded}
                    uploadStatus={uploadStatus}
                    setAssignmentData={setAssignmentData}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <h1 className="training-session-trainee">Unit</h1>
              <Button
                buttonName="Back"
                className="traineebutton"
                startIcon={<ArrowBackIosIcon />}
                style={{ textTransform: "none", color: "#02215b" }}
                onClick={() => navigate(path)}
              />
              <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                <Box>
                  <Paper
                    className="paper-box"
                    style={{
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      className="secondd-box"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "20px",
                      }}
                    >
                      <Box
                        className="top-box-second-box"
                        style={{ paddingBottom: "20px" }}
                      >
                        <span className="container1">{Unitdata?.topic}</span>
                      </Box>
                      <Box className="unit-card-background-description"></Box>
                      {Unitdata?.description !== "" &&
                      Unitdata?.description !== null ? (
                        <>
                          <Box
                            className="inner-div"
                            style={{ marginTop: "20px" }}
                          >
                            <span className="course-text">
                              {Unitdata?.description}
                            </span>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        className="section3-feedback"
                        style={{ marginRight: "0px" }}
                      >
                        <RButton
                          endIcon={
                            !isUnitLoaded ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={!isUnitLoaded ? "Start" : "Starting.."}
                          onClick={handleStartUnit}
                          className={"section3-feedback-sub"}
                          style={{
                            textTransform: "capitalize",
                            padding: "6px 14px",
                          }}
                        />
                      </div>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
