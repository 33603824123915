import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Typography } from "@material-ui/core";
import { Custom } from "../../../components/Custom/Custom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { API } from "../../../Networking/API";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SchedulePage from "../schedules-upload-phase-2/schedule";
import { Button } from "@mui/material";
import Dashboard from "./dashboard";
import Trainer from "../trainee-upload/trainer";
import ReusableButtonBack from "../../../components/reusable-button/reusable-button";
import "./scheduleHome.css";

const ScheduleHome = () => {
  const location = useLocation();
  let locationbatchid = location.state.batchid;
  const [filter, setFilter] = useState(location.state.batch_bootcampname);
  const [batches, setBatches] = useState([]);
  const [tab, setTab] = useState(1);
  const [batchId, setBatchID] = useState(locationbatchid);

  const navigate = useNavigate();

  useEffect(() => {
    getBatches();
  }, []);
  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        setBatches(response.data.data[0].bacthes);
      } else {
        setBatches([]);
      }
    });
  };
  const handleInputChangeFilter = (e) => {
    var value = batches.filter(function (item) {
      return item.batch_name == e.target.value;
    });
    setBatchID(value[0].batch_pk);
    setFilter(e.target.value);
  };

  return (
    <>
      <Box>
        <Typography
          style={{
            color: "white",
            fontSize: "22px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "30px",
          }}
        >
          Bootcamp
        </Typography>
      </Box>
      <Box>
        <div style={{ paddingLeft: "20px", marginTop: "-49px" }}>
          <ReusableButtonBack
            onClick={() => navigate("/home/scheduleupload")}
            size="small"
            variant="contained"
            buttonName="Back"
            startIcon={<ArrowBackIosIcon />}
            style={{
              backgroundColor: "white",
              color: "#02215b",
              padding: "11px 17.4px 11px 17px",
              margin: "7px 0px 0px 6px",
              textTransform: "none",
              borderRadius: 6,
            }}
          />
        </div>
      </Box>
      <Box
        style={{
          paddingTop: "20px",
          // paddingBottom:"10px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Card style={{ backgroundColor: "white" }}>
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                padding: "18px 30px 18px 30px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2}
                  className="select-bootstrap"
                  style={{
                    display: "flex",
                    marginTop: "18px",
                    marginBottom: "10px",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Custom.BatchDropdown
                    name="Select Bootcamp"
                    label="Select Bootcamp"
                    value={filter}
                    onChange={handleInputChangeFilter}
                    options={batches}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      width: "12rem",
                    }}
                  ></Custom.BatchDropdown>
                </Grid>
                <Grid item xs={0.2}></Grid>
                <Grid item xs={7.45}></Grid>

                <Grid item xs={1}></Grid>
              </Grid>
            </div>
          </Box>
        </Card>
      </Box>
      <Box
        style={{
          padding: "20px",
        }}
      >
        <Card>
          <Typography
            variant="h3"
            style={{ fontSize: "26px", fontWeight: 600, padding: "20px" }}
          >
            {filter}
          </Typography>

          <Box
            style={{
              backgroundColor: "white",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "0px 20px 0px 20px",
            }}
            className="button-wrapper"
          >
            <Button
              onClick={() => {
                setTab(1);
              }}
              // className="commonbutton"
              className={tab === 1 ? "clickedTab" : "notClickedTab"}
            >
              <span className="commontypography">Dashboard</span>
            </Button>
            <Button
              onClick={() => {
                setTab(3);
              }}
              // className="commonbutton"
              className={tab === 3 ? "clickedTab" : "notClickedTab"}
            >
              <span className="commontypography">Scheduler</span>
            </Button>

            <Button
              onClick={() => {
                setTab(2);
              }}
              // className="commonbutton"
              className={tab === 2 ? "clickedTab" : "notClickedTab"}
            >
              <span className="commontypography">Trainees</span>
            </Button>
          </Box>
        </Card>

        {tab === 1 && <Dashboard />}
        {tab === 2 && <Trainer batchID={batchId} />}
        {tab === 3 && <SchedulePage batchID={batchId} />}
      </Box>
    </>
  );
};
export default ScheduleHome;
