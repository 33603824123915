import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UploadIcon from "@mui/icons-material/Upload";
import GroupIcon from "@mui/icons-material/Group";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QuizIcon from "@mui/icons-material/Quiz";
import "./menu-items-admin.css";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EventRepeatRoundedIcon from "@mui/icons-material/EventRepeatRounded";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function MenuItemsAdmin(props) {
  const { UpdatedAdminActive, mandatoryTrainingActive } = props;
  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/home/courses"
            className={({ isActive }) =>
              isActive
                ? "link-active"
                : UpdatedAdminActive
                ? "link-active"
                : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Master Data" placement="right" arrow>
                <EventRepeatRoundedIcon />
              </Tooltip>
            </ListItemIcon>
            Master Data
          </NavLink>
        </ListItemText>
      </ListItem>

      {/* <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/home/createMandatoryTraining"
            className={({ isActive }) =>
              isActive
                ? "link-active"
                : mandatoryTrainingActive
                ? "link-active"
                : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Mandate Training" placement="right" arrow>
                <CalendarMonthIcon />
              </Tooltip>
            </ListItemIcon>
            Mandate Training
          </NavLink>
        </ListItemText>
      </ListItem> */}
    </div>
  );
}
