import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./trainer.css";
import Button from "@mui/material/Button";
import { SearchBar } from "./search-bar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { API } from "../../../Networking/API";
import Notification from "../../../components/Custom/Notification";
import TrainerDetails from "./trainerDetails";
import { useRef } from "react";

const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const TrainerPage = (props) => {
  const { batchID } = props;
  const [fileMerge, setFileMerge] = useState("");
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState("1");
  const [sessionName, setSessionName] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [scheduleId, setScheduleId] = useState();
  const [showValues, setShowValues] = useState(false);
  const [sessionDetails, setSessionDetails] = useState([]);
  const inputRef = useRef(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
  };

  const handleUploadClick = () => {
    API.postAdminTrainerUploadPost(fileMerge, batchID).then(({ response }) => {
      if (response.api_status === 200) {
        setShowValues(false);
        inputRef.current.value = null;
        setUploaded(!uploaded);
        setNotify({
          isOpen: true,
          message: "File Uploaded successfully",
          type: "success",
        });
        setFileMerge("");
      } else {
        setUploaded(!uploaded);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
        setFileMerge("");
      }
    });
  };

  const setClickStatus = (status) => {
    setStatus(status);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          paddingTop: "24px",
          paddingLeft: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            marginBottom: "18px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "baseline",
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <input
                type="file"
                name="file"
                class="upload"
                accept={SheetJSFT}
                ref={inputRef}
                style={{
                  fontSize: "0.8em",
                  fontFamily: "Poppins",
                }}
                onChange={handleChange}
              />
              <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                *Only csv file is allowed
              </span>
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {fileMerge !== "" ? (
                <>
                  <Button
                    type="button"
                    className="UpldBttn"
                    variant="contained"
                    style={{
                      backgroundColor: "#fb2485",
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 200,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      padding: "12px 28px 12px 28px",
                      borderRadius: "6px",
                    }}
                    onClick={handleUploadClick}
                  >
                    Upload
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <hr
          style={{
            border: "0.5px solid #BBBBBB",
            width: "101%",
            marginLeft: "-15px",
          }}
        />
        <Box style={{ background: "#fff", paddingTop: "1px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SearchBar
                setSessionName={setSessionName}
                clickStatus={setClickStatus}
                batchID={batchID}
                setScheduleId={setScheduleId}
                uploadFile={uploaded}
              />
            </Grid>
            <Grid item xs={9}>
              {/* <h1
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: "#02215B",
                  marginTop: "8px",
                }}
              >
                {sessionName}
              </h1> */}
              {sessionName !== undefined ? (
                <Box
                  sx={{ width: "100%", typography: "body1", marginTop: "10px" }}
                >
                  {/* here we need to keep the condition for hackathon tasks & schdle assignment & quizz  */}
                  <TabContext value={value}>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Box>
                          <TabList
                            onChange={handleTabChange}
                            aria-label="lab API tabs example"
                            style={{ marginTop: "-12px" }}
                          >
                            <Tab
                              label="Trainee Details"
                              style={{ textTransform: "capitalize" }}
                              className="Tab-container"
                              value="1"
                            />
                            <Tab
                              label="Assignment"
                              style={{ textTransform: "capitalize" }}
                              className="Tab-container"
                              value="2"
                            />
                            <Tab
                              label="Quiz"
                              style={{ textTransform: "capitalize" }}
                              value="3"
                              className="Tab-container"
                            />
                            {/* <Tab
                              label="Pre-Requisite"
                              style={{ textTransform: "capitalize" }}
                              value="4"
                              className="Tab-container"
                            />
                            <Tab
                              label="Assignment"
                              style={{ textTransform: "capitalize" }}
                              value="5"
                              className="Tab-container"
                            />
                            <Tab
                              label="Quiz"
                              style={{ textTransform: "capitalize" }}
                              value="6"
                              className="Tab-container"
                            /> */}
                          </TabList>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <span>{sessionName}</span>
                        </Box>
                      </Grid>
                    </Grid>
                    <TabPanel value="1">
                      <TrainerDetails scheduleId={scheduleId} />
                    </TabPanel>
                    <TabPanel value="2">Assignment</TabPanel>
                    <TabPanel value="3">Quiz</TabPanel>
                    {/* <TabPanel value="4">Quiz</TabPanel>
                    <TabPanel value="5">Quiz</TabPanel>
                    <TabPanel value="6">Quiz</TabPanel> */}
                  </TabContext>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20%",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      /* identical to box height */
                      color: "#02215B",
                      marginTop: "8px",
                    }}
                  >
                    Please select a Trainee
                  </h1>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default TrainerPage;
