import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Cookies from "js-cookie";
import IconButton from "@mui/material/IconButton";
import pIcon from "../../../assets/Images/pIcon.png";
import badge from "../../../assets/Images/badge.png";
import logout from "../../../assets/Images/logOut.png";
import MenuList from "@mui/material/MenuList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { makeStyles } from "@material-ui/core";
import "./trainee-layout.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { API } from "../../../Networking/API";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import MenuItems from "../menu-items-trainee/menu-items-trainee";
import "./trainee-layout.css";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate, createSearchParams } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: "#000936",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const styles = makeStyles((theme) => ({
  openDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: "#000936",
    },
  },
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function TraineeHomePage(props) {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const jwtToken = Cookies.get("jwt_token");
  const [pAnchorEl, setPAnchorEl] = useState(null);
  const isOpen = Boolean(pAnchorEl);
  const handleClick = (event) => {
    setPAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const location = useLocation();
  const active =
    location.pathname === "/trainee/home/calendardetails" ||
    location.pathname === "/trainee/home/sessiondetails";
  const hActive =
    location.pathname === "/trainee/home/changePassword" ||
    location.pathname === "/trainee/home/" ||
    location.pathname === "/trainee/home/Profile";
  // const active =
  //   location.pathname === "/user/bootcamp/home/calendardetails" ||
  //   location.pathname === "/user/bootcamp/home/sessiondetails";
  // const hActive =
  //   location.pathname === "/trainee/home/changePassword" ||
  //   location.pathname === "/user/bootcamp/home/" ||
  //   location.pathname === "/user/bootcamp/home/Profile";

  const onClickLogout = () => {
    Cookies.remove("jwt_token");
    Cookies.remove("user_role");
    Cookies.remove("user_name");
    Cookies.remove("orginalRole");
    Cookies.remove("entryType");
    Cookies.remove("quizdata");
    navigate("/");
    setAnchorEl(null);
  };

  // To open and close the menu Icon
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleChangePassword = async () => {
    navigate({
      pathname: "/user/bootcamp/trainee/home/changePassword",
      search: createSearchParams({ email: userData.user_email }).toString(),
    });
    handleClose();
  };
  const getUserData = async () => {
    API.getTraineeDetails().then(({ response }) => {
      const userDetails = response.data.data[0].user_details;
      setUserData(userDetails);
    });
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          style={{ backgroundColor: "#000936" }}
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
            className="column"
          >
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: "12px",
                paddingRight: "15px !important",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="menuiconBtn" />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
              style={{
                alignSelf: "start",
                textAlign: "start",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: 25,
                marginTop: "11px",
                marginLeft: "40px",
              }}
            >
              ABSYZ Academy
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                className="profilebtn"
                sx={{
                  backgroundColor: "#fb2485",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                aria-controls={isOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img src={pIcon} alt="person icon" className="userIcon2" />
                <Typography className="profilebtnTxt">
                  {userData ? userData.user_name.split(" ")[0] : ""}
                </Typography>
                {pAnchorEl ? (
                  <KeyboardArrowUpRoundedIcon style={{ color: "#fff" }} />
                ) : (
                  <KeyboardArrowDownRoundedIcon style={{ color: "#fff" }} />
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={pAnchorEl}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{ marginTop: "10px" }}
              >
                <MenuList>
                  <div onClick={handleClose} className="Account">
                    <AccountCircleIcon
                      style={{ width: "38px", height: "38px" }}
                      className={"AccountIcon"}
                    />

                    <p
                      className="AccountLabel"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {userData
                        ? userData.user_name.charAt(0).toUpperCase() +
                          userData.user_name.slice(1)
                        : ""}
                    </p>
                    <p
                      className="AccountLabel2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {userData ? userData.user_role : ""}
                    </p>
                  </div>

                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={handleClose}
                  >
                    <img
                      src={badge}
                      style={{ marginRight: "10px" }}
                      alt="badge"
                      width="18px"
                      height="25px"
                    />
                    <Typography className="badge">Recent Badge</Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={() => {
                      navigate("/trainee/home/Profile");
                      handleClose();
                    }}
                  >
                    <AccountCircleOutlinedIcon
                      style={{
                        marginLeft: "-4px",
                        color: "#4b5e89",
                        marginRight: "10px",
                      }}
                    />
                    <Typography className="badge">My Profile</Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={handleChangePassword}
                  >
                    <LockResetRoundedIcon
                      style={{
                        marginLeft: "-4px",
                        color: "#4b5e89",
                        marginRight: "10px",
                      }}
                    />
                    <Typography className="badge">Change Password</Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={onClickLogout}
                  >
                    <img
                      src={logout}
                      alt="logout"
                      width="18px"
                      height="13.8px"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography className="badge">Logout</Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} className={classes.openDrawer}>
          <Toolbar
            style={{
              backgroundColor: "#000936",
              display: "flex",
              justifyContent: "end",
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              backgroundColor: "#000936",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {<MenuItems active={active} hActive={hActive} />}
          </List>
        </Drawer>
        <Box
          component="main"
          // className='layout-size'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "hidden !important",
          }}
        >
          <Toolbar />
          <Container
            className="adminlayout"
            maxWidth="xxl"
            sx={{ minheight: "40vh", padding: "0px !important" }}
          >
            <Outlet />
            {/* <Departments/> */}
            {/* <UserHomePage/> */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default TraineeHomePage;
