import Cookies from "js-cookie";
import axios from "axios";
import AuthVerify from "../components/Custom/AuthVerify";
import { ConnectQuery } from "../constants/userProfile";

const RequestMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const getJWTToken = async () => {
  const jwtToken = Cookies.get("jwt_token");

  return jwtToken;
};

const get = async (url) => callGetApi(RequestMethod.GET, url);

const post = async (url, params, header) =>
  callPostApi(RequestMethod.POST, url, params, header);

const put = async (url, params, header) =>
  callPutApi(RequestMethod.PUT, url, params, header);

const Delete = async (url) => callDeleteApi(RequestMethod.DELETE, url);

const callGetApi = async (requestMethod, url) => {
  let jwtToken = await getJWTToken();
  const options = {
    method: requestMethod,
    headers: {
      token: "asdfghjk",
      "aa-token": jwtToken,
    },
  };

  return axios(`${url}`, options)
    .then((response) => {
      if (response.data.api_status !== 403) {
        const statusCode = response.status;
        return new Promise((resolve, reject) => {
          resolve({ response, statusCode });
          reject(new Error("Invalid Response!"));
        });
      } else {
        <AuthVerify />;
      }
    })
    .catch((error) => {});
};

const callPostApi = async (requestMethod, url, params, header) => {
  let jwtToken = await getJWTToken();
  let requestOptions = null;
  const Header = { "Content-Type": "application/json" };
  if (header) {
    requestOptions = {
      method: requestMethod,
      body: params,
      mode: "cors",
      headers: { "aa-token": jwtToken, "Content-Type": Header["Content-Type"] },
    };
  } else {
    requestOptions = {
      method: requestMethod,
      body: params,
      mode: "cors",
      headers: { "aa-token": jwtToken },
    };
  }

  return fetch(`${url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;

      return new Promise((resolve, reject) => {
        response
          .json()
          .then((response) => {
            resolve({ response, statusCode });
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
    .catch((error) => {});
};

const callDeleteApi = async (requestMethod, url) => {
  let jwtToken = await getJWTToken();
  const DeleteOptions = {
    method: requestMethod,
    mode: "cors",
    headers: { token: `asdfghjk`, "aa-token": jwtToken },
  };

  return fetch(`${url}`, DeleteOptions)
    .then((response) => {
      const statusCode = response.status;
      return new Promise((resolve, reject) => {
        response
          .json()
          .then((result) => {
            resolve({ result, statusCode });
          })
          .catch((error) => {
            reject("Invalid Response!");
          });
      });
    })
    .catch((error) => {});
};

const GetConnect = async (url, token, connectuserId) => {
  //  const URL = url + '/services/data/v45.0/query/?';
  //  const params = {
  //   q: ConnectQuery + `'${connectuserId}'`
  //  };
  // let requestOptions={
  //   method: 'GET',
  //   mode : "no-cors",
  //   headers:{'Authorization': `Bearer ${token}`}

  //  };
  //
  //  return fetch(`${URL}`, requestOptions)
  //  .then((response) => {
  //
  //    const statusCode = response.status;
  //    return new Promise((resolve, reject) => {
  //      response
  //        .json()
  //        .then((response) => {
  //
  //          resolve({ response, statusCode });
  //        })
  //        .catch((error) => {
  //          reject("Invalid Response!", error);
  //        });
  //    });
  //  })
  //  .catch((error) => {
  //
  //  });

  try {
    const params = {
      q: "SELECT name, email FROM User WHERE id='005p0000005Fc9IAAS'",
    };

    const response = await axios.get(
      "https://absyzconnect--devcrm.sandbox.my.salesforce.com/services/data/v45.0/query",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        mode: "cors",
      }
    );

    // Handle the response data
  } catch (error) {
    // Handle any errors
    console.error(error);
  }
};

const callPutApi = async (requestMethod, url, params, header) => {
  let jwtToken = await getJWTToken();
  let requestOptions = null;
  if (header) {
    requestOptions = {
      method: requestMethod,
      body: params,
      mode: "cors",
      headers: { "aa-token": jwtToken, "Content-Type": "application/json" },
    };
  } else {
    requestOptions = {
      method: requestMethod,
      body: params,
      mode: "cors",
      headers: { "aa-token": jwtToken },
    };
  }

  return fetch(`${url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      return new Promise((resolve, reject) => {
        response
          .json()
          .then((response) => {
            resolve({ response, statusCode });
          })
          .catch((error) => {
            reject("Invalid Response!");
          });
      });
    })
    .catch((error) => {});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  post,
  put,
  Delete,
  GetConnect,
};
