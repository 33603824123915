import { Description } from "@mui/icons-material";

const BootCampTableHandler = async (data) => {
  const TableUpdatedData = await data.map((items, index) => ({
    sno: index + 1,
    id: items.batch_pk,
    BootcampName: items.batch_name,
    StartDate: items.start_date
      ? new Date(items.start_date).toLocaleDateString().slice(0, 10)
      : "",
    EndDate: items.end_date
      ? new Date(items.end_date).toLocaleDateString().slice(0, 10)
      : "",
  }));
  return TableUpdatedData;
};

const AdminDataTableHandler = async (AdminData) => {
  const AdminTableupdated = AdminData.map((items, index) => ({
    sno: index + 1,
    id: items.user_pk,
    firstname: items.user_name,
    department: items.role_name,
    designation: items.work_posiotion,
    email: items.user_email,
    certificates: items.certificates,
    phone: items.phonenumber,
    college: items.trainee_colleage,
    Status: items.status ? "Registered" : "Not Registered",
    experience: items.work_experience,
  }));
  return AdminTableupdated;
};

const AdminAddTask = async (task) => {
  const updatedData = task.map((items, index) => ({
    id: index + 1,
    taskName: items.task_name,
    quizFromTime: items.from_time,
    quizToTime: items.end_time,
    Resources: items.sets,
  }));
  return updatedData;
};

const AdminHackathons = async (data) => {
  const updatedData = data.map((items, index) => ({
    sno: index + 1,
    id: items.schedule_pk,
    topic: items.topic,
    trainingDate: items.training_date,
    fromTime: items.from_time,
    toTime: items.to_time,
    TrainerEmail: items.user_email,
  }));
  return updatedData;
};

const AdminUploadQuestions = async (data) => {
  const updatedData = data.map((items, index) => ({
    sno: index + 1,
    id: items.question_pk,
    Question: items.question,
    CorrectOption: items.ValidOption,
    Marks: items.marks,
    Topic: items.topic,
  }));
  return updatedData;
};

const AdminAssignment = async (data) => {
  const updatedData = data.map((items, index) => ({
    id: items.params_pk,
    parameterName: items.params_name,
    score: items.marks,
  }));
  return updatedData;
};

const AdminPrerequisite = async (data) => {
  const updatedData = data.map((items, index) => ({
    id: index + 1,
    Description: items.prereq_description,
    Prerequities: items.module_url,
    ModuleName: items.module_name,
    Topic: items.topic,
    pre_req_pk: items.pre_req_pk,
    schedule_pk: items.schedule_pk,
  }));
  return updatedData;
};

const AdminQuiz = async (data) => {
  const updatedData = data.map((items, index) => ({
    id: index + 1,
    quizNumber: items.quiz_number,
    quizFromTime: items.from_time,
    quizToTime: items.to_time,
    quizFromDate: items.from_date,
    quizToDate: items.to_date,
    Topic: items.topic,
  }));
  return updatedData;
};

const AdminTrainingMaterial = async (data) => {
  const updatedData = data.map((items, index) => ({
    id: index + 1,
    trainingName: items.material_name,
    trainingMaterial: items.material_url,
    Topic: items.topic,
    material_pk: items.material_pk,
    schedule_pk: items.schedule_pk,
  }));
  return updatedData;
};

const AdminGetUnits = async (data) => {
  const updatedData = data.map((item, index) => ({
    id: item.id,
    unitName: item.topic,
    unitDuration: `${item.duration} min`,
    Status:
      item.active_status === 1 || item.active_status === true
        ? "Active"
        : "InActive",
  }));
  return updatedData;
};

const AdminProgramDataTableHandler = (
  unitsData,
  coursesData,
  selectedEventDetials
) => {
  const checkWithTrainerDetailsForUnits = (programOtherData, id, type) => {
    let selectedUnitData = selectedEventDetials.find(
      (unitItem) => unitItem.unitId === id && !unitItem.isCourse
    );
    if (programOtherData && programOtherData !== "N/A") {
      return programOtherData;
    }
    if (selectedUnitData) {
      if (type === "date") {
        return selectedUnitData ? selectedUnitData.startDate : "N/A";
      } else if (type === "endtime") {
        return selectedUnitData ? selectedUnitData.end_time : "N/A";
      } else if (type === "starttime")
        return selectedUnitData ? selectedUnitData.start_time : "N/A";
      else if (type === "trainer") {
        return selectedUnitData
          ? selectedUnitData.trainer.first_name
            ? selectedUnitData.trainer.first_name
            : selectedUnitData.trainer.user_email
          : "N/A";
      } else return "N/A";
      // return "N/A";
    } else {
      return "N/A";
    }
  };
  const checkWithTrainerDetailsForCourseUnits = (
    programOtherData,
    unitId,
    courseId,
    type
  ) => {
    let selectedCourseUnitData = selectedEventDetials.find(
      (courseItem) =>
        courseItem.unitId === unitId &&
        courseItem.isCourse &&
        courseItem.courseId === courseId
    );
    if (programOtherData && programOtherData !== "N/A") {
      return programOtherData;
    }
    if (selectedCourseUnitData) {
      if (type == "date") {
        return selectedCourseUnitData
          ? selectedCourseUnitData.startDate
          : "N/A";
      } else if (type == "endtime") {
        return selectedCourseUnitData ? selectedCourseUnitData.end_time : "N/A";
      } else if (type == "trainer") {
        return selectedCourseUnitData
          ? selectedCourseUnitData.trainer.first_name
            ? selectedCourseUnitData.trainer.first_name
            : selectedCourseUnitData.trainer.user_email
          : "N/A";
      } else if (type == "starttime") {
        return selectedCourseUnitData
          ? selectedCourseUnitData.start_time
          : "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const unitData = unitsData.map((programUnitData) => {
    return {
      id: programUnitData.id,
      Program: programUnitData.unitName,
      // Date: programUnitData.date ? programUnitData.date : "N/A",
      Date: checkWithTrainerDetailsForUnits(
        programUnitData.date,
        programUnitData.id,
        "date"
      ),
      // Time: programUnitData.time ? programUnitData.time : "N/A",
      EndTime: checkWithTrainerDetailsForUnits(
        programUnitData.endtime,
        programUnitData.id,
        "endtime"
      ),
      StartTime: checkWithTrainerDetailsForUnits(
        programUnitData.starttime,
        programUnitData.id,
        "starttime"
      ),
      Duration: `${programUnitData.unitDuration} min`,
      // Trainer: programUnitData.trainer ? programUnitData.trainer : `N/A`,
      Trainer: checkWithTrainerDetailsForUnits(
        programUnitData.trainer,
        programUnitData.id,
        "trainer"
      ),
      Type: "Unit",
      Status: programUnitData.Status,
      backup: programUnitData?.backupTrainer
        ? programUnitData?.backupTrainer
        : "N/A",
      description: programUnitData?.description
        ? programUnitData?.description
        : "N/A",
      schedule_pk: programUnitData?.schedule_pk
        ? programUnitData?.schedule_pk
        : "N/A",
    };
  });
  let CourseUnit = [];
  const courseData = coursesData.map((programCourseData) => {
    return {
      id: programCourseData.courseId,
      Program: programCourseData.courseName,
      Date: programCourseData.date ? programCourseData.date : "N/A",

      Time: programCourseData.endtime ? programCourseData.endtime : "N/A",
      Duration: `${programCourseData.duration} min`,
      Trainer: programCourseData.trainer ? programCourseData.trainer : `N/A`,
      Type: "Course",
      Status: programCourseData.status,
      Units: programCourseData.units.map((programCourseUnit) => {
        if (programCourseUnit.isunitSelected === true) {
          CourseUnit.push({
            id: programCourseUnit.unitid,
            Program: programCourseUnit.unitTitle,
            Type: "Course-Unit",
            Duration: `${programCourseUnit.duration} min`,
            Status: programCourseUnit.status === 1 ? "Active" : "In-Active",
            Trainer: checkWithTrainerDetailsForCourseUnits(
              programCourseUnit.trainer,
              programCourseUnit.unitid,
              programCourseData.courseId,
              "trainer"
            ),
            Date: checkWithTrainerDetailsForCourseUnits(
              programCourseUnit.date,
              programCourseUnit.unitid,
              programCourseData.courseId,
              "date"
            ),
            EndTime: checkWithTrainerDetailsForCourseUnits(
              programCourseUnit.endtime,
              programCourseUnit.unitid,
              programCourseData.courseId,
              "endtime"
            ),
            StartTime: checkWithTrainerDetailsForCourseUnits(
              programCourseUnit.starttime,
              programCourseUnit.unitid,
              programCourseData.courseId,
              "starttime"
            ),
            CourseId: programCourseData.courseId,
          });
        }
        return {
          id: programCourseUnit.unitid,
          name: programCourseUnit.unitTitle,
          Description: programCourseUnit.unitDetails,
        };
      }),
    };
  });
  return { unitData, courseData, CourseUnit };
};

export const DataTableHandler = {
  BootCampTableHandler,
  AdminDataTableHandler,
  AdminAddTask,
  AdminHackathons,
  AdminUploadQuestions,
  AdminAssignment,
  AdminPrerequisite,
  AdminQuiz,
  AdminTrainingMaterial,
  AdminGetUnits,
  AdminProgramDataTableHandler,
};
