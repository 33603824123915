import { Navigate, Outlet } from "react-router-dom";
import Cookie from "js-cookie";

const ProtectedRoute = ({ allowedRoles }) => {
  const token = Cookie.get("jwt_token");
  const userRole = Cookie.get("user_role");

  return token !== undefined ? (
    userRole === allowedRoles ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
