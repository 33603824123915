import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";

import "./trainer-session.css";
import "./trainer-session-hackathon.css";
import "./progressbarstyles.css";
import { useReactCountdown } from "use-react-countdown";

export default function HackathonFooter(props) {
  const { hackathonDetails, hackathonDetailsTopFive } = props;
  const { days, hours, minutes, seconds } = useReactCountdown(
    hackathonDetails.remain_time || hackathonDetailsTopFive.remain_time
  );
  let hoursdata = days;
  const hoursdatas = hoursdata * `${24}` + hours;
  if (hoursdatas === 0 && minutes === 0 && seconds === 0) {
    document.location.reload();
  }

  return (
    <Box className="boxFooter">
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className="pCount">
            {hackathonDetails.total_participents ||
              hackathonDetailsTopFive.total_participents}
          </span>
          <span className="inProgress">In Progress</span>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {days !== "00" &&
          hours !== "00" &&
          minutes !== "00" &&
          seconds !== "00" ? (
            <span className="pCount" style={{ marginLeft: "20px" }}>
              {hoursdatas >= 10 ? hoursdatas : `0${hoursdatas}`}:
              {minutes >= 10 ? minutes : `0${minutes}`}:
              {seconds >= 10 ? seconds : `0${seconds}`}
            </span>
          ) : (
            <>
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={20}
                width={20}
              />
            </>
          )}
          <span className="inProgress" style={{ marginLeft: "-10px" }}>
            Time Remaining
          </span>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center !important",
            flexDirection: "column",
            alignItems: "center !important",
          }}
        >
          <span className="pCount" style={{ marginLeft: "150px" }}>
            {hackathonDetails.completed || hackathonDetailsTopFive.completed}
          </span>
          <span className="inProgress" style={{ marginLeft: "120px" }}>
            Completed
          </span>
        </Grid>
      </Grid>
    </Box>
  );
}
