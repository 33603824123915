import { useNavigate } from "react-router";
import { API } from "../Networking/API";
import { UnitQueryParams } from "../handler/addCourseHandler";

export const HandleUnitTag = (
  formik,
  unitData,
  setTagValue,
  setselectedTag,
  allTag
) => {
  formik.values.unitName = unitData.topic;
  formik.values.Difficulty = unitData.levelDetails.id;
  formik.values.Duration = unitData.duration;
  if (unitData.active_status === 1) {
    formik.values.Active = true;
  } else formik.values.Active = false;
  if (unitData.unit_type) {
    formik.values.selectType = 1;
    formik.values.attempts = unitData.attempts;
  } else {
    formik.values.selectType = 0;
  }
  formik.values.Technology = unitData.technology;
  formik.values.session = unitData.description;
  formik.values.passPercentage = unitData.pass_percentage;
  let TagData = unitData.tagDetails.map((data) => {
    return data.tag_name;
  });
  formik.values.Tag = TagData.join(",");

  let TagTitle = unitData.tagDetails.map((TagValue) => {
    return {
      id: TagValue.id,
      title: TagValue.tag_name,
    };
  });

  setTagValue(TagTitle);
  let TagName = unitData.tagDetails.map((Tag) => {
    return Tag.tag_name;
  });
  let FinalTag = allTag.filter((Tags) => TagName.includes(Tags.title));
  let OtherOptios = allTag.filter((Tags) => !TagName.includes(Tags.title));
  setTagValue(OtherOptios);
  setselectedTag(FinalTag);
};

export const HandleTagChange = async (
  selectedValue,
  formik,
  setTagValue,
  allTag
) => {
  if (selectedValue.length > 0) {
    let SelectedTagName = selectedValue.map((TagValue) => {
      return TagValue.title;
    });
    formik.values.Tag = SelectedTagName.join(",");
    // formik.values.Tag = SelectedTagName;
    let TagValue = allTag.filter(
      (data) => !SelectedTagName.includes(data.title)
    );
    setTagValue(TagValue);
  } else {
    setTagValue(allTag);
  }
  let TagData = selectedValue.map((TagData) => {
    return TagData.id;
  });
  // formik.values.Tag = TagData;
  formik.values.Tag = TagData.join(",");
  if (selectedValue.length < 0) {
    formik.errors.Tag = "Please Select  Tag";
  } else {
    delete formik.errors["Tag"];
  }
};

export const CreateUnitHelper = (
  formik,
  setIsDisabled,
  setNotify,
  setIsCreate,
  isCreate,
  setDisabled,
  allTag,
  selectedTag,
  navigate,
  setloaderForSaveLaterUnit,
  setloaderForPublishUnit
) => {
  let SelectedTagName = selectedTag.map((data) => {
    return data.title;
  });
  let TagId = allTag
    .filter((AllTag) => SelectedTagName.includes(AllTag.title))
    .map((filtredData) => {
      return filtredData.id;
    });
  // const UnitData = {
  //   topic: formik.values.unitName,
  //   description: formik.values.session,
  //   difficulty_level_id: formik.values.Difficulty,
  //   technology: formik.values.Technology,
  //   tag_names: TagId,
  //   // duration: formik.values.Duration,
  //   active_status: formik.values.Active === true ? 1 : 0,
  // };

  const UnitCreation = {
    topic: formik.values.unitName,
    description: formik.values.session,
    difficulty_level_id: formik.values.Difficulty,
    technology: formik.values.Technology,
    tag_names: TagId,
    active_status: formik.values.Active === true ? 1 : 0,
    pass_percentage: formik.values.passPercentage,
    unit_type: 0,
  };

  if (isCreate === false) {
    const UnitId = window.localStorage.getItem("UnitId");
    if (!UnitId) {
      setloaderForSaveLaterUnit(true);
      API.CreateUnit(UnitCreation)
        .then(({ response }) => {
          if (response.api_status === 200) {
            setloaderForSaveLaterUnit(false);
            setDisabled(false);
            setIsDisabled(true);
            setNotify({
              isOpen: true,
              message: response.data[0].message,
              type: "success",
            });
            let UnitId = response.data[0].unit.id;
            window.localStorage.setItem("UnitId", UnitId);
          } else {
            setloaderForSaveLaterUnit(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        })
        .catch(({ error }) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong, Please Try Again",
            type: "error",
          });
        });
    } else {
      setloaderForSaveLaterUnit(true);
      API.AdminEditUnit(UnitCreation, UnitId)
        .then(({ response }) => {
          if (response.api_status === 200) {
            setloaderForSaveLaterUnit(false);
            setDisabled(false);
            setIsDisabled(true);
            setNotify({
              isOpen: true,
              message: response.data[0].message,
              type: "success",
            });
          } else {
            setloaderForSaveLaterUnit(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        })
        .catch(({ error }) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong, Please Try Again",
            type: "error",
          });
        });
    }
  } else {
    setIsCreate(false);
    const UnitId = window.localStorage.getItem("UnitId");
    if (UnitId) {
      setloaderForPublishUnit(true);
      API.finalUnitCreation(UnitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishUnit(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishUnit(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  }
};

export const GetAllUnitHelper = (
  pageCount,
  limit,
  setAllUnits,
  setPageLimit,
  isSelectedDifficultyLevel,
  courseDifficultyLevel,
  isSeletedTag,
  unitSelectedTag,
  setLoader
) => {
  let pageNo = pageCount + 1;
  const queryParms = UnitQueryParams(
    isSelectedDifficultyLevel,
    isSeletedTag,
    courseDifficultyLevel,
    unitSelectedTag ? unitSelectedTag : "",
    limit,
    pageNo,
    "unit"
  );
  API.getAllUnit(pageNo, limit, queryParms).then(({ response }) => {
    if (response.data.api_status === 200) {
      setPageLimit(response.data.data.count);
      let ApiCourseData = response.data.data.rows;
      setAllUnits(response.data.data.rows);
      setLoader(false);
    } else {
      setPageLimit(1);
      setAllUnits([]);
      setLoader(false);
    }
  });
};

export const GetAllCourseHelper = (
  coursePageCount,
  limit,
  setAllCourseData,
  setPageLimit,
  isSelectedDifficultyLevel,
  courseDifficulty,
  isSeletedTag,
  courseTags,
  setCourseCount,
  setLoader
) => {
  let pageNo = coursePageCount + 1;
  const queryParms = UnitQueryParams(
    isSelectedDifficultyLevel,
    isSeletedTag,
    courseDifficulty,
    courseTags ? courseTags : "",
    limit,
    pageNo,
    "course"
  );

  API.getAllCourse(coursePageCount, limit, queryParms).then(({ response }) => {
    if (response.data.api_status === 200) {
      setAllCourseData(response.data.data.rows);
      setCourseCount(response.data.data.count);
      setLoader(false);
    } else {
      setAllCourseData([]);
      setCourseCount(0);
      setLoader(false);
    }
  });
};

export const DeleteUnitHelper = async (setNotify, navigate, UnitId) => {
  if (UnitId) {
    await API.AdminDeleteUnit(UnitId)
      .then((response) => {
        if (response.result.api_status === 200) {
          window.localStorage.removeItem("UnitId");
          setNotify({
            isOpen: true,
            message: response.result.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
          return true;
        } else {
          setNotify({
            isOpen: true,
            message: response.result.error[0].error_msg,
            type: "error",
          });
          return false;
        }
      })
      .catch(({ error }) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please Try Again",
          type: "error",
        });
        return false;
      });
  }
};

export const UnitReorderCall = async (draggableData, courseId) => {
  const unitIds = draggableData.map((data) => {
    return data.id;
  });
  if (!Array.isArray(courseId)) {
    await API.UnitReorder(unitIds, courseId)
      .then((response) => { })
      .catch((error) => { });
  }
};

export const EditDescriptionHelper = (
  description,
  setNotify,
  unitId,
  setDisableDesc,
  setState
) => {
  API.EditPreDescription(description, unitId)
    .then((response) => {
      if (response.response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.response.data[0].message,
          type: "success",
        });

        setDisableDesc(true);
        // setState();
      } else {
        setNotify({
          isOpen: true,
          message: response.response.error[0].error_msg,
          type: "error",
        });
        // setState();
      }
    })
    .catch((error) => { });
};
