import { Box, Typography, IconButton } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./mandatoryTraining-layout.css";
import { Custom } from "../../../components/Custom/Custom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import { SxStyles } from "../../../styles/styles";
import { useFormik } from "formik";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { API } from "../../../Networking/API";
import Notification from "../../../components/Custom/Notification";
import { CreateTrainingMaterialvalidations } from "../../../Validation/FieldValidatorSchema";
import DeletePopUp from "../masterData-layout/masterDataModal/deletePopUp";
import { DeleteUnitHelper } from "../../../helper/unitHelper";
import { useNavigate } from "react-router";
import ReactLoading from "react-loading";
import DataTableDrag from "../../../components/Custom/draggable/dataTableDraggable";
import ReactPaginate from "react-paginate";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CircularProgress from "@mui/material/CircularProgress";

const SheetJSFTVideo = ["mp4"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const SheetJSFT = ["pdf,ppt,docx,pptx,doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const MandateMaterial = () => {
  const styles = SxStyles.styles;
  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      fileName: "",
      videoLink: "",
      file: "",
      duration: "",
    },
    validationSchema: CreateTrainingMaterialvalidations,
    onSubmit: (values) => {
      handleAddButtonClick();
    },
  });
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const inputRef = useRef();
  const [viewModalData, setViewModalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [draggableData, setDraggableData] = useState([]);
  const [deleteTrainingMaterialId, setDeleteTrainingMaterialId] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [deleteModal, setDeletModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [done, setDone] = useState(true);
  const [limit, setLimit] = useState(4);
  const [loaderForSaveLaterTM, setLoaderForSaveLaterTM] = useState(false);
  const [loaderForPublishTM, setLoaderForPublishTM] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dataTableHeader = [
    "S.No",
    "Name",
    "Video Link",
    "Material",
    "Duration",
    "Actions",
  ];
  const mandateId = localStorage.getItem("mandateId");
  
  let navigate = useNavigate();

  useEffect(() => {
    getTrainingMaterial();
  }, []);

  useEffect(() => {
    updateTrainingMaterial();
  }, [draggableData]);

  const updateTrainingMaterial = () => {
    const sortableIds = draggableData.map((x) => x.id);
    API.AdminTrainingMaterialReorder(mandateId, sortableIds).then(
      ({ response }) => {
        if (response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        }
      }
    );
  };

  const getTrainingMaterial = () => {
    API.getAdminTrainingMaterial(mandateId).then(({ response }) => {
      setDone(false);
      if (response.data.api_status === 200) {
        const data = response.data.data;
        const formattedData = data.map((each) => ({
          id: each.material_pk,
          trainingName: each.material_name,
          trainingVideo:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          trainingMaterial:
            each.material_url !== null ? each.material_url : "N/A",
        }));
        const FinalFormData = data.map((each, index) => ({
          id: each.material_pk,
          sno: index + 1,
          first: each.material_name,
          second:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          third: each.material_url !== null ? each.material_url : "N/A",
          fourth: each.duration,
          // five: "view",
        }));
        setDraggableData(FinalFormData);
        setTableData(formattedData);
      } else {
        setDraggableData([]);
      }
    });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
  };

  const handleVideoChange = (event, setFieldValue) => {
    
    const file = event.target.files[0];
    setFieldValue("video", file);
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteTrainingMaterialId(params);
    setOpen(true);
  };

  const handleViewClick = (id) => {
    setViewOpen(true);

    API.viewAdminMandatoryTrainingMaterial(mandateId, id).then(
      ({ response }) => {
        if (response.api_status === 200) {
          setPageIndex(0);
          setPageCount(0);
          const data = response.data[0].questions;
          const formattedData = data.map((each, index) => ({
            id: index + 1,
            question: each.question,
            answer:
              each.ValidOption !== null && each.ValidOption !== ""
                ? each.ValidOption
                : "N/A",
          }));
          setViewModalData(formattedData);
          // setNotify({
          //   isOpen: true,
          //   message: response.data[0].message,
          //   type: "success",
          // });
          getTrainingMaterial();
        } else {
          setNotify({
            isOpen: true,
            message: "Training Material Not View Please Try Again",
            type: "error",
          });
        }
      }
    );
  };

  const handleCloseDelete = (id) => {
    
    setDone(true);
    setOpen(false);

    API.deleteAdminMandatoryTrainingMaterial(mandateId, id).then(
      ({ response }) => {
        // const response = result;
        
        if (response.api_status === 200) {
          setPageIndex(0);
          setPageCount(0);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          getTrainingMaterial();
        } else {
          setNotify({
            isOpen: true,
            message: "Training Material Not Deleted Please Try Again",
            type: "error",
          });
        }
      }
    );
  };

  const handleAddButtonClick = () => {
    setDone(true);
    // 
    const { fileName, video, file, duration } = values;
    
    API.AdminAddMandatoryTrainingMaterial(
      mandateId,
      fileName,
      video,
      file,
      duration
    ).then(({ response }) => {
      getTrainingMaterial();
      resetForm();
      inputRef.current.value = null;
      if (response.api_status === 200) {
        
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const HandleDeleteUnit = () => {
    DeleteUnitHelper(setNotify, navigate, mandateId);
    setDeletModal(false);
  };

  const handleSaveForLaterButton = () => {
    setLoaderForSaveLaterTM(true);
    API.AdminSaveForLaterApi(mandateId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setLoaderForSaveLaterTM(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setLoaderForSaveLaterTM(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again later",
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    setLoaderForPublishTM(true);
    API.finalAdminCreationPublish(mandateId).then(({ response }) => {
      
      if (response.data.api_status === 200) {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
        navigate("/home/courses");
      } else {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response?.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handlePageClick = (data) => {
    setPageIndex(data.selected);
    setPageCount(data.selected);
  };

  const viewModalColumn = [
    {
      field: "id",
      headerName: "S.No",
      width: 20,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "question",
      headerName: "Question",
      width: 580,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "answer",
      headerName: "Answer",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Box className="training-material-container">
        <form onSubmit={handleSubmit}>
          <Grid container className="training-materail">
            <Grid item xs={2.4}>
              <div style={styles.inputContainer}>
                <Typography style={styles.label} variant="h6">
                  File Name
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                <span>
                  <ReusableInputfield
                    name="fileName"
                    id="fileName"
                    placeholder="Name"
                    value={values.fileName}
                    onChange={handleChange}
                    size="small"
                    style={styles.inputFleid}
                    error={touched.fileName && Boolean(errors.fileName)}
                    helperText={touched.fileName && errors.fileName}
                  />
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={2.3}
              style={
                errors.file
                  ? styles.inputContainerValidator
                  : styles.inputContainer
              }
            >
              <div style={styles.inputContainer}>
                <Typography style={styles.label} variant="h6">
                  Upload Video
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                {/* <span>
                  <ReusableInputfield
                    name="videoLink"
                    id="videoLink"
                    placeholder="Video Link"
                    value={values.videoLink}
                    style={{
                      height: "41px",
                      width: "100%",
                    }}
                    onChange={handleChange}
                    error={touched.file && Boolean(errors.file)}
                    helperText={touched.file && errors.file}
                    size="small"
                  />
                </span> */}
                {/* <div></div> */}
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="upload-box"
                  ref={inputRef}
                  accept={SheetJSFTVideo}
                  style={{
                    fontSize: "0.8em",
                    fontFamily: "Poppins",
                    height: "41px",
                    position: "relative",
                    bottom: "3px",
                    width: "100%",
                  }}
                  onChange={(e) => handleVideoChange(e, setFieldValue)}
                />
              </div>
            </Grid>

            <Grid item xs={3.3} style={styles.fileContainer}>
              <div style={styles.inputContainer}>
                <Typography style={styles.label} variant="h6">
                  Upload Questions
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                {/* <span> */}
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="upload-box"
                  ref={inputRef}
                  accept={SheetJSFT}
                  style={{
                    fontSize: "0.8em",
                    fontFamily: "Poppins",
                    height: "40px",
                    position: "relative",
                    bottom: "3px",
                    // width:"100%"
                  }}
                  onChange={(e) => handleFileChange(e, setFieldValue)}
                />
                {/* </span> */}
                <Typography style={styles.note} variant="p">
                  Only .csv format allowed
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={2.3}
              style={
                errors.file
                  ? styles.inputContainerValidator
                  : styles.inputContainer
              }
            >
              <div style={styles.inputContainer}>
                <Typography style={styles.label} variant="h6">
                  Duration(in minss)
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                <span>
                  <ReusableInputfield
                    name="duration"
                    id="duration"
                    placeholder="Duration"
                    value={values.duration}
                    style={{
                      height: "41px",
                      width: "100%",
                    }}
                    onChange={handleChange}
                    error={touched.duration && Boolean(errors.duration)}
                    helperText={touched.duration && errors.duration}
                    size="small"
                  />
                </span>
              </div>
            </Grid>
            <Grid
              item
              // xs={0.2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "2%",
                paddingTop: "1%",
              }}
            ></Grid>
            <ReusableButton
              type="submit"
              size="large"
              variant="outlined"
              buttonName="Add"
              style={styles.addbuttonTrainingMaterial}
            ></ReusableButton>
          </Grid>
        </form>
        {done ? (
          <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {draggableData.length > 0 ? (
              <>
                <Box style={styles.tableContainer}>
                  <DataTableDrag
                    draggableData={draggableData}
                    setDraggableData={setDraggableData}
                    handleDeleteInpre={handleDeleteInpre}
                    handleViewClick={handleViewClick}
                    pageIndex={pageIndex}
                    pageLimit={limit}
                    link={true}
                    dataTableHeader={dataTableHeader}
                  />
                  {draggableData.length > limit ? (
                    <ReactPaginate
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                      pageCount={Math.ceil(draggableData.length / limit)}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={pageCount}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box style={styles.tableErrorMsg}>
                  No Training Material found. Please Add Training Material
                </Box>
              </>
            )}
          </>
        )}
        <Box style={styles.btnContainer}>
          <Box style={styles.buttonContainer}>
            <ReusableButton
              onClick={() => navigate("/home/courses")}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={styles.cancelButton}
              disabled={
                loaderForSaveLaterTM || loaderForPublishTM ? true : false
              }
            ></ReusableButton>
            <ReusableButton
              onClick={handleSaveForLaterButton}
              className="addbutton"
              size="large"
              variant="contained"
              endIcon={
                !loaderForSaveLaterTM ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForSaveLaterTM ? "Save for Later" : "Saving"}
              style={
                !loaderForPublishTM
                  ? styles.saveForLaterBtn
                  : styles.saveForLaterBtnDisabled
              }
              disabled={loaderForPublishTM ? true : false}
            ></ReusableButton>
            <ReusableButton
              onClick={handleCreateButton}
              size="large"
              variant="contained"
              endIcon={
                !loaderForPublishTM ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForPublishTM ? "Publish" : "Publishing"}
              style={
                !loaderForSaveLaterTM
                  ? styles.createBtn
                  : styles.createBtnDisabled
              }
              disabled={loaderForSaveLaterTM ? true : false}
            ></ReusableButton>
          </Box>
        </Box>
      </Box>

      <Dialog
        divider
        open={open}
        maxWidth="sm"
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to deleteee?
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleCloseInByCancel}
          />
          <Button
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName="Delete"
            style={styles.createBtnDialog}
            onClick={() => handleCloseDelete(deleteTrainingMaterialId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        divider
        open={viewOpen}
        fullWidth="true"
        maxWidth="md"
        onClose={handleViewClose}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <span
              style={{
                color: "#02215b",
                fontFamily: "poppins",
                fontWeight: 600,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Quiz Questions
            </span>
            {viewModalData.length !== 0 ? (
              <>
                <DataTable
                  rows={viewModalData}
                  columns={viewModalColumn}
                  className="assigntable"
                  rowHeight={40}
                  autoHeight
                  hideFooterPagination={true}
                  hideFooter={true}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#8080802b",
                  }}
                >
                  <span
                    style={{
                      color: "#02215b",
                      fontFamily: "poppins",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    No Questions Available
                  </span>
                </div>
              </>
            )}

            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Close"
            style={styles.cancelButton}
            onClick={handleViewClose}
          />
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default MandateMaterial;
