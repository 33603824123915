import * as React from "react";
import "./reports.css";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    field: "id",
    width: 90,
    cellClassName: "super-app-theme--cell",
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "participants",
    headerName: "Participants",
    width: 195,
    cellClassName: "super-app-theme--cell",
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "assignment",
    headerName: "Assignment",
    width: 150,
    headerClassName: "super-app-theme--headers1eA",
  },
  {
    field: "quiz",
    headerName: "Quiz",
    width: 105,
    headerClassName: "super-app-theme--headerse",
  },
  {
    field: "trainer",
    headerName: "Trainer",
    width: 190,
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "mentor",
    headerName: "Mentor",
    width: 190,
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "fromdate",
    headerName: "From Date",
    width: 125,
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "todate",
    headerName: "To Date",
    width: 125,
    headerClassName: "super-app-theme--header1se",
  },
];
const column = [
  {
    field: "id",
    width: 70,
    cellClassName: "super-app-theme--cell",
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "participants",
    headerName: "Participants",
    width: 195,
    cellClassName: "super-app-theme--cell",
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "task1",
    headerName: "Task 1",
    width: 105,
    headerClassName: "super-app-theme--headers1e",
  },
  {
    field: "task2",
    headerName: "Task 2",
    width: 105,
    headerClassName: "super-app-theme--headerse",
  },
  {
    field: "task3",
    headerName: "Task 3",
    width: 105,
    headerClassName: "super-app-theme--headerse",
  },
  {
    field: "task4",
    headerName: "Task 4",
    width: 105,
    headerClassName: "super-app-theme--headerse",
  },
  {
    field: "task5",
    headerName: "Task 5",
    width: 105,
    headerClassName: "super-app-theme--headerse",
  },
  {
    field: "mentor",
    headerName: "Mentor",
    width: 150,
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "fromdate",
    headerName: "From Date",
    width: 125,
    headerClassName: "super-app-theme--header1se",
  },
  {
    field: "score",
    headerName: "Score",
    width: 105,
    headerClassName: "super-app-theme--header1se",
  },
];

const rows = [
  {
    id: 1,
    participants: "nadeem",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 2,
    participants: "saran",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 3,
    participants: "sumanth",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 4,
    participants: "abhi",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 5,
    participants: "prem",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 6,
    participants: "nataraj",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 7,
    participants: "saurov",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 8,
    participants: "phani",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 9,
    participants: "nadeem",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 10,
    participants: "saran",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 11,
    participants: "sumanth",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 12,
    participants: "abhi",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 13,
    participants: "prem",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 14,
    participants: "nataraj",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 15,
    participants: "saurov",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
  {
    id: 16,
    participants: "phani",
    quiz: "80%",
    assignment: "4.5/5",
    trainer: "Lannister",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    todate: "19/12/2022",
  },
];
const row = [
  {
    id: 1,
    participants: "nadeem",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 2,
    participants: "saran",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 3,
    participants: "sumanth",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 4,
    participants: "abhi",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 5,
    participants: "prem",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 6,
    participants: "nataraj",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 7,
    participants: "saurov",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 8,
    participants: "phani",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 9,
    participants: "nadeem",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 10,
    participants: "saran",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 11,
    participants: "sumanth",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 12,
    participants: "abhi",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 13,
    participants: "prem",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 14,
    participants: "nataraj",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 15,
    participants: "saurov",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
  {
    id: 16,
    participants: "phani",
    task1: "80%",
    task2: "4.5/5",
    task3: "80%",
    task4: "4.5/5",
    mentor: "Melisandre",
    fromdate: "16/12/1999",
    score: "19/12/2022",
  },
];

const columnGroupingModel = [
  {
    groupId: "S.No",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "id",
      },
    ],
  },
  {
    groupId: "Participants",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "participants",
      },
    ],
  },

  {
    groupId: "Session Name from bootcamp 1",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "quiz",
      },
      {
        field: "assignment",
      },
    ],
  },
  {
    groupId: "Trainer",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "trainer",
      },
    ],
  },
  {
    groupId: "Mentor",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "mentor",
      },
    ],
  },
  {
    groupId: "From Date",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "fromdate",
      },
    ],
  },
  {
    groupId: "To Date",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "todate",
      },
    ],
  },
];
const columnGroupingModel2 = [
  {
    groupId: "S.No",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "id",
      },
    ],
  },
  {
    groupId: "Participants",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "participants",
      },
    ],
  },

  {
    groupId: "Hackathon",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "task1",
      },
      {
        field: "task2",
      },
      {
        field: "task3",
      },
      {
        field: "task4",
      },
      {
        field: "task5",
      },
    ],
  },

  {
    groupId: "Mentor",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "mentor",
      },
    ],
  },
  {
    groupId: "From Date",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "fromdate",
      },
    ],
  },
  {
    groupId: "Score",
    headerClassName: "my-super-theme--naming-group",
    children: [
      {
        field: "score",
      },
    ],
  },
];

export default function View(props) {
  const { setViews, scheduleIdv, schedulepkv, batchIdv, selectedValue } = props;
  const [filter, setFilter] = useState("");
  const [done, setDone] = useState(undefined);
  const [hackathonfilter, setFilterHackathon] = useState("");
  // const location = useLocation();
  // let navigate = useNavigate();
  // let locationscheduler = location.state.schedulename;
  // let locatebatchid = location.state.batchid;let locateschedulePk = location.state.schedulePk;
  let locatebatchid = batchIdv;
  let locateschedulePk = schedulepkv;
  let locationScheduleId = scheduleIdv;
  let locateschedulename = selectedValue;

  useEffect(() => {
    viewdataSchedule();
  }, [locatebatchid, locateschedulePk]);

  useEffect(() => {
    viewdataHackathon();
  }, [locationScheduleId, locatebatchid]);

  const viewdataSchedule = async () => {
    await API.getViewIconFun(locateschedulePk, locatebatchid).then(
      ({ response }) => {
        if (response.data.api_status === 200) {
          let topic = response.data.data;
          const scheduleTopic = topic.map((items, index) => ({
            id: index + 1,
            participants: items.user_name,
            assignment: items.asn_percentage,
            quiz: items.quiz_percentage,
            assignments: items.asn_percentage,
            quizs: items.quiz_percentage,
            trainer: items.trainer_name,
            mentor: items.mentor,
            fromdate: items.from_date.slice(0, 10),
            todate: items.end_date.slice(0, 10),
          }));
          setFilter(scheduleTopic);
          setDone(true);
        }
      }
    );
  };
  const viewdataHackathon = async () => {
    await API.getViewIconHackathonFun(locateschedulePk, locatebatchid).then(
      ({ response }) => {
        if (response.data.api_status === 200) {
          let topicHackathon = response.data.data;
          const scheduleTopic = topicHackathon.map((items, index) => ({
            id: index + 1,
            participants: items.user_name,
            task1: items.tasks[0].task_score,
            task2: items.tasks[1].task_score,
            task3: items.tasks[2].task_score,
            task4: items.tasks[3].task_score,
            task5: items.tasks[4].task_score,
            mentor: items.mentor ? items.mentor : "null",
            fromdate: items.tasks[0].from_date.slice(0, 10),
            score:
              (items.tasks[4].task_score +
                items.tasks[0].task_score +
                items.tasks[1].task_score +
                items.tasks[3].task_score +
                items.tasks[2].task_score) /
              5,
          }));
          setFilterHackathon(scheduleTopic);
          setDone(true);
        }
      }
    );
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box className="view-header">
            <Grid container spacing={2}>
              <Grid item xs={5.5}>
                <ReusableButton
                  onClick={() => setViews(true)}
                  size="small"
                  variant="contained"
                  buttonName="Back"
                  startIcon={<ArrowBackIosIcon />}
                  style={{
                    backgroundColor: "white",
                    color: "#02215b",
                    padding: "11px 17.4px 11px 17px",
                    margin: "7px 0px 0px 17px",
                    textTransform: "none",
                    borderRadius: 6,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <div
            style={{
              height: "95vh",
              width: "100%",
              padding: 20,
              borderRadius: "16px",
            }}
          >
            {locateschedulename === "Schedules" ? (
              <>
                <Box className="reportbox">
                  <Typography variant="p">Report : Bootcamp #1234</Typography>
                </Box>
                <Box className="viewbox">
                  <DataGrid
                    experimentalFeatures={{ columnGrouping: true }}
                    rows={filter}
                    columns={columns}
                    rowHeight={45}
                    // rowsPerPageOptions = {[1000]}
                    // hideFooterPagination="true"
                    // hideFooter="true"
                    // hideFooterSelectedRowCount="true"
                    columnGroupingModel={columnGroupingModel}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box className="reportbox">
                  <Typography variant="p">Report : Bootcamp #1234</Typography>
                </Box>
                <Box className="viewbox">
                  <DataGrid
                    experimentalFeatures={{ columnGrouping: true }}
                    rows={hackathonfilter}
                    columns={column}
                    rowHeight={45}
                    // rowsPerPageOptions = {[1000]}
                    // hideFooterPagination="true"
                    // hideFooter="true"
                    // hideFooterSelectedRowCount="true"
                    columnGroupingModel={columnGroupingModel2}
                  />
                </Box>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
