import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import CreateMandateTraining from "./mandateTraining";
import TrainingMaterial from "../masterData-layout/createUnit/training-material";
import PreRequisite from "../masterData-layout/createUnit/pre-requisite";
import Assignment from "../masterData-layout/createUnit/assignment";
import Quiz from "../masterData-layout/createUnit/Quiz";
import AdminMandateQuestionsUpload from "../mandatory-training/mandatequizQuestionsUpload";
import ReactLoading from "react-loading";
import { API } from "../../../Networking/API";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SxStyles } from "../../../styles/styles";
import MandateMaterial from "./mandateMaterial";
import MandateQuiz from "./mandateQuiz";
import MandatoryIntroduction from "./mandatoryIntroduction";

const MandatoryTrainingLayout = (props) => {
  const styles = SxStyles.styles;
  const [tab, setTab] = useState(1);
  const [mandateData, setMandateData] = useState({});
  const [dissabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isInitialRendrer, setIsInitialRendere] = useState(false);
  const [unitDescription, setUnitDescription] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [quizDisabled, setQuizDisabled] = useState(false);
  const [mandateUnique, setMandateUnique] = useState(
    window.localStorage.getItem("mandateId")
  );
  const [tabVisible, setTabVisible] = useState(false);

  useEffect(() => {
    setMandateUnique(localStorage.getItem("mandateId"));
  }, [localStorage.getItem("mandateId")]);
  const navigate = useNavigate();
  useEffect(() => {
    const mandateId = window.localStorage.getItem("mandateId");

    if (mandateId) {
      API.getMandatoryTrainingById(mandateId)
        .then(({ response }) => {
          if (response.data.api_status === 200) {
            
            setMandateData(response.data.data[0].mandatorytraining);
            setDisabled(false);
            setLoader(false);
            setLoadingScreen(false);
            setQuizDisabled(
              response.data.data[0].mandatorytraining?.quiz_mandatory
            );
          } else {
            setLoader(true);
            setLoadingScreen(false);
          }
        })
        .catch(({ error }) => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, [tab]);

  return (
    <>
      {loader ? (
        <Box
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box
          style={{
            padding: "20px",
          }}
        >
          <Box styles={styles.headerContainer}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <div>
                    <ReusableButton
                      onClick={() => navigate("/home/courses")}
                      size="small"
                      variant="contained"
                      buttonName="Back"
                      startIcon={<ArrowBackIosIcon />}
                      style={styles.backBtn}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span style={SxStyles.createCourseStyle.mainCreateCourseSpan}>
                  Mandatory Training
                </span>
              </Grid>
            </Grid>
          </Box>
          {/* {Object.keys(mandateData).length === 0 ? ( */}
          {/* <Box
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box> */}
          {/* ) : ( */}
          <>
            <Card
              style={{
                backgroundColor: "#F0F0F0",
                borderRadius: "12px 12px 0px 0px",
              }}
            >
              <Typography
                variant="h3"
                style={{ fontSize: "26px", fontWeight: 600, padding: "20px" }}
              >
                Mandatory Training
              </Typography>
              <Box
                style={{
                  backgroundColor: "#F0F0F0",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 20px 0px 30px",
                }}
                className="button-wrapper"
              >
                <Button
                  onClick={() => {
                    setTab(1);
                  }}
                  className={tab === 1 ? "clickedTab" : "notClickedTab"}
                >
                  <span className="commontypography">Training Details</span>
                </Button>
                <Button
                  disabled={mandateUnique > 0 && tabVisible ? false : true}
                  onClick={() => {
                    setTab(2);
                  }}
                  className={tab === 2 ? "clickedTab" : "notClickedTab"}
                >
                  <span className="commontypography">Sections</span>
                </Button>
                {/* <Button
                  // disabled={dissabled}
                  disabled={mandateUnique > 0 && tabVisible ? false : true}
                  // disabled={localStorage.getItem("mandateId") ?? true}
                  onClick={() => {
                    setTab(3);
                  }}
                  className={tab === 3 ? "clickedTab" : "notClickedTab"}
                >
                  <span className="commontypography">Training Material</span>
                </Button> */}

                <Button
                  // disabled={localStorage.getItem("mandateId") ?? true}
                  disabled={mandateUnique > 0 && tabVisible ? false : true}
                  // disabled={dissabled}
                  onClick={() => {
                    setTab(4);
                  }}
                  className={tab === 4 ? "clickedTab" : "notClickedTab"}
                >
                  <span className="commontypography">Quiz Configurations</span>
                </Button>

                <Button
                  // disabled={localStorage.getItem("mandateId") ?? true}
                  disabled={
                    mandateUnique > 0 && tabVisible && quizDisabled
                      ? false
                      : true
                  }
                  // disabled={quizDisabled}
                  onClick={() => {
                    setTab(3);
                  }}
                  className={tab === 3 ? "clickedTab" : "notClickedTab"}
                >
                  <span className="commontypography">Questions Bank</span>
                </Button>
              </Box>
            </Card>
            {tab === 1 && (
              <CreateMandateTraining
                mandateData={mandateData}
                dissabled={dissabled}
                setDisabled={setDisabled}
                loadingScreen={loadingScreen}
                setTabVisible={setTabVisible}
              />
            )}
            {tab === 2 && (
              <MandatoryIntroduction
                mandateData={mandateData}
                dissabled={dissabled}
                setDisabled={setDisabled}
                loadingScreen={loadingScreen}
              />
            )}
            {/* {tab === 3 && <MandateMaterial />} */}
            {tab === 3 && <AdminMandateQuestionsUpload />}
            {tab === 4 && (
              <MandateQuiz
                setQuizDisabled={setQuizDisabled}
                mandateQuiz={quizDisabled}
              />
            )}
          </>
          {/* ) */}
          {/* } */}
        </Box>
      )}
    </>
  );
};

export default MandatoryTrainingLayout;
