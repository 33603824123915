import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./schedule.css";
import { Custom } from "../../../components/Custom/Custom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MyForm, Form } from "../../../components/MyForm";
import Notification from "../../../components/Custom/Notification";
import { API } from "../../../Networking/API";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const TrainingMaterial = (props) => {
  const { scheduleId, sessionData } = props;
  const schedTMTopic = sessionData.topic;
  const [fileMerge, setFileMerge] = useState("");
  const [desp, setDesp] = useState();
  const [modename, setModename] = useState();
  const [available, setAvailable] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [deletescheduleId, setDeleteScheduleId] = useState();
  const [deletetrainingmaterialId, setDeleteTrainingMaterialId] = useState();
  const [open, setOpen] = useState(false);

  const initialValues = {
    description: "",
    source: "",
    modname: "",
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const SheetJSFT = ["pdf,ppt"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  useEffect(() => {
    getScheduleById(scheduleId);
  }, [scheduleId]);

  const getScheduleById = async (id) => {
    setAvailable(true);
    API.getAdminTrainingMaterial(id)
      .then(async ({ response }) => {
        if (response.data.api_status === 200) {
          const trainmaterialdata = response.data.data[0].training_material;
          const updatedData = await DataTableHandler.AdminTrainingMaterial(
            trainmaterialdata
          );
          setTableData(updatedData);
        }
        if (response.data.api_status === 404) {
          setTableData([]);
        }
      })
      .catch(function (error) {});
  };

  const columns = [
    { field: "id", headerName: "S.No", hide: true },
    { field: "trainingName", headerName: "Training Name", hide: true },
    {
      field: "trainingMaterial",
      headerName: "Training Material",
      flex: 1,
      headerClassName: "training-mat-header",
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <a
                href={params.row.trainingMaterial}
                target="_blank"
                style={{ color: "#02215b", textDecoration: "none" }}
                rel="noreferrer"
              >
                {params.row.trainingName}
              </a>
            </div>
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: "right",
      sortable: false,
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <Tooltip title="Delete">
              <IconButton aria-label="edit" size="small">
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleDeleteInpre(params)}
                />
              </IconButton>
            </Tooltip>
            <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseInByCancel}
              BackdropProps={{
                style: {
                  background: "#00000022",
                  boxShadow: "0px 0px 0px 0px !important",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                    {/* "{questions}" */}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseInByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    // marginRight:"30px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    handleCloseDelete(
                      deletescheduleId,
                      deletetrainingmaterialId
                    )
                  }
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const rows = [
    { id: 1, trainingMaterial: "Snow" },
    { id: 2, trainingMaterial: "Lannister" },
    { id: 3, trainingMaterial: "Sandhosh" },
    { id: 4, trainingMaterial: "Stark" },
  ];

  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("description" in inputValues)
      code.description =
        inputValues.description || desp ? "" : "Description is required";
    if ("modname" in inputValues)
      code.modname =
        inputValues.modname || modename ? "" : "Material Name is required";
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const handleCloseDelete = (paramPre, paramSchId) => {
    setOpen(false);
    API.deleteAdminTrainingMaterial(paramPre, paramSchId).then(({ result }) => {
      const response = result;
      if (response.api_status === 200) {
        getScheduleById(scheduleId);
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Deleted Successfully",
          type: "success",
        });
      } else {
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };

  const handleCloseByCancel = () => {
    resetForm();
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteScheduleId(params.row.schedule_pk);
    setDeleteTrainingMaterialId(params.row.material_pk);
    setOpen(true);
  };

  const handleSubmitButton = () => {
    if (validate()) {
      API.addAdminTrainingMaterial(
        scheduleId,
        fileMerge,
        values.description,
        schedTMTopic,
        values.modname
      ).then(({ response }) => {
        if (response.api_status === 200) {
          getScheduleById(scheduleId);
          setNotify({
            isOpen: true,
            message: response.data[0].msg,
            type: "success",
          });
          resetForm();
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong",
            type: "error",
          });
        }
      });
    }
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                }}
                variant="h6"
              >
                Material Name
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  *
                </span>
              </Typography>
              <span>
                <Custom.Input
                  name="modname"
                  placeholder="Material Name"
                  value={modename || values.modname}
                  onChange={handleInputChange}
                  error={errors.modname}
                  size="small"
                />
              </span>
            </div>
          </Grid>

          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                }}
                variant="h6"
              >
                Upload Training Material{" "}
                <span
                  style={{ color: "red", fontSize: "12px", fontWeight: 400 }}
                >
                  *
                </span>
              </Typography>
              <span style={{ marginLeft: "-7px" }}>
                <input
                  type="file"
                  name="source"
                  class="upload-box"
                  accept={SheetJSFT}
                  style={{
                    fontSize: "0.8em",
                    fontFamily: "Poppins",
                  }}
                  onChange={handleChange}
                />
              </span>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "11px",
                  // fontWeight: 600,
                  marginTop: "3px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                }}
                variant="p"
              >
                Only .pdf .ppt format allowed
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                }}
                variant="h6"
              >
                Description
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  *
                </span>
              </Typography>
              <span>
                {/* <Custom.Input
                  name="description"
                  placeholder="Description"
                  value={desp || values.description}
                  onChange={handleInputChange}
                  error={errors.description}
                  size="small"
                  multiline
                  rows={7}
                  style={{
                    maxWidth: "100%",
                    minWidth: "100%",
                    borderRadius: "4px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    color: "#0A0A0A",
                    fontSize: "16px",
                    marginTop: "10px",
                  }}
                /> */}
                <textarea
                  style={{
                    width: "100%",
                    height: "17vh",
                    boxSizing: "border-box",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    paddingLeft: "12px",
                    paddingTop: "9px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    color: "#0A0A0A",
                    fontSize: "14px",
                  }}
                  name="description"
                  className="description_in_training_material"
                  placeholder="Description"
                  onChange={handleInputChange}
                  value={desp || values.description}
                  error={errors.description}
                  // disabled={showEdit}
                  multiline
                  size="medium"
                />
                {errors.description !== "" ? (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.description}
                  </span>
                ) : null}
              </span>
            </div>
          </Grid>
        </Grid>
        {tableData.length > 0 ? (
          <>
            <Box sx={{ marginTop: "40px" }}>
              <DataTable
                rows={tableData}
                columns={columns}
                // pageSize={4}
                rowHeight={40}
                autoHeight
                style={{ backgroundColor: "white" }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              style={{
                marginTop: "10px",
                backgroundColor: "#fcfbfb",
                marginTop: "10px",
                color: "#9a9a9a",
                height: "20vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              No Training Material found. Please Add Training Material
            </Box>
          </>
        )}

        <Box sx={{ marginTop: "30px", display: "flex", justifyContent: "end" }}>
          <ReusableButton
            onClick={handleCloseByCancel}
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "10px",
              marginBottom: "25px",
              textTransform: "none",
            }}
          ></ReusableButton>
          <ReusableButton
            onClick={handleSubmitButton}
            className="addbutton"
            type="submit"
            size="large"
            variant="contained"
            buttonName="Submit"
            style={{
              backgroundColor: "#fb2485",
              marginBottom: "25px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 30px",
              borderRadius: "4px",
              textTransform: "none",
            }}
          ></ReusableButton>
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default TrainingMaterial;
