import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PinkFilled from "../../../assets/Images/checkPink.svg";
import TickWhite from "../../../assets/Images/tickwhite.svg";
import ReactLoading from "react-loading";
import { Box, CircularProgress, Typography } from "@mui/material";
import { preRequisiteCompletion } from "../../../helper/userHelper";
import completedCheck from "../../../assets/Images/completedCheck.svg";
import uniticonuncheck from "../../../assets/Images/uniticonuncheck.png";
import { API } from "../../../Networking/API";

export default function CheckboxList({
  list,
  selected,
  setSelected,
  unitId,
  courseId,
  preRequisite,
  setUnitdata,
  getUnitDetails,
}) {
  const selectedObject = list.find((x) => x.id === selected);
  const [loading, setLoading] = React.useState(false);
  const [totalSeconds, setTotalSeconds] = React.useState();
  const [currentSeconds, setCurrentSeconds] = React.useState();
  const [percentage, setPercentage] = React.useState(0);
  const [iscompleted, setIsCompelted] = React.useState(true);

  React.useEffect(() => {
    if (list.length <= 0) {
      setLoading(true);
    } else {
      if (localStorage.getItem(unitId + "currentSeconds" + selectedObject.pk)) {
        setCurrentSeconds(
          parseInt(
            localStorage.getItem(unitId + "currentSeconds" + selectedObject.pk)
          )
        );
        setTotalSeconds(selectedObject?.duration * 60);
      } else {
        setTotalSeconds(selectedObject?.duration * 60);
        setCurrentSeconds(selectedObject?.duration * 60);
      }
      setLoading(false);
    }
  }, [list]);
  React.useEffect(() => {
    const next = preRequisite?.find((item, index) => index === selected + 1);
    const completed = preRequisite?.find((item, index) => index === selected);
    const isCompleted =
      preRequisite.length > 1
        ? preRequisite.find((x) => x.id === selected)
        : preRequisite[0];
    if (!isCompleted?.isCompleted) {
      totalSeconds > 0 &&
        currentSeconds > 0 &&
        setTimeout(() => setCurrentSeconds(currentSeconds - 1), 1000);
      setPercentage(((totalSeconds - currentSeconds) / totalSeconds) * 100);
      if (percentage === 100) {
        setIsCompelted(false);
        preRequisiteCompletion(
          next ? next.duration : completed.duration,
          next ? next?.pre_req_pk : completed.pre_req_pk,
          unitId,
          courseId,
          completed.pre_req_pk,
          setUnitdata
        );
        getUnitDetails();
        postPrerequisiteTrack();
        localStorage.removeItem(
          "currentSeconds" + selectedObject.pk + courseId
        );
        localStorage.removeItem(unitId + "currentSeconds" + selectedObject.pk);
      }
    } else {
      setIsCompelted(true);
      localStorage.removeItem("currentSeconds" + selectedObject.pk + courseId);
    }
  }, [courseId, currentSeconds, list, percentage, totalSeconds, unitId]);

  const postPrerequisiteTrack = () => {
    API.TrainingMaterialTrack(unitId, courseId ? courseId : null).then(
      ({ response }) => {
        if (response.api_status === 200) {
          
        }
      }
    );
  };

  React.useEffect(() => {
    if (currentSeconds !== undefined && iscompleted) {
      localStorage.setItem(
        unitId + "currentSeconds" + selectedObject.pk,
        currentSeconds
      );
    }
  }, [currentSeconds]);

  React.useEffect(() => {
    const isCurrent = list.findIndex((x) => x.isCurrent === true);
    if (!loading && isCurrent > 0) {
      setSelected(isCurrent);
    }
  }, [loading]);

  const handleToggle = (value) => () => {
    setSelected(value.id);
  };

  let previousRecords = [];
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {loading ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {list.map((value, index) => {
            previousRecords.push({
              isCompleted: value?.isCompleted,
            });
            let _visible =
              index === 0
                ? true
                : index > 0 && previousRecords[index - 1].isCompleted
                ? true
                : false;
            const timer = list[selected];
            return (
              <>
                <ListItem
                  key={value}
                  style={{
                    width: "20vw",
                    paddingRight: "2%",
                    padding: 0,
                    margin: 0,
                    backgroundColor: selected === value.id ? "#02215B" : "#fff",
                    color: selected === value.id ? "#fff" : "#000",
                    borderBottom: "1.5px solid #F3F3F3",
                    cursor: _visible
                      ? "pointer !important"
                      : "default !important",
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    disableRipple={true}
                    onClick={_visible ? handleToggle(value) : null}
                    sx={{
                      cursor: _visible
                        ? "pointer !important"
                        : "default !important",
                      background: _visible ? "" : "#fff",
                    }}
                    dense
                  >
                    <ListItemIcon
                      style={{ minWidth: "30px", position: "relative" }}
                    >
                      {!selectedObject.isCompleted &&
                      percentage < 100 &&
                      selected === value.id ? (
                        <>
                          <CircularProgress
                            variant="determinate"
                            sx={{ color: "#FB2485" }}
                            thickness={3}
                            size={25}
                            value={percentage}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={5}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <img
                              src={TickWhite}
                              alt=""
                              style={{ zindex: 1000 }}
                            />
                          </Box>
                        </>
                      ) : (
                        <img
                          src={
                            selected === value.id
                              ? PinkFilled
                              : _visible
                              ? completedCheck
                              : uniticonuncheck
                          }
                          alt=""
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText id={value.id}> {value.name} </ListItemText>
                  </ListItemButton>
                </ListItem>
              </>
            );
          })}
        </>
      )}
    </List>
  );
}
