import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { Custom } from "../../../components/Custom/Custom";
import { InputLabel } from "@material-ui/core";
import "./mandatoryTraining-layout.css";
import ReusableButton from "../../../components/Custom/button/button";
import { useFormik } from "formik";
import {
  CreateMandateTrainingSchema,
  CreateUnitValidation,
} from "../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../../../styles/styles";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { API } from "../../../Networking/API";
import { DropDownHandler } from "../../../handler/dropDownHandler";
import { TimeDuration, ActiveSatus } from "../../../constants/adminCreateUnit";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Notification from "../../../components/Custom/Notification";
import { TechnologyConst } from "../../../constants/adminCreateUnit";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ReactLoading from "react-loading";
import DeletePopUp from "../masterData-layout/masterDataModal/deletePopUp";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CreateMandateTrainingHelper } from "../../../helper/mandatoryHelper";
import { HandleTagChange } from "../../../helper/unitHelper";

const CreateMandateTraining = (props) => {
  const styles = SxStyles.styles;
  let { mandateData, dissabled, setDisabled, loadingScreen } = props;
  const [isDisable, setIsDisabled] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState([]);
  const [duration, setDuration] = useState(TimeDuration);
  const [status, setStatus] = useState(ActiveSatus);
  const [isCreate, setIsCreate] = useState(false);
  const [deleteModal, setDeletModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [draggableData, setDraggableData] = useState([]);
  const mandateId = window.localStorage.getItem("mandateId");
  const [tagValue, setTagValue] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [selectedTag, setselectedTag] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loaderForSaveLaterUnit, setloaderForSaveLaterUnit] = useState(false);
  const [loaderForPublishUnit, setloaderForPublishUnit] = useState(false);

  let navigate = useNavigate();
  let st = {
    "& .MuiOutlinedInput-input": {
      padding: "10px 10px",
    },
  };

  const formik = useFormik({
    initialValues: {
      trainingName: "",
      attempts: "",
      duration: draggableData,
      activeDate: new Date(),
      aboutSession: "",
      Difficulty: "",
      Technology: "",
      Tag: [],
      passPercentage: "",
    },
    validationSchema: CreateMandateTrainingSchema,
    onSubmit: (values) => {
      handleUnitCreation();
    },
  });

  useEffect(() => {
    
    formik.values.trainingName = mandateData.topic;
    formik.values.Difficulty = mandateData?.levelDetails?.id; //need to change the respective property
    formik.values.Technology = mandateData.technology; //need to change the respective property
    formik.values.passPercentage = mandateData.pass_percentage;
    //selectedTag();
    formik.values.attempts = mandateData.attempts;
    formik.values.activeDate = mandateData.active_Date
      ? mandateData.active_Date
      : new Date();
    formik.values.aboutSession = mandateData.description;
    if (mandateData?.tagDetails) {
      let SelectedTagName = mandateData?.tagDetails.map((TagValue) => {
        return TagValue.id;
      });
      
      let TagValue = allTag.filter((data) => SelectedTagName.includes(data.id));
      
      setselectedTag(TagValue);
    }
  }, [mandateData]);

  
  /*********State for AddCourse Unit */

  useEffect(() => {
    getDifficultyLevels();
    getselectedTags();
    getTrainingMaterial();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("mandateId")) {
  //     if (mandateData) {
  //       setLoader(false);
  //     } else {
  //       setLoader(true);
  //     }
  //   }
  // }, [mandateData]);

  useEffect(() => {
    if (!mandateData) {
      setLoader(false);
    } else {
      setIsDisabled(true);
      setLoader(true);
    }
  }, [mandateData]);

  const getDifficultyLevels = () => {
    API.getDifficultyLevel().then(({ response }) => {
      const DiffcultyData = response.data.data;
      setDifficultyLevel(DropDownHandler(DiffcultyData));
    });
  };

  const getTrainingMaterial = () => {
    API.getAdminTrainingMaterial(mandateId).then(({ response }) => {
      // setDone(false);
      if (response.data.api_status === 200) {
        const data = response.data.data;
        const FinalFormData = data.map((each, index) => ({
          // id: each.material_pk,
          duration: each.duration,
        }));
        var sum = 0;
        FinalFormData.forEach((value) => {
          sum = sum + value.duration;
        });
        setDraggableData(sum);
      } else {
        setDraggableData([]);
      }
    });
  };
  const handleTagChange = (value) => {
    setselectedTag(value);
    
    HandleTagChange(value, formik, setTagValue, allTag);
  };
  const getselectedTags = () => {
    API.getAllTags(true).then(({ response }) => {
      const TagData = response.data.data.map((TagValue) => {
        return { id: TagValue.id, title: TagValue.tag_name };
      });
      setAllTag(TagData);
    });
  };

  const handleUnitCreation = () => {
    CreateMandateTrainingHelper(
      formik,
      setIsDisabled,
      setNotify,
      setIsCreate,
      isCreate,
      setDisabled,
      navigate,
      setloaderForSaveLaterUnit,
      setloaderForPublishUnit,
      props.setTabVisible,
      selectedTag
    );
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const HandleDeleteUnit = async () => {
    setDeletModal(false);
    navigate("/home/courses");
  };

  const cancelUnit = async () => {
    if (!isDisable) {
      setDeletModal(true);
    } else {
      navigate("/home/courses");
    }
  };

  let renderButton;

  if (mandateId === null || isDisable === false) {
    renderButton = (
      <ReusableButton
        className="addbutton"
        type="submit"
        size="medium"
        variant="contained"
        endIcon={
          !loaderForSaveLaterUnit ? (
            ""
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )
        }
        buttonName={!loaderForSaveLaterUnit ? "Save for Later" : "Saving..."}
        style={
          !loaderForPublishUnit
            ? styles.saveForLaterBtn
            : styles.saveForLaterBtnDisabled
        }
        disabled={loaderForPublishUnit ? true : false}
      ></ReusableButton>
    );
  } else if (mandateId !== null || isDisable === true) {
    renderButton = (
      <ReusableButton
        className="addbutton"
        size="medium"
        variant="contained"
        style={styles.saveForLaterBtn}
        buttonName="Edit"
        onClick={() => {
          setTimeout(function () {
            setIsDisabled(false);
            props.setTabVisible(true);
          }, 500);
        }}
      ></ReusableButton>
    );
  }

  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          padding: "40px 0px 40px 20px",
          marginBottom: "60px",
        }}
      >
        {/* {Object.keys(mandateData).length === 0 ? (
          <Box
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : ( */}
        <form onSubmit={formik.handleSubmit}>
          <Grid container style={{ marginLeft: "14px" }}>
            <Grid
              item
              xs={12}
              sm={5}
              lg={2.1}
              md={2}
              style={{ marginRight: "22px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Training Name
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>
              <ReusableInputfield
                name="trainingName"
                id="trainingName"
                disabled={mandateId === null ? false : isDisable}
                value={formik.values.trainingName}
                onChange={formik.handleChange}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "10px 14px" },
                  },
                }}
                error={
                  formik.touched.trainingName &&
                  Boolean(formik.errors.trainingName)
                }
                helperText={
                  formik.touched.trainingName && formik.errors.trainingName
                }
                // disabled={isDisable}
                style={st}
                className="UnitName"
                variant="outlined"
                margin="normal"
                type="text"
                size="small"
                placeholder="Training Name"
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.2}
              md={2}
              sm={5}
              style={{ marginRight: "34px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Number of Attempts
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>

              <ReusableInputfield
                name="attempts"
                id="attempts"
                value={formik.values.attempts}
                onChange={formik.handleChange}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "10px 14px" },
                  },
                }}
                error={
                  formik.touched.attempts && Boolean(formik.errors.attempts)
                }
                helperText={formik.touched.attempts && formik.errors.attempts}
                disabled={mandateId === null ? false : isDisable}
                style={st}
                className="UnitName"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                placeholder="no of attempts"
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.2}
              md={3}
              sm={5}
              style={{ marginRight: "34px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Difficulty Level
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>
              <Custom.ReusableDropdown
                // disabled={isDisable}
                name="Difficulty"
                id="Difficulty"
                placeholder="Select Difficulty"
                displayEmpty
                options={difficultyLevel}
                onChange={formik.handleChange}
                value={formik.values.Difficulty}
                className="batchmuipopober"
                size="small"
                style={{
                  height: 40,
                  fontFamily: "Poppins",
                }}
                error={
                  formik.touched.Difficulty && Boolean(formik.errors.Difficulty)
                }
                helperText={
                  formik.touched.Difficulty && formik.errors.Difficulty
                }
              ></Custom.ReusableDropdown>
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.2}
              md={3}
              sm={5}
              style={{ marginRight: "34px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Technology
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>
              <Custom.ReusableDropdown
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0.92px solid #cfcbcb !important",
                    },
                }}
                // disabled={isDisable}
                blurOnSelect
                name="Technology"
                id="Technology"
                displayEmpty
                placeholder="Select Technology"
                options={TechnologyConst}
                onChange={formik.handleChange}
                value={formik.values.Technology}
                className="batchmuipopober"
                size="small"
                style={{
                  height: 40,
                  fontFamily: "Poppins",
                }}
                error={
                  formik.touched.Technology && Boolean(formik.errors.Technology)
                }
                helperText={
                  formik.touched.Technology && formik.errors.Technology
                }
              ></Custom.ReusableDropdown>
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.1}
              md={2}
              sm={5}
              style={{ marginRight: "18px", marginTop: "20px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Duration
              </InputLabel>
              <ReusableInputfield
                name="duration"
                id="duration"
                placeholder="Duration"
                disabled={true}
                size="small"
                // value={formik.values.Duration}
                value={draggableData}
                type="number"
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "10px 12px", width: "90%" },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.8}
              md={4}
              sm={5}
              style={{ marginTop: "18px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Active Date
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>
              <Custom.DatePicker
                name="activeDate"
                // id="activeDate"
                style={{
                  width: "19vw",
                }}
                value={formik.values.activeDate}
                onChange={formik.handleChange}
                // disabled={disabled}
                size="small"
                error={
                  formik.touched.activeDate && Boolean(formik.errors.activeDate)
                }
                helperText={
                  formik.touched.activeDate && formik.errors.activeDate
                }
                disabled={mandateId === null ? false : isDisable}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.8}
              md={4}
              sm={5}
              style={{ marginTop: "20px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Pass Percentage
              </InputLabel>
              <ReusableInputfield
                type="text"
                size="medium"
                className="questanddurationclass"
                name="passPercentage"
                id="passPercentage"
                placeholder="In %"
                style={{ width: "100%" }}
                value={formik.values.passPercentage}
                onChange={formik.handleChange}
                // onKeyDown={(e) => {
                //   // Prevent the default behavior of arrow keys (38: up arrow, 40: down arrow)
                //   if (e.keyCode === 38 || e.keyCode === 40) {
                //     e.preventDefault();
                //   }
                // }}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "8px 14px" },
                  },
                }}
                error={
                  formik.touched.passPercentage &&
                  Boolean(formik.errors.passPercentage)
                }
                helperText={
                  formik.touched.passPercentage && formik.errors.passPercentage
                }
                disabled={mandateId === null ? false : isDisable}
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12} style={{ marginTop: "30px" }}>
              <InputLabel className="InputLabel" style={styles.label}>
                About Session
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>
              <ReusableInputfield
                rows={4}
                disabled={mandateId === null ? false : isDisable}
                displayEmpty
                placeholder="About Session"
                style={{ width: "99.6%" }}
                className="description_in_Pre-requisite"
                name="aboutSession"
                id="aboutSession"
                onChange={formik.handleChange}
                value={formik.values.aboutSession}
                multiline
                size="medium"
                error={
                  formik.touched.aboutSession &&
                  Boolean(formik.errors.aboutSession)
                }
                helperText={
                  formik.touched.aboutSession && formik.errors.aboutSession
                }
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    style: {
                      resize: "auto",
                      height: "100%",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              sm={11.3}
              md={4.5}
              xs={8}
              lg={4.5}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Add Tag
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>

              <Autocomplete
                multiple
                limitTags={2}
                id="Tag"
                name="Tag"
                options={allTag}
                // disabled={isDisable}
                value={selectedTag}
                getOptionLabel={(option) => option.title}
                onChange={(e, value) => {
                  // if (selectedTag.length < 4) {
                  handleTagChange(value);
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.Tag && Boolean(formik.errors.Tag)}
                    helperText={formik.touched.Tag && formik.errors.Tag}
                    placeholder="Select tag"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box style={styles.buttonContainer}>
            <Box style={{ marginRight: "40px" }}>
              <ReusableButton
                onClick={cancelUnit}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={styles.cancelButton}
                disabled={
                  loaderForSaveLaterUnit || loaderForPublishUnit ? true : false
                }
              ></ReusableButton>

              {renderButton}

              {/* {mandateId === null || isDisable === false ? (
                <>
                  <ReusableButton
                    className="addbutton"
                    type="submit"
                    size="medium"
                    variant="contained"
                    endIcon={
                      !loaderForSaveLaterUnit ? (
                        ""
                      ) : (
                        <CircularProgress
                          size={20}
                          style={{ color: "white" }}
                        />
                      )
                    }
                    buttonName={
                      !loaderForSaveLaterUnit ? "Save for Later" : "Saving..."
                    }
                    style={
                      !loaderForPublishUnit
                        ? styles.saveForLaterBtn
                        : styles.saveForLaterBtnDisabled
                    }
                    disabled={loaderForPublishUnit ? true : false}
                  ></ReusableButton>
                </>
              ) : isDisable === true && mandateId !== null ? (
                <>
                <ReusableButton
                  className="addbutton"
                  size="medium"
                  variant="contained"
                  style={styles.saveForLaterBtn}
                  buttonName="Edit"
                  onClick={() => {
                    setTimeout(function () {
                      setIsDisabled(false);
                    }, 500);
                  }}
                ></ReusableButton>
                </>
              ) : null } */}

              {/* {isDisable ? (
                <ReusableButton
                  className="addbutton"
                  size="medium"
                  variant="contained"
                  style={styles.saveForLaterBtn}
                  buttonName="Edit"
                  onClick={() => {
                    setTimeout(function () {
                      setIsDisabled(false);
                    }, 500);
                  }}
                ></ReusableButton>
              ) : (
                <ReusableButton
                  className="addbutton"
                  type="submit"
                  size="medium"
                  variant="contained"
                  endIcon={
                    !loaderForSaveLaterUnit ? (
                      ""
                    ) : (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )
                  }
                  buttonName={
                    !loaderForSaveLaterUnit ? "Save for Later" : "Saving..."
                  }
                  style={
                    !loaderForPublishUnit
                      ? styles.saveForLaterBtn
                      : styles.saveForLaterBtnDisabled
                  }
                  disabled={loaderForPublishUnit ? true : false}
                ></ReusableButton>
              )} */}

              <ReusableButton
                onClick={() => {
                  setIsCreate(false);
                }}
                type="submit"
                size="large"
                variant="contained"
                endIcon={
                  !loaderForPublishUnit ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForPublishUnit ? "Publish" : "Publishing"}
                style={
                  !loaderForSaveLaterUnit && mandateId
                    ? styles.createBtn
                    : styles.createBtnDisabled
                }
                disabled={loaderForSaveLaterUnit || !mandateId ? true : false}
              ></ReusableButton>
            </Box>
          </Box>
          <DeletePopUp
            deleteModal={deleteModal}
            deleteModalClose={deleteModalClose}
            HandleDeleteUnit={HandleDeleteUnit}
          />
        </form>
        {/* )} */}
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};
export default CreateMandateTraining;
