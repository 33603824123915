export const UserProfile = {
  ADMIN: "Admin",
  MENTOR: "Mentor",
  TRAINER: "Trainer",
  TRAINEE: "Trainee",
  USER: "User",
};



export const ConnectQuery = `SELECT+name,email+from+User+where+id=`;
