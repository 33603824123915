/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { API } from "../../../../../../Networking/API";
import Cookies from "js-cookie";
import { Grid, Box, Button, DialogContentText } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactLoading from "react-loading";
import ReusableButton from "../../../../../../components/reusable-button/reusable-button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import hourGlass from "../../../../../assets/Images/Hourglass.svg";
import CompleteUnit from "../../../../../../assets/Images/completeUnit.png";
import Failed from "../../../../../../assets/Images/failedO.svg";
import "../../mandatoryTraningModule.css";

import MandateQuizHeader from "./mandate-user-quiz-header";
import { useNavigate } from "react-router-dom";

const MandateUserQuizQuestions = ({
  quizdata,
  trainingId,
  userUnitTrackId,
  courseId,
  unitId,
  setQuizStarted,
  ClearLocalStorage,
}) => {
  const [topicName, setTopicName] = useState();
  const [question, setQuestion] = useState([]);
  const [optionsOpted, setOptionsOpted] = useState("");
  const [value, setValue] = useState();
  const [questIndex, setQuestIndex] = useState(0);
  const [questId, setQuestId] = useState();
  const [quizId, setQuizId] = useState();
  const [userQuizId, setUserQuizId] = useState();
  const [results, setResults] = useState();
  const [done, setDone] = useState(undefined);
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [visited, setVisited] = useState(0);
  const [answered, setAnswered] = useState([]);
  const [quizNumber01, setQuizNumber01] = useState(
    quizdata ? quizdata[0].quizNumber : 0
  );
  const [errorStatus, setErrorStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState([]);
  const [timerOver, setTimerOver] = useState(false);
  const [sucesspopup, setSucesspopup] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [requiredPercentage, setRequiredPercentage] = useState();
  const [achievedPercentage, setAchievedPercentage] = useState();
  const [passpercentage, setPassPercentage] = useState();
  const [totalAttempts, setTotalAttempts] = useState();
  const [totalQuetions, setTotalQuetions] = useState();
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState();
  const [showCompletePopUp, setShowCompletePopup] = useState(false);
  const [showQuizTimeOut, setQuizTimeOut] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (quizNumber01 !== undefined) {
      getAllQuestions(quizNumber01);
    }
  }, []);

  const handleChange = (event, index) => {
    setValue(event.target.value);
    setOptionsOpted(event.target.value);
    setAnswered([...answered, index]);
  };

  useEffect(() => {
    if (question.length > 0) setQuizTimeOut(true);
  }, [timerOver]);

  const getAllQuestions = async (quizNumber01) => {
    try {
      API.getUserAllQuestions(quizNumber01, userUnitTrackId, trainingId).then(
        ({ response }) => {
          console.log(response, "responseofallquestions");
          if (response.api_status === 200) {
            setRes(false);
            setStart(true);
            setDone(true);
            let quiztimer;
            const quizId = response.data[0].schedule_details.user_qz_id;
            if (response.data[0].remianing_duration !== null) {
              const quizRemainingTime = response.data[0].remianing_duration
                .toString()
                .split(".");
              console.log(quizRemainingTime, "quizremain");
              let remainingSeconds = "00";
              if (quizRemainingTime[1] === undefined) {
                remainingSeconds = "00";
              } else {
                remainingSeconds = `${quizRemainingTime[1]}`;
              }
              quiztimer = {
                Minutes: `${quizRemainingTime[0]}`,
                Seconds: remainingSeconds ?? 0,
              };
            } else {
              quiztimer = {
                Minutes: `${response.data[0].countdown}`,
                Seconds: "00",
              };
            }
            localStorage.setItem(
              "QuizId" + quizId + trainingId,
              JSON.stringify(quiztimer)
            );
            setQuestion(response.data[0].questions);
            setQuizId(response.data[0].schedule_details.user_qz_id);
            setQuestId(response.data[0].questions[0].question_pk);
            setUserQuizId(response.data[0].schedule_details.user_qz_id);
            setTopicName(response.data[0].schedule_details.topic);
            setOptionsOpted(response.data[0].questions[0].userOption);
            setAnswered(response.data[0].userOptionIdx);
          } else if (response.api_status === 404) {
            setDone(true);
            setRes(true);
            setErrorMessage(response.error[0].error_msg);
          } else if (response.api_status === 400) {
            setDone(true);
            setRes(true);
            setErrorMessage(response.error[0].error_msg);
            setErrorStatus(true);
          } else if (response.api_status === 502) {
            setDone(true);
            setRes(true);
            setErrorMessage(response.error[0].error_msg);
          } else {
            setDone(true);
            setRes(true);
            setErrorMessage(response.error[0].error_msg);
          }
        }
      );
    } catch (e) {
      console.log("something went wrong!", e);
      setRes(true);
    }
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr1 = getUniqueListBy(question, "question");

  const onClickPrevious = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 - 1);
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserOnclick(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        console.log(response, "responseofprev");
        // "here, if you click previous you will get previous qstn option along with selected option"
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    await API.putUserOnclick(question[param1 - 1].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    setDone(true);
  };

  const onClickNext = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 + 1);
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserOnClickNext(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        console.log("responseofnext", response);
        setQuestIndex(questIndex + 1);
        if (response.api_status === 200) {
          nextQuestion(question[param1 + 1].question_pk, id);
          setShow(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const nextQuestion = async (question_pk, id) => {
    await API.putUserOnClickNext(question_pk, id)
      .then(({ response }) => {
        console.log(response, "nextresponse");
        setDone(true);

        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex + 1);
        if (response.api_status === 404) {
          setShow(false);
        }
        setShow(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleClickOpenDelModal = (e, id, param) => {
    setShow(false);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const onClickSubmit = async (e, id, param) => {
    e.preventDefault();
    Cookies.remove("quizdata");
    apiCall(id, param);
    setQuizTimeOut(false);
    setOpenModal(false);
    setDone(false);
    localStorage.removeItem(`IsFinalQuizStarted_${userUnitTrackId}`);
  };

  const handleCloseCompletePopup = () => {
    setShowCompletePopup(false);
    localStorage.removeItem("QuizId" + quizId + trainingId);
    localStorage.removeItem("minsecs" + quizId + trainingId);
    setQuizStarted(false);
    localStorage.removeItem(`IsFinalQuizStarted_${userUnitTrackId}`);
  };

  const handleCompleteUnit = () => {
    setShowCompletePopup(false);
    localStorage.removeItem("QuizId" + quizId + trainingId);
    localStorage.removeItem("minsecs" + quizId + trainingId);
    setQuizStarted(false);
    localStorage.removeItem(`IsFinalQuizStarted_${userUnitTrackId}`);
    // if (courseId !== null) {
    //   navigate("/user/courses");
    // } else {
    navigate(`/user/mandatoryTraining/${userUnitTrackId}`);
    ClearLocalStorage();
    // }
  };

  const apiCall = async (id, param) => {
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserAPICall(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        console.log(response, "submitresponse");
        if (response.api_status === 200) {
          const ResultsOfQuiz = async (id) => {
            const { response } = await API.getUserQuizResults(id);
            console.log(response, "response data");
            setDone(true);
            // setAchievedPercentage(response.data.data[0].percentage);
            // setTotalQuestionsData(response.data.data[0].total_questions);
            // setCorrectAnswers(response.data.data[0].corect_answers);
            // setPassPercentage(response.data.data[0].pass_score);
            if (response.data.api_status === 200) {
              // setShowCompletePopup(true);
              const trackingResponse = await API.MandatoryTrainingMaterialTrack(
                trainingId,
                userUnitTrackId
              );
              console.log(trackingResponse, "TrackingResponse");
              if (trackingResponse.response.api_status === 400) {
                setShowCompletePopup(true);
                // setSucesspopup("");
                setSucesspopup(0);
                setTotalQuetions(trackingResponse.response.data.total_qns);
                setTotalCorrectAnswers(
                  trackingResponse.response.data.correct_ans
                );
                setTotalAttempts(trackingResponse.response.data.no_of_attempts);
                setAchievedPercentage(
                  trackingResponse.response.data.percentage
                );
                setRequiredPercentage(
                  trackingResponse.response.data.required_percentage
                );
              } else if (trackingResponse.response.api_status === 200) {
                // setSucesspopup("");
                setSucesspopup(1);
                setShowCompletePopup(true);
                setTotalAttempts(trackingResponse.response.data.no_of_attempts);
                setTotalQuetions(trackingResponse.response.data.total_qns);
                setTotalCorrectAnswers(
                  trackingResponse.response.data.correct_ans
                );
                setAchievedPercentage(
                  trackingResponse.response.data.percentage
                );
                setRequiredPercentage(
                  trackingResponse.response.data.required_percentage
                );
              }
            }
          };
          ResultsOfQuiz(id);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {!showCompletePopUp ? (
        <>
          {!done ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {!showCompletePopUp ? (
                <>
                  {errorMsg.length === 0 ? (
                    <>
                      {arr1.map((data, index) => {
                        const questionname = data.question;
                        data.options.selectedOption = data.userOption;
                        data.options.questPk = data.question_pk;
                        // eslint-disable-next-line no-lone-blocks
                        {
                          if (index === questIndex) {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={11.5}
                                  style={{
                                    backgroundColor: "white",
                                    padding: "0px !important",
                                    height: "auto",
                                    borderBottomLeftRadius: "10px",
                                    marginTop: "6px",
                                  }}
                                >
                                  <Grid
                                      item
                                      xs={2}
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ marginRight: "20px" }}>
                                        <b>{`${index + 1}/${arr1.length}`}</b>
                                      </div>
                                      <div>
                                        <MandateQuizHeader
                                          Id={`${quizId}${trainingId}`}
                                          setTimer={setTimer}
                                          setFinish={setTimerOver}
                                        />
                                      </div>
                                    </Grid>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "10px",
                                    }}
                                  >
                                      <b>
                                        {" "}
                                        {questionname.replace(/�/g, " ")}{" "}
                                      </b>
                                  </Grid>

                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={optionsOpted}
                                    onChange={(e) => {
                                      handleChange(e, index);
                                    }}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {[data.options].map((opt) => {
                                      return (
                                        <>
                                          {opt.a ? (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                <Grid item xs={1}></Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    value={opt.a.id}
                                                    control={<Radio />}
                                                    label={opt.a.a.replace(/�/g, " ")}
                                                  />
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                              </Grid>
                                            </>
                                          ) : (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ height: "60px" }}
                                              ></Grid>
                                            </>
                                          )}
                                          {opt.b ? (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                <Grid item xs={1}></Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    value={opt.b.id}
                                                    control={<Radio />}
                                                    label={opt.b.b.replace(/�/g, " ")}
                                                  />
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                              </Grid>
                                            </>
                                          ) : (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ height: "60px" }}
                                              ></Grid>
                                            </>
                                          )}
                                          {opt.c ? (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                <Grid item xs={1}></Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    value={opt.c.id}
                                                    control={<Radio />}
                                                    label={opt.c.c.replace(/�/g, " ")}
                                                  />
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                              </Grid>
                                            </>
                                          ) : (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ height: "60px" }}
                                              ></Grid>
                                            </>
                                          )}
                                          {opt.d ? (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                <Grid item xs={1}></Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "start",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    value={opt.d.id}
                                                    control={<Radio />}
                                                    label={opt.d.d.replace(/�/g, " ")}
                                                  />
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                              </Grid>
                                            </>
                                          ) : (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ height: "60px" }}
                                              ></Grid>
                                            </>
                                          )}
                                        </>
                                      );
                                    })}
                                  </RadioGroup>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Grid item xs={1}>
                                      {index === 0 ? null : (
                                        <ReusableButton
                                          size="large"
                                          variant="outlined"
                                          className="candidateDltepageCancelBttn"
                                          buttonName="Previous"
                                          style={{
                                            width: "98px",
                                            height: "44px",
                                            flexGrow: 0,
                                            padding: "13px 32px 13px 33px",
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAalign: "center",
                                            color: "#000936",
                                            padding: "8px 12px",
                                            borderRadius: "4px",
                                            textTransform: "none",
                                            border: "1px solid #000936",
                                          }}
                                          onClick={(e) => {
                                            onClickPrevious(
                                              e,
                                              quizId,
                                              questIndex,
                                              data.question_pk
                                            );
                                            setDone(false);
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={1}>
                                      {index + 1 < arr1.length ? (
                                        <ReusableButton
                                          size="large"
                                          variant="contained"
                                          className="candidateDltepageDeleteBttn"
                                          buttonName="Next"
                                          style={{
                                            backgroundColor: "#fb2485",
                                            width: "98px",
                                            height: "44px",
                                            flexGrow: 0,
                                            padding: "13px 32px 13px 33px",
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAalign: "center",
                                            color: "#fff",
                                            padding: "8px 28px",
                                            borderRadius: "4px",
                                            textTransform: "none",
                                          }}
                                          onClick={(e) => {
                                            onClickNext(
                                              e,
                                              quizId,
                                              questIndex,
                                              data.question_pk
                                            );
                                            setDone(false);
                                          }}
                                        ></ReusableButton>
                                      ) : (
                                        <>
                                          <ReusableButton
                                            size="large"
                                            variant="contained"
                                            className="candidateDltepageDeleteBttn"
                                            buttonName="Submit"
                                            style={{
                                              backgroundColor: "#fb2485",
                                              width: "98px",
                                              height: "44px",
                                              flexGrow: 0,
                                              padding: "13px 32px 13px 33px",
                                              fontSize: "16px",
                                              fontWeight: "normal",
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "normal",
                                              textAalign: "center",
                                              color: "#fff",
                                              padding: "8px 28px",
                                              borderRadius: "4px",
                                              textTransform: "none",
                                            }}
                                            onClick={(e) => {
                                              handleClickOpenDelModal(
                                                e,
                                                userQuizId,
                                                data.question_pk
                                              );
                                            }}
                                          ></ReusableButton>

                                          <Dialog
                                            open={openModal}
                                            className="dialogBox"
                                            keepMounted
                                            onClose={handleCloseByCancelModal}
                                            aria-describedby="alert-dialog-slide-description"
                                            BackdropProps={{
                                              style: {
                                                backgroundColor: "black",
                                                opacity: "0.5",
                                                boxShadow:
                                                  "0px 0px 0px 0px !important",
                                              },
                                            }}
                                          >
                                            <DialogContent>
                                              <DialogContentText
                                                id="alert-dialog-slide-description"
                                                style={{ color: "#000" }}
                                              >
                                                Are you sure you want to submit
                                                the quiz?
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingBottom: "30px",
                                              }}
                                            >
                                              <ReusableButton
                                                size="large"
                                                variant="outlined"
                                                className="candidateDltepageCancelBttn"
                                                buttonName="CANCEL"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "normal",
                                                  fontStretch: "normal",
                                                  fontStyle: "normal",
                                                  lineHeight: "normal",
                                                  letterSpacing: "normal",
                                                  textAalign: "center",
                                                  color: "#000936",
                                                  border: "1px solid #000936",
                                                  padding: "7px 20px",
                                                  borderRadius: "4px",
                                                  marginRight: "8px",
                                                  textTransform: "none",
                                                }}
                                                onClick={
                                                  handleCloseByCancelModal
                                                }
                                              />
                                              <ReusableButton
                                                size="large"
                                                variant="contained"
                                                className="candidateDltepageDeleteBttn"
                                                buttonName="OK"
                                                style={{
                                                  backgroundColor: "#fb2485",
                                                  marginRight: "10px",
                                                  fontSize: "16px",
                                                  fontWeight: "normal",
                                                  fontStretch: "normal",
                                                  fontStyle: "normal",
                                                  lineHeight: "normal",
                                                  letterSpacing: "normal",
                                                  textAalign: "center",
                                                  color: "#fff",
                                                  padding: "8px 28px",
                                                  borderRadius: "4px",
                                                  textTransform: "none",
                                                }}
                                                onClick={(e) => {
                                                  onClickSubmit(
                                                    e,
                                                    userQuizId,
                                                    data.question_pk
                                                  );
                                                }}
                                              >
                                                Submit
                                              </ReusableButton>
                                            </DialogActions>
                                          </Dialog>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          }
                        }
                      })}{" "}
                    </>
                  ) : (
                    <h2>Quiz already attempted today, please try again tomorrow.</h2>
                  )}
                </>
              ) : null}
            </>
          )}
        </>
      ) : (
        ""
      )}
      <Dialog
        open={showCompletePopUp}
        className="dialogBoxs"
        keepMounted
        onClose={handleCloseByCancelModal}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <b
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "29px",
                color: "#000000",
                marginBottom: "10px",
              }}
            >
              {sucesspopup == 1 ? <>Congratulations!</> : <>Attempt Failed!</>}
            </b>
            <img
              src={sucesspopup == 1 ? CompleteUnit : Failed}
              alt="congrats=image"
              height="240"
              width="220"
            />
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "12px 0px 0px 0px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "29px",
                    color: "#000000",
                    margin: "8px 0px",
                  }}
                >
                  {sucesspopup === 1
                    ? `You have successfully completed the ${topicName}`
                    : " You need to retake the quiz"}
                </span>

                <br />
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "29px",
                    color: "#000000",
                    margin: "8px 0px",
                  }}
                >
                  Total number of questions: {totalQuetions}{" "}
                </span>
                <br />
                <span
                  style={{
                    fontStyle: "bold",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "29px",
                    color: "#000000",
                    margin: "8px 0px",
                  }}
                >
                  Number of questions correctly answered:
                  {totalCorrectAnswers}
                </span>
                <br />
              </div>

              {/* {`You have successfully completed the ${topicName}`} */}
            </p>

            <span
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "8px 0px",
              }}
            >
              {`Score Achieved: ${Math.round(achievedPercentage)}%`}
            </span>
            <br />
            <span
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "8px 0px",
              }}
            >
              {`Pass Percentage: ${Math.round(requiredPercentage)}%`}
            </span>
            <br />
            <span
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "0px 0px 8px 0px",
              }}
            >
              {`Attempted:  ${totalAttempts}`}
            </span>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            className="candidateDltepageCancelBttn"
            buttonName="Close"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCloseCompletePopup}
          />
          <ReusableButton
            size="large"
            variant="contained"
            className="candidateDltepageDeleteBttn"
            buttonName="Continue"
            style={{
              backgroundColor: "#fb2485",
              marginRight: "10px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 28px",
              borderRadius: "4px",
              textTransform: "none",
            }}
            onClick={(e) => {
              handleCompleteUnit();
            }}
          >
            Submit
          </ReusableButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showQuizTimeOut}
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Quiz Timed Out!! Please Click ok to Submit
          </DialogContentText>
          <DialogActions>
            <ReusableButton
              size="large"
              variant="contained"
              className="candidateDltepageDeleteBttn"
              buttonName="OK"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "10px",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "8px 28px",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={(e) => {
                setShowModal(false);

                onClickSubmit(e, userQuizId, question[visited].question_pk);
                setShow(true);
              }}
            ></ReusableButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default MandateUserQuizQuestions;
