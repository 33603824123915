import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import ReactLoading from "react-loading";
import Header from "./header";
import Questions from "./questions";
import Cookies from "js-cookie";

export default function QuizQuestionsUser() {
  const location = useLocation();
  const [subcatnamedisplay, setSubCatNameDisplay] = useState();
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [done, setDone] = useState(undefined);
  const [finish, setFinish] = useState(false);
  const data = Cookies.get("quizdata");

  if (location.state === null) {
    location.state = JSON.parse(data);
  }

  useEffect(() => {
    setSubCatNameDisplay(location.state.topic);
  }, []);
  const setData = (data) => {
    setStart(data.start);
  };
  const showRes = (data) => {
    setRes(data.res);
  };
  const setFinishVal = (data) => {
    setFinish(data);
  };
  const setCallBack = (data) => {
    setDone(data);
  };
  return (
    <>
      <>
        <Box style={{ padding: "36px" }}>
          <Grid container spacing={0}>
            {!done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Header
                show={!res}
                subcatnamedisplay={subcatnamedisplay}
                start={start}
                callback2={setFinishVal}
              />
            )}
            <Questions
              callback={setData}
              showRes={showRes}
              fin={finish}
              loaded={setCallBack}
            />
          </Grid>
        </Box>
      </>
    </>
  );
}
