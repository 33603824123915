import { Box, Typography, IconButton } from "@material-ui/core";
import * as yup from "yup";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./createUnit.css";
import { Custom } from "../../../../components/Custom/Custom";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import Notification from "../../../../components/Custom/Notification";
import { SxStyles } from "../../../../styles/styles";
import { useFormik } from "formik";
import { QuizValidations } from "../../../../Validation/FieldValidatorSchema";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { API } from "../../../../Networking/API";
import { DeleteUnitHelper } from "../../../../helper/unitHelper";
import { useNavigate } from "react-router";
import DeletePopUp from "../masterDataModal/deletePopUp";
import ReactLoading from "react-loading";
import { InputLabel } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { EditOutlined } from "@mui/icons-material";
import { QuizErrorMessages } from "../../../../constants/errorMsg";

const Quiz = (props) => {
  const { isUnit, mandateQuiz, unitData } = props;
  const styles = SxStyles.styles;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [addedQuizCheck, setAddedQuizCheck] = useState([]);
  const [departmentApiDD, setDepartmentApiDD] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [deleteModal, setDeletModal] = useState(false);
  //const unitId = localStorage.getItem("UnitId");
  let unitId = "";
  if (window.localStorage.getItem("UnitId")) {
    unitId = window.localStorage.getItem("UnitId");
  } else {
    unitId = window.localStorage.getItem("mandateId");
  }
  const [done, setDone] = useState(true);
  const [loaderForSaveLaterQZ, setloaderForSaveLaterQZ] = useState(false);
  const [loaderForPublishQZ, setloaderForPublishQZ] = useState(false);
  const [quizMandate, setQuizMandate] = useState(null);
  const [editopen, setEditOpen] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [editParams, setEditParams] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(null);

  let navigate = useNavigate();

  const ERROR_MSG = {
    QUESTIONS_ERROR: "no_of_questions must be greater than or equal to 1",
    DURATION_ERROR: "countdown must be greater than or equal to 1",
  };

  useEffect(() => {
    getQuizType();
    getQuizData();
  }, []);

  useEffect(() => {
    if (!isUnit) {
      if (mandateQuiz === null) return setQuizMandate(mandateQuiz);
      setQuizMandate(mandateQuiz ? 1 : 0);
    }
  }, [mandateQuiz]);

  const getQuizType = () => {
    API.getAdminQuizType(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        const data = response.data.data;
        const formattedData = data.map((each) => ({
          title: each.quiz_number,
          count: each.questions_count,
          value: each.quiz_number,
        }));
        setTotalQuestions(formattedData[0].count);
        setFieldValue("TotalQuestions", formattedData[0]?.count);
        setDepartmentApiDD(formattedData);
      } else {
        setDepartmentApiDD([]);
      }
    });
  };
  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    // handleChangeQuiz,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      quiz: "",
      numberOfQuestions: "",
      duration: "",
      passPercentage: "",
      description: "",
      TotalQuestions: "",
      isCheckAssignment: "",
    },
    validationSchema: QuizValidations,
    // validationSchema: quizMandate ? QuizValidations : yup.object({}),
    onSubmit: (values) => {
      handleAddQuiz();
    },
  });
  const getQuizData = () => {
    API.getAdminQuizzes(unitId).then(({ response }) => {
      setDone(false);
      if (response.data.api_status === 200) {
        const data = response.data.data;
        setEditOpen(true);

        const formatteddata = data.map((each, index) => ({
          id: each.quizz_pk,
          sno: index + 1,
          quizNumber: each.quiz_number,
          passscore: `${each.pass_score}`,
          noofquestions: each.no_of_questions,
          duration: each.countdown,
          topic_id: each.unit_id,
          description: each.description,
          status: each.status,
        }));
        setAddedQuizCheck(formatteddata);
        if (isUnit) setQuizMandate(formatteddata[0].status);
        setTableData(formatteddata);
        setFieldValue("isCheckAssignment", "");
        setFieldValue("quiz", formatteddata[0].quizNumber);
        setFieldValue("numberOfQuestions", formatteddata[0].noofquestions);
        setFieldValue("duration", formatteddata[0].duration);
        setFieldValue("description", formatteddata[0].description);
      } else {
        setTableData([]);
      }
    });
  };

  useEffect(() => {
    const optionKey = departmentApiDD.filter(
      (data) => data.title === values.quiz
    );
    if (optionKey.length > 0) {
      setFieldValue("quiz", values.quiz);
      setTotalQuestions(optionKey[0].count);
      setFieldValue("TotalQuestions", optionKey[0].count);
    }
  }, [values.quiz]);

  const handleCloseDelete = (id) => {
    setDone(true);
    API.deleteAdminQuizzes(unitId, id).then(({ result }) => {
      const response = result;
      getQuizData();
      setOpen(false);
      if (response.api_status === 200) {
        setEditOpen(null);
        setNotify({
          isOpen: true,
          message: "Quiz Deleted Successfully",
          type: "success",
        });
        setFieldValue("isCheckAssignment", "");
        setFieldValue("quiz", "");
        setFieldValue("numberOfQuestions", "");
        setFieldValue("duration", "");
        setFieldValue("description", "");
        setQuizMandate(null);
        setIsDisabled(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleEdit = (params) => {
    setIsDisabled(false);
    setEditParams(params);
  };
  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const handleDeleteInpre = (params) => {
    setId(tableData[0].id);
    setOpen(true);
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "sno",
      headerName: "S.No",
      width: 20,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "quizNumber",
      headerName: "Quiz",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "passscore",
      headerName: "Pass Percentage",
      width: 300,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      hide: true,
    },
    {
      field: "noofquestions",
      headerName: "No of Questions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "cellsDataAction",
      flex: 1,
      width: 180,
      headerClassName: "training-mat-header",
      headerAlign: "start",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <Tooltip title="Delete">
              <IconButton aria-label="edit" size="small">
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleDeleteInpre(params)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="edit" size="small">
                <EditOutlined
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleEdit(params)}
                />
              </IconButton>
            </Tooltip>
            {/* <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseInByCancel}
              BackdropProps={{
                style: styles.dialogBoxContainer,
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span
                    style={{ fontWeight: "bold", marginLeft: "5px" }}
                  ></span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={styles.cancelButton}
                  onClick={handleCloseInByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={styles.createBtnDialog}
                  onClick={() => handleCloseDelete(id)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog> */}
          </span>
        </>
      ),
    },
  ];

  const handleAddQuiz = () => {
    setDone(true);
    const { quiz, numberOfQuestions, duration, passPercentage, description } =
      values;

    API.addAdminQuizz(
      unitId,
      quiz,
      numberOfQuestions,
      duration,
      passPercentage,
      description,
      isUnit ? quizMandate : null
    ).then(({ response }) => {
      getQuizData();
      resetForm();
      if (response.api_status === 200) {
        setEditOpen(true);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const HandleDeleteUnit = () => {
    DeleteUnitHelper(setNotify, navigate, unitId);

    setDeletModal(false);
  };

  const handleSaveForLaterButton = () => {
    if (quizMandate === undefined || quizMandate === null)
      return setNotify({
        isOpen: true,
        message: "Please Select required field.",
        type: "error",
      });
    if (
      (!values.quiz ||
        !values.numberOfQuestions ||
        !values.duration ||
        !values.description ||
        !values.TotalQuestions) &&
      quizMandate === 1
    ) {
      return setNotify({
        isOpen: true,
        message: "Please fill all mandatory fields.",
        type: "error",
      });
    }
    setloaderForSaveLaterQZ(true);
    API.AdminSaveForLaterBtn(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setloaderForSaveLaterQZ(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterQZ(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    if (quizMandate === undefined || quizMandate === null)
      return setNotify({
        isOpen: true,
        message: "Please Select required field.",
        type: "error",
      });
    if (quizMandate === true || quizMandate === 1) {
      if (addedQuizCheck.length === 0) {
        return setNotify({
          isOpen: true,
          message: "Please add final quiz.",
          type: "error",
        });
      }
    }

    setloaderForPublishQZ(true);
    if (isUnit) {
      API.finalUnitCreation(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishQZ(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishQZ(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    } else {
      API.finalAdminCreationPublish(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishQZ(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishQZ(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };

  const handleChangedValues = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    handleChange({
      target: {
        name: `${e.target.name}`,
        value: numericValue,
      },
    });
  };
  const handleChangeQuiz = async (e) => {
    let _status;
    if (e.target.value === "1") {
      _status = 1;
      // props.setQuizDisabled(_status);
    } else {
      _status = 0;
      //props.setQuizDisabled(_status);
    }
    setQuizMandate(_status);
    if (!isUnit) {
      API.AdminQuizTrainingStatusChange(_status, unitId).then(
        ({ response }) => {}
      );
    }
  };

  const handleEditsubmit = (e) => {
    e.preventDefault();
    if (values.TotalQuestions < values.numberOfQuestions) {
      return setNotify({
        isOpen: true,
        message:
          "Questions to be Presented should not be greater than Total no of questions",
        type: "error",
      });
    }

    if (
      (!values.quiz ||
        !values.numberOfQuestions ||
        !values.duration ||
        !values.description ||
        !values.TotalQuestions) &&
      (quizMandate === 1 || quizMandate === true)
    ) {
      return setNotify({
        isOpen: true,
        message: "Please fill all mandatory fields.",
        type: "error",
      });
    }

    API.editAdminQuizconfiguration(
      tableData[0].topic_id,
      tableData[0].id,
      values.numberOfQuestions,
      values.duration,
      values.description,
      // values.isCheckAssignment,
      values.quiz,
      isUnit ? quizMandate : null
    ).then(({ response }) => {
      if (response.api_status === 200) {
        getQuizData();
        setEditOpen(true);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        if (response.error[0].error_msg === ERROR_MSG.QUESTIONS_ERROR)
          setFieldValue("numberOfQuestions", "");
        if (response.error[0].error_msg === ERROR_MSG.DURATION_ERROR)
          setFieldValue("duration", "");
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
        setEditOpen(true);
      }
    });
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          padding: "40px 0px 40px 20px",
          marginBottom: "60px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container style={{ marginLeft: "14px" }}>
            <Grid item xs={12} style={{ marginBottom: "22px" }}>
              <FormControl>
                <Typography style={styles.label1}>
                  Is Quiz Required
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                {/* {quizMandate && ( */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isCheckAssignment"
                  id="isCheckAssignment"
                  value={quizMandate}
                  style={{ marginLeft: "0px", marginTop: "5px" }}
                  onChange={handleChangeQuiz}
                  // disabled={disableFields}
                  // defaultValue={}
                >
                  <FormControlLabel
                    value={1}
                    className="radiobtn"
                    control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={0}
                    className="radiobtn"
                    control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                    label="No"
                  />
                </RadioGroup>
                {/* )} */}
              </FormControl>
            </Grid>
            {/* {quizMandate ? ( */}
            {/* <> */}
            <Grid
              item
              xs={12}
              sm={5}
              lg={2.1}
              md={2}
              style={{ marginRight: "22px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Select Quiz
              </InputLabel>

              <Custom.ReusableDropdown
                // disabled={
                //   tableData.length > 0
                //     ? isDisabled
                //     : // : editopen === true
                //     quizMandate === "0"
                //     ? isDisabled
                //     : false
                // }
                disabled={isDisabled}
                name="quiz"
                id="quiz"
                displayEmpty
                value={values.quiz}
                options={departmentApiDD}
                onChange={handleChange}
                placeholder="Select Quiz"
                error={touched.quiz && Boolean(errors.quiz)}
                helperText={touched.quiz && errors.quiz}
                size="medium"
                style={styles.dropdownWidth}
              ></Custom.ReusableDropdown>
            </Grid>

            <Grid
              item
              xs={12}
              lg={2.2}
              md={2}
              sm={5}
              style={{ marginRight: "22px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Total No. of Questions
              </InputLabel>

              <ReusableInputfield
                name="TotalQuestions"
                id="TotalQuestions"
                value={totalQuestions}
                size="medium"
                placeholder={"No of questions"}
                disabled={true}
                style={styles.disableInputField}
                className={"inputColor"}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "8.6px 14px" },
                  },
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={2.1}
              md={2}
              sm={5}
              style={{ marginRight: "22px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Questions to be Presented
              </InputLabel>

              <ReusableInputfield
                // disabled={
                //   tableData.length > 0
                //     ? isDisabled
                //     : // : editopen === true
                //     quizMandate === 0
                //     ? isDisabled
                //     : false
                // }
                disabled={isDisabled}
                name="numberOfQuestions"
                id="numberOfQuestions"
                placeholder="Question count"
                size="small"
                type={"text"}
                onChange={(e) => handleChangedValues(e)}
                value={values.numberOfQuestions}
                style={{ height: "5px" }}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "8px 14px" },
                  },
                }}
                error={
                  touched.numberOfQuestions && Boolean(errors.numberOfQuestions)
                }
                helperText={
                  touched.numberOfQuestions && errors.numberOfQuestions
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={2.1}
              md={2}
              sm={5}
              style={{ marginRight: "22px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Quiz Duration
              </InputLabel>

              <ReusableInputfield
                // disabled={
                //   tableData.length > 0
                //     ? isDisabled
                //     : // : editopen === true
                //     quizMandate === 0
                //     ? isDisabled
                //     : false
                // }
                disabled={isDisabled}
                type={"text"}
                size="small"
                className="questanddurationclass"
                minWidth="300px"
                name="duration"
                id="duration"
                placeholder="In Minutes"
                onChange={(e) => handleChangedValues(e)}
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "8px 14px" },
                  },
                }}
                style={styles.inputFleid}
                value={values.duration}
                error={touched.duration && Boolean(errors.duration)}
                helperText={touched.duration && errors.duration}
              />
            </Grid>
            {/* <Grid item xs={12} lg={2} md={1.5} sm={5}>
                  <InputLabel className="InputLabel" style={styles.label}>
                    Pass Percentage
                  </InputLabel>
                  <ReusableInputfield
                    type={"number"}
                    size="medium"
                    className="questanddurationclass"
                    name="passPercentage"
                    id="passPercentage"
                    placeholder="In %"
                    style={{ width: "100%" }}
                    value={values.passPercentage}
                    onChange={handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8px 14px" },
                      },
                    }}
                    error={
                      touched.passPercentage && Boolean(errors.passPercentage)
                    }
                    helperText={touched.passPercentage && errors.passPercentage}
                  />
                </Grid> */}
            <Grid
              item
              sm={11.5}
              md={11.6}
              xs={11.5}
              style={{ marginTop: "30px" }}
            >
              <InputLabel className="InputLabel" style={styles.label}>
                Quiz Instruction
              </InputLabel>
              <ReusableInputfield
                // disabled={
                //   tableData.length > 0
                //     ? isDisabled
                //     : // : editopen === true
                //     quizMandate === 0
                //     ? isDisabled
                //     : false
                // }
                disabled={isDisabled}
                rows={4}
                displayEmpty
                placeholder="Description"
                style={{ width: "98%" }}
                className="description_in_Pre-requisite"
                name="description"
                id="description"
                onChange={handleChange}
                value={values.description}
                multiline
                size="medium"
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    style: {
                      resize: "auto",
                      height: "100%",
                    },
                  },
                }}
              />
            </Grid>
            {/* </> */}
            {/* // ) : (
            //   ""
            // )} */}
          </Grid>
          {/* it for the button  */}
          {/* {quizMandate ? (
            <Grid
              container
              spacing={5}
              style={{ marginBottom: "5px", paddingRight: "20px" }}
            >
              <Grid item xs={12} style={styles.addBtn}>
                {editopen || tableData.length > 0 ? (
                  <ReusableButton
                    disabled={editopen === true ? false : true}
                    size="large"
                    variant="outlined"
                    buttonName={"Edit"}
                    onClick={handleEditsubmit}
                    // type="submit"
                    style={
                      editopen === true ? styles.button : styles.buttonDisabled
                    }
                  ></ReusableButton>
                ) : (
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    buttonName={"Add"}
                    type="submit"
                    style={styles.button}
                  ></ReusableButton>
                )}
              </Grid>
            </Grid>
          ) : (
            ""
          )} */}
          <Grid container spacing={5} style={{ marginBottom: "5px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={1} style={styles.addBtn}>
              {editopen === null ? null : (
                <ReusableButton
                  disabled={editopen === null ? true : false}
                  size="large"
                  variant="outlined"
                  buttonName={"Delete"}
                  onClick={handleDeleteInpre}
                  style={
                    editopen === null ? styles.buttonDisabled : styles.button
                  }
                ></ReusableButton>
              )}
            </Grid>
            <Grid item xs={1} style={styles.addBtn}>
              {editopen === null ? (
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName={"Add"}
                  type="submit"
                  style={styles.button}
                ></ReusableButton>
              ) : (
                // ) : editopen === true ? (
                //   <ReusableButton
                //     disabled={editopen === true ? false : true}
                //     size="large"
                //     variant="outlined"
                //     buttonName={"Edit"}
                //     // onClick={() => setEditOpen(false)}
                //     style={
                //       editopen === true ? styles.button : styles.buttonDisabled
                //     }
                //   ></ReusableButton>
                // ) : (
                <ReusableButton
                  // disabled={editopen === true ? false : true}
                  size="large"
                  variant="outlined"
                  buttonName={"Update"}
                  onClick={handleEditsubmit}
                  style={styles.button}
                ></ReusableButton>
              )}
            </Grid>
          </Grid>
        </form>
        {/* it is for the table rendring  */}
        {/* {quizMandate ? (
          <>
            {done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  height: "50vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                {tableData.length > 0 ? (
                  <>
                    <Box style={{ marginRight: "28px" }}>
                      <DataTable
                        rows={tableData}
                        columns={columns}
                        rowHeight={40}
                        autoHeight
                        style={{
                          backgroundColor: "white",
                        }}
                        hideFooter={true}
                        hideFooterPagination={true}
                        hideFooterSelectedRowCount={true}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={styles.tableErrorMsg}>
                      No Quiz Found. Please Add A Quiz
                    </Box>
                  </>
                )}
              </>
            )}{" "}
          </>
        ) : (
          ""
        )} */}
        <Box style={styles.btnContainer}>
          <Box style={styles.buttonContainer}>
            <Box style={{ marginRight: "40px" }}>
              <ReusableButton
                onClick={() => navigate("/home/courses")}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={styles.cancelButton}
                disabled={
                  loaderForSaveLaterQZ || loaderForPublishQZ ? true : false
                }
              ></ReusableButton>
              <ReusableButton
                onClick={handleSaveForLaterButton}
                className="addbutton"
                size="large"
                variant="contained"
                endIcon={
                  !loaderForSaveLaterQZ ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForSaveLaterQZ ? "Save for Later" : "Saving"}
                style={
                  !loaderForPublishQZ
                    ? styles.saveForLaterBtn
                    : styles.saveForLaterBtnDisabled
                }
                disabled={loaderForPublishQZ ? true : false}
              ></ReusableButton>
              <ReusableButton
                onClick={handleCreateButton}
                size="large"
                variant="contained"
                endIcon={
                  !loaderForPublishQZ ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForPublishQZ ? "Publish" : "Publishing"}
                style={
                  !loaderForSaveLaterQZ
                    ? styles.createBtn
                    : styles.createBtnDisabled
                }
                disabled={loaderForSaveLaterQZ ? true : false}
              ></ReusableButton>
            </Box>
          </Box>
        </Box>

        <Notification notify={notify} setNotify={setNotify} />
        <Dialog
          divider
          open={open}
          maxWidth="sm"
          onClose={handleCloseInByCancel}
          BackdropProps={{
            style: styles.dialogBoxContainer,
          }}
        >
          <DialogTitle></DialogTitle>
          <DialogContent
            style={{ boxShadow: "none" }}
            BackdropProps={{
              style: { boxShadow: "none", transition: "none" },
            }}
          >
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete?
              <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ReusableButton
              size="medium"
              variant="outlined"
              className="qstnUpldPageBttn"
              buttonName="Cancel"
              style={styles.cancelButton}
              onClick={handleCloseInByCancel}
            />
            <Button
              size="medium"
              variant="contained"
              className="qstnUpldPageBttn"
              buttonName="Delete"
              style={styles.createBtnDialog}
              onClick={() => handleCloseDelete(id)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Quiz;
