import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { API } from "../../../Networking/API";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import "./createCourse.css";
import IconButton from "@mui/material/IconButton";
import Notification from "../../../components/Custom/Notification";
import Container from "@mui/material/Container";
import moment, { duration } from "moment";
import Card from "@mui/material/Card";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { Custom } from "../../../components/Custom/Custom";
import { SxStyles } from "../../../styles/styles";
import { useFormik } from "formik";
import { createCourseValidationSchema } from "../../../Validation/FieldValidatorSchema";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { UnitSearchHandler } from "../../../handler/addCourseHandler";
import { GetAllUnitHelper } from "../../../helper/unitHelper";
import { DropDownHandler } from "../../../handler/dropDownHandler";
import { AddUnitDialogBox } from "./addunitDialogBox";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import {
  GetCourseHelper,
  DeleteCourseHelper,
} from "../../../helper/courseHelper";
import {
  AddCourseCheckBoxHandler,
  UnitAddtionHandler,
} from "../../../handler/addCourseHandler";
import { TechnologyConst } from "../../../constants/adminCreateUnit";
import ReactLoading from "react-loading";
import DataTableDrag from "../../../components/Custom/draggable/dataTableDraggable";
import ReactPaginate from "react-paginate";
import { UnitReorderCall } from "../../../helper/unitHelper";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2.5,
  boxShadow: 24,
  p: 4,
};
const courseId = window.localStorage.getItem("courseId");
let st = {
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
};

export const CreateCourse = () => {
  let navigate = useNavigate();
  const styles = SxStyles.styles;
  const [courseData, setCoursedata] = useState([]);
  const [SubmitHandle, SetSubmitHandle] = useState(false);
  const [course, setCourseId] = useState(
    localStorage.getItem("courseId") ? localStorage.getItem("courseId") : []
  );
  const [disabled, setDisabled] = useState(false);
  const [id, setId] = useState("");
  const [date, setActiveDate] = useState();
  const [units, setUnits] = useState([]);
  const [open, setOpen] = useState(false);
  const [addUnitDialog, setAddUnitDialog] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let activeDate;
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(4);
  const [edit, setEdit] = useState(false);
  const [allUnits, setAllUnits] = useState([]);
  const [selectedUnitData, setselectedUnitData] = useState(() => new Set());
  const [checkboxId, setCheckboxId] = useState(() => new Set());
  const [pageLimit, setPageLimit] = useState(1);
  const [courseDifficultyLevel, setCourseDifficultyLevel] = useState("");
  const [alldifficultyLevel, setallDifficultyLevel] = useState([]);
  const [totalUnitData, setTotalUnitData] = useState([]);
  const [courseDuration, setCourseDuration] = useState(0);
  const [removeUnit, setRemoveUnit] = useState(false);
  const [dateValidation, setDateValidation] = useState(false);
  const TodayDate = moment().format("YYYY-MM-DD");
  const currentDate = new Date();
  const [unitSelectedTag, setUnitSelectedTag] = useState("");
  const [draggableData, setDraggableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const UnitId = window.localStorage.getItem("UnitId");
  const dataTableHeader = ["S.No", "UnitName", "Duration", "Status", "Actions"];
  const [done, setDone] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [pageIndexUnit, setUnitPageIndex] = useState(0);
  const [unitPageCount, setUnitPageCount] = useState(0);
  const [loaderForSaveLaterCourse, setloaderForSaveLaterCourse] =
    useState(false);
  const [loaderForPublishCourse, setloaderForPublishCourse] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (Array.isArray(course)) {
      setDisabled(false);
      setIsCreate(true);
    } else {
      handleGetCourse(course);
      setDisabled(true);
    }
    getAllDifficultyLevels();
    getAllUnit();
  }, []);

  const formik = useFormik({
    initialValues: {
      courseName: courseData.length > 0 ? courseData.data.course_name : "",
      technology: courseData.length > 0 ? courseData.data.technology : "",
      difficultyLevel:
        courseData.length > 0 ? courseData.data.courseLevelDetails.id : "",
      description: courseData.length > 0 ? courseData.data.about_course : "",
      activeDate:
        courseData.length > 0
          ? courseData.data.active_date
          : new Date(TodayDate),
      duration: courseData.length > 0 ? courseData.data.duration : 0,
    },
    validationSchema: createCourseValidationSchema,

    onSubmit: (values) => {
      if (edit === false) {
        handleAddCourse(values);
      } else {
        editCourse(values);
      }
      SetSubmitHandle(true);
    },
  });
  useEffect(() => {
    getAllUnit();
  }, [unitPageCount, courseDifficultyLevel, unitSelectedTag]);
  

  useEffect(() => {
    let TotalDuration = 0;
    if (totalUnitData.length > 0) {
      totalUnitData.forEach((TotalSelectedUnitdata) => {
        if (typeof TotalSelectedUnitdata.unitDuration === "string") {
          TotalDuration =
            TotalDuration +
            parseInt(TotalSelectedUnitdata.unitDuration.substring(0, 2));
        } else {
          TotalDuration = TotalDuration + TotalSelectedUnitdata.unitDuration;
        }
      });
      let FinalDraggableData = totalUnitData.map((data) => {
        return {
          id: data.id,
          first: data.unitName,
          second: data.unitDuration,
          third: data.Status,
        };
      });
      setDraggableData(FinalDraggableData);
      setCourseDuration(TotalDuration);
    } else {
      setDraggableData([]);
      setCourseDuration(0);
    }
  }, [totalUnitData]);

  useEffect(() => {
    handleUnitReorder();
  }, [draggableData]);

  useEffect(() => {
    UnitAddtionHandler(
      setPageCount,
      totalUnitData,
      selectedUnitData,
      setTotalUnitData,
      setCourseDifficultyLevel,
      setAddUnitDialog,
      checkboxId
    );
    setRemoveUnit(false);
  }, [removeUnit]);

  useEffect(() => {
    if (courseDuration !== 0) {
      formik.setFieldValue("duration", courseDuration);
    }
    formik.values.duration = courseDuration;
  }, [courseDuration, formik.errors, checkboxId]);

  const newColumns = [
    {
      field: "unitName",
      headerName: "Unit Name",
      width: 320,
      headerAlign: "start",
      headerClassName: "training-mat-header",
      cellClassName: "cellsDataTable",
    },
    {
      field: "unitDuration",
      headerName: "Unit Duration",
      flex: 1,
      headerAlign: "start",
      cellClassName: "cellsDataDuration",
      align: "center",
      headerClassName: "training-mat-header",
    },
    {
      field: "Status",
      headerName: "Status",
      cellClassName: "cellsData",
      flex: 1,
      align: "center",
      headerAlign: "start",
      headerClassName: "training-mat-header",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "cellsDataAction",
      flex: 1,
      width: 180,
      headerClassName: "training-mat-header",
      headerAlign: "start",
      align: "center",
      renderCell: (params) => (
        <div>
          <ReusableButton
            disabled={disabled}
            size="large"
            className="qstnUpldPageBttn"
            onClick={() => {
              deleteUnit(params);
            }}
            startIcon={
              <IconButton aria-label="delete">
                <DeleteOutlineOutlinedIcon
                  color={disabled ? "disabled" : "primary"}
                  fontSize="small"
                />
              </IconButton>
            }
          />
        </div>
      ),
    },
  ];

  const handleUnitReorder = async () => {
    UnitReorderCall(draggableData, course);
  };

  const handleAddCourse = async (values) => {
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    if (values.duration !== 0) {
      const {
        courseName,
        technology,
        difficultyLevel,
        activeDate,
        duration,
        units,
        description,
      } = values;
      await API.adminAddCourse(
        courseName,
        technology,
        courseDuration,
        activeDate,
        description,
        units,
        difficultyLevel,
        totalUnitData,
        draggableData
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setloaderForSaveLaterCourse(false);
          setDisabled(true);
          setCourseId(response.data[0].data.id);
          let id = response.data[0].data.id;
          localStorage.setItem("courseId", id);
          handleGetCourse(response.data[0].data.id);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        } else {
          setloaderForSaveLaterCourse(false);
          setNotify({
            isOpen: true,
            message: "Course not added",
            type: "error",
          });
        }
      });
    } else {
      setloaderForSaveLaterCourse(false);
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const editCourse = async (values) => {
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    if (values.duration !== 0) {
      const {
        courseName,
        technology,
        difficultyLevel,
        activeDate,
        duration,
        units,
        description,
      } = values;
      await API.adminEditCourse(
        courseName,
        technology,
        courseDuration,
        activeDate,
        description,
        units,
        difficultyLevel,
        totalUnitData,
        course,
        draggableData
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setloaderForSaveLaterCourse(false);
          setEdit(false);
          setDisabled(true);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        } else {
          setloaderForSaveLaterCourse(false);
          setNotify({
            isOpen: true,
            message: "Course not added",
            type: "error",
          });
        }
      });
    } else {
      setloaderForSaveLaterCourse(false);
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const handleGetCourse = async (id) => {
    GetCourseHelper(
      setCoursedata,
      checkboxId,
      setCheckboxId,
      setTotalUnitData,
      setCourseDuration,
      setUnits,
      formik,
      id,
      activeDate,
      setActiveDate,
      setDone
    );
  };

  const createCourse = async () => {
    if (formik.values.duration !== 0) {
      setloaderForPublishCourse(true);
      await API.adminCreateCourse(course).then(async ({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishCourse(false);
          setTimeout(function () {
            navigate("/home/courses");
          }, 1000);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
        } else {
          setloaderForPublishCourse(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "error",
          });
        }
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const deleteUnit = async (paramsId) => {
    setPageIndex(0);
    await AddCourseCheckBoxHandler(
      paramsId,
      checkboxId,
      setCheckboxId,
      selectedUnitData,
      setselectedUnitData,
      paramsId
    );
    setRemoveUnit(true);
  };

  const getAllDifficultyLevels = () => {
    API.getDifficultyLevel().then(({ response }) => {
      const DiffcultyData = response.data.data;

      setallDifficultyLevel(DropDownHandler(DiffcultyData));
    });
  };
  const deleteCourse = async () => {
    navigate("/home/courses");
  };

  const dateChange = async (e) => {
    setActiveDate(e.target.value);
  };

  const editPage = async () => {
    setDisabled(false);
    setEdit(true);
  };

  const cancelCourse = async () => {
    if (edit) {
      setOpen(!open);
    } else {
      navigate("/home/courses");
    }
  };

  const getAllUnit = async () => {
    let isSelectedDifficultyLevel = await UnitSearchHandler(
      courseDifficultyLevel
    );
    let isSeletedTag = await UnitSearchHandler(unitSelectedTag);
    GetAllUnitHelper(
      unitPageCount,
      limit,
      setAllUnits,
      setPageLimit,
      isSelectedDifficultyLevel,
      courseDifficultyLevel,
      isSeletedTag,
      unitSelectedTag,
      setLoader
    );
  };

  const HandleCheckboxClick = (selectedId, UnitData) => {
    AddCourseCheckBoxHandler(
      selectedId,
      checkboxId,
      setCheckboxId,
      selectedUnitData,
      setselectedUnitData,
      UnitData
    );
  };

  const handleAddUnitDifficulty = (event) => {
    setPageCount(0);
    setCourseDifficultyLevel(event.target.value);
  };

  const handleAddUnit = () => {
    if (selectedUnitData && selectedUnitData.size > 0) {
      UnitAddtionHandler(
        setUnitPageCount,
        totalUnitData,
        selectedUnitData,
        setTotalUnitData,
        setCourseDifficultyLevel,
        setAddUnitDialog,
        checkboxId
      );
    } else {
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const handleUnitCancel = () => {
    setAddUnitDialog(false);
    setUnitPageCount(0);
    setCourseDifficultyLevel("");
    setUnitSelectedTag("");
    setCheckboxId(() => new Set(totalUnitData.map((data) => data.id)));
    setselectedUnitData(
      () =>
        new Set(
          totalUnitData.map((data) => {
            return {
              id: data.id,
              unitName: data.unitName,
              unitDuration: data.unitDuration,
              Status: data.Status,
              date: "N/A",
              endtime: "N/A",
              trainer: "N/A",
              starttime: "N/A",
            };
          })
        )
    );
  };

  const handleTagChangs = (e) => {
    setUnitPageCount(0);
    setUnitSelectedTag(e.target.value);
  };
  const handlePageClick = (data) => {
    setPageIndex(data.selected);
  };
  const handlePageClickUnit = (data) => {
    setUnitPageIndex(data.selected);
    setUnitPageCount(data.selected);
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box className="batch-wrapper">
            <Box className="batch-header">
              <Grid container spacing={2}>
                <Grid item xs={5.5}>
                  <Box>
                    <div>
                      <ReusableButton
                        onClick={() => navigate("/home/courses")}
                        size="small"
                        variant="contained"
                        buttonName="Back"
                        startIcon={<ArrowBackIosIcon />}
                        style={SxStyles.createCourseStyle.backBtn}
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  style={{ display: "flex", justifyContent: "start" }}
                >
                  {Object.keys(courseData).length > 0 ? (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      Edit Course
                    </span>
                  ) : (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      Create Course
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Container
              component="main"
              maxWidth="xxl"
              style={{
                padding: 0,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Card
                  variant="outlined"
                  sx={SxStyles.createCourseStyle.mainContainerCard}
                >
                  <Box>
                    {Object.keys(courseData).length > 0 ? (
                      <div style={SxStyles.createCourseStyle.createCourseDiv}>
                        {courseData.data.course_name}
                      </div>
                    ) : (
                      <div style={SxStyles.createCourseStyle.createCourseDiv}>
                        Create Course
                      </div>
                    )}
                    <Grid
                      container
                      spacing={1.5}
                      style={SxStyles.createCourseStyle.mainGrid}
                    >
                      <Grid
                        item
                        xs={2.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Create Course
                        </span>
                        <ReusableInputfield
                          name="courseName"
                          id="courseName"
                          disabled={disabled}
                          helperText={
                            formik.touched.courseName &&
                            formik.errors.courseName
                          }
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "10px 14px" },
                            },
                          }}
                          placeholder="Course Name"
                          value={formik.values.courseName}
                          onChange={formik.handleChange}
                          size="small"
                          variant="outlined"
                          error={
                            formik.touched.courseName &&
                            Boolean(formik.errors.courseName)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Technology
                        </span>

                        <Custom.ReusableDropdown
                          disabled={disabled}
                          name="technology"
                          id="technology"
                          displayEmpty
                          placeholder="Select Technology"
                          options={TechnologyConst}
                          onChange={formik.handleChange}
                          value={formik.values.technology}
                          className="batchmuipopober"
                          size="small"
                          style={{
                            height: 42,
                            fontFamily: "Poppins",
                          }}
                          error={
                            formik.touched.technology &&
                            Boolean(formik.errors.technology)
                          }
                          helperText={
                            formik.touched.technology &&
                            formik.errors.technology
                          }
                        ></Custom.ReusableDropdown>
                      </Grid>
                      <Grid
                        item
                        xs={2.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Difficulty Level
                        </span>
                        <Custom.ReusableDropdown
                          disabled={disabled}
                          name="difficultyLevel"
                          id="difficultyLevel"
                          placeholder="Select Difficulty"
                          displayEmpty
                          options={alldifficultyLevel}
                          onChange={formik.handleChange}
                          value={formik.values.difficultyLevel}
                          className="batchmuipopober"
                          size="small"
                          style={{
                            height: 42,
                            fontFamily: "Poppins",
                          }}
                          error={
                            formik.touched.difficultyLevel &&
                            Boolean(formik.errors.difficultyLevel)
                          }
                          helperText={
                            formik.touched.difficultyLevel &&
                            formik.errors.difficultyLevel
                          }
                        ></Custom.ReusableDropdown>
                      </Grid>
                      <Grid
                        item
                        xs={2.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Duration(in mins)
                        </span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          value={courseDuration}
                          size="small"
                          style={st}
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "10px 14px" },
                            },
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          disabled={true}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.duration &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            formik.touched.duration && formik.errors.duration
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Active Date
                        </span>
                        <Custom.DatePicker
                          name="activeDate"
                          id="activeDate"
                          value={formik.values.activeDate}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.activeDate &&
                            Boolean(formik.errors.activeDate)
                          }
                          helperText={
                            formik.touched.activeDate &&
                            formik.errors.activeDate
                          }
                          disabled={disabled}
                          minDate={formik.values.activeDate}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    {course && edit ? (
                      <Modal
                        open={open}
                        onClose={cancelCourse}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            sx={{ marginTop: "3%" }}
                            id="modal-modal-description"
                          >
                            Changes made will be lost. Do you want to proceed?
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "5%",
                            }}
                          >
                            <ReusableButton
                              size="large"
                              variant="outlined"
                              buttonName="No"
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#000936",
                                border: "1px solid #000936",
                                padding: "7px 20px",
                                borderRadius: "4px",
                                marginRight: "8px",
                                textTransform: "none",
                              }}
                              onClick={cancelCourse}
                            />
                            <ReusableButton
                              size="large"
                              variant="contained"
                              buttonName="Yes"
                              style={{
                                backgroundColor: "#fb2485",
                                marginRight: "10px",
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#fff",
                                padding: "8px 28px",
                                borderRadius: "4px",
                                textTransform: "none",
                              }}
                              onClick={deleteCourse}
                            ></ReusableButton>
                          </div>
                        </Box>
                      </Modal>
                    ) : null}

                    <Box style={SxStyles.createCourseStyle.aboutCourseBox}>
                      <h1
                        className="required"
                        style={SxStyles.createCourseStyle.aboutCourseh1}
                      >
                        About Course
                      </h1>
                      <ReusableInputfield
                        rows={4}
                        displayEmpty
                        style={{ width: "100%" }}
                        className="description_in_course"
                        name="description"
                        id="description"
                        disabled={disabled}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        multiline
                        size="medium"
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box style={SxStyles.createCourseStyle.unitsParentBox}>
                    <div style={SxStyles.createCourseStyle.unitsParentDiv}>
                      <span style={SxStyles.createCourseStyle.unitsSpan}>
                        Units
                      </span>
                      <div style={{ width: "auto" }}>
                        <ReusableButton
                          className="addbuttonCourse"
                          size="large"
                          variant="contained"
                          onClick={() => {
                            setAddUnitDialog(true);
                          }}
                          disabled={disabled}
                          buttonName="Add Unit"
                          style={{
                            backgroundColor: disabled ? "grey" : "#fb2485",
                            marginRight: "0px !important",
                            fontSize: "18px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAalign: "center",
                            color: "#fff",
                            padding: "8px 30px",
                            borderRadius: "4px",
                            textTransform: "none",
                          }}
                        >
                          Add Unit
                        </ReusableButton>
                      </div>
                    </div>
                    {draggableData.length !== 0 ? (
                      <DataTableDrag
                        draggableData={draggableData}
                        setDraggableData={setDraggableData}
                        handleDeleteInpre={deleteUnit}
                        pageIndex={pageIndex}
                        pageLimit={limit}
                        link={false}
                        dataTableHeader={dataTableHeader}
                        disabled={disabled}
                      />
                    ) : (
                      <>
                        <Box style={styles.tableErrorMsg}>
                          No Unit has been added
                        </Box>
                      </>
                    )}

                    {draggableData.length > limit ? (
                      <ReactPaginate
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                        pageCount={Math.ceil(draggableData.length / limit)}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        forcePage={pageCount}
                      />
                    ) : null}
                  </Box>
                  <div style={SxStyles.createCourseStyle.addCancelDivCourse}>
                    <ReusableButton
                      size="large"
                      variant="outlined"
                      buttonName="Cancel"
                      onClick={cancelCourse}
                      style={SxStyles.createCourseStyle.cancelButton}
                      disabled={
                        loaderForPublishCourse || loaderForSaveLaterCourse
                          ? true
                          : false
                      }
                    >
                      Cancel
                    </ReusableButton>
                    {disabled ? (
                      <ReusableButton
                        size="large"
                        variant="outlined"
                        buttonName="Edit"
                        style={SxStyles.styles.saveForLaterBtn}
                        onClick={editPage}
                        disabled={loaderForPublishCourse ? true : false}
                      />
                    ) : (
                      <ReusableButton
                        size="large"
                        variant="outlined"
                        onClick={formik.handleSubmit}
                        endIcon={
                          !loaderForSaveLaterCourse ? (
                            ""
                          ) : (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          )
                        }
                        buttonName={
                          !loaderForSaveLaterCourse
                            ? "Save for Later"
                            : "Saving"
                        }
                        style={
                          !loaderForPublishCourse
                            ? styles.saveForLaterBtn
                            : styles.saveForLaterBtnDisabled
                        }
                        disabled={loaderForPublishCourse ? true : false}
                      />
                    )}

                    {!isCreate ? (
                      <ReusableButton
                        onClick={createCourse}
                        className="addbuttonCourse"
                        size="large"
                        variant="contained"
                        endIcon={
                          !loaderForPublishCourse ? (
                            ""
                          ) : (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          )
                        }
                        buttonName={
                          !loaderForPublishCourse ? "Publish" : "Publishing"
                        }
                        style={
                          !loaderForSaveLaterCourse
                            ? styles.createBtn
                            : styles.createBtnDisabled
                        }
                        disabled={loaderForSaveLaterCourse ? true : false}
                      />
                    ) : (
                      <ReusableButton
                        className="addbuttonCourse"
                        size="large"
                        variant="contained"
                        endIcon={
                          !loaderForPublishCourse ? (
                            ""
                          ) : (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          )
                        }
                        buttonName={
                          !loaderForPublishCourse ? "Publish" : "Publishing"
                        }
                        style={
                          loaderForSaveLaterCourse
                            ? styles.createBtn
                            : styles.createBtnDisabled
                        }
                        disabled={!loaderForSaveLaterCourse ? true : false}
                      ></ReusableButton>
                    )}
                  </div>
                </Card>
                <AddUnitDialogBox
                  addUnitDialog={addUnitDialog}
                  handleAddUnitDifficulty={handleAddUnitDifficulty}
                  alldifficultyLevel={alldifficultyLevel}
                  checkboxId={checkboxId}
                  HandleCheckboxClick={HandleCheckboxClick}
                  allUnits={allUnits}
                  pageLimit={pageLimit}
                  handlePageClickUnit={handlePageClickUnit}
                  pageCount={pageCount}
                  handleUnitCancel={handleUnitCancel}
                  handleAddUnit={handleAddUnit}
                  limit={limit}
                  courseDifficultyLevel={courseDifficultyLevel}
                  handleTagChangs={handleTagChangs}
                  setAddUnitDialog={setAddUnitDialog}
                />
              </form>
            </Container>
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      )}
    </>
  );
};
