import React, { useState } from "react";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MyForm, Form } from "../../../components/MyForm";
import Notification from "../../../components/Custom/Notification";
import Cookies from "js-cookie";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import "./batch.css";

const initialValues = {
  batchname: "",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Editpopup(props) {
  const { open, id, handleClose, batchData, getBatchData } = props;
  const [user, setUser] = useState(initialValues);
  const [showField, setShowField] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const jwtToken = Cookies.get("jwt_token");

  const handleOnChange = (e) => {
    setUser({
      ...user,
      departmentName: e.target.value,
    });
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("batchname" in inputValues)
      code.firstname = inputValues.firstname ? "" : "batch Name is required";

    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const handleIdChange = (event) => {
    setUser({ ...user, batchid: event.target.value });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px", overflowY: "hidden !important" }}
        maxWidth="lg"
        className="batch-add-model"
      >
        <Form>
          <DialogTitle>Edit Batches Record </DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                        marginBottom: "8px",
                      }}
                    >
                      Batch Name
                    </span>
                    <span style={{ marginLeft: "" }}>
                      <ReusableInputfield
                        name="firstname"
                        placeholder="Batch Name"
                        value={user.name}
                        onChange={handleIdChange}
                        // error={user.nameStatus}
                        helperText={user.batchname}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                        marginBottom: "8px",
                      }}
                      variant="h6"
                    >
                      Batch Id
                    </Typography>
                    <ReusableInputfield
                      type="number"
                      name="id"
                      value={user.batchid}
                      placeholder="Batch Id"
                      onChange={handleIdChange}
                      helperText={user.forBatchid}
                      size="small"
                    />
                    {/* </span> */}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <ReusableButton
              onClick={handleClose}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={{
                marginRight: "10px",
                color: "#000936",
                border: "1px solid #000936",
                marginBottom: "40px",
                width: "98px",
                height: "38px",
                borderRadius: 4,
              }}
            >
              Cancel
            </ReusableButton>
            <ReusableButton
              size="large"
              variant="contained"
              buttonName="Add"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "30px",
                marginBottom: "40px",
                width: "98px",
                height: "38px",
                borderRadius: 4,
              }}
            >
              Add
            </ReusableButton>
          </DialogActions>
        </Form>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
