export const AcademyCSS = {
  alignSelf: "start",
  textAlign: "start",
  color: "white",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: 25,
  marginTop: "11px",
  marginLeft: "40px",
};
export const InputCss = {
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
};
export const CourseUnitHeader = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#0078D4",
};
export const UnitTopic = {
  fontFamily: "Poppins",
  fontSize: "10px !important",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  display: "flex",
  alignItems: "center",
};
export const TraineeCol = {
  marginLeft: 10,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
};
export const CancelBtn = {
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#000936",
  border: "1px solid #000936",
  padding: "7px 20px",
  borderRadius: "4px",
  marginRight: "10px",
  marginBottom: "25px",
  textTransform: "none",
};
export const AddBtn = {
  backgroundColor: "#fb2485",
  marginRight: "30px",
  marginBottom: "25px",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#fff",
  padding: "8px 30px",
  borderRadius: "4px",
  textTransform: "none",
};
export const DeleteBtn = {
  backgroundColor: "#fb2485",
  marginRight: "10px",
  // marginBottom:"25px",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#fff",
  padding: "8px 28px",
  borderRadius: "4px",
  textTransform: "none",
};
export const OkBtn = {
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#000936",
  border: "1px solid #000936",
  padding: "7px 20px",
  borderRadius: "4px",
  marginRight: "8px",
  textTransform: "none",
};
export const CardCss = {
  minHeight: 55,
  minWidth: "95%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  borderRadius: "10px",
};
export const CanditBtn = {
  backgroundColor: "white",
  color: "#000936",
  textTransform: "none ",
  padding: "6px 16px",
  borderRadius: "4px",
};
export const CandMainDiv = {
  width: "95%",
  textAlign: "center",
  backgroundColor: "white",
  marginLeft: "30px",
  marginRight: "100px",
  borderRadius: "15px",
  marginBottom: "200px",
};

export const GenbtnCss = {
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#000936",
  border: "1px solid #000936",
  padding: "7px 20px",
  borderRadius: "4px",
  marginRight: "8px",
  textTransform: "none",
};
export const CollabHead = {
  fontSize: "1.3750em",
  fontFamily: "Poppins",
  color: "white",
  fontStretch: "normal",
  letterSpacing: "normal",
};

export const AddBatchCancelBtn = {
  backgroundColor: "#fb2485",
  marginRight: "30px",
  marginBottom: "25px",
  fontSize: "16px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAalign: "center",
  color: "#fff",
  padding: "8px 30px",
  borderRadius: "4px",
  textTransform: "none",
};
