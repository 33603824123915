import { React, useState } from "react";
import Button from "@mui/material/Button";
import "./trainer-sessions-details-assessed.css";

export default function List({
  contacts,
  trainerName,
  trainerRole,
  trainerID,
  clickStatus,
}) {
  const [innerText, setInnerText] = useState("");
  const handleClick = (event) => {
    trainerName(event.target.innerText);
    trainerRole(event.target.nextSibling.innerText);
    trainerID(event.target.nextSibling.nextSibling.innerText);
    setInnerText(event.target.innerText);
    clickStatus(true);
  };
  return (
    <div
      className="contact-list"
      style={{
        padding: "12px 12px 12px 12px",
        overflowY: "scroll",
        height: "800px",
      }}
    >
      {contacts === null ? (
        "No Trainees Found"
      ) : (
        <>
          {contacts.map((contact, index) => (
            <>
              <Button
                // className="trainers-list-button"
                key={index + 1}
                id={index + 1}
                onClick={handleClick}
                className={
                  trainerID === contact.user_pk ? "selected" : "NotSelected"
                }
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  borderRadius: "4px",
                  color: "#000",
                  textTransform: "capitalize",
                  padding: "12px 14px",
                  marginTop: "4px",
                }}
              >
                {contact.user_name}
              </Button>
              <span style={{ display: "none" }}>{contact.role_name}</span>
              <span style={{ display: "none" }}>{contact.user_pk}</span>
            </>
          ))}
        </>
      )}
    </div>
  );
}
