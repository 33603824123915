import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import RButton from "../../../components/Custom/button/button";
import { API } from "../../../Networking/API";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import Notification from "../../../components/Custom/Notification";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import CheckboxUnitList from "./user-unit-list";
import { SxStyles } from "../user-styles/styles";
import { useFormik } from "formik";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { feedbackValidations } from "../../../Validation/FieldValidatorSchema";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserCourses(props) {
  const ref = useRef();
  const [isCourseStarted, setIsCourseStarted] = useState(false);
  const [value, setValue] = useState(0);
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);
  const [courseUnits, setCourseUnits] = useState([]);
  const [courseLoading, setCourseLoading] = useState(false);
  const [loadingf, setLoadingF] = useState(undefined);
  const [feedbackStatussub, setFeedbackStatusSub] = useState();
  const [selected, setSelected] = React.useState();
  const [unitDetails, setUnitDetails] = useState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const id = localStorage.getItem("courseId");

  const { values, handleSubmit, errors, handleChange, touched, resetForm } =
    useFormik({
      initialValues: {
        feedback: "",
        radioopt: "",
      },
      validationSchema: feedbackValidations,
      onSubmit: (values) => {
        onSubmitEdit();
      },
    });

  let navigate = useNavigate();

  useEffect(() => {
    getCourseDetailsById(id);
  }, []);

  const getCourseDetailsById = async (id) => {
    try {
      API.getCourseDetailsByCourseId(id).then(({ response }) => {
        setLoadingF(false);
        if (response.data.api_status === 200) {
          setCourseLoading(true);
          setCourseName(response.data.data.course.course_name);
          setIsCourseStarted(response.data.data.course.isCourseStarted);
          setCourseDescription(response.data.data.course.about_course);
          setCourseDuration(response.data.data.course.duration);
          setFeedbackStatusSub(response.data.data.feedback_status);
          const unitsData = response.data.data.course.units;
          const updatedCourseUnits = unitsData.map((unit, index) => ({
            id: unit.id,
            unitName: unit.topic,
            description: unit.description,
            isUnitCompleted: unit.isUnitCompleted,
            isCurrentUnit: unit.isCurrentUnit,
          }));
          setCourseUnits(updatedCourseUnits);
        } else {
          setCourseLoading(true);
        }
      });
    } catch (e) {}
  };

  const onSubmitEdit = async () => {
    const { radioopt, feedback } = values;
    setLoadingF(true);
    try {
      API.postFeed(id, radioopt, feedback).then(({ response }) => {
        resetForm();
        getCourseDetailsById(id);
        const resultInJson = response;
        if (resultInJson.api_status === 200) {
          setNotify({
            isOpen: true,
            message: "FeedBack Submitted",
            type: "success",
          });
        }
        if (resultInJson.api_status === 404) {
          setNotify({
            isOpen: true,
            message: "FeedBack Not Submitted",
            type: "error",
          });
        }
        if (resultInJson.api_status === 500) {
          setNotify({
            isOpen: true,
            message: "Feedback Already Submitted",
            type: "error",
          });
        }
      });
    } catch (e) {}
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {courseLoading ? (
        <>
          <h1 className="training-session-trainee">Course</h1>
          <ReusableButton
            buttonName="Back"
            className="traineebutton"
            startIcon={<ArrowBackIosIcon />}
            style={SxStyles.styles.courseBackBtn}
            onClick={() => navigate("/user/")}
          />
          <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
            <Box>
              <Paper className="paper-box" style={{ borderRadius: "12px" }}>
                <Box className="unit-card-background"></Box>
                <Box className="second-box">
                  <Box
                    className="top-box-second-box"
                    style={{ paddingBottom: "20px" }}
                  >
                    <span className="container1">{courseName}</span>
                    <div>
                      <span className="duration">Duration :</span>
                      <span>&nbsp; {courseDuration}</span>
                    </div>
                  </Box>
                  <>
                    <Box className="inner-div">
                      <div>
                        <p
                          style={{ marginTop: "4px" }}
                          className="course-heading"
                        >
                          <b>About Course</b>
                        </p>
                      </div>
                      <span className="course-text">{courseDescription}</span>
                    </Box>
                  </>
                </Box>
              </Paper>
            </Box>

            <Box>
              <>
                <Box
                  sx={{
                    marginTop: 5,
                    marginBottom: 15,
                    height: "auto",
                    borderRadius: "10px",
                  }}
                >
                  <Box sx={{ borderRight: 2, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Topics"
                        className="tabs-item "
                        style={{ width: "200px", textTransform: "capitalize" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Feedback"
                        className="tabs-item"
                        style={{
                          width: "200px",
                          borderTopRightRadius: "12px",
                          textTransform: "capitalize",
                        }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel
                    value={value}
                    index={0}
                    style={SxStyles.styles.topicsTabPanel}
                  >
                    <>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={3}
                            style={SxStyles.styles.topicsTabLeftGrid}
                          >
                            <CheckboxUnitList
                              list={courseUnits}
                              selected={selected}
                              setSelected={setSelected}
                              courseUnits={courseUnits}
                              setUnitDetails={setUnitDetails}
                              courseLoading={courseLoading}
                              isCourseStarted={isCourseStarted}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={8.5}
                            style={{ padding: "24px 0px 10px 24px" }}
                          >
                            <div>
                              <b>{unitDetails?.topic}</b>
                            </div>
                            <p>{unitDetails?.description}</p>
                            <div style={{ marginTop: "20px" }}>
                              <ReusableButton
                                size="small"
                                variant="contained"
                                buttonName="Start Unit"
                                style={SxStyles.styles.startUnitBtn}
                                onClick={() => {
                                  navigate("/user/courses/units", {
                                    state: { Unitid: selected, courseId: id },
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  </TabPanel>

                  <TabPanel
                    value={value}
                    index={1}
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  >
                    {loadingf ? (
                      <Box
                        sx={{ flexGrow: 1 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40vh",
                        }}
                      >
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#fb2485"}
                          height={100}
                          width={100}
                        />
                      </Box>
                    ) : (
                      <>
                        {feedbackStatussub === 1 ? (
                          <p>You're Feedback Is Submitted Successfully.</p>
                        ) : (
                          <>
                            <form onSubmit={handleSubmit}>
                              <div className="feedBack">
                                <div className="section1-feedback">
                                  <FormControl>
                                    <p style={{ fontFamily: "poppins" }}>
                                      How would you rate this course?
                                    </p>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="radioopt"
                                      value={values.radioopt}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="Outstanding"
                                        control={<Radio />}
                                        label="Outstanding."
                                      />
                                      <FormControlLabel
                                        value="Good"
                                        control={<Radio />}
                                        label="Good."
                                      />
                                      <FormControlLabel
                                        value="Satisfactory"
                                        control={<Radio />}
                                        label="Satisfactory."
                                      />
                                      <FormControlLabel
                                        value="Unsatisfactory"
                                        control={<Radio />}
                                        label="Unsatisfactory."
                                      />
                                    </RadioGroup>
                                    {touched.radioopt && errors.radioopt ? (
                                      <FormHelperText style={{ color: "red" }}>
                                        {errors.radioopt}
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                </div>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        minWidth: "85vw",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <ReusableInputfield
                                        name="feedback"
                                        placeholder="You can write upto 1000 words.."
                                        value={values.feedback}
                                        onChange={handleChange}
                                        size="small"
                                        multiline
                                        rows={6}
                                        displayEmpty
                                        error={
                                          touched.feedback &&
                                          Boolean(errors.feedback)
                                        }
                                        helperText={
                                          touched.feedback && errors.feedback
                                        }
                                        InputProps={{
                                          inputComponent: TextareaAutosize,
                                          inputProps: {
                                            style: {
                                              resize: "auto",
                                              height: "100%",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <div
                                  className="section3-feedback"
                                  style={{ marginRight: "0px" }}
                                >
                                  <RButton
                                    buttonName={"Submit"}
                                    type="submit"
                                    className={"section3-feedback-sub"}
                                    style={SxStyles.styles.feedBackSubmitBtn}
                                  />
                                </div>
                              </div>
                            </form>
                          </>
                        )}
                      </>
                    )}
                  </TabPanel>
                </Box>
              </>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
