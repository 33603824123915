import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import "./training-session-tabs.css";
import gavrav from "../../../assets/Images/Group 390.png";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RButton from "../../../components/Custom/button/button";
import ReusableButton from "../../../components/Custom/button/button";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cookies from "js-cookie";
//import CustomDataGrid from '../../../components/reusable-datagrid/TestGrid';
import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";
// import API from "../../../api/index";
import { API } from "../../../Networking/API";
import { useLocation } from "react-router-dom";
// import ReactLoading from 'react-loading';
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Helmet } from "react-helmet";
import { Custom } from "../../../components/Custom/Custom";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import Notification from "../../../components/Custom/Notification";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Checkbox from "@mui/material/Checkbox";

import { MyForm, Form } from "../../../components/MyForm";

// import { Typography } from '@mui/material';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@mui/material";
const api1 = API.Api1;
const api2 = API.Api2;
const styles = makeStyles((theme) => ({
  disablebtn: {
    "& .Mui-disabled": {
      color: "#ffffff",
    },
  },
}));

const initialValues = {
  feedback: "",
  radioopt: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HackathonTabs(props) {
  const classes = styles();
  const {
    scheduledata,
    assignmentData,
    uploadStatus,
    trainerdata,
    quizArray,
    prerequiste,
    quizdata,
    quizResultStatus,
    startQuizButton,
    feedbackStatus,
    topicName,
    done,
    setViewAfterButtonClick,
    viewAfterButtonClick,
    quizDetails,
    quizDetailsAfterResults,
    trainermaterial,
    getTraineeTabDetails,
    resultLoaded,
    setResultLoaded,
    totalTasks,
    userHid,
  } = props;
  const ref = useRef();
  const [upload, setUpload] = useState(true);
  const [del, setDel] = useState();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(true);
  const [delFileId, setDelFileId] = useState();

  const [tasksData, setTasksData] = useState([]);
  const [setsData, setSetsData] = useState([]);

  const [toggleStatus, setToggleStatus] = useState(0);
  // const [done, setDone] = useState(undefined);
  const jwtToken = Cookies.get("jwt_token");
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [lastModified, setLastModified] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  // const [trainermaterial, SetTrainermaterial] = useState();
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [quizAltButton, setQuizAltButton] = useState(true);
  const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
  const [status, setStatus] = useState(0);
  const [time, setTime] = useState(0);
  const [data, setData] = useState([]);
  const [radio, setRadio] = useState(false);
  const [loadingf, setLoadingF] = useState(undefined);
  const [radioValue, setRadioValue] = useState("");
  const [feedbackStatussub, setFeedbackStatusSub] = useState();
  const [index, setIndex] = useState(0);
  const [taskindex, setTaskIndex] = useState(0);
  const [loadingtab, setloadingtab] = useState(false);
  const [checked, setChecked] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [open, setOpen] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const [message, setMessage] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const location = useLocation();
  const id = location.state.data.id || location.state.data;
  const [file, setFile] = useState({});
  const [files, setFiles] = useState([]);

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    setBtnDisabled(!btnDisabled);
  };

  useEffect(() => {
    getFeedById();
  }, []);

  const getFeedById = async (id) => {
    
    try {
      
      API.getFeed(scheduledata.schedule_pk, scheduledata.trainer_id).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setFeedbackStatusSub(resultInJson.data[0].feedback_status);
            setLoadingF(false);
          }
         
        }
      );
    } catch (e) {
      
    }
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("feedback" in inputValues)
      code.feedback = inputValues.feedback ? "" : "Feedback is required";
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const onSubmitEdit = async (e, id) => {
    e.preventDefault();
    if (radioValue === "") {
      setRadio(true);
    } else {
      setRadio(false);
    }
    if (validate() && radio === false) {
      setLoadingF(true);
      // const raw = JSON.stringify({
      //   schedule_id: scheduledata.schedule_pk,
      //   trainer_id: scheduledata.trainer_id,
      //   performance: radioValue,
      //   comment: values.feedback,
      // });
      // 

      // var requestOptions = {
      //   method: "POST",
      //   headers: {
      //     "aa-token": jwtToken,
      //     "Content-Type": "application/json",
      //   },
      //   body: raw,
      // };
      try {
        // const response = await fetch(
        //   `${api1}/trainee/feedBack-schedule`,
        //   requestOptions
        // );
        API.postFeed(
          scheduledata.schedule_pk,
          scheduledata.trainer_id,
          radioValue,
          values.feedback
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            // getScheduleById(id);
            // setShowPre(false);
            getFeedById();
            setRadioValue("");
            setRadio(false);
            // await getTraineeTabDetails();
            // getFeedById();
            setNotify({
              isOpen: true,
              message: "FeedBack Submitted",
              type: "success",
            });
            // setFeedbackStatus(true);
          }

          if (resultInJson.api_status === 404) {
            // getScheduleById(id);
            // setShowPre(false);

            setRadioValue("");
            setRadio(false);
            setNotify({
              isOpen: true,
              message: "FeedBack Not Submitted",
              type: "error",
            });
          }
          if (resultInJson.api_status === 500) {
            // getScheduleById(id);
            // setShowPre(false);
            setNotify({
              isOpen: true,
              message: "Feedback Already Submitted",
              type: "error",
            });
            setRadioValue("");
            setRadio(false);
          }
        });
      } catch (e) {
        
      }
      resetForm();
    }
  };

  useEffect(() => {
    setloadingtab(true);
    getAssignmentData();
  }, [taskindex]);

  const getAssignmentData = async () => {
    if (totalTasks.length !== 0) {
      
      try {
        // const response = await fetch(
        //   `${api1}/trainee/Hackathon-Trainee`,
        //   requestOptions
        // );
        API.getHackathon(
          totalTasks[taskindex].task_pk,
          totalTasks[taskindex].hackathon_id,
          userHid
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setFiles(resultInJson.data[0].files);
            setTasksData(resultInJson.data[0].tasks);
            // if(resultInJson.data[0].sets){
            setSetsData(resultInJson.data[0].sets);
            // }
            setToggleStatus(resultInJson.data[0].toggle_status);
            setloadingtab(false);
            setDeleteLoading(true);
            setLastModified(resultInJson.data[0].last_update);
            setDel(undefined);
            setStatus(resultInJson.data[0].astmt_status);
            setTime(resultInJson.data[0].remain_time);
            setData(resultInJson.data[0]);
            if (resultInJson.data[0].astmt_status === 0) {
              setSubmissionStatus(false);
            } else {
              setSubmissionStatus(true);
            }
            setLoading(false);
            // setStatusDone(true);
          } else if (resultInJson.api_status === 404) {
            setFiles([]);
            setLoading(false);
            setloadingtab(false);
            // setStatusDone(true);
            setSubmissionStatus(false);

            setTime(resultInJson.error[0].time_remain);
          } else {
            setFiles([]);
            setLoading(false);
            setloadingtab(false);
            // setStatusDone(true);
            setSubmissionStatus(false);
          }
        });
      } catch (e) {
        setloadingtab(false);
        
      }
    } else {
      setloadingtab(false);
    }
  };

  const handleupload = (event) => {
    setFile(event.target.files[0]);
    setUpload(true);
    setErrorStatus(false);
    // 
  };
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setRadio(false);
  };

  const handleNext = () => {
    setErrorStatus(false);
    switch (toggleStatus) {
      case 0:
        if (submissionStatus) {
          setIndex(index + 1);
          setTaskIndex(index + 1);
        } else {
          setMessage(
            "Please Complete Your Current Task To Start The Next One."
          );
          setOpen(true);
        }

        break;
      case 1:
        setIndex(index + 1);
        setTaskIndex(index + 1);
        break;
      default:
        break;
    }
  };

  const handlePrev = () => {
    setErrorStatus(false);
    setIndex(index - 1);
    setTaskIndex(index - 1);
  };

  const handlechangeupload = async (e) => {
    e.preventDefault();
    ref.current.value = "";

    setUpload(false);
    // 
    // var fd = new FormData();
    // 
    // fd.append("hackathonid", tasksData[0].hackathon_id);
    // fd.append("task_id", tasksData[0].task_pk);
    // // fd.append('asn_pk', assignmentData[0].asn_pk);
    // fd.append("files", file);
    // // 
    // setFile({});
    // for (var pair of fd.entries()) {
    //   
    // }
    // const requestOptions = {
    //   method: "POST",
    //   body: fd,
    //   mode: "cors",
    //   headers: { "aa-token": jwtToken },
    // };
    // 
    try {
      if (files.length < 2) {
        // const response = await fetch(
        //   `${api1}/trainee/Hackathon-Trainee`,
        //   requestOptions
        // );
        API.uploadHacakathon(
          tasksData[0].hackathon_id,
          tasksData[0].task_pk,
          file
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setChecked(false);
            setBtnDisabled(true);
            setDel(undefined);
            getAssignmentData();
          } else {
            setErrorMessage(resultInJson.error[0].error_msg);
            setErrorStatus(true);
            setLoading(false);
          }
        });
      }
    } catch (e) {
      
      setErrorMessage("Only .png, .jpg and .jpeg format allowed!");
      setErrorStatus(true);
      setLoading(false);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitAll = async () => {
    // e.preventDefault();
    setOpen(true);
    // const rawData = {};
    // const requestOptions = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     task_pk: tasksData[0].task_pk,
    //     user_task_fk: tasksData[0].user_task_pk,
    //     user_hid_fk: tasksData[0].user_hid_fk,
    //   }),
    //   mode: "cors",
    //   headers: { "aa-token": jwtToken, "Content-Type": "application/json" },
    // };

    try {
      // const response = await fetch(
      //   `${api1}/trainee/task-final-submit`,
      //   requestOptions
      // );

      API.SubmitAllHackthon(
        tasksData[0].task_pk,
        tasksData[0].user_task_pk,
        tasksData[0].user_hid_fk
      ).then(({ response }) => {
        const resultInJson = response;
        if (resultInJson.api_status === 200) {
          setChecked(false);

          setSubmitLoading(false);

          if (index < totalTasks.length - 1) {
            setIndex(index + 1);
            setTaskIndex(index + 1);
          }

          getAssignmentData();
        } else {
          setErrorMessage(resultInJson.error[0].error_msg);
          setErrorStatus(true);
          setSubmitLoading(false);
          setChecked(false);
        }
      });
    } catch (e) {
      
    }
  };

  const handleDelete = async (id) => {
    setDeleteLoading(false);
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("aa-token", jwtToken);

    // const requestOptions = {
    //   method: "DELETE",

    //   mode: "cors",
    //   headers: myHeaders,
    // };
    // 
    try {
      // const response = await fetch(
      //   `${api1}/trainee/Hackathon-Trainee/${id}`,
      //   requestOptions
      // );
      API.deleteHackathon(id).then(({ result }) => {
        const resultInJson = result;
        if (resultInJson.api_status === 200) {
          getAssignmentData();
        }
      });
    } catch (e) {
      
    }
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <>
        <Box
          sx={{
            // bgcolor: "white",
            marginTop: 5,
            marginBottom: 15,
            height: "auto",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ borderRight: 2, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="About Invigilator"
                className="tabs-item "
                style={{ borderTopLeftRadius: "12px" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Hackathon Material"
                className="tabs-item"
                {...a11yProps(1)}
              />

              <Tab label="Hackathon" className="tabs-item" {...a11yProps(3)} />
              <Tab
                label="Feedback"
                className="tabs-item"
                style={{ borderTopRightRadius: "12px" }}
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {trainerdata ? (
              <>
                <div className="about-trainer">
                  <div className="section1-about-trainer">
                    <div className="image-div-about-trainer">
                      <img
                        src={gavrav}
                        style={{ height: "75px" }}
                        alt="trainer image"
                      />
                    </div>
                    <div className="details-about-trainer">
                      <h3 className="name">{trainerdata.user_name}</h3>
                      <p className="desigination">
                        {trainerdata.work_posiotion}
                      </p>
                      <p className="experience">
                        {trainerdata.work_experience} years of experience
                      </p>
                    </div>
                  </div>
                  <div className="section2-about-trainer">
                    <div className="section2-about-trainer-sub">
                      {trainerdata.user_description !== "null" ? (
                        <p>{trainerdata.user_description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="section3-about-trainer">
                    {trainerdata.certificates === null ? (
                      <div className="sectio3-inner-trainer">
                        {trainerdata.certificates !== null ? (
                          <div className="section3-sub-about-trainer">
                            <div className="circular-about-trainer">
                              <span className="number-about-trainer">
                                {trainerdata.certificates}x
                              </span>
                            </div>
                            <div className="description2">
                              <span>Certifications Achieved</span>
                            </div>
                          </div>
                        ) : null}
                        <div className="section3-sub2-about-trainer">
                          <div className="circular-about-trainer">
                            <span className="number2-about-trainer">
                              {trainerdata.sessions_assigned}
                            </span>
                          </div>
                          <div className="description2">
                            <span>Sessions Assigned</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="sectio3-inner-trainer"
                          style={{ marginTop: "30px", marginBottom: "0px" }}
                        >
                          <div className="section3-sub2-about-trainer">
                            <div className="circular-about-trainer">
                              <span className="number2-about-trainer">
                                {trainerdata.sessions_assigned}
                              </span>
                            </div>
                            <div className="description2">
                              <span>Sessions Assigned</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <p style={{ backgroundColor: "white" }}>No Data Found</p>
              </>
            )}
          </TabPanel>

          <>
            <TabPanel
              value={value}
              index={1}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <Box>
                {trainermaterial !== "" || undefined ? (
                  <>
                    <p className="download-text">
                      {trainermaterial[0].description}
                    </p>
                    {trainermaterial.map((item) => (
                      <>
                        {item.url !== undefined ? (
                          <a
                            href={item.url}
                            download
                            target="_blank"
                            className="download-element"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <p>No Material Found</p>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <p style={{ backgroundColor: "white" }}>No Data Found</p>
                  </>
                )}
              </Box>
            </TabPanel>
          </>

          {/* --------------------------------------------------------------------------------------------------------------------- */}

          <>
            <TabPanel
              value={value}
              index={2}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <Box>
                {/* {tasksData.length !== 0 ? ( */}
                <>
                  {loadingtab ? (
                    <>
                      <Box
                        sx={{ flexGrow: 1 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "90vh",
                        }}
                      >
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#fb2485"}
                          height={100}
                          width={100}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {tasksData.length !== 0 ? (
                        <>
                          <div className="assignment">
                            <div className="section1-assignment">
                              <div
                                className="section1-sub-assignment"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p>
                                  <b>{tasksData[0].task_name}</b>
                                </p>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    style={{
                                      width: "25 !important",
                                      minWidth: 10,
                                      marginRight: "6px",
                                      height: 25,
                                      // backgroundColor:  '#fb2485',
                                      backgroundColor:
                                        index === 0 ? "#a9a9a9" : "#fb2485",
                                    }}
                                    disabled={index === 0 ? true : false}
                                    onClick={() => {
                                      // if (index > 0) {
                                      //   setIndex(index - 1);
                                      // }
                                      handlePrev();
                                    }}
                                  >
                                    <IoIosArrowBack color="#ffffff"></IoIosArrowBack>
                                  </Button>
                                  <p>{`${index + 1}/${totalTasks.length}`}</p>
                                  <Button
                                    style={{
                                      width: "25 !important",
                                      minWidth: 10,
                                      marginLeft: "6px",
                                      height: 25,
                                      backgroundColor:
                                        index === totalTasks.length - 1
                                          ? "#a9a9a9"
                                          : "#fb2485",
                                    }}
                                    disabled={
                                      index === totalTasks.length - 1
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      handleNext();
                                    }}
                                  >
                                    <IoIosArrowForward color="#ffffff"></IoIosArrowForward>{" "}
                                  </Button>
                                </div>
                              </div>

                              <hr
                                style={{
                                  borderTop: "none",
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderBottom: "1.5px dotted #666666",
                                  margin: "0px 0px 14px 0px",
                                  opacity: "0.5",
                                }}
                              />

                              <span className="time">Start Time : </span>
                              <span>&nbsp;{tasksData[0].task_start_time}</span>
                              <br />
                              <span className="duration">End Time : </span>
                              <span>&nbsp;{tasksData[0].task_end_time}</span>
                              <br />
                              {/* {tasksData[0].as_description !== null ? (
                                <>
                                  <span className="duration">
                                    Requirement:{" "}
                                    <p>{tasksData[0].as_description}</p>
                                    
                                   
                                  
                                  </span>
                                </>
                              ) : (
                                <p>Attcachment Not Yet Uploaded</p>
                              )} */}
                              <span className="duration"> Requirement: </span>
                              <div
                                className="outer-box-of-task"
                                style={{ display: "flex", padding: "10px 0px" }}
                              >
                                {setsData.length !== 0 ? (
                                  <>
                                    {setsData.map((data) => {
                                      return (
                                        <>
                                          <Box className="buttonBoxTask">
                                            <a
                                              className="attachment"
                                              href={data.resource_link}
                                              target="_blank"
                                              style={{
                                                textDecorationLine: "none",
                                              }}
                                            >
                                              <p className="attachment-title-task">
                                                {data.set_name}
                                              </p>
                                            </a>
                                          </Box>
                                          {/* <Grid container >
                                          <Grid item xs={3}>
                                          <a
                            href={data.resource_link}
                            download
                            target="_blank"
                            className="download-element"
                          >
                                        <span style={{padding:'20px'}}>{data.set_name}</span>
                                        </a>
                                          </Grid>
                                        </Grid>
                                          */}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <p>Attcachment Not Yet Uploaded</p>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="section2-assignment">
                              <Box className="container-section2-assignment">
                                <div className="section2-sub-assignment">
                                  <div className="submission">
                                    <p>Submission status </p>
                                    <p style={{ fontSize: "14px" }}>
                                      <b>
                                        {!submissionStatus
                                          ? "In Progress"
                                          : status === 1
                                          ? "Submitted"
                                          : status == 2
                                          ? "Assessed"
                                          : "Time Out"}
                                      </b>
                                    </p>
                                  </div>
                                  <div className="time-remaining">
                                    <p>Time remaining</p>
                                    <p style={{ fontSize: "14px" }}>
                                      <b>{time}</b>
                                    </p>
                                  </div>
                                  <div className="last-modified">
                                    <p>Last Modified</p>
                                    <p style={{ fontSize: "14px" }}>
                                      <b>
                                        {lastModified !== undefined
                                          ? lastModified
                                          : "-"}
                                      </b>
                                    </p>
                                  </div>
                                  <div className="Score Achived">
                                    <p>Score Achieved</p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <b>
                                        {status === 2
                                          ? `${data.score_achived} of ${data.total_score}`
                                          : "NA"}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </Box>
                            </div>

                            <div className="section3-assignment">
                              <Box>
                                <h3>
                                  <b>Task Attachments</b>
                                </h3>
                              </Box>

                              {/* Upload file and button shown here */}
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 15,
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginTop: -5,
                                    }}
                                  >
                                    <input
                                      type="file"
                                      name="productImage"
                                      className={
                                        !submissionStatus
                                          ? files.length >= 2
                                            ? "upload-box-disabled"
                                            : "upload-box1"
                                          : "upload-box-disabled"
                                      }
                                      disabled={
                                        !submissionStatus
                                          ? files.length >= 2
                                            ? true
                                            : false
                                          : true
                                      }
                                      onChange={handleupload}
                                      ref={ref}
                                      style={{
                                        height: "43px",

                                        fontSize: "0.8em",
                                        fontFamily: "Poppins",
                                        width: "330px",
                                      }}
                                    />

                                    <RButton
                                      buttonName={
                                        !loading ? (
                                          "Upload"
                                        ) : (
                                          <CircularProgress size={16} />
                                        )
                                      }
                                      variant={"contained"}
                                      startIcon={
                                        loading ? null : <UploadIcon />
                                      }
                                      disabled={
                                        !loading
                                          ? !submissionStatus
                                            ? files.length >= 2
                                              ? true
                                              : false
                                            : true
                                          : true
                                      }
                                      className={
                                        !loading
                                          ? !submissionStatus
                                            ? files.length >= 2
                                              ? "button-assignment-disabled"
                                              : "button-assignment"
                                            : "button-assignment-disabled"
                                          : "button-assignment-disabled"
                                      }
                                      onClick={(e) => {
                                        setLoading(true);
                                        handlechangeupload(e);
                                      }}
                                    />
                                  </div>
                                  {errorStatus ? (
                                    <p className="error">{`*${errorMessage}`}</p>
                                  ) : (
                                    <p className="error2">
                                      Only .png, .jpg and .jpeg .docx .pdf
                                      format allowed!
                                    </p>
                                  )}
                                </div>
                              </Box>
                              {/* Upload file and button shown here */}

                              {/* Attachments shown here */}
                              <Box
                                sx={{
                                  marginTop: "30px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={7} style={{ display: "flex" }}>
                                    {files !== undefined
                                      ? files.length !== 0
                                        ? files.map((item, index) => (
                                            <>
                                              <Box className="buttonBox">
                                                <a
                                                  className="attachment"
                                                  href={item.hacklink}
                                                  target="_blank"
                                                  style={{
                                                    textDecorationLine: "none",
                                                  }}
                                                >
                                                  <p className="attachment-title">
                                                    {`Attachment file ${
                                                      index + 1
                                                    }`}
                                                  </p>
                                                </a>
                                                <IconButton
                                                  aria-label="delete"
                                                  color="primary"
                                                  disabled={del === index}
                                                >
                                                  {!submissionStatus ? (
                                                    <>
                                                      <>
                                                        <DeleteOutlineOutlinedIcon
                                                          className="delete-outlined"
                                                          onClick={() => {
                                                            setDel(index);
                                                            handleDelete(
                                                              item.hacklink_pk
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    </>
                                                  ) : (
                                                    <VisibilityOutlinedIcon />
                                                  )}
                                                </IconButton>
                                              </Box>
                                              {/* :null} */}
                                            </>
                                          ))
                                        : null
                                      : null}
                                  </Grid>
                                  <Grid item xs={5}>
                                    {!submissionStatus && files.length > 0 ? (
                                      <Box style={{ textAlign: "right" }}>
                                        <RButton
                                          buttonName={
                                            !SubmitLoading ? (
                                              "Submit"
                                            ) : (
                                              <CircularProgress
                                                size={16}
                                                color="#fff"
                                              />
                                            )
                                          }
                                          variant={"contained"}
                                          className={
                                            btnDisabled
                                              ? "button-assignment3"
                                              : "button-assignment2"
                                          }
                                          disabled={btnDisabled}
                                          onClick={(e) => {
                                            setOpen(true);
                                            setSubmitClick(true);
                                            setMessage(
                                              "Are You Sure You Want To Submit?"
                                            );
                                            // handleSubmitAll(e);
                                          }}
                                        />
                                      </Box>
                                    ) : null}
                                    <Box
                                      style={{
                                        textAlign: "right",
                                        marginRight: "-10px",
                                      }}
                                    >
                                      {!submissionStatus && files.length > 0 ? (
                                        <>
                                          <span className="checkbox-text">
                                            <small>
                                              I Ensure that the above task has
                                              been evaluated by the Invigilator
                                            </small>
                                          </span>
                                          <Checkbox
                                            checked={checked}
                                            // size="small"
                                            onChange={handleChangeCheckbox}
                                            sx={{
                                              color: "#545454",
                                              "&.Mui-checked": {
                                                color: "#fb2485",
                                              },
                                              marginLeft: "-5px",
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 14,
                                              },
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>

                              {/* Attachments shown here */}

                              {/* submit button shown here */}

                              {/* submit button shown here */}

                              {/* Parameters shown here */}
                              {status === 2 ? (
                                <Box style={{ marginTop: "20px" }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Box
                                        style={{
                                          background: "#02215b",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: "10px",
                                          borderTopLeftRadius: "20px",
                                          borderTopRightRadius: "20px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            paddingLeft: "25px",
                                            color: "#fff",
                                          }}
                                        >
                                          Parameters
                                        </span>
                                        <span
                                          style={{
                                            paddingRight: "25px",
                                            color: "#fff",
                                          }}
                                        >
                                          Scores Achieved
                                        </span>
                                      </Box>
                                      {data.score.map((item, index) => {
                                        return (
                                          <>
                                            <ListItem
                                              key={index}
                                              secondaryAction={
                                                <div className={"scoreBox"}>
                                                  {item.score_achieved}
                                                </div>
                                              }
                                              disablePadding
                                            >
                                              <Box
                                                role={undefined}
                                                // onClick={handleToggle(value)}
                                                dense
                                                style={{ maxWidth: "80%" }}
                                              >
                                                <ListItemText
                                                  primary={item.hackparam_name}
                                                  className="parameter-list"
                                                  style={{
                                                    paddingLeft: "20px",
                                                  }}
                                                />
                                              </Box>
                                            </ListItem>
                                            <Divider
                                              style={{
                                                marginLeft: "15px",
                                                marginRight: "15px",
                                                backgroundColor: "#bfbfbf",
                                              }}
                                            />
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Box>
                              ) : null}
                              {/* Parameters shown here */}
                            </div>
                          </div>
                          <Dialog
                            divider
                            open={open}
                            maxWidth="sm"
                            onClose={handleCloseByCancel}
                            BackdropProps={{
                              style: {
                                background: "#00000022",
                                boxShadow: "0px 0px 0px 0px !important",
                              },
                            }}
                          >
                            <DialogTitle></DialogTitle>
                            <DialogContent
                              style={{ boxShadow: "none" }}
                              BackdropProps={{
                                style: {
                                  boxShadow: "none",
                                  transition: "none",
                                },
                              }}
                            >
                              <DialogContentText className="alert-dialog-slide-description">
                                {message}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              {submitClick ? (
                                <RButton
                                  buttonName={"OK"}
                                  className={"section3-feedback-sub"}
                                  onClick={() => {
                                    setSubmitLoading(true);
                                    setBtnDisabled(true);

                                    handleSubmitAll();

                                    handleCloseByCancel();
                                  }}
                                />
                              ) : null}

                              <RButton
                                buttonName={"Close"}
                                className={
                                  submitClick
                                    ? "section4-feedback-sub"
                                    : "section3-feedback-sub"
                                }
                                onClick={() => {
                                  setChecked(false);
                                  setBtnDisabled(true);

                                  handleCloseByCancel();
                                }}
                              />
                            </DialogActions>
                          </Dialog>
                        </>
                      ) : (
                        <>
                          <p style={{ backgroundColor: "white" }}>
                            No Data Found
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
                {/* ) : (
                <>
                 <p style={{ backgroundColor: 'white' }}>No Data Found</p>
                </>
              )} */}
              </Box>
            </TabPanel>
          </>

          {/* --------------------------------------------------------------------------------------------------------------------- */}

          <TabPanel
            value={value}
            index={3}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {loadingf ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                {feedbackStatussub === 1 ? (
                  <p>You're Feedback Is Submitted Successfully.</p>
                ) : (
                  <>
                    <div className="feedBack">
                      <div className="section1-feedback">
                        <FormControl>
                          <p style={{ fontFamily: "poppins" }}>
                            How would you rate this course?
                          </p>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Outstanding"
                              control={<Radio />}
                              label="Outstanding."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Good"
                              control={<Radio />}
                              label="Good."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Satisfactory"
                              control={<Radio />}
                              label="Satisfactory."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Unsatisfactory"
                              control={<Radio />}
                              label="Unsatisfactory."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                          </RadioGroup>
                          {radio ? (
                            <span style={{ color: "red" }}>
                              Atleast One Option Should Be Selected.
                            </span>
                          ) : null}
                        </FormControl>
                      </div>
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minWidth: "85vw",
                              marginTop: "10px",
                            }}
                          >
                            <Custom.Input
                              name="feedback"
                              placeholder="You can write upto 1000 words.."
                              value={values.feedback}
                              onChange={handleInputChange}
                              error={errors.feedback}
                              size="small"
                              multiline
                              rows={6}
                              maxLength={1000}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div
                        className="section3-feedback"
                        style={{ marginRight: "0px" }}
                      >
                        <RButton
                          buttonName={"Submit"}
                          className={"section3-feedback-sub"}
                          onClick={onSubmitEdit}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* } */}
          </TabPanel>
        </Box>
      </>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
