export const LoginErrorMessages = {
  EMAIL_ERROR_MSG: "Enter Correct Email-ID",
  PASSWORD_ERROR_MSG: "Please Enter a Valid Password",
  REQUIRED_EMAIL: "Email is Required",
  REQUIRED_PASSWORD: "Password is Required",
};

export const MaterialsErrorMessage = {
  FILE_NAME_ERR_MSG: "File Name is Requireed",
  VIDEO_LINK_ERR_MSG: "Video Link Or upload file is required",
  FILE_ERR_MSG: "Please select file",
  DESCRIPTION_ERR_MSG: "Description is required ",
  PPT_LINK_ERR_MSG: "PPT link is required",
};
export const CreateCourseError = {
  COURSE_ERROR_MSG: "Course name is Required",
  TECHNOLOGY_ERROR_MSG: "Technology is required",
  DATE_ERROR_MSG: "Active date is required",
  DIFFICULTY_ERROR_MSG: "Difficulty level is required",
  DESCRIPTION_ERR_MSG: "Description is required",
  DURATION_ERR_MSG: "Duration is required",
};

export const AssignmentErrorMessages = {
  ASN_NAME: "Assignment Name required",
  DESCRIPTION_ERR_MSG: "Description is required ",
};

export const AdminCollabErrorMessage = {
  FIRST_NAME: "First Name is required",
  CERTIFICATES_REQUIRED: "Certificates is required",
  CERTIFICATES_VALID: "Please Enter Valid Number of Certification",
  COLLAGE: "College is required",
  DESCRIPTION: "description is required",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_EXIST: "User email already exists",
  EMAIL_VALID: "Email is not valid",
  PHONENO_EXIST: "User Phone already exists",
  PHONENO_VALID: "Phone No is not Valid",
  ROLE: "Role is required",
  BATCH: "Batch is required",
  DESIGNATION: "Designation is required",
  EXPERIENCE: "Experience is required",
  EXPERIENCE_VALID: "Experience is not valid",
  FILE_SELECT_MSG: "Please select a file",
  FILE_VALID: "Only .png, .jpg and .jpeg format allowed!",
};

export const CreateUnit = {
  UNIT_NAME_ERROR: "Please enter Unit Name",
  UNIT_TECHNOLOGY_ERROR: "Please select the Technology",
  UNIT_DIFFICULTY_ERROR: "Please select Difficulty Level",
  UNIT_ACTIVE_STATUS_ERROR: "Please select  status",
  UNIT_TAG_ERROR: "Please select Tag",
  UNIT_DURATION_ERROR: "Please Enter the Duration",
  UNIT_ABOUT_SESSION_ERROR: "Please Fill The Session Details",
  UNIT_PERCENTAGE_ERROR:"Please Enter Percentage",
  UNIT_SELECT_TYPE_ERROR:"Please Select Type",
  UNIT_ATTEMPTS_ERROR: "Please Enter Number of Attempts"
};

export const FileUploadErrorMsg = {
  FILE_SELECT_MSG: "Please select a file",
  FILE_VALID: "Only .png, .jpg and .jpeg format allowed!",
};

export const QuizErrorMessages = {
  QUIZ_ERR_MSG: "Please select type",
  NO_OF_QUS_ERR_MSG: "No of questions is required",
  DURATION_ERR_MSG: "Duration is required",
  PERCENTAGE_ERR_MSG: "Pass percentage is required",
  DESCRIPTION_ERR_MSG: "Description is required ",
};

export const UploadQuestionsErrMsg = {
  File_Err_Msg: "(File is required)",
};

export const FeedbackErrorMsg = {
  RADIO_BTN_ERR_MSG: "Atleast One Option Should Be Selected.",
  FEEDBACK_ERR_MSG: "Feedback is required",
};

export const CreateMandateTrainings = {
  TRAINING_NAME_ERROR: "Please enter Training Name.",
  ATTEMPTS_ERROR: "Please enter the attempts.",
  DATE_ERROR_MSG: "Please select the date.",
  ABOUT_SESSION_ERROR: "Please add the description for training.",
};
