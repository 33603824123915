import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
// import "../user-unit-details.css";
import axios from "axios";
import Cookies from "js-cookie";
import { API } from "../../../../Networking/API";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import MandatoryTrainingTabs from "./mandatory-training-tabs";
import Button from "../../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import UserUnitsTabs from "./user-units-tab";
import RButton from "../../../../components/Custom/button/button";
import Notification from "../../../../components/Custom/Notification";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

export default function MandatoryTraningLayout(props) {
  const quizValuesafterresults = {
    totalquest: "",
    correctquest: "",
    percentagescored: "",
  };

  const quizValues = {
    count: "",
    noOFques: "",
  };

  const something = 1;
  const jwtToken = Cookies.get("jwt_token");
  const [Unitdata, setUnitdata] = useState();
  const [done, setDone] = useState(false);

  const [tabDone, setTabDone] = useState(true);
  const [assignmentData, setAssignmentData] = useState();
  const [trainerdata, setTrainerdata] = useState("");
  const [quizArray, setQuizArray] = useState("");

  const [prerequiste, setPrerequiste] = useState("");
  const [scheduleType, setScheduleType] = useState();

  const [quizdata, setQuizdata] = useState("");
  const [quizResultStatus, setQuizResultStatus] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState();
  const [totalTasks, setTotalTasks] = useState(0);
  const [userHid, setUserHid] = useState("");
  const [startQuizButton, setStartQuizButton] = useState("");
  const [topicName, setTopicName] = useState("");
  const [quizDetails, setQuizDetails] = useState(quizValues);
  const [quizDetailsAfterResults, setQuizDetailsAfterResults] = useState(
    quizValuesafterresults
  );
  const [isUnitStarted, setIsUnitStarted] = useState(false);
  const [trainermaterial, SetTrainermaterial] = useState("");
  const [isUnitLoaded, setIsUnitLoaded] = useState(false);
  const [viewAfterButtonClick, setViewAfterButtonClick] = useState(false);

  const [resultLoaded, setResultLoaded] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [uploadStatus, setUploadStatus] = useState(1);
  const location = useLocation();
  let unitId;
  let courseId;
  let path;
  //   if (localStorage.getItem("UnitId")) {
  //     unitId = localStorage.getItem("UnitId");
  //     courseId = null;
  //     path = "/user/";
  //   } else {
  //     unitId = location.state.Unitid;
  //     courseId = location.state.courseId;
  //     path = "/user/courses";
  //   }
  let navigate = useNavigate();
  const [isLoadTraining, setIsLoadTraining] = useState(false);
  const [isMandateCont, setIsMandateCont] = useState(false);
  const [loader, setLoader] = useState(true);
  const [mandatetrainingData, setMandatetrainingData] = useState([]);
  const [mandateTraining, setMandateTraining] = useState([]);
  let TrackId = window.localStorage.getItem("userUnitTrackId");
  const [OverallSlideData, setOverallSlideData] = useState([]);
  const user_id = Cookies.get("user_pk");

  const { traningId } = useParams();

  useEffect(() => {
    if (traningId) {
      getMandateTrainingData(traningId);
    }
  }, [traningId]);

  const getMandateTrainingData = () => {
    API.getMandatoryTraninigById(traningId, TrackId)
      .then((data) => {
        if (data.response.api_status === 200) {
          let receivedTraningDetails = data.response.data;
          let completedTraningMaterialLength = 0;
          receivedTraningDetails.training_material =
            receivedTraningDetails.training_material.map((material) => ({
              ...material,
              isCompleted: localStorage.getItem(
                `${user_id}-${material.material_pk}_isCompleted`
              )
                ? true
                : false,
            }));

          receivedTraningDetails.training_material.forEach((data) => {
            if (localStorage.getItem(`${data.material_pk}_isCompleted`))
              completedTraningMaterialLength++;
          });
          

          setMandatetrainingData(receivedTraningDetails);
          setMandateTraining(receivedTraningDetails.training_material);
          setOverallSlideData(
            receivedTraningDetails.trainingSlides.map((data) => {
              const localStorageKey = `SlideId_${data.id}_ModuleId_${receivedTraningDetails.id}`;
              const localStorageValue = localStorage.getItem(localStorageKey);

              const isSlideOver = localStorageValue ? true : false;
              return { ...data, isSlideOver };
            })
          );
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      {!done ? (
        <>
          <h1 className="training-session-trainee">
            {mandatetrainingData.topic}
          </h1>
          <Button
            buttonName="Back"
            className="traineebutton"
            startIcon={<ArrowBackIosIcon />}
            style={{ textTransform: "none", color: "#02215b" }}
            onClick={() => navigate("/user/")}
          />
          <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
            <Box>
              <Paper className="paper-box" style={{ borderRadius: "12px" }}>
                <Box className="unit-card-background"></Box>
                <Box className="second-box">
                  <Box
                    className="top-box-second-box"
                    style={{ paddingBottom: "20px" }}
                  >
                    <span className="container1">
                      {mandatetrainingData.topic}
                    </span>
                    <div>
                      <span className="duration">Duration :</span>
                      <span>&nbsp;{mandatetrainingData.duration}</span>
                    </div>
                  </Box>
                  {mandatetrainingData.description !== "" &&
                  mandatetrainingData.description !== null ? (
                    <>
                      <Box className="inner-div">
                        <div>
                          <p
                            style={{ marginTop: "3px" }}
                            className="course-heading"
                          >
                            <b>About Course</b>
                          </p>
                        </div>
                        <span className="course-text">
                          {mandatetrainingData.description}
                        </span>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Paper>
            </Box>

            <Box>
              <MandatoryTrainingTabs
                user_id={user_id}
                mandatetrainingData={mandatetrainingData}
                OverallSlideData={OverallSlideData}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
