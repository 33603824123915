import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./menu-items-trainee.css";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";

export default function MenuItems(props) {
  const { active, hActive } = props;
  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/user/bootcamp/home/"
            className={({ isActive }) =>
              isActive ? "link-active" : hActive ? "link-active" : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Home" placement="right" arrow>
                <HomeIcon />
              </Tooltip>
            </ListItemIcon>
            Dashboard
          </NavLink>
        </ListItemText>
      </ListItem>

      {/* <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/trainee/home/sessiondetails"
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemIcon style={{ color: "#FFF" }}>
            <CalendarMonthIcon />
          </ListItemIcon>
          Session Details
        </NavLink>
      </ListItemText>
    </ListItem> */}
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/user/bootcamp/home/calendardetails"
            className={({ isActive }) =>
              isActive ? "link-active" : active ? "link-active" : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Sessions" placement="right" arrow>
                <CalendarMonthIcon />
              </Tooltip>
            </ListItemIcon>
            Calendar Details
          </NavLink>
        </ListItemText>
      </ListItem>
    </div>
  );
}
