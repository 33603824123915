import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import "./createCourse.css";
import { Custom } from "../../../components/Custom/Custom";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { InputCss, UnitTopic } from "../admin-layout/adminStyles";
import ReactPaginate from "react-paginate";
import { Checkbox } from "@mui/material";
import { SxStyles } from "../../../styles/styles";

export const AddUnitDialogBox = (props) => {
  const {
    addUnitDialog,
    handleAddUnitDifficulty,
    alldifficultyLevel,
    checkboxId,
    HandleCheckboxClick,
    allUnits,
    pageLimit,
    handlePageClickUnit,
    pageCount,
    handleUnitCancel,
    handleAddUnit,
    limit,
    courseDifficultyLevel,
    handleTagChangs,
    setAddUnitDialog,
  } = props;

  const styles = SxStyles.styles;
  const handleDialogClose = () => {
    setAddUnitDialog(false);
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "end",
          },
          "& .MuiPaper-root": {
            height: "98%",
            margin: "0px 0px 1px 0px",
            width: "62%",
          },
        }}
        open={addUnitDialog}
        onClose={handleUnitCancel}
        style={{ overflowY: "hidden !important" }}
      >
        <DialogTitle className="DialogHeader">Add Units</DialogTitle>

        <DialogContent>
          <Box
            sx={{ flexGrow: 1 }}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={InputCss}>Tags/Units</Typography>
                  <span style={{ marginLeft: "-7px", marginTop: "5px" }}>
                    <Custom.Input
                      name="Tags"
                      placeholder="Select Tags"
                      size="small"
                      s
                      onChange={(e) => handleTagChangs(e)}
                    />
                  </span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={InputCss} variant="h6">
                    Difficulty Level
                  </Typography>
                  <span style={{ marginLeft: "-7px", marginTop: "5px" }}>
                    <Custom.ReusableDropdown
                      placeholder="Please Select Difficulty"
                      value={courseDifficultyLevel}
                      displayEmpty
                      Allvalue={"All"}
                      onChange={(e) => handleAddUnitDifficulty(e)}
                      name="difficulty"
                      style={{
                        height: 37,
                        fontFamily: "Poppins",
                      }}
                      options={alldifficultyLevel}
                      size="small"
                    />
                  </span>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography style={InputCss} variant="h6" className={"Units"}>
              Units
            </Typography>
            {allUnits.map((allUnitData) => {
              return (
                <Grid container className="InnerGrid">
                  <Grid style={{ width: "100%" }}>
                    <div className="UnitCard">
                      <Box style={{ display: "flex" }}>
                        <Checkbox
                          checked={checkboxId.has(allUnitData.id)}
                          onChange={() =>
                            HandleCheckboxClick(allUnitData.id, allUnitData)
                          }
                        />
                        <Typography
                          style={UnitTopic}
                          variant="h12"
                          className="uniTopic"
                        >
                          {allUnitData.topic}
                        </Typography>
                      </Box>
                      <Typography className="UnitDuration">
                        {allUnitData.duration} min
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginLeft: "7.3%",
                      }}
                    >
                      {allUnitData.tagDetails?.map((txt, key) => (
                        <Typography
                          key={key}
                          numberOfLines={1}
                          style={styles.UnitTagsStyle}
                        >
                          {txt.tag_name}
                        </Typography>
                      ))}
                    </div>
                    <div className="UnitDescription">
                      <Typography noWrap={true} className="UnitDecpTypo">
                        {allUnitData.description}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              );
            })}
            {allUnits.length <= 0 ? (
              <Box className="NoUnitsDiv">
                <Typography
                  style={UnitTopic}
                  variant="h9"
                  className="NoUnitsPara"
                >
                  No Units Found
                </Typography>
              </Box>
            ) : null}
          </Box>
          {pageLimit > 4 ? (
            <Box>
              <ReactPaginate
                previousLabel={<>&laquo;</>}
                nextLabel={<>&raquo;</>}
                pageCount={Math.ceil(pageLimit / limit)}
                onPageChange={handlePageClickUnit}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                forcePage={pageCount}
              />
            </Box>
          ) : null}
          <Box
            style={{
              display: "flex",
              height: "30%",
              alignItems: "flex-end",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <ReusableButton
              variant="outlined"
              buttonName="Cancel"
              className={"CancelBtn"}
              size="medium"
              style={styles.cancelButton}
              onClick={() => {
                handleUnitCancel();
              }}
            >
              Cancel
            </ReusableButton>

            <ReusableButton
              className={"addbutton"}
              type="submit"
              variant="contained"
              buttonName="Add"
              size="medium"
              style={styles.createBtn}
              onClick={() => handleAddUnit()}
            >
              Add
            </ReusableButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
