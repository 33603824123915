import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { ReusableInputfield } from "../../../components/Custom/inputfield/inputfield";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";

import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "react-router-dom";
import Notification from "../../../components/Custom/Notification";
import cp from "../../../assets/Images/cp.png";
import { useNavigate } from "react-router-dom";
import "./changePassword.css";
import { API } from "../../../Networking/API";
import { useFormik } from "formik";
import { ChangePasswordValidations } from "../../../Validation/FieldValidatorSchema";

const useStyles = makeStyles({
  inputfieldLabel: {
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: 400,
    },
  },
});

export default function UserChangePassword(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();

  const classes = useStyles();

  const { values, handleSubmit, errors, handleChange, touched, resetForm } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: ChangePasswordValidations,
      onSubmit: (values) => {
        handleUpdateSubmit();
      },
    });

  const handleUpdateSubmit = async () => {
    const { oldPassword, newPassword, confirmPassword } = values;
    API.UserChangePassword(oldPassword, newPassword, confirmPassword).then(
      (response) => {
        const data = response;
        resetForm();
        if (data.response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: "Password Reset Successfully",
            type: "success",
          });
          navigate("/user/");
        } else {
          setNotify({
            isOpen: true,
            message: data.response.error[0].error_msg,
            type: "error",
          });
        }
      }
    );
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: "100vh",
          zIndex: 1,
          position: "inherit",
          width: "100%",
          marginBottom: "4%",
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "3%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "15px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={cp}
                      alt="AdminContentPic"
                      width="335"
                      height="410"
                      style={{ marginLeft: "25px", marginTop: "30px" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Academy
                    </span>

                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "14px", marginBottom: "7px" }}
                    >
                      Create New Password
                    </span>
                  </Box>

                  <Box
                    sx={{
                      "& .MuiTextField-root": { width: "38ch" },
                      marginTop: 2,
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column" spacing={3}>
                        <ReusableInputfield
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="oldPassword"
                          label="Old Password"
                          type="password"
                          value={values.oldPassword}
                          onChange={handleChange}
                          id="oldPassword"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className={classes.inputfieldLabel}
                          error={
                            touched.oldPassword && Boolean(errors.oldPassword)
                          }
                          helperText={touched.oldPassword && errors.oldPassword}
                        />
                        <ReusableInputfield
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="newPassword"
                          label="New Password"
                          type="password"
                          value={values.newPassword}
                          onChange={handleChange}
                          id="newPassword"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className={classes.inputfieldLabel}
                          error={
                            touched.newPassword && Boolean(errors.newPassword)
                          }
                          helperText={touched.newPassword && errors.newPassword}
                        />

                        <ReusableInputfield
                          variant="outlined"
                          name="confirmPassword"
                          margin="normal"
                          type="password"
                          value={values.confirmPassword}
                          //id="email"
                          id="confirmPassword"
                          fullWidth
                          label="Confirm Password"
                          onChange={handleChange}
                          className={classes.inputfieldLabel}
                          autoFocus
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <ReusableRememberMe
                              color="default"
                              size="small"
                              name="lsRememberMe"
                              checked={isChecked}
                              onChange={onChangeCheckbox}
                            />
                            <label
                              style={{
                                margin: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="loginFieldText"
                            >
                              Remember Me
                            </label>
                          </Stack>
                        </Stack>
                      </Stack>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                          marginRight: "60px",
                        }}
                      >
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          className="cancelBttn"
                          buttonName="Cancel"
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAalign: "center",
                            color: "#000936",
                            border: "1px solid #000936",
                            padding: "7px 20px",
                            borderRadius: "4px",
                            marginRight: "18px",
                            // marginBottom:"25px",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            navigate("/user/");
                          }}
                        />
                        <ReusableButton
                          size="medium"
                          variant="contained"
                          type="submit"
                          className="okBtn"
                          buttonName="Create Now"
                          style={{
                            backgroundColor: "#fe1c7f",
                            fontFamily: "Poppins",
                            fontSize: "0.8em",
                            fontWeight: 200,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#fff",
                            padding: "12px 35px 12px 35px",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
