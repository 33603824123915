import Input from "./input";
import Dropdown from "./Dropdown";
import DatePicker from "./DatePicker";
import Phone from "./phone";
import Button from "./Button";
import LinkField from "./LinkField";
import SearchDropdown from "./SearchDropdown";
import { ReusableInputfield } from "./inputfield/inputfield";
import Dropdown3 from "./Dropdown3";
import BatchDropdown from "./BatchDropdown";
import BatchDropdownOne from "./BatchDropdown1";
import BatchDropdownTwo from "./BatchDropdown2";
import Dropdown5 from "./Dropdown5";
import Dropdown6 from "./Dropdown6";
import CustomCarousel from "./Carousel/CustomCarousel";
import Dropdown4 from "./Dropdown4";
import Dropdown7 from "./Dropdown7";
import ReusableDropdown from "./Dropdown/ReusableDropdown";

export const Custom = {
  Input,
  Dropdown,
  DatePicker,
  Phone,
  Button,
  LinkField,
  SearchDropdown,
  ReusableInputfield,
  Dropdown3,
  CustomCarousel,
  Dropdown5,
  Dropdown4,
  Dropdown6,
  BatchDropdown,
  BatchDropdownOne,
  BatchDropdownTwo,
  Dropdown7,
  ReusableDropdown,
};
