import { Box, Typography, IconButton } from "@material-ui/core";
import { Grid, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./createUnit.css";
import { Custom } from "../../../../components/Custom/Custom";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import { SxStyles } from "../../../../styles/styles";
import { useFormik } from "formik";
import { InputLabel } from "@material-ui/core";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { API } from "../../../../Networking/API";
import Notification from "../../../../components/Custom/Notification";
import {
  CreateTrainingMaterialvalidations,
  CreateTrainingMaterialPPTValidations,
} from "../../../../Validation/FieldValidatorSchema";
import DeletePopUp from "../masterDataModal/deletePopUp";
import { DeleteUnitHelper } from "../../../../helper/unitHelper";
import { useNavigate } from "react-router";
import ReactLoading from "react-loading";
import DataTableDrag from "../../../../components/Custom/draggable/dataTableDraggable";
import ReactPaginate from "react-paginate";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CircularProgress from "@mui/material/CircularProgress";

const SheetJSFT = ["pdf,ppt,docx,pptx,doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const ThumbSheetJSFT = ["png"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const TrainingMaterialNew = () => {
  const styles = SxStyles.styles;

  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [draggableData, setDraggableData] = useState([]);
  const [deleteTrainingMaterialId, setDeleteTrainingMaterialId] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [deleteModal, setDeletModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [done, setDone] = useState(true);
  const [limit, setLimit] = useState(4);
  const [loaderForSaveLaterTM, setLoaderForSaveLaterTM] = useState(false);
  const [loaderForPublishTM, setLoaderForPublishTM] = useState(false);
  const [contentTypeValue, setContentTypeValue] = useState("");
  const [linkfilled, setLinkFilled] = useState(false);
  const [pptfilled, setPPTFilled] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      fileName: "",
      videoLink: "",
      pptLink: "",
      file: "",
      duration: "",
    },
    validationSchema:
      contentTypeValue === "FILES"
        ? CreateTrainingMaterialvalidations
        : CreateTrainingMaterialPPTValidations,
    onSubmit: (values) => {
      handleAddButtonClick();
      resetForm();
    },
  });
  useEffect(() => {
    if (values.videoLink.length > 0) {
      setPPTFilled(true);
    } else if (values.file) {
      setLinkFilled(true);
    } else {
      setPPTFilled(false);
      setLinkFilled(false);
    }
  }, [values.file, values.videoLink]);
  useEffect(() => {
    // values.duration = "";
    // values.fileName = "";
    // values.file = "";
    // values.pptLink = "";
    // values.videoLink = "";
    resetForm();
    touched.fileName = "";
    touched.duration = "";
    touched.file = "";
    touched.videoLink = "";
    touched.pptLink = "";
  }, [contentTypeValue]);
  
  const dataTableHeader = [
    "S.No",
    "Name",
    "Video Link",
    "Material",
    "Duration",
    "Actions",
  ];
  const unitId = localStorage.getItem("UnitId");
  let navigate = useNavigate();

  useEffect(() => {
    getTrainingMaterial();
  }, []);

  useEffect(() => {
    updateTrainingMaterial();
  }, [draggableData]);

  const updateTrainingMaterial = () => {
    const sortableIds = draggableData.map((x) => x.id);
    API.AdminTrainingMaterialReorder(unitId, sortableIds).then(
      ({ response }) => {
        if (response.api_status === 200) {
          //   setNotify({
          //     isOpen: true,
          //     message: response.data[0].message,
          //     type: "success",
          //   });
        }
      }
    );
  };

  

  const getTrainingMaterial = () => {
    API.getAdminTrainingMaterial(unitId).then(({ response }) => {
      
      setDone(false);
      if (response.data.api_status === 200) {
        const data = response.data.data;
        const formattedData = data.map((each) => ({
          id: each.material_pk,
          trainingName: each.material_name,
          trainingVideo:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          trainingMaterial:
            each.material_url !== null ? each.material_url : "N/A",
        }));

        const FinalFormData = data.map((each, index) => ({
          id: each.material_pk,
          sno: index + 1,
          first: each.material_name,
          second:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          third: each.material_url !== null ? each.material_url : "N/A",
          fourth: each.duration,
        }));
        setDraggableData(FinalFormData);
        
        setTableData(formattedData);
      } else {
        setDraggableData([]);
      }
    });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteTrainingMaterialId(params);
    setOpen(true);
  };

  const handleCloseDelete = (id) => {
    setDone(true);
    setOpen(false);

    API.deleteAdminTrainingMaterial(unitId, id).then(({ result }) => {
      const response = result;
      if (response.api_status === 200) {
        setPageIndex(0);
        setPageCount(0);
        setNotify({
          isOpen: true,
          message: "Training Material Deleted Successfully",
          type: "success",
        });
        getTrainingMaterial();
      } else {
        setNotify({
          isOpen: true,
          message: "Training Material Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };

  const handleAddButtonClick = () => {
    setDone(true);
    const { fileName, videoLink, pptLink, file, duration } = values;
    API.addAdminTrainingMaterial(
      unitId,
      fileName,
      videoLink,
      pptLink,
      file,
      duration,
      contentTypeValue
    ).then(({ response }) => {
      getTrainingMaterial();
      resetForm();
      setContentTypeValue("");
      //   inputRef.current.value = null;
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.data[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const HandleDeleteUnit = () => {
    DeleteUnitHelper(setNotify, navigate, unitId);
    setDeletModal(false);
  };

  const handleSaveForLaterButton = () => {
    setLoaderForSaveLaterTM(true);
    API.AdminSaveForLaterBtn(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setLoaderForSaveLaterTM(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setLoaderForSaveLaterTM(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again later",
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    setLoaderForPublishTM(true);
    API.finalUnitCreation(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
        navigate("/home/courses");
      } else {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handlePageClick = (data) => {
    setPageIndex(data.selected);
    setPageCount(data.selected);
  };

  const contentTypes = [
    {
      id: 1,
      name: "PPT/PPTX",
      value: "PPT",
    },
    {
      id: 2,
      name: "FILES",
      value: "FILES",
    },
  ];

  return (
    <>
      <Box className="training-material-container">
        <Grid
          item
          xs={10}
          lg={2.2}
          md={2}
          sm={5}
          //   style={{ marginRight: "34px" }}
        >
          <InputLabel className="InputLabel" style={styles.label}>
            Type of Content
            <span style={styles.madatoryMark}>*</span>
          </InputLabel>

          <FormControl
            sx={{ width: 200 }}
            // error={Boolean(formiks.errors.domain)}
          >
            <Select
              displayEmpty
              size="small"
              value={contentTypeValue}
              name="contentType"
              id="contentType"
              onChange={(e) => {
                setContentTypeValue(e.target.value);
              }}
              inputProps={{ "aria-label": "Without label" }}
              // error={touched.contentType && Boolean(errors.contentType)}
              // helperText={touched.contentType && errors.contentType}
            >
              <MenuItem disabled value="">
                <em>Select Collection </em>
              </MenuItem>
              {contentTypes.map((each) => (
                <MenuItem key={each.id} value={each.value}>
                  {each.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <form onSubmit={handleSubmit}>
          {contentTypeValue === "FILES" ? (
            <>
              <Grid container className="training-materail">
                <Grid item xs={2.4}>
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Name
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="fileName"
                        id="fileName"
                        placeholder="Name"
                        value={values.fileName}
                        onChange={handleChange}
                        size="small"
                        style={styles.inputFleid}
                        error={touched.fileName && Boolean(errors.fileName)}
                        helperText={touched.fileName && errors.fileName}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2.3}
                  style={
                    errors.file
                      ? styles.inputContainerValidator
                      : styles.inputContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Video Link
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="videoLink"
                        id="videoLink"
                        placeholder="Video Link"
                        value={values.videoLink}
                        style={{
                          height: "41px",
                          width: "100%",
                        }}
                        onChange={handleChange}
                        disabled={linkfilled ? true : false}
                        error={touched.file && Boolean(errors.file)}
                        helperText={touched.file && errors.file}
                        size="small"
                      />
                    </span>
                    <div></div>
                  </div>
                </Grid>

                <Grid item xs={3.3} style={styles.fileContainer}>
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Upload Materials
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    {/* <span> */}
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="upload-box"
                      ref={inputRef}
                      accept={SheetJSFT}
                      style={{
                        pointerEvents: pptfilled ? "none" : "visible",
                        fontSize: "0.8em",
                        fontFamily: "Poppins",
                        height: "41px",
                        position: "relative",
                        bottom: "3px",
                      }}
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                    {/* </span> */}
                    <Typography style={styles.note} variant="p">
                      Only .pdf .ppt, pptx, mp4 format allowed
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2.3}
                  style={
                    errors.file
                      ? styles.inputContainerValidator
                      : styles.inputContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Duration(in mins)
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="duration"
                        id="duration"
                        placeholder="Duration"
                        value={values.duration}
                        style={{
                          height: "41px",
                          width: "100%",
                        }}
                        type="number"
                        InputProps={{
                          autoComplete: "off",
                          inputProps: {
                            style: { padding: "10px 12px", width: "90%" },
                          },
                        }}
                        onChange={handleChange}
                        error={touched.duration && Boolean(errors.duration)}
                        helperText={touched.duration && errors.duration}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  // xs={0.2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "2%",
                    paddingTop: "1%",
                  }}
                ></Grid>
                <ReusableButton
                  type="submit"
                  size="large"
                  variant="outlined"
                  buttonName="Add"
                  style={styles.addbuttonTrainingMaterial}
                ></ReusableButton>
              </Grid>
            </>
          ) : null}
          {contentTypeValue === "PPT" ? (
            <>
              <Grid
                container
                className="training-materail"
                spacing={1}
                style={{ marginTop: "8px" }}
              >
                <Grid item xs={3}>
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Name
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="fileName"
                        id="fileName"
                        placeholder="Name"
                        value={values.fileName}
                        onChange={handleChange}
                        size="small"
                        style={{
                          height: "41px",
                          width: "100%",
                        }}
                        error={touched.fileName && Boolean(errors.fileName)}
                        helperText={touched.fileName && errors.fileName}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={
                    errors.file
                      ? styles.inputContainerValidator
                      : styles.inputContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      PPT/PPTX Link
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="pptLink"
                        id="pptLink"
                        placeholder="PPT Link"
                        value={values.pptLink}
                        style={{
                          height: "41px",
                          width: "100%",
                        }}
                        onChange={handleChange}
                        error={touched.pptLink && Boolean(errors.pptLink)}
                        helperText={touched.pptLink && errors.pptLink}
                        size="small"
                      />
                    </span>
                    <div></div>
                  </div>
                </Grid>
                <Grid item xs={3.3} style={styles.fileContainer}>
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Thumbnail
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    {/* <span> */}
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="upload-box"
                      ref={inputRef}
                      accept={ThumbSheetJSFT}
                      style={{
                        fontSize: "0.8em",
                        fontFamily: "Poppins",
                        height: "41px",
                        position: "relative",
                        bottom: "3px",
                      }}
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                    {/* </span> */}
                    <Typography style={styles.note} variant="p">
                      Only .png format allowed
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={
                    errors.file
                      ? styles.inputContainerValidator
                      : styles.inputContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Duration(in mins)
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="duration"
                        id="duration"
                        placeholder="Duration"
                        value={values.duration}
                        style={{
                          height: "36px",
                          width: "100%",
                        }}
                        type="number"
                        InputProps={{
                          autoComplete: "off",
                          inputProps: {
                            style: { padding: "10px 12px", width: "90%" },
                          },
                        }}
                        onChange={handleChange}
                        error={touched.duration && Boolean(errors.duration)}
                        helperText={touched.duration && errors.duration}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  // xs={0.2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "2%",
                    paddingTop: "1%",
                  }}
                ></Grid>
                <ReusableButton
                  type="submit"
                  size="large"
                  variant="outlined"
                  buttonName="Add"
                  style={styles.addbuttonTrainingMaterial}
                ></ReusableButton>
              </Grid>
            </>
          ) : null}
        </form>
        {done ? (
          <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {draggableData.length > 0 ? (
              <>
                <Box style={styles.tableContainer}>
                  <DataTableDrag
                    draggableData={draggableData}
                    setDraggableData={setDraggableData}
                    handleDeleteInpre={handleDeleteInpre}
                    pageIndex={pageIndex}
                    pageLimit={limit}
                    link={true}
                    dataTableHeader={dataTableHeader}
                  />
                  {draggableData.length > limit ? (
                    <ReactPaginate
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                      pageCount={Math.ceil(draggableData.length / limit)}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={pageCount}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box style={styles.tableErrorMsg}>
                  No Training Material found. Please Add Training Material
                </Box>
              </>
            )}
          </>
        )}
        <Box style={styles.btnContainer}>
          <Box style={styles.buttonContainer}>
            <ReusableButton
              onClick={() => navigate("/home/courses")}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={styles.cancelButton}
              disabled={
                loaderForSaveLaterTM || loaderForPublishTM ? true : false
              }
            ></ReusableButton>
            <ReusableButton
              onClick={handleSaveForLaterButton}
              className="addbutton"
              size="large"
              variant="contained"
              endIcon={
                !loaderForSaveLaterTM ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForSaveLaterTM ? "Save for Later" : "Saving"}
              style={
                !loaderForPublishTM
                  ? styles.saveForLaterBtn
                  : styles.saveForLaterBtnDisabled
              }
              disabled={loaderForPublishTM ? true : false}
            ></ReusableButton>
            <ReusableButton
              onClick={handleCreateButton}
              size="large"
              variant="contained"
              endIcon={
                !loaderForPublishTM ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForPublishTM ? "Publish" : "Publishing"}
              style={
                !loaderForSaveLaterTM
                  ? styles.createBtn
                  : styles.createBtnDisabled
              }
              disabled={loaderForSaveLaterTM ? true : false}
            ></ReusableButton>
          </Box>
        </Box>
      </Box>
      <Dialog
        divider
        open={open}
        maxWidth="sm"
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleCloseInByCancel}
          />
          <Button
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName="Delete"
            style={styles.createBtnDialog}
            onClick={() => handleCloseDelete(deleteTrainingMaterialId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default TrainingMaterialNew;
