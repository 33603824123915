import React from "react";
import VideoPlayer from "./mandatoryTraningVideoPlayer";

function MandateDocViewver(props) {
  const {
    link,
    setCurrentVideoTime,
    setVideoTotalTime,
    // contentType,
    // mediaType,
    // name,
    // mediaSource,
    // MandatoryTraining,
    // thumbNail,
    primaryValue,
    setPrimaryValue,
    selected,
    selectedTrainingModule,
    existingArray,
  } = props;

  const module_url = selectedTrainingModule?.material_url
    ? selectedTrainingModule.material_url
    : selectedTrainingModule?.link
    ? selectedTrainingModule.link
    : null;

  // const module_url = `https://docs.google.com/presentation/d/15Tx82inyf6I_YTDySWKIVqAN_5mZAp_Bd2xc_W9X24E/present?slide=id.p`;

  // const module_url =
  //   "https://absyz-academy.s3.ap-south-1.amazonaws.com/Admin/training_materials/Ruby-1/1688550484754-POSH%20PPT.ppsx";
  const url = `https://docs.google.com/viewer?embedded=true&url=${module_url}&toolbar=0&navpanes=0&scrollbar=0`;

  const handlePpt = () => {
    // const newTab = window.open(url, "_blank");
    // const timer = setTimeout(() => {
    //
    //   localStorage.setItem(`${selected}_isCompleted`, true);
    //   existingArray.push(selected);
    //   localStorage.setItem("myArray", JSON.stringify(existingArray));
    //   setPrimaryValue([...primaryValue, selected.material_pk]);
    // }, 2000);
    // return () => clearTimeout(timer);
  };

  const urlMic = `https://view.officeapps.live.com/`;
  const urlGoo = `https://docs.google.com/`;
  // const url =`https://docs.google.com/presentation/d/15Tx82inyf6I_YTDySWKIVqAN_5mZAp_Bd2xc_W9X24E/present?hl=en&forcehl=1&slide=id.p`
  //
  const VideoLink = selectedTrainingModule?.video_link;
  let centerRenderingContent;

  if (
    selectedTrainingModule?.content_type === null &&
    selectedTrainingModule?.media_type === "PPT"
  ) {
    if (module_url && module_url?.startsWith(urlMic)) {
      const link = `https://view.officeapps.live.com/op/view.aspx?src=${module_url}`;
      centerRenderingContent = (
        <a href={link} target="_blank" rel="noopener noreferrer" download="">
          <img
            style={{ display: "flex", justifyContent: "center" }}
            src={selectedTrainingModule.video_link}
            onClick={() => handlePpt()}
            alt=""
          />
        </a>
      );
    } else if (module_url && module_url?.startsWith(urlGoo)) {
      centerRenderingContent = (
        <a
          href={module_url}
          target="_blank"
          rel="noopener noreferrer"
          download=""
        >
          <img
            style={{ display: "flex", justifyContent: "center" }}
            src={selectedTrainingModule.video_link}
            onClick={() => handlePpt()}
            alt=""
          />
        </a>
      );
    } else {
      const link = `https://view.officeapps.live.com/op/view.aspx?src=${module_url}`;
      centerRenderingContent = (
        <a href={link} target="_blank" rel="noopener noreferrer" download="">
          <img
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px auto",
            }}
            src={selectedTrainingModule.video_link}
            onClick={() => handlePpt()}
            alt=""
          />
        </a>
      );
    }
  } else if (selectedTrainingModule?.content_type === null) {
    VideoLink && VideoLink.indexOf("youtube") !== -1
      ? (centerRenderingContent = (
          <iframe
            className="iframe-tag"
            style={{
              height: "60vh",
              width: "55vw",
              backgroundColor: "white",
              content: "inherit",
              overflowY: "auto",
              overflowX: "auto",
              cursor: "default",
              frameBorder: "1",
            }}
            contentEditable={false}
            color="white"
            src={
              selectedTrainingModule?.video_link
                ? selectedTrainingModule?.video_link.replace(
                    "https://www.youtube.com/watch?v=",
                    "https://www.youtube.com/embed/"
                  )
                : ""
            }
            sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
            type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            title="Material"
          />
        ))
      : (centerRenderingContent = (
          <VideoPlayer
            videoLink={
              selectedTrainingModule?.material_url
                ? selectedTrainingModule?.material_url
                : selectedTrainingModule?.video_link
            }
            setCurrentVideoTime={setCurrentVideoTime}
            setVideoTotalTime={setVideoTotalTime}
          />
        ));
  } else if (selectedTrainingModule?.content_type !== null) {
    if (selectedTrainingModule?.media_type === "FILES") {
      if (selectedTrainingModule?.content_type === "application/pdf") {
        centerRenderingContent = (
          <>
            <iframe
              style={{
                height: "60vh",
                width: "55vw",
                backgroundColor: "white",
                content: "inherit",
                overflowY: "scroll",
                paddingRight: "2%",
                cursor: "default",
              }}
              color="white"
              src={url}
              title="Material"
            />
            {selectedTrainingModule?.video_link ? (
              VideoLink.indexOf("youtube") !== -1 ? (
                <iframe
                  className="iframe-tag"
                  style={{
                    height: "60vh",
                    width: "55vw",
                    backgroundColor: "white",
                    content: "inherit",
                    overflowY: "auto",
                    overflowX: "auto",
                    cursor: "default",
                    frameBorder: "1",
                  }}
                  contentEditable={false}
                  color="white"
                  src={
                    selectedTrainingModule?.video_link
                      ? selectedTrainingModule?.video_link.replace(
                          "https://www.youtube.com/watch?v=",
                          "https://www.youtube.com/embed/"
                        )
                      : ""
                  }
                  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
                  type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  title="Material"
                />
              ) : (
                <VideoPlayer
                  videoLink={selectedTrainingModule?.video_link}
                  setCurrentVideoTime={setCurrentVideoTime}
                  setVideoTotalTime={setVideoTotalTime}
                />
              )
            ) : null}
          </>
        );
      } else if (
        selectedTrainingModule?.content_type &&
        selectedTrainingModule?.content_type !== "video/mp4"
      ) {
        let LinkUrl = selectedTrainingModule?.module_url
          ? selectedTrainingModule?.module_url
          : selectedTrainingModule?.material_url;
        const LinkStr = LinkUrl.split(".");
        let LinkExt = LinkStr[LinkStr.length - 1];

        let FinalLink = "";
        if (
          LinkExt.toUpperCase() === "PPT" ||
          LinkExt.toUpperCase() === "PPTX"
        ) {
          FinalLink = `https://view.officeapps.live.com/op/view.aspx?src=${LinkUrl}`;
        } else {
          FinalLink = LinkUrl;
        }
        centerRenderingContent = (
          <>
            <a href={FinalLink} target="_blank">
              {selectedTrainingModule?.material_name}
            </a>
            {selectedTrainingModule?.video_link ? (
              VideoLink.indexOf("youtube") !== -1 ? (
                <iframe
                  className="iframe-tag"
                  style={{
                    height: "60vh",
                    width: "55vw",
                    backgroundColor: "white",
                    content: "inherit",
                    overflowY: "auto",
                    overflowX: "auto",
                    cursor: "default",
                    frameBorder: "1",
                  }}
                  contentEditable={false}
                  color="white"
                  src={
                    selectedTrainingModule?.video_link
                      ? selectedTrainingModule?.video_link.replace(
                          "https://www.youtube.com/watch?v=",
                          "https://www.youtube.com/embed/"
                        )
                      : ""
                  }
                  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
                  type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  title="Material"
                />
              ) : (
                <VideoPlayer
                  videoLink={selectedTrainingModule?.video_link}
                  setCurrentVideoTime={setCurrentVideoTime}
                  setVideoTotalTime={setVideoTotalTime}
                />
              )
            ) : (
              <></>
            )}
          </>
        );
      } else if (
        selectedTrainingModule?.content_type &&
        selectedTrainingModule?.content_type === "video/mp4"
      ) {
        centerRenderingContent = (
          <>
            <VideoPlayer
              videoLink={selectedTrainingModule?.video_link}
              setCurrentVideoTime={setCurrentVideoTime}
              setVideoTotalTime={setVideoTotalTime}
            />
          </>
        );
      }
    } else {
      centerRenderingContent = (
        <VideoPlayer
          videoLink={
            selectedTrainingModule?.video_link
              ? selectedTrainingModule?.video_link
              : selectedTrainingModule?.link
          }
          setCurrentVideoTime={setCurrentVideoTime}
          setVideoTotalTime={setVideoTotalTime}
        />
      );
    }
  }

  return (
    <div
      style={{
        height: "auto",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {centerRenderingContent}
    </div>
  );
}

export default MandateDocViewver;
