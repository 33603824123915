import React from "react";
import { Box } from "@mui/material";
import "./mentor-sessions-details.css";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import { MenteeSessionsDetailsAssessedAssignment } from "./mentee-sessions-details-assesed-assignment";
import { MenteeSessionsDetailsAssesedQuiz } from "./mentee-sessions-details-assesed-quiz";
import { SearchBar } from "./search-bar";
import { MenteeSessionsDetailsAssessedTasks } from "./mentee-sessions-details-assesed-tasks";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import "./mentor-sessions-details.css";
import { TaskSharp } from "@mui/icons-material";
export const MenteeDetails = ({
  scheduleID,
  assignments,
  topic,
  totalTasks,
  scheduleType,
  Quizz_pk,
}) => {
  const [value, setValue] = useState("1");
  const [trainerName, setTrainerName] = useState("");
  const [trainerRole, setTrainerRole] = useState("");
  const jwtToken = Cookies.get("jwt_token");
  const [quizData, setQuizData] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [trainerID, setTrainerID] = useState(0);
  const [quizDone, setQuizDone] = useState(false);
  const [taskDone, setTaskDone] = useState(true);
  const [errorMsgQ, setErrorMsgQ] = useState("");
  const [errorMsgT, setErrorMsgT] = useState("");
  const [status, setStatus] = useState(false);
  const [assignmentData, setAssignmentData] = useState();
  const [assingmentDone, setAssingmentDone] = useState(true);
  const [apiStatus, setApiStatus] = useState(true);
  const [asnfkValue, setAsnfkValue] = useState();
  const [asnidFkValue, setAsnidFkValue] = useState();
  const [paramsValue, setParamsValue] = useState();
  const [taskfkValue, settaskfkValue] = useState();
  const [taskidFkValue, settaskidFkValue] = useState();
  const [scroreforupdate, setScroreforupdate] = useState();

  const [index, setIndex] = useState(0);
  const [taskindex, setTaskIndex] = useState(0);
  const [tasksDetails, setTasksDetails] = useState([]);
  const Api1 = API.Api1;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (scheduleType == 0 && assignments.length !== 0) {
      //
      getMentorAssignmentData();
    }

    if (scheduleType === 0) {
      //
      getMentorQuizData();
    }
    if (totalTasks !== undefined && totalTasks.length !== 0) {
      getTaskDetails();
    }
  }, [trainerID]);

  useEffect(() => {
    if (totalTasks !== undefined && totalTasks.length !== 0) {
      getTaskDetails();
    }
  }, [index]);

  //
  const getTaskDetails = async () => {
    setApiStatus(true);
    setErrorMsgT("");
    setApiStatus(false);

    setTaskDone(false);
    const raw = JSON.stringify({
      user_id: parseInt(trainerID),
      hackathon_id: scheduleID,

      task_id: totalTasks[index].task_pk,
    });

    API.getMentorTaskDetails(raw).then(({ response }) => {
      if (response.api_status === 200) {
        //
        setTasksDetails(response.data[0]);
        setApiStatus(true);
        settaskfkValue(response.data[0].user_task_pk);
        settaskidFkValue(response.data[0].user_hid_pk);
        setErrorMsgT("");

        setParamsValue(response.data[0].score);
      } else {
        setApiStatus(false);
        setErrorMsgT(response.error[0].error_msg);
      }
    });

    setTaskDone(true);
  };
  const setClickStatus = (status) => {
    setStatus(status);
  };

  const handleNext = () => {
    setIndex(index + 1);
    setTaskIndex(index + 1);
  };

  const handlePrev = () => {
    setIndex(index - 1);
    setTaskIndex(index - 1);
  };
  const getMentorAssignmentData = async () => {
    setApiStatus(true);
    setErrorMsg("");
    setApiStatus(false);
    setAssingmentDone(false);
    const raw = JSON.stringify({
      user_id: parseInt(trainerID),
      // user_id: 127,
      // schedule_id: scheduleID,
      asn_fk: assignments[0].asn_pk,
    });
    API.getMentorAssignData(raw).then(({ response }) => {
      if (response.api_status === 200) {
        setAssignmentData(response.data[0]);
        setApiStatus(true);
        setAsnfkValue(response.data[0].asn_fk);
        setAsnidFkValue(response.data[0].attachments[0].asnid_fk);
        setErrorMsg("");
        setParamsValue(response.data[0].params);
        setScroreforupdate(response.data[0].score);
      } else {
        setApiStatus(false);
        setErrorMsg(response.error[0].error_msg);
      }
    });
    setAssingmentDone(true);
  };
  const Value = trainerID;
  let toRender;
  if (Value === 0) {
    toRender = (
      <>
        {assignments !== undefined ? (
          assignments.length !== 0 ? (
            <MenteeSessionsDetailsAssessedAssignment
              assignments={assignments}
              getMentorAssignmentData={getMentorAssignmentData}
            />
          ) : (
            <Box
              style={{
                height: "45vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="error-msg">
                {" "}
                No Assignments Available For This Session.
              </span>
            </Box>
          )
        ) : null}
      </>
    );
  } else if (Value !== 0 && errorMsg.length !== 0) {
    toRender = (
      <>
        <MenteeSessionsDetailsAssessedAssignment
          assignments={assignments}
          getMentorAssignmentData={getMentorAssignmentData}
        />
        {assignments !== undefined ? (
          assignments.length !== 0 ? (
            assingmentDone ? (
              <Box
                style={{
                  height: "45vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="error-msg">
                  {status
                    ? `${trainerName} has not Submitted the Assignment`
                    : null}
                </span>
              </Box>
            ) : (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            )
          ) : null
        ) : null}
      </>
    );
  } else if (Value !== 0 && errorMsg.length === 0) {
    toRender = (
      <>
        {assignments !== undefined ? (
          assingmentDone ? (
            <MenteeSessionsDetailsAssessedAssignment
              assignmentData={assignmentData}
              assignments={assignments}
              apiStatus={apiStatus}
              status={status}
              trainerID={trainerID}
              asnfkValue={asnfkValue}
              asnidFkValue={asnidFkValue}
              paramsValue={paramsValue}
              scroreforupdate={scroreforupdate}
              getMentorAssignmentData={getMentorAssignmentData}
            />
          ) : (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          )
        ) : null}
      </>
    );
  }

  let toRenderTask;
  if (Value === 0) {
    toRenderTask = (
      <>
        {totalTasks !== undefined ? (
          totalTasks.length !== 0 ? (
            <MenteeSessionsDetailsAssessedTasks
              tasks={totalTasks}
              getTaskDetails={getTaskDetails}
              taskIndex={index}
            />
          ) : (
            <Box
              style={{
                height: "45vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="error-msg">
                {" "}
                No Tasks Available For This Session.
              </span>
            </Box>
          )
        ) : null}
      </>
    );
  } else if (Value !== 0 && errorMsgT.length !== 0) {
    toRenderTask = (
      <>
        <MenteeSessionsDetailsAssessedTasks
          tasks={totalTasks}
          getTaskDetails={getTaskDetails}
          taskIndex={index}
        />
        {totalTasks !== undefined ? (
          totalTasks.length !== 0 ? (
            taskDone ? (
              <Box
                style={{
                  height: "45vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="error-msg">
                  {status ? `${trainerName} has not Submitted the task` : null}
                </span>
              </Box>
            ) : (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            )
          ) : null
        ) : null}
      </>
    );
  } else if (Value !== 0 && errorMsgT.length === 0) {
    toRenderTask = (
      <>
        {totalTasks !== undefined ? (
          taskDone ? (
            <MenteeSessionsDetailsAssessedTasks
              tasks={totalTasks}
              tasksDetails={tasksDetails}
              getTaskDetails={getTaskDetails}
              taskIndex={index}
              apiStatus={apiStatus}
              status={status}
              taskfkValue={taskfkValue}
              taskidFkValue={taskidFkValue}
              trainerID={trainerID}
              paramsValue={paramsValue}
            />
          ) : (
            //  <h1>hello</h1>
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          )
        ) : null}
      </>
    );
  }

  const getMentorQuizData = async () => {
    setErrorMsgQ("");
    setQuizDone(false);
    setApiStatus(false);

    const raw = JSON.stringify({
      // user_id: parseInt(trainerID),
      trainee_id: parseInt(trainerID),
      // schedule_id: scheduleID,
      quizz_pk: Quizz_pk,
    });

    API.getMentorQuiz(raw).then(({ response }) => {
      //
      if (response.api_status === 200) {
        // setQuizData(response.data[0]);
        setQuizData(response.data);
        setQuizDone(true);
        setErrorMsgQ("");
      } else {
        setQuizData(undefined);
        setQuizDone(true);
        setErrorMsgQ(response.error[0].error_msg);
      }
    });
  };

  let toRenderForQuiz;
  const ValueOfQuiz = trainerID;
  if (ValueOfQuiz === 0) {
    toRenderForQuiz = (
      <Box
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="error-msg">Welcome To Quiz Session</span>
      </Box>
    );
  } else if (ValueOfQuiz !== 0 && status === true) {
    toRenderForQuiz = !quizDone ? (
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#fb2485"}
          height={100}
          width={100}
        />
      </Box>
    ) : (
      <MenteeSessionsDetailsAssesedQuiz
        quizData={quizData}
        assignments={assignments}
        getMentorQuizData={getMentorQuizData}
      />
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div
            style={{
              background: "#fff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="participant-tab-text">Participants</span>
          </div>
        </Grid>
        <Grid item xs={10}></Grid>
      </Grid>

      {totalTasks !== undefined && totalTasks.length !== 0 ? (
        <>
          <Box style={{ background: "#fff" }}>
            <div
              style={{
                background: "#fff",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                className="participant-tab-text"
                style={{
                  paddingBottom: "0px",
                  margin: "20px",
                  paddingLeft: "40px",
                }}
              >
                {`Task ${index + 1}`}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "40px",
                }}
              >
                <Button
                  style={{
                    width: "25 !important",
                    minWidth: 10,

                    height: 25,
                    // backgroundColor:  '#fb2485',
                    backgroundColor: index === 0 ? "#a9a9a9" : "#fb2485",
                  }}
                  disabled={index === 0 ? true : false}
                  onClick={() => {
                    // if (index > 0) {
                    //   setIndex(index - 1);
                    // }
                    handlePrev();
                  }}
                >
                  <IoIosArrowBack color="#ffffff"></IoIosArrowBack>
                </Button>
                <p className={"num"}>{`${index + 1}/${totalTasks.length}`}</p>
                <Button
                  style={{
                    width: "25 !important",
                    minWidth: 10,

                    height: 25,
                    backgroundColor:
                      index === totalTasks.length - 1 ? "#a9a9a9" : "#fb2485",
                  }}
                  disabled={index === totalTasks.length - 1 ? true : false}
                  onClick={() => {
                    handleNext();
                  }}
                >
                  <IoIosArrowForward color="#ffffff"></IoIosArrowForward>{" "}
                </Button>
              </div>
            </div>
          </Box>
          <Divider className="division" />
        </>
      ) : null}

      <Box style={{ background: "#fff", paddingTop: "16px" }}>
        <Grid container spacing={2} style={{ paddingTop: "16px" }}>
          <Grid item xs={3}>
            <SearchBar
              setTrainerName={setTrainerName}
              setTrainerRole={setTrainerRole}
              setTrainerID={setTrainerID}
              clickStatus={setClickStatus}
            />
          </Grid>

          <Grid item xs={9}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              {scheduleType === 0 ? (
                <TabContext value={value}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Assignments" value="1" />
                          <Tab label="Quiz" value="2" />
                        </TabList>
                      </Box>
                    </Grid>

                    <Grid item xs={8}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                          flexDirection: "column",
                          marginRight: "17px",
                        }}
                      >
                        <span className="trainer-name">{trainerName}</span>
                        <span className="trainer-bio">{trainerRole}</span>
                      </Box>
                    </Grid>
                  </Grid>

                  <TabPanel value="1">{toRender}</TabPanel>

                  <TabPanel value="2">{toRenderForQuiz}</TabPanel>
                </TabContext>
              ) : (
                <TabContext value={value}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Box>
                        <TabList aria-label="lab API tabs example">
                          <Tab label="Hackathon" value="1" />
                        </TabList>
                      </Box>
                    </Grid>

                    <Grid item xs={5}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                          flexDirection: "column",
                          marginRight: "17px",
                        }}
                      >
                        <span className="trainer-name">{trainerName}</span>
                        <span className="trainer-bio">{trainerRole}</span>
                      </Box>
                    </Grid>
                  </Grid>

                  <TabPanel value="1">{toRenderTask}</TabPanel>
                </TabContext>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
