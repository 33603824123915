import { React, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import "./batch.css";
import { API } from "../../../Networking/API";
import Notification from "../../../components/Custom/Notification";
import Box from "@mui/material/Box";

const d = new Date();
d.setHours(0, 0, 0, 0);

const inputFieldData = {
  name: "",
  batchid: "",
};

const inputErrorMsg = {
  forName: "",
  forBatchid: "",
};

const inputFieldErrorStatus = {
  nameStatus: false,
  batchidStatus: false,
};

export const AddBatch = ({ open, handleClose, setOpen, getBatchData }) => {
  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleInputNameChange = (event) => {
    setInputFieldValues({ ...inputFieldValues, name: event.target.value });
  };

  const handleIdChange = (event) => {
    setInputFieldValues({ ...inputFieldValues, batchid: event.target.value });
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };

  const handleAddButtonClick = () => {
    setErrorStatus({ ...errorStatus, nameStatus: false });
    setErrorStatus({ ...errorStatus, batchidStatus: false });

    if (inputFieldValues.name === "") {
      setErrorStatus({ nameStatus: true });
      setInputFieldErrors({ forName: "This is Required field" });
    } else if (inputFieldValues.batchid === "") {
      setErrorStatus({ batchidStatus: true });
      setInputFieldErrors({ forBatchid: "This is Required field" });
    } else if (
      inputFieldValues.batchid === "" &&
      inputFieldValues.name !== ""
    ) {
      setErrorStatus({ batchidStatus: true });
      setInputFieldErrors({ forBatchid: "This is Required field" });
    } else if (
      inputFieldValues.batchid !== "" &&
      inputFieldValues.name === ""
    ) {
      setErrorStatus({ nameStatus: true });
      setInputFieldErrors({ forName: "This is Required field" });
    } else if (
      inputFieldValues.batchid !== "" &&
      inputFieldValues.name !== ""
    ) {
      setErrorStatus({ batchidStatus: false });
      setInputFieldErrors({ forBatchid: "" });
      setErrorStatus({ nameStatus: false });
      setInputFieldErrors({ forName: "" });

      const name = inputFieldValues.name;
      API.addBatchData(name).then(({ response }) => {
        const data = response;
        if (data.api_status === 200) {
          setInputFieldValues({ ...inputFieldValues, name: "" });
          setInputFieldValues({ ...inputFieldValues, batchid: 0 });
          setOpen(false);
          setNotify({
            isOpen: true,
            message: "Batch Added Successfully",
            type: "success",
          });
          getBatchData();
        } else if (data.api_status === 502) {
          setNotify({
            isOpen: true,
            message: "Duplicate name found, Try different name",
            type: "warning",
          });
        }
      });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px", overflowY: "hidden !important" }}
        maxWidth="lg"
        className="batch-add-model"
      >
        <DialogTitle className="batch-dialog-header">Add Batches</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000",
                      marginBottom: "8px",
                    }}
                  >
                    Batch Name
                  </span>
                  <span style={{ marginLeft: "" }}>
                    <ReusableInputfield
                      name="firstname"
                      placeholder="Batch Name"
                      value={inputFieldValues.name}
                      onChange={handleInputNameChange}
                      error={errorStatus.nameStatus}
                      helperText={inputFieldErrors.forName}
                      size="small"
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000",
                      marginBottom: "8px",
                    }}
                    variant="h6"
                  >
                    Batch Id
                  </Typography>
                  <ReusableInputfield
                    type="number"
                    name="id"
                    value={inputFieldValues.batchid}
                    placeholder="Batch Id"
                    onChange={handleIdChange}
                    error={errorStatus.batchidStatus}
                    helperText={inputFieldErrors.forBatchid}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClick={handleCloseByCancel}
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "10px",
              marginBottom: "25px",
              textTransform: "none",
            }}
          ></ReusableButton>
          <ReusableButton
            onClick={handleAddButtonClick}
            className="addbutton"
            type="submit"
            size="large"
            variant="contained"
            buttonName="Add"
            style={{
              backgroundColor: "#fb2485",
              marginRight: "30px",
              marginBottom: "25px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 30px",
              borderRadius: "4px",
              textTransform: "none",
            }}
          ></ReusableButton>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
