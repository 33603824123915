import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Grid, Box, Button, DialogContentText } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import QuizLayout from "./quiz-layout";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { API } from "../../../Networking/API";
import "./quiz.css";

function Questions(props) {
  const { callback, showRes, fin, loaded } = props;
  const location = useLocation();
  const [subcatnamedisplay, setSubCatNameDisplay] = useState();
  const [question, setQuestion] = useState([]);
  const [optionsOpted, setOptionsOpted] = useState("");
  const [value, setValue] = useState();
  const [questIndex, setQuestIndex] = useState(0);
  const [questId, setQuestId] = useState();
  const [quizId, setQuizId] = useState();
  const [userQuizId, setUserQuizId] = useState();
  const [results, setResults] = useState();
  const [done, setDone] = useState(undefined);
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [visited, setVisited] = useState(0);
  const [answered, setAnswered] = useState([]);
  const [quizNumber01, setQuizNumber01] = useState();
  const [errorStatus, setErrorStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setSubCatNameDisplay(location.state.topic);
    setQuizNumber01(location.state.quizNumber);
  }, []);

  useEffect(() => {
    if (quizNumber01 !== undefined) {
      getAllQuestions(quizNumber01);
    }
  }, [quizNumber01]);

  const jwtToken = Cookies.get("jwt_token");

  const handleChange = (event, index) => {
    setValue(event.target.value);
    setOptionsOpted(event.target.value);
    setAnswered([...answered, index]);
  };

  useEffect(() => {
    if (fin) {
      setShowModal(true);
    }
  }, [fin]);

  const removeQuizDataCookie = () => {
    localStorage.removeItem("minsecs");
    Cookies.remove("quizdata");
    window.opener = null;
    window.open("", "_self", ""); //bug fix
    window.close();
  };

  const getAllQuestions = async (quizNumber01) => {
    try {
      API.getUserAllQuestions(quizNumber01).then(({ response }) => {
        if (response.data.api_status === 200) {
          setRes(false);
          setStart(true);
          setDone(true);
          loaded(true);
          setQuestion(response.data.data[0].questions);
          localStorage.setItem(
            "countdownfromapi",
            response.data.data[0].countdown
          );
          setQuizId(response.data.data[0].schedule_details.user_qz_id);
          setQuestId(response.data.data[0].questions[0].question_pk);
          setUserQuizId(response.data.data[0].schedule_details.user_qz_id);
          setOptionsOpted(response.data.data[0].questions[0].userOption);
          setAnswered(response.data.data[0].userOptionIdx);
        } else if (response.data.api_status === 404) {
          setDone(true);
          setRes(true);
          loaded(true);
        } else if (response.data.api_status === 400) {
          setDone(true);
          setRes(true);
          loaded(true);
          setErrorStatus(true);
        } else if (response.data.api_status === 502) {
          setDone(true);
          setRes(true);
          loaded(true);
        }
      });
    } catch (e) {
      setRes(true);
      loaded(true);
    }
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr1 = getUniqueListBy(question, "question");
  const navigate = useNavigate();

  const onClickPrevious = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 - 1);
    await API.postUserOnclick(optionsOpted, param, id)
      .then(({ response }) => {
        // "here, if you click previous you will get previous qstn option along with selected option"
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    await API.putUserOnclick(question[param1 - 1].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    setShow(false);
  };

  const onClickNext = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 + 1);
    await API.postUserOnClickNext(optionsOpted, param, id)
      .then(({ response }) => {
        setQuestIndex(questIndex + 1);
        if (response.api_status === 200) {
          nextQuestion(question[param1].question_pk + 1, id);
          setShow(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const nextQuestion = async (question_pk, id) => {
    await API.putUserOnClickNext(question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex + 1);
        if (response.api_status === 404) {
          setShow(false);
        }
        setShow(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleClickOpenDelModal = (e, id, param) => {
    setShow(false);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const onClickSubmit = async (e, id, param) => {
    e.preventDefault();
    Cookies.remove("quizdata");
    apiCall(id, param);
  };
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self", ""); //bug fix
    window.close();
    localStorage.removeItem("minsecs");
  };
  const apiCall = async (id, param) => {
    await API.postUserAPICall(optionsOpted, param, id)
      .then(({ response }) => {
        const ResultsOfQuiz = async (id) => {
          const response = await API.getUserQuizResults(id);
          setOpenModal(false);
          let result = response.response.data.data[0];
          setResults(result);
          setDone(true);
          result.topic = location.state.topic;
          result.id = location.state.schid;
          setShow(false);
          navigate("/user/quizResult", { state: { result } });
        };
        ResultsOfQuiz(id);
      })
      .catch((error) => console.log("error", error));
  };

  const handleQClick = async (index, e, id, param1, param) => {
    setOptionsOpted("");
    setVisited(index);
    e.preventDefault();
    await API.postUserhandleClik(
      optionsOpted,
      question[visited].question_pk,
      id
    )
      .then(({ response }) => {
        setOptionsOpted("");
        setQuestIndex(index);
      })
      .catch((error) => console.log("error", error));
    await API.putUserhandleClik(question[index].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(index);
      })
      .catch((error) => console.log("error", error));
    setShow(false);
  };

  return (
    <>
      {showRes({ res })}
      {res ? (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "white",
            padding: "0px !important",
            minHeight: "30vh",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                {errorStatus ? (
                  <b>Quiz has been submitted!! </b>
                ) : (
                  <b>Questions Not Available At The Moment. </b>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                {errorStatus ? (
                  <b>Please check back for results!!</b>
                ) : (
                  <b>Please Try Again After Sometime. </b>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <span onClick={removeQuizDataCookie}>
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="candidateDltepageCancelBttn"
                    buttonName="Close"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#000936",
                      border: "1px solid #000936",
                      padding: "7px 20px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      textTransform: "none",
                    }}
                  />
                </span>
              </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {show ? (
            <>
              <Grid
                item
                xs={9.5}
                style={{
                  backgroundColor: "white",
                  padding: "0px !important",
                  minHeight: "65vh",

                  borderBottomLeftRadius: "10px",
                }}
              >
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "50vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fb2485"}
                    height={100}
                    width={100}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={2.5}
                style={{
                  backgroundColor: "#02215d",
                  padding: "0px !important",
                  minHeight: "65vh",
                  borderBottomRightRadius: "10px",
                  overflow: "auto",
                }}
              >
                <div className="containerOfOptions">
                  {arr1.map((data, index) => {
                    return (
                      <>
                        <Button
                          className="rect1"
                          size="small"
                          onClick={(e) => {
                            handleQClick(
                              index,
                              e,
                              quizId,
                              questIndex,
                              data.question_pk
                            );
                            setShow(true);
                          }}
                        >
                          <QuizLayout
                            qnum={index + 1}
                            color={visited === index ? "#fb2485" : null}
                            ans={answered.includes(index) ? true : false}
                            visit={index == visited ? true : false}
                          />
                        </Button>
                      </>
                    );
                  })}
                </div>
              </Grid>
            </>
          ) : (
            <>
              {callback({ start })}
              {arr1.map((data, index) => {
                const questionname = data.question;
                data.options.selectedOption = data.userOption;
                data.options.questPk = data.question_pk;
                {
                  if (index === questIndex) {
                    return (
                      <>
                        <Grid
                          item
                          xs={9.5}
                          style={{
                            backgroundColor: "white",
                            padding: "0px !important",
                            minHeight: "65vh",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                              <b>
                                {" "}
                                {index + 1}.{questionname}{" "}
                              </b>
                            </Grid>
                            <Grid item xs={1}>
                              {/* <b>
                                {index + 1}/{arr1.length}
                              </b> */}
                            </Grid>
                          </Grid>

                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={optionsOpted}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            {[data.options].map((opt) => {
                              return (
                                <>
                                  {opt.a ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.a.id}
                                            control={<Radio />}
                                            label={opt.a.a}
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.b ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.b.id}
                                            control={<Radio />}
                                            label={opt.b.b}
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.c ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.c.id}
                                            control={<Radio />}
                                            label={opt.c.c}
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.d ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.d.id}
                                            control={<Radio />}
                                            label={opt.d.d}
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </RadioGroup>

                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "15vh",
                            }}
                          >
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={1}>
                              {index === 0 ? null : (
                                <ReusableButton
                                  size="large"
                                  variant="outlined"
                                  className="candidateDltepageCancelBttn"
                                  buttonName="Previous"
                                  style={{
                                    width: "98px",
                                    height: "44px",
                                    flexGrow: 0,
                                    margin: "24px 50px 0 20px",
                                    padding: "13px 32px 13px 33px",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAalign: "center",
                                    color: "#000936",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    textTransform: "none",
                                    border: "1px solid #000936",
                                  }}
                                  onClick={(e) => {
                                    onClickPrevious(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={0.8}></Grid>
                            <Grid item xs={1}>
                              {index + 1 < arr1.length ? (
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  className="candidateDltepageDeleteBttn"
                                  buttonName="Next"
                                  style={{
                                    backgroundColor: "#fb2485",
                                    width: "98px",
                                    height: "44px",
                                    flexGrow: 0,
                                    margin: "24px 50px 0 20px",
                                    padding: "13px 32px 13px 33px",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAalign: "center",
                                    color: "#fff",
                                    padding: "8px 28px",
                                    borderRadius: "4px",
                                    textTransform: "none",
                                  }}
                                  onClick={(e) => {
                                    onClickNext(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                ></ReusableButton>
                              ) : (
                                <>
                                  <ReusableButton
                                    size="large"
                                    variant="contained"
                                    className="candidateDltepageDeleteBttn"
                                    buttonName="Submit"
                                    style={{
                                      backgroundColor: "#fb2485",
                                      width: "98px",
                                      height: "44px",
                                      flexGrow: 0,
                                      margin: "27px 50px 0 20px",
                                      padding: "13px 32px 13px 33px",
                                      fontSize: "16px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "normal",
                                      letterSpacing: "normal",
                                      textAalign: "center",
                                      color: "#fff",
                                      padding: "8px 28px",
                                      borderRadius: "4px",
                                      textTransform: "none",
                                    }}
                                    onClick={(e) => {
                                      handleClickOpenDelModal(
                                        e,
                                        userQuizId,
                                        data.question_pk
                                      );
                                    }}
                                  ></ReusableButton>

                                  <Dialog
                                    open={openModal}
                                    className="dialogBox"
                                    keepMounted
                                    onClose={handleCloseByCancelModal}
                                    aria-describedby="alert-dialog-slide-description"
                                    BackdropProps={{
                                      style: {
                                        backgroundColor: "black",
                                        opacity: "0.5",
                                        boxShadow: "0px 0px 0px 0px !important",
                                      },
                                    }}
                                  >
                                    <DialogContent>
                                      <DialogContentText
                                        id="alert-dialog-slide-description"
                                        style={{ color: "#000" }}
                                      >
                                        Are you sure you want to submit the
                                        quiz?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingBottom: "30px",
                                      }}
                                    >
                                      <ReusableButton
                                        size="large"
                                        variant="outlined"
                                        className="candidateDltepageCancelBttn"
                                        buttonName="CANCEL"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAalign: "center",
                                          color: "#000936",
                                          border: "1px solid #000936",
                                          padding: "7px 20px",
                                          borderRadius: "4px",
                                          marginRight: "8px",
                                        }}
                                        onClick={handleCloseByCancelModal}
                                      />
                                      <ReusableButton
                                        size="large"
                                        variant="contained"
                                        className="candidateDltepageDeleteBttn"
                                        buttonName="OK"
                                        style={{
                                          backgroundColor: "#fb2485",
                                          marginRight: "10px",
                                          fontSize: "16px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAalign: "center",
                                          color: "#fff",
                                          padding: "8px 28px",
                                          borderRadius: "4px",
                                        }}
                                        onClick={(e) => {
                                          onClickSubmit(
                                            e,
                                            userQuizId,
                                            data.question_pk
                                          );
                                        }}
                                      >
                                        Submit
                                      </ReusableButton>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {show ? (
                          <Grid
                            item
                            xs={9.5}
                            style={{
                              backgroundColor: "white",
                              padding: "0px !important",
                              minHeight: "65vh",

                              borderBottomLeftRadius: "10px",
                            }}
                          >
                            <Box
                              sx={{ flexGrow: 1 }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "50vh",
                              }}
                            >
                              <ReactLoading
                                type={"spinningBubbles"}
                                color={"#fb2485"}
                                height={100}
                                width={100}
                              />
                            </Box>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={2.5}
                          style={{
                            backgroundColor: "#02215d",
                            padding: "0px !important",
                            minHeight: "65vh",
                            overflow: "auto",
                          }}
                        >
                          <div className="containerOfOptions">
                            {arr1.map((data, index) => {
                              return (
                                <>
                                  <Button
                                    className="rect1"
                                    size="small"
                                    onClick={(e) => {
                                      handleQClick(
                                        index,
                                        e,
                                        quizId,
                                        questIndex,
                                        data.question_pk
                                      );
                                      setShow(true);
                                    }}
                                  >
                                    <QuizLayout
                                      qnum={index + 1}
                                      color={
                                        visited === index ? "#fb2485" : null
                                      }
                                      ans={
                                        answered.includes(index) ? true : false
                                      }
                                      visit={index == visited ? true : false}
                                    />
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        </Grid>
                      </>
                    );
                  }
                }
              })}
            </>
          )}
        </>
      )}
      <Dialog
        open={showModal}
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Quiz Timed Out!! Please Click ok to Submit
          </DialogContentText>
          <DialogActions>
            <Link to="/user/quizResult" style={{ textDecoration: "none" }}>
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="OK"
                style={{
                  backgroundColor: "#fb2485",
                  marginRight: "10px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
                onClick={(e) => {
                  setShowModal(false);

                  onClickSubmit(e, userQuizId, question[visited].question_pk);
                  setShow(true);
                }}
              ></ReusableButton>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Questions;
