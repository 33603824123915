import { React, useEffect, useState } from "react";
import "./mentor-sessions-details.css";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import List from "./List";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";

export const SearchBar = ({
  setTrainerRole,
  setTrainerName,
  setTrainerID,
  clickStatus,
}) => {
  const [mentorSearch, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [Batchid, setBatchId] = useState();

  useEffect(() => {
    testUsers();
    // setBatchId(Cookies.get("batch_id"));
  }, [mentorSearch, Batchid]);

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };

    const entryTypeCookie = Cookies.get("batch_id");

    if (entryTypeCookie !== null) {
      const parsedEntryType = JSON.parse(entryTypeCookie);
      setBatchId(parsedEntryType);
    }
  });

  const testUsers = async () => {
    API.getSearchedSessions(mentorSearch, Batchid).then(({ response }) => {
      // const users = response.data.data[0].trainee; //orginal code
      const users = response.data.data;
      setContacts(users);
    });
  };
  // 
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    testUsers();
  };
  const setClickStatus = (status) => {
    clickStatus(status);
  };

  return (
    <>
      <div>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            onChange={handleSearchChange}
            value={mentorSearch}
            autoFocus
            style={{ border: "1px solid #9e9e9e", width: "100%" }}
          />
        </Search>
        <List
          contacts={contacts}
          trainerName={setTrainerName}
          trainerRole={setTrainerRole}
          trainerID={setTrainerID}
          clickStatus={setClickStatus}
        />
      </div>
    </>
  );
};
