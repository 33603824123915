import { Box, Grid, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Typography } from "@material-ui/core";
import { Custom } from "../../../components/Custom/Custom";
import { MyForm, Form } from "../../../components/MyForm";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { API } from "../../../Networking/API";
import Notification from "../../../components/Custom/Notification";
import Cookies from "js-cookie";
import "./schedule.css";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const initialValues = {
  description: "",
  source: "",
  modname: "",
};

export const PreRequisite = (props) => {
  const { scheduleId } = props;
  const [modename, setModename] = useState();
  const [schedTopic, setSchedTopic] = useState();
  const [schedID, setSchedID] = useState();
  const [desp, setDesp] = useState();
  const [tableData, setTableData] = useState([]);
  const [deletescheduleId, setDeleteScheduleId] = useState();
  const [deleteprerequisiteId, setDeletePreRequisiteId] = useState();
  const [available, setAvailable] = useState(true);
  const [open, setOpen] = useState(false);
  // const []
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const jwtToken = Cookies.get("jwt_token");

  const SheetJSFT = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("description" in inputValues)
      code.description =
        inputValues.description || desp ? "" : "Description is required";
    if ("modname" in inputValues)
      code.modname =
        inputValues.modname || modename ? "" : "Module Name is required";
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);
  useEffect(() => {
    getScheduleById(scheduleId);
    setTableData([]);
  }, [scheduleId]);

  const getScheduleById = async (scheduleId) => {
    setAvailable(true);
    API.getAdminPrerequisites(scheduleId)
      .then(async ({ response }) => {
        if (response.data.api_status === 200) {
          setSchedID(response.data.data[0].shedule_details[0].schedule_pk);
          setSchedTopic(response.data.data[0].shedule_details[0].topic);
          const prereqdata = response.data.data[0].pre_requisites;
          const updatedData = await DataTableHandler.AdminPrerequisite(
            prereqdata
          );
          setTableData(updatedData);
        }
        if (response.data.api_status === 404) {
          setAvailable(false);
          setTableData([]);
          setSchedID(response.data.data[0].shedule_details[0].schedule_pk);
          setSchedTopic(response.data.data[0].shedule_details[0].topic);
        }
      })
      .catch(function (error) {});
  };
  const handleUploadClick = async (e, scheduleId) => {
    e.preventDefault();
    if (validate()) {
      try {
        API.addAdminPrerequisites(
          scheduleId,
          fileMerge,
          desp || values.description,
          schedTopic,
          values.modname
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setNotify({
              isOpen: true,
              message: response.data[0].msg,
              type: "success",
            });

            getScheduleById(scheduleId);
            resetForm();
          }

          if (resultInJson.api_status === 500) {
            // getScheduleById(id);
            // setShowPre(false);
            setNotify({
              isOpen: true,
              message: response.data[0].msg,
              type: "error",
            });
          }
        });
      } catch (e) {}
    }
  };

  const [fileMerge, setFileMerge] = useState("");

  const handleCloseByCancel = () => {
    setOpen(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteScheduleId(params.row.schedule_pk);
    setDeletePreRequisiteId(params.row.pre_req_pk);
    setOpen(true);
  };
  const handleCloseDelete = (paramPre, paramSchId) => {
    API.deleteAdminPreRequisite(paramPre, paramSchId).then(({ result }) => {
      const response = result;
      if (response.api_status === 200) {
        getScheduleById(scheduleId);
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Deleted Successfully",
          type: "success",
        });
      } else {
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };
  const handleCloseButtonByCancel = () => {
    resetForm();
  };
  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "ModuleName",
      headerName: "Modules",
      flex: 1,
      headerClassName: "scheduler-prerequistes-module-header",
      cellClassName: "scheduler-prerequistes-module-body",
      headerAlign: "left",
      align: "left",
      sortable: false,
    },
    {
      field: "action",
      type: "Action",
      headerName: "Actions",
      flex: 1,
      headerClassName: "scheduler-prerequistes-action-header",
      cellClassName: "scheduler-prerequistes-action-body",
      headerAlign: "right",
      align: "right",
      sortable: false,
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <Tooltip title="Delete">
              <IconButton aria-label="edit" size="small">
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    //fontSize: '14px !important',
                    color: "blue !important",
                  }}
                  onClick={() => handleDeleteInpre(params)}
                />
              </IconButton>
            </Tooltip>
            <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseByCancel}
              BackdropProps={{
                style: {
                  background: "#00000022",
                  boxShadow: "0px 0px 0px 0px !important",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                    {/* "{questions}" */}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    // marginRight:"30px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    handleCloseDelete(deleteprerequisiteId, deletescheduleId)
                  }
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box>
        <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Poppins",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
            >
              Module Name{" "}
              <span style={{ color: "red", fontSize: "12px", fontWeight: 400 }}>
                *
              </span>
            </Typography>
            <span>
              <Custom.Input
                name="modname"
                placeholder="Module Name"
                style={{
                  minWidth: "100%",
                }}
                value={modename || values.modname}
                onChange={handleInputChange}
                error={errors.modname}
                size="small"
              />
            </span>
          </Grid>
        </Grid>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#0A0A0A",
            }}
          >
            Description
            <span style={{ color: "red", fontSize: "12px", fontWeight: 400 }}>
              *
            </span>
          </Typography>
          <textarea
            style={{
              width: "83%",
              height: "17vh",
              boxSizing: "border-box",
              border: "1px solid #ccc",
              borderRadius: "4px",
              paddingLeft: "12px",
              paddingTop: "9px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              color: "#0A0A0A",
              fontSize: "14px",
            }}
            className="description_in_Pre-requisite"
            name="description"
            placeholder="Description"
            onChange={handleInputChange}
            value={desp || values.description}
            error={errors.description}
            // disabled={showEdit}
            multiline
            size="medium"
          />
          {errors.description !== "" ? (
            <span
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                color: "red",
                fontSize: "12px",
              }}
            >
              {errors.description}
            </span>
          ) : null}
        </div>

        <Grid container item xs={12}>
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginTop: "17px",
                marginBottom: "8px",
              }}
              variant="h6"
            >
              Upload Pre-requisite Material{" "}
              <span style={{ color: "red", fontSize: "12px", fontWeight: 400 }}>
                *
              </span>
            </Typography>
            <span>
              <input
                type="file"
                name="file"
                class="upload"
                accept={SheetJSFT}
                style={{
                  fontSize: "0.8em",
                  fontFamily: "Poppins",
                }}
                onChange={handleChange}
              />
            </span>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "11px",
                // fontWeight: 600,
                marginTop: "5px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="p"
            >
              Only .pdf .ppt format allowed
            </Typography>
          </Grid>
        </Grid>
        {tableData.length > 0 ? (
          <>
            <Box sx={{ marginTop: "40px" }}>
              <DataTable
                rows={tableData}
                columns={columns}
                rowHeight={40}
                autoHeight
                style={{ backgroundColor: "white" }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              style={{
                marginTop: "10px",
                backgroundColor: "#fcfbfb",
                color: "#9a9a9a",
                height: "20vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              No Pre-requisites found. Please Add a Pre-requsite
            </Box>
          </>
        )}
        <Box sx={{ marginTop: "30px", display: "flex", justifyContent: "end" }}>
          <ReusableButton
            onClick={handleCloseButtonByCancel}
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "10px",
              marginBottom: "25px",
              textTransform: "none",
            }}
          ></ReusableButton>
          <ReusableButton
            // onClick={handleSubmitButton}
            onClick={(e) => handleUploadClick(e, scheduleId)}
            className="addbutton"
            type="submit"
            size="large"
            variant="contained"
            buttonName="Submit"
            style={{
              backgroundColor: "#fb2485",
              marginBottom: "25px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 30px",
              borderRadius: "4px",
              textTransform: "none",
            }}
          ></ReusableButton>
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
