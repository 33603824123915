import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
const styles = makeStyles((theme) => ({
  main: {
    //   '& .MuiInputLabel-formControl': {
    //  top:"-8px"

    //   },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px,10px) scale(1)",
    },
  },
}));
export default function Dropdown4(props) {
  const {
    name,
    label,
    value,
    size,
    error = null,
    onChange,
    style,
    menustyle,
    placeholder,
    options,
    disabled,
  } = props;
  const classes = styles();
  return (
    <FormControl
      className={classes.main}
      variant="outlined"
      fullWidth
      {...(error && { error: true })}
    >
      <InputLabel>{label} </InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
        disabled={disabled}
      >
        <MenuItem value="" style={menustyle}>
          None
        </MenuItem>
        {options.map((item) => (
          <MenuItem
            key={item.count}
            value={item.title}
            data-option-key={item.count}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
