/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { API } from "../../../../Networking/API";
import Cookies from "js-cookie";
import { Grid, Box, Button, DialogContentText } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactLoading from "react-loading";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hourGlass from "../../../../assets/Images/Hourglass.svg";
import CompleteUnit from "../../../../assets/Images/completeUnit.png";
import Failed from "../../../../assets/Images/failedO.svg";
import QuizHeader from "./user-quiz-header";
import { useNavigate } from "react-router-dom";

const UserQuizQuestions = ({ quizdata, courseId, unitId, setQuizStarted }) => {
  const [topicName, setTopicName] = useState();
  const [question, setQuestion] = useState([]);
  const [optionsOpted, setOptionsOpted] = useState("");
  const [value, setValue] = useState();
  const [questIndex, setQuestIndex] = useState(0);
  const [questId, setQuestId] = useState();
  const [quizId, setQuizId] = useState();
  const [userQuizId, setUserQuizId] = useState();
  const [results, setResults] = useState();
  const [done, setDone] = useState(undefined);
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [visited, setVisited] = useState(0);
  const [answered, setAnswered] = useState([]);
  const [quizNumber01, setQuizNumber01] = useState(quizdata[0].quizNumber);
  const [errorStatus, setErrorStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState([]);
  const [timerOver, setTimerOver] = useState(false);
  const [totalQuetions, setTotalQuetions] = useState();
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState();
  const [achievedPercentage, setAchievedPercentage] = useState();
  const [passPercentage, setPassPercentage] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [showCompletePopUp, setShowCompletePopup] = useState(false);
  const [showQuizTimeOut, setQuizTimeOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (quizNumber01 !== undefined) {
      getAllQuestions(quizNumber01);
    }
  }, [quizNumber01]);

  const handleChange = (event, index) => {
    setValue(event.target.value);
    setOptionsOpted(event.target.value);
    setAnswered([...answered, index]);
  };

  useEffect(() => {
    if (question.length > 0) setQuizTimeOut(true);
  }, [timerOver]);

  const getAllQuestions = async (quizNumber01) => {
    try {
      API.getUserAllQuestions(quizNumber01).then(({ response }) => {
        if (response.api_status === 200) {
          setRes(false);
          setStart(true);
          setDone(true);
          let quiztimer;
          const quizId = response.data[0].schedule_details.user_qz_id;
          if (response.data[0].remianing_duration !== null) {
            const quizRemainingTime = response.data[0].remianing_duration
              .toString()
              .split(".");
            let remainingSeconds;
            if ([undefined, null, "", " "].includes(quizRemainingTime[1])) {
              remainingSeconds = "00";
            } else {
              remainingSeconds = `${quizRemainingTime[1]}`;
            }
            quiztimer = {
              Minutes: `${quizRemainingTime[0]}`,
              Seconds: remainingSeconds,
            };
          } else {
            quiztimer = {
              Minutes: `${response.data[0].countdown}`,
              Seconds: "00",
            };
          }
          localStorage.setItem(
            "QuizId" + quizId + unitId,
            JSON.stringify(quiztimer)
          );
          setQuestion(response.data[0].questions);
          setQuizId(response.data[0].schedule_details.user_qz_id);
          setQuestId(response.data[0].questions[0].question_pk);
          setUserQuizId(response.data[0].schedule_details.user_qz_id);
          setTopicName(response.data[0].schedule_details.topic);
          setOptionsOpted(response.data[0].questions[0].userOption);
          setAnswered(response.data[0].userOptionIdx);
        } else if (response.api_status === 404) {
          setDone(true);
          setRes(true);
        } else if (response.api_status === 400) {
          setDone(true);
          setRes(true);
          setErrorStatus(true);
        } else if (response.api_status === 502) {
          setDone(true);
          setRes(true);
        }
      });
    } catch (e) {
      setRes(true);
    }
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr1 = getUniqueListBy(question, "question");

  const onClickPrevious = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 - 1);
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserOnclick(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        // "here, if you click previous you will get previous qstn option along with selected option"
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    await API.putUserOnclick(question[param1 - 1].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex - 1);
      })
      .catch((error) => console.log("error", error));
    setDone(true);
  };

  const onClickNext = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 + 1);
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserOnClickNext(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        setQuestIndex(questIndex + 1);
        if (response.api_status === 200) {
          nextQuestion(question[param1 + 1].question_pk, id);
          setShow(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const nextQuestion = async (question_pk, id) => {
    await API.putUserOnClickNext(question_pk, id)
      .then(({ response }) => {
        setDone(true);

        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex + 1);
        if (response.api_status === 404) {
          setShow(false);
        }
        setShow(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleClickOpenDelModal = (e, id, param) => {
    setShow(false);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const onClickSubmit = async (e, id, param) => {
    e.preventDefault();
    Cookies.remove("quizdata");
    apiCall(id, param);
    setQuizTimeOut(false);
    setOpenModal(false);
    setDone(false);
  };

  const handleCloseCompletePopup = () => {
    setShowCompletePopup(false);
    localStorage.removeItem("QuizId" + quizId + unitId);
    localStorage.removeItem("minsecs" + quizId + unitId);
    setQuizStarted(false);
  };

  const handleCompleteUnit = () => {
    setShowCompletePopup(false);
    localStorage.removeItem("QuizId" + quizId + unitId);
    localStorage.removeItem("minsecs" + quizId + unitId);
    setQuizStarted(false);
    if (courseId !== null) {
      navigate("/user/courses");
    } else {
      navigate("/user/");
    }
  };

  const apiCall = async (id, param) => {
    const remainingTime = `${timer[0]}.${timer[1]}`;
    await API.postUserAPICall(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        if (response.api_status === 200) {
          const ResultsOfQuiz = async (id) => {
            const { response } = await API.getUserQuizResults(id);
            setDone(true);
            if (response.data.api_status === 200) {
              setShowCompletePopup(true);
              const trackingResponse = await API.TrainingMaterialTrack(
                unitId,
                courseId
              );
              setTotalQuetions(trackingResponse.response.data.total_qns);
              setTotalCorrectAnswers(
                trackingResponse.response.data.correct_ans
              );
              setAchievedPercentage(trackingResponse.response.data.percentage);
              setPassPercentage(trackingResponse.response.data.pass_percent);
            }
          };
          ResultsOfQuiz(id);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <>
            {arr1.map((data, index) => {
              const questionname = data.question;
              data.options.selectedOption = data.userOption;
              data.options.questPk = data.question_pk;
              // eslint-disable-next-line no-lone-blocks
              {
                if (index === questIndex) {
                  return (
                    <>
                      <Grid
                        item
                        xs={9.5}
                        style={{
                          backgroundColor: "white",
                          padding: "0px !important",
                          height: "auto",
                          borderBottomLeftRadius: "10px",
                          marginTop: "-32px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Grid item xs={10}>
                            <b>
                              {" "}
                              {index + 1}.{questionname}{" "}
                            </b>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginRight: "20px" }}>
                              <b>{`${index + 1}/${arr1.length}`}</b>
                            </div>
                            <div>
                              <QuizHeader
                                Id={`${quizId}${unitId}`}
                                setTimer={setTimer}
                                setFinish={setTimerOver}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={optionsOpted}
                          onChange={(e) => {
                            handleChange(e, index);
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          {[data.options].map((opt) => {
                            return (
                              <>
                                {opt.a ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.a.id}
                                          control={<Radio />}
                                          label={opt.a.a}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.b ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.b.id}
                                          control={<Radio />}
                                          label={opt.b.b}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.c ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.c.id}
                                          control={<Radio />}
                                          label={opt.c.c}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.d ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.d.id}
                                          control={<Radio />}
                                          label={opt.d.d}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </RadioGroup>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Grid item xs={1}>
                            {index === 0 ? null : (
                              <ReusableButton
                                size="large"
                                variant="outlined"
                                className="candidateDltepageCancelBttn"
                                buttonName="Previous"
                                style={{
                                  width: "98px",
                                  height: "44px",
                                  flexGrow: 0,
                                  padding: "13px 32px 13px 33px",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#000936",
                                  padding: "8px 12px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                  border: "1px solid #000936",
                                }}
                                onClick={(e) => {
                                  onClickPrevious(
                                    e,
                                    quizId,
                                    questIndex,
                                    data.question_pk
                                  );
                                  setDone(false);
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            {index + 1 < arr1.length ? (
                              <ReusableButton
                                size="large"
                                variant="contained"
                                className="candidateDltepageDeleteBttn"
                                buttonName="Next"
                                style={{
                                  backgroundColor: "#fb2485",
                                  width: "98px",
                                  height: "44px",
                                  flexGrow: 0,
                                  padding: "13px 32px 13px 33px",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "8px 28px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                }}
                                onClick={(e) => {
                                  onClickNext(
                                    e,
                                    quizId,
                                    questIndex,
                                    data.question_pk
                                  );
                                  setDone(false);
                                }}
                              ></ReusableButton>
                            ) : (
                              <>
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  className="candidateDltepageDeleteBttn"
                                  buttonName="Submit"
                                  style={{
                                    backgroundColor: "#fb2485",
                                    width: "98px",
                                    height: "44px",
                                    flexGrow: 0,
                                    padding: "13px 32px 13px 33px",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAalign: "center",
                                    color: "#fff",
                                    padding: "8px 28px",
                                    borderRadius: "4px",
                                    textTransform: "none",
                                  }}
                                  onClick={(e) => {
                                    handleClickOpenDelModal(
                                      e,
                                      userQuizId,
                                      data.question_pk
                                    );
                                  }}
                                ></ReusableButton>

                                <Dialog
                                  open={openModal}
                                  className="dialogBox"
                                  keepMounted
                                  onClose={handleCloseByCancelModal}
                                  aria-describedby="alert-dialog-slide-description"
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "black",
                                      opacity: "0.5",
                                      boxShadow: "0px 0px 0px 0px !important",
                                    },
                                  }}
                                >
                                  <DialogContent>
                                    <DialogContentText
                                      id="alert-dialog-slide-description"
                                      style={{ color: "#000" }}
                                    >
                                      Are you sure you want to submit the quiz?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      paddingBottom: "30px",
                                    }}
                                  >
                                    <ReusableButton
                                      size="large"
                                      variant="outlined"
                                      className="candidateDltepageCancelBttn"
                                      buttonName="CANCEL"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAalign: "center",
                                        color: "#000936",
                                        border: "1px solid #000936",
                                        padding: "7px 20px",
                                        borderRadius: "4px",
                                        marginRight: "8px",
                                        textTransform: "none",
                                      }}
                                      onClick={handleCloseByCancelModal}
                                    />
                                    <ReusableButton
                                      size="large"
                                      variant="contained"
                                      className="candidateDltepageDeleteBttn"
                                      buttonName="OK"
                                      style={{
                                        backgroundColor: "#fb2485",
                                        marginRight: "10px",
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAalign: "center",
                                        color: "#fff",
                                        padding: "8px 28px",
                                        borderRadius: "4px",
                                        textTransform: "none",
                                      }}
                                      onClick={(e) => {
                                        onClickSubmit(
                                          e,
                                          userQuizId,
                                          data.question_pk
                                        );
                                      }}
                                    >
                                      Submit
                                    </ReusableButton>
                                  </DialogActions>
                                </Dialog>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
              }
            })}
          </>
        </>
      )}
      <Dialog
        open={showCompletePopUp}
        className="dialogBox"
        keepMounted
        onClose={handleCloseByCancelModal}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <b
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "29px",
                color: "#000000",
              }}
            >
              {achievedPercentage >= passPercentage
                ? "Congratulations!"
                : "Attempt Failed!"}
            </b>
            <img
              src={achievedPercentage >= passPercentage ? CompleteUnit : Failed}
              alt="congrats=image"
              height="240"
              widthe="220"
            />
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
              }}
            >
              {courseId === null ? (
                <div style={{ textAlign: "center" }}>
                  {achievedPercentage >= passPercentage ? (
                    <>You have successfully completed the {topicName}</>
                  ) : (
                    <>You need to retake the quiz</>
                  )}
                  <br />
                  <>Total number of questions: {totalQuetions} </>
                  <br />
                  <>
                    Number of questions correctly answered:{" "}
                    {totalCorrectAnswers}
                  </>
                  <br />
                  <span
                    style={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "29px",
                      color: "#000000",
                      margin: "8px 0px",
                    }}
                  >
                    {`Score Achieved: ${Math.round(achievedPercentage)}%`}
                  </span>
                  <br />
                  <span
                    style={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "29px",
                      color: "#000000",
                      margin: "0px 0px 8px 0px",
                    }}
                  >
                    {`Pass Percentage:  ${passPercentage}%`}
                  </span>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <>
                    You have successfully completed the ${topicName} of your
                    course
                  </>
                  <>Total number of questions: {totalQuetions} </>
                  <>
                    Number of questions correctly answered:{" "}
                    {totalCorrectAnswers}
                  </>
                </div>
              )}
            </p>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            className="candidateDltepageCancelBttn"
            buttonName="Close"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCloseCompletePopup}
          />
          <ReusableButton
            size="large"
            variant="contained"
            className="candidateDltepageDeleteBttn"
            buttonName="Continue"
            style={{
              backgroundColor: "#fb2485",
              marginRight: "10px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 28px",
              borderRadius: "4px",
              textTransform: "none",
            }}
            onClick={(e) => {
              handleCompleteUnit();
            }}
          >
            Submit
          </ReusableButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showQuizTimeOut}
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Quiz Timed Out!! Please Click ok to Submit
          </DialogContentText>
          <DialogActions>
            <ReusableButton
              size="large"
              variant="contained"
              className="candidateDltepageDeleteBttn"
              buttonName="OK"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "10px",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "8px 28px",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={(e) => {
                setShowModal(false);

                onClickSubmit(e, userQuizId, question[visited].question_pk);
                setShow(true);
              }}
            ></ReusableButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UserQuizQuestions;
