import { Signup } from "../pages/signup-page/signup";
import Login from "../pages/login-page/login";
import UserForgotPassword from "../pages/user-forgot-password/user-forgot-password";
import * as Paths from "../constants/userPath";
import AdminHomePage from "../pages/admin/admin-layout/admin-layout";
import CandidatesList from "../pages/admin/admin-layout/candidates-count";
import { Reports } from "../pages/admin/reports/reports";
import HackathonUpload from "../pages/admin/hackathon-upload/hackathon-upload";
import QuizUpload from "../pages/admin/quiz-upload/quiz-upload";
import { Batch } from "../pages/admin/batch/batch";
import ScheduleHome from "../pages/admin/Schedules/scheduleHome";
import TraineeDashboard from "../pages/trainee/trainee-dashboard/trainee-dashboard";
import TraineeCalender from "../pages/trainee/trainee-session-calendar/trainee-session-calendar";
import TraineeSessionDetails from "../pages/trainee/trainee-session-details/trainee-session-details";
import ChangePassword from "../pages/trainee/Change-Password/changePassword";
import Profile from "../pages/trainee/Profile/Profile";
import QuizQuestions from "../pages/trainee/trainee-session-details/quizQuestions";
import QuizResult from "../pages/trainee/trainee-session-details/quiz-result";
import TrainerDashboard from "../pages/trainer/trainer-dashboard/trainer-dashboard";
import TrainerSession from "../pages/trainer/trainer-session/trainer-session";
import TraineeSessionDetailsAssessed from "../pages/trainer/trainer-sessions-details-assessed/trainer-sessions-details-assessed";
import TrainerSessionHackathon from "../pages/trainer/trainer-session/trainer-session-hackathon";
import MentorDashboard from "../pages/mentor/mentor_dashboard/mentor-dashboard";
import MentorSession from "../pages/mentor/mentor-session/mentor-session";
import MentorSessionDetails from "../pages/mentor/mentor-sessions-details/mentor-sessions-details";
import UnitLayout from "../pages/admin/masterData-layout/createUnit/unitLayout";
import CoursesUnit from "../pages/admin/courses-units/DashBoard";
import { CreateCourse } from "../pages/admin/create-course/createCourse";
import createProgram from "../pages/admin/masterData-layout/program/createProgram";
import userCourses from "../pages/users/user-courses/user-courses";
import UserUnitsDetails from "../pages/users/user-courses-details/user-unit-details";
import DashBoard from "../pages/users/user-dashboard/DashBoard";
import UserProfile from "../pages/users/Profile/Profile";
import QuizQuestionsUser from "../pages/users/user-courses-details/quiz-questions";
import QuizResultUser from "../pages/users/user-courses-details/quiz-result";
import UserChangePassword from "../pages/users/Change-Password/changePassword";
import UserMandatoryTraining from "../pages/users/user-dashboard/MandatoryTraining";
import MandatoryTrainingLayout from "../pages/admin/mandatory-training/mandatoryTraining-layout";
import MandatoryTraningModule from "../pages/users/user-courses-details/MandatoryTraningLayout/mandatoryTraningModule";
import ConnectLogin from "../pages/users/userConnect/connectLogin";
import MandatoryTraningLayout from "../pages/users/user-courses-details/mandatory-training-new/mandatory-training-layout";
import TraineeHomePage from "../pages/trainee/trainee-layout/trainee-layout";

const InitialRoutes = [
  {
    path: Paths.InitialPath.SIGNUP_PATH,
    element: Signup,
  },
  {
    path: Paths.InitialPath.LOGIN_PATH,
    element: Login,
  },
  {
    path: Paths.InitialPath.FORGOTPASSWORD_PATH,
    element: UserForgotPassword,
  },
  {
    path: Paths.InitialPath.CONNECT_LOGIN_PATH,
    element: ConnectLogin,
  },
];

const AdminRoutes = [
  // {
  //   path: Paths.AdminPath.ADMIN_CANDIDATELIST_PATH,
  //   element: CandidatesList,
  // },
  {
    path: Paths.AdminPath.ADMIN_REPORTS_PATH,
    element: Reports,
  },
  {
    path: Paths.AdminPath.ADMIN_UPLOAD_HACKATHON_PATH,
    element: HackathonUpload,
  },
  {
    path: Paths.AdminPath.ADMIN_QUIZ_UPLOAD_PATH,
    element: QuizUpload,
  },
  {
    path: Paths.AdminPath.ADMIN_UPLOAD_SCHEDULE_PATH,
    element: Batch,
  },
  {
    path: Paths.AdminPath.ADMIN_BOOTCAMP_PATH,
    element: ScheduleHome,
  },
  {
    path: Paths.AdminPath.ADMIN_HOME_COURSES,
    element: CoursesUnit,
  },
  {
    path: Paths.AdminPath.ADMIN_CREATE_UNIT,
    element: UnitLayout,
  },
  {
    path: Paths.AdminPath.ADMIN_CREATE_COURSE,
    element: CreateCourse,
  },
  {
    path: Paths.AdminPath.ADMIN_CREATE_PROGRAM,
    element: createProgram,
  },
  {
    path: Paths.AdminPath.ADMIN_CREATE_MANDATORY_TRAINING,
    element: MandatoryTrainingLayout,
  },
];

const TraineeRoutes = [
  {
    path: Paths.TraineePath.TRAINEE_HOME_PATH,
    element: TraineeDashboard,
  },
  {
    path: Paths.TraineePath.TRAINEE_CALENDAR_PATH,
    element: TraineeCalender,
  },
  {
    path: Paths.TraineePath.TRAINEE_SESSION_PATH,
    element: TraineeSessionDetails,
  },
  {
    path: Paths.TraineePath.TRAINEE_CHANGEPASSWORD_PATH,
    element: ChangePassword,
  },
  {
    path: Paths.TraineePath.TRAINEE_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.TraineePath.TRAINEE_QUIZQUESTION_PATH,
    element: QuizQuestions,
  },
  {
    path: Paths.TraineePath.TRAINEE_QUIZRESULT_PATH,
    element: QuizResult,
  },
];

const TrainerRoutes = [
  {
    path: Paths.TrainerPath.TRAINER_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.TrainerPath.TRAINER_HOME_PATH,
    element: TrainerDashboard,
  },
  {
    path: Paths.TrainerPath.TRAINER_SESSION_PATH,
    element: TrainerSession,
  },
  {
    path: Paths.TrainerPath.TRAINER_SESSION_DETAILS_PATH,
    element: TraineeSessionDetailsAssessed,
  },
  {
    path: Paths.TrainerPath.TRAINER_HACKATHON_PATH,
    element: TrainerSessionHackathon,
  },
  {
    path: Paths.TrainerPath.TRAINER_HACAKTHON_DETAILS_PATH,
    element: TraineeSessionDetailsAssessed,
  },
];

const MentorRoutes = [
  {
    path: Paths.MentorPath.MENTOR_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.MentorPath.MENTOR_HOME_PATH,
    element: MentorDashboard,
  },
  {
    path: Paths.MentorPath.MENTOR_SESSION_PATH,
    element: MentorSession,
  },
  {
    path: Paths.MentorPath.MENTOR_SESSION_DETAILS_PATH,
    element: MentorSessionDetails,
  },
];

const UsersRoutes = [
  {
    path: Paths.UsersPath.USER_DASHBOARD,
    element: DashBoard,
  },
  {
    path: Paths.UsersPath.USER_COURSES,
    element: userCourses,
  },
  {
    path: Paths.UsersPath.USER_COURSES_UNITS,
    element: UserUnitsDetails,
  },
  {
    path: Paths.UsersPath.USER_PROFILE_PATH,
    element: UserProfile,
  },
  {
    path: Paths.UsersPath.USER_QUESTIONS_PATH,
    element: QuizQuestionsUser,
  },
  {
    path: Paths.UsersPath.USER_QUIZ_RESULT_PATH,
    element: QuizResultUser,
  },
  {
    path: Paths.UsersPath.USER_CHANGE_PASSWORD_PATH,
    element: UserChangePassword,
  },
  {
    path: Paths.UsersPath.USER_MANDATORY_TRANING_MODULE,
    element: MandatoryTraningLayout,
  },
  {
    path: Paths.UsersPath.USER_MANDATORY_TRAINING,
    element: UserMandatoryTraining,
  },

  //Trainee Goes Here
  {
    path: Paths.UsersPath.TRAINEE_HOME_PATH,
    element: TraineeDashboard,
  },
  {
    path: Paths.UsersPath.TRAINEE_CALENDAR_PATH,
    element: TraineeCalender,
  },
  {
    path: Paths.UsersPath.TRAINEE_SESSION_PATH,
    element: TraineeSessionDetails,
  },
  {
    path: Paths.UsersPath.TRAINEE_CHANGEPASSWORD_PATH,
    element: ChangePassword,
  },
  {
    path: Paths.UsersPath.TRAINEE_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.UsersPath.TRAINEE_QUIZQUESTION_PATH,
    element: QuizQuestions,
  },
  {
    path: Paths.UsersPath.TRAINEE_QUIZRESULT_PATH,
    element: QuizResult,
  },

  //Trainer Goes Here
  {
    path: Paths.UsersPath.TRAINER_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.UsersPath.TRAINER_HOME_PATH,
    element: TrainerDashboard,
  },
  {
    path: Paths.UsersPath.TRAINER_SESSION_PATH,
    element: TrainerSession,
  },
  {
    path: Paths.UsersPath.TRAINER_SESSION_DETAILS_PATH,
    element: TraineeSessionDetailsAssessed,
  },
  {
    path: Paths.UsersPath.TRAINER_HACKATHON_PATH,
    element: TrainerSessionHackathon,
  },
  {
    path: Paths.UsersPath.TRAINER_HACAKTHON_DETAILS_PATH,
    element: TraineeSessionDetailsAssessed,
  },
  // mentor routs goes gere
  {
    path: Paths.UsersPath.MENTOR_PROFILE_PATH,
    element: Profile,
  },
  {
    path: Paths.UsersPath.MENTOR_HOME_PATH,
    element: MentorDashboard,
  },
  {
    path: Paths.UsersPath.MENTOR_SESSION_PATH,
    element: MentorSession,
  },
  {
    path: Paths.UsersPath.MENTOR_SESSION_DETAILS_PATH,
    element: MentorSessionDetails,
  },
];

export const RoutesHandler = {
  InitialRoutes,
  AdminRoutes,
  TraineeRoutes,
  TrainerRoutes,
  MentorRoutes,
  UsersRoutes,
};
