import { React, useState } from "react";
import "./mentor-sessions-details.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Custom } from "../../../components/Custom/Custom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
export const MenteeSessionsDetailsAssessedTasks = ({
  tasks,
  tasksData,
  apiStatus,
  status,
  trainerID,
  asnfkValue,
  taskfkValue,
  getTaskDetails,
  asnidFkValue,
  taskidFkValue,
  paramsValue,
  tasksDetails,
  taskIndex,
}) => {
  const [scoreValue, setScoreValue] = useState([]);

  const dropdownValue = [
    { id: "0", title: "0" },
    { id: "1", title: "1" },
    { id: "2", title: "2" },
    { id: "3", title: "3" },
    { id: "4", title: "4" },
    { id: "5", title: "5" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setScoreValue({
      ...scoreValue,
      [name]: value,
    });
  };

  const handleSubmitButton = async () => {
    const scores = Object.values(scoreValue);
    var size = Object.keys(scoreValue).length;
    let holder = [];
    for (let i = 0; i < size; i++) {
      holder.push({
        user_task_pk: taskfkValue,
        hackparam_pk: paramsValue[i]?.hackparam_pk,
        user_id: trainerID,
        score: scores[i],
        user_hid_fk: taskidFkValue,
      });
    }
    const raw = JSON.stringify(holder);

    API.getMentorTaskDataAssessed(raw).then(({ response }) => {
      if (response.api_status === 200) {
        getTaskDetails();
        holder = [];
      }
    });
  };
  return (
    <>
      {tasks.length === 0 ? (
        <Box
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="error-msg">No Data Found...</span>
        </Box>
      ) : (
        <>
          <Box style={{ display: "flex" }}>
            <span className="assignment-text">
              Assignment : {tasks[taskIndex].task_name}
            </span>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: "15px",
            }}
          >
            <span>
              <b>Start Time :</b> {tasks[taskIndex].task_start_time}
            </span>
            <span>
              <b>End Time :</b> {tasks[taskIndex].task_end_time}
            </span>
          </Box>
          {tasks[taskIndex].as_description !== null ? (
            tasks[taskIndex].as_description.length !== 0 ? (
              <Box style={{ marginTop: "15px", display: "flex" }}>
                <span className="required-text">
                  <b>Required :</b> {tasks[taskIndex].as_description}
                </span>
              </Box>
            ) : null
          ) : null}
          {apiStatus && status === true ? (
            <>
              {tasksDetails.astmt_status === 2 ? (
                <>
                  <Box
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      backgroundColor: "#80808054",
                      padding: "8px 20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submission Status
                        </span>
                        <p className="table-values-text">Assessed</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Time Remaining
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.remain_time}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Last Modified
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.last_update}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Score Achieved
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.score_achived !== "-"
                            ? `${tasksDetails.score_achived} of ${tasksDetails.total_score}`
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginTop: "16px",
                    }}
                  >
                    <span className="attachments">Assignment Attachments</span>

                    <div style={{ marginTop: "16px" }}>
                      {tasksDetails.attachments.map((items, index) => (
                        <a
                          href={items.hacklink}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <Button
                            variant="outlined"
                            startIcon={
                              <RemoveRedEyeOutlinedIcon
                                style={{ fill: "#0078d4" }}
                              />
                            }
                            style={{
                              marginRight: "20px",
                              textDecoration: "none",
                              textTransform: "initial",
                              color: "#000",
                              border: "solid 1px #c9c9c9",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                            }}
                          >
                            {/* Attachments {index + 1} */}
                            <p className="attachment-title">
                              {`Attachment file ${index + 1}`}
                            </p>
                          </Button>
                        </a>
                      ))}
                    </div>
                  </Box>
                  {tasksDetails.score.length != 0 ? (
                    <Box style={{ marginTop: "20px" }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          style={{ padding: "18px 18px 0px 18px" }}
                        >
                          <Box
                            style={{
                              background: "#02215b",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              style={{ paddingLeft: "25px", color: "#fff" }}
                            >
                              Parameters
                            </span>
                            <span
                              style={{ paddingRight: "25px", color: "#fff" }}
                            >
                              Scores Achieved
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <span className="error-msg">
                      Parameters are not yet uploaded for review.
                    </span>
                  )}
                  {tasksDetails.score.map((items, index) => (
                    <>
                      <ListItem
                        className="mentor-list"
                        style={{ padding: "0px" }}
                        key={index}
                        secondaryAction={
                          <span
                            style={{
                              background: "#e9e9e9",
                              color: "#000",
                              padding: "8px 27px",
                              borderRadius: "3px",
                            }}
                          >
                            {items.score_achieved}
                          </span>
                        }
                        disablePadding
                      >
                        <Box
                          role={undefined}
                          // onClick={handleToggle(value)}
                          dense
                          style={{ maxWidth: "75%" }}
                        >
                          <ListItemText
                            className="parameter-list"
                            style={{ paddingLeft: "15px" }}
                            primary={items.hackparam_name}
                          />
                        </Box>
                      </ListItem>
                      <Divider
                        style={{
                          marginTop: "9px",
                          marginBottom: "9px",
                          marginLeft: "15px",
                          marginRight: "15px",
                          backgroundColor: "#bfbfbf",
                        }}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Box
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      backgroundColor: "#80808054",
                      padding: "8px 20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submission Status
                        </span>
                        <p className="table-values-text">Submitted</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Time Remaining
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.remain_time}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Last Modified
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.last_update}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Score Achieved
                        </span>
                        <p className="table-values-text">
                          {tasksDetails.score_achived !== "-"
                            ? `${tasksDetails.score_achived} of ${tasksDetails.total_score}`
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginTop: "16px",
                    }}
                  >
                    <span className="attachments">Assignment Attachments</span>

                    <div style={{ marginTop: "16px" }}>
                      {tasksDetails.attachments.map((items, index) => (
                        <a
                          href={items.hacklink}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <Button
                            variant="outlined"
                            startIcon={
                              <RemoveRedEyeOutlinedIcon
                                style={{ fill: "#0078d4" }}
                              />
                            }
                            style={{
                              marginRight: "20px",
                              textDecoration: "none",
                              textTransform: "initial",
                              color: "#000",
                              border: "solid 1px #c9c9c9",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                            }}
                          >
                            {/* Attachments {index + 1} */}
                            <p className="attachment-title">
                              {`Attachment file ${index + 1}`}
                            </p>
                          </Button>
                        </a>
                      ))}
                    </div>
                  </Box>

                  <Box style={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{ padding: "18px 18px 0px 18px" }}
                      >
                        {paramsValue.length != 0 ? (
                          <Box
                            style={{
                              background: "#02215b",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              style={{ paddingLeft: "25px", color: "#fff" }}
                              className="parameter-col"
                            >
                              Parameters
                            </span>
                            <span
                              style={{ paddingRight: "25px", color: "#fff" }}
                            >
                              Scores Achieved
                            </span>
                          </Box>
                        ) : (
                          <span className="error-msg">
                            Parameters are not yet uploaded for review.
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {paramsValue.map((items, index) => (
                    <>
                      <ListItem
                        className="quiz-list"
                        style={{ marginLeft: "-20px", padding: "0px" }}
                        key={index}
                        secondaryAction={
                          <Custom.Dropdown5
                            name={items.hackparam_pk}
                            placeholder="Score"
                            // label="Score"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            value={scoreValue.items}
                            options={dropdownValue}
                            onChange={handleChange}
                            size="small"
                            style={{
                              height: 40,
                              color: "black",
                            }}
                          ></Custom.Dropdown5>
                        }
                        disablePadding
                      >
                        <Box
                          role={undefined}
                          // onClick={handleToggle(value)}
                          dense
                          style={{ maxWidth: "80%" }}
                        >
                          <ListItemText
                            primary={items.hackparam_name}
                            className="parameter-list"
                            style={{ paddingLeft: "35px" }}
                          />
                        </Box>
                      </ListItem>
                      <Divider
                        style={{
                          marginTop: "9px",
                          marginBottom: "9px",
                          marginLeft: "15px",
                          marginRight: "15px",
                          backgroundColor: "#bfbfbf",
                        }}
                      />
                    </>
                  ))}
                  {tasksDetails.astmt_status === 1 &&
                  tasksDetails.score.length != 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                        marginRight: "30px",
                      }}
                    >
                      {/* {buttonTrue ?  */}
                      <ReusableButton
                        onClick={handleSubmitButton}
                        type="Submit"
                        size="large"
                        variant="contained"
                        buttonName="Submit"
                        style={{
                          backgroundColor: "#fb2485",
                          borderRadius: 4,
                          padding: "8px 36px",
                          width: "117px",
                          height: "38px",
                          flexGrow: 0,
                          margin: " 3px 0 3px 30px",
                        }}
                        className="buttonStyle"
                      />
                      {/* :  */}
                      {/* <></> */}
                      {/* } */}
                    </Box>
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};
