import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export const DataTable = ({
  rows,
  columns,
  pageSize,
  className,
  autoHeight,
  hideFooter,
  rowHeight,
  hideFooterPagination,
  hideFooterSelectedRowCount,
  sx,
  isRowExpandable,
  components,
}) => {
  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        autoHeight={autoHeight}
        rowHeight={rowHeight}
        style={{ backgroundColor: "white" }}
        className={`${className}`}
        hideFooter={hideFooter}
        hideFooterPagination={hideFooterPagination}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        sx={sx}
        isRowExpandable
        components
      />
    </>
  );
};
