import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Notification from "../../../components/Custom/Notification";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { API } from "../../../Networking/API";
import "./schedule.css";

const TrainerDetails = (props) => {
  const { sessionData, batchID, getScheduleSessionDetails, scheduleId } = props;
  const initialValues = {
    trainerDesignation: sessionData.work_posiotion,
    trainerExperience: sessionData.work_experience,
    trainerCertificates: sessionData.certificates,
    backupTrainerDesignation: sessionData.backup_work_posiotion,
    backupTrainerExperience: sessionData.backup_work_experience,
    backupTrainerCertificates: sessionData.backup_certificates,
    trainerId: sessionData.trainer_id,
    backupTrainerId: sessionData.backup_trainer_id,
  };
  const [tscheduleId, setTscheduleId] = useState(sessionData.schedule_pk);
  const [description, setDescription] = useState(
    sessionData.trainer_description
  );
  const [errorDescription, setErrorDescription] = useState("");
  const [showEdit, setShowEdit] = useState(true);
  const [trainers, setTrainers] = useState([]);
  const [Backuptrainers, setBackupTrainers] = useState([]);
  const [Alltrainers, setAllTrainers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleChange = (e) => {
    setDescription(e.target.value);
    setErrorDescription("");
  };
  useEffect(() => {
    getTrainersData(batchID);
  }, [batchID]);

  const getTrainersData = (batchID) => {
    API.getTrainers(batchID).then(({ response }) => {
      if (response.data.api_status === 200) {
        const data = response.data.data[0].trainerlist;
        let AllTraniersData = data.map((each) => {
          const nameSplit = each.user_email.split("@");
          const name = nameSplit[0].split(".");
          const user_name = name.join(" ");
          return {
            id: each.user_pk,
            user_name: user_name,
            user_email: each.user_email,
            work_experience: each.work_experience,
            work_posiotion: each.work_posiotion,
            certificates: each.certificates,
          };
        });

        let updatedData = data.map((each) => {
          if (each.user_pk !== values?.backupTrainerId) {
            const nameSplit = each.user_email.split("@");
            const name = nameSplit[0].split(".");
            const user_name = name.join(" ");
            return {
              id: each.user_pk,
              user_name: user_name,
              user_email: each.user_email,
              work_experience: each.work_experience,
              work_posiotion: each.work_posiotion,
              certificates: each.certificates,
            };
          }
        });

        let updatedBackupData = data.map((each) => {
          if (each.user_pk !== values?.trainerId) {
            const nameSplit = each.user_email.split("@");
            const name = nameSplit[0].split(".");
            const user_name = name.join(" ");
            return {
              id: each.user_pk,
              user_name: user_name,
              user_email: each.user_email,
              work_experience: each.work_experience,
              work_posiotion: each.work_posiotion,
              certificates: each.certificates,
            };
          }
        });

        updatedData = updatedData.filter(function (element) {
          return element !== undefined;
        });

        updatedBackupData = updatedBackupData.filter(function (element) {
          return element !== undefined;
        });
        setTrainers(updatedData);
        setBackupTrainers(updatedBackupData);
        setAllTrainers(AllTraniersData);
      } else {
        setTrainers([]);
      }
    });
  };

  const handleSubmit = async (e) => {
    setShowEdit(!showEdit);
    if (!showEdit) {
      if (description !== "" && description !== null) {
        await API.editTrainerDetails(
          String(tscheduleId),
          description,
          String(values.trainerId),
          String(values.backupTrainerId)
        ).then(({ response }) => {
          setErrorDescription("");
          if (response.api_status === 200) {
            setNotify({
              isOpen: true,
              message: response.data[0].message,
              type: "success",
            });
            getScheduleSessionDetails(parseInt(scheduleId), batchID);
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong!",
              type: "error",
            });
            getScheduleSessionDetails(parseInt(scheduleId), batchID);
          }
        });
      } else {
        setShowEdit(false);
        setErrorDescription("Description is Required");
      }
    }
  };

  const handleCancelPopUp = () => {
    setShowEdit(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleBootcampDropDown = (e) => {
    const data = trainers.find((each) => each.id === e.target.value);
    let UpdatedBackupTranier = Alltrainers.map((Alltranierdata) => {
      if (Alltranierdata.id !== e.target.value) {
        return {
          id: Alltranierdata.id,
          user_name: Alltranierdata.user_name,
          user_email: Alltranierdata.user_email,
          work_experience: Alltranierdata.work_experience,
          work_posiotion: Alltranierdata.work_posiotion,
          certificates: Alltranierdata.certificates,
        };
      }
    });
    UpdatedBackupTranier = UpdatedBackupTranier.filter(function (element) {
      return element !== undefined;
    });
    setBackupTrainers(UpdatedBackupTranier);
    setValues({
      ...values,
      trainerId: e.target.value,
      trainerDesignation: data.work_posiotion,
      trainerExperience: data.work_experience,
      trainerCertificates: data.certificates,
    });
  };

  const handleBackupTrainerDropDown = (e) => {
    const data = trainers.find((each) => each.id === e.target.value);
    let UpdatedTranier = Alltrainers.map((Alltranierdata) => {
      if (Alltranierdata.id !== e.target.value) {
        return {
          id: Alltranierdata.id,
          user_name: Alltranierdata.user_name,
          user_email: Alltranierdata.user_email,
          work_experience: Alltranierdata.work_experience,
          work_posiotion: Alltranierdata.work_posiotion,
          certificates: Alltranierdata.certificates,
        };
      }
    });
    UpdatedTranier = UpdatedTranier.filter(function (element) {
      return element !== undefined;
    });
    setTrainers(UpdatedTranier);
    setValues({
      ...values,
      backupTrainerId: e.target.value,
      backupTrainerDesignation: data.work_posiotion,
      backupTrainerExperiencea: data.work_experience,
      backupTrainerCertificates: data.certificates,
    });
  };

  return (
    <>
      <Grid container spacing={2} style={{ paddingLeft: "7px !important" }}>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Trainer Name
            </span>
            <span style={{}}>
              <Custom.Dropdown7
                name="Select Bootcamp"
                value={values.trainerId}
                onChange={handleBootcampDropDown}
                options={trainers}
                placeholder="Select Source"
                size="small"
                style={{
                  height: 40,
                  fontFamily: "Poppins",
                }}
                disabled={showEdit}
              ></Custom.Dropdown7>
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Designation
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="Designation"
                value={values.trainerDesignation}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Years of Experience
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="Years of Experience"
                value={values.trainerExperience}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              No. Of Certificates
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="No. Of Certificates"
                value={values.trainerCertificates}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ paddingLeft: "7px !important", marginTop: "20px" }}
      >
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Backup Trainer Name
            </span>
            <span style={{}}>
              <Custom.Dropdown7
                name="Select Bootcamp"
                value={values.backupTrainerId}
                onChange={handleBackupTrainerDropDown}
                options={Backuptrainers}
                placeholder="Select Source"
                size="small"
                style={{
                  height: 40,
                  fontFamily: "Poppins",
                }}
                disabled={showEdit}
              ></Custom.Dropdown7>
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Designation
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="Designation"
                value={values.backupTrainerDesignation}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Years of Experience
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="Years of Experience"
                value={values.backupTrainerExperience}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              No. Of Certificates
            </Typography>
            <span>
              <Custom.Input
                name="designation"
                placeholder="No. Of Certificates"
                value={values.backupTrainerCertificates}
                size="small"
                disabled={true}
              />
            </span>
          </div>
        </Grid>
      </Grid>
      <h1
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "20px",
          /* identical to box height, or 125% */
          color: "#0A0A0A",
          marginTop: "40px",
        }}
      >
        About Trainer
      </h1>
      <textarea
        style={{
          width: "100%",
          // height: "37vh",
          height: "20vh",
          boxSizing: "border-box",
          border: "1px solid #ccc",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingTop: "9px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          color: "#0A0A0A",
          fontSize: "16px",
        }}
        placeholder="Description"
        onChange={handleChange}
        value={description}
        error={errorDescription}
        disabled={showEdit}
        multiline
        size="medium"
      />
      {errorDescription !== "" ? (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "red",
            fontSize: "12px",
          }}
        >
          {errorDescription}
        </span>
      ) : null}
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <ReusableButton
          onClick={handleCancelPopUp}
          size="large"
          variant="outlined"
          buttonName="Cancel"
          style={{
            marginRight: "10px",
            color: "#000936",
            border: "1px solid #000936",
            marginBottom: "40px",
            width: "98px",
            height: "38px",
            borderRadius: 4,
          }}
        >
          Cancel
        </ReusableButton>
        <ReusableButton
          onClick={(e) => handleSubmit(e)}
          size="large"
          variant="contained"
          buttonName={showEdit ? "Edit" : "Save"}
          style={{
            backgroundColor: "#fb2485",
            marginBottom: "40px",
            width: "98px",
            height: "38px",
            borderRadius: 4,
          }}
        >
          {showEdit ? "Save" : "Edit"}
        </ReusableButton>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default TrainerDetails;
