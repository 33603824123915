import { API } from "../Networking/API";

export const GetProgramHelper = async (
  id,
  setProgramData,
  formik,
  setSelectedUnits,
  setSelectedCourse,
  setDone,
  setIsSaved,
  setSelectedValue
) => {
  let ApiData = [];
  await API.getProgramById(id).then(async ({ response }) => {
    if (response.data.api_status === 200) {
      setDone(false);
      const updatedresponse = response.data.data;
      setProgramData(updatedresponse);
      formik.values.programName = updatedresponse.program_name;
      formik.values.technology = updatedresponse.technology;
      formik.values.startDate = updatedresponse.start_date;
      formik.values.endDate = updatedresponse.end_date;
      formik.values.duration = updatedresponse.duration;
      formik.values.description = updatedresponse.about_program;
      formik.values.TraningType = updatedresponse.training_type;
      ApiData = updatedresponse.programSchedules;
      setSelectedValue(
        updatedresponse.batch_type === 1 ? 0 : updatedresponse.batch_type
      );
      setIsSaved(true);
    }
  });
  setDone(false);
  return ApiData;
};

export const GetBootCampData = async (
  id,
  setProgramData,
  formik,
  setSelectedUnits,
  setSelectedCourse,
  setDone,
  setIsSaved,
  setBootCampDate,
  setSelectedValue
) => {
  let ApiData = [];
  await API.getBootCampById(id).then(async ({ response }) => {
    const updatedresponse = response.data.data;

    formik.values.programName = updatedresponse.batch_name;
    formik.values.startDate = updatedresponse.batch_start;
    formik.values.endDate = updatedresponse.batch_end;
    ApiData = updatedresponse.schedules;
    formik.values.description = updatedresponse.description;
    setBootCampDate(updatedresponse.batch_start);
    setSelectedValue(
      updatedresponse.batch_type === 0 ? 1 : updatedresponse.batch_type
    );
    setIsSaved(true);
  });
  setDone(false);
  return ApiData;
};

export const uploadParticipant = async (
  id,
  file,
  setNotify,
  setIsSuccessUpload,
  selectTypeCheck
) => {
  await API.uploadProgramParticipant(id, file, selectTypeCheck).then(
    async ({ response }) => {
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "Uploaded Participants successfully",
          type: "success",
        });
        setIsSuccessUpload(true);
        return true;
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "Error",
        });
        setIsSuccessUpload(false);
        return false;
      }
    }
  );
};

export const uploadBootCampParticipant = async (
  id,
  file,
  setNotify,
  setIsSuccessUpload,
  selectTypeCheck,
  getParticipantsOfBootCamp
) => {
  await API.uploadBootCampParticipant(id, file, selectTypeCheck).then(
    async ({ response }) => {
      getParticipantsOfBootCamp();
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "Uploaded Participants successfully",
          type: "success",
        });
        setIsSuccessUpload(true);
        return true;
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "Error",
        });
        setIsSuccessUpload(false);
        return false;
      }
    }
  );
};

export const editProgramWithTrainer = async (setNotify) => {
  await API.updateProgramWithTrainer().then(async ({ response }) => {
    if (response.api_status === 200) {
      setNotify({
        isOpen: true,
        message: "Updated details successfully",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: response.error[0].error_msg,
        type: "Error",
      });
    }
  });
};
const DeleteProgramHelper = async (programId, setOpen, setNotify, open) => {
  const id = programId;
  await API.AdminDeleteProgram(id).then(async (response) => {
    if (response.result.api_status === 200) {
      setNotify({
        isOpen: true,
        message: response.result.data[0].message,
        type: "success",
      });
      return true;
    } else if (response.result.error[0].error_msg === 404) {
      setNotify({
        isOpen: true,
        message: response.result.error[0].error_msg,
        type: "success",
      });
      return false;
    } else {
      setNotify({
        isOpen: true,
        message: response.result.error[0].error_msg,
        type: "success",
      });
      return false;
    }
  });
};
export { DeleteProgramHelper };
