import React, { useEffect } from "react";

import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@material-ui/core";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import "./CustomDatagrid.css";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
const styles = makeStyles((theme) => ({
  listTxt: {
    "& .MuiListItem-root": {
      fontSize: "14px",
    },
    sub: {
      "& .MuiListSubheader-root": {
        color: " #dedede !important",

        position: "relative !important",
      },
    },
  },
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, props }) => ({
  border: `${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", marginLeft: "5px", display: props.status }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  minHeight: "0px",
  height: "40px",
  padding: "0px",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(255, 255, 255, .05)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    //marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),

  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomDataGrid(props) {
  const { rowData, rowQuestionsData, rowTypes } = props;
  const classes = styles();
  const createData = (information, status) => {
    return { information, status };
  };
  const rows = [];

  for (let i = 0; i < rowTypes.length; i++) {
    rows.push(createData(rowTypes[i], Object.values(rowData)[i]));
  }

  // const rows = [
  //   createData('Number Questions', rowData.totalquest),
  //   createData('Correct Answers', rowData.correctquest),
  //   createData('Percentage', `${rowData.percentagescored} %`),
  // ];

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <List sx={{ minWidth: 650 }}>
      <ListSubheader
        component="div"
        id="nested-list-subheader"
        className={[classes.sub, "subHeader"]}
        sx={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          backgroundColor: "#dedede",
          padding: "16px",
          lineHeight: "10px",
        }}
      >
        <Grid container>
          <Grid item xs={9}>
            <span className="spantxt">Information</span>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <span className="spantxt" style={{ paddingLeft: 0 }}>
              User Option
            </span>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <span className="spantxt" style={{ paddingLeft: 0 }}>
              Status
            </span>
          </Grid>
        </Grid>
      </ListSubheader>

      {rows.map((row, index) => (
        <>
          {index === 0 ? (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  status="block"
                >
                  <Grid container>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={9.5}>
                        <span className={"gridtxt setMargin"}>
                          {row.information}
                        </span>
                      </Grid>
                      <Grid item xs={1.25}></Grid>
                      <Grid item xs={1} sx={{ textAlign: "center" }}>
                        <span className={"gridtxt spanGrid"}>{row.status}</span>
                      </Grid>
                    </ListItem>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className="acnt">
                  {rowQuestionsData.quizQuestions.map((item, index) => {
                    return (
                      <>
                        <Grid container>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={9}>
                              <span
                                className={"gridtxt2 gridtxt"}
                                sx={{ textAlign: "center" }}
                              >
                                {item.question}
                              </span>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "center" }}>
                              <span className={"gridtxt2 gridtxt spanGrid"}>
                                {item.UserQuizResult?.user_option}
                              </span>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: "center" }}>
                              <span className={"gridtxt2 gridtxt spanGrid"}>
                                {item.UserQuizResult.ans_type}
                              </span>
                            </Grid>
                          </ListItem>
                        </Grid>
                        {index !== rowQuestionsData.quizQuestions.length - 1 ? (
                          <Divider className="div2" />
                        ) : null}
                      </>
                      // </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <Grid container>
              <ListItem
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={9.5}>
                  <span className={"gridtxt"} sx={{ textAlign: "center" }}>
                    {row.information}
                  </span>
                </Grid>
                <Grid item xs={1.25}></Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                  <span className={"gridtxt spanGrid"}>{row.status}</span>
                </Grid>
              </ListItem>
            </Grid>
          )}

          <Divider />
        </>
      ))}
    </List>
  );
}

export default CustomDataGrid;
