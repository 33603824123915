import * as React from "react";
import { Box, Paper } from "@mui/material";
import "./mentor-sessions-details.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactLoading from "react-loading";
import { MenteeDetails } from "./mentee-details";
import { useNavigate } from "react-router-dom";
import { parse } from "date-fns/esm";

export default function MentorSessionDetails() {
  const [scheduleData, setScheduleData] = useState();
  const [done, setDone] = useState(undefined);
  const [Quizz_pk, setQuizz_pk] = useState();
  const [scheduleID, setScheduleID] = useState();
  const [scheduleType, setScheduleType] = useState();
  const [assignments, setAssignments] = useState([]);
  const [totalTasks, setTasks] = useState([]);
  const location = useLocation();
  const id = location?.state?.data;

  let navigate = useNavigate();

  useEffect(() => {
    getTrainerSessionDetails();
  }, []);

  const getTrainerSessionDetails = async () => {
    API.getSessionDetailsMentor(id).then(({ response }) => {
      // 
      if (response.data.api_status === 200) {
        // 
        // 
        const schedule = response.data.data[0].schedule;
        setQuizz_pk(response?.data?.data[0]?.quizz[0]?.quizz_pk);
        setScheduleData(schedule);
        setScheduleType(response?.data?.data[0]?.schedule?.schedule_type);
        setScheduleID(schedule.schedule_pk);
        setAssignments(response?.data?.data[0]?.assignments);
        setTasks(response?.data?.data[0]?.total_tasks);
        setDone(true);
      } else if (response.data.api_status === 403) {
        Cookies.remove("jwt_token");
        navigate("/");
      }
    });
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <div style={{ padding: "20px" }}>
            <Box>
              <h1 style={{ marginTop: "40px" }} className="training-session">
                Session Details
              </h1>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "0px 0px 20px 24px",
                }}
              >
                <ReusableButton
                  onClick={() => navigate("/user/bootcamp/mentor/home/")}
                  buttonName="Back"
                  startIcon={<ArrowBackIosIcon fontSize="small" />}
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    fontFamily: "Helvetica",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#02215b",
                    padding: "10px 20px",
                    margin: "-40px 0px 0px 0px",
                    textTransform: "initial",
                  }}
                  variant="outlined"
                  size="medium"
                />
              </Box>
            </Box>

            {scheduleData ? (
              <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                <Box>
                  <Paper className="paper-box">
                    {/* <Box className="first-box"> */}
                    <Box
                      className={
                        scheduleData?.schedule_type === 0
                          ? "firstbox-background"
                          : "firstbox-background22"
                      }
                    >
                      {" "}
                      <Box className="mentor-top-box">
                        <div className="trainer-date">
                          <span style={{ marginLeft: "20px" }}>
                            {scheduleData?.training_date}
                          </span>
                        </div>
                        <div
                          className={
                            scheduleData?.day === "Pending"
                              ? "today"
                              : scheduleData?.day === "Assessed"
                              ? "completed"
                              : "further1"
                          }
                        >
                          {scheduleData?.day}
                        </div>
                      </Box>
                    </Box>
                    {/* </Box> */}

                    <Box className="second-box">
                      <Box
                        className="top-box-two"
                        style={{ paddingBottom: "20px" }}
                      >
                        <span className="container1">{scheduleData.topic}</span>
                        <div>
                          <span className="time">Time :</span>
                          <span> {scheduleData?.time} IST</span>
                          <br />
                          <span className="duration">Duration :</span>
                          <span> {scheduleData?.duration} Hours</span>
                        </div>
                      </Box>
                      {scheduleData?.description !== null &&
                      scheduleData?.description.length !== 0 ? (
                        <>
                          <Box className="inner-div">
                            <span style={{ margin: "20px 0px 10px 0px" }}>
                              <b>About Course</b>
                            </span>
                            <span>{scheduleData?.description}</span>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Paper>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Paper className="mentor-session-paper">
                  <span className="mentor-error-text">No Data Found !</span>
                </Paper>
              </Box>
            )}

            <Box sx={{ flexGrow: 1, margin: "20px", marginBottom: "7%" }}>
              <MenteeDetails
                totalTasks={totalTasks}
                scheduleType={scheduleType}
                scheduleID={scheduleID}
                assignments={assignments}
                topic={scheduleData.topic}
                Quizz_pk={Quizz_pk}
              />
            </Box>
          </div>
        </>
      )}
    </>
  );
}
