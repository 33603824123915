import * as React from "react";
import { Box, Paper } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { API } from "../../../Networking/API";
import "./trainer-sessions-details-assessed.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactLoading from "react-loading";
import Notification from "../../../components/Custom/Notification";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TraineeDetails } from "./trainee-details";

export default function TraineeSessionDetailsAssessed() {
  const [scheduleData, setScheduleData] = useState();
  const [scheduleID, setScheduleID] = useState();
  const [done, setDone] = useState(undefined);
  const [assignments, setAssignments] = useState([]);
  const [training_materials, setTraining_Material] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [totalTasks, setTasks] = useState([]);
  const [prerequisite, setPreRequisite] = useState([]);

  const [scheduleType, setScheduleType] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openModal, setOpenModal] = useState(false);

  const location = useLocation();
  const id = location?.state?.data;
  const Api1 = API.Api1;
  const jwtToken = Cookies.get("jwt_token");
  let navigate = useNavigate();

  const getTrainerSessionDetails = async (id) => {
    API.getTrainerSessionDetailsFunc(id).then(({ response }) => {
      if (response.data.api_status === 200) {
        const schedule = response.data.data[0].schedule;
        setScheduleData(schedule);
        setScheduleType(response.data.data[0].schedule.schedule_type);
        setScheduleID(schedule.schedule_pk);
        setTraining_Material(response.data.data[0].training_material);
        setPreRequisite(response.data.data[0].pre_requisite);
        if (response.data.data[0].training_material) {
          setAssignments(response.data.data[0].assignments);
        }

        if (response.data.data[0].quizz) {
          setQuiz(response.data.data[0].quizz);
        }
        setTasks(response.data.data[0].total_tasks);

        setDone(true);
      } else if (response.data.api_status === 403) {
        Cookies.remove("jwt_token");
        navigate("/");
      }
    });
  };

  const openEnableQuizPopUp = () => {
    setOpenModal(true);
  };
  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const onClickEnableQuiz = async (e, id, param) => {
    e.preventDefault();
    apiCall(id, param);
  };

  const apiCall = async (id, param) => {
    API.enableTrainerQuizFunc.then(({ response }) => {
      if (response.data.api_status === 200) {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Quiz Enabled Successfully",
          type: "success",
        });
      }
      if (response.data.api_status === 404) {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Quiz Time Expired, Cannot Enable Quiz",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    getTrainerSessionDetails(id);
  }, []);
  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <div style={{ padding: "50px 20px 20px 20px" }}>
            <Box>
              <h1 className="training-session-trainer">Session Details</h1>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0px 0px 20px 24px",
                }}
              >
                <ReusableButton
                  buttonName="Back"
                  startIcon={<ArrowBackIosIcon fontSize="small" />}
                  style={{
                    borderRadius: "4px ",
                    backgroundColor: "#fff",
                    fontFamily: "Helvetica",
                    fontSize: "16px ",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center ",
                    color: "#02215b",
                    padding: "10px 20px ",
                    textTransform: "initial",
                  }}
                  onClick={() => navigate("/user/bootcamp/trainer/home/")}
                  variant="outlined"
                  size="medium"
                />

                {/* {quiz.length > 0 ? (
                  <>
                    <ReusableButton
                      buttonName="Enable Quiz"
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#fb2485",
                        fontFamily: "Helvetica",
                        fontSize: "16px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#fff",
                        padding: "10px 20px",
                        textTransform: "initial",
                        marginRight: "20px",
                      }}
                      onClick={openEnableQuizPopUp}
                      variant="outlined"
                      size="medium"
                    />

                    <Dialog
                      open={openModal}
                      className="dialogBox"
                      // TransitionComponent={Transition}
                      keepMounted
                      onClose={handleCloseByCancelModal}
                      aria-describedby="alert-dialog-slide-description"
                      sx={{ background: "rgba(0, 0, 0, 0.52)" }}
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.52)",
                          opacity: "0.5",
                          boxShadow: "0px 0px 0px 0px !important",
                        },
                      }}
                    >
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-slide-description"
                          style={{ color: "#000" }}
                        >
                          Are you sure, You want to enable the quiz for{" "}
                          <b>{scheduleData.topic}</b> ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "30px",
                        }}
                      >
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          className="candidateDltepageCancelBttn"
                          buttonName="No"
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAalign: "center",
                            color: "#000936",
                            border: "1px solid #000936",
                            padding: "7px 20px",
                            borderRadius: "4px",
                            marginRight: "8px",
                            textTransform: "none",
                            // marginBottom:"25px",
                            // textTransform: "none",
                          }}
                          onClick={handleCloseByCancelModal}
                        />
                        <ReusableButton
                          size="large"
                          variant="contained"
                          className="candidateDltepageDeleteBttn"
                          buttonName="Yes"
                          style={{
                            backgroundColor: "#fb2485",
                            marginRight: "10px",
                            // marginBottom:"25px",
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAalign: "center",
                            color: "#fff",
                            padding: "8px 28px",
                            borderRadius: "4px",
                            textTransform: "none",
                          }}
                          // onClick={() => handleDelete(id)}
                          onClick={(e) => {
                            onClickEnableQuiz(
                              e,
                              quiz[0].schedule_id,
                              quiz[0].quizz_pk
                            );
                          }}
                        ></ReusableButton>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <></>
                )} */}
              </Box>
            </Box>
            <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
              <Box>
                <Paper className="paper-box">
                  <Box
                    className={
                      scheduleData.schedule_type === 0
                        ? "firstbox-background"
                        : "firstbox-background24"
                    }
                  >
                    <Box className="top-box">
                      <div className="trainer-date">
                        {scheduleData.training_date}
                      </div>
                      <div
                        className={
                          scheduleData.day === "Today"
                            ? "today"
                            : scheduleData.day === "Assessed"
                            ? "completed"
                            : "further1"
                        }
                      >
                        {scheduleData.day}
                      </div>
                    </Box>
                  </Box>

                  <Box className="second-box">
                    <Box className="top-box">
                      <div className="container1">{scheduleData.topic}</div>
                      {/* <div className="container1">
                        {scheduleData.description}
                      </div> */}
                      <div style={{ display: "grid" }}>
                        <span className="time">Time : {scheduleData.time}</span>
                        {/* <span>&nbsp; </span> */}

                        <span className="duration" style={{ marginTop: "8px" }}>
                          Duration : {scheduleData.duration} Hours
                        </span>
                        {/* <span>&nbsp; </span> */}
                      </div>
                    </Box>
                    <Box className="inner-div">
                      {/* <span style={{ margin: "10px 0px 10px 0px" }}>
                        {scheduleData.day !== "completed" ? null : (
                          <b>About Course</b>
                        )}
                      </span> */}
                      <span style={{ textTransform: "capitalize" }}>
                        {scheduleData.topic_descp}
                      </span>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1, margin: "20px", marginBottom: "7%" }}>
              <TraineeDetails
                scheduleID={scheduleID}
                prerequisite={prerequisite}
                training_materials={training_materials}
                assignments={assignments}
                scheduleType={scheduleType}
                totalTasks={totalTasks}
                quiz={quiz}
                scheduleData={scheduleData}
              />
            </Box>
          </div>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
