import {
  React,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { API } from "../../../../Networking/API";
import "./createProgram.css";
import Notification from "../../../../components/Custom/Notification";
import Container from "@mui/material/Container";
import moment from "moment";
import Card from "@mui/material/Card";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { Custom } from "../../../../components/Custom/Custom";
import AddCourseUnitModal from "../masterDataModal/addCourseUnitModal";
import { SxStyles } from "../../../../styles/styles";
import { useFormik } from "formik";
import { createProgramValidationSchema } from "../../../../Validation/FieldValidatorSchema";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { UnitAddtionHandler } from "../../../../handler/addCourseHandler";
import { TechnologyConst } from "../../../../constants/adminCreateUnit";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import { UnitReorderCall } from "../../../../helper/unitHelper";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CollapsibleTable from "../../../../components/reusable-datagrid/ProgramDataGridAccordian";
import {
  GetProgramHelper,
  uploadParticipant,
  uploadBootCampParticipant,
  GetBootCampData,
} from "../../../../helper/programHelper";
import { DeleteOutline } from "@mui/icons-material";
import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataTableHandler } from "../../../../handler/dataTableHandler";
import TimePicker from "react-time-picker";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@material-ui/core";
import { fontSize } from "@mui/system";
import downloadCSV from "../../../../helper/csvHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2.5,
  boxShadow: 24,
  p: 4,
};
const courseId = window.localStorage.getItem("courseId");
let st = {
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
};
const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const CreateProgram = () => {
  let navigate = useNavigate();
  const styles = SxStyles.styles;
  const [courseData, setCoursedata] = useState([]);
  const [SubmitHandle, SetSubmitHandle] = useState(false);
  const inputRef = useRef();
  const [BootCampDate, setBootCampDate] = useState(null);
  const [programLocal, setProgramLocal] = useState(
    localStorage.getItem("programId") ? localStorage.getItem("programId") : []
  );
  const [isBootCamp, setIsBootCamp] = useState(
    localStorage.getItem("IsBootCamp")
  );
  useEffect(() => {
    setIsBootCamp(localStorage.getItem("IsBootCamp"));
  }, [localStorage.getItem("IsBootCamp")]);
  const [disabled, setDisabled] = useState(false);
  const [id, setId] = useState("");
  const [date, setActiveDate] = useState();
  const [units, setUnits] = useState([]);
  const [open, setOpen] = useState(false);
  const [addUnitDialog, setAddUnitDialog] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let activeDate;
  const [pageCount, setPageCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [selectedUnitData, setselectedUnitData] = useState(() => new Set());
  const [checkboxId, setCheckboxId] = useState(() => new Set());
  const [courseDifficultyLevel, setCourseDifficultyLevel] = useState(0);
  const [totalUnitData, setTotalUnitData] = useState([]);
  const [removeUnit, setRemoveUnit] = useState(false);
  const TodayDate = moment().format("YYYY-MM-DD");
  const currentDate = new Date();
  const [unitSelectedTag, setUnitSelectedTag] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [programDate, setProgramDate] = useState();
  const [search, setSearch] = useState();
  const [trainers, setTrainers] = useState();
  const [BackupTrainer, setBackupTrainer] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [selectedTrainer, setSelectedTrainer] = useState();
  const [unitId, setUnitId] = useState();
  const [courseIdInEdit, setCourseId] = useState();
  const [participantsProgram, setParticipantProgram] = useState();
  const [mentorProgram, setMentorProgram] = useState([]);

  const [fieldValue, setFieldValue] = useState();
  const [fieldValueMentor, setFieldValueMentor] = useState();

  const [isSucccessUpload, setIsSuccessUpload] = useState();
  const [trainerAddLoader, setTrainerAddLoader] = useState(false);
  const [selectType, setSelectType] = useState([]);

  const [mentorId, setMentorId] = useState("");
  const [traineeId, setTraineId] = useState("");
  const [selectId, setSelectId] = useState(0);
  const [isProgram, setIsProgram] = useState(true);

  const handleClickOpenParticipantModal = (id, programId) => {
    setOpenParticipantModal(true);
    setSelectId(id);
  };

  const handleCloseByCancelParticipantModal = () => {
    setOpenParticipantModal(false);
  };
  const handleCloseByCancelMentor = () => {
    setOpenMentorModal(false);
  };

  const handleDeleteParticipant = async (paramsId, programLocal) => {
    if (isBootCamp === "false") {
      API.deleteAdminProgramCandidate(paramsId, programLocal).then(
        ({ response }) => {
          if (response.api_status === 200) {
            setOpenParticipantModal(false);
            setSelectId(0);
            handleGetProgram(programLocal);
            setNotify({
              isOpen: true,
              message: "Candidate deleted successfully",
              type: "success",
            });
          } else {
            setOpenParticipantModal(false);
            handleGetProgram(programLocal);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        }
      );
    } else {
      API.deleteAdminBootCamp(paramsId, programLocal).then((response) => {
        if (response.result.api_status === 200) {
          getParticipantsOfBootCamp();
          setOpenParticipantModal(false);
          handleGetProgram(programLocal);
          setNotify({
            isOpen: true,
            message: "Candidate deleted successfully",
            type: "success",
          });
        } else {
          setOpenParticipantModal(false);
          handleGetProgram(programLocal);
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };
  const handleDeleteModal = (param) => {
    setParams(param);
    setOpenModal(true);
  };
  const handleProgramUnitorCourseDelete = async (
    programId,
    courseId,
    unitId
  ) => {
    if (isBootCamp === "false") {
      API.AdminDeleteProgramUnitCourse(programId, courseId, unitId).then(
        ({ response }) => {
          if (response.api_status === 200) {
            setOpenModal(false);
            setNotify({
              isOpen: true,
              message: "Unit or Course deleted successfully",
              type: "success",
            });
            handleGetProgram(programLocal);
          } else {
            setOpenModal(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        }
      );
    } else {
      DeleteBootCampUnit(unitId);
    }
  };

  const handleDeleteMentorParticipant = async (mentorId, traineeId) => {
    console.log(mentorId, "mentorId");
    console.log(traineeId, "traineeId");
    API.AdminDeleteMentor(
      mentorId,
      traineeId,
      localStorage.getItem("programId")
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setOpenMentorModal(false);
        setNotify({
          isOpen: true,
          message: "Mentor Deleted successfully",
          type: "success",
        });
        getMentorOfProgram();
        setOpenMentorModal(false);
      } else {
        setOpenMentorModal(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
        setOpenMentorModal(false);
      }
    });
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "Name",
      headerName: "Collaborator Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Email",
      headerName: "Email ID",
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <IconButton aria-label="edit" size="small">
              <DeleteOutline
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  color: "blue !important",
                }}
                onClick={() =>
                  handleClickOpenParticipantModal(params.id, programLocal)
                }
              />
            </IconButton>
          </span>
        </>
      ),
    },
  ];

  const participantsSampleCsv = {
    data: [
      {
        "First Name": "",
        "Last Name": "",
        Email: "",
        "Phone Number": "",
        Department: "",
        Collage: "",
      },
    ],
    filename: "sampleParticipantsFile",
  };
  const mentorSampleCsv = {
    data: [
      {
        Trainee_name: "",
        Trainee_Email: "",
        Phone_Number: "",
        College: "",
        Mentor_Email: "",
        Mentor_name: "",
      },
    ],
    filename: "sampleMentorFile",
  };

  const handleDeleteIcon = (params) => {
    setOpenMentorModal(true);
    setTraineId(params.row.TraineeId);
    setMentorId(params.row.MentorId);
  };

  const Mentorscolumns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "MentorName",
      headerName: "Mentor Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Name",
      headerName: "Collaborator Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Email",
      headerName: "Email ID",
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "MentorEmail",
      headerName: "Mentor Email ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <>
          {console.log(params, "checking params data")}
          <span className="editbutton">
            <IconButton aria-label="edit" size="small">
              <DeleteOutline
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  color: "blue !important",
                }}
                onClick={() => handleDeleteIcon(params)}
              />
            </IconButton>
            <Dialog
              open={openMentorModal}
              className="dialogBox"
              keepMounted
              onClose={handleCloseByCancelMentor}
              aria-describedby="alert-dialog-slide-description"
              sx={SxStyles.DialogBoxStyle}
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={SxStyles.styles.cancelButton}
                  onClick={handleCloseByCancelMentor}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={styles.createBtn}
                  onClick={() =>
                    handleDeleteMentorParticipant(mentorId, traineeId)
                  }
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const participantRows = [
    {
      id: 1,
      sno: "1",
      Name: "Sathya",
      Email: "sathyasai.vajhala@absyz.com",
      Designation: "Full Stack",
    },
    {
      id: 2,
      sno: "2",
      Name: "Sathya",
      Email: "sathyasai.vajhala@absyz.com",
      Designation: "Full Stack",
    },
  ];

  const newParticipant = participantsProgram?.map((item, index) => {
    return {
      id: item.user_pk,
      sno: index + 1,
      Name:
        item.first_name && item.last_name
          ? item.first_name + " " + item.last_name
          : item.first_name,
      Email: item.user_email,
      Designation: item.department,
    };
  });

  const newMentorList =
    mentorProgram &&
    mentorProgram?.map((item, index) => {
      return {
        id: item.user_pk,
        sno: index + 1,
        Name:
          item.first_name && item.last_name
            ? item.first_name + item.last_name
            : item.first_name,
        Email: item.user_email,
        Designation: item.department !== null ? item.department : "NA",
        MentorId: item?.traineeDetails?.mentor_id,
        TraineeId: item?.traineeDetails?.trainee_id,
        MentorEmail: item?.traineeDetails?.mentorDetails?.user_email,
        MentorName: item?.traineeDetails?.mentorDetails?.first_name,
      };
    });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [done, setDone] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [unitPageCount, setUnitPageCount] = useState(0);
  const [loaderForSaveLaterCourse, setloaderForSaveLaterCourse] =
    useState(false);
  const [loaderForPublishCourse, setloaderForPublishCourse] = useState(false);
  const [programId, setProgramId] = useState("");
  const [programName, setProgramName] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [programData, setProgramData] = useState();
  const [programTableData, setProgramTableData] = useState([]);
  const [value, setValue] = useState(0);
  const [openParticipantModal, setOpenParticipantModal] = useState(false);
  const [openMentorModal, setOpenMentorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = useState({});
  const [selectedEventDetials, setSelectedEventDetails] = useState([]);
  const [editedDetails, setEditedDetails] = useState([]);
  const [IsBootVisible, setIsBootVisible] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(null); tested with bootcamp working fine
  const [selectedValue, setSelectedValue] = useState("");
  const [selectTypeCheck, setSelectTypeCheck] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [selectedBackupTrainer, setSelecetedBackupTrainer] = useState();
  const [schedulepk, setSchedulepk] = useState();
  const [descriptionu, setDescription] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TraningType = [
    {
      key: 1,
      value: "EXTERNAL",
      title: "EXTERNAL",
    },
    {
      key: 2,
      value: "INTERNAL",
      title: "INTERNAL",
    },
  ];

  const DropdownValue = [
    { key: 1, title: "Bootcamp", value: 1 },
    { key: 0, title: "Program", value: 0 },
    { key: 2, title: "Hackathon", value: 2 },
  ];

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue(file);
  };

  const handleFileChangeMentor = async (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValueMentor(file);
  };

  useEffect(() => {
    if (Array.isArray(programLocal)) {
      setDisabled(false);
      setIsCreate(true);
    } else {
      handleGetProgram(programLocal);
      setDisabled(true);
    }
    if (isBootCamp && isBootCamp === "false") {
      formik.values.selectType = 0;
      setSelectedValue(0);
    } else if (isBootCamp && isBootCamp === "true") {
      formik.values.selectType = 1;
      setSelectedValue(1);
    }
  }, [programLocal, isBootCamp]);

  const getAllTrainer = async (search) => {
    const data = await API.getUsersTrainer(search);
    setTrainers(data.response.data.data);
    setBackupTrainer(data.response.data.data);
  };
  const getParticipantsOfProgram = async () => {
    const response = await API.getParticipants(programLocal);

    if (isBootCamp === "false") {
      const updatedData = response.response.data.data;
      setParticipantProgram(updatedData);
    }
  };

  const getMentorOfProgram = async () => {
    const result = await API.getAllMentors(programLocal);
    const updatedData = result.response.data.data;
    console.log("updatedData", updatedData);
    setMentorProgram(updatedData);
  };

  const getParticipantsOfBootCamp = async () => {
    // if (selectedValue === 1) {
    const response = await API.getBootCampParticipants(programLocal);
    const updatedData = response.response.data.data;
    setParticipantProgram(updatedData);
    // }
  };
  useEffect(() => {
    getAllTrainer();
    getParticipantsOfProgram();
    getParticipantsOfBootCamp();
    getMentorOfProgram();
  }, []);
  const formik = useFormik({
    initialValues: {
      programName: courseData.length > 0 ? courseData.data.course_name : "",
      technology: courseData.length > 0 ? courseData.data.technology : "",
      startDate: new Date(),
      endDate: new Date(),
      duration: 0,
      description: courseData.length > 0 ? courseData.data.about_course : "",
      TraningType: "",
    },
    validationSchema: createProgramValidationSchema,
    onSubmit: (values) => {
      if (edit === false) {
        if (selectTypeCheck === 0) {
          handleAddProgram();
        } else handleAddBootCamp();
      } else {
        selectedValue === 0 && editProgram();
        selectedValue === 1 && editBootCamp();
        selectedValue === 2 && editBootCamp();
      }
    },
  });

  const editBootCamp = async () => {
    setIsCreate(false);
    setDisabled(false);
    setloaderForSaveLaterCourse(true);
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          // return {
          CourseUnitId.push(unitsData.unitid);
          // };
        }),
      };
    });
    let UnitIds = [];
    UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    let combinedArray = UnitIds.concat(CourseUnitId);
    const {
      programName,
      technology,
      startDate,
      endDate,
      duration,
      description,
      TraningType,
    } = formik.values;
    await API.adminEditBootCamp(
      programLocal,
      programName,
      startDate,
      endDate,
      combinedArray,
      description
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
        setloaderForSaveLaterCourse(false);
        handleGetProgram(programLocal);
        setSelectedCourse([]);
        selectedUnits([]);
        setEdit(false);
        setDisabled(true);
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      }
    });
  };

  const handleGetProgram = async (id, isFirstLoad) => {
    if (isBootCamp === "false" || isFirstLoad === false) {
      const ProgramCourseUnit = await GetProgramHelper(
        id,
        setProgramData,
        formik,
        setSelectedUnits,
        setSelectedCourse,
        setDone,
        setIsSaved,
        setSelectedValue
      );
      let unitData = ProgramCourseUnit.filter(
        (programunitdata) => programunitdata.courseDetails === null
      );
      let courseData = ProgramCourseUnit.filter(
        (ProgramCourseData) => ProgramCourseData.courseDetails !== null
      );

      setSelectedEventDetails([
        ...unitData
          .filter((filteredData) => filteredData.trainer_id !== null)
          .map((IndFilteredData) => {
            return {
              isCourse: false,
              trainer: IndFilteredData.trainerDetails,
              trainerId: IndFilteredData.trainer_id,
              startDate: IndFilteredData.start_date,
              end_time: IndFilteredData.end_time,
              start_time: IndFilteredData.start_time,
              // courseId: IndFilteredData.course_id,
              unitId: IndFilteredData.unit_id,
            };
          }),
        ...courseData
          .filter((filtredCourseData) => filtredCourseData.trainer_id !== null)
          .map((IndFilterdCourseData) => {
            return {
              isCourse: true,
              trainer: IndFilterdCourseData.trainerDetails,
              courseId: IndFilterdCourseData.course_id,
              unitId: IndFilterdCourseData.unit_id,
              startDate: IndFilterdCourseData.start_date,
              end_time: IndFilterdCourseData.end_time,
              trainerId: IndFilterdCourseData.trainer_id,
              start_time: IndFilterdCourseData.start_time,
            };
          }),
      ]);
      const data = unitData.map((IndUnitData) => {
        return {
          Status:
            IndUnitData.unitDetails.active_status === 1
              ? "Active"
              : "In-Active",
          id: IndUnitData.unit_id,
          unitDuration: IndUnitData.unitDetails.duration,
          unitName: IndUnitData.unitDetails.topic,
          date: IndUnitData.start_date,
          endtime: IndUnitData.end_time,
          starttime: IndUnitData.start_time,
          trainer:
            IndUnitData.trainerDetails !== null
              ? IndUnitData.trainerDetails.first_name !== null
                ? IndUnitData.trainerDetails.first_name
                : IndUnitData.trainerDetails.user_email
              : null,
        };
      });
      setSelectedUnits([
        ...unitData.map((IndUnitData) => {
          return {
            Status:
              IndUnitData.unitDetails.active_status === 1
                ? "Active"
                : "In-Active",
            id: IndUnitData.unit_id,
            unitDuration: IndUnitData.unitDetails.duration,
            unitName: IndUnitData.unitDetails.topic,
            date: IndUnitData.start_date,
            endtime: IndUnitData.end_time,
            starttime: IndUnitData.start_time,
            trainer:
              IndUnitData.trainerDetails !== null
                ? IndUnitData.trainerDetails.first_name !== null
                  ? IndUnitData.trainerDetails.first_name
                  : IndUnitData.trainerDetails.user_email
                : null,
          };
        }),
      ]);

      const arrangedData = courseData.reduce((result, current) => {
        const existingCourse = result.find(
          (course) => course.course_id === current.course_id
        );
        if (existingCourse) {
          existingCourse.units.push({
            topic: current.unitDetails.topic,
            duration: current.unitDetails.duration,
            active_status: current.unitDetails.active_status,
            unit_id: current.unit_id,
            endtime: current.end_time,
            starttime: current.start_time,
            date: current.start_date,
            // trainer: current.trainer_id,
            trainer:
              current.trainerDetails !== null
                ? current.trainerDetails.first_name !== null
                  ? current.trainerDetails.first_name
                  : current.trainerDetails.user_email
                : null,
          });
        } else {
          result.push({
            course_id: current.course_id,
            courseDetails: current.courseDetails,
            units: [
              {
                topic: current.unitDetails.topic,
                duration: current.unitDetails.duration,
                active_status:
                  current.unitDetails.active_status === 1
                    ? "Active"
                    : "In-Active",
                unit_id: current.unit_id,
                endtime: current.end_time,
                starttime: current.start_time,
                date: current.start_date,
                // trainer: current.trainer_id,
                trainer:
                  current.trainerDetails !== null
                    ? current.trainerDetails.first_name !== null
                      ? current.trainerDetails.first_name
                      : current.trainerDetails.user_email
                    : null,
              },
            ],
          });
        }
        return result;
      }, []);

      setSelectedCourse(
        arrangedData.map((IndCourseData) => {
          return {
            courseId: IndCourseData.course_id,
            courseName: IndCourseData.courseDetails.course_name,
            duration: IndCourseData.courseDetails.duration,
            status:
              IndCourseData.courseDetails.status === 1 ? "Active" : "In-Active",

            units: IndCourseData.units.map((unitData) => {
              return {
                isunitSelected: true,
                unitDetails: "Lorem",
                unitTitle: unitData?.topic,
                unitid: unitData?.unit_id,
                status: unitData?.active_status === 1 ? "Active" : "In-Active",
                duration: unitData?.duration,
                date: unitData?.date,
                endtime: unitData?.endtime,
                starttime: unitData?.starttime,
                trainer: unitData?.trainer,
              };
            }),
          };
        })
      );
    } else if (isBootCamp === "true" || isFirstLoad === true) {
      const BootCampCourseUnit = await GetBootCampData(
        id,
        setProgramData,
        formik,
        setSelectedUnits,
        setSelectedCourse,
        setDone,
        setIsSaved,
        setBootCampDate,
        setSelectedValue
      );
      setDone(false);
      const unitData = BootCampCourseUnit.map((item, index) => {
        return {
          start_date: item.from_date,
          schedule_pk: item.schedule_pk,
          start_time: item.from_time,
          end_time: item.to_time,
          trainer_id: item.trainer_id,
          course_id: null,
          unit_id: item.topic_id,
          trainerDetails: item.trainer_details,
          courseDetails: null,
          backupTrainer: item.backup_trainer_id,
          description: item?.topic_details.description,
          unitDetails: {
            topic: item.topic_details.topic,
            duration: item.topic_details.duration,
            active_status: item.topic_details.active_status,
            schedule_pk: item?.schedule_pk,
          },
        };
      });
      // setSelectedUnits([]);
      // setSelectedEventDetails([]);
      setSelectedEventDetails([
        ...unitData
          .filter((filteredData) => filteredData.trainer_id !== null)
          .map((IndFilteredData) => {
            return {
              isCourse: false,
              trainer: IndFilteredData.trainerDetails,
              trainerId: IndFilteredData.trainer_id,
              backupTrainerId: IndFilteredData.backupTrainer,
              startDate: IndFilteredData.start_date,
              end_time: IndFilteredData.end_time,
              start_time: IndFilteredData.start_time,
              // courseId: IndFilteredData.course_id,
              unitId: IndFilteredData.unit_id,
              schedule_pk: IndFilteredData?.schedule_pk,
              description: IndFilteredData?.description,
            };
          }),
      ]);

      setSelectedUnits([
        ...unitData.map((IndUnitData) => {
          return {
            Status:
              IndUnitData.unitDetails.active_status === 1
                ? "Active"
                : "In-Active",
            id: IndUnitData.unit_id,
            unitDuration: IndUnitData.unitDetails.duration,
            unitName: IndUnitData.unitDetails.topic,
            date: IndUnitData.start_date,
            endtime: IndUnitData.end_time,
            starttime: IndUnitData.start_time,
            backupTrainer: IndUnitData.backup_trainer_id,
            schedule_pk: IndUnitData?.schedule_pk,
            description: IndUnitData?.description,
            trainer:
              IndUnitData.trainerDetails !== null
                ? IndUnitData.trainerDetails.first_name !== null
                  ? IndUnitData.trainerDetails.first_name
                  : IndUnitData.trainerDetails.user_email
                : null,
          };
        }),
      ]);
    }
  };

  const DeleteBootCampUnit = async (id) => {
    // let CourseUnitId = [];
    let UnitIds = [];
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units
          .filter((data) => data.id !== id)
          .map((unitsData) => {
            // return {
            CourseUnitId.push(unitsData.unitid);
            // };
          }),
      };
    });
    UnitIds = selectedUnits
      .filter((data) => data.id !== id)
      .map((programUnitId) => {
        return programUnitId.id;
      });
    let combinedArray = UnitIds.concat(CourseUnitId);
    const {
      programName,
      technology,
      startDate,
      endDate,
      duration,
      description,
      TraningType,
    } = formik.values;
    await API.adminEditBootCamp(
      programLocal,
      programName,
      startDate,
      endDate,
      combinedArray,
      description
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setOpenModal(false);
        handleGetProgram(programLocal);
        setNotify({
          isOpen: true,
          message: "Unit or Course deleted sucessfully",
          type: "success",
        });
      } else {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      }
    });
  };

  useEffect(() => {
    if (selectedTrainer)
      setBackupTrainer(
        trainers.filter((data) => data.user_pk !== selectedTrainer?.user_pk)
      );
  }, [selectedTrainer]);

  useEffect(() => {
    UnitAddtionHandler(
      setPageCount,
      totalUnitData,
      selectedUnitData,
      setTotalUnitData,
      setCourseDifficultyLevel,
      setAddUnitDialog,
      checkboxId
    );
    setRemoveUnit(false);
  }, [removeUnit]);

  useEffect(() => {
    let TotalDuration = 0;
    if (selectedUnits?.length > 0) {
      selectedUnits.forEach((unitData) => {
        TotalDuration += unitData.unitDuration;
      });
    }
    selectedCourse.forEach((courseData) => {
      TotalDuration += courseData.duration;
    });
    formik.setFieldValue("duration", TotalDuration);
    // if (selectedUnits?.length > 0) {
    const { unitData, courseData, CourseUnit } =
      DataTableHandler.AdminProgramDataTableHandler(
        selectedUnits,
        selectedCourse,
        selectedEventDetials
      );
    setProgramTableData([...unitData, ...CourseUnit]);
  }, [selectedCourse, selectedUnits]);

  const selectedEditValue = useMemo(() => {
    setSchedulepk(editedDetails[0]?.schedule_pk || null);
    setDescription(editedDetails[0]?.description || null);
    if (editedDetails.length > 0) {
      if (editedDetails[0].StartTime && editedDetails[0].StartTime !== "N/A") {
        setStartTime(editedDetails[0].StartTime.substring(0, 5));
      } else setStartTime("");
      if (editedDetails[0].EndTime && editedDetails[0].EndTime !== "N/A") {
        setEndTime(editedDetails[0].EndTime.substring(0, 5));
      } else setEndTime("");
      if (editedDetails[0].Date && editedDetails[0].Date !== "N/A") {
        setProgramDate(editedDetails[0].Date);
      }
      if (editedDetails[0].Trainer && editedDetails[0].Trainer !== "N/A") {
        setSelectedTrainer(
          trainers.find(
            (item) =>
              item.first_name === editedDetails[0].Trainer ||
              item.user_email === editedDetails[0].Trainer
          )
        );
        setSearch(
          trainers.find(
            (item) =>
              item.first_name === editedDetails[0].Trainer ||
              item.user_email === editedDetails[0].Trainer
          )
        );
      } else {
        setSelectedTrainer(null);
        setSearch({});
      }
    }
  }, [editedDetails]);

  const handleOpenModal = (title, id) => {
    setProgramId(id);
    setProgramName(title);
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddProgram = async () => {
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          return {
            unitId: unitsData.unitid,
            isUnitSelected: unitsData.isunitSelected,
          };
        }),
      };
    });
    const UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    const {
      programName,
      technology,
      startDate,
      endDate,
      duration,
      description,
      TraningType,
    } = formik.values;
    await API.postProgram(
      programName,
      technology,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      duration,
      description,
      UnitIds,
      CourseIds,
      TraningType
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setProgramLocal(response.data.program_id);
        localStorage.setItem("programId", response.data.program_id);
        localStorage.setItem("IsBootCamp", false);
        handleGetProgram(response.data.program_id, false);
        setloaderForSaveLaterCourse(false);
        setDisabled(true);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };

  const downloadProgramParticipant = (csv, type) => {
    let fields = [];
    if (type === "participants") {
      fields = [
        "first_name",
        "last_name",
        "user_email",
        "phonenumber",
        "department",
        "trainee_colleage",
      ];
    } else {
      fields = [
        "Designation",
        "Email",
        "MentorEmail",
        "MentorId",
        "MentorName",
        "Name",
      ];
    }
    downloadCSV(csv, fields, type);
  };

  const handleAddBootCamp = async () => {
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          // return {
          CourseUnitId.push(unitsData.unitid);
          // };
        }),
      };
    });
    let UnitIds = [];
    UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    let combinedArray = UnitIds.concat(CourseUnitId);

    const {
      programName,
      technology,
      startDate,
      endDate,
      duration,
      description,
      TraningType,
    } = formik.values;
    await API.postBootCamp(
      programName,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      // duration,
      combinedArray,
      description,
      selectedValue
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setProgramLocal(response.data.batch_pk);
        localStorage.setItem("programId", response.data.batch_pk);
        localStorage.setItem("IsBootCamp", true);
        handleGetProgram(response.data.batch_pk, true);
        setSelectedCourse([]);
        selectedUnits([]);
        setloaderForSaveLaterCourse(false);
        setDisabled(true);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };
  const editPage = async () => {
    setDisabled(false);
    setEdit(true);
    setloaderForSaveLaterCourse(false);
  };

  const editProgram = async () => {
    setIsCreate(false);
    setDisabled(false);
    setloaderForSaveLaterCourse(true);
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          return {
            unitId: unitsData.unitid,
            isUnitSelected: unitsData.isunitSelected,
          };
        }),
      };
    });
    const UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    if (formik.values.duration !== 0) {
      const {
        programName,
        technology,
        startDate,
        endDate,
        duration,
        description,
        TraningType,
      } = formik.values;
      await API.adminEditProgram(
        programLocal,
        programName,
        technology,
        duration,
        startDate,
        endDate,
        description,
        UnitIds,
        CourseIds,
        TraningType
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setloaderForSaveLaterCourse(false);
          setEdit(false);
          setDisabled(true);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        } else {
          setloaderForSaveLaterCourse(false);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        }
      });
    }
  };

  const uploadProgramParticipant = async () => {
    // if(selectTypeCheck === 0)
    if (isBootCamp === "false") {
      await uploadParticipant(
        programLocal,
        fieldValue,
        setNotify,
        setIsSuccessUpload,
        selectTypeCheck
      );
      getParticipantsOfProgram(); //need to test with the participants sheet
    } else {
      await uploadBootCampParticipant(
        programLocal,
        fieldValue,
        setNotify,
        setIsSuccessUpload,
        selectTypeCheck,
        getParticipantsOfBootCamp
      );
      //getParticipantsOfProgram();
      //getParticipantsOfProgram(); //need to test with the participants sheet
      getParticipantsOfBootCamp();
    }
    // else
  };

  const handleMentorUpload = async () => {
    await API.AdminAddMentorparticipants(programLocal, fieldValueMentor).then(
      ({ response }) => {
        if (response.api_status === 200) {
          getMentorOfProgram();
          setNotify({
            isOpen: true,
            message: "Uploaded Mentor successfully",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Upload Failed",
            type: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    if (isSucccessUpload && selectedValue === 0) {
      getParticipantsOfProgram();
    } else if (isSucccessUpload && selectedValue === 1) {
      getParticipantsOfBootCamp();
    }
  }, [isSucccessUpload]);

  const createCourse = async () => {
    if (formik.values.duration !== 0) {
      if (isBootCamp === "false") {
        setloaderForPublishCourse(true);
        await API.adminCreateProgram(programLocal).then(
          async ({ response }) => {
            if (response.data.api_status === 200) {
              setloaderForPublishCourse(false);
              setTimeout(function () {
                navigate("/home/courses");
              }, 1000);
              setNotify({
                isOpen: true,
                message: response.data.data[0].message,
                type: "success",
              });
            } else {
              setloaderForPublishCourse(false);
              setNotify({
                isOpen: true,
                message: response.data.error[0].error_msg,
                type: "error",
              });
            }
          }
        );
      } else {
        await API.adminCreateBootCamp(programLocal).then(
          async ({ response }) => {
            if (response.data.api_status === 200) {
              setloaderForPublishCourse(false);
              setTimeout(function () {
                navigate("/home/courses");
              }, 1000);
              setNotify({
                isOpen: true,
                message: response.data.data[0].message,
                type: "success",
              });
            } else {
              setloaderForPublishCourse(false);
              setNotify({
                isOpen: true,
                message: response.data.error[0].error_msg,
                type: "error",
              });
            }
          }
        );
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const cancelProgram = async () => {
    navigate("/home/courses");
  };

  const handleTagChangs = (e) => {
    setUnitPageCount(0);
    setUnitSelectedTag(e.target.value);
  };

  const handleAddDetails = async () => {
    if (unitId && isEndTimeValid()) {
      setTrainerAddLoader(true);
      if (isBootCamp === "false") {
        const response = await API.updateProgramWithTrainer(
          Number(programLocal),
          moment(programDate).format().toString().slice(0, 10),
          startTime,
          search.user_pk,
          unitId,
          courseIdInEdit,
          endTime
        );
        if (response.response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.response.data[0].message,
            type: "success",
          });
          setTrainerAddLoader(false);
          handleClose();
          handleGetProgram(programLocal);
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
          setTrainerAddLoader(false);
          handleClose();
        }
      } else {
        const response = await API.updateBootCampTrainer(
          Number(programLocal),
          moment(programDate).format().toString().slice(0, 10),
          startTime,
          search.user_pk,
          unitId,
          courseIdInEdit,
          endTime,
          programLocal,
          // descriptionu,
          schedulepk
        );

        if (response.response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.response.data[0].message,
            type: "success",
          });
          setTrainerAddLoader(false);
          handleClose();
          handleGetProgram(programLocal);
        } else if (
          response.response.api_status === 422 &&
          response.response.error[0].error_msg ===
            "schedule_pk must be a number"
        ) {
          setNotify({
            isOpen: true,
            message: "Save your changes first!",
            type: "error",
          });
          setTrainerAddLoader(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
          setTrainerAddLoader(false);
        }
      }
    }
  };

  const handleSelectType = (e) => {
    setSelectTypeCheck(e.target.value);
    if (e.target.value === 0) {
      createProgramValidationSchema.fields.technology =
        createProgramValidationSchema.fields.technology.required(
          "Technology is required"
        );
      createProgramValidationSchema.fields.TraningType =
        createProgramValidationSchema.fields.TraningType.required(
          "Training Type is required"
        );

      createProgramValidationSchema.fields.description =
        createProgramValidationSchema.fields.description.required(
          "Description is required"
        );
    } else {
      createProgramValidationSchema.fields.technology =
        createProgramValidationSchema.fields.technology.notRequired();
      createProgramValidationSchema.fields.TraningType =
        createProgramValidationSchema.fields.TraningType.notRequired();
      createProgramValidationSchema.fields.description =
        createProgramValidationSchema.fields.description.notRequired();
    }
    formik.setErrors({});

    setSelectedValue(e.target.value);
  };

  function isEndTimeValid() {
    if (!startTime || !endTime) {
      // If either start or end time is not selected, consider it as valid
      return true;
    }

    return endTime >= startTime;
  }
  useEffect(() => {
    setSelectType(DropdownValue);
  }, []);

  function isEndTimeValid() {
    if (!startTime || !endTime) {
      // If either start or end time is not selected, consider it as valid
      return true;
    }

    return endTime >= startTime;
  }
  const handleUnitNavigate = async (id) => {
    await localStorage.setItem("UnitId", id);
    await localStorage.setItem("Screen", "/home/createProgram");
    navigate("/home/createunit");
  };

  return (
    <>
      {done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box className="batch-wrapper">
            <Box className="batch-header">
              <Grid container spacing={2}>
                <Grid item xs={5.5}>
                  <Box>
                    <div>
                      <ReusableButton
                        onClick={() => navigate("/home/courses")}
                        size="small"
                        variant="contained"
                        buttonName="Back"
                        startIcon={<ArrowBackIosIcon />}
                        style={SxStyles.createCourseStyle.backBtn}
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginRight: "",
                  }}
                >
                  {programLocal > 0 ? (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      Edit{" "}
                      {selectedValue === 1
                        ? "Bootcamp"
                        : selectedValue === 0
                        ? "Program"
                        : "Hackathon"}
                    </span>
                  ) : (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      Create{" "}
                      {selectedValue === 1
                        ? "Bootcamp"
                        : selectedValue === 0
                        ? "Program"
                        : "Hackathon"}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Container
              component="main"
              maxWidth="xxl"
              style={{
                padding: 0,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Card
                  variant="outlined"
                  sx={SxStyles.createCourseStyle.mainContainerCard}
                >
                  <Box>
                    {Object.keys(courseData).length > 0 ? (
                      <div style={SxStyles.createCourseStyle.createCourseDiv}>
                        {courseData.data.course_name}
                      </div>
                    ) : (
                      <div style={SxStyles.createCourseStyle.createCourseDiv}>
                        {programLocal
                          ? formik.values.programName
                          : "Create Training Program"}
                      </div>
                    )}
                    <Grid
                      container
                      spacing={1.5}
                      style={SxStyles.createCourseStyle.mainGrid}
                    >
                      <Grid xs={12} sx={{ padding: "0px 10px" }}>
                        <Grid
                          item
                          xs={3}
                          // style={{ marginRight: "34px" }}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <InputLabel
                            className="InputLabel"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Select Type
                            <span style={styles.madatoryMark}>*</span>
                          </InputLabel>
                          <Custom.ReusableDropdown
                            disabled={programLocal > 0 ? true : false}
                            name="selectType"
                            id="selectType"
                            placeholder="Select Type"
                            displayEmpty
                            options={selectType}
                            onChange={(e) => {
                              handleSelectType(e);
                            }}
                            value={selectedValue}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 40,
                              fontFamily: "Poppins",
                            }}
                          ></Custom.ReusableDropdown>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selectedValue === 0 ? (
                      <Grid container spacing={2} sx={{ padding: "0px 40px" }}>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Create Name
                          </span>
                          <ReusableInputfield
                            name="programName"
                            id="programName"
                            disabled={disabled}
                            helperText={
                              formik.touched.programName &&
                              formik.errors.programName
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            size="small"
                            placeholder="Program Name"
                            value={formik.values.programName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={
                              formik.touched.programName &&
                              Boolean(formik.errors.programName)
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Technology
                          </span>

                          <Custom.ReusableDropdown
                            // disabled={IsBootVisible}
                            disabled={disabled}
                            name="technology"
                            id="technology"
                            displayEmpty
                            placeholder="Select Technology"
                            options={TechnologyConst}
                            onChange={formik.handleChange}
                            value={formik.values.technology}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 42,
                              fontFamily: "Poppins",
                            }}
                            error={
                              formik.touched.technology &&
                              Boolean(formik.errors.technology)
                            }
                            helperText={
                              formik.touched.technology &&
                              formik.errors.technology
                            }
                          ></Custom.ReusableDropdown>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Start Date
                          </span>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate)
                            }
                            helperText={
                              formik.touched.startDate &&
                              formik.errors.startDate
                            }
                            disabled={disabled}
                            // minDate={formik.values.startDate}
                            size="small"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            End Date
                          </span>
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate)
                            }
                            helperText={
                              formik.touched.endDate && formik.errors.endDate
                            }
                            disabled={disabled}
                            minDate={formik.values.startDate}
                            size="small"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Duration
                          </span>
                          <ReusableInputfield
                            name="duration"
                            id="duration"
                            placeholder="Duration"
                            value={formik.values.duration}
                            size="small"
                            style={st}
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                              readOnly: true,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                            disabled={IsBootVisible}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.duration &&
                              Boolean(formik.errors.duration)
                            }
                            helperText={
                              formik.touched.duration && formik.errors.duration
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Training Type
                          </span>
                          <Custom.ReusableDropdown
                            // disabled={IsBootVisible}
                            disabled={disabled}
                            name="TraningType"
                            id="TraningType"
                            displayEmpty
                            placeholder="Select Traning Type"
                            options={TraningType}
                            onChange={formik.handleChange}
                            value={formik.values.TraningType}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 42,
                              fontFamily: "Poppins",
                            }}
                            error={
                              formik.touched.TraningType &&
                              Boolean(formik.errors.TraningType)
                            }
                            helperText={
                              formik.touched.TraningType &&
                              formik.errors.TraningType
                            }
                          ></Custom.ReusableDropdown>
                        </Grid>
                      </Grid>
                    ) : selectedValue === 1 || selectedValue === 2 ? (
                      <Grid container spacing={2} sx={{ padding: "0px 40px" }}>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Create Name
                          </span>
                          <ReusableInputfield
                            name="programName"
                            id="programName"
                            disabled={disabled}
                            helperText={
                              formik.touched.programName &&
                              formik.errors.programName
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            size="small"
                            placeholder="Program Name"
                            value={formik.values.programName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={
                              formik.touched.programName &&
                              Boolean(formik.errors.programName)
                            }
                          />
                        </Grid>

                        {/* <Grid
                        item
                        xs={3}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Technology
                        </span>

                        <Custom.ReusableDropdown
                          // disabled={IsBootVisible}
                          name="technology"
                          id="technology"
                          displayEmpty
                          placeholder="Select Technology"
                          options={TechnologyConst}
                          onChange={formik.handleChange}
                          value={formik.values.technology}
                          className="batchmuipopober"
                          size="small"
                          style={{
                            height: 42,
                            fontFamily: "Poppins",
                          }}
                          error={
                            formik.touched.technology &&
                            Boolean(formik.errors.technology)
                          }
                          helperText={
                            formik.touched.technology &&
                            formik.errors.technology
                          }
                        ></Custom.ReusableDropdown>
                      </Grid> */}

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Start Date
                          </span>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate)
                            }
                            helperText={
                              formik.touched.startDate &&
                              formik.errors.startDate
                            }
                            disabled={disabled}
                            // minDate={BootCampDate ? BootCampDate : new Date()}
                            size="small"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            End Date
                          </span>
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate)
                            }
                            helperText={
                              formik.touched.endDate && formik.errors.endDate
                            }
                            disabled={disabled}
                            minDate={BootCampDate ? BootCampDate : new Date()}
                            size="small"
                          />
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.endDate}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Duration
                          </span>
                          <ReusableInputfield
                            name="duration"
                            id="duration"
                            placeholder="Duration"
                            value={formik.values.duration}
                            size="small"
                            style={st}
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                              readOnly: true,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                            disabled={IsBootVisible}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.duration &&
                              Boolean(formik.errors.duration)
                            }
                            helperText={
                              formik.touched.duration && formik.errors.duration
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <h1
                            className="required"
                            style={SxStyles.createCourseStyle.aboutCourseh1}
                          >
                            About Bootcamp
                          </h1>
                          <ReusableInputfield
                            rows={4}
                            displayEmpty
                            style={{ width: "100%" }}
                            className="description_in_course"
                            name="description"
                            id="description"
                            disabled={disabled}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            multiline
                            size="medium"
                            error={
                              formik.touched.description &&
                              Boolean(formik.errors.description)
                            }
                            helperText={
                              formik.touched.description &&
                              formik.errors.description
                            }
                            InputProps={{
                              inputComponent: TextareaAutosize,
                              inputProps: {
                                style: {
                                  resize: "auto",
                                  height: "100%",
                                },
                              },
                            }}
                          />
                        </Grid>

                        {/* <Grid
                        item
                        xs={3}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Duration
                        </span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          value={formik.values.duration}
                          size="small"
                          style={st}
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "9px 14px" },
                            },
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          disabled={IsBootVisible}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.duration &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            formik.touched.duration && formik.errors.duration
                          }
                        />
                      </Grid> */}

                        {/* <Grid
                        item
                        xs={3}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      >
                        <span
                          className="required"
                          style={SxStyles.createCourseStyle.createCourseSpan}
                        >
                          Training Type
                        </span>
                        <Custom.ReusableDropdown
                          disabled={IsBootVisible}
                          name="TraningType"
                          id="TraningType"
                          displayEmpty
                          placeholder="Select Traning Type"
                          options={TraningType}
                          onChange={formik.handleChange}
                          value={formik.values.TraningType}
                          className="batchmuipopober"
                          size="small"
                          style={{
                            height: 42,
                            fontFamily: "Poppins",
                          }}
                          error={
                            formik.touched.TraningType &&
                            Boolean(formik.errors.TraningType)
                          }
                          helperText={
                            formik.touched.TraningType &&
                            formik.errors.TraningType
                          }
                        ></Custom.ReusableDropdown>
                      </Grid> */}
                      </Grid>
                    ) : null}

                    <Grid
                      container
                      spacing={1.5}
                      style={SxStyles.createCourseStyle.trainingGrid}
                    >
                      {formik.values.TraningType === TraningType[0].value ? (
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Trainer Email Address
                          </span>
                          <ReusableInputfield
                            name="trainerEmail"
                            id="trainerEmail"
                            disabled={disabled}
                            // disabled={IsBootVisible}
                            helperText={
                              formik.touched.trainerEmail &&
                              formik.errors.trainerEmail
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            placeholder="Trainer Email ID"
                            value={formik.values.trainerEmail}
                            onChange={formik.handleChange}
                            size="small"
                            variant="outlined"
                            error={
                              formik.touched.trainerEmail &&
                              Boolean(formik.errors.trainerEmail)
                            }
                          />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={3.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      ></Grid>
                      <Grid
                        item
                        xs={3.4}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      ></Grid>
                    </Grid>

                    {selectedValue === 0 ? (
                      <Box style={SxStyles.createCourseStyle.aboutCourseBox}>
                        <h1
                          className="required"
                          style={SxStyles.createCourseStyle.aboutCourseh1}
                        >
                          About Program
                        </h1>
                        <ReusableInputfield
                          rows={4}
                          displayEmpty
                          style={{ width: "100%" }}
                          className="description_in_course"
                          name="description"
                          id="description"
                          disabled={disabled}
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          multiline
                          size="medium"
                          error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                          }
                          helperText={
                            formik.touched.description &&
                            formik.errors.description
                          }
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: {
                                resize: "auto",
                                height: "100%",
                              },
                            },
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "1.4%",
                    }}
                  >
                    <ReusableButton
                      className="addbutton"
                      size="large"
                      variant="contained"
                      onClick={() => {
                        setAddUnitDialog(true);
                      }}
                      disabled={disabled || value !== 0}
                      buttonName="Add"
                      style={{
                        backgroundColor:
                          disabled || value !== 0 ? "grey" : "#fb2485",
                        marginBottom: "1%",
                        fontSize: "18px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        padding: "8px 30px",
                        borderRadius: "4px",
                        textTransform: "none",
                        marginTop: "1%",
                      }}
                    >
                      Add
                    </ReusableButton>
                  </div>

                  <Box styles={{ padding: "0px 20px" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "93%",
                        margin: "0px auto",
                      }}
                    >
                      <Tabs value={value} onChange={handleChange}>
                        <Tab
                          label="Courses/Units"
                          {...a11yProps(0)}
                          style={{ marginRight: "10px" }}
                        />
                        <Tab
                          label="Participants"
                          {...a11yProps(1)}
                          style={{ marginRight: "10px" }}
                        />
                        <Tab
                          label="Mentors"
                          {...a11yProps(2)}
                          style={{ marginRight: "10px" }}
                        />
                      </Tabs>
                    </Box>

                    {value === 0 ? (
                      <>
                        {programTableData.length !== 0 ? (
                          <>
                            <Box
                              style={{
                                marginTop: "20px",
                                padding: "14px 3.2%",
                                width: "100%",
                              }}
                            >
                              <CollapsibleTable
                                rows={programTableData}
                                handleOpenModal={handleOpenModal}
                                disabled={disabled}
                                handleDeleteModal={handleDeleteModal}
                                setUnitId={setUnitId}
                                programLocal={programLocal}
                                editedDetails={editedDetails}
                                setEditedDetails={setEditedDetails}
                                SelectType={selectedValue}
                                handleUnitNavigate={handleUnitNavigate}
                              />
                            </Box>
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "150px",
                              backgroundColor: "#f5f5f5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "800",
                              fontSize: "18px",
                            }}
                          >
                            <p>No Data Found.</p>
                          </div>
                        )}
                      </>
                    ) : null}

                    {value === 1 ? (
                      <>
                        <div
                          style={{
                            width: "98%",
                            height: "100%",
                            padding: "0px 20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "2%",
                              marginLeft: "2.5%",
                              marginTop: "2%",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#000",
                                marginLeft: "-7px",
                                marginBottom: "10px",
                              }}
                              variant="h6"
                            >
                              Upload Program Participants.
                            </Typography>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span style={{ marginLeft: "-7px" }}>
                                  <input
                                    name="file"
                                    id="file"
                                    type="file"
                                    disabled={
                                      localStorage.getItem("programId")
                                        ? false
                                        : true
                                    }
                                    class="upload-box"
                                    accept={SheetJSFT}
                                    style={{
                                      fontSize: "0.8em",
                                      fontFamily: "Poppins",
                                      height: "41px",
                                      opacity: localStorage.getItem("programId")
                                        ? 1
                                        : 0.5,
                                    }}
                                    ref={inputRef}
                                    onChange={(e) =>
                                      handleFileChange(e, setFieldValue)
                                    }
                                  />
                                </span>
                                <Typography style={styles.note} variant="p">
                                  Only .csv format allowed &nbsp;
                                </Typography>
                              </div>
                              <ReusableButton
                                className="addbutton"
                                size="large"
                                variant="contained"
                                onClick={() => uploadProgramParticipant()}
                                buttonName="Upload CSV"
                                style={{
                                  backgroundColor: localStorage.getItem(
                                    "programId"
                                  )
                                    ? "#fb2485"
                                    : "grey",
                                  // marginTop: "0.7%",
                                  fontSize: "18px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "0px 15px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                  height: "40px",
                                  width: "10vw",
                                }}
                              />
                              <ReusableButton
                                className="addbutton"
                                size="large"
                                variant="contained"
                                onClick={() =>
                                  downloadProgramParticipant(
                                    participantsProgram,
                                    "participants"
                                  )
                                }
                                buttonName="Download CSV"
                                disabled={participantsProgram === undefined}
                                style={{
                                  backgroundColor:
                                    participantsProgram === undefined
                                      ? "grey"
                                      : "#fb2485",
                                  // marginTop: "0.7%",
                                  fontSize: "18px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "0px 15px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                  height: "40px",
                                  width: "10vw",
                                }}
                              />
                              <CSVLink
                                style={{
                                  color: "#1976d2",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                {...participantsSampleCsv}
                              >
                                <DownloadIcon
                                  color="primary"
                                  fontSize="medium"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Download Sample CSV
                              </CSVLink>
                            </div>
                          </div>

                          {participantsProgram ? (
                            <DataGrid
                              style={{
                                border: "1px solid rgb(189 197 206) !important",
                                width: "98%",
                                margin: "0px 2%",
                              }}
                              columns={columns}
                              rows={newParticipant}
                              hideFooter
                              autoHeight
                            />
                          ) : null}
                        </div>
                      </>
                    ) : null}

                    {value === 2 ? (
                      <div
                        style={{
                          width: "98%",
                          height: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2%",
                            marginLeft: "2.5%",
                            marginTop: "2%",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                              marginLeft: "-7px",
                              marginBottom: "10px",
                            }}
                            variant="h6"
                          >
                            Upload Mentor
                          </Typography>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span style={{ marginLeft: "-7px" }}>
                                <input
                                  name="file"
                                  id="file"
                                  type="file"
                                  disabled={
                                    localStorage.getItem("programId")
                                      ? false
                                      : true
                                  }
                                  class="upload-box"
                                  accept={SheetJSFT}
                                  style={{
                                    fontSize: "0.8em",
                                    fontFamily: "Poppins",
                                    height: "41px",
                                    opacity: localStorage.getItem("programId")
                                      ? 1
                                      : 0.5,
                                  }}
                                  ref={inputRef}
                                  onChange={(e) =>
                                    handleFileChangeMentor(
                                      e,
                                      setFieldValueMentor
                                    )
                                  }
                                />
                              </span>
                              <Typography style={styles.note} variant="p">
                                Only .csv format allowed &nbsp;
                              </Typography>
                            </div>
                            <ReusableButton
                              className="addbutton"
                              size="large"
                              variant="contained"
                              onClick={() => handleMentorUpload()}
                              buttonName="Upload CSV"
                              style={{
                                backgroundColor: localStorage.getItem(
                                  "programId"
                                )
                                  ? "#fb2485"
                                  : "grey",
                                // marginTop: "0.7%",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#fff",
                                padding: "0px 15px",
                                borderRadius: "4px",
                                textTransform: "none",
                                height: "40px",
                                width: "10vw",
                              }}
                            />

                            <ReusableButton
                              className="addbutton"
                              size="large"
                              variant="contained"
                              onClick={() =>
                                downloadProgramParticipant(
                                  newMentorList,
                                  "mentor"
                                )
                              }
                              disabled={newMentorList === undefined}
                              buttonName="Download CSV"
                              style={{
                                backgroundColor:
                                  newMentorList === undefined
                                    ? "grey"
                                    : "#fb2485",
                                // marginTop: "0.7%",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#fff",
                                padding: "0px 15px",
                                borderRadius: "4px",
                                textTransform: "none",
                                height: "40px",
                                width: "10vw",
                              }}
                            />
                            <CSVLink
                              style={{
                                color: "#1976d2",
                                display: "flex",
                                alignItems: "center",
                              }}
                              {...mentorSampleCsv}
                            >
                              <DownloadIcon
                                color="primary"
                                fontSize="medium"
                                style={{
                                  marginRight: "5px",
                                }}
                              />
                              Download Sample CSV
                            </CSVLink>
                          </div>
                        </div>

                        {mentorProgram ? (
                          <DataGrid
                            style={{
                              border: "1px solid rgb(189 197 206) !important",
                              width: "98%",
                              margin: "0px 2%",
                            }}
                            columns={Mentorscolumns}
                            rows={newMentorList}
                            hideFooter
                            autoHeight
                          />
                        ) : null}
                      </div>
                    ) : null}

                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>{programName}</DialogTitle>
                      <DialogContent sx={{ overflow: "hidden" }}>
                        <Grid
                          container
                          spacing={1.5}
                          style={SxStyles.createCourseStyle.mainGrid}
                        >
                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              className="required"
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Select Date
                            </span>
                            <Custom.DatePicker
                              name="programDate"
                              id="programDate"
                              style={{
                                width: "19vw",
                                marginTop: "3px",
                                height: "6.5vh",
                              }}
                              value={programDate}
                              onChange={(e) => setProgramDate(e.target.value)}
                              disabled={disabled}
                              size="small"
                            />
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "0.5%",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              className="required"
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Start Time
                            </span>
                            <input
                              type="time"
                              style={{
                                height: "6.5vh",
                                width: "19vw",
                                marginTop: "1px",
                                border: "0.5px solid  #B0B0B0",
                                borderRadius: 4,
                                fontFamily: "poppins",
                                fontSize: 14,
                                paddingLeft: "12px",
                              }}
                              name="toTimeOfQuiz"
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              End Time
                            </span>
                            <input
                              type="time"
                              style={{
                                height: "6.5vh",
                                width: "19vw",
                                border: "0.5px solid  #B0B0B0",
                                borderRadius: 4,
                                fontFamily: "poppins",
                                fontSize: 14,
                                paddingLeft: "12px",
                              }}
                              name="toTimeOfQuiz"
                              value={endTime}
                              onChange={(e) => {
                                setEndTime(e.target.value);
                              }}
                            />
                            {!isEndTimeValid() && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                End time should not be less than start time.
                              </span>
                            )}
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Select Trainer
                            </span>
                            <Autocomplete
                              id="Tag"
                              name="Tag"
                              options={trainers}
                              getOptionLabel={(option) =>
                                option.first_name !== null ||
                                (option.first_name !== undefined &&
                                  option.last_name !== null) ||
                                option.last_name !== undefined
                                  ? `${option.first_name} ${option.last_name}`
                                  : option.first_name !== null &&
                                    option.first_name !== undefined
                                  ? option.first_name
                                  : option.user_email
                              }
                              clearIcon={false}
                              sx={{
                                width: "19vw",
                                height: "48px",
                              }}
                              size="small"
                              value={selectedTrainer}
                              onChange={(e, newValue) => {
                                setSearch(newValue);
                                setSelectedTrainer(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  style={{
                                    height: "49px !important",
                                    fontSize: "14px",
                                    paddingBottom: "10px",
                                  }}
                                  placeholder="Select Trainer"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          buttonName="Cancel"
                          onClick={() => {
                            handleClose();
                          }}
                          style={SxStyles.createCourseStyle.cancelButton}
                          disabled={
                            loaderForPublishCourse || loaderForSaveLaterCourse
                              ? true
                              : false
                          }
                        >
                          Cancel
                        </ReusableButton>
                        <ReusableButton
                          className="addbutton"
                          size="large"
                          variant="contained"
                          onClick={() => {
                            handleAddDetails();
                          }}
                          endIcon={
                            !trainerAddLoader ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={!trainerAddLoader ? "Add" : "Adding"}
                          style={
                            !trainerAddLoader
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={trainerAddLoader ? true : false}
                        >
                          Add
                        </ReusableButton>
                      </DialogActions>
                    </Dialog>
                  </Box>

                  <div style={{ marginTop: "2%" }}>
                    <div style={SxStyles.createCourseStyle.addCancelDiv}>
                      <ReusableButton
                        size="large"
                        variant="outlined"
                        buttonName="Cancel"
                        onClick={cancelProgram}
                        style={SxStyles.createCourseStyle.cancelButton}
                        disabled={
                          loaderForPublishCourse || loaderForSaveLaterCourse
                            ? true
                            : false
                        }
                      >
                        Cancel
                      </ReusableButton>
                      {disabled ? (
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          buttonName="Edit"
                          style={SxStyles.styles.saveForLaterBtn}
                          onClick={editPage}
                          disabled={loaderForPublishCourse ? true : false}
                        />
                      ) : (
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          onClick={formik.handleSubmit} //saveforlater
                          endIcon={
                            !loaderForSaveLaterCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForSaveLaterCourse
                              ? "Save for Later"
                              : "Saving"
                          }
                          style={
                            !loaderForPublishCourse
                              ? styles.saveForLaterBtn
                              : styles.saveForLaterBtnDisabled
                          }
                          disabled={loaderForPublishCourse ? true : false}
                        />
                      )}

                      {!isCreate ? (
                        <ReusableButton
                          onClick={createCourse}
                          size="large"
                          variant="contained"
                          endIcon={
                            !loaderForPublishCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForPublishCourse ? "Publish" : "Publishing"
                          }
                          style={
                            !loaderForSaveLaterCourse
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={loaderForSaveLaterCourse ? true : false}
                        />
                      ) : (
                        <ReusableButton
                          size="large"
                          variant="contained"
                          endIcon={
                            !loaderForPublishCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForPublishCourse ? "Create" : "Creating"
                          }
                          style={
                            !loaderForSaveLaterCourse
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={loaderForSaveLaterCourse ? true : false}
                        ></ReusableButton>
                      )}
                    </div>
                  </div>
                </Card>
                <AddCourseUnitModal
                  isOpen={addUnitDialog}
                  setIsOpen={setAddUnitDialog}
                  handleTagChangs={handleTagChangs}
                  programSelectedUnit={selectedUnits}
                  programSelectedCourses={selectedCourse}
                  programSetSelectedCourses={setSelectedCourse}
                  programSetSelectedUnit={setSelectedUnits}
                  editBootCamp={editBootCamp}
                />
              </form>
            </Container>
            <Dialog
              open={openModal}
              className="dialogBox"
              keepMounted
              onClose={() => {
                setOpenModal(!openModal);
              }}
              aria-describedby="alert-dialog-slide-description"
              sx={SxStyles.DialogBoxStyle}
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={SxStyles.styles.cancelButton}
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={styles.createBtn}
                  onClick={() =>
                    handleProgramUnitorCourseDelete(
                      programLocal,
                      params.CourseId ? params.CourseId : null,
                      params.id
                    )
                  }
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <Dialog
            open={openParticipantModal}
            className="dialogBox"
            keepMounted
            onClose={handleCloseByCancelParticipantModal}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
          >
            <DialogTitle></DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                style={{ color: "#000" }}
              >
                Are you sure you want to delete ?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <ReusableButton
                size="large"
                variant="outlined"
                className="candidateDltepageCancelBttn"
                buttonName="Cancel"
                style={SxStyles.styles.cancelButton}
                onClick={handleCloseByCancelParticipantModal}
              />
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="Delete"
                style={styles.createBtn}
                onClick={() =>
                  handleDeleteParticipant(selectId, parseInt(programLocal))
                }
              >
                Delete
              </ReusableButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
export default CreateProgram;
