import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import TimePicker from "react-time-picker";
import { Custom } from "../../../components/Custom/Custom";
import Notification from "../../../components/Custom/Notification";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { API } from "../../../Networking/API";
import "./schedule.css";

const SessionsDetails = (props) => {
  const { sessionData, batchID, scheduleId, getScheduleSessionDetails } = props;
  const [minDate, setMinDate] = useState(sessionData.training_date);
  const [maxDate, setMaxDate] = useState(sessionData.end_date);
  const [fromDate, setFromDate] = useState(sessionData.training_date);
  const [toDate, setToDate] = useState(sessionData.end_date);
  const [fromTime, setFromTime] = useState(sessionData.from_time);
  const [endTime, setEndTime] = useState(sessionData.to_time);
  const [description, setDescription] = useState(sessionData.description);
  const [errorDescription, setErrorDescription] = useState("");
  const [showEdit, setShowEdit] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleFromDate = (event) => {
    const fromApiDate = moment(event.target.value).format();
    setFromDate(fromApiDate.split("T"));
  };

  const handleEndDate = (event) => {
    const toApiDate = moment(event.target.value).format();
    setToDate(toApiDate.split("T"));
  };

  const onFromTimeChange = (e) => {
    setFromTime(e);
  };

  const onEndTimeChange = (e) => {
    setEndTime(e);
  };

  const handleInputChange = (e) => {
    setDescription(e.target.value);
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowEdit(!showEdit);
    if (!showEdit) {
      const toFromDate = convert(fromDate);
      const endDate = convert(toDate);
      if (description !== "") {
        setErrorDescription("");
        API.editSessionSchedule(
          batchID,
          parseInt(scheduleId),
          toFromDate,
          endDate,
          fromTime,
          endTime,
          description
        ).then(({ response }) => {
          if (response.api_status === 200) {
            setNotify({
              isOpen: true,
              message: "Successfully updated",
              type: "success",
            });
            getScheduleSessionDetails(parseInt(scheduleId), batchID);
          } else {
            getScheduleSessionDetails(parseInt(scheduleId), batchID);
            setNotify({
              isOpen: true,
              message: "Something went wrong",
              type: "error",
            });
          }
        });
      } else {
        setShowEdit(false);
        setErrorDescription("Description is Required");
      }
    }
  };

  const handleCancelPopUp = () => {
    setShowEdit(true);
  };

  return (
    <>
      <Grid container spacing={2} style={{ paddingLeft: "7px !important" }}>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Select From Date
            </span>
            <span style={{}}>
              <Custom.DatePicker
                name="startdate"
                className=""
                value={fromDate}
                onChange={handleFromDate}
                size="small"
                minDate={minDate}
                maxDate={toDate}
                disabled={showEdit}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
              }}
              variant="h6"
            >
              Select To Date
            </span>
            <span style={{}}>
              <Custom.DatePicker
                name="todate"
                className=""
                value={toDate}
                onChange={handleEndDate}
                size="small"
                minDate={fromDate}
                maxDate={maxDate}
                disabled={showEdit}
                // sx={{width :'245px !important'}}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-5px",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Start Time
            </span>
            <span style={{}}>
              <TimePicker
                name="toTimeOfQuiz"
                onChange={onFromTimeChange}
                value={fromTime}
                disabled={showEdit}
                className="time-container"
                disableClock={true}
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-5px",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              End Time
            </span>
            <span style={{}}>
              <TimePicker
                name="toTimeOfQuiz"
                onChange={onEndTimeChange}
                value={endTime}
                disabled={showEdit}
                className="time-container"
                disableClock={true}
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
              />
            </span>
          </div>
        </Grid>
      </Grid>
      <h1
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "20px",
          /* identical to box height, or 125% */
          color: "#0A0A0A",
          marginTop: "40px",
        }}
      >
        About Session
      </h1>
      <textarea
        style={{
          width: "100%",
          height: "20vh",
          // height : "37vh"
          boxSizing: "border-box",
          border: "1px solid #ccc",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingTop: "9px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          color: "#0A0A0A",
          fontSize: "16px",
        }}
        placeholder="Description"
        onChange={handleInputChange}
        value={description}
        error={errorDescription}
        disabled={showEdit}
        multiline
        size="medium"
      />
      {errorDescription !== "" ? (
        <span
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "red",
            fontSize: "12px",
          }}
        >
          {errorDescription}
        </span>
      ) : null}
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <ReusableButton
          onClick={handleCancelPopUp}
          size="large"
          variant="outlined"
          buttonName="Cancel"
          style={{
            marginRight: "10px",
            color: "#000936",
            border: "1px solid #000936",
            marginBottom: "40px",
            width: "98px",
            height: "38px",
            borderRadius: 4,
          }}
        >
          Cancel
        </ReusableButton>
        <ReusableButton
          //  onClick={handleClose}
          //type="submit"
          //   onClick={() => onSubmitEdit(id)}
          onClick={(e) => handleSubmit(e)}
          size="large"
          variant="contained"
          buttonName={showEdit ? "Edit" : "Save"}
          style={{
            backgroundColor: "#fb2485",
            marginBottom: "40px",
            width: "98px",
            height: "38px",
            borderRadius: 4,
          }}
        >
          {showEdit ? "Save" : "Edit"}
        </ReusableButton>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default SessionsDetails;
