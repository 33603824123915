/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
// import "./user-units-tabs.css";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import UploadIcon from "@mui/icons-material/Upload";
// import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RButton from "../../../../components/Custom/button/button";
import Cookies from "js-cookie";
// import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";
import { API } from "../../../../Networking/API";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import Notification from "../../../../components/Custom/Notification";
import { FileUploadErrorMsg } from "../../../../constants/errorMsg";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
// import { UserTrainingMaterial } from "./user-training-material";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { useFormik } from "formik";
import { feedbackValidations } from "../../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../../user-styles/styles";
import Mandatorytrainingmaterial from "./mandatory-training-material";
// import Mandatorytrainingquiz from "./mandatory-training-quiz";
// import MandateUserQuiz from "./mandatory-training-final-quiz/mandate-user-quiz";
import MandatoryTraningTabPanel from "../MandatoryTraningLayout/mandatoryTraningTabpanel";
// import MandateUserQuiz from "../MandatoryTraningLayout/MandatoryTraningQuiz/MandatoryTrainingFinalQuiz/mandate-user-quiz";
// import { UserPrerequisite } from "./user-prerequisite";
// import UserQuiz from "./user-quiz/user-quiz";
import MandateUserQuiz from "../MandatoryTraningLayout/MandatoryTraningQuiz/MandatoryTrainingFinalQuiz/mandate-user-quiz";

const column = [
  {
    field: "Information",
    headerName: "Information",
    headerClassName: "super-app-theme--header",
    className: "quiz-class-column",
    flex: 1,
  },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    flex: 1,
  },
];

// function MandatoryTrainingTabs(props) {
//   const { mandatetrainingData } = props;

//   // const ref = useRef();
//   const { traningId } = useParams();
//   // const [upload, setUpload] = useState(true);
//   // const [del, setDel] = useState();
//   const [value, setValue] = useState(0);
//   // const [loading, setLoading] = useState(false);
//   // const [SubmitLoading, setSubmitLoading] = useState(false);
//   // const [lastModified, setLastModified] = useState();
//   // const [errorMessage, setErrorMessage] = useState("");
//   // const [errorStatus, setErrorStatus] = useState(false);
//   // const [submissionStatus, setSubmissionStatus] = useState(false);
//   // const [quizAltButton, setQuizAltButton] = useState(true);
//   // const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
//   // const [status, setStatus] = useState();
//   // const [time, setTime] = useState(0);
//   // const [data, setData] = useState([]);
//   // const [loadingf, setLoadingF] = useState(undefined);
//   // const [radioValue, setRadioValue] = useState("");
//   // const [feedbackStatussub, setFeedbackStatusSub] = useState();
//   const [notify, setNotify] = useState({
//     isOpen: false,
//     message: "",
//     type: "",
//   });
//   const [done, setDone] = useState(false);
//   const location = useLocation();
//   // const [file, setFile] = useState({});
//   // const [files, setFiles] = useState([]);
//   // const [unitdata, setUnitData] = useState();
//   // const [preRequisite, setPreRequisite] = useState([]);
//   // const [trainingMaterial, setTrainingMaterial] = useState([]);
//   // const [isUnitStarted, setIsUnitStarted] = useState(false);
//   // const [trainingDisabled, setTrainingDisabled] = useState(true);
//   // const [assignmentDisabled, setAssignmentDisabled] = useState(true);
//   // const [quizzdisabled, setQuizzDisabled] = useState(true);
//   // const trainingData = unitdata?.training_material;

//   // const { values, handleSubmit, errors, handleChange, touched, resetForm } =
//   //   useFormik({
//   //     initialValues: {
//   //       feedback: "",
//   //       radioopt: "",
//   //     },
//   //     validationSchema: feedbackValidations,
//   //     onSubmit: (values) => {
//   //       // onSubmitEdit();
//   //     },
//   //   });

//   // useEffect(() => {
//   //   // if (assignmentData.length > 0) {
//   //   //   getAssignmentData();
//   //   // }
//   //   // getUnitDetails();
//   // }, []);

//   // const getUnitDetails = async () => {
//   //   await GetUserUnitDetails(
//   //     unitId,
//   //     courseId,
//   //     setDone,
//   //     setUnitData,
//   //     setFeedbackStatusSub,
//   //     setPreRequisite,
//   //     setAssignmentData,
//   //     setTrainingMaterial,
//   //     setIsUnitStarted
//   //   );
//   //   setLoadingF(false);
//   // };

//   // useEffect(() => {
//   //   const lastRequisite = preRequisite[preRequisite?.length - 1];
//   //   if (lastRequisite?.isCompleted) {
//   //     setTrainingDisabled(false);
//   //   }
//   //   if (unitdata !== undefined) {
//   //     const last =
//   //       unitdata.training_material[unitdata.training_material.length - 1];
//   //     if (last?.isCompleted && lastRequisite?.isCompleted) {
//   //       setAssignmentDisabled(false);
//   //       setQuizzDisabled(false);
//   //     }
//   //   }
//   // }, [preRequisite, unitdata]);

//   // const onSubmitEdit = async () => {
//   //   const { radioopt, feedback } = values;
//   //   setLoadingF(true);
//   //   try {
//   //     API.UnitpostFeed(unitId, courseId, radioopt, feedback).then(
//   //       ({ response }) => {
//   //         resetForm();
//   //         // getUnitDetails();
//   //         const resultInJson = response;
//   //         if (resultInJson.api_status === 200) {
//   //           setNotify({
//   //             isOpen: true,
//   //             message: "FeedBack Submitted",
//   //             type: "success",
//   //           });
//   //         }
//   //         if (resultInJson.api_status === 404) {
//   //           setNotify({
//   //             isOpen: true,
//   //             message: "FeedBack Not Submitted",
//   //             type: "error",
//   //           });
//   //         }
//   //         if (resultInJson.api_status === 500) {
//   //           setNotify({
//   //             isOpen: true,
//   //             message: "Feedback Already Submitted",
//   //             type: "error",
//   //           });
//   //         }
//   //       }
//   //     );
//   //   } catch (e) {}
//   // };

//   // Cookies.set("quizdata", JSON.stringify(quizdata[0]));

//   // const TabClosing = () => {
//   //   setQuizAltButton(false);
//   //   localStorage.removeItem("minsecs");
//   //   localStorage.removeItem("countdownfromapi");
//   // };
//   // const closeTab = () => {
//   //   TabClosing();
//   // };
//   // const QuizStatusFunction = () => {
//   //   setResultLoaded(false);
//   //   getTraineeTabDetails();
//   // };

//   // const getAssignmentData = async () => {
//   //   try {
//   //     API.userGetAssignment(unitId, assignmentData[0].asn_pk).then(
//   //       ({ response }) => {
//   //         const resultInJson = response;
//   //         if (resultInJson.api_status === 200) {
//   //           if (resultInJson.data.userAssignmentDetails !== null) {
//   //             setFiles(resultInJson.data.userAssignmentDetails?.assignLinks);
//   //             setLastModified(
//   //               resultInJson.data.userAssignmentDetails?.last_update
//   //             );
//   //             setDel(undefined);
//   //             setStatus(resultInJson.data.userAssignmentDetails?.astmt_status);
//   //             setData(resultInJson.data);
//   //             if (resultInJson.data.userAssignmentDetails?.astmt_status === 0) {
//   //               setSubmissionStatus(false);
//   //             } else {
//   //               setSubmissionStatus(true);
//   //             }
//   //           }
//   //           setLoading(false);
//   //           // setStatusDone(true);
//   //         } else if (resultInJson.api_status === 404) {
//   //           setFiles([]);
//   //           setLoading(false);
//   //           // setStatusDone(true);
//   //           setSubmissionStatus(false);
//   //         } else {
//   //           setFiles([]);
//   //           setLoading(false);
//   //           // setStatusDone(true);
//   //           setSubmissionStatus(false);
//   //         }
//   //       }
//   //     );
//   //   } catch (e) {}
//   // };

//   // const handleupload = (event) => {
//   //   setFile(event.target.files[0]);
//   //   setUpload(true);
//   //   setErrorStatus(false);
//   // };

//   // const handlechangeupload = async (e) => {
//   //   e.preventDefault();
//   //   ref.current.value = "";
//   //   setUpload(false);
//   //   try {
//   //     if (!file.size) {
//   //       setErrorMessage(FileUploadErrorMsg.FILE_SELECT_MSG);
//   //       setErrorStatus(true);
//   //       setLoading(false);
//   //     } else if (files.length < 3) {
//   //       API.uploadAssignment(assignmentData[0].asn_pk, file).then(
//   //         ({ response }) => {
//   //           const resultInJson = response;
//   //           if (resultInJson.api_status === 200) {
//   //             setFile([]);
//   //             setDel(undefined);
//   //             getAssignmentData();
//   //             e.target.value = "";
//   //           } else {
//   //             setErrorMessage(resultInJson.error[0].error_msg);
//   //             setErrorStatus(true);
//   //             setLoading(false);
//   //           }
//   //         }
//   //       );
//   //     }
//   //   } catch (e) {
//   //     setErrorMessage(FileUploadErrorMsg.FILE_VALID);
//   //     setErrorStatus(true);
//   //     setLoading(false);
//   //   }
//   // };

//   const handleTabChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   // const datas = [
//   //   {
//   //     id: 1,
//   //     Information: "Number Questions",
//   //     Status: `${quizDetails.noOFques}`,
//   //   },
//   //   {
//   //     id: 2,
//   //     Information: "Time Duration",
//   //     Status: `${quizDetails.count} mins `,
//   //   },
//   //   { id: 3, Information: "Attempts", Status: 1 },
//   // ];

//   // const handleSubmitAll = async (e) => {
//   //   e.preventDefault();
//   //   setErrorMessage("");
//   //   try {
//   //     API.submitAllAsssignment(assignmentData[0].asn_pk).then(
//   //       ({ response }) => {
//   //         const resultInJson = response;
//   //         if (resultInJson.api_status === 200) {
//   //           getAssignmentData();
//   //         } else {
//   //           setNotify({
//   //             isOpen: true,
//   //             message: "File Not Submitted",
//   //             type: "error",
//   //           });
//   //           setSubmitLoading(false);
//   //         }
//   //       }
//   //     );
//   //   } catch (e) {}
//   // };

//   // async function handleDelete(id, index) {
//   //   setDel(index);
//   //   try {
//   //     API.userDeleteAssignment(id).then(({ result }) => {
//   //       const resultInJson = result;
//   //       if (resultInJson.api_status === 200) {
//   //         getAssignmentData();
//   //       }
//   //     });
//   //   } catch (e) {}
//   // }
//   // const HandleTabIndexEnable = (data1, data2, data3) => {
//   //   if (data1 && data2 && data3) {
//   //     return false;
//   //   }
//   //   if (data1 && data2) {
//   //     return false;
//   //   } else return true;
//   // };
//   return (
//     <>
//       <Box
//         sx={{
//           marginTop: 5,
//           marginBottom: 15,
//           height: "auto",
//           borderRadius: "10px",
//         }}
//       >
//         <Box sx={{ borderRight: 2, borderColor: "divider" }}>
//           <Tabs
//             value={value}
//             onChange={handleTabChange}
//             aria-label="basic tabs example"
//           >
//             <Tab
//               label="Training Material"
//               className="tabs-item"
//               style={{
//                 textTransform: "capitalize",
//               }}
//               {...a11yProps(0)}
//             />

//             <Tab
//               label="Quiz"
//               // disabled={HandleTabIndexEnable(
//               //   unitdata?.topicQuizz.length > 0 ? true : false,
//               //   unitdata?.training_material.length > 0
//               //     ? unitdata?.training_material[
//               //         unitdata?.training_material.length - 1
//               //       ].isCompleted
//               //     : true
//               // )}
//               className="tabs-item"
//               style={{
//                 textTransform: "capitalize",
//               }}
//               {...a11yProps(1)}
//             />
//           </Tabs>
//         </Box>

//         <>
//           <TabPanel
//             value={value}
//             index={0}
//             style={{
//               backgroundColor: "#fff",
//               borderBottomLeftRadius: "12px",
//               borderBottomRightRadius: "12px",
//               borderTopRightRadius: "12px",
//             }}
//           >
//             {/* {trainingMaterial.length !== 0 ? ( */}
//             <Box>
//               <>
//                 <Mandatorytrainingmaterial

//                 // trainingMaterial={trainingMaterial}
//                 // unitId={unitId}
//                 // courseId={courseId}
//                 // getUnitDetails={getUnitDetails}
//                 />
//               </>
//             </Box>
//             {/* ) : ( */}
//             {/* <>
//                     <p
//                       style={{
//                         backgroundColor: "white",
//                         fontSize: "20px",
//                         justifyContent: "center",
//                         fontWeight: "600",
//                         height: "20vh",
//                         display: "flex",
//                         alignItems: "center",
//                       }}
//                     >
//                       No Data Found
//                     </p>
//                   </> */}
//             {/* )}  */}
//           </TabPanel>
//         </>

//         <TabPanel
//           value={value}
//           index={1}
//           style={{
//             backgroundColor: "#fff",
//             borderBottomLeftRadius: "12px",
//             borderBottomRightRadius: "12px",
//             borderTopRightRadius: "12px",
//           }}
//         >
//           <Mandatorytrainingquiz />
//         </TabPanel>
//       </Box>
//       <Notification notify={notify} setNotify={setNotify} />
//     </>
//   );
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MandatoryTrainingTabs(props) {
  const { mandatetrainingData, OverallSlideData, user_id } = props;

  const [value, setValue] = useState(0);
  const [isFinalQuizEnabled, setIsFinalQuizEnabled] = useState(false);
  const [enableQuizTab, setEnableQuiz] = useState(true);
  const [materialData, setMaterialData] = useState(
    mandatetrainingData.training_material
  );
  const [MandateTrainingDetails, setMandateTrainingDetails] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const userUnitTrackId = localStorage.getItem("userUnitTrackId");

  //

  const ClearLocalStorage = () => {
    MandateTrainingDetails[0]?.traningModules?.forEach((data) => {
      localStorage.removeItem(
        `${data.ModuleId}_${MandateTrainingDetails[0].mainId}`
      );
    });
  };

  const handleSetFinalQuiz = (type) => {
    setValue(1);
    setEnableQuiz(true);
    if (type === "final") setIsFinalQuizEnabled(true);
  };

  const handleTabChange = (event, newValue) => {
    if (isFinalQuizEnabled) return setValue(1);
    if (enableQuizTab) return setValue(0);
    setValue(newValue);
  };
  

  return (
    <>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 15,
          height: "auto",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ borderRight: 2, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Training Material"
              className="tabs-item"
              style={{
                textTransform: "capitalize",
              }}
              {...a11yProps(0)}
            />

            <Tab
              label="Quiz"
              // disabled={HandleTabIndexEnable(
              //   unitdata?.topicQuizz.length > 0 ? true : false,
              //   unitdata?.training_material.length > 0
              //     ? unitdata?.training_material[
              //         unitdata?.training_material.length - 1
              //       ].isCompleted
              //     : true
              // )}
              className="tabs-item"
              style={{
                textTransform: "capitalize",
              }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <>
          <TabPanel
            value={value}
            index={0}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {mandatetrainingData.length !== 0 ? (
              <Box>
                {/* <>
                  <Mandatorytrainingmaterial
                    materialData={mandatetrainingData.training_material}
                    // trainingMaterial={trainingMaterial}
                    // unitId={unitId}
                    // courseId={courseId}
                    // getUnitDetails={getUnitDetails}
                  />
                </> */}
                <MandatoryTraningTabPanel
                  user_id={user_id}
                  handleSetFinalQuiz={handleSetFinalQuiz}
                  setEnableQuiz={setEnableQuiz}
                  setMandateTrainingDetails={setMandateTrainingDetails}
                  mandatetrainingData={mandatetrainingData}
                />
              </Box>
            ) : (
              <>
                <p
                  style={{
                    backgroundColor: "white",
                    fontSize: "20px",
                    justifyContent: "center",
                    fontWeight: "600",
                    height: "20vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  No Data Found
                </p>
              </>
            )}
          </TabPanel>
        </>

        <TabPanel
          value={value}
          index={1}
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <MandateUserQuiz
            userUnitTrackId={userUnitTrackId}
            ClearLocalStorage={ClearLocalStorage}
            handleSetFinalQuiz={handleSetFinalQuiz}
          />
        </TabPanel>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default MandatoryTrainingTabs;
