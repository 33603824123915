import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import TrainingMaterialList from "../user-training-material-list";
// import MaterialView from "./user-training-materialview";
import PdfContentExtractor from "../user-training-materialview";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../Networking/API";
import { getMandatoryTraningByIdHelper } from "../../../../helper/mandatoryHelper";
// import MandatoryTrainingMaterialList from "./mandatoryLists";
import MandateDocViewver from "./mandatoryDocViewver";
import MandatoryTraningListItems from "./mandatoryTraningListItem";
import MandatoryQuizQusetions from "./MandatoryTraningQuiz/mandatory-quiz-question";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Notification from "../../../../components/Custom/Notification";

const MandatDoryTraningTabPanel = (props) => {
  const { user_id } = props;
  const [selected, setSelected] = React.useState(0);
  const [totalMaterialCount, setTotalMaterialCount] = useState(0);
  const traningId = window.localStorage.getItem("mandateId");
  const {
    setHeaderTabPanel,
    setDuration,
    mandatetrainingData,
    setIsFinalQuizEnabled,
    handleSetFinalQuiz,
    setEnableQuiz,
  } = props;
  const [materialData, setMaterialData] = useState();
  let [quizIndex, setQuizIndex] = useState(0);
  let [selectedSlides, setSelectedSlides] = useState();
  let [videoTotalTime, setVideoTotalTime] = useState(0);
  let [isQuizEnabled, setIsQuizEnabled] = useState(false);
  let [quizData, setQuizData] = useState([]);
  let [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [MandatoryTraining, setMandatoryTraining] = useState([]);
  const [selectedTrainingModule, setSelectedTraningModule] = useState([]);
  const [loader, setLoader] = useState(true);
  const [links, setLinks] = useState("");
  let [videoLink, setVideoLink] = useState("");
  const [enableFinalQuiz, setEnableFinalQuiz] = useState(false);
  // const { traningId } = useParams();
  const [trainingModule, setTrainingModule] = useState([]);
  const [hostileModule, setHostileModule] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isChecked, setisChecked] = useState(false);
  const [slideData, setSlideData] = useState([]);
  const [isSlidesOver, setIsSlidesOver] = useState(false);
  const [clickedContinue, setClickedContinue] = useState(false);
  const [isLastIndex, setIsLatIndex] = useState([]);
  const [OverallSlideData, setOverallSlideData] = useState([]);
  const [ifSlidesCompleted, setIfSlidesCompleted] = useState(false);
  const [parentVideoId, setParentVideoId] = useState(null);
  const [primaryValue, setPrimaryValue] = useState([]);
  const [dataQuestion, setDataQuestions] = useState([]);
  const [quizStarted, setisQuizStarted] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let existingArray = JSON.parse(localStorage.getItem("myArray")) || [];
  const [trainingMaterial, setTrainingMaterial] = useState(
    mandatetrainingData?.training_material
  );
  let [finalQuizEnable, setFinalQuizEnable] = useState(false);
  const [haveQuiz, setHaveQuiz] = useState(false);
  const [makeit, setMakeIt] = useState(false);

  const selectedObject = mandatetrainingData.training_material.find(
    (item, index) => index === selected
  );
  useEffect(() => {
    let TotalModuleCompleted = 0;
    mandatetrainingData.training_material.map((data) => {
      if (
        window.localStorage.getItem(
          `${user_id}-${data.material_pk}_isCompleted`
        )
      ) {
        TotalModuleCompleted++;
      }
    });
    if (
      mandatetrainingData.training_material.length === TotalModuleCompleted &&
      mandatetrainingData.finalQuiz
    )
      setFinalQuizEnable(true);
  }, [selectedTrainingModule, makeit]);
  let navigate = useNavigate();
  const userUnitTrackId = localStorage.getItem("userUnitTrackId");

  const ClearLocalStorage = () => {
    MandatoryTraining[0]?.traningModules?.forEach((data) => {
      localStorage.removeItem(
        `${data.ModuleId}_${MandatoryTraining[0].mainId}`
      );
    });
    OverallSlideData?.forEach((data) => {
      localStorage.removeItem(
        `SlideId_${data.id}_ModuleId_${MandatoryTraining[0].mainId}`
      );
    });
  };

  useEffect(() => {
    // setMandateTrainingDetails(MandatoryTraining);
    const module = MandatoryTraining[0]?.training_material.find(
      (module, index, array) => {
        return !module.isCompleted;
      }
    );

    setSelectedTraningModule(module);

    // const trainingVideoLink = module
    //   ? module.trainingVideoLink
    //   : MandatoryTraining[0]?.training_material[0].video_link;
  }, [MandatoryTraining]);

  const idCourse = localStorage.getItem("courseId");
  const idUnit = localStorage.getItem("UnitId");

  const handleCancelButton = () => {
    if (idCourse) {
      navigate("/user/courses");
    } else if (idUnit) {
      navigate("/user/courses/units");
    } else {
      navigate("/user/");
    }
  };
  // const links = {
  //   module_url:
  //     "https://absyz-academy.s3.ap-south-1.amazonaws.com/Admin/pre-requisite/Demo%202/1680542341225/Charter%20query%20managment%20%281%29.pdf",
  // };

  const checkBoxData = mandatetrainingData?.training_material?.map(
    (item, index) => {
      return {
        id: index,
        name: item.material_name,
        duration: item.duration,
        pk: item.material_pk,
        isCompleted: item.isCompleted,
      };
    }
  );

  const checkIfQuizExist = (selected) => {
    const IsQuizEnabled = mandatetrainingData.training_material.find((data) => {
      return data.material_pk === selected && data.trainigMaterialQuiz !== null;
    });

    return IsQuizEnabled;
  };

  // useEffect(() => {}, [selected]);

  useEffect(() => {
    if (localStorage.getItem(`${user_id}-${selected}`) == 100) {
      setIsQuizEnabled(true);
    } else {
      setIsQuizEnabled(false);
    }
    // setIsFinalQuizEnabled(true);
    // }
  }, [localStorage.getItem(`${user_id}-${selected}`)]);

  useEffect(() => {
    const selectedobject = mandatetrainingData.training_material.find(
      (item, index) => item.material_pk === selected
    );

    setSelectedTraningModule(selectedobject);
    setVideoLink(
      selectedObject?.content_type?.length > 0
        ? selectedobject?.video_link
        : selectedObject?.material_url
    );
  }, [selected]);

  useEffect(() => {
    setTotalMaterialCount(mandatetrainingData?.training_material?.length);
    setCurrentIndex(0);
  }, [mandatetrainingData]);

  const onPageRequest = (index) => {
    const isQuizCompleted = mandatetrainingData.training_material.find(
      (item, _index) =>
        _index === index - 1 &&
        item.trainigMaterialQuiz &&
        !item.isCompleted &&
        !localStorage.getItem(
          `VideoQuizSubmitted_${item.trainigMaterialQuiz.quizz_pk}`
        )
    );
    console.log(isQuizCompleted, "isQuizCompleted check");
    if (!isQuizCompleted) {
      const selectedobject = mandatetrainingData.training_material.find(
        (item, _index) => _index === index
      );
      if (
        index === mandatetrainingData.training_material.length &&
        mandatetrainingData.finalQuiz &&
        !mandatetrainingData?.finalQuiz?.includes(null)
      ) {
        handleSetFinalQuiz();
        setEnableQuiz(true);
      } else if (index !== mandatetrainingData.training_material.length) {
        setSelectedTraningModule(selectedobject);

        setVideoLink(selectedobject?.material_url);
        setCurrentIndex(index);
        setSelected(selectedobject.material_pk);
        setParentVideoId(selectedobject.material_pk);
        setisQuizStarted(false);
      } else if (
        index === mandatetrainingData.training_material.length &&
        (mandatetrainingData.finalQuiz === null ||
          mandatetrainingData?.finalQuiz?.includes(null))
      ) {
        getResultForSlides();
      }
    } else {
      setSelectedTraningModule(isQuizCompleted);
      setVideoLink(isQuizCompleted?.material_url);
      setCurrentIndex(index - 1);
      setSelected(isQuizCompleted.material_pk);
      setParentVideoId(isQuizCompleted.material_pk);
      setIsQuizEnabled(false);
      setisQuizStarted(false);
    }
  };

  const getResultForSlides = async () => {
    //
    const NoFinalQuiztrack = await API.MandatoryTrainingMaterialTrack(
      traningId,
      UserUnitTrackId
    );
    if (NoFinalQuiztrack.response.api_status === 400) {
      setHaveQuiz(true);
      // setNotify({
      //   isOpen: true,
      //   message: NoFinalQuiztrack?.data[0],
      //   type: "success",
      // });
    } 
    else{
      setNotify({
        isOpen: true,
        message: "Please try Again!",
        type: "error",
      });
    }
  };

  const handleCloseByCancelModal = () => {
    localStorage.clear();
    setHaveQuiz(false);
    navigate("/user/");
  };

  console.log(selectedTrainingModule, "Check with the selected traning list");
  const nextBtnDisabled = (currentIndex, totalMaterialCount) => {
    if (
      selectedTrainingModule?.trainigMaterialQuiz &&
      !localStorage.getItem(
        `VideoQuizSubmitted_${selectedTrainingModule?.trainigMaterialQuiz?.quizz_pk}`
      )
    )
      return false;
    else if (
      localStorage.getItem(
        `VideoQuizSubmitted_${selectedTrainingModule?.trainigMaterialQuiz?.quizz_pk}`
      )
    )
      return true;
    else if (mandatetrainingData.finalQuiz) return true;
    else if (
      currentIndex < totalMaterialCount - 1 &&
      mandatetrainingData.finalQuiz
    )
      return false;
    else return true;
  };

  const UserUnitTrackId = window.localStorage.getItem("userUnitTrackId");

  const checkNextButtonDisabled = () => {
    if (selectedTrainingModule?.isCompleted) return false;
    else if (
      window.localStorage.getItem(
        `${user_id}-${selectedTrainingModule?.material_pk}_isCompleted`
      )
    )
      return false;
    else return true;
  };

  return (
    <>
      <Box sx={{ height: "fit-content" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid item xs={3}>
            {mandatetrainingData ? (
              <MandatoryTraningListItems
                user_id={user_id}
                MandatoryTraining={mandatetrainingData}
                setLinks={setLinks}
                setIsQuizEnabled={setIsQuizEnabled}
                setQuizIndex={setQuizIndex}
                setQuizData={setQuizData}
                videoTotalTime={videoTotalTime}
                currentVideoTime={currentVideoTime}
                selectedTrainingModule={selectedTrainingModule}
                isQuizEnabled={isQuizEnabled}
                setMandatoryTraining={setMandatoryTraining}
                videoLink={videoLink}
                setVideoLink={setVideoLink}
                enableFinalQuiz={enableFinalQuiz}
                setSlideData={setSlideData}
                OverallSlideData={OverallSlideData}
                isSlidesOver={isSlidesOver}
                ifSlidesCompleted={ifSlidesCompleted}
                setSelected={setSelected}
                selected={selected}
                setCurrentIndex={setCurrentIndex}
                parentVideoId={parentVideoId}
                setParentVideoId={setParentVideoId}
                setDataQuestions={setDataQuestions}
                setisQuizStarted={setisQuizStarted}
                setIsFinalQuizEnabled={setIsFinalQuizEnabled}
                existingArray={existingArray}
                // setIsQuizEnabled={setIsQuizEnabled}
                onPageRequest={onPageRequest}
                setCurrentVideoTime={setCurrentVideoTime}
              />
            ) : null}
          </Grid>
          <div style={{ borderLeft: "1px solid #F0F0F0" }}></div>
          <Grid item xs={8}>
            {isQuizEnabled && quizStarted ? (
              <MandatoryQuizQusetions
                MandatoryId={mandatetrainingData.id}
                QuizData={quizData}
                mandatoryData={mandatetrainingData}
                setMandatoryTraining={setMandatoryTraining}
                setIsQuizEnabled={setIsQuizEnabled}
                // setIsQuizEnabled={setIsQuizEnabled}
                setisQuizStarted={setisQuizStarted}
                trainingMaterial={trainingMaterial}
                isQuizEnabled={isQuizEnabled}
                traningId={traningId}
                ClearLocalStorage={ClearLocalStorage}
                setSelectedTraningModule={setSelectedTraningModule}
                selectedTrainingModule={selectedTrainingModule}
                setVideoLink={setVideoLink}
                setCurrentIndex={setCurrentIndex}
                // setVideoLink={setVideoLink}
                setIsFinalQuizEnabled={setIsFinalQuizEnabled}
                finalQuizEnable={finalQuizEnable}
                setParentVideoId={setParentVideoId}
                userUnitTrackId={userUnitTrackId}
                selected={selected}
                setSelected={setSelected}
                dataQuestion={dataQuestion}
                setMakeIt={setMakeIt}
                onPageRequest={onPageRequest}
                handleSetFinalQuiz={handleSetFinalQuiz}
              />
            ) : (
              <>
                <MandateDocViewver
                  link={videoLink}
                  existingArray={existingArray}
                  setVideoTotalTime={setVideoTotalTime}
                  setCurrentVideoTime={setCurrentVideoTime}
                  // contentType={selectedTrainingModule?.content_type}
                  // mediaType={selectedTrainingModule?.media_type}
                  // mediaSource={selectedTrainingModule?.media_source}
                  // thumbNail={selectedTrainingModule?.video_link}
                  MandatoryTraining={mandatetrainingData}
                  selected={selected}
                  selectedTrainingModule={selectedTrainingModule}
                  primaryValue={primaryValue}
                  setPrimaryValue={setPrimaryValue}
                  // name={selectedSlides?.slide_name}
                />
              </>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          {!isQuizEnabled || !quizStarted ? (
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              item
              xs={8.9}
            >
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#000936",
                  border: "1px solid #000936",
                  borderRadius: "4px",
                  height: "35px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={handleCancelButton}
              />

              <Box
                sx={{ display: "flex", float: "right", marginRight: "10%" }}
                className="navigation_button"
              >
                {currentIndex != 0 ? (
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    buttonName="Back"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "white",
                      height: "35px",
                      backgroundColor: "#02215B",
                      borderRadius: "4px",
                      // marginRight: "8px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      onPageRequest(currentIndex - 1);
                      // postTrainingMaterialTrack();
                    }}
                  />
                ) : null}
                {/* {trainingMaterial?.length > 1 ? ( */}
                {nextBtnDisabled(currentIndex, totalMaterialCount - 1) ? (
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    buttonName="Next"
                    disabled={checkNextButtonDisabled()}
                    style={{
                      fontSize: "16px",
                      color: "white",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      height: "35px",
                      letterSpacing: "normal",
                      textAalign: "center",
                      backgroundColor: "#FB2485",
                      borderRadius: "4px",
                      marginRight: "8px",
                      textTransform: "none",
                      border: "none",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      onPageRequest(currentIndex + 1);
                    }}
                  />
                ) : (
                  ""
                )}
                {/* ) : null} */}

                {/* </Box> */}
              </Box>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>

      <Dialog
        open={haveQuiz}
        className="dialogBoxs"
        keepMounted
        onClose={handleCloseByCancelModal}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "12px 0px 0px 0px",
                textAlign: "center",
              }}
            >
              You have successfully completed training
            </h2>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            className="candidateDltepageCancelBttn"
            buttonName="Close"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCloseByCancelModal}
          />
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default MandatDoryTraningTabPanel;
