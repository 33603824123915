const styles = {
  startUnitBtn: {
    backgroundColor: "#FB2485",
    color: "white",
    padding: "6px 24px",
    textTransform: "capitalize",
  },
  courseBackBtn: {
    textTransform: "none",
    color: "#02215b",
  },
  topicsTabPanel: {
    backgroundColor: "#fff",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
  },
  topicsTabLeftGrid: {
    padding: "16px 20px 10px 16px",
    borderRight: "1px solid #F0F0F0",
  },
  feedBackSubmitBtn: {
    textTransform: "capitalize",
    padding: "6px 24px",
  },
};

export const SxStyles = {
  styles,
};
