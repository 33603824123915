/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./user-units-tabs.css";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import UploadIcon from "@mui/icons-material/Upload";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RButton from "../../../components/Custom/button/button";
import Cookies from "js-cookie";
import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";
import { API } from "../../../Networking/API";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import Notification from "../../../components/Custom/Notification";
import { FileUploadErrorMsg } from "../../../constants/errorMsg";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { UserTrainingMaterial } from "./user-training-material";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { useFormik } from "formik";
import { feedbackValidations } from "../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../user-styles/styles";
import { GetUserUnitDetails } from "../../../helper/userHelper";
import { UserPrerequisite } from "./user-prerequisite";
import UserQuiz from "./user-quiz/user-quiz";

const column = [
  {
    field: "Information",
    headerName: "Information",
    headerClassName: "super-app-theme--header",
    className: "quiz-class-column",
    flex: 1,
  },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    flex: 1,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserUnitsTabs(props) {
  const {
    unitId,
    courseId,
    uploadStatus,
    scheduledata,
    trainerdata,
    quizArray,
    prerequiste,
    quizdata,
    quizResultStatus,
    startQuizButton,
    feedbackStatus,
    quizDetails,
    quizDetailsAfterResults,
    trainermaterial,
    getTraineeTabDetails,
    resultLoaded,
    setResultLoaded,
    assignmentData,
    setAssignmentData,
  } = props;

  const ref = useRef();
  const [upload, setUpload] = useState(true);
  const [del, setDel] = useState();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [lastModified, setLastModified] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [quizAltButton, setQuizAltButton] = useState(true);
  const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
  const [status, setStatus] = useState();
  const [time, setTime] = useState(0);
  const [data, setData] = useState([]);
  const [loadingf, setLoadingF] = useState(undefined);
  const [radioValue, setRadioValue] = useState("");
  const [feedbackStatussub, setFeedbackStatusSub] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [done, setDone] = useState(false);
  const location = useLocation();
  const [file, setFile] = useState({});
  const [files, setFiles] = useState([]);
  const [unitdata, setUnitData] = useState();
  const [preRequisite, setPreRequisite] = useState([]);
  const [preskdesc, setpreskdesc] = useState("");
  const [trainingMaterial, setTrainingMaterial] = useState([]);
  const [isUnitStarted, setIsUnitStarted] = useState(false);
  const [trainingDisabled, setTrainingDisabled] = useState(true);
  const [assignmentDisabled, setAssignmentDisabled] = useState(true);
  const [quizzdisabled, setQuizzDisabled] = useState(true);
  const trainingData = unitdata?.training_material;

  const { values, handleSubmit, errors, handleChange, touched, resetForm } =
    useFormik({
      initialValues: {
        feedback: "",
        radioopt: "",
      },
      validationSchema: feedbackValidations,
      onSubmit: (values) => {
        onSubmitEdit();
      },
    });

  useEffect(() => {
    if (assignmentData.length > 0) {
      getAssignmentData();
    }
    getUnitDetails();
  }, []);

  const getUnitDetails = async () => {
    await GetUserUnitDetails(
      unitId,
      courseId,
      setDone,
      setUnitData,
      setFeedbackStatusSub,
      setPreRequisite,
      setpreskdesc,
      setAssignmentData,
      setTrainingMaterial,
      setIsUnitStarted
    );
    setLoadingF(false);
  };
  
  

  useEffect(() => {
    const lastRequisite = preRequisite[preRequisite?.length - 1];
    if (lastRequisite?.isCompleted) {
      setTrainingDisabled(false);
    }
    if (unitdata !== undefined) {
      const last =
        unitdata.training_material[unitdata.training_material.length - 1];
      if (last?.isCompleted && lastRequisite?.isCompleted) {
        setAssignmentDisabled(false);
        setQuizzDisabled(false);
      }
    }
  }, [preRequisite, unitdata]);

  const onSubmitEdit = async () => {
    const { radioopt, feedback } = values;
    setLoadingF(true);
    try {
      API.UnitpostFeed(unitId, courseId, radioopt, feedback).then(
        ({ response }) => {
          resetForm();
          getUnitDetails();
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setNotify({
              isOpen: true,
              message: "FeedBack Submitted",
              type: "success",
            });
          }
          if (resultInJson.api_status === 404) {
            setNotify({
              isOpen: true,
              message: "FeedBack Not Submitted",
              type: "error",
            });
          }
          if (resultInJson.api_status === 500) {
            setNotify({
              isOpen: true,
              message: "Feedback Already Submitted",
              type: "error",
            });
          }
        }
      );
    } catch (e) {}
  };

  Cookies.set("quizdata", JSON.stringify(quizdata[0]));

  const TabClosing = () => {
    setQuizAltButton(false);
    localStorage.removeItem("minsecs");
    localStorage.removeItem("countdownfromapi");
  };
  const closeTab = () => {
    TabClosing();
  };
  const QuizStatusFunction = () => {
    setResultLoaded(false);
    getTraineeTabDetails();
  };

  const getAssignmentData = async () => {
    try {
      API.userGetAssignment(unitId, assignmentData[0].asn_pk).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            if (resultInJson.data.userAssignmentDetails !== null) {
              setFiles(resultInJson.data.userAssignmentDetails?.assignLinks);
              setLastModified(
                resultInJson.data.userAssignmentDetails?.last_update
              );
              setDel(undefined);
              setStatus(resultInJson.data.userAssignmentDetails?.astmt_status);
              setData(resultInJson.data);
              if (resultInJson.data.userAssignmentDetails?.astmt_status === 0) {
                setSubmissionStatus(false);
              } else {
                setSubmissionStatus(true);
              }
            }
            setLoading(false);
            // setStatusDone(true);
          } else if (resultInJson.api_status === 404) {
            setFiles([]);
            setLoading(false);
            // setStatusDone(true);
            setSubmissionStatus(false);
          } else {
            setFiles([]);
            setLoading(false);
            // setStatusDone(true);
            setSubmissionStatus(false);
          }
        }
      );
    } catch (e) {}
  };

  const handleupload = (event) => {
    setFile(event.target.files[0]);
    setUpload(true);
    setErrorStatus(false);
  };

  const handlechangeupload = async (e) => {
    e.preventDefault();
    ref.current.value = "";
    setUpload(false);
    try {
      if (!file.size) {
        setErrorMessage(FileUploadErrorMsg.FILE_SELECT_MSG);
        setErrorStatus(true);
        setLoading(false);
      } else if (files.length < 3) {
        API.uploadAssignment(assignmentData[0].asn_pk, file).then(
          ({ response }) => {
            const resultInJson = response;
            if (resultInJson.api_status === 200) {
              setFile([]);
              setDel(undefined);
              getAssignmentData();
              e.target.value = "";
            } else {
              setErrorMessage(resultInJson.error[0].error_msg);
              setErrorStatus(true);
              setLoading(false);
            }
          }
        );
      }
    } catch (e) {
      setErrorMessage(FileUploadErrorMsg.FILE_VALID);
      setErrorStatus(true);
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const datas = [
    {
      id: 1,
      Information: "Number Questions",
      Status: `${quizDetails.noOFques}`,
    },
    {
      id: 2,
      Information: "Time Duration",
      Status: `${quizDetails.count} mins `,
    },
    { id: 3, Information: "Attempts", Status: 1 },
  ];

  const handleSubmitAll = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      API.submitAllAsssignment(assignmentData[0].asn_pk).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            getAssignmentData();
          } else {
            setNotify({
              isOpen: true,
              message: "File Not Submitted",
              type: "error",
            });
            setSubmitLoading(false);
          }
        }
      );
    } catch (e) {}
  };

  async function handleDelete(id, index) {
    setDel(index);
    try {
      API.userDeleteAssignment(id).then(({ result }) => {
        const resultInJson = result;
        if (resultInJson.api_status === 200) {
          getAssignmentData();
        }
      });
    } catch (e) {}
  }
  const HandleTabIndexEnable = (data1, data2, data3) => {
    if (data1 && data2 && data3) {
      return false;
    }
    if (data1 && data2) {
      return false;
    } else return true;
  };
  // 
  return (
    <>
      <>
        <>
          <Box
            sx={{
              marginTop: 5,
              marginBottom: 15,
              height: "auto",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ borderRight: 2, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Pre-Requisite"
                  className="tabs-item"
                  style={{
                    borderTopLeftRadius: "12px",
                    textTransform: "capitalize",
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Training Material"
                  className="tabs-item"
                  style={{
                    textTransform: "capitalize",
                  }}
                  {...a11yProps(1)}
                />

                <Tab
                  label="Assignment"
                  disabled={HandleTabIndexEnable(
                    unitdata?.topicAssignments.length > 0 ? true : false,
                    unitdata?.training_material.length > 0
                      ? unitdata?.training_material[
                          unitdata?.training_material.length - 1
                        ].isCompleted
                      : true
                  )}
                  className="tabs-item"
                  style={{
                    textTransform: "capitalize",
                  }}
                  {...a11yProps(2)}
                />
                <Tab
                  label="Quiz"
                  disabled={HandleTabIndexEnable(
                    unitdata?.topicQuizz.length > 0 ? true : false,
                    unitdata?.training_material.length > 0
                      ? unitdata?.training_material[
                          unitdata?.training_material.length - 1
                        ].isCompleted
                      : true
                  )}
                  className="tabs-item"
                  style={{
                    textTransform: "capitalize",
                  }}
                  {...a11yProps(3)}
                />
                <Tab
                  label="Feedback"
                  className="tabs-item"
                  style={{
                    borderTopRightRadius: "12px",
                    textTransform: "capitalize",
                  }}
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>

            <>
              <TabPanel
                value={value}
                index={1}
                style={{
                  backgroundColor: "#fff",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  borderTopRightRadius: "12px",
                  paddingBottom: "5%",
                }}
              >
                {trainingMaterial.length !== 0 ? (
                  <Box>
                    {" "}
                    <>
                      <UserTrainingMaterial
                        trainingMaterial={trainingMaterial}
                        unitId={unitId}
                        courseId={courseId}
                        unitdata={unitdata}
                        getUnitDetails={getUnitDetails}
                        handleTabChange={handleTabChange}
                      />
                    </>
                  </Box>
                ) : (
                  <>
                    <p
                      style={{
                        backgroundColor: "white",
                        fontSize: "20px",
                        justifyContent: "center",
                        fontWeight: "600",
                        height: "20vh",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      No Data Found
                    </p>
                  </>
                )}
              </TabPanel>
            </>
            <>
              <TabPanel
                value={value}
                index={0}
                style={{
                  backgroundColor: "#fff",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                {preRequisite.length !== 0 || preskdesc !== "" ? (
                  <div className="Pre-Requisite ">
                    <div className="section1-Pre-Requisite ">
                      <p
                        className="description-text"
                        dangerouslySetInnerHTML={{
                          __html: preskdesc,
                        }}
                      />
                    </div>
                    {preRequisite.length !== 0 ? (
                      <div className="section2-Pre-Requisite ">
                        <UserPrerequisite
                          preRequisite={preRequisite}
                          unitId={unitId}
                          courseId={courseId}
                          setUnitdata={setUnitData}
                          getUnitDetails={getUnitDetails}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <p
                      style={{
                        backgroundColor: "white",
                        fontSize: "20px",
                        justifyContent: "center",
                        fontWeight: "600",
                        height: "20vh",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      No Data Found
                    </p>
                  </>
                )}
              </TabPanel>
            </>

            {/* --------------------------------------------------------------------------------------------------------------------- */}
            {assignmentData.length > 0 ? (
              <TabPanel
                value={value}
                index={2}
                style={{
                  backgroundColor: "#fff",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <div className="assignment">
                  <div className="section1-assignment">
                    <div className="section1-sub-assignment">
                      <p>
                        <b>{assignmentData[0].as_name}</b>
                      </p>
                    </div>

                    {assignmentData[0].as_description !== null ? (
                      // <p className="description-text">
                      //   {assignmentData[0].as_description}
                      // </p>

                      <p
                        className="description-text"
                        dangerouslySetInnerHTML={{
                          __html: assignmentData[0].as_description,
                        }}
                      />
                    ) : (
                      <>
                        <p
                          style={{
                            backgroundColor: "white",
                            fontSize: "20px",
                            justifyContent: "center",
                            fontWeight: "600",
                            height: "20vh",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          No Data Found
                        </p>
                      </>
                    )}
                  </div>
                  <div className="section2-assignment">
                    <Box className="container-section2-assignment">
                      <div className="section2-sub-assignment">
                        <div className="submission">
                          <p>Submission status </p>
                          <p style={{ fontSize: "14px" }}>
                            <b>
                              {!submissionStatus && uploadStatus === 0
                                ? "TimeOut"
                                : !submissionStatus
                                ? status === 0
                                  ? "In Progress"
                                  : "-"
                                : status === 1
                                ? "Submitted"
                                : "Assessed"}
                            </b>
                          </p>
                        </div>
                        <div className="last-modified">
                          <p>Last Modified</p>
                          <p style={{ fontSize: "14px" }}>
                            <b>
                              {lastModified !== undefined ? lastModified : "-"}
                            </b>
                          </p>
                        </div>
                        <div className="Score Achived">
                          <p>Score Achieved</p>
                          <p style={{ fontSize: "14px", textAlign: "center" }}>
                            <b>
                              {status === 2
                                ? `${data.score_achived} of ${data.total_score}`
                                : "NA"}
                            </b>
                          </p>
                        </div>
                      </div>
                    </Box>
                  </div>
                  {assignmentData && assignmentData[0].status !== 2 ? (
                    <div className="section3-assignment">
                      <Box>
                        <h3>
                          <b className="required">Assignment Attachments</b>
                        </h3>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 15,
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: -5,
                            }}
                          >
                            <input
                              type="file"
                              name="productImage"
                              className={
                                assignmentData[0].status === 1
                                  ? !submissionStatus
                                    ? files.length >= 3
                                      ? "upload-box-disabled"
                                      : "upload-box1"
                                    : "upload-box-disabled"
                                  : "upload-box-disabled"
                              }
                              disabled={
                                assignmentData[0].status === 1
                                  ? uploadStatus > 0
                                    ? !submissionStatus
                                      ? files.length >= 3
                                        ? true
                                        : false
                                      : true
                                    : true
                                  : true
                              }
                              onChange={handleupload}
                              ref={ref}
                              style={{
                                height: "43px",

                                fontSize: "0.8em",
                                fontFamily: "Poppins",
                                width: "330px",
                              }}
                            />
                            <RButton
                              buttonName={
                                !loading ? (
                                  "Upload"
                                ) : (
                                  <CircularProgress size={16} />
                                )
                              }
                              variant={"contained"}
                              startIcon={loading ? null : <UploadIcon />}
                              disabled={
                                assignmentData[0].status === 1
                                  ? uploadStatus > 0
                                    ? !loading
                                      ? !submissionStatus
                                        ? files.length >= 3
                                          ? true
                                          : false
                                        : true
                                      : true
                                    : true
                                  : true
                              }
                              className={
                                assignmentData[0].status === 1
                                  ? uploadStatus > 0
                                    ? !loading
                                      ? !submissionStatus
                                        ? files.length >= 3
                                          ? "button-assignment-disabled"
                                          : "button-assignment"
                                        : "button-assignment-disabled"
                                      : "button-assignment-disabled"
                                    : "button-assignment-disabled"
                                  : "button-assignment-disabled"
                              }
                              onClick={(e) => {
                                setLoading(true);
                                handlechangeupload(e);
                              }}
                            />
                          </div>
                          {errorStatus ? (
                            <p className="error">{`${errorMessage}`}</p>
                          ) : (
                            <p className="error2">
                              Only .png, .jpg and .jpeg .docx .pdf format
                              allowed
                            </p>
                          )}
                        </div>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {files.length !== 0
                          ? files.map((item, index) => (
                              <>
                                <Box className="buttonBox">
                                  <a
                                    className="attachment"
                                    href={item.asn_link}
                                    target="_blank"
                                    style={{
                                      textDecorationLine: "none",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <p className="attachment-title">
                                      {`Attachment file ${index + 1}`}
                                    </p>
                                  </a>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    disabled={del === index}
                                  >
                                    {!submissionStatus ? (
                                      <DeleteOutlineOutlinedIcon
                                        className="delete-outlined"
                                        onClick={() => {
                                          handleDelete(item.asmt_pk, index);
                                        }}
                                      />
                                    ) : (
                                      <a
                                        href={item.asn_link}
                                        target="_blank"
                                        style={{
                                          textDecorationLine: "none",
                                          color: "#2089d9",
                                          marginTop: "7px",
                                        }}
                                      >
                                        <VisibilityOutlinedIcon />
                                      </a>
                                    )}
                                  </IconButton>
                                </Box>
                              </>
                            ))
                          : null}
                      </Box>
                      {!submissionStatus && files.length > 0 ? (
                        <Box style={{ textAlign: "right" }}>
                          <RButton
                            buttonName={
                              !SubmitLoading ? (
                                "Submit"
                              ) : (
                                <CircularProgress size={16} color="#fff" />
                              )
                            }
                            variant={"contained"}
                            className={"button-assignment2"}
                            onClick={(e) => {
                              setSubmitLoading(true);
                              handleSubmitAll(e);
                            }}
                          />
                        </Box>
                      ) : null}
                      {status === 2 ? (
                        <Box style={{ marginTop: "20px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box
                                style={{
                                  background: "#02215b",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "10px",
                                  borderTopLeftRadius: "20px",
                                  borderTopRightRadius: "20px",
                                }}
                              >
                                <span
                                  style={{ paddingLeft: "25px", color: "#fff" }}
                                >
                                  Parameters
                                </span>
                                <span
                                  style={{
                                    paddingRight: "25px",
                                    color: "#fff",
                                  }}
                                >
                                  Scores Achieved
                                </span>
                              </Box>
                              {data.score.map((item, index) => {
                                return (
                                  <>
                                    <ListItem
                                      key={index}
                                      secondaryAction={
                                        <div className={"scoreBox"}>
                                          {item.AssignmentScore.score}
                                        </div>
                                      }
                                      disablePadding
                                    >
                                      <Box
                                        role={undefined}
                                        dense
                                        style={{ maxWidth: "80%" }}
                                      >
                                        <ListItemText
                                          primary={item.params_name}
                                          className="parameter-list"
                                          style={{ paddingLeft: "22px" }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <Divider
                                      style={{
                                        marginLeft: "15px",
                                        marginRight: "15px",
                                        backgroundColor: "#bfbfbf",
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </TabPanel>
            ) : (
              <TabPanel
                value={value}
                index={2}
                style={{
                  backgroundColor: "#fff",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <p style={{ backgroundColor: "white" }}>No Data Found</p>
              </TabPanel>
            )}
            {/* {quizArray !== undefined ? ( */}
            {/* --------------------------------------------------------------------------------------------------------------------- */}

            <TabPanel
              value={value}
              index={3}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <UserQuiz unitId={unitId} courseId={courseId} />
            </TabPanel>

            {/* <TabPanel
          value={value}
          index={4}
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <div className="quiz">
            {quizArray.length > 0 ? (
              <>
                <div className="section1-quiz">
                  <p>
                    <b>{quizdata[0].topic}</b>
                  </p>

                  {quizResultStatus === null ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          "& .super-app-theme--header": {
                            backgroundColor: "#d8d8d8",
                            color: "#02215b",
                            fontSize: "16px",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        <DataTable
                          rows={datas}
                          className="datatable-style-quiz"
                          columns={column}
                          hideFooter={true}
                          hideFooterSelectedRowCount={true}
                          hideFooterPagination={true}
                          autoHeight
                          rowHeight={50}
                        />
                      </Box>

                      <div style={{ marginLeft: "10px" }}>
                        <p>Note:</p>
                        <p>
                          1. Please make sure your internet connection is
                          stable.
                        </p>
                        <p>
                          2. Quiz will be automatically submitted for any
                          browser actions / when it's timed out.
                        </p>
                      </div>
                    </>
                  ) : quizDetailsAfterResults.totalquest > 0 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          "& .super-app-theme--header": {
                            backgroundColor: "#d8d8d8",
                            color: "#02215b",
                            fontSize: "16px",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        <CustomDataGrid
                          rowData={{
                            totalquest: quizDetailsAfterResults.totalquest,
                            correctquest:
                              quizDetailsAfterResults.correctquest,
                            percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                          }}
                          rowQuestionsData={{
                            quizQuestions:
                              quizDetailsAfterResults.quizQuestions,
                          }}
                          rowTypes={[
                            "Number Questions",
                            "Correct Answers",
                            "Percentage",
                          ]}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <p style={{ backgroundColor: "white" }}>
                        You have already completed the quiz. Result will be
                        Update soon.
                      </p>
                    </>
                  )}
                </div>
                {quizResultStatus !== null ? ( //change the === to !== for it work as per requirement
                  <></>
                ) : (
                  <>
                    {startQuizButton === false ? (
                      <></>
                    ) : (
                      <>
                        {quizAltButton === true &&
                        quizResultStatus === null ? (
                          <>
                            <div className="button-start-quiz">
                              <span onClick={closeTab}>
                                <Link
                                  // onClick={closeTab}
                                  to="/user/quizQuestions"
                                  state={{ data: quizdata[0] }}
                                  style={{
                                    textDecoration: "none",
                                    color: "none",
                                  }}
                                  target="_blank"
                                >
                                  <RButton
                                    buttonName="Start Quiz"
                                    className={"button-start-quiz-sub"}
                                    style={{ textTransform: "none" }}
                                  />
                                </Link>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {viewQuizStatusButton ? (
                              <>
                                <div className="button-start-quiz">
                                  <span onClick={QuizStatusFunction}></span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : quizResultStatus === "" ? (
              <>
                <p style={{ backgroundColor: "white" }}>No Data Found</p>
              </>
            ) : quizDetailsAfterResults ? (
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#d8d8d8",
                    color: "#02215b",
                    fontSize: "16px",
                    fontWeight: "bold",
                  },
                }}
              >
                <div className="section1-quiz">
                  <p>
                    <b>{quizdata[0].topic}</b>
                  </p>
                </div>
                <CustomDataGrid
                  rowData={{
                    totalquest: quizDetailsAfterResults.totalquest,
                    correctquest: quizDetailsAfterResults.correctquest,
                    percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                  }}
                  rowQuestionsData={{
                    quizQuestions: quizDetailsAfterResults.quizQuestions,
                  }}
                  rowTypes={[
                    "Number Questions",
                    "Correct Answers",
                    "Percentage",
                  ]}
                />
              </Box>
            ) : (
              <>
                <p style={{ backgroundColor: "white" }}>No Quiz Found.</p>
              </>
            )}
          </div>
        </TabPanel> */}

            <TabPanel
              value={value}
              index={4}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              {loadingf ? (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fb2485"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <>
                  {feedbackStatussub === 1 ? (
                    <p>You're Feedback Is Submitted Successfully.</p>
                  ) : (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="feedBack">
                          <div className="section1-feedback">
                            <FormControl>
                              <p style={{ fontFamily: "poppins" }}>
                                How would you rate this course?
                              </p>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="radioopt"
                                value={values.radioopt}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="Outstanding"
                                  control={<Radio />}
                                  label="Outstanding."
                                />
                                <FormControlLabel
                                  value="Good"
                                  control={<Radio />}
                                  label="Good."
                                />
                                <FormControlLabel
                                  value="Satisfactory"
                                  control={<Radio />}
                                  label="Satisfactory."
                                />
                                <FormControlLabel
                                  value="Unsatisfactory"
                                  control={<Radio />}
                                  label="Unsatisfactory."
                                />
                              </RadioGroup>
                              {touched.radioopt && errors.radioopt ? (
                                <FormHelperText style={{ color: "red" }}>
                                  {errors.radioopt}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </div>
                          <Grid container>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minWidth: "85vw",
                                  marginTop: "10px",
                                }}
                              >
                                <ReusableInputfield
                                  name="feedback"
                                  placeholder="You can write upto 1000 words.."
                                  value={values.feedback}
                                  onChange={handleChange}
                                  size="small"
                                  multiline
                                  rows={6}
                                  displayEmpty
                                  error={
                                    touched.feedback && Boolean(errors.feedback)
                                  }
                                  helperText={
                                    touched.feedback && errors.feedback
                                  }
                                  InputProps={{
                                    inputComponent: TextareaAutosize,
                                    inputProps: {
                                      style: {
                                        resize: "auto",
                                        height: "100%",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <div
                            className="section3-feedback"
                            style={{ marginRight: "0px" }}
                          >
                            <RButton
                              buttonName={"Submit"}
                              type="submit"
                              className={"section3-feedback-sub"}
                              style={SxStyles.styles.feedBackSubmitBtn}
                            />
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </>
              )}

              {/* } */}
            </TabPanel>
          </Box>
        </>
        <Notification notify={notify} setNotify={setNotify} />
      </>
    </>
  );
}
