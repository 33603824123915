import { React, useState } from "react";
import Button from "@mui/material/Button";
import "../../mentor/mentor-sessions-details/mentor-sessions-details.css";

export default function List({
  contacts,
  clickStatus,
  sessionName,
  sessionId,
}) {
  const [innerText, setInnerText] = useState("");

  const handleClick = (event) => {
    setInnerText(event.target.innerText);
    sessionName(event.target.innerText);
    sessionId(event.target.nextSibling.innerText);
    clickStatus(true);
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        overflowY: "scroll",
        height: "800px",
      }}
    >
      {contacts.length <= 0 ? (
        "No Sessions Found"
      ) : (
        <>
          {contacts.map((contact, index) => (
            <>
              <Button
                key={index + 1}
                id={index + 1}
                onClick={handleClick}
                className={
                  innerText.toLowerCase() === contact.user_name.toLowerCase()
                    ? "selected"
                    : "NotSelected"
                }
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                  textTransform: "capitalize",
                  padding: "12px 14px",
                  marginBottom: "3px",
                }}
              >
                {contact.user_name}
              </Button>
              <span style={{ display: "none" }}>{contact.user_pk}</span>
            </>
          ))}
        </>
      )}
    </div>
  );
}
