import { API } from "../Networking/API";
import { DataTableHandler } from "../handler/dataTableHandler";

const GetCourseHelper = async (
  setCoursedata,
  checkboxId,
  setCheckboxId,
  setTotalUnitData,
  setCourseDuration,
  setUnits,
  formik,
  id,
  activeDate,
  setActiveDate,
  setDone
) => {
  setDone(false);
  await API.adminGetCourse(id).then(async ({ response }) => {
    if (response.data.api_status === 200) {
      setDone(true);
      const updatedresponse = response.data;
      setCoursedata(updatedresponse);
      activeDate = updatedresponse.data.active_date.slice(0, 10);
      setActiveDate(activeDate);
      const updatedData = await DataTableHandler.AdminGetUnits(
        updatedresponse.data.units
      );
      const updatedDataIndex = updatedData.map((data) => {
        return data.id;
      });

      let exitUnitIndex = checkboxId;
      updatedDataIndex.forEach((data) => {
        exitUnitIndex.add(data);
      });
      setCheckboxId(exitUnitIndex);
      setTotalUnitData(updatedData);
      let sum = 0;
      updatedData.forEach((item) => {
        sum += item.unitDuration;
        return sum;
      });
      formik.values.duration = sum;
      setCourseDuration(sum);
      setUnits(updatedData);
      formik.values.courseName = updatedresponse.data.course_name;
      formik.values.technology = updatedresponse.data.technology;
      formik.values.difficultyLevel =
        updatedresponse.data.courseLevelDetails.id;
      formik.values.description = updatedresponse.data.about_course;
      formik.values.activeDate = updatedresponse.data.active_date.slice(0, 10);
    }
  });
};

const DeleteCourseHelper = async (courseId, setOpen, setNotify, open) => {
  const id = courseId;
  await API.deleteCourse(id).then(async (response) => {
    if (response.result.api_status === 200) {
      setNotify({
        isOpen: true,
        message: response.result.data[0].message,
        type: "success",
      });
      return true;
    } else {
      setNotify({
        isOpen: true,
        message: response.result.error[0].error_msg,
        type: "error",
      });
      return false;
    }
  });
};

export { GetCourseHelper, DeleteCourseHelper };
