import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import TimePicker from "react-time-picker";
import { Custom } from "../../../components/Custom/Custom";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import "./schedule.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { API } from "../../../Networking/API";
import { MyForm } from "../../../components/MyForm";
import ReactLoading from "react-loading";
import Notification from "../../../components/Custom/Notification";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const dropdownValue = [
  { id: "0", title: "0" },
  { id: "1", title: "1" },
  { id: "2", title: "2" },
  { id: "3", title: "3" },
  { id: "4", title: "4" },
  { id: "5", title: "5" },
];

const initialValues = {
  assignmentname: "",
  description: "",
  dateOfJoin: new Date(),
  dateOfJoin01: new Date(),
  fromTimeOfQuiz: "10:00",
  toTimeOfQuiz: "10:00",
};

const editInitialValues = {
  assignmentname: "",
  description: "",
  dateOfJoin: new Date(),
  dateOfJoin01: new Date(),
  fromTimeOfQuiz: "",
  toTimeOfQuiz: "",
};

const Assignment = (props) => {
  const { scheduleId } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [fromtime, setFromTime] = useState("10:00");
  const [done, setDone] = useState(undefined);
  const [totime, setToTime] = useState("10:00");
  const [schedID, setSchedID] = useState();
  const [available, setAvailable] = useState(true);
  // for parameters

  const [addParamId, setAddParamId] = useState();
  const [openAddParam, setOpenAddParam] = useState(false);

  const [editParamsId, setEditParamsId] = useState();
  const [openEditParams, setOpenEditParams] = useState(false);
  const [editValues, setEditValues] = useState(editInitialValues);
  const [file, setFile] = useState("");
  const [editFromTime, setEditFromTime] = useState();
  const [editTotime, setEditToTime] = useState();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [parameterName, setParameterName] = useState();
  const [parameterScore, setParameterScore] = useState("none");
  const [errorParameterName, setErrorParameterName] = useState("");
  const [errorParameterScore, setErrorParameterScore] = useState("");
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [asnId, setAsnId] = useState();
  const ref = useRef();

  const handleClickOpen = (value) => {
    setId(value);
    setOpen(true);
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      editable: true,
      width: 30,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
      hide: true,
    },
    {
      field: "parameterName",
      headerName: "Parameter Name",
      width: 450,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "score",
      headerName: "Parameter Score",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="delete-button">
            <IconButton aria-label="delete" size="small">
              {" "}
              <DeleteOutlineIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  //fontSize: '14px !important',
                }}
                onClick={() => handleClickOpen(params.id)}
              />
            </IconButton>

            <Dialog
              open={open}
              className="dialogBox"
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseByCancel}
              aria-describedby="alert-dialog-slide-description"
              BackdropProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
              >
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseByCancel}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    marginRight: "10px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                  onClick={() => handleDelete()}
                ></ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("description" in inputValues)
      code.description = inputValues.description
        ? ""
        : "Description is required";
    if ("assignmentname" in inputValues)
      code.assignmentname = inputValues.assignmentname
        ? ""
        : "Assignment Name is required";
    setErrors({
      ...code,
    });
    //
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const intialValue = openEditParams ? editInitialValues : initialValues;
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(intialValue, true, validate);

  const handleCancelPopUp = () => {
    setShowEdit(true);
    setErrorParameterName("");
    setErrorParameterScore("");
  };

  const onFromTimeChange = (e) => {
    setFromTime(e);
  };

  const onToTimeChange = (e) => {
    setToTime(e);
  };

  useEffect(() => {
    getScheduleById(scheduleId);
    setTableData([]);
  }, [scheduleId]);

  const getScheduleById = async (id) => {
    setAvailable(true);
    API.getAdminAssignment(id).then(async ({ response }) => {
      if (response.data.api_status === 200) {
        setDone(true);
        setSchedID(response.data.data[0].schedule_details.schedule_id);

        const assigndata = response.data.data[0].assignments[0].params;
        const data = response.data.data[0].assignments[0];
        const updatedData = await DataTableHandler.AdminAssignment(assigndata);
        setAsnId(response.data.data[0].assignments[0].asn_pk);
        setValues({
          ...values,
          assignmentname: data.as_name,
          description: data.as_description,
          fromTimeOfQuiz: data.as_from_time,
          toTimeOfQuiz: data.as_to_time,
          dateOfJoin: data.as_from_date,
          dateOfJoin01: data.as_to_date,
        });
        setTableData(updatedData);
        setFromTime(data.as_from_time);
        setToTime(data.as_to_time);
        setShowAddBtn(false);
        // setParameterScore();
        setShowEdit(true);
        setErrorParameterName("");
        setErrorParameterScore("");
      }
      if (response.data.api_status === 404) {
        setAvailable(false);
        setSchedID(response.data.data[0].schedule_details.schedule_id);
        setDone(true);
        setTableData([]);
        setShowEdit(false);
        setShowAddBtn(true);
        setErrorParameterName("");
        setErrorParameterScore("");
        // setSchedTopic(response.data.data[0].schedule_details.topic);
        // setDesp(response.data.data[0].pre_requisites[0].prereq_description);
      }
    });
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleDelete = () => {
    handleCloseByCancel();
    API.adminAsnDeleteParams(id).then(({ result }) => {
      if (result.api_status === 200) {
        getScheduleById(scheduleId);
        setNotify({
          isOpen: true,
          message: result.data[0].message,
          type: "success",
        });
      } else {
        getScheduleById(scheduleId);
        setNotify({
          isOpen: true,
          message: "Params already in use",
          type: "error",
        });
      }
    });
  };

  const handleParameter = (e) => {
    setParameterName(e.target.value);
    setErrorParameterName("");
  };

  const handleParameterScore = (e) => {
    setParameterScore(e.target.value);
    setErrorParameterScore("");
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    if (
      parameterName !== "" &&
      parameterScore !== "" &&
      parameterName !== undefined &&
      parameterScore !== undefined &&
      parameterScore !== "none"
    ) {
      API.adminAsnAddParams(parameterName, parameterScore, asnId).then(
        ({ response }) => {
          setParameterScore("none");
          if (response.api_status === 200) {
            setNotify({
              isOpen: true,
              message: response.data,
              type: "success",
            });
            getScheduleById(scheduleId);
            setParameterName("");
            setErrorParameterName("");
            setErrorParameterScore("");
          } else {
            getScheduleById(scheduleId);
            setParameterName("");
            setErrorParameterName("");
            setErrorParameterScore("");
            setNotify({
              isOpen: true,
              message: "Something went wrong, Please try again",
              type: "error",
            });
          }
        }
      );
    } else if (parameterName === "") {
      setErrorParameterName("Paramter is required");
      setErrorParameterScore("");
    } else if (parameterScore === "" && parameterScore === "none") {
      setErrorParameterName("");
      setErrorParameterScore("Score is required");
    } else {
      setErrorParameterScore("Score is required");
    }
  };

  const handleSubmit = (e) => {
    const date = convert(values.dateOfJoin);
    const date01 = convert(values.dateOfJoin01);
    if (showAddBtn) {
      if (validate() && parameterName !== "" && parameterScore !== "") {
        setShowEdit(!showEdit);
        setErrorParameterName("");
        setErrorParameterScore("");
        API.addAssignmentParams(
          scheduleId,
          values.assignmentname,
          date,
          date01,
          fromtime,
          totime,
          values.description,
          parameterName,
          parameterScore
        ).then(({ response }) => {
          setParameterScore("none");
          if (response.api_status === 200) {
            setNotify({
              isOpen: true,
              message: response.data[0].message,
              type: "success",
            });
            getScheduleById(scheduleId);
            setParameterName("");
            // setParameterScore();
          } else {
            setNotify({
              isOpen: true,
              message: "something went wrong",
              type: "error",
            });
            getScheduleById(scheduleId);
          }
        });
      } else if (parameterName === "") {
        setErrorParameterName("Paramter is required");
        setErrorParameterScore("");
      } else if (parameterScore === "" && parameterName === "none") {
        setErrorParameterName("");
        setErrorParameterScore("Score is required");
      } else {
        setErrorParameterName("Paramter is required");
        setErrorParameterScore("Score is required");
      }
    } else {
      setShowEdit(!showEdit);
      if (validate()) {
        setShowEdit(!showEdit);
        if (!showEdit) {
          API.editAssignmentOnly(
            asnId,
            values.assignmentname,
            date,
            date01,
            fromtime,
            totime,
            values.description
          ).then(({ response }) => {
            setParameterScore("none");
            if (response.api_status === 200) {
              if (
                parameterName !== "" &&
                parameterScore !== "" &&
                parameterName !== undefined &&
                parameterScore !== undefined &&
                parameterScore !== "none"
              ) {
                setNotify({
                  isOpen: true,
                  message: response.data,
                  type: "success",
                });
                getScheduleById(scheduleId);
                setParameterName("");
                setShowEdit(true);
                // setParameterScore();
              } else {
                setNotify({
                  isOpen: true,
                  message: "please Add Parameter",
                  type: "error",
                });
                setShowEdit(false);
                setErrorParameterName("Paramter is required");
                setErrorParameterScore("Score is required");
              }
              //
            } else {
              setNotify({
                isOpen: true,
                message: "something went wrong",
                type: "error",
              });
              getScheduleById(scheduleId);
            }
          });
        }
      }
      // else {

      //   setShowEdit(false);
      // }
    }
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                    marginBottom: "5px",
                  }}
                >
                  Assignment Name
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    *
                  </span>
                </Typography>
                <span>
                  <Custom.Input
                    name="assignmentname"
                    placeholder="Assignment Name"
                    className="assignment-input"
                    onChange={handleInputChange}
                    value={values.assignmentname}
                    error={errors.assignmentname}
                    disabled={showEdit}
                    size="small"
                    style={{ width: "60% !important", marginBottom: "10px" }}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Select From Date
                </span>
                <span style={{}}>
                  <Custom.DatePicker
                    name="dateOfJoin"
                    className=""
                    value={values.dateOfJoin}
                    onChange={handleInputChange}
                    size="small"
                    // minDate={minDate}
                    // maxDate={toDate}
                    disabled={showEdit}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Select To Date
                </span>
                <span style={{}}>
                  <Custom.DatePicker
                    name="dateOfJoin01"
                    className=""
                    value={values.dateOfJoin01}
                    onChange={handleInputChange}
                    size="small"
                    // minDate={fromDate}
                    // maxDate={maxDate}
                    disabled={showEdit}
                    // sx={{width :'245px !important'}}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2.2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "-5px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                    marginBottom: "5px",
                  }}
                  variant="h6"
                >
                  Start Time
                </span>
                <span style={{}}>
                  <TimePicker
                    name="toTimeOfQuiz"
                    onChange={onFromTimeChange}
                    value={fromtime}
                    disabled={showEdit}
                    className="time-container"
                    disableClock={true}
                    style={{
                      border: " 1px solid #B9B9B9",
                      borderRadius: "8px",
                    }}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "-5px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                    marginBottom: "5px",
                  }}
                  variant="h6"
                >
                  End Time
                </span>
                <span style={{}}>
                  <TimePicker
                    name="toTimeOfQuiz"
                    onChange={onToTimeChange}
                    value={totime}
                    disabled={showEdit}
                    className="time-container"
                    disableClock={true}
                    style={{
                      border: " 1px solid #B9B9B9",
                      borderRadius: "8px",
                    }}
                  />
                </span>
              </div>
            </Grid>
          </Grid>
          <h1
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
              /* identical to box height, or 125% */
              color: "#0A0A0A",
              marginTop: "40px",
            }}
          >
            Required
          </h1>
          <textarea
            style={{
              width: "100%",
              height: "17vh",
              boxSizing: "border-box",
              border: "1px solid #ccc",
              borderRadius: "4px",
              paddingLeft: "12px",
              paddingTop: "9px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              color: "#0A0A0A",
              fontSize: "14px",
            }}
            className="description_in_assignment"
            name="description"
            placeholder="Description"
            onChange={handleInputChange}
            value={values.description}
            error={errors.description}
            disabled={showEdit}
            multiline
            size="medium"
          />
          {errors.description !== "" ? (
            <span
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                color: "red",
                fontSize: "12px",
              }}
            >
              {errors.description}
            </span>
          ) : null}
          <Grid
            container
            spacing={2}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Grid item xs={5}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                    marginBottom: "5px",
                  }}
                >
                  Parameters
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    *
                  </span>
                </Typography>
                <span>
                  <Custom.Input
                    name="assignmentname"
                    placeholder="Provide Parameters Question"
                    className="parameter-input"
                    onChange={handleParameter}
                    value={parameterName}
                    error={errorParameterName}
                    disabled={showEdit}
                    size="small"
                    style={{ width: "60% !important", marginBottom: "10px" }}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                    marginBottom: "5px",
                    visibility: "hidden",
                  }}
                >
                  Score
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  ></span>
                </Typography>
                <Custom.Dropdown5
                  name="parameterScore"
                  value={parameterScore}
                  options={dropdownValue}
                  disabled={showEdit}
                  onChange={handleParameterScore}
                  size="small"
                  style={{
                    height: 40,
                    color: "black",
                  }}
                ></Custom.Dropdown5>
                {errorParameterScore !== "" ? (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorParameterScore}
                  </span>
                ) : null}
              </div>
            </Grid>
            {showAddBtn ? (
              <Grid item xs={2}></Grid>
            ) : (
              <Grid item xs={2}>
                <ReusableButton
                  //  onClick={handleClose}
                  //type="submit"
                  //   onClick={() => onSubmitEdit(id)}
                  onClick={(e) => handleAddSubmit(e)}
                  size="large"
                  variant="contained"
                  buttonName="Add"
                  disabled={showEdit}
                  style={
                    !showEdit
                      ? {
                          backgroundColor: "#fb2485",
                          width: "98px",
                          height: "38px",
                          borderRadius: 4,
                          color: "#fff",
                          marginTop: "25px",
                          marginLeft: "10px",
                        }
                      : {
                          backgroundColor: "grey",
                          width: "98px",
                          height: "38px",
                          borderRadius: 4,
                          color: "#fff",
                          marginTop: "25px",
                          marginLeft: "10px",
                        }
                  }
                >
                  Add
                </ReusableButton>
              </Grid>
            )}
          </Grid>
          {tableData.length > 0 ? (
            <DataTable
              rows={tableData}
              columns={columns}
              rowHeight={50}
              autoHeight
              pageSize={10}
              // hideFooter={true}
              // hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
              className="assigntable"
            />
          ) : (
            <Box
              style={{
                backgroundColor: "#fcfbfb",
                height: "20vh",
                width: "100%",
                // marginLeft: "30px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                className="no-content"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                No Assignment found. Please Add an Assignment
              </h1>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ReusableButton
              onClick={handleCancelPopUp}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={{
                marginRight: "10px",
                color: "#000936",
                border: "1px solid #000936",
                marginBottom: "40px",
                width: "98px",
                height: "38px",
                borderRadius: 4,
              }}
            >
              Cancel
            </ReusableButton>
            <ReusableButton
              // onClick={handleClose}
              //type="submit"
              // onClick={onSubmitEdit}
              onClick={(e) => handleSubmit(e)}
              size="large"
              variant="contained"
              buttonName={showEdit ? "Edit" : "Save"}
              style={{
                backgroundColor: "#fb2485",
                marginBottom: "40px",
                width: "98px",
                height: "38px",
                borderRadius: 4,
                color: "#fff",
              }}
            >
              {showEdit ? "Save" : "Edit"}
            </ReusableButton>
          </Box>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Assignment;
