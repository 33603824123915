import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import "./trainee-dashboard.css";
import Cookies from "js-cookie";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import Avatar from "@mui/material/Avatar";
import { API } from "../../../Networking/API";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TraineeDashboard = () => {
  const [batchId, setBatchId] = useState(localStorage.getItem("batchType"));
  const [sessionsData, setSessionData] = useState();
  const [userData, setUserData] = useState("");
  const [traineedata, setTrainee_data] = useState();
  const [topachivers, setTop_achivers] = useState();
  const [batchIdForTopAchievers, setBatchIdForTopAchievers] = useState();
  const [formatTopAchiever, setFormatTopAchiever] = useState();
  const [scoresAchieved, setScoresAchieved] = useState();
  const [done, setDone] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("TrainSessionTabs")) {
      localStorage.removeItem("TrainSessionTabs");
    }
    getscoresAchieved();

    const getCookieValue = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };

    const TopAchieversCookie = getCookieValue("batch_id");

    if (TopAchieversCookie !== null) {
      const parsedEntryType = JSON.parse(TopAchieversCookie);
      setBatchIdForTopAchievers(parsedEntryType);
    }
  }, [batchIdForTopAchievers]);
  const courseId = localStorage.getItem("courseId");

  const getDashboardData = async () => {
    API.getTraineeDashBoardData(courseId).then(({ response }) => {
      if (response.data.api_status === 200) {
        const sessionDetails = response.data.data[0].schedules;
        const top_achivers = response.data.data[0].top_achivers;
        const formatted_top_achivers = top_achivers.map((eachData) => ({
          percentage: eachData.percentage,
          name: eachData.user_name,
          user_profile: eachData.user_profile,
        }));
        const trainee_data = response.data.data[0].trainee_data[0];
        const userDetails = response.data.data[0].user_details;
        const formattedSessionDetails = sessionDetails.map((eachData) => ({
          id: eachData.schedule_pk,
          date: eachData.training_date,
          day: eachData.day,
          topic: eachData.topic,
          time: eachData.time,
          duartion: eachData.duration,
          trainingMaterial: eachData.training_material,
          name: eachData?.trainer_details?.user_name,
          designation: eachData?.trainer_details?.work_posiotion,
          email: eachData?.trainer_details?.user_email.split(".")[0],
          experience: eachData?.trainer_details?.work_experience,
          certificate: eachData?.trainer_details?.certificates,
          sessions: eachData?.trainer_details?.sessions_assigned,
        }));
        setUserData(userDetails);
        setTop_achivers(formatted_top_achivers);
        setTrainee_data(trainee_data);
        setSessionData(formattedSessionDetails);
        setDone(true);
      } else if (response.data.api_status === 403) {
        Cookies.remove("jwt_token");
        navigate("/");
      }
    });
  };
  useEffect(() => {
    getTopAchievers();
    getDashboardData();
  }, [batchIdForTopAchievers]);

  const getTopAchievers = () => {
    if (batchIdForTopAchievers) {
      API.getTraineeTopAchievers(batchIdForTopAchievers).then(
        ({ response }) => {
          const data = response.data.data;
          if (response.data.api_status === 200) {
            const formatTopAchievers = data.map((every) => ({
              percentage:
                (every.quizPercantage +
                  (every.assignmentPercantage === null
                    ? 0
                    : every.assignmentPercantage)) /
                2,
              // percentage: every.overAllPercantage,
              name: every.userData.first_name,
            }));
            setFormatTopAchiever(formatTopAchievers);
          }
        }
      );
    }
  };

  const getscoresAchieved = () => {
    if (batchIdForTopAchievers) {
      API.getTraineeScoresAchieved(batchIdForTopAchievers).then(
        ({ response }) => {
          const scoresachived = response.data.data[0];
          if (response.data.api_status === 200) {
            const formatscoresAchieved = [scoresachived].map((every) => ({
              overal_percentage: every.overAllPercantage,
              total_sessions_attended: every.totalSessionsCompleted,
              quiz_percentage: every.quizPercantage,
              user_asn_count: every.assignmentAttemptedCount,
              asn_count: every.assignmentsCount,
            }));
            setScoresAchieved(formatscoresAchieved);
          }
        }
      );
    }
  };

  const handleDashboardBack = () => {
    navigate("/user/");
    localStorage.removeItem("batchType");
    localStorage.removeItem("isSwitch");
  };
  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <h1 className="traineeHeading">Welcome {userData.user_name}!</h1>
          <Button
            buttonName="Back"
            className="traineebutton"
            startIcon={<ArrowBackIosIcon />}
            style={{ textTransform: "none", color: "#02215b" }}
            // onClick={() => navigate("/trainee/home/calendardetails")}
            onClick={handleDashboardBack}
          />
          <Box
            sx={{ marginLeft: "30px", marginRight: "30px", marginTop: "0px" }}
          >
            {sessionsData !== undefined ? (
              <Custom.CustomCarousel
                height={"350px"}
                items={sessionsData}
                autoPlay={false}
                kind="session"
                // path="/trainee/home/sessiondetails"
                path="/user/bootcamp/home/sessiondetails"
              />
            ) : null}
            <Grid container spacing={2} style={{ marginBottom: "100px" }}>
              <Grid item xs={4} md={6}>
                <Box className="first-card-container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginRight: "15%",
                    }}
                  >
                    <h1 className="bottom-card-container">Scores Achieved</h1>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={2}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <span className="session-attented-count">
                          {scoresAchieved ? (
                            scoresAchieved[0]?.total_sessions_attended
                          ) : (
                            <span>0</span>
                          )}
                        </span>
                        <span className="session-text">Sessions Attended</span>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={1}>
                      <div className="card-border"></div>
                    </Grid>
                    <Grid item xs={4} md={9}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p className="score-text">
                            Overall Score :{" "}
                            {scoresAchieved ? (
                              scoresAchieved[0].overal_percentage !== null ? (
                                <>{scoresAchieved[0]?.overal_percentage}%</>
                              ) : (
                                <span>0%</span>
                              )
                            ) : (
                              <span>0%</span>
                            )}
                          </p>
                        </Box>
                        <hr className="line" />
                        <Grid container spacing={0}>
                          <Grid item xs={4} md={7} sx={{ textAlign: "center" }}>
                            <span className="assignment-score">
                              {scoresAchieved ? (
                                `${scoresAchieved[0]?.user_asn_count} / ${scoresAchieved[0]?.asn_count} `
                              ) : (
                                <>
                                  <span>0</span>/<span>0</span>
                                </>
                              )}
                            </span>
                            <p className="assignment-score-test">
                              Assignments Scored
                            </p>
                          </Grid>
                          <Grid item xs={4} md={5} sx={{ textAlign: "center" }}>
                            <span className="assignment-score">
                              {scoresAchieved ? (
                                scoresAchieved[0]?.quiz_percentage !== null ? (
                                  <>{scoresAchieved[0]?.quiz_percentage}%</>
                                ) : (
                                  <span>0%</span>
                                )
                              ) : (
                                <span>0%</span>
                              )}
                            </span>
                            <p className="assignment-score-test">
                              Quizzes Scored{" "}
                            </p>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} md={6}>
                <Box className="first-card-container">
                  <h1 className="bottom-card-container">Top Achievers</h1>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatTopAchiever?.map((item) => (
                      <>
                        <Grid item xs={4} md={3}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "-35px",
                            }}
                            className="trainee-vertical-line"
                          >
                            {item?.user_profile === null ||
                            item?.user_profile === undefined ? (
                              // <img
                              //   src={Icon}
                              //   alt="userIcon"
                              //   className="user-icon"
                              // />
                              <AccountCircleIcon
                                style={{
                                  width: "66px",
                                  height: "66px",
                                  color: "#03215a",
                                }}
                                className={"AccountPIcon"}
                              />
                            ) : (
                              <Avatar
                                src={item.user_profile}
                                alt="image"
                                sx={{ width: 66, height: 66 }}
                                className="user-icon"
                              />
                            )}

                            <p className="user-icon-name">{item?.name}</p>
                            <spn className="user-icon-score">
                              Scored {item?.percentage}%
                            </spn>
                          </div>
                        </Grid>
                        <Grid item xs={4} md={1}>
                          <div className="card-border"></div>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default TraineeDashboard;
