/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";
import RButton from "../../../../components/Custom/button/button";
import Cookies from "js-cookie";
import CustomDataGrid from "../../../../components/reusable-datagrid/CustomDataGrid";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Notification from "../../../../components/Custom/Notification";
import { GetUserUnitDetails } from "../../../../helper/userHelper";
import { API } from "../../../../Networking/API";
import UserInitialQuiz from "./user-initial-quiz";
import UserQuizQuestions from "./user-quiz-questions";

const quizValuesafterresults = {
  totalquest: "",
  correctquest: "",
  percentagescored: "",
};

const quizValues = {
  count: "",
  noOFques: "",
};

const UserQuiz = (props) => {
  const { unitId, courseId } = props;
  const [done, setDone] = useState(false);
  const [quizArray, setQuizArray] = useState("");
  const [quizdata, setQuizdata] = useState("");
  const [quizResultStatus, setQuizResultStatus] = useState("");
  const [startQuizButton, setStartQuizButton] = useState("");
  const [topicName, setTopicName] = useState("");
  const [quizDetails, setQuizDetails] = useState(quizValues);
  const [quizDetailsAfterResults, setQuizDetailsAfterResults] = useState(
    quizValuesafterresults
  );
  const [resultLoaded, setResultLoaded] = useState(true);
  const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
  const [quizStarted, setQuizStarted] = useState(false);

  const datas = [
    {
      id: 1,
      Information: "Number Questions",
      Status: `${quizDetails.noOFques}`,
    },
    {
      id: 2,
      Information: "Time Duration",
      Status: `${quizDetails.count} mins `,
    },
    { id: 3, Information: "Attempts", Status: 1 },
  ];

  const column = [
    {
      field: "Information",
      headerName: "Information",
      headerClassName: "super-app-theme--header",
      className: "quiz-class-column",
      flex: 1,
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  useEffect(() => {
    getQuizDetails();
    setDone(false);
  }, [quizStarted]);

  const getQuizDetails = () => {
    API.getUserUnitDetails(unitId, courseId).then(({ response }) => {
      setDone(true);
      if (response.api_status === 200) {
        const data = response.data.unitDetails;
        const quizToWrite = data.topicQuizz;
        setQuizArray(quizToWrite);

        const quizResult = quizToWrite[0].userQuizz;
        setQuizResultStatus(quizResult);

        // if userQuizz value present 1 else 0

        const quizResultValues = quizToWrite[0].userQuizz;
        if (quizToWrite.length > 0) {
          const formattedQuizToWrite = quizToWrite.map((eachdata) => ({
            id: eachdata.quizz_pk,
            noOfQuest: eachdata.no_of_questions,
            duration: eachdata.countdown,
            quizNumber: eachdata.quizz_pk,
            questcount: eachdata.no_of_questions,
            topic: eachdata.topic,
            buttonstart: eachdata.button,
          }));
          setStartQuizButton(formattedQuizToWrite[0].buttonstart);
          setQuizdata(formattedQuizToWrite);
          setTopicName(formattedQuizToWrite.topic);
          setQuizDetails({
            ...quizDetails,
            count: quizToWrite[0].countdown,
            noOFques: quizToWrite[0].no_of_questions,
          });
        }
        setQuizDetailsAfterResults({
          ...quizDetailsAfterResults,
          totalquest: quizResultValues ? quizResultValues.total_questions : 0,
          correctquest: quizResultValues
            ? quizResultValues.correct_answers
            : "",
          percentagescored: quizResultValues
            ? Math.ceil(quizResultValues.percentage)
            : "",
          quizQuestions: quizResultValues ? quizResultValues.questions : [],
        });
      }
    });
  };

  const QuizStatusFunction = () => {
    setResultLoaded(false);
  };

  return (
    <div className="quiz">
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {quizArray.length > 0 ? (
            <>
              <div className="section1-quiz">
                {!quizStarted && quizResultStatus === null ? (
                  <UserInitialQuiz datas={datas} column={column} />
                ) : quizDetailsAfterResults.totalquest > 0 ? (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#d8d8d8",
                          color: "#02215b",
                          fontSize: "16px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDataGrid
                        rowData={{
                          totalquest: quizDetailsAfterResults.totalquest,
                          correctquest: quizDetailsAfterResults.correctquest,
                          percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                        }}
                        rowQuestionsData={{
                          quizQuestions: quizDetailsAfterResults.quizQuestions,
                        }}
                        rowTypes={[
                          "Number Questions",
                          "Correct Answers",
                          "Percentage",
                        ]}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {quizResultStatus !== null ? ( //change the === to !== for it work as per requirement
                <></>
              ) : (
                <>
                  {quizStarted === true ? (
                    <>
                      <UserQuizQuestions
                        quizdata={quizdata}
                        courseId={courseId}
                        unitId={unitId}
                        setQuizStarted={setQuizStarted}
                      />
                    </>
                  ) : (
                    <>
                      {quizResultStatus === null ? (
                        <>
                          <div className="button-start-quiz">
                            <RButton
                              buttonName="Start Quiz"
                              className={"button-start-quiz-sub"}
                              style={{ textTransform: "none" }}
                              onClick={() => setQuizStarted(true)}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : quizDetailsAfterResults ? (
            <Box
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#d8d8d8",
                  color: "#02215b",
                  fontSize: "16px",
                  fontWeight: "bold",
                },
              }}
            >
              <div className="section1-quiz">
                <p>
                  <b>{quizdata[0].topic}</b>
                </p>
              </div>
              <CustomDataGrid
                rowData={{
                  totalquest: quizDetailsAfterResults.totalquest,
                  correctquest: quizDetailsAfterResults.correctquest,
                  percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                }}
                rowQuestionsData={{
                  quizQuestions: quizDetailsAfterResults.quizQuestions,
                }}
                rowTypes={["Number Questions", "Correct Answers", "Percentage"]}
              />
            </Box>
          ) : (
            <>
              <p style={{ backgroundColor: "white" }}>No Quiz Found.</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserQuiz;
