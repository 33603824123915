import { API } from "../Networking/API";

export const getMandatoryTraningByIdHelper = async (
  traningId,
  setMandatoryTraining,
  setLoader,
  setSlideData,
  setHeaderTabPanel,
  setDuration,
  setOverallSlideData,
  setIfSlidesCompleted,
  // setMandatetrainingData,
  setMaterialData
) => {
  setLoader(true);
  let receivedTrainingDetails = [];
  let TrackId = window.localStorage.getItem("userUnitTrackId");
  await API.getMandatoryTraninigById(traningId, TrackId)
    .then((data) => {
      if (data.response.api_status === 200) {
        // const newData = data.response.data;
        let receivedTraningDetails = data.response.data;
        setMaterialData(receivedTraningDetails);
        // setMandatetrainingData(receivedTraningDetails);
        const datas = receivedTraningDetails.trainingSlides.filter((data) => {
          const localStorageKey = `SlideId_${data.id}_ModuleId_${receivedTraningDetails.id}`;
          const localStorageValue = localStorage.getItem(localStorageKey);
          
          return !localStorageValue || localStorageValue === null; // Include if local storage value is falsy or undefined
        });
        setSlideData(datas);
        if (datas?.length !== 0) {
          setIfSlidesCompleted(false);
        }

        setOverallSlideData(
          receivedTraningDetails.trainingSlides.map((data) => {
            const localStorageKey = `SlideId_${data.id}_ModuleId_${receivedTraningDetails.id}`;
            const localStorageValue = localStorage.getItem(localStorageKey);
           
            const isSlideOver = localStorageValue ? true : false;
            return { ...data, isSlideOver };
          })
        );
        const getFinalQuizValidation = (finalQuiz) => {
          if (finalQuiz === null || !finalQuiz) return false;
          else if (finalQuiz[0] === null) return false;
          else return true;
        };
        const Data = [receivedTraningDetails].map((FilteredData) => {
          return {
            mainHeading: FilteredData.topic,
            mainId: FilteredData.id,
            totalDuration: FilteredData.duration,
            finalQuizDetails: FilteredData.finalQuiz,
            isFinalQuiz: getFinalQuizValidation(FilteredData.finalQuiz),
            // FilteredData.finalQuiz && FilteredData?.finalQuiz[0] !== null
            //   ? true
            //   : false,
            traningModules: FilteredData?.training_material.map(
              (TrainingData) => {
                return {
                  ModuleId: TrainingData.material_pk,
                  moduleHeading: TrainingData.material_name,
                  totalTime: TrainingData.duration,
                  trainingVideoLink: TrainingData.video_link,
                  traningModuleCompleted: window.localStorage.getItem(
                    `${TrainingData.material_pk}_${FilteredData.id}`
                  )
                    ? true
                    : TrainingData.isCompleted,
                  // traningModuleCompleted: false,
                  quiz: {
                    isCompleted:
                      TrainingData.trainigMaterialQuiz?.userQuizz !== null
                        ? true
                        : false,
                    // isCompleted: false,
                    quizId: TrainingData.trainigMaterialQuiz?.quizz_pk,
                  },
                };
              }
            ),
          };
        });
        setMandatoryTraining(Data);
        setLoader(false);
      }
    })
    .catch((error) => {
    });
  return receivedTrainingDetails;
};
// export const CreateMandateTrainingHelper = (
//   formik,
//   setIsDisabled,
//   setNotify,
//   setIsCreate,
//   isCreate,
//   setDisabled,
//   navigate,
//   setloaderForSaveLaterUnit,
//   setloaderForPublishUnit
// ) => {
//   const mandatoryTrainingCreation = {
//     topic: formik.values.trainingName,
//     attempts: formik.values.attempts,
//     active_date: formik.values.activeDate,
//     description: formik.values.aboutSession,
//     quiz_mandatory: true,
//   };
//   if (isCreate === false) {
//     const mandateId = window.localStorage.getItem("mandateId");
//     if (!mandateId) {
//       setloaderForSaveLaterUnit(true);
//       API.CreateMandateTraining(mandatoryTrainingCreation)
//         .then(({ response }) => {
//           if (response.api_status === 200) {
//             setloaderForSaveLaterUnit(false);
//             setDisabled(false);
//             setIsDisabled(true);
//             setNotify({
//               isOpen: true,
//               message: response.data[0].message,
//               type: "success",
//             });
//             let UnitId = response.data[0].unit.id;
//             window.localStorage.setItem("UnitId", UnitId);
//           } else {
//             setloaderForSaveLaterUnit(false);
//             setNotify({
//               isOpen: true,
//               message: response.error[0].error_msg,
//               type: "error",
//             });
//           }
//         })
//         .catch(({ error }) => {
//           setNotify({
//             isOpen: true,
//             message: error,
//             type: "error",
//           });
//         });
//     }
//   }
// };

export const CreateMandateTrainingHelper = (
  formik,
  setIsDisabled,
  setNotify,
  setIsCreate,
  isCreate,
  setDisabled,
  navigate,
  setloaderForSaveLaterUnit,
  setloaderForPublishUnit,
  setTabVisible,
  selectedTag
) => {
  if (selectedTag) {
    let TagData = selectedTag.map((TagData) => {
      return TagData.id;
    });
    formik.values.Tag = TagData;
  }
  const mandatoryTrainingCreation = {
    topic: formik.values.trainingName
      ? formik.values.trainingName
      : formik.values.unitName,
    attempts: formik.values.attempts,
    active_date: formik.values.activeDate,
    description: formik.values.session,
    difficulty_level_id: formik.values.Difficulty,
    technology: formik.values.Technology,
    tag_names: formik.values.Tag,
    pass_percentage: formik.values.passPercentage,
    unit_type: 1,
    // quiz_mandatory: true,
  };
  if (isCreate === false) {
    const mandateId = window.localStorage.getItem("mandateId");
    if (!mandateId) {
      setloaderForSaveLaterUnit(true);
      API.CreateMandateTraining(mandatoryTrainingCreation)
        .then(({ response }) => {
          if (response.api_status === 200) {
            setloaderForSaveLaterUnit(false);
            setDisabled(false);
            setIsDisabled(true);
            setNotify({
              isOpen: true,
              message: "Successfully Created",
              type: "success",
            });

            let mandateId = response.data[0].unit.id;
            localStorage.setItem("mandateId", mandateId);
            if (setTabVisible) {
              setTabVisible(true);
            }
          } else {
            setloaderForSaveLaterUnit(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        })
        .catch(({ error }) => {
          setNotify({
            isOpen: true,
            message: error,
            type: "error",
          });
        });
    } else {
      setloaderForSaveLaterUnit(true);
      API.EditMandatoryTraining(mandatoryTrainingCreation, mandateId)
        .then(({ response }) => {
          if (response.api_status === 200) {
            setloaderForSaveLaterUnit(false);
            setDisabled(false);
            setIsDisabled(true);
            setNotify({
              isOpen: true,
              message: "Successfully Updated",
              type: "success",
            });
          } else {
            setloaderForSaveLaterUnit(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        })
        .catch(({ error }) => {
          setNotify({
            isOpen: true,
            message: error,
            type: "error",
          });
        });
    }
  } else {
    setIsCreate(false);
    const mandateId = window.localStorage.getItem("mandateId");
    if (mandateId) {
      setloaderForPublishUnit(true);
      API.finalAdminCreationPublish(mandateId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishUnit(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishUnit(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  }
};

export const getMandateQuizDataHelper = (
  MandatoryId,
  ModuleId,
  setQuizData,
  setQuizLoader,
  setOptionsOpted,
  setNotify,
  setCompletedQuizQuestion
) => {
  const TrackId = window.localStorage.getItem("userUnitTrackId");
  let filtredQuizData = [];
  API.getModuleQuizData(ModuleId, MandatoryId, TrackId)
    .then(({ response }) => {
      if (response.api_status === 200) {
        filtredQuizData = response.data.map((mappedData) => {
          return {
            user_quiz_pk: mappedData.schedule_details.user_qz_id,
            quizPk: mappedData.schedule_details.quizz_pk,
            QuizQuestions: mappedData.questions
              // .filter((filtredData) => filtredData.userOption === null)
              .map((quizQuestions) => {
                return {
                  question_pk: quizQuestions.question_pk,
                  question: quizQuestions.question,
                  options: quizQuestions.options,
                };
              }),
          };
        });
        const transformendData = response.data[0].questions
          .filter((filtredOptions) => filtredOptions.userOption !== null)
          // .filter((userOptions)=>userOptions.)
          .map((filtredmappedOptions) => {
            return {
              questionPk: filtredmappedOptions.question_pk,
              value: filtredmappedOptions.userOption,
            };
          });
        setOptionsOpted(transformendData);
        setCompletedQuizQuestion(transformendData.length);
        setQuizData(filtredQuizData);
        setQuizLoader(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "warning",
        });
      }
    })
    .catch(({ error }) => {
      setNotify({
        isOpen: true,
        message: "Something went wrong",
        type: "warning",
      });
    });
  return filtredQuizData;
};

export const ValidateMandeQuizAns = (
  submittedQuizData,
  setShowAns,
  setAnsValue,
  setIsNextButtonDisabled
) => {
  const { MaterialId, topicId, userPk, userOption, questionId, unit_type } =
    submittedQuizData;
  API.ValidateMandateQuizAns(
    MaterialId,
    parseInt(topicId),
    userPk,
    userOption,
    questionId,
    unit_type
  ).then(({ response }) => {
    if (response.api_status === 200) {
      setShowAns(true);
      setAnsValue(response.data[0].message);
      setIsNextButtonDisabled(true);
    } else {
      setShowAns(true);
      setAnsValue(response.error[0].error_msg);
      setIsNextButtonDisabled(true);
    }
  });
};

export const SubmitMandateQuiz = async (
  submittedData,
  QuizData,
  setMandatoryTraining,
  setIsQuizEnabled
) => {
  const { MaterialId, topicId, userPk, userTrack, quizPk } = submittedData;
  let Validated = false;

  try {
    const { response } = await API.SubmitMandateQuiz(
      MaterialId,
      parseInt(topicId),
      userPk,
      userTrack,
      quizPk
    );
    if (response.api_status === 200) {
      Validated = true;

      return Promise.resolve(Validated);
    } else if (response.api_status === 400) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const SubmitTrainingModule = async (submittedData) => {
  let Validated = false;

  try {
    const {
      current_training_materials_id,
      unit_id,
      user_unit_track_id,
      completed_training_materials_id,
    } = submittedData;
    const { response } = await API.SubmitTrainingModule(
      current_training_materials_id,
      unit_id,
      user_unit_track_id,
      completed_training_materials_id
    );
    if (response.api_status === 200) {
      Validated = true;
      return Promise.resolve(Validated);
    } else if (response.api_status === 400) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const DeleteMandatoryTrainingHelper = async (
  setNotify,
  navigate,
  mandateId
) => {
  if (mandateId) {
    await API.AdminDeleteUnit(mandateId)
      .then((response) => {
        if (response.result.api_status === 200) {
          window.localStorage.removeItem("mandateId");
          setNotify({
            isOpen: true,
            message: response.result.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
          return true;
        } else {
          setNotify({
            isOpen: true,
            message: response.result.error[0].error_msg,
            type: "error",
          });
          return false;
        }
      })
      .catch(({ error }) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please Try Again",
          type: "error",
        });
        return false;
      });
  }
};
// export const SubmitMandateQuiz = async (
//   submittedData,
//   QuizData,
//   setMandatoryTraining,
//   setIsQuizEnabled
// ) => {
//   const { MaterialId, topicId, userPk, userTrack, quizPk } = submittedData;
//   const Validated = [];
//   // return false;

//   await API.SubmitMandateQuiz(MaterialId, topicId, userPk, userTrack, quizPk)
//     .then(({ response }) => {
//       if (response.api_status === 200) {
//         setMandatoryTraining((prevState) => {
//           const updatedState = prevState.map((item) => {
//             const updatedModules = item.traningModules.map((module) => {
//               if (module.ModuleId === QuizData.ModuleId) {
//                 module.quiz.isCompleted = true;
//               }
//               return module;
//             });

//             return { ...item, traningModules: updatedModules };
//           });

//           return updatedState;
//         });
//         Validated = true;
//         setIsQuizEnabled(false);
//       } else {
//         return Validated;
//       }
//     })
//     .catch(({ error }) => {
//       return Validated;
//     });
// };

export const GetSlideImage = async (slideId, setLoader, setNotify) => {
  try {
    const { response } = await API.getImageSlide(slideId);
    if (response.data.api_status === 200) {
      let Validated = true;
      const data = [response.data.data].map((data) => {
        return {
          MainHeader: data.slide_name,
          slide_id: data.id,
          traningSlides: data.trainingSlideElements.map((datas) => {
            return {
              id: datas.id,
              image: datas.element_source,
              description: datas.description,
            };
          }),
        };
      });
      setLoader(false);

      return Promise.resolve(data);
    } else if (response.api_status === 400) {
      setLoader(false);
      return Promise.resolve([]);
    } else {
      setLoader(false);
      return Promise.resolve([]);
    }
  } catch (error) {
    return Promise.reject([]);
  }
};
