import React from "react";

function TrainerPreRequisite(props) {
  const { link } = props;

  let contentType = link?.content_type;
  let moduleUrl = link?.module_url;
  let Link = link?.link;
  let renderMaterial;

  if (contentType === "application/pdf") {
    renderMaterial = (
      <iframe
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "scroll",
          paddingRight: "2%",
          cursor: "default",
        }}
        color="white"
        src={moduleUrl}
        title="Material"
      />
    );
  } else if (contentType !== "application/pdf" && contentType === null) {
    const urlGoo = `https://docs.google.com/`;
    const ytLink = "https://www.youtube.com/";
    if (Link?.startsWith(urlGoo)) {
      renderMaterial = (
        <iframe
          style={{
            height: "60vh",
            width: "100%",
            backgroundColor: "white",
            content: "inherit",
            overflowY: "scroll",
            paddingRight: "2%",
            cursor: "default",
          }}
          color="white"
          src={Link}
          title="Material"
        />
      );
    } else if (Link?.startsWith(ytLink)) {
      renderMaterial = (
        <iframe
          className="iframe-tag"
          style={{
            height: "60vh",
            width: "100%",
            backgroundColor: "white",
            content: "inherit",
            overflowY: "auto",
            overflowX: "auto",
            cursor: "default",
            frameBorder: "1",
          }}
          contentEditable={false}
          color="white"
          src={
            Link
              ? Link.replace(
                  "https://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              : ""
          }
          sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
          type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      );
    }
  } else if (contentType !== "application/pdf" && contentType !== null) {
    const microLinkEmb = `https://view.officeapps.live.com/op/embed.aspx?src=${moduleUrl}`;
    renderMaterial = (
      <iframe
        style={{
          height: "60vh",
          width: "100%",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "scroll",
          paddingRight: "2%",
          cursor: "default",
        }}
        color="white"
        src={microLinkEmb}
        title="Material"
      />
    );
  }

  return (
    <div style={{ height: "62vh", overflow: "auto" }}>{renderMaterial}</div>
  );
}

export default TrainerPreRequisite;
