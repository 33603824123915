import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckboxList from "./user-prerequisite-list";
import PdfContentExtractor from "./user-training-materialview";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Networking/API";

export const UserPrerequisite = (props) => {
  const { preRequisite, unitId, courseId, getUnitDetails } = props;
  let navigate = useNavigate();
  const [selected, setSelected] = React.useState(0);
  const selectedObject = preRequisite.find((item, index) => index === selected);
  const link =
    "https://absyz-academy.s3.ap-south-1.amazonaws.com/Admin/pre-requisite/Demo%202/1680542341225/Charter%20query%20managment%20%281%29.pdf";
  const newArray = [
    { id: 1, name: "Introduction to Angular", status: 1 },
    { id: 2, name: "Application Programming", status: 0 },
    { id: 3, name: "Programming with JS", status: 0 },
  ];

  const postTrainingMaterialTrack = () => {
    API.TrainingMaterialTrack(unitId, courseId).then(({ response }) => {
      if (response.api_status === 200) {
        
      }
    });
  };

  const idCourse = localStorage.getItem("courseId");
  const idUnit = localStorage.getItem("UnitId");

  const handleCancelButton = () => {
    if (idCourse) {
      navigate("/user/courses");
    } else if (idUnit) {
      navigate("/user/courses/units");
    } else {
      navigate("/user/");
    }
  };

  const checkBoxData = preRequisite.map((item, index) => {
    return {
      id: index,
      name: item.module_name,
      duration: item.duration,
      isCompleted: item.isCompleted,
      isCurrent: item.isCurrent,
      pk: item.pre_req_pk,
    };
  });
  const links = preRequisite.find((item, index) => index === selected);
  const next = async () => {
    if (selected > preRequisite.length - 1) {
      const lastIndex = preRequisite.findIndex(
        (item, index) => index === preRequisite.length - 1
      );
      setSelected(lastIndex);
    } else {
      setSelected(selected + 1);
    }
    postTrainingMaterialTrack();
  };
  return (
    <Box sx={{ height: "70vh" }}>
      <Grid
        container
        spacing={1}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item xs={3}>
          <CheckboxList
            list={checkBoxData}
            selected={selected}
            setSelected={setSelected}
            unitId={unitId}
            courseId={courseId}
            preRequisite={preRequisite}
            getUnitDetails={getUnitDetails}
          />
        </Grid>
        <div style={{ borderLeft: "1px solid #F0F0F0" }}></div>
        <Grid item xs={8}>
          <PdfContentExtractor link={links} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
          item
          xs={9}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              borderRadius: "4px",
              height: "35px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCancelButton}
          />
          <Box sx={{ display: "flex", float: "right", marginRight: "10%" }}>
            {selected !== 0 ? (
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Back"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "white",
                  height: "35px",
                  backgroundColor: "#02215B",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={() => {
                  setSelected(selected - 1);
                }}
              />
            ) : null}
            {preRequisite?.length > 1 ? (
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Next"
                disabled={selected == preRequisite.length - 1 ? true : false}
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  height: "35px",
                  letterSpacing: "normal",
                  textAalign: "center",
                  backgroundColor:
                    selected == preRequisite.length - 1 ? "grey" : "#FB2485",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={() => {
                  next();
                }}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
