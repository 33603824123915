import { React, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ReusableButton from "../../../components/Custom/button/button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./hackathon-upload.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Cookies from "js-cookie";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Notification from "../../../components/Custom/Notification";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import QuizIcon from "@mui/icons-material/Quiz";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AddTaskPopup from "./addtask";
import { API } from "../../../Networking/API";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const HackathonUpload = (props) => {
  const [tableData, setTableData] = useState([]);
  const [done, setDone] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [questions, setQuestion] = useState("");
  const [showValues, setShowValues] = useState(false);
  const [fileMerge, setFileMerge] = useState("");
  const [showPre, setShowPre] = useState(false);
  const [totalquestionscountforpage, setTotalQuestionsCountForPage] =
    useState("");

  //for add resouce//
  const [editId, setEditId] = useState();
  const [openEdit, setOpenEdit] = useState(false);

  //for add training material
  const [addTrainId, setAddTrainId] = useState();
  const [openAddTrain, setOpenAddTrain] = useState(false);

  //for add assignment//
  const [addAssignId, setAddAssignId] = useState();
  const [openAddAssign, setOpenAddAssign] = useState(false);

  //for add quiz//
  const [addQuizId, setAddQuizId] = useState();
  const [openAddQuiz, setOpenAddQuiz] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [batches, setBatches] = useState([]);

  const jwtToken = Cookies.get("jwt_token");

  useEffect(() => {
    getIdValue(batches);
    getBatches();
  }, [batches]);
  

  let pageNumber = 0;

  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        const apiData2 = response.data.data[0].bacthes[0].batch_pk;
        setBatches(apiData2);
      } else {
        setBatches([]);
      }
    });
  };

  const getIdValue = async (batches) => {
    API.getAdminHackathons(batches).then(async ({ response }) => {
      if (response.data.api_status === 200) {
        const apiData = response.data.data[0].hackathons;
        const updatedData = await DataTableHandler.AdminHackathons(apiData);
        setTableData(updatedData);
        setDone(true);
      } else {
        const error_code = response.data.data[0].error_code;

        const error_code_length = response.data.data[0].hackathons.length;
        if (error_code === 404 && error_code_length === 0) {
          setDone(true);
        } else {
          setNotify({
            isOpen: true,
            message: "There is an error" + `${error_code}`,
            type: "error",
          });
        }
      }
    });
  };

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    getIdValue(pageNumber);
  };

  const handleClickOpen = (value) => {
    setId(value.row.id);
    setQuestion(value.row.question);
    setOpen(true);
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };

  const handleEdit = (id) => {
    setOpenEdit(true);
    setEditId(id);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setShowPre(true);
  };

  const handleAddTrainingMaterial = (id) => {
    setOpenAddTrain(true);
    setAddTrainId(id);
  };

  const handleAddTrainingMaterialClose = () => {
    setOpenAddTrain(false);
  };

  //for handling the on click og add assignment//
  const handleAddAssignment = (id) => {
    setOpenAddAssign(true);
    setAddAssignId(id);
  };

  const handleAddAssignmentClose = () => {
    setOpenAddAssign(false);
  };

  //for handling the on click of add quiz//

  const handleAddQuiz = (id) => {
    setOpenAddQuiz(true);
    setAddQuizId(id);
  };

  const handleAddQuizClose = () => {
    setOpenAddQuiz(false);
  };

  const handleCloseByDelete = (paramsId) => {
    API.deleteAdminHackathon(paramsId).then(({ result }) => {
      const resultInJson = result;
      if (resultInJson.api_status === 200) {
        getIdValue();
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Hackathon Deleted Successfully",
          type: "success",
        });
      } else {
        getIdValue();
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Hackathon Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 20,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "topic",
      headerName: "Hackathon Name",
      // flex: 1,
      width: 310,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "trainingDate",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fromTime",
      headerName: "From",
      // flex: 1,
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "toTime",
      headerName: "To",
      // flex: 1,
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "TrainerEmail",
      headerName: "Trainer Email",
      // flex: 1,
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {/* <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <Tooltip title="Add Task">
            <IconButton aria-label="edit" size="small">
              <AddCircleOutlineIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: '14px !important',
                  //fontSize: '14px !important',
                  color: 'blue !important',
                }}
                onClick={() => handleAddQuizClose(params.id)}
              />
            </IconButton>
            </Tooltip>
          </span> */}

          {/* <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <Tooltip title="Add Training Material">
            <IconButton aria-label="edit" size="small">
              <DriveFolderUploadIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: '14px !important',
                  //fontSize: '14px !important',
                  color: 'blue !important',
                }}
                onClick={() => handleAddTrainingMaterial(params.id)}
              />
            </IconButton>
            </Tooltip>
          </span>
          <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <Tooltip title="Add Assignment">
            <IconButton aria-label="edit" size="small">
              <AssignmentIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: '14px !important',
                  //fontSize: '14px !important',
                  color: 'blue !important',
                }}
                onClick={() => handleAddAssignment(params.id)}
              />
            </IconButton>
            </Tooltip>
          </span> */}
          <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <Tooltip title="Add Task">
              <IconButton aria-label="edit" size="small">
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    //fontSize: '14px !important',
                    color: "blue !important",
                  }}
                  onClick={() => handleAddQuiz(params.id)}
                />
              </IconButton>
            </Tooltip>
          </span>

          <span>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" size="small">
                {" "}
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    //fontSize: '14px !important',
                  }}
                  onClick={() => handleClickOpen(params)}
                />
              </IconButton>
            </Tooltip>
            <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseByCancel}
              BackdropProps={{
                style: {
                  background: "#00000022",
                  boxShadow: "0px 0px 0px 0px !important",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                    {/* "{questions}" */}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    // marginRight:"30px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                  onClick={() => handleCloseByDelete(id)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  //   const handleClickByFilter = () => {
  //     if (filterClick === false) {
  //       setFilterClick(true);
  //       setFilterButton("Close");
  //     } else {
  //       setFilterClick(false);
  //       getIdValue();
  //       // setMakeTrue({digitalsQstn:true,
  //       //   salesforcesQstn:true,
  //       //   generalsQstn:true});
  //       setFilterButton("Filter");
  //     }
  //   };

  const handleUploadClick = async (event) => {
    API.postAdminHackathonUpload(fileMerge).then(({ response }) => {
      if (response.api_status === 200) {
        setShowValues(false);
        setNotify({
          isOpen: true,
          message: "File Uploaded successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "File Not Uploaded",
          type: "error",
        });
      }
    });
  };

  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
    setShowValues(true);
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box component="main" className="overallQUDiv" sx={{ flexGrow: 1 }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <h2 className="questionsPageTitle">Hackathons</h2>
              </Grid>
            </Grid>
          </div>

          <Container
            component="main"
            maxWidth="xl"
            sx={{ Padding: "0px !important" }}
          >
            <Card
              variant="outlined"
              sx={{ marginTop: "2%", Width: "100%", borderRadius: 0 }}
            >
              <Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    padding: "18px 30px 18px 30px",
                  }}
                >
                  <span>
                    Please Upload the Hackathon file in Spreadsheet(csv)* Format
                  </span>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        class="upload-box"
                        accept={SheetJSFT}
                        style={{
                          marginTop: "18px",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {showValues ? (
                        <>
                          <Button
                            type="button"
                            className="UpldBttn"
                            variant="contained"
                            startIcon={<FileUploadOutlinedIcon />}
                            style={{
                              marginTop: "18px",
                              backgroundColor: "#fb2485",
                              color: "#fff",
                              fontFamily: "Poppins",
                              fontSize: "0.8em",
                              fontWeight: 200,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              padding: "12px 28px 12px 28px",
                              borderRadius: "6px",
                            }}
                            onClick={handleUploadClick}
                          >
                            Upload Excel
                          </Button>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Card>
          </Container>

          <Container component="main" maxWidth="xl">
            <Card
              variant="outlined"
              sx={{
                marginTop: "2%",
                Width: "100%",
                borderRadius: 0,
                marginBottom: 12,
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: "16px 26px 16px 26px" }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#080825",
                      fontFamily: "Poppins",
                      fontSize: "1.3em",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                    }}
                  >
                    Recently Shared Hackathons
                  </span>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#f1f1f1",
                    color: "#02215b",
                    fontSize: "16px",
                  },
                }}
              >
                {tableData.length > 0 ? (
                  <>
                    <DataTable
                      rows={tableData}
                      columns={columns}
                      rowHeight={40}
                      autoHeight
                      pageSize={10}
                      // hideFooter={true}
                      // hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                      className="assigntable"
                    />
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "black",
                        justifyContent: "center",
                        margin: "20px 0px 0px 0px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel="next>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={6}
                        marginPagesDisplayed={2}
                        pageCount={totalquestionscountforpage / 10}
                        previousLabel="<previous"
                        pageClassName="pageClassName"
                        pageLinkClassName="pageLink"
                        previousClassName="previousClass"
                        previousLinkClassName="previousLinkClass"
                        nextClassName="nextClass"
                        nextLinkClassName="nextLinkClass"
                        breakLabel="...."
                        breakClassName="breakClass"
                        breakLinkClassName="breakLinkClass"
                        containerClassName="pagination"
                        activeClassName="activeClass"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Box
                      style={{
                        color: "black",
                        height: "20vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      No Data found in Hackathons. please upload Hackathons
                    </Box>
                  </>
                )}
                {/* <DataTable
                  rows={tableData}
                  columns={columns}
                  rowHeight={40}
                  autoHeight
                  pageSize={10}
                  // hideFooter={true}
                  // hideFooterPagination={true}
                  hideFooterSelectedRowCount={true}
                  className="assigntable"
                />
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "black",
                    justifyContent: "center",
                    margin: "20px 0px 0px 0px",
                  }}
                >
                  <ReactPaginate
                    nextLabel="next>"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={6}
                    marginPagesDisplayed={2}
                    pageCount={totalquestionscountforpage / 10}
                    previousLabel="<previous"
                    pageClassName="pageClassName"
                    pageLinkClassName="pageLink"
                    previousClassName="previousClass"
                    previousLinkClassName="previousLinkClass"
                    nextClassName="nextClass"
                    nextLinkClassName="nextLinkClass"
                    breakLabel="...."
                    breakClassName="breakClass"
                    breakLinkClassName="breakLinkClass"
                    containerClassName="pagination"
                    activeClassName="activeClass"
                    renderOnZeroPageCount={null}
                  />
                </div> */}
              </Box>
            </Card>
          </Container>

          {/* <AddResourcesPopup
            open={openEdit}
            id={editId}
            handleClose={handleEditClose}
          />
          <AddTrainingMaterialPopup
          open={openAddTrain}
          id={addTrainId}
          handleClose={handleAddTrainingMaterialClose}
          />
          <AddAssignmentsPopup
          open={openAddAssign}
          id={addAssignId}
          handleClose={handleAddAssignmentClose}
          /> */}

          <AddTaskPopup
            open={openAddQuiz}
            id={addQuizId}
            handleClose={handleAddQuizClose}
          />

          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </>
  );
};
export default HackathonUpload;
