import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles, Paper } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "./trainer-session.css";
import ReactLoading from "react-loading";
import { API } from "../../../Networking/API";

// const getDepartment = [
//   { id: "1", title: "All" },
//   { id: "2", title: "Upcoming" },
//   { id: "3", title: "Today" },
//   { id: "4", title: "Completed" },
// ];

const getDepartment = [
  { id: "1", title: "All" },
  { id: "2", title: "upcoming" },
  { id: "3", title: "today" },
  { id: "4", title: "completed" },
];

const styles = makeStyles((theme) => ({
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "100%",
      height: "40px",
    },
  },
}));

const TrainerSession = () => {
  const classes = styles();
  const [done, setDone] = useState(undefined);
  const [sessionData, setSessionData] = useState();
  const [filter, setFilter] = useState("");
  const [search, setsearch] = useState("");
  const [sessionCount, setSessionCount] = useState();
  const [apistatus, setApiStatus] = useState();
  const [OrgRole, setOrgRole] = useState();
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearch = (e) => {
    setsearch(e.target.value);
  };
  useEffect(() => {
    getSessionsData();
  }, [filter]);

  useEffect(() => {
    const getRole = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };
    const orgiRole = getRole("batch_id");
    // setOrgRole(orgiRole);

    if (orgiRole !== null) {
      const parsedEntryType = orgiRole;

      setOrgRole(parsedEntryType);
    }
    getSessionsData();
  }, [OrgRole]);

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      getSessionsData();
    }
  };

  const handelOnClicksearch = () => {
    getSessionsData();
  };

  const getSessionsData = async () => {
    let value;
    if (filter === "All") {
      value = "";
    } else {
      value = filter;
    }
    API.getSessionDetailsTrainer(0, value, search, OrgRole).then(
      ({ response }) => {
        setApiStatus(response.api_status);
        if (response.api_status === 200) {
          setSessionData(response.data[0].completed);
          setSessionCount(response.data[0].total_count);
          setDone(true);
        } else {
          setSessionCount(response.error[0].total_count);
          setDone(true);
        }
      }
    );
  };

  return (
    <>
      <h1 className="trainerHeading">Session Details</h1>
      <Box className="box2-trainer">
        <Grid container>
          <Grid item xs={2.5}>
            <Custom.Dropdown
              name="sessions"
              label="All Sessions"
              value={filter}
              onChange={handleInputChange}
              options={getDepartment}
              size="small"
              style={{
                height: 40,
                alignItems: "center",
                display: "flex",
                fontFamily: "Poppins",
              }}
              maxHeight="27.5vh"
            ></Custom.Dropdown>
          </Grid>
          <Grid item xs={0.25}></Grid>
          <Grid item xs={7.45}>
            <Custom.Input
              name="productName"
              type="text"
              fullWidth={true}
              //   className={classes.inputStyle}
              // label={'Product Name'}
              placeholder="Search by name"
              size="small"
              className={classes.inputStyle}
              onChange={handleSearch}
              onKeyPress={handleKeypress}
              //   error={errors.productName}
              // helperText={FieldErrors.forProductName}
            />
          </Grid>

          <Grid item xs={1}>
            <ReusableButton
              onClick={handelOnClicksearch}
              type="search"
              size="large"
              variant="contained"
              buttonName="Search"
              style={{
                backgroundColor: "#02215b",
                borderRadius: 6,
                width: "117px",
                height: "38px",
                flexGrow: 0,
                marginLeft: "30px",
                padding: " 11px 27.5px 11px 27.5px",
              }}
              className="buttonStyle"
            >
              Search
            </ReusableButton>
          </Grid>
        </Grid>
      </Box>

      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {sessionCount > 0 ? (
            <>
              <Grid container sx={{ marginBottom: "10%" }}>
                {apistatus !== 404 ? (
                  <>
                    {sessionData.map((item) => (
                      <>
                        <Grid item xs={6} md={4} key={item.schedule_pk}>
                          <Link
                            to="/user/bootcamp/trainer/home/session-details"
                            style={{ textDecoration: "none", color: "none" }}
                            state={{ data: item.schedule_pk }}
                          >
                            <Box className={"cardBox2"}>
                              <Grid
                                container
                                sx={{
                                  verticalAlign: "center",
                                  // backgroundImage: ,
                                  padding: "0px 0px",
                                  display: "flex",
                                  justifyContent: "end",
                                  alignContent: "center",
                                  height: "96px",
                                  width: "100%",
                                  borderTopLeftRadius: "12px",
                                }}
                                className={
                                  item.schedule_type === 0
                                    ? "background-image1"
                                    : "trainer-background-image3"
                                }
                              >
                                <Grid item xs={3} md={4}></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  md={4}
                                  // justifyContent="flex-start"
                                  style={{
                                    margin: " 15px 0 0 122.8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  className={
                                    item.day === "Today"
                                      ? "trainertoday"
                                      : item.day === "Assessed"
                                      ? "trainercompleted"
                                      : "trainerupcoming"
                                  }
                                >
                                  <div>
                                    <p className="trainerspanText1">
                                      {item.day}
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                sx={{ paddingLeft: "24px", marginTop: "0px" }}
                              >
                                <Grid item xs={6} md={7}>
                                  <h1 className="heading-trainer-session">
                                    {item.topic}
                                  </h1>
                                </Grid>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <p className="timeText2">
                                    {item.training_date}
                                  </p>
                                </Box>
                              </Grid>
                            </Box>
                          </Link>
                        </Grid>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Paper className="error-msg-paper">
                        <span className="noSession-msg">No Sessions !</span>
                      </Paper>
                    </Box>
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Box
                sx={{
                  // marginLeft: '30px',
                  // marginRight: '30px',
                  marginTop: "0px",
                }}
              >
                <Box
                  style={{
                    height: "20vh",
                    width: "95%",
                    marginLeft: "30px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "30px",
                  }}
                >
                  {/* <Box
                      sx={{
                        height: '20vh',
                        width: '95%',
                        marginLeft: '30px',
                        borderRadius: '12px',
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    > */}
                  <h3 style={{ color: "#02215b", marginTop: "10px" }}>
                    {" "}
                    No Sessions Assigned !!!
                  </h3>
                  {/* </Box> */}{" "}
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TrainerSession;
