import React, { useRef, useMemo, useState, useCallback } from "react";
// import "../MandatoryTraningLayout/mandatoryTraningModule.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../userConnect/connectLogin.css";
import ReactLoading from "react-loading";
import { Box } from "@mui/system";
import { getConnectUserProfile } from "../../../helper/userHelper";
import axios from "axios";
import { API } from "../../../Networking/API";
import { LoginPageNavigation } from "../../../Navigation/navigation";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ConnectLogin = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationHash = window.location.hash; // Get the location hash from the window object

  const [unAuthorizedUser, setUnAuthorizedUser] = useState(false);
  const hashParams = new URLSearchParams(
    locationHash ? locationHash.substr(1) : ""
  ); // Create a URLSearchParams object from the hash string

  const accessToken = hashParams.get("access_token");
  const refreshToken = hashParams.get("refresh_token");
  const instanceUrl = hashParams.get("instance_url");

  const idParam = hashParams.get("id");

  const id = idParam?.substring(idParam.lastIndexOf("/") + 1);

  // Access individual query parameters
  const onSubmitSuccess = async (
    jwtToken,
    userRole,
    userName,
    entryType,
    originalRole
  ) => {
    Cookies.set("jwt_token", jwtToken, {
      expires: 10,
    });

    Cookies.set("orginalRole", originalRole, {
      expires: 10,
    });

    Cookies.set("user_role", userRole, {
      expires: 10,
    });

    Cookies.set("user_name", userName, {
      expires: 10,
    });

    Cookies.set("entryType", entryType, {
      expires: 10,
    });

    // document.cookie = "entryType=" + JSON.stringify(entryType);
  };

  const AuthenticateConnectLoginUser = useCallback(async () => {
    if (accessToken && instanceUrl) {
      setUnAuthorizedUser(false);

      // getConnectUserProfile(accessToken,instanceUrl,id);
      try {
        const params = {
          q: `SELECT FIELDS(ALL) FROM User WHERE id='${id}'`,
        };
        const SFLoginUrl = process.env.REACT_APP_CONNECT_SF_LOGIN_URL;

        const response = axios.get(`${SFLoginUrl}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
          mode: "cors",
        });

        // Handle the response data
        const responseRecord = (await response)?.data?.records;

        if (responseRecord) {
          API.ConnectLogin(
            responseRecord[0].Email,
            responseRecord[0].Name,
            responseRecord[0].LastName,
            responseRecord[0].FirstName,
            responseRecord[0].Phone,
          ).then(async ({ response }) => {
            const data = response;
            if (data.api_status === 200) {
              // const jwtToken = data.data[0].jwttoken;
              // let userRole;
              const jwtToken = data.data[0].jwttoken;
              let userRole;
              if (data.data[0].entry_type === 1) {
                userRole = "User";
              } else {
                userRole = data.data[0].user_roles[0].role_name;
              }
              const userName = data.data[0].user_name;
              let isAdmin = data.data[0].is_admin;
              let originalRole =
                isAdmin === true
                  ? "Admin"
                  : data.data[0]?.user_roles[0]?.role_name; // changed the data.data[0]?.user_roles[0]?.role_name changed to ====  data.data[0]?.user_roles[0][0]?.role_name because destructuring has changed

              let entryType = data.data[0].entry_type;
              if (data.data[0].is_admin) {
                userRole = "Admin";
              } else {
                userRole = "User";
              }

              await onSubmitSuccess(
                jwtToken,
                userRole,
                userName,
                entryType,
                originalRole
              );
              await LoginPageNavigation(userRole, navigate);

              // if (data.data[0].entry_type === 1) {
              //   userRole = "User";
              // } else {
              //   userRole = data.data[0].user_roles[0].role_name;
              // }
              // const userName = data.data[0].user_name;
              // // await onSubmitSuccess(jwtToken, userRole, userName);
              // await LoginPageNavigation(userRole, navigate);

              // if (isChecked === true && email !== "") {
              //   localStorage.loginusername = email;
              //   localStorage.loginpassword = password;
              //   localStorage.logincheckbox = isChecked;
              // }
            } else {
              setUnAuthorizedUser(true);
              // setNotify({
              //   isOpen: true,
              //   type: "error",
              //   message: data.error[0].error_msg,
              // });
            }
          });
        }
        // navigate("/userLogin");
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    } else {
      // setUnAuthorizedUser(true);
    }
  }, [accessToken, refreshToken, instanceUrl]);

  useEffect(() => {
    AuthenticateConnectLoginUser();
  }, [accessToken, refreshToken, instanceUrl]);

  return (
    <div className="OverallDiv">
      <Box className="LoaderContent">
        {!unAuthorizedUser ? (
          <>
            <span className="AuthenticationLoader">
              Authenticating User Please Wait
            </span>
            <ReactLoading
              //   type={"spinningBubbles"}
              color={"#fb2485"}
              height={60}
              width={60}
            />
          </>
        ) : (
          <span className="AuthenticationLoader">UnAuthorized User</span>
        )}
      </Box>
    </div>
  );
};

export default ConnectLogin;
