import React, { useState, useCallback, useEffect } from "react";
import TableRowsMandate from "./mandatoryDraggableRows.jsx";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import arrayMove from "../../../handler/draggableHandler";
import arrayMove from "../../../handler/draggableHandler.js";
// import { makeStyles } from '@material-ui/core/styles';
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
// import TableRow from '@material-ui/core/TableRow';

const MyTableWrapper = styled.div`
  .SNo {
    width: 9% !important;
  }
  .Name {
    width: 18% !important;
  }
  .VideoLink {
    width: 20% !important;
  }
  .Material {
    width: 16% !important;
  }
  .Actions {
    width: 15% !important;
  }
  .MuiTableCell-root {
    padding-right: 36px;
  }
  .Actions {
    text-align: right !important;
  }
  .MuiTableCell-root {
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});
const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const SortableItem = SortableElement((props) => (
  <TableRowsMandate {...props} />
));

const DataTableDragMandate = ({
  draggableData,
  pageIndex,
  pageLimit,
  setDraggableData,
  handleDeleteInpre,
  handleEditModal,
  handleViewClick,
  link,
  dataTableHeader,
  IsActivity,
  disabled,
  OpenTheEditBox,
}) => {
  let pageNo = pageIndex + 1;
  let startIndex = (pageNo - 1) * pageLimit;
  let endIndex = pageNo * pageLimit - 1;
  // const [items, setItems] = useState(draggableData);
  if (pageIndex > 0) {
    pageIndex++;
  }
  // useEffect(()=>{
  //   setDraggableData(items);
  // },[items]);

  const handleUpArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
      });
      let oldIndex = indexValue;
      let newIndex = indexValue - 1;
      if (oldIndex !== 0) {
        setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
      }
    }
  };

  const handleDownArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
      });
      let oldIndex = indexValue;
      let newIndex = indexValue + 1;

      setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    }
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);
  const classes = useStyles();

  return (
    <MyTableWrapper>
      <Table className={classes.table}>
        <TableHead style={{ background: "#f1f1f1" }}>
          <TableRow className="table-row">
            {dataTableHeader.map((data) => {
              return (
                <>
                  <TableCell
                    style={{
                      textAlign: "center !important",
                      color: "02215B",
                      fontSize: "16px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                    className={`${data.replace(" ", "").replace(".", "")}`}
                  >
                    {data}
                  </TableCell>
                </>
              );
            })}
          </TableRow>
        </TableHead>

        {/* <table className="table table-dark fixed_header">
        <thead>
          <tr className="TableHeader">
            {dataTableHeader.map((data, index) => {
              return (
                <th className={`${data.replace(" ", "").replace(".", "")}`}>
                  {data}
                </th>
              );
            })} */}
        {/* <th className="fileNameHeader">Name</th>
            <th>Video Link</th>
            <th classNmae="MaterialHeader">Material</th>
            <th>Actions</th> */}
        {/* </tr>
        </thead> */}
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {draggableData.map((value, index) => {
            if (index >= startIndex && index <= endIndex) {
              return (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  sno={index + 1}
                  first={value.first}
                  second={value.second}
                  third={value.third}
                  fourth={value.fourth}
                  five={value.five}
                  sixth={value.sixth}
                  id={value.id}
                  handleDeleteInpre={handleDeleteInpre}
                  handleEditModal={handleEditModal}
                  // selectedData={value}
                  handleViewClick={handleViewClick}
                  handleUpArrow={handleUpArrow}
                  handleDownArrow={handleDownArrow}
                  link={link}
                  disabled={disabled}
                  isDisabled={disabled}
                  IsActivity={IsActivity}
                  OpenTheEditBox={OpenTheEditBox}
                  //   id={value.id}
                />
              );
            }
          })}
        </SortableCont>
      </Table>
    </MyTableWrapper>
  );
};

export default DataTableDragMandate;
