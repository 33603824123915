import { React, useState, useEffect, useRef } from "react";
import "./reports.css";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Custom } from "../../../components/Custom/Custom";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { API } from "../../../Networking/API";
import Notification from "../../../components/Custom/Notification";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { CSVLink } from "react-csv";
import { MenuProps, useStyles } from "./utils";
import moment from "moment";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Select from "@mui/material/Select";
import View from "./view";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const getBootcamp = [
  { id: "1", batch_name: "BootCamp - 1" },
  { id: "2", batch_name: "BootCamp - 2" },
];

const getHackathons = [
  { id: "1", batch_name: "Hackathon - 1" },
  { id: "2", batch_name: "Hackathon - 2" },
];

const getScheduler = [
  { id: "1", batch_name: "Schedules" },
  { id: "2", batch_name: "Hackathons" },
];
const getSession = [
  { id: "1", batch_name: "Session - 1" },
  { id: "2", batch_name: "Session - 2" },
  { id: "3", batch_name: "Session - 3" },
  { id: "4", batch_name: "Session - 4" },
];

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.topic}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.topic}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export const Reports = () => {
  const theme = useTheme();
  const csvLink = useRef();
  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      width: 30,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "bootcamp",
      headerName: "Bootcamp",
      flex: 1,
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "scheduler",
      headerName: "Scheduler",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "session",
      headerName: "Session",
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "assesment",
      headerName: "Assesment",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <IconButton aria-label="edit" size="small">
              <DownloadIcon
                color="primary"
                fontSize="medium"
                style={{
                  marginRight: "5px",
                }}
                onClick={() => handleClickDownload(params)}
              />
            </IconButton>
          </span>

          <span className="delete-button" style={{ marginLeft: "5px" }}>
            <IconButton aria-label="delete" size="small">
              {" "}
              <VisibilityIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  //fontSize: '14px !important',
                }}
                onClick={() => handleClickOpenDelModal(params)}
              />
            </IconButton>

            <Dialog
              //   open={openModal}
              className="dialogBox"
              // TransitionComponent={Transition}
              keepMounted
              //   onClose={handleCloseByCancelModal}
              aria-describedby="alert-dialog-slide-description"
              BackdropProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
              >
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete the user?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  //   onClick={handleCloseByCancelModal}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    marginRight: "10px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                ></ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];
  const classes = useStyles();
  const [bootcamps, setBootcamps] = useState("");
  const [schedules, setSchedules] = useState("");
  const [sessions, setSessions] = useState([]);
  const [assesmentOption, setAssesmentOption] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [sessionFilter, setSessionFilter] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchID, setBatchID] = useState();
  const [schedulerID, setSchedulerID] = useState();
  const [filter, setFilter] = useState("");
  const [filterData, setFilterData] = useState("");
  const [assesments, setAssesments] = useState([]);
  const [hackathons, setHackathons] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [getAssesments, setGetAssessment] = useState([]);
  const [showAssesment, setShowAssessment] = useState(false);
  const [hackathonOption, setHackathonOption] = useState([]);
  const [downloadScheduleData, setDownloadScheduleData] = useState([]);
  const [csvData, setCsvData] = useState(false);
  const [fromDateMin, setFromDateMin] = useState();
  const [sessionsData, setSessionData] = useState(true);
  const [optionSelected, setOptionSelected] = useState(null);
  const [toDateMax, setToDateMax] = useState();

  const [selected, setSelected] = useState([]);
  const [views, setViews] = useState(true);
  // const [addprops,setAddprops]= useState();
  const [batchIdv, setBatchIdv] = useState();
  const [schedulepkv, setSchedulepkv] = useState();
  const [scheduleIdv, setScheduleIdv] = useState();
  const [message2, setMessage2] = useState();
  const [makeTrue, setTrue] = useState(false);

  const csvReport = {
    data: filterData,
    // headers: columns,
    filename: `${filter}`,
  };

  useEffect(() => {
    getBatches();
  }, []);

  useEffect(() => {
    getSchedules();
  }, [batchID, schedulerID]);

  useEffect(() => {
    if (csvData && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  // let navigate = useNavigate();

  const handleClickOpenDelModal = (params) => {
    if (schedules === "Schedules") {
      API.getViewIconFun(params.row.schedulePk, params.row.batchId).then(
        ({ response }) => {
          if (response.data.api_status === 200) {
            setViews(false);
            setBatchIdv(params.row.batchId);
            setSchedulepkv(params.row.schedulePk);
          } else {
            const message = response.data.error[0].error_msg;
            setNotify({
              isOpen: true,
              message: `${message}`,
              type: "error",
            });
          }
        }
      );
    } else {
      API.getViewIconHackathonFun(
        params.row.scheduleId,
        params.row.batchId
      ).then(({ response }) => {
        if (response.data.api_status === 200) {
          setViews(false);
          setBatchIdv(params.row.batchId);
          setScheduleIdv(params.row.scheduleId);
        } else {
          const message = response.data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
        }
      });
    }
  };

  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        setBatches(response.data.data[0].bacthes);
        setFilter(response.data.data[0].bacthes[0].batch_name);
        setBatchID(response.data.data[0].bacthes[0].batch_pk);
      } else {
        setBatches([]);
      }
    });
  };

  const handleClickDownload = (params) => {
    const batchid = params.row.batchId;
    const schedulePk = params.row.schedulePk;
    if (selectedValue === "Hackathons") {
      API.getViewHackathonData(schedulePk, batchid).then(({ response }) => {
        if (response.data.api_status === 200) {
          setDownloadScheduleData(response.data.data);
          setCsvData(true);
        } else {
          const message = response.data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
        }
      });
    } else if (selectedValue === "Schedules") {
      API.getViewIconFun(schedulePk, batchid).then(({ response }) => {
        if (response.data.api_status === 200) {
          setDownloadScheduleData(response.data.data);
          setCsvData(true);
        } else {
          const message = response.data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
        }
      });
    }
  };

  const getSchedules = async () => {
    if (schedulerID !== undefined) {
      API.getSchedulers(batchID, schedulerID).then(({ response }) => {
        if (response.data.api_status === 200) {
          setShowDropdowns(true);

          setFromDate(response.data.data.from_date.split("T"));
          setFromDateMin(response.data.data.from_date.split("T"));
          setToDate(response.data.data.to_Date.split("T"));
          setToDateMax(response.data.data.to_Date.split("T"));
          setSessions(response.data.data.result);
        } else {
          const message = response.data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
          setShowDropdowns(false);
        }
      });
    }
  };

  const getHackathon = async () => {
    API.getHackathonOnBatchId(batchID).then(({ response }) => {
      if (response.data.api_status === 200) {
        const topic = response.data.data;
        const hackathonTopic = topic.map((items, index) => ({
          batch_name: items.topic,
          schedule_pk: items.schedule_pk,
        }));
        setHackathonOption(hackathonTopic);
      }
    });
  };

  const handleSearchButton = () => {
    if (selectedValue === "Hackathons") {
      API.getHackathonData(batchID, hackathons).then(({ response }) => {
        const apiData = response.data.data;
        const updatedData = apiData.map((items, index) => ({
          sno: index + 1,
          id: index + 1,
          bootcamp: items.batch_name,
          scheduler: selectedValue,
          session: items.topic,
          date: items.training_date.slice(0, 10),
          schedulePk: items.schedule_pk,
          batchId: items.batch_id,
          assesment: "Null",
          scheduleId: items.schedule_id,
        }));
        API.getViewIconHackathonFun(
          response.data.data[0].schedule_pk,
          batchID
        ).then(({ response }) => {
          if (response.data.api_status === 404) {
            setMessage2("No One Attempted the Hackathon");
          } else {
            setFilterData(updatedData);
          }
        });
      });
    } else if (selectedValue === "Schedules") {
      var assesmentFormat = {
        assignment: 0,
        quiz: 0,
      };

      assesments.forEach((element) => {
        if (element === "Assignment") {
          assesmentFormat.assignment = 1;
        }
        if (element === "Quiz") {
          assesmentFormat.quiz = 1;
        }
      });

      API.getSchedulesData(
        batchID,
        fromDate[0],
        toDate[0],
        sessionFilter,
        assesmentFormat
      ).then(({ response }) => {
        if (response.api_status === 200) {
          const apiData = response.data;
          const updatedData = apiData.map((items, index) => ({
            sno: index + 1,
            id: index + 1,
            bootcamp: items.batch_name,
            scheduler: selectedValue,
            session: items.topic,
            date: items.training_date.slice(0, 10),
            assesment:
              items.assignmentcount && items.assignmentcount === 1
                ? "Assigments"
                : items.quizcount && items.quizcount === 1
                ? "Quiz"
                : "Null",
            schedulePk: items.schedule_pk,
            batchId: items.batch_id,
          }));
          setFilterData(updatedData);
        } else {
          const message = response.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
        }
      });
    }
  };

  const handleFromDate = (event) => {
    const fromApiDate = moment(event.target.value).format();
    setFromDate(fromApiDate.split("T"));
    getScheduleWithDate(fromApiDate.split("T"), toDate);
  };

  const handleEndDate = (event) => {
    const toApiDate = moment(event.target.value).format();
    setToDate(toApiDate.split("T"));
    getScheduleWithDate(fromDate, toApiDate.split("T"));
  };

  const getAssignmentQuiz = (sessionFilter) => {
    if (sessionFilter !== undefined) {
      API.getAdminAssignmentQuiz(sessionFilter).then(({ response }) => {
        setGetAssessment([]);
        if (response.api_status === 200) {
          setShowAssessment(true);
          if (
            response.data.assignmentsCount === 1 &&
            response.data.quizzesCount === 1
          ) {
            setGetAssessment([
              {
                id: 1,
                batch_name: "Assignment",
              },
              {
                id: 2,
                batch_name: "Quiz",
              },
            ]);
          } else if (
            response.data.quizzesCount === 1 &&
            response.data.assignmentsCount === 0
          ) {
            setGetAssessment([
              {
                id: 2,
                batch_name: "Quiz",
              },
            ]);
          } else if (
            response.data.assignmentsCount === 1 &&
            response.data.quizzesCount === 0
          ) {
            setGetAssessment([
              {
                id: 1,
                batch_name: "Assignment",
              },
            ]);
          } else if (
            response.data.assignmentsCount === 0 &&
            response.data.quizzesCount === 0
          ) {
            setShowAssessment(false);
            setNotify({
              isOpen: true,
              message: "There are no assignments and quiz",
              type: "error",
            });
          }
        } else {
          setShowAssessment(false);
        }
      });
    } else if (sessionFilter.length === 0) {
      setShowAssessment(false);
    }
  };

  const getScheduleWithDate = async (fromDate, toDate) => {
    setShowAssessment(false);
    setSessionData(false);

    if (sessions !== undefined) {
      API.getScheduleWithDates(
        batchID,
        schedulerID,
        fromDate[0],
        toDate[0]
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setSessions(response.data);
          setSessionData(true);
          // setShowAssessment(true);
        } else {
          setSessions([]);
          const message = response.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: `${message}`,
            type: "error",
          });
        }
      });
    }
  };

  const handleBootcampDropDown = (e) => {
    var value = batches.filter(function (item) {
      return item.batch_name == e.target.value;
    });
    setBatchID(value[0].batch_pk);
    setFilter(e.target.value);
  };

  const handleSchedulerDropDown = (e) => {
    var schedulervalue = e.target.value;
    var value;
    setSchedules(schedulervalue);
    if (schedulervalue !== undefined) {
      if (schedulervalue === "Schedules") {
        value = 0;
        setSelectedValue("Schedules");
      } else if (schedulervalue === "Hackathons") {
        value = 1;
        setSelectedValue("Hackathons");
        getHackathon(batchID);
      }
      setSchedulerID(value);
    }
    setFilterData("");
    //   getSchedules(batchID,schedulerID);
  };

  const handleChange = (event) => {
    const selectedSession = event.target.value;
    setSessionFilter(event.target.value);
    if (selectedSession.length !== 0) {
      getAssignmentQuiz(selectedSession);
    } else if (selectedSession.length === 0) {
      setShowAssessment(false);
    }
  };

  const handleAssesmentsDropDown = (e) => {
    setAssesments(e.target.value);
  };

  const handleHackathonsDropDown = (e) => {
    setHackathons(e.target.value);
  };

  const handleResetReports = () => {
    // setGetScheduler([""])
    // setFromDateMin("")
    setFilterData("");
    setSchedules("");
    setSchedulerID();
    setGetAssessment([""]);
    setSessions([""]);
    // getScheduler = ""
    setToDate("");
    setFromDate("");
  };
  return (
    <>
      {views ? (
        <>
          <Box className="reports-wrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="report-header">Reports</span>
            </div>

            <div className="content-one">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                      variant="h6"
                    >
                      Select Bootcamp
                    </span>
                    <span style={{}}>
                      <Custom.BatchDropdown
                        name="Select Bootcamp"
                        value={filter}
                        onChange={handleBootcampDropDown}
                        options={batches}
                        placeholder="Select Source"
                        size="small"
                        style={{
                          height: 40,
                          fontFamily: "Poppins",
                        }}
                      ></Custom.BatchDropdown>
                    </span>
                  </div>
                </Grid>
                {filter.length > 0 ? (
                  <>
                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Select Scheduler
                        </span>
                        <span style={{}}>
                          <Custom.BatchDropdown
                            name="Select Scheduler"
                            value={schedules}
                            onChange={handleSchedulerDropDown}
                            options={getScheduler}
                            size="small"
                            placeholder="Select Source"
                            style={{
                              height: 40,
                              fontFamily: "Poppins",
                            }}
                          ></Custom.BatchDropdown>
                        </span>
                      </div>
                    </Grid>
                  </>
                ) : null}
                {showDropdowns === false ? (
                  <></>
                ) : schedules === "Schedules" && schedules.length !== "" ? (
                  <>
                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Select From Date
                        </span>
                        <span style={{}}>
                          <Custom.DatePicker
                            name="startdate"
                            className=""
                            value={fromDate}
                            onChange={handleFromDate}
                            size="small"
                            minDate={fromDateMin}
                            maxDate={toDate}
                          />
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Select To Date
                        </span>
                        <span style={{}}>
                          <Custom.DatePicker
                            name="todate"
                            className=""
                            value={toDate}
                            onChange={handleEndDate}
                            size="small"
                            minDate={fromDate}
                            maxDate={toDateMax}
                            // sx={{width :'245px !important'}}
                          />
                        </span>
                      </div>
                    </Grid>
                    {sessionsData && (
                      <Grid item xs={3}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                            variant="h6"
                          >
                            Select Session
                          </span>

                          <span style={{}}>
                            <FormControl className={classes.formControl}>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={sessionFilter}
                                onChange={handleChange}
                                style={{
                                  height: 40,
                                  fontFamily: "Poppins",
                                }}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                              >
                                {sessions.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name.schedule_pk}
                                    style={getStyles(
                                      name,
                                      sessionFilter,
                                      theme
                                    )}
                                  >
                                    {name.topic}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </span>
                        </div>
                      </Grid>
                    )}

                    {showAssesment === false ? (
                      <></>
                    ) : (
                      <>
                        <Grid item xs={3}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#000",
                              }}
                              variant="h6"
                            >
                              Select Assesment
                            </span>
                            <span style={{}}>
                              <FormControl className={classes.formControl}>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  value={assesments}
                                  style={{
                                    height: 40,
                                    fontFamily: "Poppins",
                                  }}
                                  onChange={handleAssesmentsDropDown}
                                  input={<OutlinedInput label="Name" />}
                                  MenuProps={MenuProps}
                                >
                                  {getAssesments.map((name) => (
                                    <MenuItem
                                      key={name}
                                      // value={name.id}
                                      value={name.batch_name}
                                      style={getStyles(
                                        name,
                                        sessionFilter,
                                        theme
                                      )}
                                    >
                                      {name.batch_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </span>
                          </div>
                        </Grid>
                      </>
                    )}
                  </>
                ) : schedules === "Hackathons" && schedules.length !== "" ? (
                  <>
                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Select Hackathon
                        </span>
                        <span style={{}}>
                          <Custom.BatchDropdownOne
                            name="Select Hackathon"
                            value={hackathons}
                            onChange={handleHackathonsDropDown}
                            options={hackathonOption}
                            placeholder="Select Hackathon"
                            size="small"
                            style={{
                              height: 40,
                              fontFamily: "Poppins",
                            }}
                          ></Custom.BatchDropdownOne>
                        </span>
                      </div>
                    </Grid>
                  </>
                ) : null}

                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Custom.Button
                      style={{
                        color: "#fff",
                        background: "#FB2485",
                        borderRadius: "6px",
                        padding: "6px 20px",
                      }}
                      text="Search"
                      size="small"
                      onClick={handleSearchButton}
                    ></Custom.Button>
                    <Custom.Button
                      style={{
                        color: "#02215B",
                        background: "#fff",
                        borderRadius: "6px",
                        border: "1px solid #02215B",
                        padding: "6px 20px",
                      }}
                      text="Reset"
                      size="small"
                      onClick={handleResetReports}
                    ></Custom.Button>
                  </div>
                </Grid>
              </Grid>
            </div>

            {filterData.length > 0 ? (
              <>
                <div className="content-two">
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <span className="search-result-text">Search Result</span>
                    <CSVLink
                      style={{
                        color: "#1976d2",
                        display: "flex",
                        alignItems: "center",
                      }}
                      {...csvReport}
                    >
                      <DownloadIcon
                        color="primary"
                        fontSize="medium"
                        style={{
                          marginRight: "5px",
                        }}
                      />
                      Download Report
                    </CSVLink>
                  </Box>

                  <Box style={{ marginBottom: "100px" }}>
                    <DataTable
                      rows={filterData}
                      columns={columns}
                      pageSize={10}
                      rowHeight={40}
                      rowsPerPageOptions={[10]}
                      autoHeight
                      width="100%"
                      className="batch-data-table"
                      hideFooterSelectedRowCount={true}
                    />
                  </Box>
                </div>
              </>
            ) : (
              <Box
                sx={{
                  height: "30vh",
                  width: "100%",
                  // marginLeft: "0px",
                  marginTop: "30px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4 style={{ color: "#02215b", marginTop: "0px" }}>
                  {makeTrue === true ? message2 : "No Data Available"}
                </h4>
              </Box>
            )}
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          {csvData ? (
            <CSVLink
              style={{
                color: "#1976d2",
                display: "flex",
                alignItems: "center",
              }}
              data={downloadScheduleData}
              filename={`${filter}_${selectedValue}`}
              ref={csvLink}
            />
          ) : undefined}
        </>
      ) : (
        <View
          setViews={setViews}
          scheduleIdv={scheduleIdv}
          schedulepkv={schedulepkv}
          batchIdv={batchIdv}
          selectedValue={selectedValue}
        />
      )}
    </>
  );
};
