import React, { useState, useEffect } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./trainee-session-calendar.css";
import { makeStyles } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { MyForm } from "../../../components/MyForm";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import moment from "moment";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

let date;
const d = new Date();
d.setHours(0, 0, 0, 0);
const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const styles = makeStyles((theme) => ({
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "100%",
      height: "44px",
    },
  },
}));
const initialValues = {
  sessionName: "",
};

const jwtToken = Cookies.get("jwt_token");
const TraineeCalender = () => {
  const currentMonth = new Date().getMonth() + 1;
  const [value, setValue] = React.useState(dayjs());
  const [errorMsg, setErrorMsg] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const classes = styles();
  const [sessionsData, setSessionData] = useState();
  const [CompletedData, setCompletedData] = useState();
  const [noSession, setNoSession] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [done, setDone] = useState(false);
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true);
  let d = new Date(new Date().setMonth(new Date().getMonth() + 3));
  let Befordate = new Date(new Date().setMonth(new Date().getMonth()));
  var BeforeUpdatedDate = Befordate.toISOString().slice(0, 10);
  var BeforeStartDate = "2022-09-01";
  let addedMonths = d.toISOString().slice(0, 10);
  var FinalEndMonth = addedMonths.slice(0, 7).concat("-01");
  let navigate = useNavigate();
  useEffect(() => {
    getData();
  }, [value]);
  useEffect(() => {
    getCompletedData("", 1);
  }, []);

  const handleSearch = () => {
    setDone(false);
    var searchWord = values.sessionName.toLowerCase().trim();
    if (searchWord.length !== 0) {
      setPageNumber(1);
      getCompletedData(searchWord, 1);
    } else {
      setPageNumber(1);
      getCompletedData(searchWord, 1);
    }
  };
  const getCompletedData = async (search, page) => {
    try {
      const CourseId = localStorage.getItem("courseId");
      API.postTrainerSessionCalender(page, search, CourseId).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setNoSession(false);
            setErrorMsg("");
            setErrorStatus(false);
            const dataArray = resultInJson.data[0].completed;
            setPageCount(Math.ceil(resultInJson.data[0].total_count / 5));
            const newData = [];
            dataArray.map((data) => {
              const completedSessionDetails = {
                day: data.day,
                diff: data.diff,
                duration: data.duration,
                from_time: data.from_time,
                schedule_pk: data.schedule_pk,
                schedule_type: data.schedule_type,
                time: data.time,
                to_time: data.to_time,
                topic: data.topic,
                topic_id: data.topic_id,
                trainer_id: data.trainer_id,
                training_date: data.training_date,
                user_name: data.user_name,
                user_email: data.user_email,
              };
              newData.push(completedSessionDetails);
            });

            setCompletedData(newData);
            setFilteredData(newData);
          } else {
            setErrorMsg(resultInJson.error[0].error_msg);
            setErrorStatus(true);
            setFilteredData([]);
            setPageCount(0);
          }
          setDone(true);
        }
      );
    } catch (e) {}
  };

  const getData = async () => {
    if (value.$d) {
      date = value.$d;
      date = moment(date).format("YYYY-MM-DD");
    } else {
      date = value;
    }
    try {
      let courseId = localStorage.getItem("courseId");
      API.PostTrainer_SessionTraineeData(date, courseId).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            setNoSession(false);
            const data = resultInJson.data[0].schedule;
            console.log(data, "data check");
            // const formattedSessionDetails = [
            //   {
            //     day: data?.day,
            //     diff: data.diff,
            //     duration: data.duration,
            //     from_time: data.from_time,
            //     schedule_pk: data.schedule_pk,
            //     schedule_type: data.schedule_type,
            //     time: data.time,
            //     to_time: data.to_time,
            //     topic: data.topic,
            //     topic_id: data.topic_id,
            //     trainer_id: data.trainer_id,
            //     training_date: data.training_date,
            //     user_name: data.user_name,
            //   },
            // ];
            // console.log(formattedSessionDetails, "formattedSessionDetails");
            setSessionData(data);
          } else {
            setNoSession(true);
          }
        }
      );
    } catch (e) {}
  };

  const handlePageClick = async (event) => {
    setDone(false);
    setPageNumber(event.selected + 1);
    getCompletedData(values.sessionName.trim(), event.selected + 1);
    // }
  };

  return (
    <>
      <h1 className="traineeHeading">Calender Schedule</h1>
      <Button
        buttonName="Back"
        className="traineebutton"
        startIcon={<ArrowBackIosIcon />}
        style={{ textTransform: "none", color: "#02215b" }}
        // onClick={() => navigate("/trainee/home/calendardetails")}
        onClick={() => navigate("/user/bootcamp/home/")}
      />
      <Box className="container">
        <Grid container spacing={4}>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "left",
              // marginRight: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span className="selectDate">Select Date</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  orientation="landscape"
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={value}
                  maxDate={FinalEndMonth}
                  minDate={BeforeStartDate}
                  onChange={(newValue) => {
                    date = newValue.$d;
                    date = moment(date).format("YYYY-MM-DD");
                    setValue(date, "1");
                    //   value.$d
                  }}
                />
              </LocalizationProvider>
            </div>
          </Grid>

          <Grid item xs={8}>
            <div>
              <span className="selectedDate">
                {moment(value).format("dddd, MMMM Do YY")}
              </span>

              <Box sx={{ display: "flex" }}>
                <div
                  className="horizontal_scroll"
                  style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                >
                  <Box style={{ display: "flex", flexWrap: "nowrap" }}>
                    {!noSession ? (
                      <>
                        {sessionsData !== undefined ? (
                          <>
                            {sessionsData.map((item, i) => (
                              <Box className={"cardBox"} key={item.schedule_pk}>
                                <Link
                                  state={{ data: item.schedule_pk }}
                                  to="/user/bootcamp/home/sessiondetails"
                                  style={{
                                    textDecoration: "none",
                                    color: "none",
                                  }}
                                >
                                  <Grid
                                    container
                                    sx={{
                                      verticalAlign: "center",
                                      padding: "1px 0px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "space-between",
                                      height: "96px",
                                      width: "100%",
                                      borderTopLeftRadius: "12px",
                                    }}
                                    className={
                                      item.schedule_type === 0
                                        ? "background-image1"
                                        : "trainee-background-image2"
                                    }
                                  >
                                    <Grid item xs={3} md={4}></Grid>

                                    <Grid
                                      item
                                      xs={3}
                                      md={4}
                                      style={{ margin: "0 0 0 101px" }}
                                      className={
                                        item.day === "Today"
                                          ? "spanHeaderText1"
                                          : item.day === "Completed"
                                          ? "spanHeaderText2"
                                          : "spanHeaderText3"
                                      }
                                    >
                                      <div>
                                        <p className="spanText1">{item.day}</p>
                                      </div>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    container
                                    sx={{
                                      paddingLeft: "24px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <Grid item xs={6} md={7}>
                                      <h1 className="heading2">{item.topic}</h1>
                                    </Grid>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p className="timeText1">
                                          <span className="span1">Time: </span>
                                          {item.time}
                                        </p>
                                        <p className="dur">
                                          <span className="span1">
                                            Duration:{" "}
                                          </span>
                                          {item.duration} Hours
                                        </p>
                                        <p className="dur">
                                          <span className="span1">
                                            Trainer:{" "}
                                          </span>
                                          {item.user_name}
                                        </p>
                                      </div>
                                    </Box>
                                  </Grid>
                                </Link>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <div className="noSession">
                            <span>There is no session</span>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="noSession">
                          <span>There is no session</span>
                        </div>
                      </>
                    )}
                  </Box>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>

      <h2 className="sub">Sessions History</h2>

      <Box className="box2-trainee">
        <Grid container>
          <Grid item xs={10.45}>
            <Custom.Input
              name="sessionName"
              type="text"
              placeholder="Search by name"
              size="small"
              className={classes.inputStyle}
              onChange={handleInputChange}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={1}>
            <ReusableButton
              type="search"
              size="large"
              variant="contained"
              buttonName="Search"
              onClick={handleSearch}
              style={{
                backgroundColor: "#02215b",
                borderRadius: 6,
                padding: "8px 36px",
                width: "117px",
                height: "38px",
                flexGrow: 0,
                margin: " 3px 0 3px 30px",
                padding: " 11px 27.5px 11px 27.5px",
                backgroundColor: " #02215b",
              }}
              className="buttonStyle"
            >
              Search
            </ReusableButton>
          </Grid>
        </Grid>
      </Box>

      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : filteredData.length !== 0 ? (
        <Grid container>
          {filteredData.map((item, i) => (
            <>
              <Grid item xs={6} md={4} key={item.schedule_pk}>
                <Link
                  to="/user/bootcamp/home/sessiondetails"
                  style={{ textDecoration: "none", color: "none" }}
                  state={{ data: item.schedule_pk }}
                >
                  <Box className={"cardBox2"}>
                    <Grid
                      container
                      sx={{
                        verticalAlign: "center",
                        // backgroundImage: ,
                        padding: "0px 0px",
                        display: "flex",

                        justifyContent: "end",
                        alignContent: "center",
                        height: "96px",
                        width: "100%",
                        borderTopLeftRadius: "12px",
                      }}
                      className={
                        item.schedule_type === 0
                          ? "background-image1"
                          : "trainee-background-image3"
                      }
                    >
                      <Grid item xs={3} md={4}></Grid>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        // justifyContent="flex-start"
                        style={{
                          margin: "15px 0 0 122.8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={"trainercompleted"}
                      >
                        <div>
                          <p className="trainerspanText1">Completed</p>
                        </div>
                      </Grid>
                    </Grid>

                    <Box sx={{ padding: "12px 20px" }}>
                      <Box
                        sx={{
                          // maxWidth: "300px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span className="heading3">{item.topic}</span>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "6px",
                        }}
                      >
                        <p className="timeText2">{item.training_date}</p>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            </>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="sub">No Sessions Available</h2>
        </Box>
      )}
      <Box
        sx={{
          marginBottom: "100px",
          marginRight: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >>"
            forcePage={pageNumber - 1}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            pageClassName="pageClassNameTrainer"
            pageLinkClassName="pageLinkTrainer"
            previousClassName="previousClassTrainer"
            previousLinkClassName="previousLinkClassTrainer"
            nextClassName="nextClassTrainer"
            nextLinkClassName="nextLinkClassTrainer"
            breakClassName="breakClassTrainer"
            breakLinkClassName="breakLinkClassTrainer"
            containerClassName="paginationTrainer"
            activeClassName="activeClassTrainer"
            disabledClassName="disabledClassName"
          />
        ) : null}
      </Box>
    </>
  );
};

export default TraineeCalender;




