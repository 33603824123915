import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { styled } from "@mui/material/styles";
import { Paper, Box } from "@material-ui/core";
import "./CustomCarousel.css";
import Grid from "@mui/material/Grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
//comment
export default function CustomCarousel(props) {
  const { items, path, autoPlay, height, kind } = props;
  const [OrgRole, setOrgRole] = useState();
  useEffect(() => {
    const getRole = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };
    const orgiRole = getRole("orginalRole");
    // console

    if (orgiRole !== null) {
      const parsedEntryType = orgiRole;
      setOrgRole(parsedEntryType);
    }
  }, [OrgRole]);
  return items !== undefined ? (
    <>
      {kind === "assessed" ? (
        <Carousel
          indicatorIconButtonProps={{
            style: {
              zIndex: 1,
              color: "#02215b", // 3
              textAlign: "right",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#fb2485", // 2
            },
          }}
          indicatorContainerProps={{
            style: {
              textAlign: "right", // 4
              marginLeft: "-80px",
            },
          }}
          navButtonsWrapperProps={{
            style: {
              marginRight: "28px",
            },
          }}
        >
          {items.map((item, i) => (
            <Box
              style={{
                height: "280px",
                margin: "30px 30px 182px 0px",
                padding: "0 0 11px ",
                borderRadius: "12px",
                backgroundColor: "#fff",
                // paddingBottom: "30px",
              }}
            >
              <Link
                to={path}
                state={{ data: item.id }}
                style={{ textDecoration: "none", color: "none" }}
              >
                <Grid
                  container
                  sx={{
                    verticalAlign: "center",
                    // backgroundImage: ,
                    padding: "11px 0 9px 33px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "space-between",
                    height: "54px",
                    width: "100%",
                    borderTopLeftRadius: "12px",
                  }}
                  className="background-image"
                >
                  <Grid item xs={4} md={8}>
                    <p className="date-text" style={{ width: "550px" }}>
                      {item.date}
                    </p>
                  </Grid>
                  <Grid item xs={3} md={4} className="span-header-container">
                    <div>
                      <p className="span-text">{item.day}</p>
                    </div>
                  </Grid>
                </Grid>
              </Link>
              <Link
                to={path}
                state={{ data: item.id }}
                style={{ textDecoration: "none", color: "none" }}
              >
                <Box
                  style={{
                    marginLeft: "33px",
                    marginBottom: "30px",
                    borderRadius: "12px",
                  }}
                >
                  <h1 className="topic">{item.topic}</h1>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-10px",
                      marginTop: "-10px",
                      borderRadius: "12px",
                    }}
                  >
                    <div>
                      <p className="time-text">
                        <span className="span">Time: </span>
                        {item.time} IST
                      </p>
                    </div>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "12px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                    />
                    <div>
                      <p className="timeText">
                        <span className="span">Duration: </span>
                        {item.duration} Hours
                      </p>
                    </div>
                  </Box>
                  {item.trainingmaterial.length > 0 && OrgRole !== "Mentor" ? (
                    <h1 className="heading-text">Training Materials</h1>
                  ) : null}
                  {OrgRole !== "Mentor" ? (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "-10px",
                        marginBottom: "30px",
                      }}
                    >
                      {item.trainingmaterial.map((eachMaterial) => (
                        <>
                          {eachMaterial.material_url !== undefined ? (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "5px",
                                marginBottom: "30px",
                              }}
                            >
                              <a
                                href={eachMaterial.material_url}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <AttachFileIcon
                                  sx={{
                                    color: "#0078d4",
                                    marginRight: "8px",
                                    fontSize: "20px",
                                  }}
                                />
                                <span className="downloadText">
                                  {eachMaterial.material_name}
                                </span>
                              </a>
                            </Box>
                          ) : (
                            <p
                              className="timeText"
                              style={{ color: "red", fontSize: "18px" }}
                            >
                              No Training Material Avaliable
                            </p>
                          )}
                        </>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              </Link>
            </Box>
          ))}
        </Carousel>
      ) : kind === "profile" ? (
        <Carousel
          indicatorIconButtonProps={{
            style: {
              zIndex: 1,
              marginTop: "-40px",
              position: "relative",
              color: "#02215b", // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#fb2485", // 2
            },
          }}
          navButtonsWrapperProps={{
            style: {
              marginLeft: "19px",
              marginRight: "42px",
            },
          }}
        >
          {items.map((item, i) => (
            <Grid
              container
              sx={{
                verticalAlign: "center",
                padding: "0px 0 9px 0px",
                display: "flex",
                flexDirection: "column",
                alignContent: "space-between",
                height: "240px",
                borderTopLeftRadius: "12px",
              }}
              className="outer-container"
            >
              <Grid item xs={4} md={4}>
                <Box className="card-containertrainer">
                  <div class="textplayer">Training Toppers</div>
                  {item.user_profile === null ||
                  item.user_profile === undefined ? (
                    <AccountCircleIcon
                      style={{
                        width: "66px",
                        height: "66px",
                        color: "#03215a",
                      }}
                      className={"AccountPIcon"}
                    />
                  ) : (
                    <Avatar
                      src={item.user_profile}
                      alt="image"
                      sx={{ width: 66, height: 66 }}
                      className="user-icon"
                    />
                  )}

                  <div class="textnames">{item.username}</div>

                  <div class="scored">Scored {item.percentage} %</div>
                </Box>
              </Grid>
            </Grid>
            // </Box>
          ))}
        </Carousel>
      ) : kind === "session" ? (
        <Carousel autoPlay={autoPlay}>
          {items.map((item, i) => (
            <Box
              style={{
                height: height,
                borderRadius: "12px",
                backgroundColor: "#fff",
                marginBottom: "0px",
              }}
              key={item.id}
            >
              <Link
                to={path}
                state={{ data: item.id }}
                style={{ textDecoration: "none", color: "none" }}
              >
                <Grid
                  container
                  sx={{
                    verticalAlign: "center",
                    padding: "1px 0px 24px 30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "space-between",
                    height: "86px",
                    width: "100%",
                    borderTopLeftRadius: "12px",
                  }}
                  className="background-image"
                >
                  <Grid item xs={4} md={5}>
                    <p className="dateText">{item.date}</p>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    md={4}
                    className={
                      item.day === "Today"
                        ? "spanHeaderText"
                        : "traineenext-class"
                    }
                  >
                    <div>
                      <p className="spanText">{item.day}</p>
                    </div>
                  </Grid>
                </Grid>
              </Link>
              <Grid container sx={{ paddingLeft: "24px", marginTop: "0px" }}>
                <Grid item xs={6} md={7}>
                  <Link
                    to={path}
                    state={{ data: item.id }}
                    style={{ textDecoration: "none", color: "none" }}
                  >
                    <>
                      <h1
                        className="heading"
                        style={{
                          width: "520px",
                          marginBottom: "-6px",
                        }}
                      >
                        {item.topic}
                      </h1>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "-10px",
                        }}
                      >
                        <div>
                          <p className="timeText">
                            <span className="span">Time: </span>
                            {item.time} IST
                          </p>
                        </div>
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "12px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        />
                        <div>
                          <p className="timeText">
                            <span className="span">Duration: </span>
                            {item.duartion} Hours
                          </p>
                        </div>
                      </Box>
                    </>
                  </Link>
                  {item.trainingMaterial ? (
                    <h1 className="bottom-heading">Training Materials</h1>
                  ) : null}

                  <Box sx={{ height: "8vh", overflowY: "scroll" }}>
                    <ul style={{ margin: "10px", listStyle: "none" }}>
                      {item.trainingMaterial
                        ? item.trainingMaterial.map((eachdata) => (
                            <li style={{ margin: "5px" }}>
                              <Link
                                // target="_blank"
                                // style={{
                                //   display: "flex",
                                //   alignItems: "center",
                                //   textDecoration: "none",
                                // }}
                                // rel="noreferrer"
                                to={path}
                                state={{ data: item.id }}
                                style={{
                                  textDecoration: "none",
                                  color: "none",
                                }}
                              >
                                <AttachFileIcon
                                  sx={{
                                    color: "#0078d4",
                                    marginRight: "8px",
                                    fontSize: " 20px",
                                  }}
                                />
                                <span className="downloadText">
                                  {eachdata.material_name}
                                </span>
                              </Link>
                            </li>
                          ))
                        : null}
                    </ul>
                  </Box>
                </Grid>
                <Grid item xs={4} md={1}>
                  <div className="border-style"></div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Link
                    to={path}
                    state={{ data: item.id }}
                    style={{ textDecoration: "none", color: "none" }}
                  >
                    <Box className="side-container">
                      <h1 className="sideText">Trainer Info</h1>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "28px",
                        }}
                      >
                        <AccountCircleIcon className="userIcon" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <h1 className="trainer-name">
                            {item.name !== undefined && item.name !== null
                              ? item.name
                              : item.email !== undefined && item.email !== null
                              ? item.name
                              : "External Trainer"}
                          </h1>
                          <p className="trainer-designation">
                            {item.designation ? item.designation : null}
                          </p>
                          <p className="trainer-experience">
                            {item.experience ? item.experience : null}
                            {item.experience ? "years of experience" : null}
                          </p>
                        </div>
                      </Box>
                      <Grid container>
                        {item.certificate !== null &&
                        item.certificate !== undefined ? (
                          <Grid xs={4} md={7}>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "-20px",
                              }}
                            >
                              <Box
                                style={{
                                  padding: "7px 8px 6px",
                                  borderRadius: "25px",
                                  backgroundColor: "#02215b",
                                  width: "40px",
                                  height: "40px",
                                }}
                              >
                                <span className="trainer-span-text">
                                  {item.certificate}X
                                </span>
                              </Box>
                              <p
                                className="certifcation-text"
                                style={{ width: "175px" }}
                              >
                                Certifications Achieved
                              </p>
                            </Box>
                          </Grid>
                        ) : (
                          <Grid xs={4} md={0.7}></Grid>
                        )}
                        <Grid xs={4} md={5}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-20px",
                            }}
                          >
                            {item.sessions !== null &&
                            item.sessions !== undefined ? (
                              <>
                                <Box
                                  style={{
                                    padding: "7px 14px 6px",
                                    borderRadius: "25px",
                                    backgroundColor: "#02215b",
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "-20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <span className="trainer-span-text">
                                    {item.sessions}
                                  </span>
                                </Box>
                                <p className="certifcation-text">
                                  Sessions Assigned
                                </p>
                              </>
                            ) : (
                              <>
                                <Grid xs={4} md={0.7}></Grid>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Carousel>
      ) : kind === "upcoming" ? (
        <Carousel
          indicatorIconButtonProps={{
            style: {
              zIndex: 1,
              color: "#02215b", // 3
              textAlign: "right",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#fb2485", // 2
            },
          }}
          indicatorContainerProps={{
            style: {
              textAlign: "right", // 4
              marginLeft: "-80px",
            },
          }}
          navButtonsWrapperProps={{
            style: {
              marginRight: "28px",
            },
          }}
        >
          {items.map((item, i) => (
            <Box
              style={{
                height: "240px",
                margin: "30px 30px 182px 0px",
                padding: "0 0 11px ",
                borderRadius: "12px",
                backgroundColor: "#fff",
                // paddingBottom: "30px",
              }}
              key={item.id}
            >
              <Link
                to={path}
                state={{ data: item.id }}
                style={{ textDecoration: "none", color: "none" }}
              >
                <Grid
                  container
                  sx={{
                    verticalAlign: "center",
                    // backgroundImage: ,
                    padding: "11px 0 9px 33px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "space-between",
                    height: "54px",
                    width: "100%",
                    borderTopLeftRadius: "12px",
                  }}
                  className="background-image"
                >
                  <Grid item xs={4} md={8}>
                    <p className="date-text" style={{ width: "550px" }}>
                      {item.date}
                    </p>
                  </Grid>
                  <Grid item xs={3} md={4} className="span-header-container">
                    <div>
                      <p className="span-text">{item.day}</p>
                    </div>
                  </Grid>
                </Grid>
              </Link>
              <Link
                to={path}
                state={{ data: item.id }}
                style={{ textDecoration: "none", color: "none" }}
              >
                <Box style={{ marginLeft: "33px", marginBottom: "30px" }}>
                  <h1 className="topic">{item.topic}</h1>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-10px",
                      marginTop: "-10px",
                    }}
                  >
                    <div>
                      <p className="time-text">
                        <span className="span">Time: </span>
                        {item.time} IST
                      </p>
                    </div>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "12px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                    />
                    <div>
                      <p className="timeText">
                        <span className="span">Duration: </span>
                        {item.duartion} Hours
                      </p>
                    </div>
                  </Box>
                  {item.trainingMaterial.length > 0 ? (
                    <h1 className="heading-text">Training Materials</h1>
                  ) : null}
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-10px",
                      marginBottom: "30px",
                    }}
                  >
                    {item.trainingMaterial.map((eachMaterial) => (
                      <>
                        {eachMaterial.material_url !== undefined ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                              marginBottom: "30px",
                            }}
                          >
                            <a
                              href={eachMaterial.material_url}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                marginBottom: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <AttachFileIcon
                                sx={{
                                  color: "#0078d4",
                                  marginRight: "8px",
                                  fontSize: "20px",
                                }}
                              />
                              <span className="downloadText">
                                {eachMaterial.material_name}
                              </span>
                            </a>
                          </Box>
                        ) : (
                          <p
                            className="timeText"
                            style={{ color: "red", fontSize: "18px" }}
                          >
                            No Training Material Avaliable
                          </p>
                        )}
                      </>
                    ))}
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Carousel>
      ) : null}
    </>
  ) : null;
}
