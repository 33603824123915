import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import LabTabs from "./training-session-tabs.jsx";
import "./trainee-session-details.css";
import axios from "axios";
import Cookies from "js-cookie";
// import API from "../../../api/index";
import { API } from "../../../Networking/API";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HackathonTabs from "./training-session-hackathon-tabs";

export default function TraineeSessionDetails(props) {
  const quizValuesafterresults = {
    totalquest: "",
    correctquest: "",
    percentagescored: "",
  };

  const quizValues = {
    count: "",
    noOFques: "",
  };

  const something = 1;
  // const Api1 = API.Api1;
  const jwtToken = Cookies.get("jwt_token");
  const [scheduledata, setScheduledata] = useState("");
  const [done, setDone] = useState(undefined);

  const [tabDone, setTabDone] = useState(undefined);
  const [assignmentData, setAssignmentData] = useState();
  // const [tasksData, setTasksData] = useState();
  const [trainerdata, setTrainerdata] = useState("");
  const [quizArray, setQuizArray] = useState("");

  const [prerequiste, setPrerequiste] = useState([]);
  const [scheduleType, setScheduleType] = useState();

  const [quizdata, setQuizdata] = useState("");
  const [quizResultStatus, setQuizResultStatus] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState();
  const [totalTasks, setTotalTasks] = useState(0);
  const [userHid, setUserHid] = useState("");
  const [startQuizButton, setStartQuizButton] = useState("");
  const [topicName, setTopicName] = useState("");
  const [quizDetails, setQuizDetails] = useState(quizValues);
  const [quizDetailsAfterResults, setQuizDetailsAfterResults] = useState(
    quizValuesafterresults
  );

  const [trainermaterial, SetTrainermaterial] = useState("");
  const [viewAfterButtonClick, setViewAfterButtonClick] = useState(false);

  const [resultLoaded, setResultLoaded] = useState(true);

  const [uploadStatus, setUploadStatus] = useState(1);
  const location = useLocation();
  const id = location.state.data.id || location.state.data;
  let navigate = useNavigate();
  useEffect(() => {
    // gettraineesessionDetails();
    getTraineeTabDetails();
  }, []);

  const getTraineeTabDetails = async () => {
    API.getTraineeSession_Details(id)
      .then(function (response) {
        if (response.response.data.api_status === 200) {
          setTabDone(true);
          setDone(true);

          const scheduleTraineeSession =
            response.response.data.data[0].schedule;
          const assignment = response.response.data.data[0].assignment;

          setTotalTasks(response.response.data.data[0].total_tasks);
          setUserHid(response.response.data.data[0].user_hid_pk);

          setScheduleType(
            response.response.data.data[0].schedule.schedule_type
          );
          setUploadStatus(response.response.data.data[0].status_assignment);

          setScheduledata(scheduleTraineeSession);
          setAssignmentData(assignment);
          setViewAfterButtonClick(false);
          const prequest = response.response.data.data[0].pre_requisite;

          // if (prequest.length > 0) {
          // const formattedPrerequest = prequest.map((eachData, index) => ({
          //   id: eachData.pre_req_pk,
          //   module_url: eachData.module_url,
          //   description: eachData.prereq_description,
          //   name: eachData.module_name,
          //   topic: eachData.topic,
          //   content_type: eachData.content_type,
          //   link: eachData.link,
          // }));
          setPrerequiste(response.response.data.data[0].pre_requisite);
          // }

          const Tmaterial = response.response.data.data[0].training_material;

          if (Tmaterial.length > 0) {
            const formattedTmaterial = Tmaterial.map((eachData, index) => ({
              id: eachData.material_pk,
              url: eachData.material_url,
              description: eachData.trgmat_description,
              name: eachData.material_name,
              content_type: eachData.content_type,
              link: eachData.link,
              video_link: eachData.video_link,
              media_type: eachData.media_type,
            }));

            SetTrainermaterial(formattedTmaterial);
          }
          const schedule = response.response.data.data[0].trainer_details[0];

          if (schedule !== undefined) {
            setTrainerdata(schedule);
          }

          const quizToWrite = response.response.data.data[0].quizz;

          setQuizArray(quizToWrite);

          const quizResult = response.response.data.data[0].userQuizz;
          if (response.response.data.data[0].quizz[0].userQuizz !== null) {
            setQuizResultStatus(1);
          } else {
            setQuizResultStatus(0);
          }

          const feedbackResult = response.response.data.data[0].feedback_status;
          setFeedbackStatus(feedbackResult);

          const quizResultValues =
            response.response.data.data[0].quizz[0].userQuizz;

          if (quizToWrite.length > 0) {
            const formattedQuizToWrite = quizToWrite.map((eachdata) => ({
              id: eachdata.quizz_pk,
              noOfQuest: eachdata.no_of_questions,
              duration: eachdata.countdown,
              fromDate: eachdata.from_date,
              toDate: eachdata.to_date,
              fromTime: eachdata.from_time,
              toTime: eachdata.to_time,
              quizNumber: eachdata.quizz_pk,
              questcount: eachdata.no_of_questions,
              topic: eachdata.topic,
              schid: eachdata.schedule_id,
              buttonstat: eachdata.button,
            }));
            setStartQuizButton(formattedQuizToWrite[0].buttonstat);
            setQuizdata(formattedQuizToWrite);
            setTopicName(formattedQuizToWrite.topic);
            setQuizDetails({
              ...quizDetails,
              count: quizToWrite[0].countdown,
              noOFques: quizToWrite[0].no_of_questions,
            });
          }
          setQuizDetailsAfterResults({
            ...quizDetailsAfterResults,
            totalquest: quizResultValues ? quizResultValues.total_questions : 0,
            correctquest: quizResultValues
              ? quizResultValues.correct_answers
              : "",
            percentagescored: quizResultValues
              ? Math.ceil(quizResultValues.percentage)
              : "",
            quizQuestions: quizResultValues ? quizResultValues.questions : [],
          });
          setResultLoaded(true);
        } else if (response.data.api_status === 403) {
          Cookies.remove("jwt_token");
          navigate("/");
        }
      })
      .catch(function (err) {});
  };
  return (
    <>
      {tabDone && done ? (
        <>
          <h1 className="training-session-trainee">Training Sessions</h1>
          <Button
            buttonName="Back"
            className="traineebutton"
            startIcon={<ArrowBackIosIcon />}
            style={{ textTransform: "none", color: "#02215b" }}
            // onClick={() => navigate("/trainee/home/calendardetails")}
            onClick={() => navigate("/user/bootcamp/home/")}
          />
          <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
            <Box>
              <Paper className="paper-box" style={{ borderRadius: "12px" }}>
                <Box
                  className={
                    scheduledata.schedule_type === 0
                      ? "firstbox-background"
                      : "firstbox-background2"
                  }
                >
                  <Box className="trainee-top-box1">
                    <div className="date">{scheduledata.training_date}</div>
                    <div
                      className={
                        scheduledata.day === "Today"
                          ? "today"
                          : scheduledata.day === "Completed"
                          ? "completed"
                          : "further"
                      }
                    >
                      {scheduledata.day}
                    </div>
                  </Box>
                </Box>
                <Box className="second-box">
                  <Box
                    className="top-box-second-box"
                    style={{ paddingBottom: "20px" }}
                  >
                    <span className="container1">{scheduledata.topic}</span>
                    <div>
                      <span className="time">Time :</span>
                      <span>&nbsp; {scheduledata.time}</span>
                      <br />
                      <span className="duration">Duration :</span>
                      <span>&nbsp; {scheduledata.duration} Hours</span>
                    </div>
                  </Box>
                  {scheduledata.topic_descp !== "" &&
                  scheduledata.topic_descp !== null ? (
                    <>
                      <Box className="inner-div">
                        {/* <div>
                          <p
                            style={{ marginTop: "3px" }}
                            className="course-heading"
                          >
                            <b>About Course</b>
                          </p>
                        </div> */}
                        <span className="course-text">
                          {scheduledata.topic_descp}
                        </span>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Paper>
            </Box>

            <Box>
              {scheduleType === 0 ? (
                <LabTabs
                  feedbackStatus={feedbackStatus}
                  scheduledata={scheduledata}
                  assignmentData={assignmentData}
                  // tasksData={tasksData}
                  trainerdata={trainerdata}
                  quizArray={quizArray}
                  prerequiste={prerequiste}
                  quizdata={quizdata}
                  quizResultStatus={quizResultStatus}
                  startQuizButton={startQuizButton}
                  topicName={topicName}
                  quizDetails={quizDetails}
                  done={tabDone}
                  quizDetailsAfterResults={quizDetailsAfterResults}
                  trainermaterial={trainermaterial}
                  getTraineeTabDetails={getTraineeTabDetails}
                  resultLoaded={resultLoaded}
                  setResultLoaded={setResultLoaded}
                  uploadStatus={uploadStatus}
                />
              ) : (
                <HackathonTabs
                  feedbackStatus={feedbackStatus}
                  scheduledata={scheduledata}
                  // tasksData={tasksData}
                  assignmentData={assignmentData}
                  trainerdata={trainerdata}
                  quizArray={quizArray}
                  prerequiste={prerequiste}
                  quizdata={quizdata}
                  quizResultStatus={quizResultStatus}
                  startQuizButton={startQuizButton}
                  topicName={topicName}
                  quizDetails={quizDetails}
                  done={tabDone}
                  quizDetailsAfterResults={quizDetailsAfterResults}
                  trainermaterial={trainermaterial}
                  getTraineeTabDetails={getTraineeTabDetails}
                  resultLoaded={resultLoaded}
                  setResultLoaded={setResultLoaded}
                  totalTasks={totalTasks}
                  userHid={userHid}

                  // loading={loading}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
    </>
  );
}
