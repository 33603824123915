export const TimeDuration = [
  {
    id: "15 min",
    value: 15,
    title: "15 min",
  },
  {
    id: "30 min",
    value: 30,
    title: "30 min",
  },
  {
    id: "40 min",
    value: 40,
    title: "40 min",
  },
  {
    id: "50 min",
    value: 50,
    title: "50 min",
  },
  {
    id: "60 min",
    value: 60,
    title: "1 hour",
  },
  {
    id: "90 min",
    value: 90,
    title: "1 hour : 30 minutes",
  },
];

export const ActiveSatus = [
  {
    id: true,
    value: true,
    title: "Active",
  },
  {
    id: false,
    value: false,
    title: "InActive",
  },
];

export const TechnologyConst = [
  // {
  //   id: "DIGITAL",
  //   value: "DIGITAL",
  //   title: "Digital",
  // },
  // {
  //   id: "SALESFORE",
  //   value: "SALESFORCE",
  //   title: "Salesforce",
  // },
  {
    id: "Salesforce",
    value: "Salesforce",
    title: "Salesforce",
  },
  {
    id: "Digital",
    value: "Digital",
    title: "Digital",
  },
  {
    id: "QA",
    value: "QA",
    title: "QA",
  },
  {
    id: "MuleSoft",
    value: "MuleSoft",
    title: "MuleSoft",
  },
  {
    id: "BA",
    value: "BA",
    title: "BA",
  },
  {
    id: "PM",
    value: "PM",
    title: "PM",
  },
  {
    id: "Industries",
    value: "Industries",
    title: "Industries",
  },
  {
    id: "Design",
    value: "Design",
    title: "Design",
  },
  {
    id: "Sales",
    value: "Sales",
    title: "Sales",
  },
  {
    id: "MuleSoft",
    value: "MuleSoft",
    title: "MuleSoft",
  },
  {
    id: "Marketing",
    value: "Marketing",
    title: "Marketing",
  },
  {
    id: "HR",
    value: "HR",
    title: "HR",
  },
  {
    id: "Admin",
    value: "Admin",
    title: "Admin",
  },
  {
    id: "Finance",
    value: "Finance",
    title: "Finance",
  },
  {
    id: "Data Security",
    value: "Data Security",
    title: "Data Security",
  },
];
