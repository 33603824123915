import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { ReusableInputfield } from "../../../components/Custom/inputfield/inputfield";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";

import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "react-router-dom";
import Notification from "../../../components/Custom/Notification";
import cp from "../../../assets/Images/cp.png";
import { useNavigate } from "react-router-dom";
import "./changePassword.css";
import { API } from "../../../Networking/API";

const useStyles = makeStyles({
  inputfieldLabel: {
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: 400,
    },
  },
});
const inputFieldData = {
  email: "",
  confirmPassword: "",
  password: "",
};

const inputErrorMsg = {
  forConfirmPassword: "",
  forPassword: "",
};

const inputFieldErrorStatus = {
  confirmPasswordStatus: false,
  passwordStatus: false,
};

export default function ChangePassword(props) {
  const [searchEmail] = useSearchParams();
  const [userEmail, setUserEmail] = useState(searchEmail.get("email"));

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);

  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();

  const handleSubmit = async () => {
    var passwordValidation =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    if (passwordValidation.test(inputFieldValues.password) === false) {
      setErrorStatus({ ...errorStatus, passwordStatus: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        forPassword: "Enter Valid Password ",
      });
    } else if (inputFieldValues.password !== inputFieldValues.confirmPassword) {
      setErrorStatus({ ...errorStatus, confirmPasswordStatus: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        forConfirmPassword: "Password is Not Matching with Confirm Password",
      });
    } else {
      API.traineeChangePassword(
        userEmail,
        inputFieldValues.password,
        inputFieldValues.confirmPassword
      ).then((response) => {
        const data = response;
        if (response.status === 200) {
          setNotify({
            isOpen: true,
            message: "Password Reset Successfully",
            type: "success",
          });
          setInputFieldValues({ confirmPassword: "", password: "" });
          navigate("/trainee/home");
        }
      });
    }
  };

  const handleTextChangeConfirmPassword = (event, value) => {
    setInputFieldValues({
      ...inputFieldValues,
      confirmPassword: event.target.value,
    });
  };
  const handleTextChangePassword = (event, value) => {
    setInputFieldValues({ ...inputFieldValues, password: event.target.value });
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: "95%",
          zIndex: 1,
          position: "inherit",
          width: "100%",
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "3%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "15px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={cp}
                      alt="AdminContentPic"
                      width="335"
                      height="410"
                      style={{ marginLeft: "25px", marginTop: "30px" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Academy
                    </span>

                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "14px", marginBottom: "7px" }}
                    >
                      Create New Password
                    </span>
                  </Box>

                  <Box
                    sx={{
                      "& .MuiTextField-root": { width: "38ch" },
                      marginTop: 2,
                    }}
                  >
                    <Stack direction="column" spacing={3}>
                      <ReusableInputfield
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={inputFieldValues.password}
                        onChange={(e) =>
                          handleTextChangePassword(e, "password")
                        }
                        id="password"
                        // id="outlined-basic"
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        className={classes.inputfieldLabel}
                        error={errorStatus.passwordStatus}
                        helperText={inputFieldErrors.forPassword}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        name="confirmPassword"
                        margin="normal"
                        required
                        type="password"
                        value={inputFieldValues.confirmPassword}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="Confirm Password"
                        onChange={(e) =>
                          handleTextChangeConfirmPassword(e, "confirmPassword")
                        }
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        className={classes.inputfieldLabel}
                        autoFocus
                        error={errorStatus.confirmPasswordStatus}
                        helperText={inputFieldErrors.forConfirmPassword}
                      />

                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <ReusableRememberMe
                            color="default"
                            size="small"
                            name="lsRememberMe"
                            checked={isChecked}
                            onChange={onChangeCheckbox}
                          />
                          <label
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="loginFieldText"
                          >
                            Remember Me
                          </label>
                        </Stack>
                      </Stack>
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                        marginRight: "60px",
                      }}
                    >
                      <ReusableButton
                        size="large"
                        variant="outlined"
                        className="cancelBttn"
                        buttonName="Cancel"
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAalign: "center",
                          color: "#000936",
                          border: "1px solid #000936",
                          padding: "7px 20px",
                          borderRadius: "4px",
                          marginRight: "18px",
                          // marginBottom:"25px",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          navigate("/home");
                        }}
                      />
                      <ReusableButton
                        size="medium"
                        variant="contained"
                        type="submit"
                        className="okBtn"
                        buttonName="Create Now"
                        style={{
                          backgroundColor: "#fe1c7f",
                          fontFamily: "Poppins",
                          fontSize: "0.8em",
                          fontWeight: 200,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#fff",
                          padding: "12px 35px 12px 35px",
                          borderRadius: "4px",
                        }}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
