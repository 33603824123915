import React from "react";
import "./App.css";
import Routers from "./Navigation/router";

function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}
export default App;
