import React, { useEffect } from "react";

const QuizTimer = (props) => {
  const { MinSecs, start, parentcallback, Id } = props;

  const { minutes = 0, seconds = 60 } = MinSecs;

  const [[mins, secs], setTime] = React.useState([+minutes, +seconds]);

  const tick = () => {
    if (mins === 0 && secs === 0) reset();
    else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };
  useEffect(() => {
    const timmer = { Minutes: mins, Seconds: secs };

    window.localStorage.setItem("minsecs" + Id, JSON.stringify(timmer));
  }, [[mins, secs]]);
  const reset = () => setTime([parseInt(0), parseInt(0)]);

  React.useEffect(() => {
    if (start) {
      const timerId = setInterval(() => {
        tick();
        parentcallback([mins, secs]);
      }, 1000);
      return () => clearInterval(timerId);
    }
  });

  return (
    <div>
      <p
        style={{
          fontFamily: "Helvetica",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "18px",
          marginLeft: "8px",
        }}
      >{`${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`}</p>
    </div>
  );
};

export default QuizTimer;
