import { React, useState, useEffect } from "react";
import "./trainer-sessions-details-assessed.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

export const TrainerSessionsDetailsHackathon = ({
  taskData,
  totalTasks,
  index,
}) => {
  const [assignmentResult, setAssignmentResult] = useState([]);
  useEffect(() => {
    if (taskData !== undefined) {
      setAssignmentResult(taskData.score);
    }
  });
  return (
    <>
      <Box style={{ display: "flex" }}>
        <span className="assignment-text">
          Assignment : {totalTasks[index].task_name}
        </span>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: "10px",
        }}
      >
        <span>
          <b>Start Time :</b> {totalTasks[index].task_start_time}
        </span>
        <span>
          <b>End Time :</b> {totalTasks[index].task_end_time}
        </span>
      </Box>

      <Box style={{ marginTop: "10px", display: "flex" }}>
        {totalTasks[index].as_description !== null ? (
          totalTasks[index].as_description.length !== 0 ? (
            <span className="required-text">
              <b style={{ marginRight: "10px" }}>Required :</b>
              {totalTasks[index].as_description}
            </span>
          ) : null
        ) : null}
      </Box>
      {taskData !== undefined ? (
        <>
          <Box
            style={{
              marginTop: "10px",
              display: "flex",
              backgroundColor: "#80808054",
              padding: "8px 20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <span className="table-data-headers-text">
                  Submission Status
                </span>
                <p className="table-values-text">
                  {taskData.astmt_status === 0
                    ? "In Progress"
                    : taskData.astmt_status === 1
                    ? "Submitted"
                    : "Assessed"}
                </p>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span className="table-data-headers-text">Time Remaining</span>
                <p className="table-values-text">{taskData.remain_time}</p>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span className="table-data-headers-text">Last Modified</span>
                <p className="table-values-text">{taskData.last_update}</p>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span className="table-data-headers-text">Score Achieved</span>
                <p className="table-values-text">
                  {taskData.score_achived !== "-"
                    ? ` ${taskData.score_achived} of ${taskData.total_score}`
                    : "NA"}
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              marginTop: "16px",
            }}
          >
            <span>Task Attachments</span>

            <div style={{ marginTop: "16px" }}>
              {taskData.attachments.map((items, index) => (
                <a
                  href={items.asn_link}
                  target="_blank"
                  className="attachment"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    startIcon={
                      <RemoveRedEyeOutlinedIcon style={{ fill: "#0078d4" }} />
                    }
                    style={{
                      marginRight: "20px",
                      textDecoration: "none",
                      textTransform: "initial",
                      color: "#000",
                      border: "solid 1px #c9c9c9",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                    }}
                  >
                    <p className="attachment-title">
                      {`Attachment file ${index + 1}`}
                    </p>
                  </Button>
                </a>
              ))}
            </div>
          </Box>
          {taskData.astmt_status === 2 ? (
            <Box style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    style={{
                      background: "#02215b",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    {" "}
                    <Grid item xs={6}>
                      <span style={{ paddingLeft: "25px", color: "#fff" }}>
                        Parameters
                      </span>
                    </Grid>
                    <Grid item xs={2}>
                      <span style={{ paddingLeft: "25px", color: "#fff" }}>
                        Marks
                      </span>
                    </Grid>
                    <Grid item xs={2}>
                      <span style={{ paddingRight: "25px", color: "#fff" }}>
                        Scores Achieved
                      </span>
                    </Grid>
                  </Box>

                  {assignmentResult.map((item, index) => {
                    return (
                      <>
                        <ListItem
                          key={index}
                          className="trainer-list"
                          secondaryAction={
                            <div className={"scoreBox"}>
                              {item.score_achieved}
                            </div>
                          }
                          disablePadding
                        >
                          {/* <Grid item xs={6}> */}
                          <Box
                            role={undefined}
                            // onClick={handleToggle(value)}
                            dense
                            style={{ maxWidth: "80%" }}
                          >
                            <ListItemText
                              primary={item.hackparam_name}
                              className="parameter-list"
                            />
                          </Box>
                          {/* </Grid> */}
                          {/* <Grid item xs={2}>
                            <Box
                              role={undefined}
                              // onClick={handleToggle(value)}
                              dense
                              style={{ maxWidth: "80%" }}
                            >
                              <ListItemText
                                primary={item.marks}
                                className="parameter-list"
                              />
                            </Box>
                          </Grid> */}
                        </ListItem>
                        <Divider
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            backgroundColor: "#bfbfbf",
                          }}
                        />
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  );
};
