import React from "react";
import { Box, Paper } from "@mui/material";
import "./mentor-sessions-details.css";
import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";

export const MenteeSessionsDetailsAssesedQuiz = ({
  quizData,
  assignments,
  getMentorQuizData,
}) => {
  const [value, setValue] = React.useState("1");
  return (
    <>
      {quizData === undefined ? (
        <Box
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="error-msg">Quiz Has Not Yet Attempted...</span>
        </Box>
      ) : (
        <>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ margin: "20px 0px 30px 0px" }}>
              <span className="heading-text-quiz">Quiz : {quizData.topic}</span>
            </Box>

            <CustomDataGrid
              rowData={{
                totalquest: quizData.quizzes[0].total_qns,
                correctquest: quizData.quizzes[0].correct_ans,
                percentagescored: `${quizData.quizzes[0].percentage}%`,
                attempts: 1,
              }}
              rowQuestionsData={{
                quizQuestions: quizData.questions,
              }}
              rowTypes={[
                "Number Questions",
                "Correct Answers",
                "Percentage",
                "Attempts",
              ]}
            />
          </Box>
        </>
      )}
    </>
  );
};
