import { API } from "../Networking/API";

export const GetUserUnitDetails = async (
  unitId,
  courseId,
  setDone,
  setUnitdata,
  setFeedbackStatusSub,
  setPreRequisite,
  setpreskdesc,
  setAssignmentData,
  setTrainingMaterial,
  setIsUnitStarted
) => {
  API.getUserUnitDetails(unitId, courseId).then(({ response }) => {
    setDone(true);
    if (response.api_status === 200) {
      const data = response.data.unitDetails;
      setUnitdata(data);
      setAssignmentData(data.topicAssignments);
      setFeedbackStatusSub(response.data.feedback_status);
      setPreRequisite(data.pre_requisite);
      setTrainingMaterial(data.training_material);
      setIsUnitStarted(response.data.isUnitStarted);
      setpreskdesc(data.pre_requisites_description);
    }
  });
};

export const preRequisiteCompletion = async (
  currentDuration,
  currentPrerequisiteId,
  unitId,
  courseId,
  completedPrerequisiteId,
  setUnitdata
) => {
  API.preRequisiteCompletion(
    currentDuration,
    currentPrerequisiteId,
    unitId,
    courseId,
    completedPrerequisiteId
  ).then(({ response }) => {
    if (response.api_status === 200) {
      return true;
    }
    return false;
  });
};

export const trainingMaterialCompletion = async (
  currentDuration,
  currentPrerequisiteId,
  unitId,
  courseId,
  completedPrerequisiteId,
  getUnitDetails
) => {
  return API.trainingMaterialCompletion(
    currentDuration,
    currentPrerequisiteId,
    unitId,
    courseId,
    completedPrerequisiteId
  ).then(({ response }) => {
    if (response.api_status === 200) {
      getUnitDetails();
    }
    return false;
  });
};

export const getConnectUserProfile = async (
  accessToken,
  instanceUrl,
  idParam
) => {
  const ConnectUserData = await API.GetConnectUserDetails(
    accessToken,
    instanceUrl,
    idParam
  );
};
