/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import { UserProfile } from "../constants/userProfile";
import {
  AdminPath,
  TraineePath,
  TrainerPath,
  MentorPath,
  UsersPath,
} from "../constants/userPath";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

export const LoginPageNavigation = async (userRole, navigate, isAdmin) => {
  switch (userRole) {
    case isAdmin === true:
      navigate(AdminPath.ADMIN_HOME_COURSES);
      break;
    case isAdmin === false:
      navigate(UsersPath.USER_DASHBOARD);
      break;
    default:
      navigate(UsersPath.USER_DASHBOARD);


    // case UserProfile.TRAINER:
    //   navigate(TrainerPath.TRAINER_HOME_PATH);
    // case UserProfile.TRAINEE:
    //   navigate(TraineePath.TRAINEE_HOME_PATH);
    // case UserProfile.USER:
    //   navigate(UsersPath.USER_DASHBOARD);
  }
};

export const UserAuthLandingPage = (userRole) => {
  switch (userRole) {
    case UserProfile.ADMIN:
      return <Navigate to={`${AdminPath.ADMIN_HOME_COURSES}`} />;
    case UserProfile.MENTOR:
      return <Navigate to={MentorPath.MENTOR_HOME_PATH} />;
    case UserProfile.TRAINER:
      return <Navigate to={TrainerPath.TRAINER_HOME_PATH} />;
    case UserProfile.TRAINEE:
      return <Navigate to={UsersPath.USER_DASHBOARD} />;
    case UserProfile.USER:
      return <Navigate to={UsersPath.USER_DASHBOARD} />;
  }
};
