import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { ReusableInputfield } from "../../components/Custom/inputfield/inputfield";
import ReusableButton from "../../components/Custom/button/button";
import { ReusableRememberMe } from "../../components/Custom/remember-me/remember-me";
import { useSearchParams } from "react-router-dom";
import Notification from "../../components/Custom/Notification";
import signupImg from "../../assets/Images/signupImg.png";
import "./signup.css";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import secret_key from "../../components/Custom/SecretKey/secret_key";
import { API } from "../../Networking/API";

const inputFieldData = {
  email: "",
  confirmPassword: "",
  password: "",
};

const inputErrorMsg = {
  forConfirmPassword: "",
  forPassword: "",
  forEmail: "",
};

const inputFieldErrorStatus = {
  confirmPasswordStatus: false,
  passwordStatus: false,
  emailStatus: false,
};

export const Signup = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  function customDecodeURIComponent(encodedString) {
    const decodedString = decodeURIComponent(encodedString);
    return decodedString.replace(/\s/g, "+");
  }

  const encodedQueryString = searchParams.get("q");

  // Use the custom decoder function
  const encryptEmail = customDecodeURIComponent(encodedQueryString);

  
  
  var bytes = CryptoJS.AES.decrypt(encryptEmail, secret_key);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    storeEmail(originalText);
    checkStatus();
  }, []);

  const storeEmail = (originalText) => {
    setInputFieldValues({ ...inputFieldValues, email: originalText });
  };

  const checkStatus = async () => {
    
  };
  const classes = [];

  const handleSubmit = async () => {
    var passwordValidation =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    if (passwordValidation.test(inputFieldValues.password) === false) {
      setErrorStatus({
        // ...errorStatus,
        passwordStatus: true,
      });
      setInputFieldErrors({
        // ...inputFieldErrors,
        forPassword: "Enter Valid Password ",
      });
    } else if (inputFieldValues.password !== inputFieldValues.confirmPassword) {
      setErrorStatus({
        // ...errorStatus,
        confirmPasswordStatus: true,
      });
      setInputFieldErrors({
        // ...inputFieldErrors,
        forConfirmPassword: "Password is Not Matching with Confirm Password",
      });
    } else {
      API.signupPage(
        inputFieldValues.email,
        inputFieldValues.password,
        inputFieldValues.confirmPassword
      ).then((response) => {
        const data = response;
        if (data.api_status === 200) {
          setNotify({
            isOpen: true,
            message: "Password Updated Successfully",
            type: "success",
          });
          navigate("/");
        } else if (data.api_status === 404) {
          setErrorStatus({
            // ...errorStatus,
            emailStatus: true,
          });
          setInputFieldErrors({
            // ...inputFieldErrors,
            forEmail: data.error[0].error_msg,
          });
        }
      });
    }
  };

  const handleTextChangeConfirmPassword = (event, value) => {
    setInputFieldValues({
      ...inputFieldValues,
      confirmPassword: event.target.value,
    });
  };
  const handleTextChangePassword = (event, value) => {
    setInputFieldValues({ ...inputFieldValues, password: event.target.value });
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: "115vh",
          zIndex: 1,
          position: "absolute",
          width: "100%",
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "7%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "15px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={signupImg}
                      alt="AdminContentPic"
                      width="335"
                      height="410"
                      style={{ marginLeft: "25px", marginTop: "30px" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span className="adminLoginHeadingOne">Welcome!</span>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Academy
                    </span>
                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "14px", marginBottom: "7px" }}
                    >
                      User Signup
                    </span>
                  </Box>

                  <Box
                    sx={{
                      "& .MuiTextField-root": { width: "38ch" },
                      marginTop: 2,
                    }}
                  >
                    <Stack direction="column" spacing={3}>
                      <ReusableInputfield
                        variant="outlined"
                        name="email"
                        margin="normal"
                        required
                        type="text"
                        value={inputFieldValues.email}
                        // defaultValue={inputFieldValues.email}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="E-Mail"
                        //onChange={(e) => handleTextChangeEmail(e, "email")}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        className={classes.inputfieldLabel}
                        autoFocus
                        error={errorStatus.emailStatus}
                        helperText={inputFieldErrors.forEmail}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={inputFieldValues.password}
                        onChange={(e) =>
                          handleTextChangePassword(e, "password")
                        }
                        id="password"
                        // id="outlined-basic"
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        className={classes.inputfieldLabel}
                        error={errorStatus.passwordStatus}
                        helperText={inputFieldErrors.forPassword}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        name="confirmPassword"
                        margin="normal"
                        required
                        type="password"
                        value={inputFieldValues.confirmPassword}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="Confirm Password"
                        onChange={(e) =>
                          handleTextChangeConfirmPassword(e, "confirmPassword")
                        }
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        className={classes.inputfieldLabel}
                        autoFocus
                        error={errorStatus.confirmPasswordStatus}
                        helperText={inputFieldErrors.forConfirmPassword}
                      />

                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <ReusableRememberMe
                            color="default"
                            size="small"
                            name="lsRememberMe"
                            checked={isChecked}
                            onChange={onChangeCheckbox}
                          />
                          <label
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="loginFieldText"
                          >
                            Remember Me
                          </label>
                        </Stack>
                      </Stack>
                    </Stack>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        paddingTop: "15px",
                      }}
                    >
                      <ReusableButton
                        size="medium"
                        variant="contained"
                        type="submit"
                        className="adminLoginButton"
                        buttonName="Create Account"
                        style={{
                          backgroundColor: "#fe1c7f",
                          fontFamily: "Poppins",
                          fontSize: "0.8em",
                          fontWeight: 200,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#fff",
                          padding: "12px 35px 12px 35px",
                          borderRadius: "4px",
                        }}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};
