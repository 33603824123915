import React from "react";
import { TextField } from "@mui/material";

export const ReusableInputfield = ({
  onChange,
  type,
  size,
  className,
  value,
  style,
  id,
  InputProps,
  disabled,
  variant,
  defaultValue,
  fullWidth,
  label,
  name,
  required,
  error,
  helperText,
}) => {
  return (
    <>
      <TextField
        type={type}
        style={style}
        id={id}
        variant={variant}
        label={label}
        value={value}
        name={name}
        disabled={disabled}
        className={`${className}`}
        required={required}
        onChange={onChange}
        size={size}
        error={error}
        helperText={helperText}
        InputProps={InputProps}
        autoComplete="off"
      ></TextField>
    </>
  );
};
