import { React, useEffect, useState } from "react";
import "../../mentor/mentor-sessions-details/mentor-sessions-details.css";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import List from "./List";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";
import "./schedule.css";
import Notification from "../../../components/Custom/Notification";
import ReactLoading from "react-loading";
import { Box } from "@material-ui/core";

export const SearchBar = ({
  clickStatus,
  batchID,
  setSessionName,
  setScheduleId,
  uploadFile,
}) => {
  const [mentorSearch, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    testUsers();
  }, [mentorSearch, batchID, uploadFile]);

  const testUsers = async () => {
    setLoading(true);
    setScheduleId();
    API.postScheduleSearchOnBatch(batchID, mentorSearch).then(
      ({ response }) => {
        if (response.api_status === 200) {
          const apiData1 = response.data;
          const tabledata = apiData1.map((items, index) => ({
            user_pk: items.schedule_pk,
            user_name: items.topic,
          }));
          // // setTableData(tabledata);
          setContacts(tabledata);
          setLoading(false);
          // setTableData()
        } else if (response.api_status === 404) {
          const errmessage = response.data;
          setNotify({
            isOpen: true,
            message: errmessage,
            type: "error",
          });
          setContacts([]);
          setLoading(false);
        } else {
          setNotify({
            isOpen: true,
            message: "file not uploaded",
            type: "error",
          });
          setContacts([]);
          setLoading(false);
        }
      }
    );
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      // margin : theme.spacing(0,0,2,0),
    },
  }));

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    testUsers();
  };
  const setClickStatus = (status) => {
    clickStatus(status);
  };

  return (
    <>
      <div>
        <h1
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#02215B",
          }}
        >
          Sessions Topic List
        </h1>
        <Search
          className="search-container"
          style={{ marginLeft: "12px !important" }}
        >
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            onChange={handleSearchChange}
            value={mentorSearch}
            autoFocus
            style={{ border: "1px solid #9e9e9e", width: "97%" }}
          />
        </Search>
        {loading ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <List
            contacts={contacts}
            sessionName={setSessionName}
            clickStatus={setClickStatus}
            sessionId={setScheduleId}
          />
        )}
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
