import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { API } from "../../../Networking/API";

function TrainerDetails(props) {
  const { scheduleId } = props;
  const [details, setDetails] = useState();
  useEffect(() => {
    getTraineedtails(scheduleId);
  }, [scheduleId]);

  const getTraineedtails = (scheduleId) => {
    API.getAdminTraineeDetails(scheduleId).then(({ response }) => {
      let userdetails = response.data.data[0];
      setDetails(userdetails);
    });
  };
  return (
    <>
      {details !== undefined ? (
        <>
          <Box>
            Name :{" "}
            {details.user_name !== null &&
            details.user_name !== "" &&
            details.user_name !== undefined
              ? details.user_name
              : "N/A"}
          </Box>
          <Box>
            Work Position :{" "}
            {details.work_posiotion === "null" &&
            details.work_posiotion === null &&
            details.work_posiotion !== undefined &&
            details.work_posiotion !== ""
              ? details.work_posiotion
              : "N/A"}
          </Box>
          <Box>
            Phone Number :{" "}
            {details.work_posiotion === "null" &&
            details.phonenumber === null &&
            details.phonenumber !== undefined &&
            details.phonenumber !== ""
              ? details.phonenumber
              : "N/A"}
          </Box>
          {/* <Box>
            Certificates :{" "}
            {details.certificates !== null &&
            details.certificates !== undefined &&
            details.certificates !== ""
              ? details.certificates
              : "N/A"}
          </Box> */}
          {/* <Box>
            Department :{" "}
            {details.department !== null &&
            details.department !== undefined &&
            details.department !== ""
              ? details.department
              : "N/A"}
          </Box> */}
          <Box>
            User Email :{" "}
            {details.user_email !== null &&
            details.user_email !== undefined &&
            details.user_email !== ""
              ? details.user_email
              : "N/A"}
          </Box>
          <Box>
            Trainee College :{" "}
            {details.trainee_colleage === null &&
            details.trainee_colleage !== null &&
            details.trainee_colleage !== undefined &&
            details.trainee_colleage !== ""
              ? details.trainee_colleage
              : "N/A"}
          </Box>
        </>
      ) : null}
    </>
  );
}
export default TrainerDetails;
