import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core";
import Cookies from "js-cookie";
import "./trainer-session.css";
import "./trainer-session-hackathon.css";
import "./progressbarstyles.css";
import { API } from "../../../Networking/API";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
// import api from "../../../api/index";
import ReactLoading from "react-loading";
import ReactImageShapes from "react-image-shapes";
import HackathonFooter from "./hackathon-footer";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#fb2485" : "#fb2485",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#fb2485" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const getDepartment = [
  { id: "1", title: "Hackathon01" },
  { id: "2", title: "Hackathon02" },
  { id: "3", title: "Hackathon03" },
  //   { id: '4', title: 'Completed' },
];

const styles = makeStyles((theme) => ({
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "100%",
      height: "40px",
    },
  },
}));

const TrainerSessionHackathon = () => {
  const [done, setDone] = useState(false);
  const [hackathonDetails, setHackathonDetails] = useState([]);
  const [hackathonsessiondrop, setHackathonsessiondrop] = useState([]);
  const [loadingTop5Page, setLoadingTop5Page] = useState(false);

  const [hackathonDetailsTopFive, setHackathonDetailsTopFive] = useState([]);
  const [hackathonTopFiveData, setHackathonTopFiveData] = useState([]);
  const [filter, setFilter] = useState("");
  const [checked, setChecked] = React.useState(false);
  var filterValue = localStorage.getItem("dropdownvalue");
  let timeInterval = undefined;

  const HitApi = () => {
    if (timeInterval !== undefined) {
      clearInterval(timeInterval);
    }
    timeInterval = setInterval(() => {
      if ((filterValue !== "" || filterValue !== null) && filter !== "") {
        filterValue = localStorage.getItem("dropdownvalue");
        getHackathonTopScoreDetails(filterValue);
        getHackathonScoreDetails(filterValue);
      }
    }, 240 * 1000);
  };

  useEffect(() => {
    if (filter !== "") {
      getHackathonScoreDetails(filterValue);
      getHackathonTopScoreDetails(filterValue);
      HitApi();
    }
  }, [filter]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (e) => {
    localStorage.setItem("dropdownvalue", e.target.value);
    const abc = e.target.value;
    HitApi();
    if (filter == "") {
      setChecked(false);
    }
    setFilter(e.target.value);
    setLoadingTop5Page(true);
  };

  const getHackathonSessionDetails = async () => {
    API.getTrainerHackathon().then(({ response }) => {
      if (response.data.api_status === 200) {
        setHackathonsessiondrop(response.data.data[0].hackathons);
      } else {
        
      }
    });
  };

  const getHackathonScoreDetails = async (valueId) => {
    API.getTrainerHackathonScore(valueId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setHackathonDetails(response.data.data[0]);
        setDone(true);
      } else {
        
      }
    });
  };

  const getHackathonTopScoreDetails = async (valueId) => {
    API.getTrainerHackathonTopScore(valueId).then(({ response }) => {
      if (response.data.api_status === 200) {
        const usersData = response.data.data[0].users;
        const sortedUsersData = usersData.sort(
          (a, b) => b.run_rate - a.run_rate
        );
        setHackathonDetailsTopFive(response.data.data[0]);
        setHackathonTopFiveData(sortedUsersData);
        setDone(true);
        setLoadingTop5Page(false);
      } else {
        setLoadingTop5Page(false);
      }
    });
  };

  useEffect(() => {
    getHackathonSessionDetails();
  }, []);

  return (
    <>
      {filter === "" ? (
        <>
          <Box className="heading">
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <h1
                  className="hacHeading"
                  style={
                    checked ? { fontWeight: "300" } : { fontWeight: "600" }
                  }
                >
                  Leader Board
                </h1>
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  style={{ marginBottom: "-40px" }}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      // defaultChecked
                      // value={checked}
                      onChange={handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <h1
                  className="hacHeading"
                  style={
                    !checked ? { fontWeight: "300" } : { fontWeight: "600" }
                  }
                >
                  Score Board
                </h1>
              </Grid>
            </Grid>
          </Box>
          <Box className="box2">
            <Grid container>
              <Grid item xs={3}>
                <Custom.Dropdown6
                  name="sessions"
                  label="All Sessions"
                  value={filter}
                  onChange={handleInputChange}
                  options={hackathonsessiondrop}
                  // error={errors.department}
                  size="small"
                  style={{
                    height: 40,
                    alignItems: "center",
                    display: "flex",
                    fontFamily: "Poppins",
                  }}
                ></Custom.Dropdown6>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              ></Grid>
            </Grid>
          </Box>
          <>
            <Box
              sx={{
                height: "30vh",
                width: "95%",
                marginLeft: "30px",
                borderRadius: "12px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "#02215b", marginTop: "0px" }}>
                {" "}
                Please Select A Session To View Hackathon Details
              </h4>
            </Box>{" "}
          </>
        </>
      ) : (
        <>
          {!done ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "90vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <Box className="heading">
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <h1
                      className="hacHeading"
                      style={
                        checked ? { fontWeight: "300" } : { fontWeight: "600" }
                      }
                    >
                      Leader Board
                    </h1>
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel
                      style={{ marginBottom: "-40px" }}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          // defaultChecked
                          onChange={handleChange}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <h1
                      className="hacHeading"
                      style={
                        !checked ? { fontWeight: "300" } : { fontWeight: "600" }
                      }
                    >
                      Score Board
                    </h1>
                  </Grid>
                </Grid>
              </Box>
              <Box className="box2">
                <Grid container>
                  <Grid item xs={3}>
                    <Custom.Dropdown6
                      name="sessions"
                      label="All Sessions"
                      value={filter}
                      onChange={handleInputChange}
                      options={hackathonsessiondrop}
                      // error={errors.department}
                      size="small"
                      style={{
                        height: 40,
                        alignItems: "center",
                        display: "flex",
                        fontFamily: "Poppins",
                      }}
                    ></Custom.Dropdown6>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <h2 style={{ color: "#02215b", marginTop: "0px" }}>
                      {hackathonDetails.length === 0 ||
                      hackathonDetailsTopFive.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {hackathonDetails.hackathon.topic ||
                            hackathonDetailsTopFive.hackathon.topic}
                        </>
                      )}
                      {/* {hackathonDetails ? hackathonDetails.hackathon.topic : hackathonDetailsTopFive ?  hackathonDetailsTopFive.hackathon.topic : null} */}
                    </h2>
                  </Grid>
                  <Grid item xs={1}></Grid>

                  {hackathonDetails.length !== 0 ? (
                    <>
                      {hackathonDetails.hackathon.live === "true" ? (
                        <>
                          <Grid item xs={2} className="live">
                            <div
                              style={{ marginTop: "-45px" }}
                              className={checked ? "livenow" : "livenow"}
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span
                              style={{
                                color: "#585858",
                                height: 16,
                                marginTop: "-30px",
                                marginLeft: "3px",
                              }}
                            >
                              Live Hackathon
                            </span>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={2}></Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
              <>
                {loadingTop5Page ? (
                  <Box
                    sx={{ flexGrow: 1 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "90vh",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#fb2485"}
                      height={100}
                      width={100}
                    />
                  </Box>
                ) : (
                  <>
                    {checked ? (
                      <>
                        {hackathonDetails.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            {hackathonDetails.users.length > 0 ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginLeft: "30px",
                                    marginRight: "30px",
                                    //   borderRadius: '15px',
                                    // borderTopLeftRadius: '15px',
                                    // borderTopRightRadius: '15px',
                                    marginBottom: "20px",
                                    // paddingBottom:
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "95%",
                                      "& .super-app-theme--header": {
                                        backgroundColor: "white",
                                        color: "#000000",
                                        fontSize: "16px",
                                      },
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "white",
                                        // marginLeft: '30px',
                                        marginRight: "30px",
                                        // marginTop: '-30px',
                                        //   borderRadius: '15px',
                                        borderTopLeftRadius: "15px",
                                        borderTopRightRadius: "15px",
                                        // marginBottom: '20px',
                                        height: "1vh",
                                      }}
                                    ></div>

                                    <TableContainer
                                      sx={{
                                        background: "white",
                                        maxHeight: "390px",
                                        // borderTopRightRadius: '15px',
                                        // borderTopLeftRadius: '15px',
                                        // borderRadius:"15px",
                                        //  width:800,
                                        width: "100% !important",
                                      }}
                                    >
                                      <Table
                                        sx={{
                                          minWidth: 650,
                                          width: "max-content",
                                        }}
                                        aria-label="simple table"
                                        stickyHeader
                                        className="tablehackclass"
                                      >
                                        <TableHead>
                                          <TableRow className="tableHeadssss">
                                            <TableCell
                                              style={{
                                                position: "sticky",
                                                left: 0,
                                                zIndex: 110,
                                                width: "200px",
                                                border: "0px",
                                                paddingRight: "0px",
                                                backgroundColor: "white",
                                                textAlign: "left",
                                              }}
                                              className="namecell"
                                            >
                                              <b>Names</b>
                                            </TableCell>
                                            {hackathonDetails.length === 0 ? (
                                              <></>
                                            ) : (
                                              <>
                                                {hackathonDetails.tasks.map(
                                                  (task) => (
                                                    <TableCell
                                                      className="rowTxts2"
                                                      style={{
                                                        border: "0px",
                                                        minWidth: "145px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                          justifyContent:
                                                            "start", //changesmade
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <span>
                                                          {task.task_name}
                                                        </span>
                                                        <span>
                                                          ({task.task_time})
                                                        </span>
                                                      </div>
                                                    </TableCell>
                                                  )
                                                )}

                                                <TableCell
                                                  style={{
                                                    minWidth: "250px",

                                                    borderLeft:
                                                      " 1px solid rgba(149, 161, 172, .2)",
                                                    borderBottom: "0px",
                                                    position: "sticky",
                                                    right: 0,
                                                    zIndex: 110,
                                                    backgroundColor: "white",
                                                    // borderLeft:'1px solid black'
                                                  }}
                                                  className="rowTxts"
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        // "space-around",
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <b
                                                      style={
                                                        {
                                                          // marginLeft: "20px",
                                                        }
                                                      }
                                                    >
                                                      Overall
                                                    </b>
                                                    <b
                                                      style={{
                                                        marginLeft: "10px",
                                                        marginRight: "-20px",
                                                        width: "122px",
                                                      }}
                                                    >
                                                      Score Achieved
                                                    </b>
                                                  </div>
                                                </TableCell>
                                              </>
                                            )}
                                          </TableRow>
                                        </TableHead>

                                        <TableBody>
                                          {hackathonDetails.length === 0 ? (
                                            <></>
                                          ) : (
                                            <>
                                              {hackathonDetails.users.map(
                                                (user, index) => (
                                                  <TableRow
                                                    key={`user.user_id+${Math.random()
                                                      .toString(36)
                                                      .substring(7)}`}
                                                    sx={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                    }}
                                                    className="tableBodys"
                                                  >
                                                    <TableCell
                                                      className="rowTxts2"
                                                      style={{
                                                        position: "sticky",
                                                        left: 0,
                                                        zIndex: 99,
                                                        width: "200px",
                                                        border: "0px",
                                                        paddingRight: "0px",
                                                        backgroundColor:
                                                          index % 2 === 0
                                                            ? "#f5f5f5"
                                                            : "#ffffff",
                                                      }}
                                                      scope="row"
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <span
                                                          className="timeHrss"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {" "}
                                                          {user.name}
                                                        </span>
                                                      </div>
                                                    </TableCell>

                                                    {hackathonDetails.tasks.map(
                                                      (task, index) => (
                                                        <TableCell
                                                          className="rowTxts2"
                                                          style={{
                                                            border: "0px",
                                                            minWidth: "140px",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "row",
                                                              justifyContent:
                                                                "center", //changesmade
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                user.tasks[
                                                                  index
                                                                ] !== undefined
                                                                  ? user.tasks[
                                                                      index
                                                                    ].color ===
                                                                    0
                                                                    ? "statusEllipse"
                                                                    : user
                                                                        .tasks[
                                                                        index
                                                                      ]
                                                                        .color ===
                                                                      1
                                                                    ? "statusEllipse2"
                                                                    : "statusEllipse3"
                                                                  : null
                                                              }
                                                            ></div>
                                                            <span className="timeHrss">
                                                              {" "}
                                                              {user.tasks[
                                                                index
                                                              ] !==
                                                              undefined ? (
                                                                user.tasks[
                                                                  index
                                                                ].time
                                                              ) : (
                                                                <div
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  N/A
                                                                </div>
                                                              )}
                                                            </span>
                                                          </div>
                                                        </TableCell>
                                                      )
                                                    )}
                                                    {/* <TableCell
                                  className="rowTxts2"
                                  style={{
                                    border: '0px',
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                
                                  </div>
                                </TableCell> */}
                                                    <TableCell
                                                      className="rowTxts2"
                                                      style={{
                                                        width: "100%",
                                                        border: "0px",
                                                        // borderLeft:'1px solid black',
                                                        display: "flex",
                                                        borderLeft:
                                                          " 1px solid rgba(149, 161, 172, .2)",
                                                        justifyContent:
                                                          "space-between",
                                                        position: "sticky",
                                                        right: 0,
                                                        zIndex: 100,
                                                        backgroundColor:
                                                          index % 2 === 0
                                                            ? "#f5f5f5"
                                                            : "#ffffff",
                                                      }}
                                                      scope="row"
                                                    >
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          // justifyContent:
                                                          //   "space-between",
                                                        }}
                                                      >
                                                        <div
                                                          className="timeHrss"
                                                          style={{
                                                            // marginLeft: "20px",
                                                            width: "50%",
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {user.overall
                                                            ? user.overall
                                                            : "N/A"}
                                                        </div>
                                                        <div
                                                          className="timeHrss"
                                                          style={{
                                                            // display: "flex",
                                                            // justifyContent:
                                                            // "center",
                                                            // marginLeft: "63px",
                                                            // marginRight:'-20px',
                                                            color: "#000000de",
                                                            width: "50%",
                                                            textAlign: "left",
                                                            marginLeft: "",
                                                          }}
                                                        >
                                                          {user.score_achieved !==
                                                          null
                                                            ? user.score_achieved
                                                            : "N/A"}
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </>
                                          )}
                                        </TableBody>

                                        <TableHead>
                                          <TableRow
                                            style={{
                                              bottom: 0,
                                              position: "sticky",
                                              zIndex: 110,
                                            }}
                                          >
                                            <TableCell
                                              className=""
                                              align="left"
                                              style={{
                                                minWidth: 160,
                                                bottom: 0,
                                                position: "sticky",
                                                left: 0,
                                                zIndex: 110,
                                                textAlign: "start",
                                              }}
                                            >
                                              <b>Total Average</b>
                                            </TableCell>

                                            {hackathonDetails.tasks.map(
                                              (items, index) => (
                                                <TableCell align="center">
                                                  <b>{items.time}</b>
                                                </TableCell>
                                              )
                                            )}

                                            <TableCell
                                              align="center"
                                              style={{
                                                width: "146px",
                                                border: "0px",
                                                position: "sticky",
                                                right: 0,
                                                zIndex: 110,
                                                bottom: 0,
                                                backgroundColor: "white",
                                                borderLeft:
                                                  " 1px solid rgba(149, 161, 172, .2)",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                <b
                                                // style={{ marginLeft: "16px" }}
                                                >
                                                  N/A
                                                </b>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                      </Table>
                                    </TableContainer>
                                    {/* ) */}
                                  </Box>
                                </div>

                                <>
                                  <Box
                                    sx={{
                                      height: "2.5vh",
                                      width: "95%",
                                      marginLeft: "30px",
                                      borderBottomRightRadius: "12px",
                                      borderBottomLeftRadius: "12px",
                                      backgroundColor: "white",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "-20px",
                                    }}
                                  ></Box>
                                </>

                                {/* <Box className="boxFooter">
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span className="pCount">
                                        {hackathonDetails.total_participents}
                                      </span>
                                      <span className="inProgress">
                                        In Progress
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span className="pCount">
                                        {hackathonDetails.remain_time}
                                      </span>
                                      <span
                                        className="inProgress"
                                        style={{ marginLeft: "-40px" }}
                                      >
                                        Time Remaining
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center !important",
                                        flexDirection: "column",
                                        alignItems: "center !important",
                                      }}
                                    >
                                      <span
                                        className="pCount"
                                        style={{ marginLeft: "150px" }}
                                      >
                                        {hackathonDetails.completed}
                                      </span>
                                      <span
                                        className="inProgress"
                                        style={{ marginLeft: "120px" }}
                                      >
                                        Completed
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Box> */}
                                {hackathonDetails.length !== 0 ||
                                hackathonDetailsTopFive.length !== 0 ? (
                                  <>
                                    {hackathonDetails.hackathon.live ===
                                    "true" ? (
                                      <>
                                        <HackathonFooter
                                          hackathonDetails={hackathonDetails}
                                          hackathonDetailsTopFive={
                                            hackathonDetailsTopFive
                                          }
                                        />
                                      </>
                                    ) : (
                                      <div style={{ marginTop: "100px" }}></div>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    height: "30vh",
                                    width: "95%",
                                    marginLeft: "30px",
                                    borderRadius: "12px",
                                    backgroundColor: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4
                                    style={{
                                      color: "#02215b",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {" "}
                                    {hackathonDetails.tasks.length === 0 &&
                                    hackathonDetails.users.length === 0
                                      ? "Hackathon Not Yet Started. Stay Tuned."
                                      : "Participants Have Not Submitted the Tasks yet!"}
                                  </h4>
                                </Box>{" "}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {hackathonDetailsTopFive.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            {hackathonDetailsTopFive.users.length > 0 ? (
                              <>
                                <div
                                  style={{
                                    // width: '90%',
                                    textAlign: "center",
                                    //   backgroundColor: "white",
                                    marginLeft: "30px",
                                    marginRight: "30px",
                                    //   borderRadius: '15px',
                                    borderTopLeftRadius: "15px",
                                    borderTopRightRadius: "15px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Box
                                    className="leaderboard-styling"
                                    sx={{
                                      width: "100%",
                                      "& .super-app-theme--header": {
                                        backgroundColor: "white",
                                        color: "#000000",
                                        fontSize: "16px",
                                        borderRadius: "0px",
                                      },
                                    }}
                                  >
                                    <TableContainer
                                      sx={{
                                        background: "transparent",
                                        //  width: 800
                                      }}
                                    >
                                      <Table
                                        sx={{
                                          minWidth: 650,
                                          width: "max-content",
                                        }}
                                        aria-label="simple table"
                                      >
                                        <TableHead className="tableHeader">
                                          <TableRow className="tableHead">
                                            <TableCell
                                              style={{
                                                border: "0px",
                                                position: "sticky",
                                                left: 0,
                                                zIndex: 99,

                                                overflow: "hidden",
                                                // background: "inherit",
                                                // height: '100%',
                                                // backgroundImage: ` url(${bgImage})`,
                                                // backgroundSize: 'cover',
                                                // backgroundRepeat: 'no-repeat',
                                                // backgroundPosition: 'center',
                                              }}
                                              className="rowTxt2namecelltop5"
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div> Participant </div>
                                                <div> Run Rate</div>
                                              </div>
                                            </TableCell>
                                            {/* <TableCell
                              style={{
                                width: '106px',
                                border: '0px',
                                position: 'sticky',
                                left: '48px',
                                zIndex: 85,
                              }}
                              className="rowTxt"
                            >
                              Run Rate
                            </TableCell> */}
                                            {hackathonDetailsTopFive.length ===
                                            0 ? (
                                              <></>
                                            ) : (
                                              <>
                                                {hackathonDetailsTopFive.tasks.map(
                                                  (task) => (
                                                    <TableCell
                                                      className="rowTxt"
                                                      style={{
                                                        border: "0px",
                                                        minWidth: "140px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <span>
                                                          {task.task_name}
                                                        </span>
                                                        <span>
                                                          ({task.time})
                                                        </span>
                                                      </div>
                                                    </TableCell>
                                                  )
                                                )}
                                              </>
                                            )}

                                            {/* <TableCell className="rowTxt" style={{ border: '0px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                <span>Task 2</span>
                                <span>(02:00 Hours)</span>
                              </div>
                            </TableCell>
                            <TableCell style={{ border: '0px' }} className="rowTxt">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span>Task 3</span>
                                <span>(03:00 Hours)</span>
                              </div>
                            </TableCell>
                            <TableCell style={{ border: '0px' }} className="rowTxt">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span>Task 4</span>
                                <span>(04:00 Hours)</span>
                              </div>
                            </TableCell>
                            <TableCell className="rowTxt" style={{ border: '0px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span>Task 5</span>
                                <span>(01:00 Hours)</span>
                              </div>
                            </TableCell>
                            <TableCell className="rowTxt" style={{ border: '0px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span>Task 6</span>
                                <span>(01:00 Hours)</span>
                              </div>
                            </TableCell>
                            <TableCell className="rowTxt" style={{ border: '0px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span>Task 7</span>
                                <span>(01:00 Hours)</span>
                              </div>
                            </TableCell> */}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {hackathonDetailsTopFive.length ===
                                          0 ? (
                                            <></>
                                          ) : (
                                            <>
                                              {hackathonDetailsTopFive.users
                                                .length === 0 ? (
                                                <></>
                                              ) : (
                                                <>
                                                  {hackathonTopFiveData.map(
                                                    (user, index) => (
                                                      <TableRow
                                                        key={Math.random()
                                                          .toString(36)
                                                          .substring(7)}
                                                        sx={{
                                                          backgroundColor:
                                                            "transparent",
                                                        }}
                                                        className="tableBody"
                                                      >
                                                        <TableCell
                                                          className="rowTxt2namecell"
                                                          style={{
                                                            width: "400px",
                                                            border: "0px",
                                                            position: "sticky",
                                                            left: 0,
                                                            zIndex: 90,
                                                            //   backgroundColor:"black",
                                                            //   backgroundImage : `url(${bgImage})`,
                                                          }}
                                                          scope="row"
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              // flexDirection:
                                                              //   "row",
                                                              // justifyContent:
                                                              //   "space-between",
                                                              alignItems:
                                                                "center",
                                                              // marginLeft:"12px"
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                width: "48px",
                                                                marginRight:
                                                                  "18px",
                                                                paddingLeft:
                                                                  "12px",
                                                              }}
                                                            >
                                                              <img
                                                                src={require("../../../assets/Images/recentBadge.png")}
                                                                alt={
                                                                  user.name_user
                                                                }
                                                                // className="badgeImg"
                                                              />
                                                            </div>
                                                            {user.user_profile !==
                                                            null ? (
                                                              <div className="user-image-container">
                                                                <ReactImageShapes
                                                                  image={
                                                                    user.user_profile
                                                                  }
                                                                  width="60px"
                                                                  height="60px"
                                                                  type="rhombus"
                                                                  className="user-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              // <img
                                                              //   src={
                                                              //     user.user_profile
                                                              //   }
                                                              //   alt="image"
                                                              //   className="user-icons"
                                                              // />
                                                              // </div>
                                                              <div className="user-image-container">
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "60px",
                                                                    height:
                                                                      "60px",
                                                                  }}
                                                                  src={require("../../../assets/Images/badgerecent1.png")}
                                                                  alt="images"
                                                                />
                                                              </div>
                                                            )}
                                                            {/* </div> */}
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                justifyContent:
                                                                  "space-between",
                                                              }}
                                                              className="rownameandrun"
                                                            >
                                                              <div
                                                                // className="timeHrs"
                                                                style={{
                                                                  textTransform:
                                                                    "capitalize",
                                                                  minWidth:
                                                                    "180px",
                                                                }}
                                                              >
                                                                {" "}
                                                                {user.name}
                                                              </div>
                                                              <div
                                                              // className="timeHrsss"
                                                              >
                                                                {" "}
                                                                {user.run_rate}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </TableCell>

                                                        {/* 2nd copy */}
                                                        {hackathonDetailsTopFive.tasks.map(
                                                          (task, index) => (
                                                            <TableCell
                                                              className="rowTxt2"
                                                              style={{
                                                                border: "0px",
                                                                minWidth:
                                                                  "140px",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    user.tasks[
                                                                      index
                                                                    ] !==
                                                                    undefined
                                                                      ? user
                                                                          .tasks[
                                                                          index
                                                                        ]
                                                                          .color ===
                                                                        0
                                                                        ? "statusEllipse"
                                                                        : user
                                                                            .tasks[
                                                                            index
                                                                          ]
                                                                            .color ===
                                                                          1
                                                                        ? "statusEllipse2"
                                                                        : "statusEllipse3"
                                                                      : null
                                                                  }
                                                                ></div>
                                                                <span className="timeHrss">
                                                                  {/* {task.time ? task.time : 'N/A'} */}{" "}
                                                                  {user.tasks[
                                                                    index
                                                                  ] !==
                                                                  undefined
                                                                    ? user
                                                                        .tasks[
                                                                        index
                                                                      ].time
                                                                    : "N/A"}
                                                                </span>
                                                              </div>
                                                            </TableCell>
                                                          )
                                                        )}
                                                      </TableRow>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </div>
                                {hackathonDetailsTopFive.length !== 0 &&
                                hackathonDetails.length !== 0 ? (
                                  // ||
                                  // hackathonDetails.hackathon.live !==
                                  //   undefined
                                  <>
                                    {hackathonDetails.hackathon.live ===
                                    "true" ? (
                                      <>
                                        <HackathonFooter
                                          hackathonDetails={hackathonDetails}
                                          hackathonDetailsTopFive={
                                            hackathonDetailsTopFive
                                          }
                                        />
                                      </>
                                    ) : (
                                      <div style={{ marginTop: "100px" }}></div>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    height: "30vh",
                                    width: "95%",
                                    marginLeft: "30px",
                                    borderRadius: "12px",
                                    backgroundColor: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4
                                    style={{
                                      color: "#02215b",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {" "}
                                    Hackathon Not Yet Started. Stay Tuned.
                                  </h4>
                                </Box>{" "}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TrainerSessionHackathon;
