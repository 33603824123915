import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ReusableButton from "../../../components/Custom/button/button";
import { Custom } from "../../../components/Custom/Custom";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./quiz-upload.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Cookies from "js-cookie";
import Notification from "../../../components/Custom/Notification";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import { API } from "../../../Networking/API";
import { Typography } from "@mui/material";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const SheetJSFT = ["xlsx", "csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const QuizUpload = (props) => {
  const [tableData, setTableData] = useState([]);
  const [batchID, setBatchID] = useState();
  const [done, setDone] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [questions, setQuestion] = useState("");
  const [showValues, setShowValues] = useState(false);
  const [filter, setFilter] = useState("");
  const [batches, setBatches] = useState([]);
  const [fileMerge, setFileMerge] = useState("");

  useEffect(() => {
    getBatches();
  }, []);

  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        const data = response.data.data[0].bacthes;
        const updatedData = data.map((each) => ({
          key: each.batch_pk,
          value: each.batch_pk,
          title: each.batch_name,
          start_date: each.start_date,
          end_date: each.end_date,
        }));
        setBatches(updatedData);
        setFilter(updatedData[0].key);
        setBatchID(response.data.data[0].bacthes[0].batch_pk);
        getIdValue(response.data.data[0].bacthes[0].batch_pk);
      } else {
        setBatches([]);
      }
    });
  };

  const handleInputChangeFilter = (e) => {
    const value = batches.filter(function (item) {
      return item.key === e.target.value;
    });
    setBatchID(value[0].batch_pk);
    setFilter(value[0].key);
  };

  const [totalquestionscountforpage, setTotalQuestionsCountForPage] =
    useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // const jwtToken = Cookies.get("jwt_token");

  useEffect(() => {
    getIdValue(batchID);
  }, [batchID]);

  let pageNumber = 0;

  const getIdValue = async (batchID) => {
    if (batchID !== undefined) {
      API.getAdminQuestionsUpload(batchID).then(async ({ response }) => {
        if (response.data.api_status === 200) {
          const apiData = response.data.data[0].quizz_questions;
          const updatedData = await DataTableHandler.AdminUploadQuestions(apiData);
          setTableData(updatedData);
          setDone(true);
        } else {
          setTableData([]);
          setDone(true);
        }
      });
    }
  };

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    getIdValue(pageNumber);
  };

  const handleClickOpen = (value) => {
    setId(value.row.id);
    setQuestion(value.row.question);
    setOpen(true);
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };
  const handleCloseByDelete = (paramsId) => {
    API.deleteAdminQuestions(paramsId).then(({ result }) => {
      const resultInJson = result;
      if (resultInJson.api_status === 200) {
        getIdValue(batchID);
        setDone(undefined);
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Deleted Successfully",
          type: "success",
        });
      } else {
        getIdValue(batchID);
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 20,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "Question",
      headerName: "Question",
      // flex: 1,
      width: 580,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "CorrectOption",
      headerName: "Answer",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Topic",
      headerName: "Topic",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <ReusableButton
            size="large"
            className="qstnUpldPageBttn"
            startIcon={
              <IconButton aria-label="delete">
                <DeleteOutlineOutlinedIcon color="primary" fontSize="small" />
              </IconButton>
            }
            onClick={() => handleClickOpen(params)}
          />
          <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleCloseByCancel}
            BackdropProps={{
              style: {
                background: "#00000022",
                boxShadow: "0px 0px 0px 0px !important",
              },
            }}
          >
            <DialogTitle></DialogTitle>
            <DialogContent
              style={{ boxShadow: "none" }}
              BackdropProps={{
                style: { boxShadow: "none", transition: "none" },
              }}
            >
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete?
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {/* "{questions}" */}
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#000936",
                  border: "1px solid #000936",
                  padding: "7px 20px",
                  borderRadius: "4px",
                  marginRight: "8px",
                  // marginBottom:"25px",
                  textTransform: "none",
                }}
                onClick={handleCloseByCancel}
              />
              <Button
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Delete"
                style={{
                  backgroundColor: "#fb2485",
                  // marginRight:"30px",
                  // marginBottom:"25px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                  marginRight: "10px",
                }}
                onClick={() => handleCloseByDelete(id)}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ),
    },
  ];

  const handleUploadClick = async (batchID) => {
    API.postAdminQuestionUpload(fileMerge, batchID).then(({ response }) => {
      if (response.api_status === 200) {
        setShowValues(false);
        getIdValue(batchID);
        setDone(undefined);
        setNotify({
          isOpen: true,
          message: "File Uploaded successfully",
          type: "success",
        });
      } else {
        getIdValue(batchID);

        setNotify({
          isOpen: true,
          message: "File Not Uploaded",
          type: "error",
        });
      }
    });
  };

  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
    setShowValues(true);
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box component="main" className="overallQUDiv" sx={{ flexGrow: 1 }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <h2 className="questionsPageTitle">Quiz</h2>
              </Grid>
            </Grid>
          </div>

          <Container
            component="main"
            maxWidth="xl"
            sx={{ Padding: "0px !important" }}
          >
            <Card
              variant="outlined"
              sx={{ marginTop: "2%", Width: "100%", borderRadius: 0 }}
            >
              <Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    padding: "18px 30px 18px 30px",
                  }}
                >
                  <span>
                    Please Upload the Quiz file in Spreadsheet(csv)* Format
                  </span>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        marginTop: "18px",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Custom.ReusableDropdown
                        name="Select Bootcamp"
                        label="Select Bootcamp"
                        value={filter}
                        placeholder="Bootcamp"
                        onChange={handleInputChangeFilter}
                        options={batches}
                        style={{
                          height: 40,
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                        size="small"
                      ></Custom.ReusableDropdown>
                    </Grid>
                    <Grid item xs={0.5}></Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        class="upload-box"
                        accept={SheetJSFT}
                        style={{
                          marginTop: "18px",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {showValues ? (
                        <>
                          <Button
                            type="button"
                            className="UpldBttn"
                            variant="contained"
                            startIcon={<FileUploadOutlinedIcon />}
                            style={{
                              marginTop: "18px",
                              backgroundColor: "#fb2485",
                              color: "#fff",
                              fontFamily: "Poppins",
                              fontSize: "0.8em",
                              fontWeight: 200,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              padding: "12px 28px 12px 28px",
                              borderRadius: "6px",
                            }}
                            onClick={() => {
                              handleUploadClick(batchID);
                            }}
                          >
                            Upload Excel
                          </Button>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Card>
          </Container>

          <Container component="main" maxWidth="xl">
            <Card
              variant="outlined"
              sx={{
                marginTop: "2%",
                Width: "100%",
                borderRadius: 0,
                marginBottom: 12,
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: "16px 26px 16px 26px" }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#080825",
                      fontFamily: "Poppins",
                      fontSize: "1.3em",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                    }}
                  >
                    Recently Shared Quiz
                  </span>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#f1f1f1",
                    color: "#02215b",
                    fontSize: "16px",
                  },
                }}
              >
                {tableData.length > 0 ? (
                  <>
                    <DataTable
                      rows={tableData}
                      columns={columns}
                      rowHeight={40}
                      autoHeight
                      pageSize={10}
                      // hideFooter={true}
                      // hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                      className="assigntable"
                    />
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "black",
                        justifyContent: "center",
                        margin: "20px 0px 0px 0px",
                      }}
                    >
                      <ReactPaginate
                        nextLabel="next>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={6}
                        marginPagesDisplayed={2}
                        pageCount={totalquestionscountforpage / 10}
                        previousLabel="<previous"
                        pageClassName="pageClassName"
                        pageLinkClassName="pageLink"
                        previousClassName="previousClass"
                        previousLinkClassName="previousLinkClass"
                        nextClassName="nextClass"
                        nextLinkClassName="nextLinkClass"
                        breakLabel="...."
                        breakClassName="breakClass"
                        breakLinkClassName="breakLinkClass"
                        containerClassName="pagination"
                        activeClassName="activeClass"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          color: "balck",
                          fontWeight: "600",
                          paddingBottom: "30px",
                          fontSize: "16px",
                        }}
                      >
                        No Data Found
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Card>
          </Container>

          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </>
  );
};
export default QuizUpload;
