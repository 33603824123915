import { Box } from "@mui/system";
import React from "react";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";

const UserInitialQuiz = ({ datas, column }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#d8d8d8",
            color: "#02215b",
            fontSize: "16px",
            fontWeight: "bold",
          },
        }}
      >
        <DataTable
          rows={datas}
          className="datatable-style-quiz"
          columns={column}
          hideFooter={true}
          hideFooterSelectedRowCount={true}
          hideFooterPagination={true}
          autoHeight
          rowHeight={50}
        />
      </Box>

      <div style={{ marginLeft: "10px" }}>
        <p>Note:</p>
        <p>1. Please make sure your internet connection is stable.</p>
        <p>
          2. Quiz will be automatically submitted for any browser actions / when
          it's timed out.
        </p>
      </div>
    </>
  );
};

export default UserInitialQuiz;
