import React from "react";
import Typography from "@mui/material/Typography";
import { Modal } from "@mui/material";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  borderRadius: 2.5,
  boxShadow: 24,
  p: 4,
};
const DeletePopUp = (props) => {
  const { deleteModal, deleteModalClose, HandleDeleteUnit } = props;

  return (
    <Modal
      open={deleteModal}
      onClose={deleteModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ marginTop: "3%" }} id="modal-modal-description">
          Changes made will be lost. Do you want to proceed?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            buttonName="No"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={deleteModalClose}
          />
          <ReusableButton
            size="large"
            variant="contained"
            buttonName="Yes"
            style={{
              backgroundColor: "#fb2485",
              marginRight: "10px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 28px",
              borderRadius: "4px",
              textTransform: "none",
            }}
            onClick={HandleDeleteUnit}
          ></ReusableButton>
        </div>
      </Box>
    </Modal>
  );
};
export default React.memo(DeletePopUp);
