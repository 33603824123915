import React, { useEffect, useState } from "react";
import { Grid, Box, Button, DialogContentText } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReusableButton from "../../../../../components/reusable-button/reusable-button";
// import { getMandateQuizDataHelper } from "../../../../../helper/mandatoryHelper";
import { getMandateQuizDataHelper } from "../../../../../helper/mandatoryHelper";
import { Await, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { ValidateMandeQuizAns } from "../../../../../helper/mandatoryHelper";
import { SubmitMandateQuiz } from "../../../../../helper/mandatoryHelper";
import Notification from "../../../../../components/Custom/Notification";
import quizBackground from "../../../../../assets/Images/quizBackground.png";
import { SubmitTrainingModule } from "../../../../../helper/mandatoryHelper";
import { API } from "../../../../../Networking/API";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CompleteUnit from "../../../../../assets/Images/completeUnit.png";
import { useNavigate } from "react-router-dom";

export default function MandatoryQuizQusetions(props) {
  const {
    QuizData,
    MandatoryId,
    setMandatoryTraining,
    setIsQuizEnabled,
    currentSelectedModule,
    // mandatetrainingData,
    mandatoryData,
    traningId,
    ClearLocalStorage,
    userUnitTrackId,
    selected,
    setSelected,
    isQuizEnabled,
    setVideoLink,
    setParentVideoId,
    setCurrentIndex,
    setSelectedTraningModule,
    setisQuizStarted,
    mandatetrainingData,
    MandatoryTraining,
    trainingMaterial,
    finalQuizEnable,
    setIsFinalQuizEnabled,
    handleSetFinalQuiz,
    setMakeIt,
    onPageRequest,
  } = props;

  const [optionsOpted, setOptionsOpted] = useState([]);
  let [overAllQuizData, setoverallQuizData] = useState([]);
  let [Quiz, setQuiz] = useState([]);
  const [value, setValue] = useState();
  const [answered, setAnswered] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [quizLoader, setQuizLoader] = useState(true);
  const [showAns, setShowAns] = useState(false);
  const [AnsValue, setAnsValue] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [completedQuizQuestion, setCompletedQuizQuestion] = useState();
  const [isFinalSubmission, setIsFinalSubmission] = useState(true);
  const [requiredPercentage, setRequiredPercentage] = useState();
  const [achievedPercentage, setAchievedPercentage] = useState();
  const [totalAttempts, setTotalAttempts] = useState();
  const [topicName, setTopicName] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [sucesspopup, setSucesspopup] = useState();
  const [showCompletePopUp, setShowCompletePopup] = useState(false);
  // const [isFinalSubmission, setIsFinalSubmission] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const selectedobject = mandatoryData?.training_material?.find(
    (item, index) => item.material_pk === selected
  );
  useEffect(() => {
    if (isQuizEnabled) {
      getMandateQuizDataHelper(
        localStorage.getItem("mandateId"),
        selectedobject?.material_pk,
        setoverallQuizData,
        setQuizLoader,
        setOptionsOpted,
        setNotify,
        setCompletedQuizQuestion
      );
    }
  }, [QuizData]);

  useEffect(() => {
    if (overAllQuizData.length > 0) {
      setQuiz(overAllQuizData[0].QuizQuestions);
    }
  }, [overAllQuizData]);
  const handleChange = (event, index) => {
    setIsNextButtonDisabled(false);
    ValidateMandeQuizAns(
      {
        MaterialId: selectedobject?.material_pk,
        topicId: MandatoryId,
        userPk: overAllQuizData[0].user_quiz_pk,
        userOption: event.target.value,
        questionId: index,
      },
      setShowAns,
      setAnsValue,
      setIsNextButtonDisabled
    );

    const QuestionOptionExist = optionsOpted.some(
      (options) => options.questionPk === index
    );
    if (!QuestionOptionExist) {
      setValue(event.target.value);
      setOptionsOpted([
        ...optionsOpted,
        { questionPk: index, value: event.target.value },
      ]);
      setAnswered([...answered, index]);
    }
  };

  useEffect(() => {
    return () => {};
  }, [location]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // User is switching tabs
        // Display your custom dialog or notification here
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const getOptionValue = (questionIdetify) => {
    let OptionExist = optionsOpted.find(
      (questionAnsData) => questionAnsData.questionPk === questionIdetify
    );
    if (OptionExist) return OptionExist.value;
    else return null;
  };

  const getOptionValueBtn = (questionIdetify, index) => {
    if (index < completedQuizQuestion) return true;
    else if (!isNextButtonDisabled) return false;
    else {
      let OptionExist = optionsOpted.find(
        (questionAnsData) => questionAnsData.questionPk === questionIdetify
      );
      if (OptionExist) return OptionExist.value;
      else return null;
    }
  };

  const handleNextClick = async (event) => {
    setShowAns(false);
    setIsNextButtonDisabled(false);
    if (questionIndex + 1 === Quiz.length) {
      const IsQuizSubmitted = await SubmitMandateQuiz(
        {
          MaterialId: selected,
          topicId: localStorage.getItem("mandateId"),
          userPk: overAllQuizData[0].user_quiz_pk,
          userTrack: window.localStorage.getItem("userUnitTrackId"),
          quizPk: overAllQuizData[0].quizPk,
        },
        QuizData,
        setMandatoryTraining,
        setIsQuizEnabled
      );
      localStorage.setItem(
        `VideoQuizSubmitted_${overAllQuizData[0].quizPk}`,
        true
      );
      setMakeIt(true);
      if (IsQuizSubmitted === true) {
        const SubmitedTrainingStataus = await SubmitTrainingModule({
          current_training_materials_id: selectedobject?.material_pk
            ? selectedobject?.material_pk
            : selected,
          unit_id: MandatoryId,
          user_unit_track_id: window.localStorage.getItem("userUnitTrackId"),
          completed_training_materials_id: selected,
        });

        if (SubmitedTrainingStataus) {
          const currentIndex = trainingMaterial.findIndex(
            (obj) => obj.material_pk === selected
          );

          if (
            trainingMaterial.length === currentIndex + 1 &&
            mandatoryData.finalQuiz
          ) {
            handleSetFinalQuiz();
          }

          if (
            currentIndex !== -1 &&
            currentIndex + 1 <= trainingMaterial.length
          ) {
            const nextObject = trainingMaterial[currentIndex + 1];

            if (nextObject) {
              setSelectedTraningModule(nextObject);
              setVideoLink(nextObject.video_link);
              setCurrentIndex(currentIndex + 1);
              setSelected(nextObject.material_pk);
              setParentVideoId(nextObject.material_pk);
              setIsQuizEnabled(false);
              setisQuizStarted(false);
              setIsFinalSubmission(true);
              // onPageRequest(currentIndex + 1);
            } else {
              // getIsFinalSubmission(0, true);
              setIsFinalSubmission(false);
            }
          }
        }
      }
    } else {
      setQuestionIndex((prev) => {
        return prev + 1;
      });
    }
  };

  const UserUnitTrackId = window.localStorage.getItem("userUnitTrackId");

  const handleFinalClick = async (event) => {
    const NoFinalQuiztrack = await API.MandatoryTrainingMaterialTrack(
      traningId,
      UserUnitTrackId
    );

    if (NoFinalQuiztrack.response.api_status === 400) {
      setSucesspopup("");
      setSucesspopup(1);
      setShowCompletePopup(true);
      setTotalAttempts(NoFinalQuiztrack.response.data.no_of_attempts);
      // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
      setRequiredPercentage(NoFinalQuiztrack.response.data.required_percentage);
    } else if (NoFinalQuiztrack.response.api_status === 200) {
      setSucesspopup("");
      setSucesspopup(1);
      setShowCompletePopup(true);
      setTotalAttempts(NoFinalQuiztrack.response.data.no_of_attempts);
      // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
      setRequiredPercentage(NoFinalQuiztrack.response.data.required_percentage);
    }
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleCloseCompletePopup = () => {
    setShowCompletePopup(false);
    setSucesspopup(0); 
    navigate(`/user`);
    ClearLocalStorage();
    localStorage.clear();
  };

  const handleCompleteUnit = () => {
    setShowCompletePopup(false);
    navigate(`/user/mandatoryTraining/${userUnitTrackId}`);
    ClearLocalStorage();
    localStorage.clear();
  };

  const checkIfDiabled = (AnsPrimaryKey) => {
    const QuestionOptionExist = optionsOpted.some(
      (options) => options.questionPk === AnsPrimaryKey
    );
    if (QuestionOptionExist) {
      return true;
    } else return false;
  };

  // const getIsFinalSubmission = (index, isTrue) => {
  //   if (!isTrue) {
  //     if (!isFinalSubmission) return true;
  //     else if (index === Quiz.length - 1) return false;
  //     else return true;
  //   } else return false;
  // };

  return (
    <>
      {quizLoader ? (
        <Box
          style={{
            // height: "34vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={90}
            width={100}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      ) : (
        <>
          {Quiz.map((data, index) => {
            const questionname = data.question;
            data.options.selectedOption = data.userOption;
            data.options.questPk = data.question_pk;

            if (index === questionIndex) {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "white",
                      padding: "0px !important",
                      height: "auto",
                      borderBottomLeftRadius: "10px",
                      marginTop: "0px",
                      marginLeft: "20px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundImage: `url(${quizBackground})`,
                        // backgroundSize: "cover",
                        // backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        // width: "100%",
                        backgroundSize: "cover",
                        width: "100%",
                        height: "69vh",
                        overflowY: "scroll",
                        // height: "62vh",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid xs={2}></Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: "4%",
                            marginLeft: "4%",
                          }}
                        >
                          <b>
                            {" "}
                            {index + 1}.{questionname}{" "}
                          </b>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            marginTop: "4%",
                            marginRight: "2%",
                          }}
                        >
                          <b>{`${index + 1}/${Quiz.length}`}</b>
                        </Grid>
                      </Grid>

                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={getOptionValue(data.question_pk)}
                        onChange={(e) => {
                          handleChange(e, data.question_pk);
                        }}
                        disabled={checkIfDiabled(data.question_pk)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        {[data.options].map((opt, index) => {
                          return (
                            <>
                              <Box
                                style={{ marginLeft: "12%", height: "48vh" }}
                              >
                                {opt.a ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.a.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.a.a}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.b ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.b.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.b.b}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.c ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.c.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.c.c}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "0px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.d ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.d.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.d.d}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "0px" }}
                                    ></Grid>
                                  </>
                                )}
                                {showAns ? (
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginTop: "20px",
                                      marginLeft: "8.5%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: "#02215B",
                                        padding: "8px 40px",
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#FFFFFF",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        lineHeight: "23px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {AnsValue}
                                    </span>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </>
                          );
                        })}
                      </RadioGroup>
                    </Box>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid item xs={1}>
                        {
                          // !isFinalSubmission && (index !== (Quiz.length - 1))
                          isFinalSubmission ? (
                            <ReusableButton
                              size="large"
                              variant="contained"
                              className="candidateDltepageDeleteBttn"
                              buttonName="Next"
                              disabled={
                                getOptionValueBtn(data.question_pk, index)
                                  ? false
                                  : true
                              }
                              style={{
                                backgroundColor: getOptionValueBtn(
                                  data.question_pk,
                                  index
                                )
                                  ? "#fb2485"
                                  : "grey",
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "white",
                                height: "35px",
                                //backgroundColor: "#FB2485",
                                borderRadius: "4px",
                                marginLeft: "-13px",
                                textTransform: "none",
                              }}
                              onClick={(e) => {
                                handleNextClick();
                              }}
                            ></ReusableButton>
                          ) : (
                            <>
                              <ReusableButton
                                size="large"
                                variant="contained"
                                className="candidateDltepageDeleteBttn"
                                buttonName="Finish"
                                // disabled={
                                //   getOptionValueBtn(data.question_pk, index)
                                //     ? false
                                //     : true
                                // }
                                style={{
                                  backgroundColor:
                                    // : getOptionValueBtn(
                                    //   data.question_pk,
                                    //   index
                                    // )
                                    "#fb2485",
                                  // : "grey",
                                  width: "98px",
                                  marginLeft: "-23px",
                                  height: "44px",
                                  flexGrow: 0,
                                  padding: "13px 32px 13px 33px",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "8px 28px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                }}
                                onClick={(e) => {
                                  handleFinalClick();
                                }}
                              ></ReusableButton>
                            </>
                          )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            }
          })}
        </>
      )}
      <Dialog
        open={sucesspopup}
        className="dialogBoxs"
        keepMounted
        onClose={handleCloseCompletePopup}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "29px",
                color: "#000000",
                margin: "12px 0px 0px 0px",
                textAlign: "center",
              }}
            >
              You have successfully completed training
            </h2>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            className="candidateDltepageCancelBttn"
            buttonName="Close"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCloseCompletePopup}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
