import React from "react";
import { Box } from "@mui/material";
import "./trainer-sessions-details-assessed.css";
import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";
export const TrainerSessionsDetailsQuiz = ({ quizData, quiz }) => {
  const [value, setValue] = React.useState("1");

  return (
    <>
      <Box style={{ marginTop: "20px" }}>
        <Box style={{ display: "flex" }}>
          <span className="assignment-text">Quiz : {quiz[0].topic}</span>
        </Box>
{/* 
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginTop: "4px",
          }}
        >
          <span>
            <b>Start Time :</b> {quiz[0].start_time}
          </span>
          <span>
            <b>End Time :</b> {quiz[0].end_time}
          </span>
        </Box> */}

        {quizData !== undefined ? (
          <CustomDataGrid
            rowData={{
              totalquest: quizData.quizzes[0].total_qns,
              correctquest: quizData.quizzes[0].correct_ans,
              percentagescored: `${quizData.quizzes[0].percentage}%`,
              attempts: quizData.quizzes[0].attempts,
            }}
            rowQuestionsData={{
              quizQuestions: quizData.questions,
            }}
            rowTypes={[
              "Number Questions",
              "Correct Answers",
              "Percentage",
              "Attempts",
            ]}
          />
        ) : null}
      </Box>
    </>
  );
};
