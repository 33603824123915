import { Typography } from "@material-ui/core";
import React from "react";
import { Box, Grid } from "@mui/material";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { Custom } from "../../../components/Custom/Custom";
const Dashboard = () => {
  // const [batches, setBatches] = useState(["Hyderabad"]);
  // const handleChange = (event) => {
  //   // setAge(event.target.value);
  // };
  const getScheduler = [
    { id: "1", batch_name: "Hyderabad" },
    { id: "2", batch_name: "Chennai" },
    { id: "3", batch_name: "pondicherry" },
  ];
  return (
    <>
      <Box
        style={{ marginTop: "20px", backgroundColor: "white", padding: "20px",marginBottom:"60px" }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "20px", width: "18.9%" }}>
             <Custom.BatchDropdown
                    name="Select Schedular Type"
                    label="Select Schedular Type"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
                  
          </div>
          <div style={{ marginRight: "20px", width: "18.9%" }}>
            <Custom.BatchDropdown
                    name="Select Session Type"
                    label="Select Session Type"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
          <div style={{ marginRight: "20px", width: "18.9%" }}>
            <Custom.BatchDropdown
                    name="Select Start Date"
                    label="Select Start Date"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
          <div style={{ marginRight: "20px", width: "18.9%" }}>
            <Custom.BatchDropdown
                    name="Select End Date"
                    label="Select End Date"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
          <div style={{ width: "18%" }}>
            <Custom.BatchDropdown
                    name="Select Time"
                    label="Select Time"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            OverView Dashboard
          </Typography>
          <div
            style={{ marginRight: "20px", width: "18%", marginBottom: "20px" }}
          >
            <Custom.BatchDropdown
                    name="Select Source"
                    label="Select Source"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
          <Box
            style={{
              height: "300px",
              width: "99%",
              backgroundColor: "#EFEFEF",
            }}
          ></Box>
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <div
            style={{ marginRight: "20px", width: "18%", marginBottom: "10px" }}
          >
            <Custom.BatchDropdown
                    name="Select Source"
                    label="Select Source"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
          </div>
          <Box
            style={{
              height: "200px",
              width: "99%",
              backgroundColor: "#EFEFEF",
            }}
          ></Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <div style={{ width: "38%",marginTop: "20px" }}>
              <Custom.BatchDropdown
                    name="Select Source"
                    label="Select Source"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
            </div>
            <Box
            style={{
                marginTop:"10px",
              height: "200px",
              width: "99%",
              backgroundColor: "#EFEFEF",
              marginBottom:"50px"
            }}
          ></Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <div style={{ width: "38%",marginTop: "20px" }}>
              <Custom.BatchDropdown
                    name="Select Source"
                    label="Select Source"
                    // value={filter}
                    // onChange={handleInputChangeFilter}
                    options={getScheduler}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 37,
                      fontFamily: "Poppins",
                    }}
              ></Custom.BatchDropdown>
            </div>
            <Box
            style={{
                marginTop:"10px",
              height: "200px",
              width: "99%",
              backgroundColor: "#EFEFEF",
              marginBottom:"50px"
            }}
          ></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Dashboard;
