/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MyForm, Form } from "../../../components/MyForm";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Tooltip from "@mui/material/Tooltip";
import Notification from "../../../components/Custom/Notification";
import Cookies from "js-cookie";
import TimePicker from "react-time-picker";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import "./hackathon-upload.css";

import API from "../../../../src/api/index";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const api1 = API.Api1;

const getDepartment = [
  { id: "1", title: "Task 1" },
  { id: "2", title: "Task 2" },
  { id: "3", title: "Task 3" },
  { id: "4", title: "Task 4" },
  { id: "5", title: "Bonus Task" },
];

const initialValues = {
  department: "",
  dateOfJoin: new Date(),
  dateOfJoin01: new Date(),
  fromTimeOfQuiz: "",
  toTimeOfQuiz: "",
  numberOfQuestions: "",
  quizDuration: "",
  percentage: "",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SheetJSFT = ["docx"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

export default function AddTaskPopup(props) {
  const { open, id, handleClose } = props;
  const [showValues, setShowValues] = useState(false);
  const [fileMerge, setFileMerge] = useState("");
  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [fromtime, setFromTime] = useState("10:00");
  const [totime, setToTime] = useState("10:00");
  const [showPre, setShowPre] = useState(false);
  const [schedID, setSchedID] = useState();
  const [available, setAvailable] = useState(true);
  const [countValueDD, setCountValueDD] = useState("");
  const [departmentApiDD, setDepartmentApiDD] = useState([]);
  const [filter, setFilter] = useState("");
  const handleInputChangeDrop = (e) => {
    setFilter(e.target.value);
  };

  const handleChange = (files) => {
    setFileMerge(files.target.files[0]);
    setShowValues(true);
  };

  const onFromTimeChange = (e) => {
    setFromTime(e);
  };

  const onToTimeChange = (e) => {
    setToTime(e);
  };

  const jwtToken = Cookies.get("jwt_token");

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0 ? "" : "Quiz is required";
    if ("numberOfQuestions" in inputValues)
      code.numberOfQuestions =
        (inputValues.numberOfQuestions ? "" : "No Of Questions is required") ||
        (inputValues.numberOfQuestions <= countValueDD
          ? ""
          : "Selected Questions should be less than max questions ");
    if ("quizDuration" in inputValues)
      code.quizDuration = inputValues.quizDuration
        ? ""
        : "Quiz Duration is required";
    if ("percentage" in inputValues)
      code.percentage = inputValues.percentage ? "" : "Percentage is required";

    setErrors({
      ...code,
    });
    if (inputValues === values) {
      return Object.values(code).every((x) => x === "");
    }
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);
  useEffect(() => {
    getScheduleById(id);
    setTableData([]);
  }, [id, open]);
  useEffect(() => {
    if (departmentApiDD.length > 0) {
      const newArray = departmentApiDD.filter((element) => {
        return element.title === values.department;
      });
      if (newArray.length > 0) {
        setCountValueDD(newArray[0].count);
      }
    }
  }, [values]);

  const onClickAddNewOuter = () => {
    resetForm();
    setShowPre(true);
  };

  let array = [];

  const getScheduleById = async (id) => {
    setAvailable(true);
    await axios({
      method: "get",
      url: `${api1}/admin/add-task-sets/${id}`,
      headers: { token: `asdfghjk`, "aa-token": jwtToken },
    })
      .then(async function  (response) {
        if (response.data.api_status === 200) {
          setSchedID(response.data.data[0].tasks[0].hackathon_id);
          const taskdata = response.data.data[0].tasks;
          const updatedData = await DataTableHandler.AdminAddTask(taskdata);
          setTableData(updatedData);
        }
        if (response.data.api_status === 404) {
          setAvailable(false);
          setSchedID(response.data.data[0].hackathon[0].schedule_pk);
        }
      })
      .catch(function (error) {});
  };

  const handleCancelPopUp = () => {
    resetForm();
    setShowPre(false);
  };
  const handleClosePopUp = () => {
    setTableData([]);
    setShowPre(false);
    resetForm();
    handleClose();
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (validate()) {
      const date = convert(values.dateOfJoin);
      const date01 = convert(values.dateOfJoin01);

      var fd = new FormData();
      fd.append("task", filter);
      fd.append("file", fileMerge);
      fd.append("hackathon_id", schedID);
      fd.append("date", date);
      fd.append("end_date", date01);
      fd.append("from_time", fromtime);
      fd.append("end_time", totime);
      for (var pair of fd.entries()) {
      }
      const requestOptions = {
        method: "POST",
        body: fd,
        headers: { token: `asdfghjk`, "aa-token": jwtToken },
      };
      try {
        const response = await fetch(
          `${api1}/admin/add-task-sets`,
          requestOptions
        );
        const resultInJson = await response.json();
        if (resultInJson.api_status === 200) {
          getScheduleById(id);
          setShowPre(false);
          setNotify({
            isOpen: true,
            message: "Uploaded successfully",
            type: "success",
          });
        }

        if (resultInJson.api_status === 404) {
          getScheduleById(id);
          setShowPre(false);
          setNotify({
            isOpen: true,
            message: "Please attach file while uploading",
            type: "error",
          });
        }
      } catch (e) {
        
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 20,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "taskName",
      headerName: "Task",
      // flex: 1,
      width: 110,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "quizFromTime",
      headerName: "From Time",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "quizToTime",
      headerName: "To Time",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Resources",
      headerName: "Resources",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <span>
            {Object.entries(params.row.Resources).map((data) => {
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <a
                    href={data[1].resource_link}
                    target="_blank"
                    style={{ color: "grayText" }}
                  >
                    {data[1].set_name}
                  </a>
                </div>
              );
            })}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <Tooltip title="Add Task">
              <IconButton aria-label="edit" size="small">
                <EditIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          </span>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <Dialog
          open={open}
          className="dialogclasstask"
          style={{
            padding: "16px",
            overflowY: "hidden !important",
            minWidth: "400px",
            borderRadius: "12px",
          }}
        >
          <Grid container>
            {showPre !== true ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "start",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>Tasks :</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <ReusableButton
                      onClick={() => onClickAddNewOuter()}
                      size="small"
                      variant="contained"
                      buttonName="Add New "
                      style={{
                        backgroundColor: "#fb2485",
                        textTransform: "none",
                        borderRadius: 4,
                      }}
                    ></ReusableButton>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <IconButton aria-label="delete">
                      <CloseIcon onClick={handleClosePopUp} />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {showPre !== true ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#f1f1f1",
                    color: "#02215b",
                    fontSize: "16px",
                    //   minWidth:'400px',
                    padding: "16px",
                  },
                }}
                className="dialog-container2"
              >
                {available ? (
                  <>
                    <DataTable
                      rows={tableData}
                      columns={columns}
                      rowHeight={40}
                      autoHeight
                      pageSize={10}
                      // hideFooter={true}
                      // hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                      className="assigntable"
                    />
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "0px !important",
                        height: "30vh",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "15px",
                        }}
                      >
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "25px",
                            }}
                          >
                            <b>Tasks Not Available</b>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <b>Click Add New to add Task. </b>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          ></Grid>
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Form
              //onSubmit={() => onSubmitEdit(id)}
              >
                <DialogTitle>
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Grid item xs={6}>
                      Add New Task
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <IconButton aria-label="delete">
                        <CloseIcon onClick={handleClosePopUp} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                          >
                            Select Task
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <Custom.Dropdown
                              name="sessions"
                              label="Tasks"
                              value={filter}
                              onChange={handleInputChangeDrop}
                              options={getDepartment}
                              // error={errors.department}
                              size="small"
                              style={{ height: "44px", width: "258px" }}
                            ></Custom.Dropdown>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "0px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                              marginLeft: "-7px",
                            }}
                            variant="h6"
                          >
                            Upload Parameters
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <input
                              type="file"
                              name="source"
                              class="upload-box"
                              accept={SheetJSFT}
                              multiple
                              style={{
                                marginTop: "8px",
                                fontSize: "0.8em",
                                fontFamily: "Poppins",
                              }}
                              onChange={handleChange}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                          >
                            Please make sure to upload the task files with
                            respect to the task selected.
                            <br />
                            For Ex: For Task 1, The files selected should be
                            Task 1.1, Task 1.2 and so on.. , Not Task 2.3
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}></span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginLeft: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#000",
                                marginTop: "20px",
                              }}
                              variant="h6"
                            >
                              Upload Tasks
                            </Typography>
                          </div>
                          <span style={{ marginLeft: "0px" }}>
                            <input
                              type="file"
                              name="source"
                              class="upload-box-task"
                              accept={SheetJSFT}
                              multiple
                              style={{
                                marginTop: "8px",
                                fontSize: "0.8em",
                                fontFamily: "Poppins",
                              }}
                              onChange={handleChange}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            marginLeft: "-7px",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#000",
                                marginTop: "20px",
                              }}
                              variant="h6"
                            >
                              Marks
                            </Typography>
                          </div>
                          <span style={{ marginLeft: "-7px", width: "310px" }}>
                            <Custom.Input
                              type={"number"}
                              size="small"
                              name="marks"
                              label="Number"
                              // value={values.phone}
                              error={errors.marks}
                              onChange={handleInputChange}
                              InputProps={{ inputProps: { max: 30 } }}
                            />
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={5}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          <Typography
                            style={{
                              // marginLeft: 10,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                            variant="h6"
                          >
                            From Date:
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <Custom.DatePicker
                              name="dateOfJoin"
                              className="common"
                              value={values.dateOfJoin}
                              onChange={handleInputChange}
                              size="small"
                              sx={{ width: "245px !important" }}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      {/* <Grid item xs={}></Grid> */}
                      <Grid item xs={5} style={{}}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "25px",
                            marginLeft: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              marginLeft: -10,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                            variant="h6"
                          >
                            From Time:
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <TimePicker
                              name="fromTimeOfQuiz"
                              onChange={onFromTimeChange}
                              value={fromtime}
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          <Typography
                            style={{
                              // marginLeft: 10,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                            variant="h6"
                          >
                            To Date:
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <Custom.DatePicker
                              name="dateOfJoin01"
                              className="common"
                              value={values.dateOfJoin01}
                              onChange={handleInputChange}
                              size="small"
                              sx={{ width: "245px !important" }}
                            />
                            {/* <TimePicker name="fromTimeOfQuiz" onChange={onFromTimeChange} value={fromtime} /> */}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "25px",
                            marginLeft: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              marginLeft: -10,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                            }}
                            variant="h6"
                          >
                            To Time:
                          </Typography>
                          <span style={{ marginLeft: "-7px" }}>
                            <TimePicker
                              name="toTimeOfQuiz"
                              onChange={onToTimeChange}
                              value={totime}
                            />
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <ReusableButton
                    onClick={handleCancelPopUp}
                    size="large"
                    variant="outlined"
                    buttonName="Cancel"
                    style={{
                      marginRight: "10px",
                      color: "#000936",
                      border: "1px solid #000936",
                      marginBottom: "40px",
                      width: "98px",
                      height: "38px",
                      borderRadius: 4,
                    }}
                  >
                    Cancel
                  </ReusableButton>
                  <ReusableButton
                    //  onClick={handleClose}
                    //type="submit"
                    //   onClick={() => onSubmitEdit(id)}
                    onClick={(e) => handleSubmit(e, id)}
                    size="large"
                    variant="contained"
                    buttonName="Add"
                    style={{
                      backgroundColor: "#fb2485",
                      marginRight: "100px",
                      marginBottom: "40px",
                      width: "98px",
                      height: "38px",
                      borderRadius: 4,
                    }}
                  >
                    Add
                  </ReusableButton>
                </DialogActions>
              </Form>
            </>
          )}

          {/* </Grid> */}
        </Dialog>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
