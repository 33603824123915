import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { useState, useEffect } from "react";
// import TrainingMaterialList from "./user-training-material-list";
// import MaterialView from "./user-training-materialview";
import PdfContentExtractor from "../user-training-materialview";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router-dom";
import Mandatetraininglist from "./mandate-training-list";
// import { useNavigate } from "react-router-dom";
// import { API } from "../../../Networking/API";
// import Mandatetraininglist from "./mandate-training-list";

const Mandatorytrainingmaterial = (props) => {
  const { materialData } = props;
  const [selected, setSelected] = React.useState(0);

  const selectedObject = materialData.find((item, index) => index === selected);

  let navigate = useNavigate();
  const checkBoxData = materialData?.map((item, index) => {
    return {
      id: index,
      name: item.material_name,
      duration: item.duration,
      pk: item.material_pk,
      isCompleted: item.isCompleted,
    };
  });
  const links = materialData.find((item, index) => index === selected);
  
  return (
    <div>
      <Box sx={{ height: "70vh" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid item xs={3}>
            {checkBoxData.length > 0 ? (
              <Mandatetraininglist
                list={checkBoxData}
                selected={selected}
                setSelected={setSelected}
                //   unitId={unitId}
                //   courseId={courseId}
                materialData={materialData}
              />
            ) : null}
          </Grid>
          <div style={{ borderLeft: "1px solid #F0F0F0" }}></div>
          <Grid item xs={8}>
            <PdfContentExtractor link={links} />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
            item
            xs={9}
          >
            <ReusableButton
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#000936",
                border: "1px solid #000936",
                borderRadius: "4px",
                height: "35px",
                marginRight: "8px",
                textTransform: "none",
              }}
              //   onClick={handleCancelButton}
            />
            <Box sx={{ display: "flex", float: "right", marginRight: "10%" }}>
              {selected !== 0 ? (
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName="Back"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "white",
                    height: "35px",
                    backgroundColor: "#02215B",
                    borderRadius: "4px",
                    marginRight: "8px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setSelected(selected - 1);
                    // postTrainingMaterialTrack();
                  }}
                />
              ) : null}
              {materialData?.length > 1 ? (
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName="Next"
                  disabled={!selectedObject.isCompleted}
                  style={{
                    fontSize: "16px",
                    color: "white",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    height: "35px",
                    letterSpacing: "normal",
                    textAalign: "center",
                    backgroundColor: "#FB2485",
                    borderRadius: "4px",
                    marginRight: "8px",
                    textTransform: "none",
                    border: "none",
                  }}
                  onClick={() => {
                    setSelected(selected + 1);
                    // postTrainingMaterialTrack();
                  }}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Mandatorytrainingmaterial;
