import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PinkFilled from "../../../assets/Images/checkPink.svg";
import PinkUnfilled from "../../../assets/Images/pinkunfilled.svg";
import TickWhite from "../../../assets/Images/tickwhite.svg";
import ReactLoading from "react-loading";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment/moment";
import CheckGrey from "../../../assets/Images/checkgrey.svg";
import {
  preRequisiteCompletion,
  trainingMaterialCompletion,
} from "../../../helper/userHelper";
import completedCheck from "../../../assets/Images/completedCheck.svg";
import uniticonuncheck from "../../../assets/Images/uniticonuncheck.png";
import { API } from "../../../Networking/API";

export default function TrainingMaterialList({
  list,
  selected,
  setSelected,
  unitId,
  courseId,
  trainingMaterial,
  getUnitDetails,
  videoTotalTime,
  currentVideoTime,
  setIsVideoQuizEnabled,
  isYoutubeVideo,
  selectedTrainingMaterial,
  isVideoQuizEnabled,
  setCurrentVideoTime,
  setVideoTotalTime,
  // selectedTrainingModule,
}) {
  const [loading, setLoading] = React.useState(false);
  const [totalSeconds, setTotalSeconds] = React.useState();
  const [currentSeconds, setCurrentSeconds] = React.useState();
  const [percentage, setPercentage] = React.useState();
  const [iscompleted, setIsCompelted] = React.useState();
  const selectedObject = list.find((x) => x.id === selected);
  React.useEffect(() => {
    if (list.length <= 0) {
      setLoading(true);
    } else {
      if (
        localStorage.getItem(
          "currentTrainingSeconds" + courseId + selectedObject.pk
        )
      ) {
        setCurrentSeconds(
          parseInt(
            localStorage.getItem(
              "currentTrainingSeconds" + courseId + selectedObject.pk
            )
          )
        );
        setTotalSeconds(selectedObject?.duration * 60);
      } else {
        setTotalSeconds(selectedObject?.duration * 60);
        setCurrentSeconds(selectedObject?.duration * 60);
      }
      setLoading(false);
    }
  }, [list]);

  React.useEffect(() => {
    const isCompleted =
      trainingMaterial.length > 1
        ? trainingMaterial.find((item, index) => index === selected)
        : trainingMaterial[0];

    if (!isCompleted?.isCompleted && isCompleted.content_type !== "video/mp4") {
      const Item = localStorage.getItem(
        "currentTrainingSeconds" + courseId + selectedObject.pk
      );
      totalSeconds > 0 &&
        currentSeconds > 0 &&
        setTimeout(() => setCurrentSeconds(currentSeconds - 1), 600);
      setPercentage(((totalSeconds - currentSeconds) / totalSeconds) * 1000);
      if (percentage === 100 && Item) {
        const next = trainingMaterial?.find(
          (item, index) => index === selected + 1
        );
        const completed = trainingMaterial?.find(
          (item, index) => index === selected
        );
        if (!isCompleted?.isCompleted) {
          trainingMaterialCompletion(
            next ? next.duration : completed.duration,
            next ? next.material_pk : completed.material_pk,
            unitId,
            courseId,
            completed?.material_pk,
            getUnitDetails
          );
        }
        localStorage.removeItem(
          "currentTrainingSeconds" + courseId + selectedObject.pk
        );
        setPercentage(0);
      }
    } else if (
      selectedTrainingMaterial.trainigMaterialQuiz !== null &&
      isCompleted.content_type === "video/mp4"
    ) {
      let percentage = (currentVideoTime * 100) / videoTotalTime;
      const Item = localStorage.getItem(
        "currentTrainingSeconds" + courseId + selectedObject.pk
      );
      
      // if (percentage) {
      if (currentSeconds !== null && videoTotalTime !== null)
        setIsVideoQuizEnabled(false);
      // }
      setPercentage(percentage);
      // 
      if (percentage === 100 && Item) {
        const next = trainingMaterial?.find(
          (item, index) => index === selected + 1
        );
        const completed = trainingMaterial?.find(
          (item, index) => index === selected
        );
        if (!isCompleted?.isCompleted) {
          trainingMaterialCompletion(
            next ? next.duration : completed.duration,
            next ? next.material_pk : completed.material_pk,
            unitId,
            courseId,
            completed?.material_pk,
            getUnitDetails
          );
        }
        localStorage.removeItem(
          "currentTrainingSeconds" + courseId + selectedObject.pk
        );
        setIsVideoQuizEnabled(true);
        setCurrentVideoTime(null);
        setVideoTotalTime(null);
        setPercentage(0);
      }
      // setIsVideoQuizEnabled(true);
    } else if (
      !isCompleted?.isCompleted &&
      isCompleted.content_type === "video/mp4"
    ) {
      let percentage = (currentVideoTime * 100) / videoTotalTime;

      setPercentage(percentage);
      const Item = localStorage.getItem(
        "currentTrainingSeconds" + courseId + selectedObject.pk
      );
      
      if (percentage === 100 && Item !== null) {
        const next = trainingMaterial?.find(
          (item, index) => index === selected + 1
        );
        const completed = trainingMaterial?.find(
          (item, index) => index === selected
        );
        if (!isCompleted?.isCompleted) {
          trainingMaterialCompletion(
            next ? next.duration : completed.duration,
            next ? next.material_pk : completed.material_pk,
            unitId,
            courseId,
            completed?.material_pk,
            getUnitDetails
          );
        }
        localStorage.removeItem(
          "currentTrainingSeconds" + courseId + selectedObject.pk
        );
        setPercentage(0);
      }
    }
  }, [
    courseId,
    currentSeconds,
    list,
    list.length,
    percentage,
    totalSeconds,
    unitId,
  ]);

  // 

  React.useEffect(() => {
    
    if (currentSeconds !== undefined) {
      localStorage.setItem(
        "currentTrainingSeconds" + courseId + selectedObject.pk,
        currentSeconds || 0
      );
    }
  }, [currentSeconds, selectedObject]);

  React.useEffect(() => {
    const isCurrent = list.findIndex((x) => x.isCurrent === true);
    if (!loading && isCurrent > 0) {
      setSelected(isCurrent);
    }
  }, [loading]);

  const handleToggle = (value) => () => {
    setSelected(value.id);
    localStorage.setItem("currentTrainingSeconds" + courseId + value.pk, 0);
  };

  let previousRecords = [];
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {loading ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {list.map((value, index) => {
            previousRecords.push({
              isCompleted: value.isCompleted,
            });
            let _visible =
              index === 0
                ? true
                : isVideoQuizEnabled && !previousRecords[index].isCompleted
                ? false
                : index > 0 && previousRecords[index - 1].isCompleted
                ? true
                : false;
            
            const timer = list[selected];

            return (
              <>
                <ListItem
                  key={value}
                  style={{
                    width: "20vw",
                    paddingRight: "2%",
                    padding: 0,
                    margin: 0,
                    backgroundColor: selected === value.id ? "#02215B" : "#fff",
                    color: selected === value.id ? "#fff" : "#000",
                    borderBottom: "1.5px solid #F3F3F3",
                    // cursor: _visible
                    //   ? "pointer !important"
                    //   : "default !important",
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    disableRipple={true}
                    onClick={_visible ? handleToggle(value) : null}
                    sx={{
                      background: _visible ? "" : "#fff",
                    }}
                    dense
                  >
                    <ListItemIcon
                      style={{ minWidth: "30px", position: "relative" }}
                    >
                      {selected === value.id && !value.isCompleted ? (
                        <>
                          <CircularProgress
                            variant="determinate"
                            sx={{ color: "#FB2485" }}
                            thickness={3}
                            size={25}
                            value={percentage}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={5}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <img
                              src={TickWhite}
                              alt=""
                              style={{ zindex: 1000 }}
                            />
                          </Box>
                        </>
                      ) : (
                        <img
                          src={
                            selected === value.id
                              ? PinkFilled
                              : _visible
                              ? completedCheck
                              : uniticonuncheck
                          }
                          alt=""
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText id={value.id}> {value.name} </ListItemText>
                  </ListItemButton>
                </ListItem>
              </>
            );
          })}
        </>
      )}
    </List>
  );
}
