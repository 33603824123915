import * as React from "react";
import { Box, Paper, Button } from "@mui/material";
import "./DashBoard.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { Custom } from "../../../components/Custom/Custom";
import { MyForm } from "../../../components/MyForm";
import { makeStyles, MenuList } from "@material-ui/core";
import { courseData, unitsData } from "./MockData";
import BasicCard from "../../../components/Card";
import Typography from "@mui/material/Typography";
import { borderRadius } from "@mui/system";
import Avatar from "@material-ui/core/Avatar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Menu from "@mui/material/Menu";
import AIcon from "../../../assets/Images/AIcon.png";
import MenuItem from "@mui/material/MenuItem";
import { DeletionPopUp } from "./DeletionPopUp";
import Notification from "../../../components/Custom/Notification";

const getDepartment = [
  { id: "1", title: "All" },
  { id: "2", title: "Courses" },
  { id: "3", title: "Units" },
  { id: "4", title: "Programs" },
  { id: "5", title: "Training" },
];
const getCreatdata = [
  { value: "Courses", title: "Course" },
  { value: "Units", title: "Unit/Mandatory" },
  { value: "Programs", title: "Program" },
  //{ value: "Mandatory Training", title: "Mandatory Training" },
];

const styles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    marginBottom: "8%",
  },
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "53vw",
      height: "44px",
    },
  },
  ".MuiInputBase-root": {
    color: "#fff !important",
  },
  label: {
    color: "#fff !important",
    fontSize: "16px",
    marginTop: "-10px",
    fontFamily: "poppins",
    transform: " translate(14px, 20px) scale(1) !important",
    borderColor: "#fb2485 !important",
  },
}));

const initialValues = {
  sessionName: "",
};
export default function DashBoard() {
  const [done, setDone] = useState(false);
  const location = useLocation();
  const [filter, setFilter] = useState("All");
  const [create, setCreate] = useState("");
  const classes = styles();
  const [allUnits, setAllUnits] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [totalCourses, setTotalCourses] = useState(null);
  const [totalUnits, setTotalUnits] = useState(null);
  const [totalPrograms, setTotalPrograms] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberUnits, setPageNumberUnits] = useState(1);
  const [pageNumberPrgm, setPageNumberPrgm] = useState(1);
  const [pageNumberTrain, setPageNumberTrain] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [orgRole, setOrgRole] = useState(null);
  const [pAnchorEl, setPAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isOpen = Boolean(pAnchorEl);
  const [isLoadCourse, setIsLoadCourse] = useState(false);
  const [isLoadUnit, setIsLoadUnit] = useState(false);
  const [isLoadProgram, setIsLoadProgram] = useState(false);
  const [currentDeletionId, setCurrentDeletionId] = useState(0);
  const [type, setType] = useState("");
  const [mandatoryData, setMandatoryData] = useState();
  const [totalMandate, setTotalMandate] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true);

  const handleClose = () => {
    setPAnchorEl(null);
  };

  const handleSearch = () => {
    setIsLoadCourse(true);
    setIsLoadUnit(true);
    var searchWord = values.sessionName.toLowerCase().trim();
    if (searchWord.length !== 0) {
      setSearchKey(searchWord);
      setPageNumber(1);
      setPageNumberUnits(1);
      setPageNumberPrgm(1);
      setPageNumberTrain(1);
      getCompletedData(searchWord, 1);
    } else {
      setSearchKey("");
      setPageNumber(1);
      setPageNumberUnits(1);
      setPageNumberPrgm(1);
      setPageNumberTrain(1);
      getCompletedData("", 1);
    }
  };
  const getCompletedData = (searchWord, pageNum) => {
    getAllUnits(searchWord, pageNum);
    getAllCourses(searchWord, pageNum);
    getAllPrograms(searchWord, pageNum);
    getAllMandatoryTrainings(searchWord, pageNum);
  };

  useEffect(() => {
    if (window.localStorage.getItem("UnitId")) {
      window.localStorage.removeItem("UnitId");
    }
    if (window.localStorage.getItem("courseId")) {
      window.localStorage.removeItem("courseId");
    }

    if (window.localStorage.getItem("programId")) {
      window.localStorage.removeItem("programId");
    }
    if (window.localStorage.getItem("mandateId")) {
      window.localStorage.removeItem("mandateId");
    }
    if (open === false) {
      getAllUnits("", 1);
      getAllCourses("", 1);
      getAllPrograms("", 1);
      getAllMandatoryTrainings("", 1);
    }

    //To get Role

    const getRole = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };
    const orgiRole = getRole("orginalRole");

    if (orgiRole !== null) {
      const parsedEntryType = orgiRole;
      setOrgRole(parsedEntryType);
    }
  }, [open]);

  const getAllPrograms = async (searchValue, pageNum) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    const param = `?search=${search}&limit=${limit}&page=${page}`;
    await API.getAllPrograms(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setAllPrograms(response.data.data.rows);
        setTotalPrograms(response.data.data.count);
      } else {
        setAllPrograms([]);
        setTotalPrograms(0);
      }
      setDone(true);
      setIsLoadProgram(false);
    });
  };

  const getAllUnits = async (searchValue, pageNum) => {
    let search = searchValue;
    let limit = "12";
    let page = pageNum;
    const param = `?search=${search}&limit=${limit}&page=${page}`;
    await API.getAllUnits(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setAllUnits(response.data.data.rows);
        setTotalUnits(response.data.data.count);
      } else {
        setAllUnits([]);
        setTotalUnits(0);
      }
      setDone(true);
      setIsLoadUnit(false);
    });
  };

  const getAllMandatoryTrainings = async (searchValue, pageNum) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    const param = `?search=${search}&limit=${limit}&page=${page}`;
    await API.getAllMandatoryTrainings(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setMandatoryData(response.data.data.rows);
        setTotalMandate(response.data.data.count);
      } else {
        setMandatoryData([]);
        setTotalMandate(0);
      }
      setDone(true);
      setIsLoadUnit(false);
    });
  };

  const getAllCourses = async (searchValue, pageNum) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    const param = `?search=${search}&limit=${limit}&page=${page}`;
    await API.getAllCourses(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setAllCourses(response.data.data.rows);
        setTotalCourses(response.data.data.count);
      } else {
        setAllCourses([]);
        setTotalCourses(0);
      }
      setIsLoadCourse(false);
    });
  };
  const handleInputChangeFilter = (e) => {
    setFilter(e.target.value);
    setPageNumber(1);
    setPageNumberUnits(1);
    setPageNumberPrgm(1);
    setPageNumberTrain(1);
    getCompletedData(searchKey, 1);
  };

  let navigate = useNavigate();

  const handlebootcampValue = (e) => {
    
    if (e.target.value === "Units") {
      navigate("/home/createunit");
    } else if (e.target.value === "Courses") {
      navigate("/home/createcourse");
    }
    // else if (e.target.value === getCreatdata[3].title) {
    //   navigate("/home/createMandatoryTraining");
    // }
    else if (e.target.value === "Programs") {
      
      localStorage.removeItem("IsBootCamp");
      navigate("/home/createProgram");
    }
    setCreate(e.target.value);
  };

  const onPrevious = (e) => {
    if (e === "course") {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
        getAllCourses(searchKey, pageNumber - 1);
      }
    } else if (e === "units") {
      if (pageNumberUnits > 1) {
        setPageNumberUnits(pageNumberUnits - 1);
        getAllUnits(searchKey, pageNumberUnits - 1);
      }
    } else if (e === "program") {
      if (pageNumberPrgm > 1) {
        setPageNumberPrgm(pageNumberPrgm - 1);
        getAllPrograms(searchKey, pageNumberPrgm - 1);
      }
    } else {
      if (pageNumberTrain > 1) {
        setPageNumberTrain(pageNumberTrain - 1);
        getAllMandatoryTrainings(searchKey, pageNumberTrain - 1);
      }
    }
  };

  const getEventPageIndexCount = () => {
    if (filter === "All") {
      if (totalCourses > totalUnits && totalCourses > totalUnits)
        return Math.ceil(totalCourses / 4);
      else if (totalUnits > totalPrograms) return Math.ceil(totalUnits / 4);
      else Math.ceil(totalPrograms / 4);
    } else if (filter === "Courses") {
      return Math.ceil(totalCourses / 4);
    } else if (filter === "Units") {
      return Math.ceil(totalUnits / 12);
    } else return Math.ceil(totalPrograms / 4);
  };

  const onNext = (e) => {
    let Num;
    if (e === "course") {
      Num = Math.ceil(totalCourses / 4);
      if (pageNumber < Num) {
        setPageNumber(pageNumber + 1);
        getAllCourses(searchKey, pageNumber + 1);
      }
    } else if (e === "units") {
      Num = Math.ceil(totalUnits / 12);
      if (pageNumberUnits < Num) {
        setPageNumberUnits(pageNumberUnits + 1);
        getAllUnits(searchKey, pageNumberUnits + 1);
      }
    } else if (e === "program") {
      Num = Math.ceil(totalPrograms / 4);
      if (pageNumberPrgm < Num) {
        setPageNumberPrgm(pageNumberPrgm + 1);
        getAllPrograms(searchKey, pageNumberPrgm + 1);
      }
    } else {
      Num = Math.ceil(totalMandate / 4);
      if (pageNumberTrain < Num) {
        setPageNumberTrain(pageNumberTrain + 1);
        getAllMandatoryTrainings(searchKey, pageNumberTrain + 1);
      }
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };
  
  return (
    <div className={classes.root}>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box className="create-box">
            <h1 className="masterdata-heading" style={{ marginTop: "1%" }}>
              Master Data
            </h1>
            <Typography
              variant="div"
              sx={{
                float: "right",
                marginRight: "15px !important",
                background: "#FB2485 !important",
                color: "#FFFFFF !important",
                borderRadius: "6px",
              }}
            >
              <Custom.ReusableDropdown
                name="Create"
                value={create}
                onChange={handlebootcampValue}
                options={getCreatdata}
                label="Create"
                placeholder="Create"
                error={errors.batch}
                className="dropdown-input-color-setting"
                size="small"
                labelClasses={classes.label}
                style={{
                  width: "220px",
                  height: "38px",
                  borderRadius: "6px",
                  borderColor: "none",
                }}
              ></Custom.ReusableDropdown>
            </Typography>
          </Box>
          <Box className="dashboard-box">
            <Grid container>
              <Grid item xs={2.5}>
                <Custom.Dropdown
                  name="sessions"
                  label="Filtered By"
                  value={filter}
                  onChange={handleInputChangeFilter}
                  options={getDepartment}
                  size="small"
                  className="mentorclassName"
                  style={{
                    height: 40,
                    alignItems: "center",
                    display: "flex",
                    fontFamily: "Poppins",
                    "& .MuiPopover-paper": {
                      height: "19.5vh",
                      minWidth: "180px",
                    },
                  }}
                ></Custom.Dropdown>
              </Grid>
              <Grid item xs={0.25}></Grid>
              <Grid item xs={7.5}>
                <Custom.Input
                  name="sessionName"
                  type="text"
                  placeholder="Search by name"
                  size="small"
                  className={classes.inputStyle}
                  onChange={handleInputChange}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={1.0}>
                <Typography align="center">
                  <Button
                    onClick={handleSearch}
                    variant="contained"
                    className="searchbtn"
                  >
                    Search
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {isLoadCourse ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                {(filter === "All" || filter === "Programs") &&
                allPrograms.length > 0 &&
                Math.ceil(totalPrograms / 4) > 1 ? (
                  <Typography
                    variant="div"
                    style={{ float: "right" }}
                    className="paginationright"
                  >
                    <Avatar
                      className="avatar"
                      onClick={() => {
                        onPrevious("program");
                      }}
                      style={{ paddingLeft: "2.5%" }}
                    >
                      <ArrowBackIosIcon />
                    </Avatar>
                    <Typography className="paginationcount">
                      {pageNumberPrgm}/{Math.ceil(totalPrograms / 4)}
                    </Typography>
                    <Avatar
                      className="avatar"
                      style={{ float: "left" }}
                      onClick={() => {
                        onNext("program");
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Avatar>
                  </Typography>
                ) : (
                  ""
                )}
                {(filter === "All" || filter === "Programs") &&
                allPrograms &&
                allPrograms.length > 0 ? (
                  <>
                    <h1
                      className="masterdata-heading"
                      style={{ textAlign: "left" }}
                    >
                      Programs
                    </h1>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      style={{ marginBottom: "20px" }}
                    >
                      {allPrograms.map((item, key) => (
                        <Grid item xs={12} sm={6} md={3} key={key}>
                          <BasicCard
                            heading="Program"
                            path="/home/createProgram"
                            id={item.program_id}
                            status={item.status}
                            title={item.program_name}
                            description={item.about_program}
                            units=""
                            time={item.duration}
                            tags={[]}
                            setOpen={setOpen}
                            setCurrentDeletionId={setCurrentDeletionId}
                            setType={setType}
                            openModal={openModal}
                            batchType={item.batch_type}
                            orgRole={orgRole}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (filter === "All" || filter === "Programs") &&
                  pageNumberPrgm === 1 ? (
                  <>
                    {/* <h1
                      className="masterdata-heading"
                      style={{ textAlign: "left" }}
                    >
                      Programs
                    </h1>
                    <Box
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "1%",
                      }}
                    >
                      <Paper className="master-session-paper">
                        <span className="mentor-error-text">
                          No Data Found !
                        </span>
                      </Paper>
                    </Box> */}
                  </>
                ) : (
                  ""
                )}
                {(filter === "All" || filter === "Training") &&
                mandatoryData &&
                mandatoryData.length > 0 ? (
                  <Box>
                    {Math.ceil(totalMandate / 4) > 1 ? (
                      <Typography
                        variant="div"
                        style={{ float: "right" }}
                        className="paginationright"
                      >
                        <Avatar
                          className="avatar"
                          onClick={() => {
                            onPrevious("mandate");
                          }}
                          style={{ paddingLeft: "2.5%" }}
                        >
                          <ArrowBackIosIcon />
                        </Avatar>
                        <Typography className="paginationcount">
                          {pageNumberTrain}/{Math.ceil(totalMandate / 4)}
                        </Typography>
                        <Avatar
                          className="avatar"
                          style={{ float: "left" }}
                          onClick={() => {
                            onNext("mandate");
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </Avatar>
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      variant="div"
                      style={{ float: "left" }}
                      className="Courseleft"
                    >
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Mandatory Trainings
                      </h1>
                    </Typography>
                    {mandatoryData ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {mandatoryData?.map((item, key) => (
                            <Grid item xs={12} sm={6} md={3} key={key}>
                              <BasicCard
                                heading={"Mandatory Training"}
                                path={"/home/createunit"}
                                id={item.id}
                                status={item.status}
                                title={item.topic}
                                description={item.description}
                                // units={item.units_count}
                                time={item.duration}
                                tags={item.tagDetails}
                                setOpen={setOpen}
                                setCurrentDeletionId={setCurrentDeletionId}
                                setType={setType}
                                openModal={openModal}
                                userType={""}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : null}
                  </Box>
                ) : (filter === "All" || filter === "Training") &&
                  pageNumberTrain === 1 ? (
                  <>
                    {/* <h1
                      className="masterdata-heading"
                      style={{ textAlign: "left" }}
                    >
                      Mandatory Training
                    </h1>
                    <Box
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Paper className="master-session-paper">
                        <span className="mentor-error-text">
                          No Data Found !
                        </span>
                      </Paper>
                    </Box> */}
                  </>
                ) : (
                  ""
                )}
                <>
                  {(filter === "All" || filter === "Courses") &&
                  allCourses.length > 0 &&
                  Math.ceil(totalCourses / 4) > 1 ? (
                    <Typography
                      variant="div"
                      style={{ float: "right" }}
                      className="paginationright"
                    >
                      <Avatar
                        className="avatar"
                        onClick={() => {
                          onPrevious("course");
                        }}
                        style={{ paddingLeft: "2.5%" }}
                      >
                        <ArrowBackIosIcon />
                      </Avatar>
                      <Typography className="paginationcount">
                        {pageNumber}/{Math.ceil(totalCourses / 4)}
                      </Typography>
                      <Avatar
                        className="avatar"
                        style={{ float: "left" }}
                        onClick={() => {
                          onNext("course");
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </Avatar>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {(filter === "All" || filter === "Courses") &&
                  allCourses &&
                  allCourses.length > 0 ? (
                    <>
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Courses
                      </h1>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {allCourses?.map((item, key) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <BasicCard
                              heading={"Course"}
                              path={"/home/createcourse"}
                              id={item.id}
                              status={item.status}
                              title={item.course_name}
                              description={item.about_course}
                              units={item.units_count}
                              time={item.duration}
                              tags={item.tagDetails}
                              setOpen={setOpen}
                              setCurrentDeletionId={setCurrentDeletionId}
                              setType={setType}
                              openModal={openModal}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : (filter === "All" || filter === "Courses") &&
                    pageNumber === 1 ? (
                    <>
                      {/* <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Courses
                      </h1>
                      <Box
                        style={{
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Paper className="master-session-paper">
                          <span className="mentor-error-text">
                            No Data Found !
                          </span>
                        </Paper>
                      </Box> */}
                    </>
                  ) : (
                    ""
                  )}
                </>
              </Box>
              <>
                {(filter === "All" || filter === "Units") &&
                allUnits.length > 0 &&
                Math.ceil(totalUnits / 12) > 1 ? (
                  <Typography
                    variant="div"
                    style={{ float: "right" }}
                    className="paginationright"
                  >
                    <Avatar
                      className="avatar"
                      onClick={() => {
                        onPrevious("units");
                      }}
                      style={{ paddingLeft: "2.5%" }}
                    >
                      <ArrowBackIosIcon />
                    </Avatar>
                    <Typography className="paginationcount">
                      {pageNumberUnits}/{Math.ceil(totalUnits / 12)}
                    </Typography>
                    <Avatar
                      className="avatar"
                      style={{ float: "left" }}
                      onClick={() => {
                        onNext("units");
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Avatar>
                  </Typography>
                ) : (
                  ""
                )}
                {filter === "All" || filter === "Units" ? (
                  <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                    {allUnits && allUnits.length > 0 ? (
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Units
                      </h1>
                    ) : (
                      ""
                    )}
                    {isLoadUnit ? (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={"#fb2485"}
                        height={50}
                        width={50}
                      />
                    ) : (
                      ""
                    )}
                    {allUnits && allUnits.length > 0 ? (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {allUnits?.map((item, key) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <BasicCard
                              heading={"Unit"}
                              path={"/home/createunit"}
                              id={item.id}
                              status={item.status}
                              ActiveStatus={item.active_status}
                              title={item.topic}
                              description={item.description}
                              tags={item.tagDetails}
                              time={item.duration}
                              setOpen={setOpen}
                              setCurrentDeletionId={setCurrentDeletionId}
                              setType={setType}
                              openModal={openModal}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : pageNumberUnits === 1 ? (
                      <>
                        {/* <h1
                          className="masterdata-heading"
                          style={{ textAlign: "left" }}
                        >
                          Units
                        </h1>
                        <Box
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginBottom: "8%",
                          }}
                        >
                          <Paper className="master-session-paper">
                            <span className="mentor-error-text">
                              No Data Found !
                            </span>
                          </Paper>
                        </Box> */}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </>
            </>
          )}
        </>
      )}
      {open ? (
        <DeletionPopUp
          id={currentDeletionId}
          open={open}
          setOpen={setOpen}
          type={type}
          setNotify={setNotify}
          closeModal={closeModal}
        />
      ) : null}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
