import { Box, Grid, Typography, IconButton } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import "./createUnit.css";
import { Custom } from "../../../../components/Custom/Custom";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import { SxStyles } from "../../../../styles/styles";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { useFormik } from "formik";
import { Assignmentvalidations } from "../../../../Validation/FieldValidatorSchema";
import { API } from "../../../../Networking/API";
import Notification from "../../../../components/Custom/Notification";
import DeletePopUp from "../masterDataModal/deletePopUp";
import { useNavigate } from "react-router";
import { DeleteUnitHelper } from "../../../../helper/unitHelper";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ReactLoading from "react-loading";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { EditOutlined } from "@mui/icons-material";
import FormLabel from "@mui/material/FormLabel";
import Editor from "./editor";

const Assignment = (props) => {
  const { isUnit } = props;
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editopen, setEditOpen] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editParams, setEditParams] = useState([]);
  const styles = SxStyles.styles;
  const [deleteAsnId, setDeleteAsnId] = useState("");
  const [deleteModal, setDeletModal] = useState(false);
  const [done, setDone] = useState(true);
  const [assignParamsDone, setAssignParamsDone] = useState(true);
  const [loaderForSaveLaterASMT, setloaderForSaveLaterASMT] = useState(false);
  const [loaderForPublishASMT, setloaderForPublishASMT] = useState(false);
  const [state, setState] = React.useState();
  const [descError, setDescError] = useState("");
  const [descStatus, setDescStatus] = useState(false);
  const [isrequired, setIsRequired] = useState(false);
  const [description, setDescription] = useState("");
  const [descrpError, setDescrpError] = useState("");

  const [assignParamsErrorMessage, setAssignParamsErrorMessage] = useState(
    "Only .csv format allowed"
  );
  const inputRef = useRef();
  const [paramsData, setParamsData] = useState([]);
  const [extractedText, setExtractedText] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const SheetJSFT = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  //const unitId = localStorage.getItem("UnitId");
  let unitId = "";
  if (window.localStorage.getItem("UnitId")) {
    unitId = window.localStorage.getItem("UnitId");
  } else {
    unitId = window.localStorage.getItem("mandateId");
  }
  let navigate = useNavigate();
  const {
    values,
    fieldValue,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      assignmentName: "",
      description: "",
      isCheckAssignment: "",
    },
    validationSchema: Assignmentvalidations,
    onSubmit: (values) => {
      handleAddAssignment();
    },
  });

  console.log(values, "values");

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
  };

  const handleUploadAssignParams = () => {
    setAssignParamsDone(true);
    const { file } = values;
    API.postAdminuploadParams(file, unitId, tableData[0].id).then(
      ({ response }) => {
        if (response.api_status === 200) {
          inputRef.current.value = null;
          setAssignParamsDone(false);
          getAssignmentdata();
          setNotify({
            isOpen: true,
            message: "File Uploaded successfully",
            type: "success",
          });
        } else {
          inputRef.current.value = null;
          setAssignParamsDone(false);
          setAssignParamsErrorMessage(response.error.error_msg);
          getAssignmentdata();
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    getAssignmentdata();
  }, []);

  const getParsedText = (data) => {
    if (data !== "") {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");

      // Function to traverse the DOM tree and extract text nodes
      const extractTextFromNode = (node) => {
        let text = "";
        for (const childNode of node.childNodes) {
          if (childNode.nodeType === Node.TEXT_NODE) {
            text += childNode.textContent;
          } else if (childNode.nodeType === Node.ELEMENT_NODE) {
            text += extractTextFromNode(childNode);
          }
        }
        return text;
      };

      const extractedText = extractTextFromNode(doc.body);
      return extractedText;
    }
  };

  const getAssignmentdata = () => {
    API.getAdminAssignment(unitId).then(({ response }) => {
      setDone(false);
      if (response.data.api_status === 200) {
        setEditOpen(true);
        const data = response.data.data;
        getAssignmentParamsdata(data[0].asn_pk);
        setIsCheck(data[0].status === 1 ? true : false);
        const formattedData = data.map((each, index) => ({
          id: each.asn_pk,
          Sno: index + 1,
          assignmentName: each.as_name,
          assignmentDescription: each.as_description,
          status: each.status,
          topic_id: each.unit_id,
        }));
        setTableData(formattedData);
        setFieldValue("isCheckAssignment", formattedData[0].status);
        setFieldValue("assignmentName", formattedData[0].assignmentName);
        setFieldValue("description", formattedData[0].assignmentDescription);
      } else {
        setAssignParamsDone(false);
        setTableData([]);
        setIsCheck(false);
      }
    });
  };

  const getAssignmentParamsdata = (AsnId) => {
    API.getAdminParamsData(unitId, AsnId).then(({ response }) => {
      setAssignParamsDone(false);
      setDone(false);
      if (response.api_status === 200) {
        const data = response.data;

        const formattedData = data.map((each, index) => ({
          id: each.params_pk,
          Sno: index + 1,
          paramsName: each.params_name,
          marks: each.marks,
        }));
        setParamsData(formattedData);
      } else {
        setParamsData([]);
      }
    });
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const handleEdit = (params) => {
    // setEditOpen(true);
    setIsDisabled(false);
    setEditParams(params);
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteAsnId(tableData[0].id);
    setOpen(true);
  };

  const handleAddAssignment = () => {
    console.log(description, "inside handleClick");
    setDone(true);

    const { assignmentName, isCheckAssignment } = values;
    if (description) {
      console.log(description, "inside handleClick");

      // if (descStatus === false) {
      API.addAdminAssignmnet(
        unitId,
        assignmentName,
        description,
        isCheckAssignment
      ).then(({ response }) => {
        getAssignmentdata();
        resetForm();
        if (response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          setState();
          setEditOpen(true);
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
          setState();
        }
      });
    }
    // }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleCloseDelete = (id) => {
    setDone(true);
    API.deleteAdminAssignment(unitId, id).then(({ result }) => {
      const response = result;
      getAssignmentdata();
      setOpen(false);
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "Assignment Deleted Successfully",
          type: "success",
        });
        setEditOpen(null);
        setFieldValue("isCheckAssignment", "");
        setFieldValue("assignmentName", "");
        setFieldValue("description", "");
      } else {
        setNotify({
          isOpen: true,
          message: "Assignment Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };
  const handleAssignmentUpload = (id, status) => {
    setDone(true);
    let _status = status === 1 ? 2 : 1;
    API.AssignmentUploadStatus(id, _status).then(({ response }) => {
      getAssignmentdata();
      getAssignmentParamsdata();
      setOpen(false);
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: `${response.data[0].message}`,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Assignment Not Updated Please Try Again",
          type: "error",
        });
      }
    });
  };

  const columns = [
    { field: "id", headerName: "id", hide: true },
    {
      field: "Sno",
      headerName: "S.No",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell",
      headerAlign: "left",
      align: "left",
      width: 100,
      // flex: 1,
    },
    {
      field: "assignmentName",
      headerName: "Name",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell-video",
      headerAlign: "left",
      align: "left",
      sortable: false,
      width: 250,
      // flex: 1,
    },
    {
      field: "assignmentDescription",
      headerName: "Description",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell-material",
      headerAlign: "left",
      align: "left",
      sortable: false,
      width: 400,
      flex: 2,
      renderCell: (params) => {
        const AssignmentDescription = params.row.assignmentDescription;
        return (
          <p
            className="description-text"
            dangerouslySetInnerHTML={{
              __html: AssignmentDescription,
            }}
          />
        );
      },
    },

    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "training-mat-header",
      cellClassName: "training-cell-delete",
      headerAlign: "left",
      align: "left",
      sortable: false,
      // width: 50,
      renderCell: (params) => (
        <>
          <span className="editbutton" style={{ marginRight: "-28px" }}>
            <Tooltip title="Delete">
              <IconButton aria-label="edit" size="small">
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleDeleteInpre(params)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="edit" size="small">
                <EditOutlined
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleEdit(params)}
                />
              </IconButton>
            </Tooltip>

            {/* <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseInByCancel}
              BackdropProps={{
                style: styles.dialogBoxContainer,
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span
                    style={{ fontWeight: "bold", marginLeft: "5px" }}
                  ></span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={styles.cancelButton}
                  onClick={handleCloseInByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={styles.createBtnDialog}
                  onClick={() => handleCloseDelete(deleteAsnId)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog> */}
          </span>
        </>
      ),
    },
  ];
  const columnsParams = [
    {
      field: "Sno",
      headerName: "S.No",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "paramsName",
      headerName: "Params",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell-material",
      headerAlign: "left",
      align: "left",
      sortable: false,
      width: 500,
    },
    {
      field: "marks",
      headerName: "Marks",
      headerClassName: "training-mat-header",
      cellClassName: "training-cell-material",
      headerAlign: "left",
      align: "left",
      sortable: false,
      width: 350,
    },
  ];

  // useEffect(() => {
  //   if (values.isCheckAssignment === "0") {
  //     setIsRequired(true);
  //   } else {
  //     setIsRequired(false);
  //   }
  // }, [values.isCheckAssignment]);

  const HandleDeleteUnit = () => {
    DeleteUnitHelper(setNotify, navigate, unitId);

    setDeletModal(false);
  };

  const handleSaveForLaterButton = () => {
    setloaderForSaveLaterASMT(true);
    API.AdminSaveForLaterBtn(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setloaderForSaveLaterASMT(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterASMT(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again later",
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    setloaderForPublishASMT(true);
    if (isUnit) {
      API.finalUnitCreation(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishASMT(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishASMT(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    } else {
      API.finalAdminCreationPublish(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setloaderForPublishASMT(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setloaderForPublishASMT(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };

  const handleEditsubmit = (e) => {
    e.preventDefault();

    if (description !== "<p></p>") {
      API.editAdminAssignmnet(
        values.assignmentName,
        description,
        // extractedText,
        // editParams.row.id,
        // editParams.row.status,
        // editParams.row.topic_id
        tableData[0].id,
        // tableData[0].status,
        values.isCheckAssignment,
        tableData[0].topic_id
      ).then(({ response }) => {
        getAssignmentdata();
        resetForm();
        if (response.api_status === 200) {
          setEditOpen(true);
          setIsDisabled(true);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });

          setState();
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
          setState();
          setEditOpen(true);
        }
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Description shouldnt be empty",
        type: "error",
      });
      // setDescrpError("Description should be empty");
    }
  };

  const [isCheck, setIsCheck] = useState(false);
  // useEffect(() => {
  //   if (editopen) {
  //     setFieldValue("isCheckAssignment", tableData[0].status);
  //     setFieldValue("assignmentName", tableData[0].assignmentName);
  //     setFieldValue("description", tableData[0].assignmentDescription);
  //   }
  // }, [editopen]);

  return (
    <>
      <Box
        className="training-material-container"
        style={styles.assignemntHieght}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={styles.gridContainer}>
            <Grid item xs={10}>
              <FormControl>
                <Typography style={styles.label1}>
                  Assignment Required
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isCheckAssignment"
                  id="isCheckAssignment"
                  value={values.isCheckAssignment}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    // disabled={editopen}
                    value="1"
                    className="radiobtn"
                    control={<Radio style={{ color: "#B9B9B9" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    // disabled={editopen}
                    value="0"
                    className="radiobtn"
                    control={<Radio style={{ color: "#B9B9B9" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <Typography style={styles.label}>
                Assignment Name
                <span style={styles.madatoryMark}>*</span>
              </Typography>
              <span>
                <ReusableInputfield
                  // disabled={
                  //   tableData.length > 0
                  //     ? isDisabled
                  //     : editopen === true
                  //     ? isDisabled
                  //     : false
                  // }
                  disabled={isrequired}
                  name="assignmentName"
                  id="assignmentName"
                  placeholder="Assignment Name"
                  value={values.assignmentName}
                  onChange={handleChange}
                  size="small"
                  error={
                    touched.assignmentName && Boolean(errors.assignmentName)
                  }
                  helperText={touched.assignmentName && errors.assignmentName}
                />
              </span>
            </Grid>
          </Grid>

          <div style={styles.inputContainer}>
            <Typography style={styles.label}>
              Assignment Instructions
              <span style={styles.madatoryMark}>*</span>
            </Typography>

            <Editor
              name="description"
              id="sessdescriptionion"
              value={values.description}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              size="small"
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              extractedText={extractedText}
              setExtractedText={setExtractedText}
              setDescStatus={setDescStatus}
              // setDescError={setDescError}
              descStatus={descStatus}
              descError={descError}
              setState={setState}
              state={state}
              disabled={isrequired}
              setDescription={setDescription}
              // setDescrpError={setDescrpError}
              // descrpError={descrpError}
            />
          </div>

          <Grid container spacing={5} style={{ marginBottom: "5px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={1} style={styles.addBtn}>
              {editopen === null ? null : (
                <ReusableButton
                  disabled={editopen === null ? true : false}
                  size="large"
                  variant="outlined"
                  buttonName={"Delete"}
                  onClick={handleDeleteInpre}
                  style={
                    editopen === null ? styles.buttonDisabled : styles.button
                  }
                ></ReusableButton>
              )}
            </Grid>
            <Grid item xs={1} style={styles.addBtn}>
              {editopen === null ? (
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName={"Add"}
                  type="submit"
                  style={styles.updateButton}
                ></ReusableButton>
              ) : (
                // ) : editopen === true ? (
                //   <ReusableButton
                //     disabled={editopen === true ? false : true}
                //     size="large"
                //     variant="outlined"
                //     buttonName={"Edit"}
                //     // onClick={() => setEditOpen(false)}
                //     style={
                //       editopen === true ? styles.button : styles.buttonDisabled
                //     }
                //   ></ReusableButton>
                // ) : (
                <ReusableButton
                  // disabled={editopen === true ? false : true}
                  size="large"
                  variant="outlined"
                  buttonName={"Update"}
                  onClick={handleEditsubmit}
                  style={styles.updateButton}
                ></ReusableButton>
              )}
            </Grid>
          </Grid>
        </form>

        {/* {done ? (
          <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {tableData.length > 0 ? (
              <>
                <Box>
                  <DataTable
                    rows={tableData}
                    columns={columns}
                    rowHeight={40}
                    autoHeight
                    className="assignment-first-table"
                    style={{ backgroundColor: "white" }}
                    hideFooter={true}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box style={styles.tableErrorMsg}>
                  No Assignment found. Please Add a Assignment
                </Box>
              </>
            )}
          </>
        )} */}
        {isCheck ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div style={{ marginTop: 50 }}>
                  <Typography style={styles.label} variant="h6">
                    Upload File
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>
                  <span>
                    <input
                      name="file"
                      id="file"
                      type="file"
                      class="upload-box"
                      accept={SheetJSFT}
                      style={styles.fileInput}
                      ref={inputRef}
                      value={fieldValue}
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                  </span>
                  <br />
                  <Typography style={styles.note} variant="p">
                    Only .csv format allowed &nbsp;
                    <span>
                      {errors.file && (
                        <Typography style={styles.madatoryMark} variant="p">
                          {errors.file}
                        </Typography>
                      )}
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={1.8} style={styles.uploadAssignParamsButton}>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName="Add"
                  onClick={handleUploadAssignParams}
                  disabled={tableData.length > 0 ? false : true}
                  style={
                    tableData.length > 0 ? styles.button : styles.buttonDisabled
                  }
                ></ReusableButton>
              </Grid>
            </Grid>
            <Grid>
              {assignParamsDone ? (
                <Box
                  style={{
                    height: "30vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fb2485"}
                    height={100}
                    width={100}
                  />
                </Box>
              ) : (
                <>
                  {paramsData.length > 0 ? (
                    <>
                      <Box style={{ marginTop: 30 }}>
                        <DataTable
                          rows={paramsData}
                          columns={columnsParams}
                          rowHeight={40}
                          autoHeight
                          style={{ backgroundColor: "white" }}
                          hideFooter={true}
                          hideFooterPagination={true}
                          hideFooterSelectedRowCount={true}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={styles.tableErrorMsg}>
                        No Parameters found.
                      </Box>
                    </>
                  )}
                </>
              )}
            </Grid>
          </>
        ) : null}
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Grid item xs={6}>
            <Box style={styles.btnContainer}>
              <Box style={styles.buttonContainer}>
                <ReusableButton
                  onClick={() => navigate("/home/courses")}
                  size="large"
                  variant="outlined"
                  buttonName="Cancel"
                  style={styles.cancelButton}
                  disabled={
                    loaderForSaveLaterASMT || loaderForPublishASMT
                      ? true
                      : false
                  }
                ></ReusableButton>
                <ReusableButton
                  onClick={handleSaveForLaterButton}
                  className="addbutton"
                  type="submit"
                  size="large"
                  variant="contained"
                  endIcon={
                    !loaderForSaveLaterASMT ? (
                      ""
                    ) : (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )
                  }
                  buttonName={
                    !loaderForSaveLaterASMT ? "Save for Later" : "Saving"
                  }
                  style={
                    !loaderForPublishASMT
                      ? styles.saveForLaterBtn
                      : styles.saveForLaterBtnDisabled
                  }
                  disabled={loaderForPublishASMT ? true : false}
                ></ReusableButton>
                <ReusableButton
                  onClick={handleCreateButton}
                  type="submit"
                  size="large"
                  variant="contained"
                  endIcon={
                    !loaderForPublishASMT ? (
                      ""
                    ) : (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )
                  }
                  buttonName={!loaderForPublishASMT ? "Publish" : "Publishing"}
                  style={
                    !loaderForSaveLaterASMT
                      ? styles.createBtn
                      : styles.createBtnDisabled
                  }
                  disabled={loaderForSaveLaterASMT ? true : false}
                ></ReusableButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
      <Dialog
        divider
        open={open}
        maxWidth="sm"
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleCloseInByCancel}
          />
          <Button
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName="Delete"
            style={styles.createBtnDialog}
            onClick={() => handleCloseDelete(deleteAsnId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Assignment;
