import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import axios from "axios";
import Cookies from "js-cookie";
import "./mentor-dashbord.css";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { display } from "@mui/system";
import { MentorChart } from "./mentor-chart";
import { API } from "../../../Networking/API";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const items = [
  {
    date: "Friday 26, August 2022",
    day: "Today",
    topic: "Saleforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
    name: "Gaurav R",
    designation: "Senior Salesforce Consultant",
    experience: "10",
    certificate: "3",
    sessions: "8",
    training_material: [
      {
        material_name: "material",
        material_pk: 15,
        material_url:
          "https://absyz-academy.s3.ap-south-1.amazonaws.com/objects/training-material/ef1710en.pdf",
        schedule_pk: 116,
      },
    ],
  },
  {
    date: "Friday 26, August 2022",
    day: "Today",
    topic: "Saleforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
    name: "Gaurav R",
    designation: "Senior Salesforce Consultant",
    experience: "10",
    certificate: "3",
    sessions: "8",
    training_material: [
      {
        material_name: "material",
        material_pk: 15,
        material_url:
          "https://absyz-academy.s3.ap-south-1.amazonaws.com/objects/training-material/ef1710en.pdf",
        schedule_pk: 116,
      },
    ],
  },
  {
    date: "Friday 26, August 2022",
    day: "Today",
    topic: "Saleforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
    name: "Gaurav R",
    designation: "Senior Salesforce Consultant",
    experience: "10",
    certificate: "3",
    sessions: "8",
    training_material: [
      {
        material_name: "material",
        material_pk: 15,
        material_url:
          "https://absyz-academy.s3.ap-south-1.amazonaws.com/objects/training-material/ef1710en.pdf",
        schedule_pk: 116,
      },
    ],
  },
  {
    date: "Friday 26, August 2022",
    day: "Today",
    topic: "Saleforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
    name: "Gaurav R",
    designation: "Senior Salesforce Consultant",
    experience: "10",
    certificate: "3",
    sessions: "8",
    training_material: [
      {
        material_name: "material",
        material_pk: 15,
        material_url:
          "https://absyz-academy.s3.ap-south-1.amazonaws.com/objects/training-material/ef1710en.pdf",
        schedule_pk: 116,
      },
    ],
  },
];

const itemstopers = [
  {
    name: "Vamsi",
    score: "90%",
  },
  {
    name: "Rajesh A",
    score: "95%",
  },
];

const data1 = [
  {
    date: "Monday 29, August 2022",
    day: "Upcoming",
    topic: "Salesforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
  },
  {
    date: "Friday 26, August 2022",
    day: "Upcoming",
    topic: "Salesforce LWC",
    time: "09:00 AM - 11:00 AM",
    duartion: "02:00",
  },
];

const MentorDashboard = () => {
  const [TodaySessionData, setTodaySessionData] = useState("");
  const [upcomingSessionData, setUpcomingSessionData] = useState([]);
  const [topAchievers, setTopAchievers] = useState([]);
  const [teamPlayerData, setTeamPlayerData] = useState([]);
  const [teamCountMentor, setTeamCountMentor] = useState();
  const [userData, setUserData] = useState("");
  const [totalSessionDetails, setTotalSessionDetails] = useState("");
  const [chartData, setChartData] = useState("");
  const [done, setDone] = useState(undefined);
  const [trainerDetails, setTrainerDetails] = useState();
  const [usermail, setUsermail] = useState();
  const jwtToken = Cookies.get("jwt_token");
  const navigate = useNavigate();

  useEffect(() => {
    const batchId = localStorage.getItem("courseId");

    if (batchId !== undefined || batchId !== null) {
      getDashboardData(batchId);
    }
  }, []);
  // var undefinedparam = undefined;
  const getDashboardData = async (batchId) => {
    try {
      API.getMentorDashBoard(batchId).then(({ response }) => {
        if (response.data.api_status === 200) {
          const teamPlayer = response.data.data[0].team_players;
          setChartData(teamPlayer);
          const sessionDetails = response.data.data[0].sessions_details[0];
          setTotalSessionDetails(sessionDetails);

          const userDetails = response.data.data[0].user_details;
          setUserData(userDetails);

          const todaySessionDetails = response.data.data[0].today;
          setTrainerDetails(todaySessionDetails[0].trainer_details);
          const formattedTodaySessionDetails = todaySessionDetails.map(
            (eachData) => ({
              id: eachData.schedule_pk,
              date: eachData.training_date,
              day: eachData.day,
              topic: eachData.topic,
              time: eachData.time,
              duration: eachData.duration,
              trainingmaterial: eachData.training_material,
              name: eachData[0]?.trainer_details?.user_name,
              designation: eachData[0]?.trainer_details?.work_posiotion,
              experience: eachData[0]?.trainer_details?.work_experience,
              certificate: eachData[0]?.trainer_details?.certificates,
              sessions: eachData[0]?.trainer_details?.sessions_assigned,
            })
          );

          setTodaySessionData(formattedTodaySessionDetails[0]);

          const upcomingSessionDetails = response.data.data[0].upcoming;
          const formattedUpcomingSessionDetails = upcomingSessionDetails.map(
            (eachData) => ({
              id: eachData.schedule_pk,
              date: eachData.training_date,
              day: eachData.day,
              topic: eachData.topic,
              time: eachData.time,
              duration: eachData.duration,
              trainingmaterial: eachData.training_material,
            })
          );
          setUpcomingSessionData(formattedUpcomingSessionDetails);

          // const achievers = response.data.data[0].top_achivers;
          // const formattedAchievers = achievers.map((eachData) => ({
          //   percentage: eachData.percentage,
          //   username: eachData.user_name,
          //   user_profile: eachData.user_profile,
          // }));
          // setTopAchievers(formattedAchievers);
          const teamcount = response.data.data[0].team_count;
          setTeamCountMentor(teamcount);
          // const teamplayer = response.data.data[0].team_player[0];
          // setTeamPlayerData(teamplayer);
          setDone(true);
        } else if (response.data.api_status === 403) {
          Cookies.remove("jwt_token");
          navigate("/");
        }
      });
    } catch (e) {}
  };

  const handleMentorBack = () => {
    navigate("/user/");
    localStorage.removeItem("isSwitch");
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <h1 className="traineeHeading">Welcome {userData.user_name} !</h1>
          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              margin: "50px 0px 20px 24px",
            }}
          >
            <ReusableButton
              onClick={handleMentorBack}
              buttonName="Back"
              startIcon={<ArrowBackIosIcon fontSize="small" />}
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontFamily: "Helvetica",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#02215b",
                padding: "10px 20px",
                margin: "-40px 0px 0px 0px",
                textTransform: "initial",
              }}
              variant="outlined"
              size="medium"
            />
          </Box>
          <Box
            sx={{
              // marginLeft: "30px",
              // marginRight: "30px",

              marginTop: "0px",
              marginBottom: "90px",
            }}
          >
            <Box
              style={{
                height: "360px",
                borderRadius: "12px",
                backgroundColor: "#fff",
                marginBottom: "0px",
                marginLeft: "30px",
                marginRight: "30px",
              }}
            >
              <Link
                to="/user/bootcamp/mentor/home/session-details"
                style={{ textDecoration: "none", color: "none" }}
                state={{ data: TodaySessionData.id }}
              >
                <Grid
                  container
                  sx={{
                    verticalAlign: "center",
                    // backgroundImage: ,
                    padding: "1px 0px 24px 30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "space-between",
                    height: "86px",
                    width: "100%",
                    borderTopLeftRadius: "12px",
                  }}
                  className="background-image"
                >
                  <Grid item xs={4} md={5}>
                    <p className="dateText">{TodaySessionData.date}</p>
                  </Grid>
                  <Grid item xs={3} md={4} className="mentorspanHeaderText">
                    <p className="spanTexts">{TodaySessionData.day}</p>
                  </Grid>
                </Grid>
              </Link>
              <Grid container sx={{ paddingLeft: "24px", marginTop: "0px" }}>
                <Grid item xs={6} md={7}>
                  <Link
                    to="/user/bootcamp/mentor/home/session-details"
                    style={{ textDecoration: "none", color: "none" }}
                    state={{ data: TodaySessionData.id }}
                  >
                    <>
                      <h1
                        className="heading"
                        style={{
                          width: "520px",
                          marginBottom: "-6px",
                        }}
                      >
                        {/* SF Administration & Application Fundamentals */}
                        {TodaySessionData.topic}
                      </h1>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "-10px",
                        }}
                      >
                        <div>
                          <p className="timeText">
                            <span className="span">Time: </span>
                            {/* 09:00 AM-11:00 AM IST */}
                            {TodaySessionData.time} IST
                          </p>
                        </div>
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "12px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        />
                        <div>
                          <p className="timeText">
                            <span className="span">Duration: </span>
                            {/* 02:00 Hours */}
                            {TodaySessionData.duration} Hours
                          </p>
                        </div>
                      </Box>
                    </>
                  </Link>
                  {<h1 className="bottom-heading">Training Materials</h1>}
                  {TodaySessionData.trainingmaterial !== undefined ? (
                    <>
                      <div style={{ height: "100px", overflowY: "scroll" }}>
                        {TodaySessionData.trainingmaterial.map((eachdata) => (
                          <a
                            href={eachdata.material_url}
                            target="_blank"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              marginBottom: "10px",
                            }}
                          >
                            <AttachFileIcon
                              sx={{
                                color: "#0078d4",
                                marginRight: "8px",
                                fontSize: "15px",
                              }}
                            />
                            <span className="downloadText">
                              {eachdata.material_name}
                            </span>
                          </a>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <h1 style={{ color: "red" }}></h1>
                    </>
                  )}
                </Grid>
                <Grid item xs={4} md={1}>
                  <div className="border-style"></div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Link
                    to="/user/bootcamp/mentor/home/session-details"
                    style={{ textDecoration: "none", color: "none" }}
                    state={{ data: TodaySessionData.id }}
                  >
                    <Box className="side-container">
                      <h1 className="sideText">Trainer Info</h1>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "28px",
                        }}
                      >
                        <AccountCircleIcon className="userIcon" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <h1 className="trainer-name">
                            {/* {TodaySessionData.name} */}
                            {trainerDetails?.user_name != undefined &&
                            trainerDetails?.user_name != null
                              ? trainerDetails?.user_name
                              : trainerDetails?.user_email != undefined &&
                                trainerDetails?.user_email != null
                              ? trainerDetails?.user_email.split(".")[0]
                              : "External Trainer"}
                          </h1>
                          <p className="trainer-designation">
                            {trainerDetails
                              ? trainerDetails?.work_posiotion
                                ? trainerDetails.work_posiotion
                                : null
                              : null}
                          </p>
                          <p className="trainer-experience">
                            {trainerDetails
                              ? trainerDetails?.work_experience !== null
                                ? `${trainerDetails.work_experience} years of experience`
                                : null
                              : null}
                          </p>
                        </div>
                      </Box>
                      <Grid container>
                        {true !== null ? (
                          <Grid xs={4} md={7}>
                            {trainerDetails?.certificates !== null &&
                            trainerDetails?.certificates !== undefined ? (
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    padding: "7px 8px 6px",
                                    borderRadius: "25px",
                                    backgroundColor: "#02215b",
                                    width: "40px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="trainer-span-text">
                                    {trainerDetails?.certificates}
                                  </span>
                                  {/* } */}
                                </Box>
                                <p
                                  className="certifcation-text"
                                  style={{ width: "170px" }}
                                >
                                  Certifications Achieved
                                </p>
                              </Box>
                            ) : null}
                          </Grid>
                        ) : (
                          <Grid xs={4} md={0.7}></Grid>
                        )}
                        <Grid xs={4} md={5}>
                          {trainerDetails?.sessions !== undefined ? (
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box
                                style={{
                                  padding: "6px 14px 6px",
                                  borderRadius: "25px",
                                  backgroundColor: "#02215b",
                                  width: "40px",
                                  height: "40px",
                                  marginLeft: "-20px",
                                }}
                              >
                                <span className="trainer-span-text">
                                  {trainerDetails?.sessions}
                                </span>
                              </Box>
                              <p className="certifcation-text">
                                Sessions Assigned
                              </p>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            </Box>

            {/* bottom cards code */}
            <Grid container sx={{ marginTop: "-10px", marginBottom: "30px" }}>
              <Grid item xs={2} md={5.75}>
                <Box
                  className="card-containergraph12"
                  style={{
                    height: "274px",
                    // height: "84px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "28px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <span className="sessionscompleted">
                        Sessions Completed
                      </span>
                      <span className="mentorscore">
                        {totalSessionDetails.completed}/
                        {totalSessionDetails.total_sessions}
                      </span>

                      {/* <div
                        style={{
                          marginTop: 50,
                        }}
                      >
                        <span className="sessionsassesed">
                          Sessions Assesed{" "}
                        </span>
                        <span className="mentorscoreAssesed">
                          {totalSessionDetails.assesed}/
                          {totalSessionDetails.total_assessed}
                        </span>
                      </div> */}
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} md={6.25}>
                <Grid
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  {upcomingSessionData !== undefined ? (
                    <Custom.CustomCarousel
                      kind="assessed"
                      items={upcomingSessionData}
                      path="/user/bootcamp/mentor/home/session-details"
                    />
                  ) : null}
                </Grid>
                {/* <Box
                  className="card-containergraph121"
                  style={{
                    // height: "274px",
                    height: "84px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0px",
                    margin: "30px 30px 0px 30px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "28px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <span className="sessionscompleted">
                        Sessions Assesed
                      </span>
                      <span className="mentorscore">
                        {totalSessionDetails.completed}/
                        {totalSessionDetails.total_sessions}
                      </span>

                      <div
                        style={{
                          marginTop: 50,
                        }}
                      >
                        <span className="sessionsassesed">
                          Sessions Assesed{" "}
                        </span>
                        <span className="mentorscoreAssesed">
                          {totalSessionDetails.assesed}/
                          {totalSessionDetails.total_assessed}
                        </span>
                      </div>
                    </div>
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
            {/* <Grid
              container
              style={{ marginTop: "-10px", marginBottom: "60px" }}
            >
              <Grid item xs={4} md={7}>
                <MentorChart
                  chartData={chartData}
                  teamCountMentor={teamCountMentor}
                />
              </Grid>

              <Grid item xs={4} md={2} sx={{ marginRight: "0px" }}>
                {teamCountMentor > 0 ? (
                  <Box className="card-container">
                    <div class="textplayer">Team Player</div>
                    {teamPlayerData.user_profile === null ||
                    teamPlayerData.user_profile === undefined ? (
                      <AccountCircleIcon
                        style={{
                          width: "66px",
                          height: "66px",
                          color: "#03215a",
                        }}
                        className={"AccountPIcon"}
                      />
                    ) : (
                      <Avatar
                        src={teamPlayerData.user_profile}
                        alt="image"
                        sx={{ width: 66, height: 66 }}
                        className="user-icon"
                      />
                    )}

                    <div class="textnames1">{teamPlayerData.user_name}</div>

                    <div class="scored">
                      Scored {teamPlayerData.percentage}%
                    </div>
                  </Box>
                ) : (
                  <Grid item xs={4} md={2} sx={{ marginRight: "0px" }}>
                    <Box className="card-container">
                      <div class="textplayer">Team Player</div>
                      <AccountCircleIcon
                        style={{
                          width: "66px",
                          height: "66px",
                          color: "#03215a",
                        }}
                        className={"AccountPIcon"}
                      />
                      <div class="textnames">Name</div>

                      <div class="scored">Percentage Scored</div>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={4} md={3} sx={{ marginRight: "0px" }}>
                {topAchievers !== undefined ? (
                  <Custom.CustomCarousel kind="profile" items={topAchievers} />
                ) : null}
              </Grid>
            </Grid> */}
          </Box>
        </>
      )}
    </>
  );
};

export default MentorDashboard;
