import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TraineeHomePage from "../pages/trainee/trainee-layout/trainee-layout";
import ProtectedRoute from "../pages/protected-route/protectedroute";
import TrainerHomePage from "../pages/trainer/trainer-layout/trainer-layout";
import AdminHomePage from "../pages/admin/admin-layout/admin-layout";
import MentorHomePage from "../pages/mentor/mentor-layout/mentor-layout";
import { Navigate } from "react-router-dom";
import AuthVerify from "../components/Custom/AuthVerify.jsx";
import { RoutesHandler } from "./routesHandler";
import { UserProfile } from "../constants/userProfile";
import UserHomePage from "../pages/users/user-layout/user-layout";

const ROLES = UserProfile;

const Routers = () => {
  return (
    <Router>
      <Routes>
        {RoutesHandler.InitialRoutes.map((InitialRoutes, index) => {
          return (
            <Route
              exact
              path={`${InitialRoutes.path}`}
              element={<InitialRoutes.element />}
            />
          );
        })}

        <Route element={<ProtectedRoute allowedRoles={ROLES.ADMIN} />}>
          <Route exact path="/home/" element={<AdminHomePage />}>
            {RoutesHandler.AdminRoutes.map((AdminRoutes, index) => {
              return (
                <Route
                  exact
                  path={`${AdminRoutes.path}`}
                  element={<AdminRoutes.element />}
                />
              );
            })}
          </Route>
        </Route>

        <Route element={<ProtectedRoute allowedRoles={ROLES.TRAINEE} />}>
          <Route exact path="/trainee/home/" element={<TraineeHomePage />}>
            {RoutesHandler.TraineeRoutes.map((TraineeRoutes, index) => {
              return (
                <Route
                  exact
                  path={`${TraineeRoutes.path}`}
                  element={<TraineeRoutes.element />}
                />
              );
            })}
          </Route>
        </Route>

        <Route element={<ProtectedRoute allowedRoles={ROLES.TRAINER} />}>
          <Route exact path="/trainer/home/" element={<TrainerHomePage />}>
            {RoutesHandler.TrainerRoutes.map((TrainerRoutes, index) => {
              return (
                <Route
                  exact
                  path={`${TrainerRoutes.path}`}
                  element={<TrainerRoutes.element />}
                />
              );
            })}
          </Route>
        </Route>

        <Route element={<ProtectedRoute allowedRoles={ROLES.MENTOR} />}>
          <Route exact path="/mentor/home/" element={<MentorHomePage />}>
            {RoutesHandler.MentorRoutes.map((MentorRoutes, index) => {
              return (
                <Route
                  exact
                  path={`${MentorRoutes.path}`}
                  element={<MentorRoutes.element />}
                />
              );
            })}
          </Route>

          <Route
            exact
            path="*"
            render={() => {
              return ROLES.MENTOR ? (
                <Navigate to="/mentor/home/" replace />
              ) : ROLES.TRAINER ? (
                <Navigate to="/trainer/home/" replace />
              ) : ROLES.TRAINEE ? (
                <Navigate to="/trainee/home/" replace />
              ) : null;
            }}
          />
        </Route>

        {/* USER ROUTES */}
        <Route element={<ProtectedRoute allowedRoles={ROLES.USER} />}>
          <Route exact path="/user/" element={<UserHomePage />}>
            {RoutesHandler.UsersRoutes.map((UsersRoutes, index) => {
              return (
                <Route
                  exact
                  path={`${UsersRoutes.path}`}
                  element={<UsersRoutes.element />}
                />
              );
            })}
          </Route>
        </Route>
      </Routes>
      <AuthVerify />
    </Router>
  );
};

export default Routers;
