import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Cookies from "js-cookie";
import IconButton from "@mui/material/IconButton";
import pIcon from "../../../assets/Images/pIcon.png";
import logout from "../../../assets/Images/logOut.png";
import MenuList from "@mui/material/MenuList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { makeStyles } from "@material-ui/core";
import "./user-layout.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { API } from "../../../Networking/API";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import MenuItems from "../menu-items-user/menu-items-user";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AIcon from "../../../assets/Images/AIcon.png";
import Switch from "@mui/material/Switch";
// import Notification from "../../../components/Custom/Notification";
import Notification from "../../../components/Custom/Notification";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: "#000936",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const styles = makeStyles((theme) => ({
  openDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: "#000936",
    },
  },
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function UserHomePage(props) {
  const classes = styles();
  const [open, setOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [pAnchorEl, setPAnchorEl] = useState(null);
  const [trainerChecked, setTrainerChecked] = React.useState("");
  const [MentorChecked, setMentorChecked] = React.useState("");
  const [TraineeChecked, setTraineeChecked] = React.useState("");

  const [orgRole, setOrgRole] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // var roleNameToCheckTrainer = "Mentor";
  // var roleNameToCheckMentor = "Mentor";
  // var roleNameToCheckTrainer = "Mentor";

  const isOpen = Boolean(pAnchorEl);
  const handleClick = (event) => {
    setPAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPAnchorEl(null);
  };
  var uniqueRoleNamesJSON = localStorage.getItem("uniqueRoleNamesData");
  var uniqueRoleNames = JSON.parse(uniqueRoleNamesJSON);
  console.log(uniqueRoleNames, "uniqueRoleNames");
  console.log(typeof uniqueRoleNames, "uniqueRoleNames");

  const location = useLocation();
  const active =
    location.pathname === "/user/" ||
    location.pathname === "/user/courses" ||
    location.pathname === "/user/courses/units";
  const hActive =
    location.pathname === "/user/" ||
    location.pathname === "/user/courses" ||
    location.pathname === "/user/courses/units" ||
    location.pathname === "/user/profile" ||
    location.pathname === "/user/changepassword" ||
    // trainee paths starts
    location.pathname === "/user/bootcamp/home/" ||
    location.pathname === "/user/bootcamp/home/sessiondetails";

  const onClickLogout = () => {
    Cookies.remove("jwt_token");
    Cookies.remove("user_role");
    Cookies.remove("user_name");
    Cookies.remove("orginalRole");
    Cookies.remove("entryType");
    Cookies.remove("batch_id");
    Cookies.remove("quizdata");
    localStorage.removeItem("isSwitch");
    localStorage.removeItem("batchType");
    localStorage.removeItem("courseId");
    localStorage.removeItem("uniqueRoleNamesData");
    navigate("/");
  };

  const changeRole = async (name) => {
    const batchId = localStorage.getItem("courseId");
    console.log(name, "name");

    let roleBits;
    if (
      name === "Mentor"
      // ||
      // location.pathname === "/user/bootcamp/mentor/home/"
    ) {
      roleBits = 4;

      await API.getMentorSwitch(batchId, roleBits).then(({ response }) => {
        console.log(response, "in mentor");
        if (response.api_status === 200) {
          Cookies.set("orginalRole", "Mentor", {
            expires: 30,
          });
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          document.location.reload();
          navigate("/user/bootcamp/mentor/home/");
        } else {
          setNotify({
            isOpen: true,
            message: response.data?.error[0]?.error_msg,
            type: "error",
          });
          const interval = setInterval(() => {
            setMentorChecked(false);
          }, 1000);
          return () => clearInterval(interval);
        }
      });
    } else if (
      name === "Trainer"
      // ||
      // location.pathname === "/user/bootcamp/trainer/home/"
    ) {
      roleBits = 8;

      await API.getMentorSwitch(batchId, roleBits).then(({ response }) => {
        console.log(response, "IN Trainer");

        if (response.api_status === 200) {
          Cookies.set("orginalRole", "Trainer", {
            expires: 30,
          });
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          document.location.reload();
          navigate("/user/bootcamp/trainer/home/");
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
          const interval = setInterval(() => {
            setTrainerChecked(false);
          }, 1000);
          return () => clearInterval(interval);
        }
      });
    } else if (name === "Trainee") {
      roleBits = 16;

      await API.getMentorSwitch(batchId, roleBits).then(({ response }) => {
        console.log(response, "IN TRAINEE");
        if (response.api_status === 200) {
          Cookies.set("orginalRole", "Trainee", {
            expires: 30,
          });
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          document.location.reload();
          navigate("/user/bootcamp/home/");
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
          const interval = setInterval(() => {
            setTraineeChecked(false);
          }, 1000);
          return () => clearInterval(interval);
        }
      });
    }

    console.log(batchId, roleBits, "roleBitsroleBits");
  };

  const handleTrainerChange = (event) => {
    setTrainerChecked(event.target.checked);
    setMentorChecked(!event.target.checked);
    let name = "Trainer";
    changeRole(name);
    console.log(event.target.checked, "checking");
  };
  const handleMentorChange = (event) => {
    setMentorChecked(event.target.checked);
    setTrainerChecked(!event.target.checked);
    console.log(event.target.checked, "checking");
    let name = "Mentor";
    changeRole(name);
  };

  const handleTraineeChange = (event) => {
    setTraineeChecked(event.target.checked);
    console.log(event.target.checked, "checking");
    // setTrainerChecked(!event.target.checked);
    let name = "Trainee";
    changeRole(name);
  };

  // // To open and close the menu Icon
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    getUserData();

    const getRole = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };
    const orgiRole = getRole("orginalRole");
    // console

    if (orgiRole !== null) {
      const parsedEntryType = orgiRole;
      setOrgRole(parsedEntryType);
    }
  }, []);

  useEffect(() => {
    const roleCheck = Cookies.get("orginalRole");
    console.log(roleCheck, "roleCheck");

    if (roleCheck === "Trainer") {
      setTrainerChecked(true);
    } else if (roleCheck === "Mentor") {
      setMentorChecked(true);
    } else if (roleCheck === "Trainee") {
      setTraineeChecked(true);
    }
  }, []);

  const switchStatus = localStorage.getItem("isSwitch");
  console.log(switchStatus, "switchStatus");

  const handleChangePassword = async () => {
    navigate({
      pathname: "/user/changepassword",
    });
    handleClose();
  };
  const getUserData = async () => {
    API.getUserDetails().then(({ response }) => {
      const userDetails = response.data.data;
      setUserData(userDetails);
      if (response.data.api_status === 200) {
        Cookies.set("user_pk", response.data.data.user_pk, {
          expires: 30,
        });
      }
    });
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          style={{ backgroundColor: "#000936" }}
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
            className="column"
          >
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: "12px",
                paddingRight: "15px !important",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="menuiconBtn" />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
              style={{
                alignSelf: "start",
                textAlign: "start",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: 25,
                marginTop: "11px",
                marginLeft: "40px",
              }}
            >
              ABSYZ Academy
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                className="profilebtn"
                sx={{
                  backgroundColor: "#fb2485",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                aria-controls={isOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img src={pIcon} alt="person icon" className="userIcon2" />
                <Typography className="profilebtnTxt">
                  {userData ? userData.first_name : ""}
                </Typography>
                {pAnchorEl ? (
                  <KeyboardArrowUpRoundedIcon style={{ color: "#fff" }} />
                ) : (
                  <KeyboardArrowDownRoundedIcon style={{ color: "#fff" }} />
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={pAnchorEl}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{ marginTop: "10px" }}
              >
                <MenuList>
                  <div onClick={handleClose} className="Account">
                    <AccountCircleIcon
                      style={{ width: "38px", height: "38px" }}
                      className={"AccountIcon"}
                    />

                    <p
                      className="AccountLabel"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {(userData  ? (userData.first_name ? userData.first_name : " ") + " " + (userData.last_name ? userData.last_name : "" ): "")}
                    </p>
                    <p
                      className="AccountLabel2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {userData ? userData.designation : ""}
                    </p>
                  </div>

                  {/* {orgRole !== "Trainee" &&
                  location.pathname !== "/user/" &&
                  switchStatus === "true" ? (
                    <>
                      {orgRole === "Mentor" ||
                      location.pathname === "/user/bootcamp/mentor/home/" ? (
                        <MenuItem
                          style={{
                            width: "192px",
                            height: "38px",

                            padding: "7px 48px 7px 11px",
                            borderRadius: " 4px",
                          }}
                        >
                          <img
                            src={AIcon}
                            alt="AIcon"
                            width="18px"
                            height="17px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography className="badge">Trainer</Typography>
                          <Switch
                            checked={checked}
                            onChange={handleTrainerChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        </MenuItem>
                      ) : orgRole === "Trainer" ||
                        location.pathname === "/user/bootcamp/trainer/home/" ? (
                        <MenuItem
                          style={{
                            width: "192px",
                            height: "38px",

                            padding: "7px 48px 7px 11px",
                            borderRadius: " 4px",
                          }}
                        >
                          <img
                            src={AIcon}
                            alt="AIcon"
                            width="18px"
                            height="17px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography className="badge">Mentor</Typography>
                          <Switch
                            checked={MChecked}
                            onChange={handleMentorChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size={"small"}
                          />
                        </MenuItem>
                      ) : null}
                    </>
                  ) : null} */}

                  {location.pathname !== "/user/" &&
                  uniqueRoleNames !== null &&
                  uniqueRoleNames !== undefined &&
                  uniqueRoleNames.length > 0 ? (
                    <>
                      {uniqueRoleNames.includes("Mentor") ? (
                        <MenuItem
                          style={{
                            width: "192px",
                            height: "38px",

                            padding: "7px 48px 7px 11px",
                            borderRadius: " 4px",
                          }}
                        >
                          <img
                            src={AIcon}
                            alt="AIcon"
                            width="18px"
                            height="17px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography className="badge">Mentor</Typography>
                          <Switch
                            checked={MentorChecked}
                            disabled={MentorChecked === true ? true : false}
                            onChange={handleMentorChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size={"small"}
                          />
                        </MenuItem>
                      ) : null}

                      {uniqueRoleNames.includes("Trainee") ? (
                        <MenuItem
                          style={{
                            width: "192px",
                            height: "38px",

                            padding: "7px 48px 7px 11px",
                            borderRadius: " 4px",
                          }}
                        >
                          <img
                            src={AIcon}
                            alt="AIcon"
                            width="18px"
                            height="17px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography className="badge">Trainee</Typography>
                          <Switch
                            checked={TraineeChecked}
                            disabled={TraineeChecked === true ? true : false}
                            onChange={handleTraineeChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size={"small"}
                          />
                        </MenuItem>
                      ) : null}

                      {uniqueRoleNames.includes("Trainer") ? (
                        <MenuItem
                          style={{
                            width: "192px",
                            height: "38px",

                            padding: "7px 48px 7px 11px",
                            borderRadius: " 4px",
                          }}
                        >
                          <img
                            src={AIcon}
                            alt="AIcon"
                            width="18px"
                            height="17px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography className="badge">Trainer</Typography>
                          <Switch
                            checked={trainerChecked}
                            disabled={trainerChecked === true ? true : false}
                            onChange={handleTrainerChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        </MenuItem>
                      ) : null}
                    </>
                  ) : null}

                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={() => {
                      navigate("/user/profile");
                      handleClose();
                    }}
                  >
                    <AccountCircleOutlinedIcon
                      style={{
                        marginLeft: "-4px",
                        color: "#4b5e89",
                        marginRight: "10px",
                      }}
                    />
                    <Typography className="badge">My Profile</Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={handleChangePassword}
                  >
                    <LockResetRoundedIcon
                      style={{
                        marginLeft: "-4px",
                        color: "#4b5e89",
                        marginRight: "10px",
                      }}
                    />
                    <Typography className="badge">Change Password</Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: "192px",
                      height: "38px",

                      padding: "7px 48px 7px 11px",
                      borderRadius: " 4px",
                    }}
                    onClick={onClickLogout}
                  >
                    <img
                      src={logout}
                      alt="logout"
                      width="18px"
                      height="13.8px"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography className="badge">Logout</Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} className={classes.openDrawer}>
          <Toolbar
            style={{
              backgroundColor: "#000936",
              display: "flex",
              justifyContent: "end",
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              backgroundColor: "#000936",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {<MenuItems active={active} hActive={hActive} />}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "hidden !important",
          }}
        >
          <Toolbar />
          <Container
            className="adminlayout"
            maxWidth="xxl"
            sx={{ minheight: "40vh", padding: "0px !important" }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </ThemeProvider>
  );
}

export default UserHomePage;
