function convertToCSV(objArray, fields) {
  let str = fields.join(",") + "\r\n";

  for (let i = 0; i < objArray.length; i++) {
    let line = "";
    for (let j = 0; j < fields.length; j++) {
      if (line !== "") line += ",";

      line += objArray[i][fields[j]];
    }

    str += line + "\r\n";
  }
  return str;
}

function downloadCSV(objArray, fields, fieldName) {
  const csv = convertToCSV(objArray, fields, fieldName);
  const blob = new Blob([csv], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${fieldName}.csv`;
  link.click();
}

export default downloadCSV;
