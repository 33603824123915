import React, { useRef, useState, useEffect } from "react";

export default function VideoPlayer(props) {
  const { videoLink, setCurrentVideoTime, setVideoTotalTime } = props;

  const videoRef = useRef(null);
  var supposedCurrentTime = 0;

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleSeeking = () => {
      var delta = videoElement.currentTime - supposedCurrentTime;
      if (Math.abs(delta) > 0.01) {
        videoElement.currentTime = supposedCurrentTime;
      }
    };
    const handleEnded = () => {
      supposedCurrentTime = 0;
    };
    const handleTimeupdate = () => {
      if (!videoElement.seeking) {
        setVideoTotalTime(videoElement.duration);
        supposedCurrentTime = videoElement.currentTime;
        setCurrentVideoTime(videoElement.currentTime);
      }
      setVideoTotalTime(videoElement.duration);
      setCurrentVideoTime(videoElement.currentTime);
    };
    videoElement.addEventListener("seeking", handleSeeking);
    videoElement.addEventListener("ended", handleEnded);
    videoElement.addEventListener("timeupdate", handleTimeupdate);
    return () => {
      videoElement.removeEventListener("seeking", handleSeeking);
      videoElement.removeEventListener("ended", handleEnded);
      videoElement.removeEventListener("timeupdate", handleTimeupdate);
    };
  }, []);
  return (
    <div>
      <video
        ref={videoRef}
        style={{
          //height: "60vh",
          width: "57vw",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "auto",
          overflowX: "auto",
          cursor: "not-allowed",
        }}
        src={videoLink}
        controls
        title="Material"
      ></video>
    </div>
  );
}
