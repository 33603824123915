import { React, useState, useEffect } from "react";
import "./mentor-sessions-details.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Custom } from "../../../components/Custom/Custom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
// import ReusableInputfield from "../../../components/reusable-input/reusable-input";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import Notification from "../../../components/Custom/Notification";
import Cookies from "js-cookie";
import { API } from "../../../Networking/API";
import { TextareaAutosize } from "@mui/material";
export const MenteeSessionsDetailsAssessedAssignment = ({
  assignments,
  assignmentData,
  apiStatus,
  status,
  trainerID,
  asnfkValue,
  getMentorAssignmentData,
  asnidFkValue,
  paramsValue,
  scroreforupdate,
}) => {
  const [scoreValue, setScoreValue] = useState([]);
  const [NeedsApiCall, setNeedsApiCall] = useState();
  // const [statusForUpdateAndSubmit, setStatusForUpdateAndSubmit] =
  //   useState(false);
  let updateMarksMessage = false;
  const [textAreaValues, setTextAreaValues] = useState(Array(2).fill(""));
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  useEffect(() => {
    setScoreValue([]);
  }, [trainerID]);
  useEffect(() => {
    if (assignmentData?.score?.length > 0) {
      const newInputValues = [...scoreValue];
      const remartInputvalue = [...textAreaValues];
      assignmentData?.score.map((items, index) => {
        newInputValues[index] = items.AssignmentScore.score;
        remartInputvalue[index] = items.AssignmentScore.remarks;
        setScoreValue(newInputValues);
        setTextAreaValues(remartInputvalue);
      });
    } else {
      setScoreValue([]);
      setTextAreaValues([]);
    }
  }, [trainerID, assignmentData]);

  const dropdownValue = [
    { id: "0", title: "0" },
    { id: "1", title: "1" },
    { id: "2", title: "2" },
    { id: "3", title: "3" },
    { id: "4", title: "4" },
    { id: "5", title: "5" },
  ];

  const checkMarksAndSubmit = () => {
    var isMarksLess;

    if (assignmentData.params !== undefined) {
      isMarksLess = assignmentData?.params?.every((item, index) => {
        const enteredValue = parseInt(scoreValue[index] || 0);
        return enteredValue <= item.marks;
      });
    }

    if (isMarksLess) {
      setNeedsApiCall(true);
    } else {
      setNeedsApiCall(false);
    }
  };

  const handleChange = (index, value, isTextArea) => {
    if (isTextArea) {
      const newValue = value.replace(/[^A-Za-z]+/g, "");
      const newTextAreaValues = [...textAreaValues];
      newTextAreaValues[index] = newValue;
      setTextAreaValues(newTextAreaValues);
    } else {
      const newValue = value.replace(/[^0-9]/g, "");
      // Check if newValue is empty or a number (not NaN)
      const parsedValue = newValue === "" ? "" : parseInt(newValue);
      const newInputValues = [...scoreValue];
      newInputValues[index] = parsedValue;
      setScoreValue(newInputValues);
    }
  };

  const handleSubmitButton = async () => {
    // setStatusForUpdateAndSubmit(status);
    const scores = Object.values(scoreValue);
    var size = Object.keys(scoreValue).length;
    const textarea1 = Object.values(textAreaValues);
    var textareasize = Object.keys(textAreaValues).length;
    checkMarksAndSubmit();
    var checkMarkss;
    if (assignmentData.params !== undefined) {
      checkMarkss = true;
      assignmentData?.params?.forEach((value, index) => {
        if (
          value.marks < scoreValue[index] ||
          scoreValue[index] === "" ||
          scoreValue[index] === undefined
        ) {
          checkMarkss = false;
        }
      });
    }
    if (assignmentData.score !== undefined) {
      checkMarkss = true;
      updateMarksMessage = true;
      assignmentData?.score?.forEach((value, index) => {
        if (value.marks < scoreValue[index] || scoreValue[index] === "") {

          checkMarkss = false;
        }
      });
    }
    var holder;
    holder = [];
    for (let i = 0; i < size; i++) {
      holder.push({
        asn_fk: asnfkValue,
        params_fk: assignmentData.params
          ? paramsValue[i].params_pk
          : scroreforupdate[i].params_pk,
        user_id: trainerID,
        score: scores[i],
        asnid_fk: asnidFkValue,
        remarks: textarea1[i],
      });
    }

    const raw = JSON.stringify(holder);

    if (checkMarkss) {
      API.getMentorAssignDataAssessed(raw).then(({ response }) => {
        if (response.api_status === 200) {
          getMentorAssignmentData();
          setNotify({
            isOpen: true,
            message: updateMarksMessage
              ? "Succesfully  updated the scores"
              : "Successfully assigned the scores",
            type: "success",
          });
        }
      });
      // }
    } else if (
      scoreValue[0] === "" ||
      scoreValue[1] === "" ||
      scoreValue[0] === undefined ||
      scoreValue[1] === undefined
    ) {
      setNotify({
        isOpen: true,
        message: "Scores can't be empty",
        type: "error",
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Scores Achieved Should be Less than Marks",
        type: "error",
      });
    }
  };
  //
  return (
    <>
      {assignments.length === 0 ? (
        <Box
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="error-msg">No Data Found...</span>
        </Box>
      ) : (
        <>
          <Box style={{ display: "flex" }}>
            <span className="assignment-text">
              Assignment : {assignments[0].topic}
            </span>
          </Box>

          {assignments[0].as_description !== null ? (
            assignments[0].as_description.length !== 0 ? (
              <Box style={{ marginTop: "15px", display: "flex" }}>
                <span className="required-text">
                  <b>Required :</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: assignments[0].as_description,
                    }}
                  />
                </span>
              </Box>
            ) : null
          ) : null}

          {apiStatus && status === true ? (
            <>
              {assignmentData.astmt_status === 2 ? (
                <>
                  <Box
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      backgroundColor: "#80808054",
                      padding: "8px 20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submission Status
                        </span>
                        <p className="table-values-text">Assessed</p>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submitted Time
                        </span>
                        <p className="table-values-text">
                          {assignmentData.last_update}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Score Achieved
                        </span>
                        <p className="table-values-text">
                          {assignmentData.score_achived !== "-"
                            ? `${assignmentData.score_achived} of ${assignmentData.total_score}`
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginTop: "16px",
                    }}
                  >
                    <span className="attachments">Assignment Attachments</span>

                    <div style={{ marginTop: "16px" }}>
                      {assignmentData.attachments.map((items, index) => (
                        <a
                          href={items.asn_link}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <Button
                            variant="outlined"
                            startIcon={
                              <RemoveRedEyeOutlinedIcon
                                style={{ fill: "#0078d4" }}
                              />
                            }
                            style={{
                              marginRight: "20px",
                              textDecoration: "none",
                              textTransform: "initial",
                              color: "#000",
                              border: "solid 1px #c9c9c9",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                            }}
                          >
                            {/* Attachments {index + 1} */}
                            <p className="attachment-title">
                              {`Attachment file ${index + 1}`}
                            </p>
                          </Button>
                        </a>
                      ))}
                    </div>
                  </Box>

                  {assignmentData.score.length != 0 ? (
                    <Box style={{ marginTop: "20px" }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          style={{ padding: "18px 18px 0px 18px" }}
                        >
                          <Box
                            style={{
                              background: "#02215b",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Grid item xs={5}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Parameters
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <span
                                style={{ paddingLeft: "20%", color: "#fff" }}
                              >
                                Marks
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Scores Achieved
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Remarks
                              </span>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <span className="error-msg">
                      Parameters are not yet uploaded for review.
                    </span>
                  )}

                  {assignmentData.score.map((items, index) => (
                    <>
                      <ListItem
                        className="mentor-list"
                        style={{ padding: "0px" }}
                        disablePadding
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <Box role={undefined} dense>
                              <ListItemText
                                className="parameter-list"
                                primary={items.params_name}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              paddingRight: "20px",
                            }}
                          >
                            <Box role={undefined} dense>
                              <ListItemText
                                primary={items.marks}
                                className="parameter-list"
                                style={{
                                  textAlign: "center",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              paddingLeft: "40px",
                            }}
                          >
                            <Box role={undefined} dense>
                              {/* ReusableInputfield */}
                              <ReusableInputfield
                                key={index}
                                className="name-Training-material"
                                name={`Field_${index}`}
                                id={`Field_${index}`}
                                placeholder="Score"
                                // type="number"
                                type="text"
                                InputProps={{
                                  inputProps: { min: 0, max: items.marks },
                                }}
                                value={
                                  scoreValue.length > 0 ? scoreValue[index] : ""
                                }
                                onChange={(e) =>
                                  handleChange(index, e.target.value, false)
                                }
                                size="small"
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              paddingRight: "20px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              role={undefined}
                              dense
                            >
                              <TextareaAutosize
                                style={{
                                  height: "38px",
                                }}
                                value={textAreaValues[index] || ""}
                                onChange={(e) =>
                                  handleChange(index, e.target.value, true)
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider
                        style={{
                          marginTop: "9px",
                          marginBottom: "9px",
                          marginLeft: "15px",
                          marginRight: "15px",
                          backgroundColor: "#bfbfbf",
                        }}
                      />
                    </>
                  ))}

                  {assignmentData?.score.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                        marginRight: "30px",
                      }}
                    >
                      {/* {buttonTrue ?  */}
                      <ReusableButton
                        onClick={handleSubmitButton}
                        type="Submit"
                        size="large"
                        variant="contained"
                        buttonName="Update"
                        style={{
                          backgroundColor: "#fb2485",
                          borderRadius: 4,
                          padding: "8px 36px",
                          width: "117px",
                          height: "38px",
                          flexGrow: 0,
                          margin: " 3px 0 3px 30px",
                        }}
                        className="buttonStyle"
                      />
                    </Box>
                  ) : null}
                </>
              ) : (
                <>
                  <Box
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      backgroundColor: "#80808054",
                      padding: "8px 20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submission Status
                        </span>
                        <p className="table-values-text">Submitted</p>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Submitted Time
                        </span>
                        <p className="table-values-text">
                          {assignmentData.last_update}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="table-data-headers-text">
                          Score Achieved
                        </span>
                        <p className="table-values-text">
                          {assignmentData.score_achived !== "-"
                            ? `${assignmentData.score_achived} of ${assignmentData.total_score}`
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      marginTop: "16px",
                    }}
                  >
                    <span className="attachments">Assignment Attachments</span>

                    <div style={{ marginTop: "16px" }}>
                      {assignmentData.attachments.map((items, index) => (
                        <a
                          href={items.asn_link}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <Button
                            variant="outlined"
                            startIcon={
                              <RemoveRedEyeOutlinedIcon
                                style={{ fill: "#0078d4" }}
                              />
                            }
                            style={{
                              marginRight: "20px",
                              textDecoration: "none",
                              textTransform: "initial",
                              color: "#000",
                              border: "solid 1px #c9c9c9",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                            }}
                          >
                            {/* Attachments {index + 1} */}
                            <p className="attachment-title">
                              {`Attachment file ${index + 1}`}
                            </p>
                          </Button>
                        </a>
                      ))}
                    </div>
                  </Box>

                  <Box style={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{ padding: "18px 18px 0px 18px" }}
                      >
                        {assignmentData.params.length != 0 ? (
                          <Box
                            style={{
                              background: "#02215b",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Grid item xs={5}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Parameters
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <span
                                style={{ paddingLeft: "20%", color: "#fff" }}
                              >
                                Marks
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Scores Achieved
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{ paddingLeft: "25px", color: "#fff" }}
                              >
                                Remarks
                              </span>
                            </Grid>
                          </Box>
                        ) : (
                          <span className="error-msg">
                            Parameters are not yet uploaded for review.
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {assignmentData.params.map((items, index) => (
                    <>
                      <ListItem
                        className="quiz-list"
                        style={{ padding: "0px" }}
                        disablePadding
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <Box role={undefined} dense>
                              <ListItemText
                                primary={items.params_name}
                                className="parameter-list"
                                style={{ paddingLeft: "35px" }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              paddingRight: "20px",
                            }}
                          >
                            <Box role={undefined} dense>
                              <ListItemText
                                primary={items.marks}
                                className="parameter-list"
                                style={{
                                  paddingLeft: "20px",
                                  textAlign: "center",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              paddingLeft: "40px",
                            }}
                          >
                            <Box role={undefined} dense>
                              <ReusableInputfield
                                key={index}
                                className="name-Training-material"
                                name={`Field_${index}`}
                                id={`Field_${index}`}
                                placeholder="Score"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0, max: items.marks },
                                }}
                                value={
                                  scoreValue.length > 0 ? scoreValue[index] : ""
                                } // Use the corresponding value from state
                                onChange={(e) =>
                                  handleChange(index, e.target.value, false)
                                } // Pass index and value to handleChange, false for scores field (pass true for only remarks)
                                size="small"
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              paddingRight: "20px",
                            }}
                          >

                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              role={undefined}
                              dense
                            >
                              <TextareaAutosize
                                style={{
                                  height: "38px",
                                  fontSize: "16px",

                                }}
                                value={textAreaValues[index] || ""}
                                onChange={(e) =>
                                  handleChange(index, e.target.value, true)
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider
                        style={{
                          marginTop: "9px",
                          marginBottom: "9px",
                          marginLeft: "15px",
                          marginRight: "15px",
                          backgroundColor: "#bfbfbf",
                        }}
                      />
                    </>
                  ))}

                  {assignmentData?.params.length !== 0 ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                        marginRight: "30px",
                      }}
                    >
                      {/* {buttonTrue ?  */}
                      <ReusableButton
                        onClick={handleSubmitButton}
                        type="Submit"
                        size="large"
                        variant="contained"
                        buttonName="Submit"
                        style={{
                          backgroundColor: "#fb2485",
                          borderRadius: 4,
                          padding: "8px 36px",
                          width: "117px",
                          height: "38px",
                          flexGrow: 0,
                          margin: " 3px 0 3px 30px",
                        }}
                        className="buttonStyle"
                      />
                    </Box>
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
