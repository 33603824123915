const BaseURL =
  process.env.REACT_APP_STAGE === "PROD"
    ? "https://api.absyzacademy.com"
    : process.env.REACT_APP_STAGE === "STAGING"
    ? "https://stageapi.absyzacademy.com"
    : "http://localhost:3033";
const APIRequests = {
  login: "/api/auth",
  register: "/api/create-account",
  updateUserData: "/api/update-user-data",
  getTraineeData: "/trainee/dashboard",
  getTraineeDashData: "/trainee/dashboard",
  getUserProfileDetails: "/aa-users/user-details",
  updateProfileDetails: "/aa-users/profile",
  AdminTraineUploadGet: "/admin/trainee/",
  AdminTrainerUplodeDelete: "/admin/question-upload/",
  mentorDashboard: "/mentor/dashboard",
  mentorSwitch: "/switch/trainer-to-mentor",
  mentorTotrainerSwitch: "/mentor/mentor-trainer",
  trainerDashboard: "/trainer/dashboard",
  changePassword: "/aa-user/reset-password",
  adminLoginUrl: "/aa-user/login",
  adminSignupUrl: "/aa-user/set-password",
  TrainerSessionCalender: "/trainee/trainee-calender",
  TraineeSessionTraineeData: "/trainee/trainee-date",
  TraineeSessionDetails: "/trainee/schedule",
  MentorDashBoard: "/mentor/dashboard",
  UserForgetPassword: "/aa-user/reset-email",
  ResetPassword: "/aa-user/reset-password",
  AdminAddAssignment: "/admin/unit-assignment-details?",
  AdminGetAssignment: "/admin/add-assignment-only",
  adminDeleteAssignment: "/admin/delete-assignment?",
  AdminParameters: "/admin/assignments-params",
  AdminAddParameters: "/admin/add-training-material",
  AdminGetDeparment: "/admin/get-all-table-quizz",
  AdminAddQuiz: "/admin/add-quiz",
  AdminGetPrerequisites: "/admin/pre-requisites",
  AdminAddPrerequisites: "/admin/add-pre-requisites",
  AdminGetTrainingMaterial: "/admin/training-material",
  AdminAddTrainingMaterail: "/admin/add-training-material",
  AdminGetSchedule: "/admin/schedulesupload/",
  AdminDeleteSchedule: "/admin/schedule",
  TraineeGetFeedById: "/trainee/feedBack-schedule",
  TraineeSubmitAssignment: "/trainee/submit-assignment",
  TraineeLastSubmit: "/trainee/last-submit",
  TraineeHackathon: "/trainee/Hackathon-Trainee",
  TraineeHackathonSubmit: "/trainee/task-final-submit",
  QuestionsGetAllQuestions: "/trainee/get-questions/",
  QuestionOnclickPrevious: "/trainee/question-validation",
  QuestionOnclickPrevious1: "/trainee/get-oldOption",
  QuestionsResultsOfQuiz: "/trainee/quiz-result/",
  MentorSessionsFilter: "/mentor/calender?filter=",
  MentorSearch: "&search=",
  mentorHackathon: "/mentor/hackathons-details",
  mentorAssignment: "/mentor/assignments",
  updatedScoresRemarks: "/mentor/update-remarks",
  mentorQuiz: "/mentor/quizzDetails",
  mentorSchedule: "/mentor/schedule/",
  mentorSearch: "/mentor/traineeFilter?search=",
  adminQuestionsUploadGet: "/admin/quiz-all-questions?",
  adminQuestionsUploadPost: "/admin/bulk-question-upload",
  adminDepartmentsGet: "/admin/roles",
  adminCandidatesGet: "/admin/AddUser",
  adminAddCandidatePost: "/admin/AddUser",
  adminAddCandidateMail: "/admin/send-email",
  adminCandidateDelete: "/admin/userDetails",
  adminQuestionDelete: "/admin/question-upload",
  adminCandidateByIdGet: "/admin/userDetails",
  adminaddbatchid: "/admin/add-batch",
  adminHackathonGet: "/admin/Hackathons",
  adminHackathonUploadPost: "/admin/schedule-upload",
  adminHackathonDelete: "/admin/schedule",
  getBootcamps: "/admin/add-batch",
  trainerHackathon: "/trainer/Hackathons",
  trainerHackathonScore: "/trainer/score-board/",
  trainerHackathonTopScore: "/trainer/top-five-score-board/",
  trainerAssignmentDataGet: "/trainer/traineeResultAsnmt",
  trainerTaskDataGet: "/trainer/hackathon-details",
  trainerQuizDataGet: "/trainer/traineeQuizzes",
  trainerSessionDetailsGet: "/trainer/schedule",
  trainerQuizEnablePost: "/trainer/start-quizz",
  trainerUsersGet: "/trainer/traineefilter",
  TrainerSessionsFilter: "/trainer/trainer-calender?filter=",
  addBatch: "/admin/add-batch",
  getAllBatch: "/admin/add-batch",
  getSchedulers: "/admin/getschedules",
  getAssignmnet: "/admin/assignmentquiz",
  getScheduleWithDate: "/admin/schedulewithdate",
  getHackathonOption: "/admin/gethackathononbatch/",
  getHackathonDataSearch: "/admin/getsinglehackathon/",
  getViewIconData: "/admin/assignmentorquiz",
  getScheduledataBySearch: "/admin/searchreports",
  getViewIconHackathonData: "/admin/hackathonviews",
  getEditAssignmentData: "/admin/assignment-details",
  postSchedulesearchonbatch: "/admin/schedule-search-on-batch/",
  getAdminSessionDetails: "/admin/getscheduledetailsonId/",
  getAdminTrainers: "/admin/trainers-details/",
  postTrainerSearchOnBatchApi: "/admin/search-trainee-on-batch",
  AdminDeletePreRequisite: "/admin/delete-pre-requisite",
  adminDeleteAsnParams: "/admin/params/",
  adminAddAsnParams: "/admin/add-params-on-assignment",
  adminEditScheule: "/admin/edit-schedule",
  adminAddAsn: "/admin/add-assignment-only",
  adminEditAsn: "/admin/edit-single-assignment",
  adminEditTrainer: "/admin/edit-trainer-for-schedule",
  AdminDeleteTrainingMaterial: "/admin/training-material-delete",
  AdminGetTraineeDetails: "/admin/getTraineeDetails",
  AdminAddCourse: "/admin/add-course",
  AdminGetCourse: "/admin/get-course",
  AdminCreateCourse: "/admin/create-course",
  AdminGetDifficultyLevels: "/admin/difficulty_level",
  AdminDeleteUnit: "/admin/unit-delete",
  AdminDeleteCourse: "/admin/course-delete/",
  GetDifficulty: "/admin/difficulty_level",
  GetAllTags: "/admin/alltags",
  AddUnit: "/admin/add-unit",
  GetUnit: "/admin/unit",
  CreateUnit: "/admin/unit-creation",
  GetAllUnit: "/admin/all-units-details",
  GetSearchedUnit: "/admin/allUnits",
  AdminGetAllUnits: "/admin/all-units-details",
  AdminGetAllCourses: "/admin/all-course-details",
  getAllUserCourses: "/user/courses",
  getAllUserUnits: "/user/units",
  AdminGetQuizType: "/admin/quiz-questions-types/",
  AdminEditUnit: "/admin/edit-unit",
  AdminGetQuizzes: "/admin/unit-quiz-details?",
  AdminDeleteQuizess: "/admin/topic-quiz-delete",
  AdminSaveLaterBtn: "/admin/unit-save-later/",
  AdminSaveLaterApi: "/admin/mandatory-training-save-later/",
  AdminEditCourse: "/admin/edit-course/",
  AdminQuizQuestionsDelete: "/admin/quiz-question-delete",
  AdminTrainingmaterialReorder: "/admin/reorder-training-material/",
  AdminPrerequisitesReorder: "/admin/reorder-pre-requisite/",
  AdminCourseUnitReorder: "/admin/reorder-course",
  AdminPreDescriptionEdit: "/admin/update-pre-requisite-description",
  UserGetCourseDetailsById: "/user/course-units/",
  UserCoursesFeedback: "/user/feed-back",
  UserProfileDetails: "/aa-users/user-details",
  UserUnitDetails: "/user/unit-details",
  UserUnitStart: "/user/start-tracking",
  UserGetAssignmentById: "/user/get-assignment",
  UserDeleteAssignment: "/user/delete-assignment-link",
  UserUploadAssignment: "/user/upload-assignment",
  TraineeUploadAssignment: "/trainee/upload-assignment",
  UserSubmitAssignment: "/user/assignment-submit",
  UserGetAllQuestions: "/user/get-questions",
  UserOnclickPrevious: "/user/question-validation",
  UserOnclickPrevious1: "/user/get-oldOption",
  UserResultsOfQuiz: "/user/quiz-result/",
  TrainingMaterialTrack: "/user/submit-tracking",
  UserChangePassword: "/aa-users/change-password",
  UserPreRequisiteTrack: "/user/pre-requisites-track",
  UserTrainingMaterialTrack: "/user/training-material-track",
  EnableAssignmentUpload: "/admin/edit-assignment-status",
  AddProgram: "/admin/add-program",
  AddBootCamp: "/admin/add-batch",
  GetSearchedCourse: "admin/all-courses",
  AdminGetAllProgram: "/admin/all-programmes",
  AdminProgramById: "/admin/program-details/",
  AdminEditProgram: "/admin/edit-program/",
  AdminEditBootCamp: "/admin/batch/",
  AssignmentUploadparams: "/admin/assignment-params",
  AdminParamsData: "/admin/assignment-params",
  AdminUploadProgramParticipant: "/admin/upload-participates/",
  AdminUploadBootCampParticipant: "/admin/participates/",
  deleteAdminProgramCandidatePut: "/admin/delete-participate",
  deleteAdminBootCampParticipant: "/admin/participates",
  AdminGetTrainer: "/admin/get-users",
  AdminDeleteProgramUnitCourse: "/admin/unlink-course-or-unit",
  AdminUpdateProgram: "/admin/update-program",
  AdminUpdateBootCampSchedule: "/admin/update-schedule/",
  AdminGetParticipates: "/admin/program-participates/",
  AdminBootCampParticipates: "/admin/participates/",
  AdminCreateProgram: "/admin/create-program?",
  AdminCreateBootCamp: "/admin/batch/",
  AdminDeleteProgram: "/admin/delete-program",
  UserMandatoryTraning: "/user/mandatory-training-details",
  AdminCreateMandatoryTraining: "/admin/add-mandatory-training",
  AdminEditMandatoryTraining: "/admin/edit-mandatory-training/",
  AdminGetMandateById: "/admin/mandatory-training/",
  AdminGetMandatoryTrainings: "/admin/all-mandatory-trainings",
  AdminPublish: "/admin/mandatory-training-publish",
  getAllUserTraining: "/user/all-mandatory-trainings",
  getUserTrainingDetails: "/user/mandatory-training",
  getUserTrackMandatoryTraining: "/user/start-track-mandatory-training",
  AdminMandatoryTrainingMaterial:
    "/admin/mandatory-training-add-training-material/",
  deleteAdminMandatoryTrainingMaterial:
    "/admin/delete-mandatory-training-material?",
  deleteAdminMandatoryTrainingIntroSlides: "/admin/delete-slide?",
  viewAdminMandatoryTrainingMaterial:
    "/admin/mandatory-training-material-quiz-question-details?",
  AdminQuizTrainingStatusChange:
    "/admin/mandatory-training-quiz-mandatory-status-change",
  getMandateModuleQuizData: "/user/mandatory-training-start-quiz",
  ValidateMandateAns: "/user/mandatory-training-answer-validation",
  FinalMandetQuizSubmt: "/user/mandatory-training-submit-quiz",
  SubmitTrainingModule: "/user/mandatory-training-material-update",
  AddAdminGroupImages: "/admin/add-newslide-group-of-images",
  AddVideo: "/admin/add-newslide-video",
  GetAllSlidesOfMandatoryTraining: "/admin/get-allslides/",
  MandatoryTrainingMaterialTrack: "/user/finish-mandatory-training",
  AddNewSlideQuestion: "/admin/add-newslide-questions",
  editAdminMandatoryTrainingIntroSlides: "/admin/edit-slide",
  getImageSlides: "/admin/get-allsubslides",
  addImageSlides: "/admin/add-extra-newslide-group-of-images",
  AddSingleImageToSlide: "/admin/edit-slide-element",
  ReorderImageSlides: "/admin/reorder-mandatory-training-slide-elements",
  DeleteImageFromSlide: "/admin/delete-slide-element",
  ReoderSlide: "/admin/reorder-mandatory-training-slides",
  ValidateUserAns: "/user/mandatory-training-question-track",
  videoWithQuizz: "/admin/add-video-with-quiz",
  AuthintecateConnectUser: "/aa-user/connect-login",
  AddPPTForMandatoryTraining: "/admin/add-ppt-for-mandatory-training/",
  AddPreRequisitesStatus: "/admin/unit-pre-requisites-status-change",
  DashboardCounts: "/user/units-courses-counts",
  DashBoardScore: "/user/get-completed-score",
  UserAllPrograms: "/user/all-programmes",
  AdminAddBootCamp: "/admin/add-batch/",
  AdminAddparticipants: "/admin/add-participants-and-assignmentortrainee/",
  AdminGetMentor: "/admin/trainee-mentors/",
  // AdminDeleteMentor: "/admin/delete-trainee-to-mentor",
  AdminDeleteMentor: "/admin/delete-trainee-to-mentor",
  AdminEditAssignment: "/admin/edit-assignment",
  AdminEditQuizconfiguration: "/admin/edit-quiz",
  getTraineeTopAchieversData: "/trainee/top-achievers",
  getTraineeScoresAchievedData: "/trainee/user-result",
  startAssignmentApi: "/trainee/startTask?asn_pk=",
};

export { BaseURL, APIRequests };
