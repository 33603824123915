"Use Strict";
import { parse } from "date-fns";
import BaseApiService from "./BaseApiServices";
import { APIRequests, BaseURL } from "./NetworkConstants";

function getTraineeDetails() {
  let id = `/${86}`;
  return BaseApiService.get(BaseURL + APIRequests.getTraineeData + id);
}

function getTraineeDashBoardData(Courseid) {
  let id = `/${Courseid}`;
  return BaseApiService.get(BaseURL + APIRequests.getTraineeDashData + id);
}

function getTraineeTopAchievers(batch_id) {
  let id = `/${batch_id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getTraineeTopAchieversData + id
  );
}

function getTraineeScoresAchieved(batch_id) {
  let id = `/${batch_id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getTraineeScoresAchievedData + id
  );
}

function getProfileInfo() {
  return BaseApiService.get(BaseURL + APIRequests.getUserProfileDetails);
}

function getTraineeallquestions(quizNumber01) {
  const Id = `${quizNumber01}`;
  return BaseApiService.get(
    BaseURL + APIRequests.QuestionsGetAllQuestions + Id
  );
}

function getTraineeQuizResults(quizNumber01) {
  const Id = `${quizNumber01}`;
  return BaseApiService.get(BaseURL + APIRequests.QuestionsResultsOfQuiz + Id);
}

function postTraineeOnclick(optionsOpted, param, id, Timer) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: Timer,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.QuestionOnclickPrevious,
    params,
    header
  );
}

function putTraineeOnclick(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.QuestionOnclickPrevious1,
    params,
    header
  );
}

function postTraineeOnClickNext(optionsOpted, param, id, Timer) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: Timer,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.QuestionOnclickPrevious,
    params,
    header
  );
}

function putTraineeOnClickNext(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.QuestionOnclickPrevious1,
    params,
    header
  );
}

function postTraineeAPICall(optionsOpted, param, id, Timer) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: Timer,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.QuestionOnclickPrevious,
    params,
    header
  );
}
function postTraineehandleClik(optionsOpted, param, id, Timer) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: Timer,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.QuestionOnclickPrevious,
    params,
    header
  );
}

function putTraineehandleClik(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.QuestionOnclickPrevious1,
    params,
    header
  );
}

function getAdminTrainerUpload(batchID) {
  const Id = `${batchID}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminTraineUploadGet + Id);
}

function deleteAdminTrainerDelete(params) {
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminTrainerUplodeDelete + `${params}`
  );
}

function postAdminTrainerUploadPost(fileMerge, batchID) {
  let params = new FormData();

  params.append("file", fileMerge);

  const header = false;
  const Id = `${batchID}`;
  return BaseApiService.post(
    BaseURL + APIRequests.AdminTraineUploadGet + Id,
    params,
    header
  );
}

function traineeChangePassword(email, password, confirmPassword) {
  const params = JSON.stringify({
    email: email,
    password: password,
    confirmPassword: confirmPassword,
  });
  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.changePassword,
    params,
    header
  );
}

function adminLogin(email, password) {
  const header = true;
  const params = JSON.stringify({
    email: email,
    password: password,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.adminLoginUrl,
    params,
    header
  );
}

function signupPage(email, password, confirmPassword) {
  const header = true;
  const params = JSON.stringify({
    email: email,
    password: password,
    confirmPassword: confirmPassword,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.adminSignupUrl,
    params,
    header
  );
}

function getViewIconFun(schedulePk1, batchid1) {
  const schedule_pk = `/${schedulePk1}`;
  const batchid = `/${batchid1}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getViewIconData + schedule_pk + batchid
  );
}

function getViewIconHackathonFun(scheduleId1, batchid12) {
  const schedule_ID = `/${scheduleId1}`;
  const batch_Id = `/${batchid12}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getViewIconHackathonData + schedule_ID + batch_Id
  );
}

function getViewHackathonData(schedulePk1, batchid1) {
  const schedule_pk = `/${schedulePk1}`;
  const batchid = `/${batchid1}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getViewIconHackathonData + schedule_pk + batchid
  );
}

function getMentorLayoutApi(id) {
  const ID = `/${false}`;
  return BaseApiService.get(BaseURL + APIRequests.mentorDashboard + ID);
}

function getTrainerLayoutApi(id) {
  id = false;
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.trainerDashboard + Id);
}

// function getMentorSwitch() {
//   return BaseApiService.get(BaseURL + APIRequests.mentorTotrainerSwitch);
// }

function getMentorSwitch(batchId, roleBits) {
  const header = true;
  let params = JSON.stringify({
    batchId: batchId,
    sum_role_bits: roleBits,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.mentorTotrainerSwitch,
    params,
    header
  );
}

function postTrainerSessionCalender(page, search, CourseId) {
  // const params = JSON.stringify({ page: page });
  const Id = `?Batch_id=${CourseId}`;
  const Search = `&search=${search}`;
  const limit = `&limit=${5}&page=${page - 1}`;
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.TrainerSessionCalender + Id + Search + limit,
    header
  );
}

function PostTrainer_SessionTraineeData(date, courseId) {
  const params = JSON.stringify({ date: date });
  const id = `/${courseId}`;
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.TraineeSessionTraineeData + id,
    params,
    header
  );
}

function getTraineeSession_Details(id) {
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.TraineeSessionDetails + Id);
}

function getTrainerDashboard(id) {
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.trainerDashboard + Id);
}

function getMentorDashBoard(id) {
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.MentorDashBoard + Id);
}

function postUserForgetPassword(newData) {
  let params = JSON.stringify({ email: newData });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.UserForgetPassword,
    params,
    header
  );
}

function postResetPassword(email, password, confirmPassword) {
  const header = true;
  let params = JSON.stringify({
    email: email,
    password: password,
    confirmPassword: confirmPassword,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.ResetPassword,
    params,
    header
  );
}

function postProfileInfo(
  phone,
  firstname,
  lastname,
  designation,
  department,
  pic
) {
  let params = new FormData();
  params.append("phone", phone);
  params.append("userName", firstname);
  params.append("lastName", lastname);
  params.append("designation", designation);
  params.append("department", department);
  params.append("image", pic);
  let header;
  return BaseApiService.post(
    BaseURL + APIRequests.updateProfileDetails,
    params,
    header
  );
}

function getSessionDetails(page, value, search, batchid) {
  const params = JSON.stringify({ page: page - 1, batch_id: batchid });

  const header = true;
  return BaseApiService.post(
    BaseURL +
      APIRequests.MentorSessionsFilter +
      value +
      APIRequests.MentorSearch +
      search,
    params,
    header
  );
}

function getSessionDetailsTrainer(page, value, search, OrgRole) {
  const params = JSON.stringify({ batch_id: OrgRole });
  console.log(OrgRole, "ogrole");
  const header = true;
  return BaseApiService.post(
    BaseURL +
      APIRequests.TrainerSessionsFilter +
      value +
      `${"&limit="}` +
      APIRequests.MentorSearch +
      search +
      `${"&page="}` +
      page,
    params,
    header
  );
}

function getMentorTaskDetails(raw) {
  const header = true;
  const params = raw;
  return BaseApiService.post(
    BaseURL + APIRequests.mentorHackathon,
    params,
    header
  );
}

function getMentorTaskDataAssessed(raw) {
  const header = true;
  const params = raw;
  return BaseApiService.put(
    BaseURL + APIRequests.mentorHackathon,
    params,
    header
  );
}

function getMentorAssignData(raw) {
  const header = true;
  const params = raw;
  return BaseApiService.post(
    BaseURL + APIRequests.mentorAssignment,
    params,
    header
  );
}

function getMentorAssignDataAssessed(raw) {
  const header = true;
  const params = raw;
  return BaseApiService.put(
    BaseURL + APIRequests.mentorAssignment,
    params,
    header
  );
}

// function getUpdatedScoresRemarks(raw) {
//   const header = true;
//   const params = raw;
//   return BaseApiService.post(
//     BaseURL + APIRequests.updatedScoresRemarks,
//     params,
//     header
//   );
// }

function getMentorQuiz(raw) {
  const header = true;
  const params = raw;
  return BaseApiService.post(BaseURL + APIRequests.mentorQuiz, params, header);
}

function getSessionDetailsMentor(id) {
  return BaseApiService.get(BaseURL + APIRequests.mentorSchedule + id);
}

function getSearchedSessions(searchWord, batch_id) {
  const body = JSON.stringify({
    batch_id: batch_id,
  });
  return BaseApiService.get(
    BaseURL + APIRequests.mentorSearch + searchWord + `&Batch_id=${batch_id}`
  );
}

function getTrainerHackathon() {
  return BaseApiService.get(BaseURL + APIRequests.trainerHackathon);
}

function getTrainerHackathonScore(valueId) {
  return BaseApiService.get(
    BaseURL + APIRequests.trainerHackathonScore + valueId
  );
}

function getTrainerHackathonTopScore(valueId) {
  return BaseApiService.get(
    BaseURL + APIRequests.trainerHackathonTopScore + valueId
  );
}

function getFeed(schedule_id, trainer_id) {
  const raw = JSON.stringify({
    schedule_id: schedule_id,
    trainer_id: trainer_id,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.TraineeGetFeedById,
    raw,
    true
  );
}

function postFeed(schedule_id, trainer_id, radioValue, feedback) {
  const params = JSON.stringify({
    schedule_id: schedule_id,
    trainer_id: trainer_id,
    performance: radioValue,
    comment: feedback,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.TraineeGetFeedById,
    params,
    true
  );
}

function getAssignment(asn_pk) {
  const params = JSON.stringify({
    asn_pk: asn_pk,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.TraineeSubmitAssignment,
    params,
    true
  );
}

function uploadAssignmentInTraineeSection(asn_pk, file) {
  let fd = new FormData();
  // fd.append("schedule_id", id);
  fd.append("asn_fk", asn_pk);
  fd.append("file", file);

  return BaseApiService.post(
    BaseURL + APIRequests.TraineeSubmitAssignment,
    fd,
    false
  );
}
function uploadAssignment(asn_pk, file) {
  let fd = new FormData();
  // fd.append("schedule_id", id);
  fd.append("asn_fk", asn_pk);
  fd.append("file", file);

  return BaseApiService.post(
    BaseURL + APIRequests.UserUploadAssignment,
    fd,
    false
  );
}

function traineeUploadAssignment(asn_pk, file) {
  let fd = new FormData();
  // fd.append("schedule_id", id);
  fd.append("asn_fk", asn_pk);
  fd.append("file", file);

  return BaseApiService.post(
    BaseURL + APIRequests.TraineeSubmitAssignment,
    fd,
    false
  );
}

function traineeAsssignmentSubmition(asn_pk) {
  const params = JSON.stringify({
    asn_pk: asn_pk,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.TraineeLastSubmit,
    params,
    header
  );
}
function submitAllAsssignment(asn_pk) {
  const params = JSON.stringify({
    asn_pk: asn_pk,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.UserSubmitAssignment,
    params,
    header
  );
}

function deleteAssignment(id) {
  const Id = `/${id}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.TraineeSubmitAssignment + Id
  );
}

function getHackathon(task_pk, hackathon_id, userHid) {
  const params = JSON.stringify({
    task_id: task_pk,
    hackathonid: hackathon_id,
    user_hid_fk: userHid,
  });
  const header = true;
  return BaseApiService.put(
    BaseURL + APIRequests.TraineeHackathon,
    params,
    header
  );
}

function AssignmentUploadStatus(id, status) {
  const params = JSON.stringify({
    status: status,
  });
  const header = true;
  return BaseApiService.put(
    BaseURL + APIRequests.EnableAssignmentUpload + "/" + id,
    params,
    header
  );
}

function uploadHacakathon(hackathon_id, task_pk, file) {
  let fd = new FormData();
  fd.append("hackathonid", hackathon_id);
  fd.append("task_id", task_pk);
  fd.append("files", file);

  return BaseApiService.post(BaseURL + APIRequests.TraineeHackathon, fd);
}

function SubmitAllHackthon(task_pk, user_task_pk, user_hid_fk) {
  const params = JSON.stringify({
    task_pk: task_pk,
    user_task_fk: user_task_pk,
    user_hid_fk: user_hid_fk,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.TraineeHackathonSubmit,
    params,
    header
  );
}

function deleteHackathon(Id) {
  const id = `/${Id}`;
  return BaseApiService.Delete(BaseURL + APIRequests.TraineeHackathon + id);
}

function getAdminAssignment(id) {
  const ID = `unit_id=${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminAddAssignment + ID);
}

async function editAdminQuizconfiguration(
  topic_id,
  quiz_number,
  // instruction,
  no_of_questions,
  countdown,
  description,
  id,
  status
) {
  const ids = `/${quiz_number}`;
  const finalObj = {
    topic_id: topic_id,
    quiz_number: id,
    no_of_questions: no_of_questions,
    countdown: countdown,
    // status: status,
    instruction: description,
  };
  if (status !== null) finalObj.status = status;
  const params = JSON.stringify(finalObj);
  const header = true;

  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditQuizconfiguration + ids,
    params,
    header
  );
}

function addAdminAssignmnet(unitId, assignmentname, description, isChek) {
  const params = JSON.stringify({
    assignment_name: assignmentname,
    description: description,
    topic_id: unitId,
    status: isChek,
  });
  const header = true;

  return BaseApiService.post(
    BaseURL + APIRequests.AdminGetAssignment,
    params,
    header
  );
}
function editAdminAssignmnet(
  assignment_name,
  description,
  topic_id,
  status,
  id
) {
  const ids = `/${topic_id}`;
  const params = JSON.stringify({
    assignment_name: assignment_name,
    description: description,
    topic_id: id,
    status: status,
  });
  const header = true;

  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditAssignment + ids,
    params,
    header
  );
}

function deleteAdminAssignment(unitId, ansnId) {
  const ID = `unit_id=${unitId}&asn_id=${ansnId}`;

  return BaseApiService.Delete(
    BaseURL + APIRequests.adminDeleteAssignment + ID
  );
}

function getAdminParameters(id) {
  const ID = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminParameters + ID);
}

function addAdminParameters(
  schedTMID,
  fileMerge,
  description,
  schedTMTopic,
  modname
) {
  let fd = new FormData();
  fd.append("schedule_id", schedTMID);
  fd.append("file", fileMerge);
  fd.append("description", description);
  fd.append("topic", schedTMTopic);
  fd.append("material_name", modname);
  return BaseApiService.post(BaseURL + APIRequests.AdminAddParameters, fd);
}

function getAdminDepartments(id) {
  const ID = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetDeparment + ID);
}

function addAdminQuizz(
  topicID,
  quiz,
  numberOfQuestions,
  quizDuration,
  percentage,
  description,
  status
) {
  const finalObj = {
    topic_id: topicID,
    quiz_number: quiz,
    no_of_questions: numberOfQuestions,
    countdown: quizDuration,
    // status: status,
    instruction: description,
  };
  if (status !== null) finalObj.status = status;
  const params = JSON.stringify(finalObj);
  // const raw = JSON.stringify({
  //   topic_id: topicID,
  //   quiz_number: quiz,
  //   no_of_questions: numberOfQuestions,
  //   countdown: quizDuration,
  //   // pass_score: percentage,
  //   instruction: description,
  // });
  return BaseApiService.post(BaseURL + APIRequests.AdminAddQuiz, params, true);
}

function getAdminPrerequisites(id) {
  const ID = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetPrerequisites + ID);
}

function addAdminPrerequisites(
  topicID,
  fileName,
  videoLink,
  file,
  description,
  duration
) {
  let fd = new FormData();

  if (file !== "") {
    fd.append("file", file);
  }

  fd.append("pre_requisites_description", description);
  fd.append("topic_id", topicID);
  fd.append("module_name", fileName);
  fd.append("link", videoLink);
  fd.append("duration", parseInt(duration));
  return BaseApiService.post(BaseURL + APIRequests.AdminAddPrerequisites, fd);
}

function getAdminTrainingMaterial(id) {
  const ID = `/${id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.AdminGetTrainingMaterial + ID
  );
}

function addAdminTrainingMaterial(
  topicId,
  fileName,
  videoLink,
  pptLink,
  file,
  duration,
  contentType
) {
  let fd = new FormData();
  if (file !== "") {
    fd.append("file", file);
  }
  if (videoLink !== "") {
    fd.append("video_link", videoLink);
  }
  if (pptLink !== "") {
    fd.append("ppt_link", pptLink);
  }
  fd.append("topic_id", topicId);
  fd.append("duration", duration);
  fd.append("material_name", fileName);
  fd.append("content_type", contentType);
  return BaseApiService.post(
    BaseURL + APIRequests.AdminAddTrainingMaterail,
    fd
  );
}

function AdminAddMandatoryTrainingMaterial(
  mandateId,
  fileName,
  video,
  file,
  duration
) {
  let fd = new FormData();
  fd.append("file", video);
  fd.append("file", file);
  fd.append("duration", duration);
  fd.append("topic", fileName);
  return BaseApiService.post(
    BaseURL + APIRequests.AdminMandatoryTrainingMaterial + mandateId,
    fd
  );
}

function getAdminSchedule(id) {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetSchedule + id);
}

function deleteAdminSchedule(id) {
  const ID = `/${id}`;
  return BaseApiService.Delete(BaseURL + APIRequests.AdminDeleteSchedule + ID);
}

function addAdminSchedule(fileMerge, id) {
  let formData = new FormData();
  formData.append("file", fileMerge);
  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.AdminGetSchedule + id,
    formData,
    header
  );
}

function getAdminQuestionsUpload(id, pageCount) {
  const unitId = `unit_id=${id}&limit=8&page=${pageCount}`;
  return BaseApiService.get(
    BaseURL + APIRequests.adminQuestionsUploadGet + unitId
  );
}

function getAdminDepartmentss() {
  return BaseApiService.get(BaseURL + APIRequests.adminDepartmentsGet);
}

function getAdminCandidates(batches) {
  let Id = `/${batches}`;
  return BaseApiService.get(BaseURL + APIRequests.adminCandidatesGet + Id);
}

function getAdminHackathons(batch_id) {
  let ID = `/${batch_id}`;
  return BaseApiService.get(BaseURL + APIRequests.adminHackathonGet + ID);
}

function postAdminQuestionUpload(fileMerge, id) {
  let params = new FormData();
  params.append("unit_id", id);
  params.append("file", fileMerge);
  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.adminQuestionsUploadPost,
    params,
    header
  );
}

function postProgram(
  programName,
  technology,
  startDate,
  endDate,
  duriation,
  aboutProgram,
  unitIds,
  courseIds,
  trainingType
) {
  const raw = JSON.stringify({
    program_name: programName,
    technology: technology,
    start_date: startDate,
    end_date: endDate,
    duration: duriation,
    about_program: aboutProgram,
    unit_ids: unitIds,
    course_ids: courseIds,
    training_type: trainingType,
  });
  return BaseApiService.post(BaseURL + APIRequests.AddProgram, raw, true);
}

function postBootCamp(
  programName,
  startDate,
  endDate,
  unitIds,
  description,
  selectedValue
) {
  const raw = JSON.stringify({
    batch_name: programName,
    batch_start: startDate,
    batch_end: endDate,
    unit_ids: unitIds,
    // batch_type: 0,
    batch_type: selectedValue === 1 ? 0 : selectedValue,
    description: description,
  });
  return BaseApiService.post(BaseURL + APIRequests.AddBootCamp, raw, true);
}

function postAdminHackathonUpload(fileMerge) {
  let params = new FormData();
  params.append("file", fileMerge);
  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.adminHackathonUploadPost,
    params,
    header
  );
}

function postAdminAddCandidate(
  FirstName,
  Email,
  Designation,
  Phone,
  Experience,
  Certificates,
  Department,
  College,
  Description,
  Batch,
  batchIDDD
) {
  const params = JSON.stringify({
    user_name: FirstName,
    user_email: Email,
    work_posiotion: Designation,
    trainee_colleage: College,
    phonenumber: Phone,
    work_experience: Experience,
    certificates: Certificates,
    role_fk: Department,
    user_description: Description,
  });
  const header = true;
  const id = `/${batchIDDD}`;
  return BaseApiService.post(
    BaseURL + APIRequests.adminAddCandidatePost + id,
    params,
    header
  );
}

function postAdminAddCandidateMail(FirstName, Email, Department, batch) {
  const params = JSON.stringify({
    user_name: FirstName,
    user_email: Email,
    role_fk: Department,
    batch_id: batch,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.adminAddCandidateMail,
    params,
    header
  );
}

function getTrainerAssignmentDataFunc(trainerID, assignment_pkk) {
  const params = JSON.stringify({
    trainee_id: trainerID,
    // schedule_fk: scheduleID,
    asn_fk: assignment_pkk,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.trainerAssignmentDataGet,
    params,
    header
  );
}

function getTrainerTaskDataFunc(trainerID, scheduleID, task_pkk) {
  const params = JSON.stringify({
    trainee_id: parseInt(trainerID),
    schedule_fk: scheduleID,
    asn_fk: task_pkk,
  });
  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.trainerTaskDataGet,
    params,
    header
  );
}

function getTrainerQuizDataFunc(trainerID, quizId) {
  const params = JSON.stringify({
    trainee_id: trainerID,
    quizz_pk: quizId,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.trainerQuizDataGet,
    params,
    header
  );
}

function getTrainerSessionDetailsFunc(id) {
  const Id = `/${id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.trainerSessionDetailsGet + Id
  );
}

function enableTrainerQuizFunc(id, param) {
  const params = JSON.stringify({
    schedule_fk: id,
    quizz_pk: param,
  });
  const header = [{ "Content-Type": "application/json" }];
  return BaseApiService.post(
    BaseURL + APIRequests.trainerQuizEnablePost,
    params,
    header
  );
}

function deleteAdminQuestions(paramsId) {
  const Id = `/${paramsId}`;
  return BaseApiService.Delete(BaseURL + APIRequests.adminQuestionDelete + Id);
}

function deleteAdminCandidate(paramsId) {
  const Id = `/${paramsId}`;
  return BaseApiService.Delete(BaseURL + APIRequests.adminCandidateDelete + Id);
}

function deleteAdminBatch(paramsId) {
  const Id = `/${paramsId}`;
  return BaseApiService.Delete(BaseURL + APIRequests.adminaddbatchid + Id);
}

function deleteAdminHackathon(paramsId) {
  const Id = `/${paramsId}`;
  return BaseApiService.Delete(BaseURL + APIRequests.adminHackathonDelete + Id);
}

function getAdminCandidateById(id) {
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.adminCandidateByIdGet + Id);
}

function getAdminaddbatchid(id) {
  const Id = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.adminaddbatchid + Id);
}

function getTrainerUsersById(search) {
  const Id = `/?search=${search}`;
  return BaseApiService.get(BaseURL + APIRequests.trainerUsersGet + Id);
}

function getBatchesDetails() {
  return BaseApiService.get(BaseURL + APIRequests.getBootcamps);
}

function putAdminCandidateById(
  id,
  Bootcamp,
  firstname,
  email,
  designation,
  college,
  phone,
  experience,
  certificates,
  department
) {
  const Id = `/${id}`;
  const header = true;
  const params = JSON.stringify({
    user_name: firstname,
    user_email: email,
    work_posiotion: designation,
    trainee_colleage: college,
    phonenumber: phone,
    work_experience: experience,
    certificates: certificates,
    role_fk: department,
    batch_id: Bootcamp,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.adminCandidateByIdGet + Id,
    params,
    header
  );
}

function addBatchData(name, startdate, enddate, fileMerge) {
  let params = new FormData();
  params.append("name", name);
  params.append("start_date", startdate);
  params.append("end_date", enddate);
  params.append("file", fileMerge);
  const header = false;
  return BaseApiService.post(BaseURL + APIRequests.addBatch, params, header);
}

function getAllBatchData() {
  return BaseApiService.get(BaseURL + APIRequests.getAllBatch);
}

function getSchedulers(batch_id, schedule_type) {
  const Id = `${batch_id}`;
  const scheduleType = `${schedule_type}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getSchedulers + "/" + Id + "/" + scheduleType
  );
}

function getAdminAssignmentQuiz(sessionFilter) {
  const params = JSON.stringify({
    schedules: sessionFilter,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.getAssignmnet,
    params,
    header
  );
}

function postScheduleSearchOnBatch(batch_ID, schedule_Name) {
  const header = true;
  const params = JSON.stringify({
    batch_id: batch_ID,
    scheduleName: schedule_Name,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.postSchedulesearchonbatch,
    params,
    header
  );
}

function postTraineesSearchOnBatch(batch_ID, mentorSearch) {
  const header = true;
  const params = JSON.stringify({
    searchTrainee: mentorSearch,
    batchId: batch_ID,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.postTrainerSearchOnBatchApi,
    params,
    header
  );
}

function getHackathonOnBatchId(batchID) {
  const Id = `${batchID}`;
  return BaseApiService.get(BaseURL + APIRequests.getHackathonOption + Id);
}

function getHackathonData(batchID, hackathons) {
  const bID = `${batchID}`;
  const sID = `${hackathons}`;
  return BaseApiService.get(
    BaseURL + APIRequests.getHackathonDataSearch + bID + "/" + sID
  );
}

function getScheduleWithDates(batchID, schedulerID, fromDate, toDate) {
  const param = JSON.stringify({
    batch_id: batchID,
    schedule_type: schedulerID,
    from_date: fromDate,
    to_date: toDate,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.getScheduleWithDate,
    param,
    header
  );
}

function getSchedulesData(
  batchID,
  fromDate,
  toDate,
  sessionFilter,
  assesmentFormat
) {
  const params = JSON.stringify({
    batch_id: batchID,
    from_date: fromDate,
    to_date: toDate,
    schedules: sessionFilter,
    assesment: assesmentFormat,
  });
  const header = [{ "Content-Type": "application/json" }];
  return BaseApiService.post(
    BaseURL + APIRequests.getScheduledataBySearch,
    params,
    header
  );
}

const getEditAssignment = (Id) => {
  const id = `/${Id}`;
  return BaseApiService.get(BaseURL + APIRequests.getEditAssignmentData + id);
};

const updateAdminAssignment = (
  Id,
  fileMerge,
  assignmentname,
  date,
  date01,
  fromtime,
  totime,
  description
) => {
  const id = `/${Id}`;
  let fd = new FormData();
  fd.append("file", fileMerge);
  fd.append("assignment_name", assignmentname);
  fd.append("from_date", date);
  fd.append("to_date", date01);
  fd.append("from_time", fromtime);
  fd.append("to_time", totime);
  fd.append("description", description);
  return BaseApiService.post(
    BaseURL + APIRequests.getEditAssignmentData + id,
    fd
  );
};

const getAdminSessionDetails = (scheduleId, batchID) => {
  return BaseApiService.get(
    BaseURL + APIRequests.getAdminSessionDetails + batchID + "/" + scheduleId
  );
};

const getTrainers = (batchID) => {
  return BaseApiService.get(BaseURL + APIRequests.getAdminTrainers + batchID);
};

const adminAsnDeleteParams = (id) => {
  return BaseApiService.Delete(BaseURL + APIRequests.adminDeleteAsnParams + id);
};

const adminAsnAddParams = (parameterName, parameterScore, asnId) => {
  const header = true;
  const param = JSON.stringify({
    params_name: parameterName,
    marks: parameterScore,
    assignmentId: asnId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.adminAddAsnParams,
    param,
    header
  );
};

const editSessionSchedule = (
  batchID,
  scheduleId,
  toFromDate,
  endDate,
  fromTime,
  endTime,
  description
) => {
  const header = true;
  const param = JSON.stringify({
    schedule_id: scheduleId,
    batch_id: batchID,
    Training_Date: toFromDate,
    End_date: endDate,
    From_Time: fromTime,
    To_Time: endTime,
    Description: description,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.adminEditScheule,
    param,
    header
  );
};

const addAssignmentParams = (
  scheduleId,
  assignmentname,
  date,
  date01,
  fromtime,
  totime,
  description,
  parameterName,
  parameterScore
) => {
  const header = true;
  const param = JSON.stringify({
    schedule_id: scheduleId,
    assignment_name: assignmentname,
    from_date: date,
    to_date: date01,
    from_time: fromtime,
    to_time: totime,
    description: description,
    params_name: parameterName,
    marks: parameterScore,
  });
  return BaseApiService.post(BaseURL + APIRequests.adminAddAsn, param, header);
};

const editAssignmentOnly = (
  asnId,
  assignmentname,
  date,
  date01,
  fromtime,
  totime,
  description
) => {
  const header = true;
  const param = JSON.stringify({
    asn_pk: asnId,
    assignment_name: assignmentname,
    from_date: date,
    to_date: date01,
    from_time: fromtime,
    to_time: totime,
    description,
  });
  return BaseApiService.post(BaseURL + APIRequests.adminEditAsn, param, header);
};

const editTrainerDetails = (
  about_scheduleId,
  description,
  trainerId,
  backupTrainerId
) => {
  const header = true;
  const params = JSON.stringify({
    schedule_pk: about_scheduleId,
    trainer_user_description: description,
    trainer_id: trainerId,
    backup_trainer_id: backupTrainerId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.adminEditTrainer,
    params,
    header
  );
};

const editAdminMandatoryTrainingIntroSlides = (
  mandateId,
  slideID,
  slideName,
  Description,
  count
) => {
  const header = true;
  const params = JSON.stringify({
    unit_id: mandateId,
    slide_id: slideID,
    slide_name: slideName,
    description: Description,
    count: count,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.editAdminMandatoryTrainingIntroSlides,
    params,
    header
  );
};

function deleteAdminPreRequisite(prerequisite_Id, unit_Id) {
  const prerequisiteId = `/${prerequisite_Id}`;
  const unitId = `/${unit_Id}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminDeletePreRequisite + prerequisiteId + unitId
  );
}

function deleteAdminTrainingMaterial(unit_Id, trainingmaterial_ID) {
  const materialid = `/${trainingmaterial_ID}`;
  const unitId = `/${unit_Id}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminDeleteTrainingMaterial + materialid + unitId
  );
}

function deleteAdminMandatoryTrainingMaterial(trainingmaterial_ID, unit_Id) {
  const materialid = `material_id=${trainingmaterial_ID}`;
  const unitId = `&topic_id=${unit_Id}`;
  return BaseApiService.post(
    BaseURL +
      APIRequests.deleteAdminMandatoryTrainingMaterial +
      materialid +
      unitId
  );
}

function deleteAdminMandatoryTrainingIntroSlides(slide_Id, unit_Id) {
  const slideId = `slide_id=${slide_Id}`;
  const unitId = `&unit_id=${unit_Id}`;
  return BaseApiService.Delete(
    BaseURL +
      APIRequests.deleteAdminMandatoryTrainingIntroSlides +
      slideId +
      unitId
  );
}

function viewAdminMandatoryTrainingMaterial(mandateId, id) {
  const mandatory_training_id = `mandatory_training_id=${mandateId}`;
  const material_id = `&material_id=${id}`;
  return BaseApiService.post(
    BaseURL +
      APIRequests.viewAdminMandatoryTrainingMaterial +
      mandatory_training_id +
      material_id
  );
}

const getAdminTraineeDetails = (Id) => {
  const id = `/${Id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetTraineeDetails + id);
};

const adminAddCourse = async (
  courseName,
  technology,
  duration,
  activeDate,
  description,
  units,
  difficultyLevel,
  totalUnitData,
  draggableData
) => {
  const UnitId = draggableData.map((unitData) => {
    return unitData.id;
  });
  const param = JSON.stringify({
    course_name: courseName,
    technology: technology,
    duration: duration,
    active_date: activeDate,
    about_course: description,
    unit_ids: UnitId,
    difficulty_level_id: difficultyLevel,
  });
  return BaseApiService.post(BaseURL + APIRequests.AdminAddCourse, param, true);
};

const adminEditCourse = async (
  courseName,
  technology,
  duration,
  active_date,
  about_course,
  unit_ids,
  difficulty_level_id,
  totalUnitData,
  courseId,
  draggableData
) => {
  const UnitId = draggableData.map((unitData) => {
    return unitData.id;
  });
  const param = JSON.stringify({
    course_name: courseName,
    technology: technology,
    duration: duration,
    active_date: active_date,
    about_course: about_course,
    unit_ids: UnitId,
    difficulty_level_id: difficulty_level_id,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditCourse + courseId,
    param,
    true
  );
};

const adminGetCourse = (id) => {
  const courseId = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetCourse + courseId);
};

const adminCreateCourse = async (id) => {
  const courseId = `/${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminCreateCourse + courseId);
};
const adminCreateProgram = async (id) => {
  const params = `input_state=true&program_id=${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminCreateProgram + params);
};
const adminCreateBootCamp = async (id) => {
  const params = id;
  return BaseApiService.get(BaseURL + APIRequests.AdminCreateBootCamp + params);
};
const adminGetDifficultyLevels = async () => {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetDifficultyLevels);
};

const adminDeleteUnit = async (id) => {
  return BaseApiService.Delete(BaseURL + APIRequests.AdminDeleteUnit + id);
};

const deleteCourse = async (id) => {
  return BaseApiService.Delete(BaseURL + APIRequests.AdminDeleteCourse + id);
};
const getDifficultyLevel = () => {
  return BaseApiService.get(BaseURL + APIRequests.GetDifficulty);
};

const getAllTags = (IsMandate) => {
  let Url = APIRequests.GetAllTags;
  if (IsMandate) {
    Url += "?tag_type=1";
  }
  return BaseApiService.get(BaseURL + Url);
};

const CreateUnit = (unitData) => {
  const params = JSON.stringify(unitData);
  const header = true;
  return BaseApiService.post(BaseURL + APIRequests.AddUnit, params, header);
};

const getUnitById = (unitId) => {
  let unitPath = `/${unitId}`;
  return BaseApiService.get(BaseURL + APIRequests.GetUnit + unitPath);
};

const finalUnitCreation = (unitId) => {
  let unitPath = `/${unitId}`;
  return BaseApiService.get(BaseURL + APIRequests.CreateUnit + unitPath);
};

const finalAdminCreationPublish = (mandateId) => {
  let mandatePath = `/${mandateId}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminPublish + mandatePath);
};

const getAllUnit = (pageCount, limit, queryParms) => {
  return BaseApiService.get(BaseURL + queryParms);
};

const getAllCourse = (pageCount, limit, queryParms) => {
  return BaseApiService.get(BaseURL + `/${queryParms}`);
};

const getSearchedUnit = (
  pageCount,
  limit,
  setAllUnits,
  setPageLimit,
  courseDifficultyLevel
) => {
  let params = `?level_id=${courseDifficultyLevel}&limit=${limit}&page=${pageCount}`;
  return BaseApiService.get(BaseURL + APIRequests.GetSearchedUnit + params);
};

const getAllUnits = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetAllUnits + param);
};

const getAllPrograms = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetAllProgram + param);
};

const getAllCourses = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetAllCourses + param);
};
const getAllUserCourses = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.getAllUserCourses + param);
};
const getAllUserUnits = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.getAllUserUnits + param);
};
const getAdminQuizType = (id) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminGetQuizType + id);
};
const AdminEditUnit = (unitData, unitId) => {
  const params = JSON.stringify(unitData);

  let UnitParms = `/${unitId}`;
  const header = true;
  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditUnit + UnitParms,
    params,
    header
  );
};

const AdminDeleteUnit = (unitId) => {
  let UnitParms = `/${unitId}`;
  const header = true;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminDeleteUnit + UnitParms
  );
};

const getAdminQuizzes = (unitId) => {
  let id = `unit_id=${unitId}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetQuizzes + id);
};

const deleteAdminQuizzes = (unitId, id) => {
  const params = `?unit_id=${unitId}&quizz_pk=${id}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminDeleteQuizess + params
  );
};

const AdminSaveForLaterBtn = (id) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminSaveLaterBtn + id);
};

const AdminSaveForLaterApi = (id) => {
  return BaseApiService.get(BaseURL + APIRequests.AdminSaveLaterApi + id);
};

const adminQuizQuestionsDelete = (unitId, id) => {
  const params = `?unit_id=${unitId}&question_pk=${id}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminQuizQuestionsDelete + params
  );
};

const AdminTrainingMaterialReorder = (unitId, IdsArray) => {
  const params = JSON.stringify({
    trainingmaterialids: IdsArray,
  });

  const id = `${unitId}`;
  const header = true;

  return BaseApiService.post(
    BaseURL + APIRequests.AdminTrainingmaterialReorder + id,
    params,
    header
  );
};

const AdminPreRequisitesReorder = (unitId, IdsArray) => {
  const params = JSON.stringify({
    prerequisitelist: IdsArray,
  });

  const id = `${unitId}`;
  const header = true;

  return BaseApiService.post(
    BaseURL + APIRequests.AdminPrerequisitesReorder + id,
    params,
    header
  );
};
const UnitReorder = (unitIds, courseId) => {
  const params = JSON.stringify({
    unitslist: unitIds,
  });
  const header = true;
  const id = `/${courseId}`;
  return BaseApiService.post(
    BaseURL + APIRequests.AdminCourseUnitReorder + id,
    params,
    header
  );
};

const TrainingMaterialTrack = (unitIds, courseId) => {
  const params = JSON.stringify({
    course_id: courseId,
    unit_id: unitIds,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.TrainingMaterialTrack,
    params,
    header
  );
};

const EditPreDescription = (description, unitId) => {
  const header = true;
  const params = JSON.stringify({
    pre_requisites_description: description,
  });
  const id = `/${unitId}`;
  return BaseApiService.put(
    BaseURL + APIRequests.AdminPreDescriptionEdit + id,
    params,
    header
  );
};

const getCourseDetailsByCourseId = (id) => {
  const Id = `${id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.UserGetCourseDetailsById + Id
  );
};

function getUserDetails() {
  return BaseApiService.get(BaseURL + APIRequests.UserProfileDetails);
}

function getUserUnitDetails(unitId, courseId) {
  const header = true;
  const params = JSON.stringify({
    unit_id: unitId,
    course_id: courseId,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.UserUnitDetails,
    params,
    header
  );
}

function handleUnitStart(unitId, courseId) {
  const header = true;
  const params = JSON.stringify({
    unit_id: unitId,
    course_id: courseId,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.UserUnitStart,
    params,
    header
  );
}

function UnitpostFeed(unitId, courseId, radioValue, feedback) {
  const params = JSON.stringify({
    topic_id: unitId,
    course_id: courseId,
    performance: radioValue,
    comment: feedback,
  });

  return BaseApiService.post(
    BaseURL + APIRequests.UserCoursesFeedback,
    params,
    true
  );
}

function userGetAssignment(unitId, asn_pk) {
  const params = JSON.stringify({
    unit_id: unitId,
    asn_pk: asn_pk,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserGetAssignmentById,
    params,
    true
  );
}

function userDeleteAssignment(id) {
  const Id = `/${id}`;
  return BaseApiService.Delete(BaseURL + APIRequests.UserDeleteAssignment + Id);
}
function getUserAllQuestions(quizNumber01, userUnitTrackId, trainingId) {
  // const Id = `${quizNumber01}`;
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    quizz_id: quizNumber01,
    user_unit_tack_id: userUnitTrackId,
    unit_id: trainingId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserGetAllQuestions,
    params,
    header
  );
}

function getUserQuizResults(quizNumber01) {
  const Id = `${quizNumber01}`;
  return BaseApiService.get(BaseURL + APIRequests.UserResultsOfQuiz + Id);
}

function postUserOnclick(optionsOpted, param, id, remainingTime) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: remainingTime,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserOnclickPrevious,
    params,
    header
  );
}
function putUserOnclick(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.UserOnclickPrevious1,
    params,
    header
  );
}

function postUserOnClickNext(optionsOpted, param, id, remainingTime) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: remainingTime,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserOnclickPrevious,
    params,
    header
  );
}

function putUserOnClickNext(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.UserOnclickPrevious1,
    params,
    header
  );
}

function postUserAPICall(optionsOpted, param, id, remainingTime) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
    remianing_duration: remainingTime,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserOnclickPrevious,
    params,
    header
  );
}
function postUserhandleClik(optionsOpted, param, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    user_option: optionsOpted,
    question_id: param,
    user_qz_id: id,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserOnclickPrevious,
    params,
    header
  );
}

function putUserhandleClik(question_pk, id) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ question_id: question_pk, user_qz_id: id });
  return BaseApiService.put(
    BaseURL + APIRequests.UserOnclickPrevious1,
    params,
    header
  );
}

function UserProfileUpdate(
  phone,
  firstname,
  lastname,
  designation,
  department,
  pic
) {
  let params = new FormData();
  if (pic !== undefined) {
    params.append("file", pic);
  }
  params.append("phone", phone);
  params.append("userName", firstname);
  params.append("lastName", lastname);
  params.append("designation", designation);
  params.append("department", department);
  return BaseApiService.post(
    BaseURL + APIRequests.updateProfileDetails,
    params
  );
}

function UserChangePassword(oldPassword, newPassword, confirmPassword) {
  const params = JSON.stringify({
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirmPassword: confirmPassword,
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.UserChangePassword,
    params,
    header
  );
}

function preRequisiteCompletion(
  currentDuration,
  currentPrerequisiteId,
  unitId,
  courseId,
  completedPrerequisiteId
) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    current_pre_requisities_duration: currentDuration,
    current_pre_requisities_id: currentPrerequisiteId,
    unit_id: unitId,
    course_id: courseId,
    completed_pre_requisite_id: completedPrerequisiteId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserPreRequisiteTrack,
    params,
    header
  );
}

function trainingMaterialCompletion(
  current_training_materials_duration,
  current_training_materials_id,
  unit_id,
  course_id,
  completed_training_materials_id
) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({
    current_training_materials_duration: current_training_materials_duration,
    current_training_materials_id: current_training_materials_id,
    unit_id: unit_id,
    course_id: course_id,
    completed_training_materials_id: completed_training_materials_id,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.UserTrainingMaterialTrack,
    params,
    header
  );
}

const getProgramById = (id) => {
  const Id = `${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminProgramById + Id);
};

const adminEditBootCamp = async (
  id,
  program_name,
  start_date,
  end_date,
  unit_ids,
  description
) => {
  const param = JSON.stringify({
    batch_name: program_name,
    batch_start: start_date,
    batch_end: end_date,
    unit_ids: unit_ids,
    description: description,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditBootCamp + id,
    param,
    true
  );
};

const adminEditProgram = async (
  id,
  program_name,
  technology,
  duration,
  start_date,
  end_date,
  about_program,
  unit_ids,
  course_ids,
  training_type
) => {
  // const UnitId = draggableData.map((unitData) => {
  //   return unitData.id;
  // });
  const param = JSON.stringify({
    program_name: program_name,
    technology: technology,
    duration: duration,
    start_date: start_date,
    about_program: about_program,
    end_date: end_date,
    unit_ids: unit_ids,
    course_ids: course_ids,
    training_type: training_type,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditProgram + id,
    param,
    true
  );
};
function postAdminuploadParams(file, topicId, asnId) {
  let params = new FormData();
  params.append("file", file);
  params.append("topic_id", topicId);
  params.append("asn_pk", asnId);

  const header = false;
  return BaseApiService.post(
    BaseURL + APIRequests.AssignmentUploadparams,
    params,
    header
  );
}

function getAdminParamsData(topicId, asnId) {
  const param = JSON.stringify({
    topic_id: topicId,
    asn_pk: asnId,
  });
  return BaseApiService.put(BaseURL + APIRequests.AdminParamsData, param, true);
}

const uploadProgramParticipant = async (id, file, selectTypeCheck) => {
  let fd = new FormData();
  fd.append("file", file);
  return BaseApiService.post(
    BaseURL + APIRequests.AdminUploadProgramParticipant + id,
    fd,
    false
  );
};

const uploadBootCampParticipant = async (id, file, selectTypeCheck) => {
  let fd = new FormData();
  fd.append("file", file);
  return BaseApiService.post(
    BaseURL + APIRequests.AdminUploadBootCampParticipant + id,
    fd,
    false
  );
};

function deleteAdminProgramCandidate(paramsId, programId) {
  const header = [{ "Content-Type": "application/json" }];
  const params = JSON.stringify({ user_id: paramsId, program_id: programId });
  return BaseApiService.put(
    BaseURL + APIRequests.deleteAdminProgramCandidatePut,
    params,
    header
  );
}

function deleteAdminBootCamp(paramsId, programId) {
  const header = [{ "Content-Type": "application/json" }];
  // const params = JSON.stringify({ user_id: paramsId, program_id: programId });
  const params = `?batch_id=${programId}&user_id=${paramsId}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.deleteAdminBootCampParticipant + params,
    header
  );
}

const getUsersTrainer = async (search) => {
  if (search) {
    const queryParms = `?search=${search}`;
    return BaseApiService.get(
      BaseURL + APIRequests.AdminGetTrainer + queryParms
    );
  } else {
    return BaseApiService.get(BaseURL + APIRequests.AdminGetTrainer);
  }
};
const unLinkCourseOrUnit = async (value) => {
  return BaseApiService.post(
    BaseURL + APIRequests.AdminUnlinkProgramCourseOrUnit,
    value,
    true
  );
};

const updateProgramWithTrainer = async (
  program_id,
  start_date,
  start_time,
  trainer_id,
  unit_id,
  course_id,
  end_time
) => {
  const header = [{ "Content-Type": "application/json" }];
  const param = JSON.stringify({
    program_id: program_id,
    start_date: start_date,
    start_time: start_time,
    trainer_id: trainer_id,
    unit_id: unit_id,
    course_id: course_id,
    end_time: end_time,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.AdminUpdateProgram,
    param,
    header
  );
};

const updateBootCampTrainer = async (
  program_id,
  start_date,
  start_time,
  trainer_id,
  unit_id,
  course_id,
  end_time,
  programLocal,
  // description,
  schedulepk
) => {
  const header = [{ "Content-Type": "application/json" }];
  const param = JSON.stringify({
    trainer_id: trainer_id,
    end_date: start_date,
    from_time: start_time,
    to_time: end_time,
    // training_date:
    // description: description,
    batch_id: programLocal,
    schedule_pk: schedulepk,
    // training_date: start_date,
    // end_date:start_date,
    from_date: start_date,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.AdminUpdateBootCampSchedule,
    param,
    header
  );
};

const getParticipants = async (program_id) => {
  const id = program_id;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetParticipates + id);
};

const getAllMentors = async (program_id) => {
  const id = program_id;
  return BaseApiService.get(BaseURL + APIRequests.AdminGetMentor + id);
};

const getBootCampParticipants = async (BootCamp_id) => {
  const id = BootCamp_id;
  return BaseApiService.get(
    BaseURL + APIRequests.AdminBootCampParticipates + id
  );
};

const AdminDeleteProgramUnitCourse = (programId, courseId, unitId) => {
  const header = true;
  const param = JSON.stringify({
    program_id: programId,
    course_id: courseId,
    unit_id: unitId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.AdminDeleteProgramUnitCourse,
    param,
    header
  );
};

const AdminDeleteProgram = (programId) => {
  let UnitParms = `/${programId}`;
  const header = true;
  return BaseApiService.Delete(
    BaseURL + APIRequests.AdminDeleteProgram + UnitParms
  );
};

const getMandatoryTraninigById = (MandatoryTraningId, trackId) => {
  let TraningIdPramas = `/${MandatoryTraningId}`;
  const Paramas = JSON.stringify({
    topic_id: parseInt(MandatoryTraningId),
    user_unit_track_id: parseInt(trackId),
  });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.UserMandatoryTraning,
    Paramas,
    true
  );
};
const CreateMandateTraining = (mandatedata) => {
  const params = JSON.stringify(mandatedata);
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.AdminCreateMandatoryTraining,
    params,
    header
  );
};

const EditMandatoryTraining = (mandatedata, mandateId) => {
  const params = JSON.stringify(mandatedata);
  // const header = true;
  const header = [{ "Content-Type": "application/json" }];
  return BaseApiService.put(
    BaseURL + APIRequests.AdminEditMandatoryTraining + mandateId,
    params,
    header
  );
};

const getMandatoryTrainingById = (id) => {
  let unitPath = `${id}`;
  return BaseApiService.get(
    BaseURL + APIRequests.AdminGetMandateById + unitPath
  );
};

const getAllMandatoryTrainings = (param) => {
  return BaseApiService.get(
    BaseURL + APIRequests.AdminGetMandatoryTrainings + param
  );
};

const getAllUserTraining = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.getAllUserTraining + param);
};

const getUserTrainingDetails = (param) => {
  return BaseApiService.get(
    BaseURL + APIRequests.getUserTrainingDetails + param
  );
};
const getUserTrackMandatoryTraining = (param) => {
  return BaseApiService.get(
    BaseURL + APIRequests.getUserTrackMandatoryTraining + param
  );
};

const AdminQuizTrainingStatusChange = (status, Id) => {
  // const param = JSON.stringify({
  //   input_state: status,
  //   mandatory_training_id: parseInt(Id),
  // });
  const param = "";
  let Url = `?input_state=${status}&mandatory_training_id=${Id}`;
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.AdminQuizTrainingStatusChange + Url,
    param,
    header
  );
};

const getModuleQuizData = (MaterialId, MandatoryTraningId, TrackId) => {
  let Params = "";
  if (TrackId !== null)
    Params = JSON.stringify({
      material_id: parseInt(MaterialId),
      topic_id: parseInt(MandatoryTraningId),
      user_unit_track_id: parseInt(TrackId),
    });
  else
    Params = JSON.stringify({
      material_id: parseInt(MaterialId),
      topic_id: parseInt(MandatoryTraningId),
      // user_unit_track_id: parseInt(TrackId),
    });
  let header = true;

  return BaseApiService.post(
    BaseURL + APIRequests.getMandateModuleQuizData,
    Params,
    header
  );
};
const ValidateMandateQuizAns = (
  material_id,
  topic_id,
  user_quiz_pk,
  user_option,
  question_id,
  unit_type
) => {
  let Params =
    unit_type === 0
      ? JSON.stringify({
          material_id: material_id,
          topic_id: topic_id,
          user_quiz_pk: user_quiz_pk,
          user_option: user_option,
          question_id: question_id,
          unit_type: 0,
        })
      : JSON.stringify({
          material_id: material_id,
          topic_id: topic_id,
          user_quiz_pk: user_quiz_pk,
          user_option: user_option,
          question_id: question_id,
        });
  return BaseApiService.post(
    BaseURL + APIRequests.ValidateMandateAns,
    Params,
    true
  );
};

const SubmitMandateQuiz = (
  material_id,
  topic_id,
  user_quiz_pk,
  user_unit_track_id,
  quiz_pk
) => {
  let Params = "";
  if (user_unit_track_id !== null)
    Params = JSON.stringify({
      material_id: material_id,
      topic_id: topic_id,
      user_quiz_pk: user_quiz_pk,
      user_unit_track_id: user_unit_track_id,
      quiz_pk: quiz_pk,
    });
  else
    Params = JSON.stringify({
      material_id: material_id,
      topic_id: topic_id,
      user_quiz_pk: user_quiz_pk,
      // user_unit_track_id: user_unit_track_id,
      quiz_pk: quiz_pk,
    });
  return BaseApiService.post(
    BaseURL + APIRequests.FinalMandetQuizSubmt,
    Params,
    true
  );
};
const SubmitTrainingModule = (
  current_training_materials_id,
  unit_id,
  user_unit_track_id,
  completed_training_materials_id
) => {
  let Params = JSON.stringify({
    current_training_materials_id: current_training_materials_id,
    unit_id: unit_id,
    user_unit_track_id: user_unit_track_id,
    completed_training_materials_id: completed_training_materials_id,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.SubmitTrainingModule,
    Params,
    true
  );
};

const MandatoryTrainingMaterialTrack = (trainigId, userUnitTrackId) => {
  let params = "";
  if (userUnitTrackId)
    params = JSON.stringify({
      topic_id: trainigId,
      user_unit_track_id: userUnitTrackId,
    });
  else
    params = JSON.stringify({
      topic_id: trainigId,
      // user_unit_track_id: userUnitTrackId,
    });
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.MandatoryTrainingMaterialTrack,
    params,
    header
  );
};

const addGroupImages = (
  fileMerge,
  mediaType,
  slideName,
  trainingName,
  elementDisplayCount,
  unitId
) => {
  let params = new FormData();
  for (const image of fileMerge) {
    params.append("file", image);
  }
  if (trainingName.length > 0) {
    params.append("description", trainingName);
  }
  params.append("media_type", mediaType);
  params.append("slide_name", slideName);
  params.append("description", trainingName.length > 0 ? trainingName : "");
  params.append("element_display_count", elementDisplayCount);
  params.append("unit_id", unitId);
  // params.append("file", fileMerge);
  const header = false;
  // const Id = `${batchID}`;
  return BaseApiService.post(
    BaseURL + APIRequests.AddAdminGroupImages,
    params,
    header
  );
};

function addVideos(
  videoFile,
  description,
  slideName,
  unitId,
  mediaType,
  duration
) {
  let fd = new FormData();
  for (const video of videoFile) {
    fd.append("file", video);
  }

  fd.append("media_type", mediaType);
  fd.append("description", description);
  fd.append("slide_name", slideName ? slideName : "");
  fd.append("unit_id", unitId);
  // fd.append("file", videoFile);
  fd.append("duration", parseInt(duration));
  return BaseApiService.post(BaseURL + APIRequests.AddVideo, fd);
}

const getAllSlides = (id) => {
  return BaseApiService.get(
    BaseURL + APIRequests.GetAllSlidesOfMandatoryTraining + id
  );
};

function uploadSlideQuestions(file, mandateId, type) {
  let fd = new FormData();
  // fd.append("schedule_id", id);
  fd.append("file", file);
  fd.append("media_type", type);
  fd.append("unit_id", mandateId);
  return BaseApiService.post(
    BaseURL + APIRequests.AddNewSlideQuestion,
    fd,
    false
  );
}

const getImageSlide = (slideId) => {
  return BaseApiService.get(
    BaseURL + APIRequests.getImageSlides + `/${slideId}`,
    true
  );
};
const AddImageForSlide = (TraningId, file, description, slideId) => {
  let fd = new FormData();
  // fd.append("schedule_id", id);
  // for (const image of file) {
  // fd.append("file", file);
  // }
  fd.append("file", file);
  fd.append("description", description);
  fd.append("slide_id", slideId);
  const Params = JSON.stringify({
    file: file,
    description: description,
    slide_id: slideId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.addImageSlides + `/${TraningId}`,
    fd,
    false
  );
};

const AddSingleImageForSlide = (
  MandateId,
  elementId,
  slideId,
  descriptionValue
) => {
  let fd = new FormData();

  fd.append("description", descriptionValue);
  fd.append("unit_id", parseInt(MandateId));
  fd.append("slide_id", slideId);
  fd.append("element_id", elementId);
  const Params = JSON.stringify({
    description: descriptionValue,
    unit_id: MandateId,
    slide_id: slideId,
    element_id: elementId,
  });
  return BaseApiService.put(
    BaseURL + APIRequests.AddSingleImageToSlide,
    Params,
    true
  );
};

const ReorderImageInSlide = (listIds, SlideId) => {
  const Params = JSON.stringify({
    trainingSlideElementIds: listIds,
    slide_id: SlideId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.ReorderImageSlides,
    Params,
    true
  );
};

const DeleteSlideElement = (SlideId, ElementId) => {
  const Params = `?slide_id=${SlideId}&element_id=${ElementId}`;
  return BaseApiService.Delete(
    BaseURL + APIRequests.DeleteImageFromSlide + Params
  );
};

const ReorderSlide = (trainingIds, topic_id) => {
  const Params = JSON.stringify({
    trainingSlideIds: trainingIds,
    topic_id: topic_id,
  });
  return BaseApiService.post(BaseURL + APIRequests.ReoderSlide, Params, true);
};

const QuizAnswerValidator = (options, QuizId, userTrackId, MandateId) => {
  const Params = JSON.stringify({
    unit_id: parseInt(MandateId),
    slide_id: QuizId,
    option_id: parseInt(options),
    user_unit_track_id: parseInt(userTrackId),
  });
  return BaseApiService.post(
    BaseURL + APIRequests.ValidateUserAns,
    Params,
    true
  );
};

function postVideoWithQuizz(slideName, duriation, videoFile, mandateId) {
  let fd = new FormData();

  for (const video of videoFile) {
    fd.append("file", video);
  }
  fd.append("topic", slideName ? slideName : "");
  fd.append("duration", duriation);
  //fd.append("file", videoFile);
  // fd.append("file", videoFile);
  // fd.append("duration", parseInt(duration));
  return BaseApiService.post(
    BaseURL + APIRequests.AdminMandatoryTrainingMaterial + mandateId,
    fd
  );
}

function postPPT(
  slideName,
  duration,
  pptLink,
  mediaType,
  mandateId,
  file,
  pptfile
) {
  let fd = new FormData();

  fd.append("file", file);
  if (pptfile) {
    fd.append("file", pptfile);
  }
  fd.append("slide_name", slideName ? slideName : "");
  fd.append("duration", duration);
  if (pptLink) {
    fd.append("ppt_link", pptLink);
  }
  fd.append("media_type", mediaType);

  return BaseApiService.post(
    BaseURL + APIRequests.AddPPTForMandatoryTraining + mandateId,
    fd
  );
}

function GetConnectUserDetails(accessToken, instanceUrl, idParam) {
  return BaseApiService.GetConnect(instanceUrl, accessToken, idParam);
}

function ConnectLogin(Email, UserName, lastName, firstName,Phone) {
  const UserInfo = JSON.stringify({
    email: Email,
    username: UserName,
    lastName: lastName,
    firstName: firstName,
    Phone: Phone
  });

  return BaseApiService.post(
    BaseURL + APIRequests.AuthintecateConnectUser,
    UserInfo,
    true
  );
}

const AdminPreRequisiteStatusChange = (status, Id) => {
  const param = "";
  let Url = `?input_state=${status}&mandatory_training_id=${Id}`;
  const header = true;
  return BaseApiService.post(
    BaseURL + APIRequests.AddPreRequisitesStatus + Url,
    param,
    header
  );
};

const GetDashboardCounts = () => {
  return BaseApiService.get(BaseURL + APIRequests.DashboardCounts);
};
const GetDashboardScore = () => {
  return BaseApiService.get(BaseURL + APIRequests.DashBoardScore);
};
const getBootCampById = (id) => {
  const Id = `${id}`;
  return BaseApiService.get(BaseURL + APIRequests.AdminAddBootCamp + Id);
};

const startAssignment = (id) => {
  const Id = `${id}`;
  return BaseApiService.get(BaseURL + APIRequests.startAssignmentApi + Id);
};

const getAllUserPrograms = (param) => {
  return BaseApiService.get(BaseURL + APIRequests.UserAllPrograms + param);
};

const AdminAddMentorparticipants = (id, file) => {
  const Id = id;
  const header = true;
  let fd = new FormData();
  fd.append("file", file);
  return BaseApiService.post(
    BaseURL + APIRequests.AdminAddparticipants + Id,
    fd
  );
};

const AdminDeleteMentor = (mentorId, traineeId, batchId) => {
  const header = true;
  const param = JSON.stringify({
    mentor_id: mentorId,
    trainee_id: traineeId,
    batch_id: batchId,
  });
  return BaseApiService.post(
    BaseURL + APIRequests.AdminDeleteMentor,
    param,
    header
  );
};

export const API = {
  getBootCampById,
  uploadBootCampParticipant,
  deleteAdminBootCamp,
  adminCreateBootCamp,
  updateBootCampTrainer,
  getTraineeDetails,
  getBootCampParticipants,
  getTraineeDashBoardData,
  getFeed,
  getProfileInfo,
  postProfileInfo,
  startAssignment,
  postFeed,
  getAssignment,
  getAdminTrainerUpload,
  deleteAdminTrainerDelete,
  postAdminTrainerUploadPost,
  getMentorLayoutApi,
  getMentorSwitch,
  getTrainerLayoutApi,
  traineeChangePassword,
  adminLogin,
  signupPage,
  postTrainerSessionCalender,
  PostTrainer_SessionTraineeData,
  getTraineeSession_Details,
  getTrainerDashboard,
  getMentorDashBoard,
  postUserForgetPassword,
  postResetPassword,
  uploadAssignment,
  uploadAssignmentInTraineeSection,
  submitAllAsssignment,
  traineeAsssignmentSubmition,
  deleteAssignment,
  getHackathon,
  uploadHacakathon,
  SubmitAllHackthon,
  deleteHackathon,
  getAdminAssignment,
  addAdminAssignmnet,
  getAdminParameters,
  addAdminParameters,
  getAdminDepartments,
  addAdminQuizz,
  getAdminPrerequisites,
  addAdminPrerequisites,
  getAdminTrainingMaterial,
  addAdminTrainingMaterial,
  getAdminSchedule,
  addAdminSchedule,
  deleteAdminSchedule,
  getSessionDetails,
  getMentorTaskDetails,
  getMentorAssignData,
  getMentorQuiz,
  getMentorAssignDataAssessed,
  // getUpdatedScoresRemarks,
  getMentorTaskDataAssessed,
  getSessionDetailsMentor,
  getSearchedSessions,
  getTraineeallquestions,
  postTraineeOnclick,
  putTraineeOnclick,
  postTraineeOnClickNext,
  putTraineeOnClickNext,
  postTraineeAPICall,
  getTraineeQuizResults,
  postTraineehandleClik,
  putTraineehandleClik,
  getAdminQuestionsUpload,
  postAdminQuestionUpload,
  getAdminDepartmentss,
  getAdminCandidates,
  getAdminHackathons,
  postAdminAddCandidate,
  postAdminAddCandidateMail,
  postAdminHackathonUpload,
  getTrainerAssignmentDataFunc,
  getTrainerTaskDataFunc,
  getTrainerQuizDataFunc,
  getTrainerSessionDetailsFunc,
  enableTrainerQuizFunc,
  deleteAdminQuestions,
  deleteAdminCandidate,
  deleteAdminBatch,
  deleteAdminHackathon,
  getAdminCandidateById,
  getAdminaddbatchid,
  getTrainerUsersById,
  getTrainerHackathon,
  getTrainerHackathonScore,
  getTrainerHackathonTopScore,
  putAdminCandidateById,
  getSessionDetailsTrainer,
  addBatchData,
  getAllBatchData,
  getBatchesDetails,
  getSchedulers,
  getAdminAssignmentQuiz,
  deleteAdminAssignment,
  getHackathonOnBatchId,
  getHackathonData,
  getScheduleWithDates,
  getViewIconFun,
  getSchedulesData,
  getViewIconHackathonFun,
  getViewHackathonData,
  getEditAssignment,
  updateAdminAssignment,
  postScheduleSearchOnBatch,
  getAdminSessionDetails,
  getTrainers,
  postTraineesSearchOnBatch,
  adminAsnDeleteParams,
  adminAsnAddParams,
  editSessionSchedule,
  addAssignmentParams,
  editAssignmentOnly,
  editTrainerDetails,
  deleteAdminPreRequisite,
  deleteAdminTrainingMaterial,
  getAdminTraineeDetails,
  adminAddCourse,
  adminGetCourse,
  adminCreateCourse,
  adminCreateProgram,
  adminGetDifficultyLevels,
  adminDeleteUnit,
  deleteCourse,
  getDifficultyLevel,
  getAllTags,
  CreateUnit,
  getUnitById,
  finalUnitCreation,
  getAllUnit,
  getSearchedUnit,
  getAllUnits,
  getAllCourses,
  getAllUserCourses,
  getAllUserUnits,
  getAdminQuizType,
  AdminEditUnit,
  AdminDeleteUnit,
  getAdminQuizzes,
  deleteAdminQuizzes,
  AdminSaveForLaterBtn,
  adminEditCourse,
  adminQuizQuestionsDelete,
  AdminTrainingMaterialReorder,
  AdminPreRequisitesReorder,
  UnitReorder,
  EditPreDescription,
  getCourseDetailsByCourseId,
  getUserDetails,
  getUserUnitDetails,
  handleUnitStart,
  UnitpostFeed,
  userGetAssignment,
  userDeleteAssignment,
  getUserAllQuestions,
  getUserQuizResults,
  postUserOnclick,
  putUserOnclick,
  postUserOnClickNext,
  putUserOnClickNext,
  postUserAPICall,
  postUserhandleClik,
  putUserhandleClik,
  TrainingMaterialTrack,
  UserProfileUpdate,
  UserChangePassword,
  preRequisiteCompletion,
  trainingMaterialCompletion,
  AssignmentUploadStatus,
  postProgram,
  getAllCourse,
  getAllPrograms,
  getProgramById,
  adminEditProgram,
  postAdminuploadParams,
  getAdminParamsData,
  uploadProgramParticipant,
  deleteAdminProgramCandidate,
  getUsersTrainer,
  AdminDeleteProgramUnitCourse,
  updateProgramWithTrainer,
  getParticipants,
  getAllMentors,
  AdminDeleteProgram,
  getMandatoryTraninigById,
  CreateMandateTraining,
  getMandatoryTrainingById,
  getAllMandatoryTrainings,
  EditMandatoryTraining,
  finalAdminCreationPublish,
  AdminAddMandatoryTrainingMaterial,
  getAllUserTraining,
  getUserTrainingDetails,
  getUserTrackMandatoryTraining,
  deleteAdminMandatoryTrainingMaterial,
  viewAdminMandatoryTrainingMaterial,
  AdminQuizTrainingStatusChange,
  getModuleQuizData,
  ValidateMandateQuizAns,
  SubmitMandateQuiz,
  SubmitTrainingModule,
  MandatoryTrainingMaterialTrack,
  addGroupImages,
  addVideos,
  getAllSlides,
  uploadSlideQuestions,
  AdminSaveForLaterApi,
  deleteAdminMandatoryTrainingIntroSlides,
  editAdminMandatoryTrainingIntroSlides,
  getImageSlide,
  AddImageForSlide,
  AddSingleImageForSlide,
  ReorderImageInSlide,
  DeleteSlideElement,
  ReorderSlide,
  QuizAnswerValidator,
  postVideoWithQuizz,
  GetConnectUserDetails,
  ConnectLogin,
  postPPT,
  AdminPreRequisiteStatusChange,
  traineeUploadAssignment,
  GetDashboardCounts,
  GetDashboardScore,
  getAllUserPrograms,
  postBootCamp,
  adminEditBootCamp,
  AdminAddMentorparticipants,
  AdminDeleteMentor,
  editAdminAssignmnet,
  editAdminQuizconfiguration,
  getTraineeTopAchievers,
  getTraineeScoresAchieved,
};
