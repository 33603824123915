import React, { useEffect, useState } from "react";
import { Grid, Box, Button, DialogContentText } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import ReusableButton from "../../../../../components/reusable-button/reusable-button";
import ReusableButton from "../../../components/reusable-button/reusable-button";
// import { getMandateQuizDataHelper } from "../../../../../helper/mandatoryHelper";
// import { getMandateQuizDataHelper } from "../../../../../helper/mandatoryHelper";
import {
  getMandateQuizDataHelper,
  ValidateMandeQuizAns,
  SubmitMandateQuiz,
  SubmitTrainingModule,
} from "../../../helper/mandatoryHelper";
import { Await, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
// import { ValidateMandeQuizAns } from "../../../../helper/mandatoryHelper";
// import { SubmitMandateQuiz } from "../../../../../helper/mandatoryHelper";
// import Notification from "../../../../components/Custom/Notification";
import Notification from "../../../components/Custom/Notification";
import quizBackground from "../../../assets/Images/quizBackground.png";
// import quizBackground from "../../../../assets/Images/quizBackground.png";
// import { SubmitTrainingModule } from "../../../../helper/mandatoryHelper";
// import { API } from "../../../../Networking/API";
import { API } from "../../../Networking/API";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CompleteUnit from "../../../assets/Images/completeUnit.png";
import FailedUnit from "../../../assets/Images/quizfail.png";
import { useNavigate } from "react-router-dom";
import api from "../../../api";

export default function MandatoryUnitQuizQusetions(props) {
  const {
    QuizData,
    MandatoryId,
    setMandatoryTraining,
    setIsQuizEnabled,
    currentSelectedModule,
    courseId,
    // mandatetrainingData,
    mandatoryData,
    traningId,
    ClearLocalStorage,
    userUnitTrackId,
    selected,
    setSelected,
    isQuizEnabled,
    setVideoLink,
    setParentVideoId,
    setCurrentIndex,
    setSelectedTraningModule,
    setisQuizStarted,
    mandatetrainingData,
    MandatoryTraining,
    trainingMaterial,
    finalQuizEnable,
    setIsFinalQuizEnabled,
    setMakeIt,
    getUnitDetails,
    unitdata,
    list,
    handleTabChange,
    // setSelected,
    setIsVideoQuizEnabled,
  } = props;

  const [optionsOpted, setOptionsOpted] = useState([]);
  let [overAllQuizData, setoverallQuizData] = useState([]);
  let [Quiz, setQuiz] = useState([]);
  const [value, setValue] = useState();
  const [answered, setAnswered] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [quizLoader, setQuizLoader] = useState(true);
  const [showAns, setShowAns] = useState(false);
  const [AnsValue, setAnsValue] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [completedQuizQuestion, setCompletedQuizQuestion] = useState();
  const [isFinalSubmission, setIsFinalSubmission] = useState(false);
  const [requiredPercentage, setRequiredPercentage] = useState();
  const [achievedPercentage, setAchievedPercentage] = useState();
  const [totalAttempts, setTotalAttempts] = useState();
  const [topicName, setTopicName] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [sucesspopup, setSucesspopup] = useState();
  const [NoOfQuestions, setNoOfQuestions] = useState();
  const [passedQuestions, setPassedQuestions] = useState();
  const [calculatedPercentage, setCalculatedPercentage] = useState();
  
  const [showCompletePopUp, setShowCompletePopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const selectedobject = mandatoryData?.training_material?.find(
    (item, index) => item.material_pk === selected
  );
  
  useEffect(() => {
    
    // if (isQuizEnabled) {
    getMandateQuizDataHelper(
      localStorage.getItem("UnitId"),
      trainingMaterial
        .filter((data, index) => index === selected)
        .map((data) => {
          return data.material_pk;
        })[0],
      setoverallQuizData,
      setQuizLoader,
      setOptionsOpted,
      setNotify,
      setCompletedQuizQuestion
    );
  }, []);
  

  

  useEffect(() => {
    if (overAllQuizData.length > 0) {
      setQuiz(overAllQuizData[0].QuizQuestions);
    }
  }, [overAllQuizData]);
  
  const handleChange = (event, index) => {
    setIsNextButtonDisabled(false);
    ValidateMandeQuizAns(
      {
        MaterialId: trainingMaterial
          .filter((data, index) => index === selected)
          .map((data) => {
            return data.material_pk;
          })[0],
        topicId: window.localStorage.getItem("UnitId"),
        userPk: overAllQuizData[0].user_quiz_pk,
        userOption: event.target.value,
        questionId: index,
        unit_type: 0,
      },
      setShowAns,
      setAnsValue,
      setIsNextButtonDisabled
    );

    const QuestionOptionExist = optionsOpted.some(
      (options) => options.questionPk === index
    );
    if (!QuestionOptionExist) {
      setValue(event.target.value);
      setOptionsOpted([
        ...optionsOpted,
        { questionPk: index, value: event.target.value },
      ]);
      setAnswered([...answered, index]);
    }
  };

  useEffect(() => {
    return () => {};
  }, [location]);

  const getOptionValue = (questionIdetify) => {
    let OptionExist = optionsOpted.find(
      (questionAnsData) => questionAnsData.questionPk === questionIdetify
    );
    
    if (OptionExist) return OptionExist.value;
    else return null;
  };

  const getOptionValueBtn = (questionIdetify, index) => {
    
    if (index < completedQuizQuestion) return true;
    else if (!isNextButtonDisabled) return false;
    else {
      let OptionExist = optionsOpted.find(
        (questionAnsData) => questionAnsData.questionPk === questionIdetify
      );
      
      if (OptionExist) return OptionExist.value;
      else return null;
    }
  };
  
  const handleNextClick = async (event) => {
    setShowAns(false);
    setIsNextButtonDisabled(false);
    
    if (questionIndex + 1 === Quiz.length) {
      const IsQuizSubmitted = await SubmitMandateQuiz({
        MaterialId: trainingMaterial
          .filter((data, index) => index === selected)
          .map((data) => {
            return data.material_pk;
          })[0],
        topicId: localStorage.getItem("UnitId"),
        userPk: overAllQuizData[0].user_quiz_pk,
        // userTrack: window.localStorage.getItem("userUnitTrackId"),
        quizPk: overAllQuizData[0].quizPk,
      });
      
      setMakeIt(true);
      if (IsQuizSubmitted === true) {
        
        const currentIndex = trainingMaterial.findIndex(
          (obj, index) => index === selected
        );
        

        if (selected !== -1 && selected + 1 < trainingMaterial.length) {
          // const nextObject = trainingMaterial[currentIndex + 1];
          // if (nextObject) {
          //   setSelectedTraningModule(nextObject);
          //   setVideoLink(nextObject.video_link);
          //   setCurrentIndex(currentIndex + 1);
          //   setSelected(nextObject.material_pk);
          //   setParentVideoId(nextObject.material_pk);
          //   setIsQuizEnabled(false);
          //   setisQuizStarted(false);
          // }
          const selectedObject = list.find((x) => x.id === selected);
          const next = trainingMaterial?.find(
            (item, index) => index === selected + 1
          );
          const completed = trainingMaterial?.find(
            (item, index) => index === selected
          );
          

          getUnitDetails();
          localStorage.removeItem(
            "currentTrainingSeconds" + courseId + selectedObject.pk
          );
          setSelected(selected + 1);
          setIsVideoQuizEnabled(false);
        }
        
        if (
          trainingMaterial.length === selected + 1 &&
          unitdata.topicAssignments?.length === 0 &&
          unitdata.topicQuizz.length === 0
        ) {
          
          // setIsFinalQuizEnabled(true);
          setIsFinalSubmission(true);
        } else if (unitdata?.topicQuizz.length > 0) {
          handleTabChange("", 3);
        } else if (unitdata?.topicAssignments.length > 0) {
          handleTabChange("", 2);
        }
      }
    } else {
      setQuestionIndex((prev) => {
        return prev + 1;
      });
    }
  };
  const UNITID = window.localStorage.getItem("UnitId");
  const handleFinalClick = async (event) => {
    const NoFinalQuiztrack = await API.TrainingMaterialTrack(
      parseInt(UNITID),
      null
      // UserUnitTrackId
    );
    
    if (NoFinalQuiztrack.response.api_status === 400) {
      setSucesspopup("");
      setSucesspopup(0);
      setShowCompletePopup(true);
      setTotalAttempts(NoFinalQuiztrack.response.data.no_of_attempts);
      // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
      setRequiredPercentage(NoFinalQuiztrack.response.data.required_percentage);
    } else if (NoFinalQuiztrack.response.api_status === 200) {
      if (
        NoFinalQuiztrack.response.data.pass_percent >
        NoFinalQuiztrack.response.data.percentage
      ) {
        setNoOfQuestions(NoFinalQuiztrack.response.data.total_qns);
        setSucesspopup(0);
        setShowCompletePopup(true);
        setTotalAttempts(NoFinalQuiztrack.response.data.percentage);
        // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
        setRequiredPercentage(NoFinalQuiztrack.response.data.pass_percent);
        setCalculatedPercentage(
          NoFinalQuiztrack.response.data.required_percentage
        );
      } else {
        setSucesspopup("");
        setSucesspopup(1);
        setShowCompletePopup(true);
        setTotalAttempts(NoFinalQuiztrack.response.data.percentage);
        // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
        setRequiredPercentage(NoFinalQuiztrack.response.data.pass_percent);
        setCalculatedPercentage(
          NoFinalQuiztrack.response.data.required_percentage
        );
      }
    }
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };
  const handleCloseCompletePopup = () => {
    setShowCompletePopup(false);
    navigate(`/user/mandatoryTraining/${userUnitTrackId}`);
    // ClearLocalStorage();
  };
  const handleCompleteUnit = () => {
    setShowCompletePopup(false);
    navigate(`/user/mandatoryTraining/${userUnitTrackId}`);
    // ClearLocalStorage();
  };

  const checkIfDiabled = (AnsPrimaryKey) => {
    const QuestionOptionExist = optionsOpted.some(
      (options) => options.questionPk === AnsPrimaryKey
    );
    if (QuestionOptionExist) {
      return true;
    } else return false;
  };
  const getIsFinalSubmission = (index) => {
    if (!isFinalSubmission) return true;
    else if (index === Quiz.length - 1) return false;
    else return true;
  };
  

  return (
    <>
      {quizLoader ? (
        <Box
          style={{
            // height: "34vh",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={90}
            width={100}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      ) : (
        <>
          {Quiz.map((data, index) => {
            const questionname = data.question;
            data.options.selectedOption = data.userOption;
            data.options.questPk = data.question_pk;

            if (index === questionIndex) {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "white",
                      padding: "0px !important",
                      height: "auto",
                      borderBottomLeftRadius: "10px",
                      marginTop: "0px",
                      marginLeft: "20px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundImage: `url(${quizBackground})`,
                        // backgroundSize: "cover",
                        // backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        // width: "100%",
                        backgroundSize: "cover",
                        width: "100%",
                        height: "64vh",
                        overflowY: "scroll",
                        // height: "62vh",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid xs={2}></Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: "4%",
                            marginLeft: "4%",
                          }}
                        >
                          <b>
                            {" "}
                            {index + 1}.{questionname}{" "}
                          </b>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            marginTop: "4%",
                            marginRight: "2%",
                          }}
                        >
                          <b>{`${index + 1}/${Quiz.length}`}</b>
                        </Grid>
                      </Grid>

                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={getOptionValue(data.question_pk)}
                        onChange={(e) => {
                          handleChange(e, data.question_pk);
                        }}
                        disabled={checkIfDiabled(data.question_pk)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        {[data.options].map((opt, index) => {
                          return (
                            <>
                              <Box style={{ marginLeft: "12%" }}>
                                {opt.a ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.a.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.a.a}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.b ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.b.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.b.b}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "60px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.c ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.c.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.c.c}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "0px" }}
                                    ></Grid>
                                  </>
                                )}
                                {opt.d ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Grid item xs={1}></Grid>
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <FormControlLabel
                                          value={opt.d.id}
                                          control={
                                            <Radio
                                              disabled={checkIfDiabled(
                                                data.question_pk
                                              )}
                                            />
                                          }
                                          label={opt.d.d}
                                        />
                                      </Grid>
                                      <Grid item xs={1}></Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ height: "0px" }}
                                    ></Grid>
                                  </>
                                )}
                                {showAns ? (
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginTop: "20px",
                                      marginLeft: "8.5%",
                                      backgroundColor: "#02215B",
                                    }}
                                  >
                                    <span
                                      style={{
                                        padding: "8px 30px",
                                        borderRadius: "20px",
                                        // display: "flex",
                                        // alignItems: "center",
                                        // justifyContent: "center",
                                        color: "#FFFFFF",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        lineHeight: "23px",
                                        // marginBottom: "20px",
                                      }}
                                    >
                                      {AnsValue}
                                    </span>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </>
                          );
                        })}
                      </RadioGroup>
                    </Box>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid item xs={1}>
                        

                        {
                          // !isFinalSubmission && (index !== (Quiz.length - 1))
                          getIsFinalSubmission(index) ? (
                            <ReusableButton
                              size="large"
                              variant="contained"
                              className="candidateDltepageDeleteBttn"
                              buttonName="Next"
                              disabled={
                                getOptionValueBtn(data.question_pk, index)
                                  ? false
                                  : true
                              }
                              style={{
                                backgroundColor: getOptionValueBtn(
                                  data.question_pk,
                                  index
                                )
                                  ? "#fb2485"
                                  : "grey",
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "white",
                                height: "35px",
                                //backgroundColor: "#FB2485",
                                borderRadius: "4px",
                                marginLeft: "-13px",
                                textTransform: "none",
                              }}
                              onClick={(e) => {
                                handleNextClick();
                              }}
                            ></ReusableButton>
                          ) : (
                            <>
                              <ReusableButton
                                size="large"
                                variant="contained"
                                className="candidateDltepageDeleteBttn"
                                buttonName="Finish"
                                // disabled={
                                //   getOptionValueBtn(data.question_pk, index)
                                //     ? false
                                //     : true
                                // }
                                style={{
                                  backgroundColor: "#fb2485",
                                  width: "98px",
                                  marginLeft: "-23px",
                                  height: "44px",
                                  flexGrow: 0,
                                  padding: "13px 32px 13px 33px",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "8px 28px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                }}
                                onClick={(e) => {
                                  handleFinalClick();
                                }}
                              ></ReusableButton>
                            </>
                          )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            }
          })}
        </>
      )}
      {sucesspopup == 1 ? (
        <Dialog
          open={showCompletePopUp}
          className="dialogBox"
          keepMounted
          onClose={handleCloseByCancelModal}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: {
              backgroundColor: "black",
              opacity: "0.5",
              boxShadow: "0px 0px 0px 0px !important",
            },
          }}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{
                color: "#000",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <b
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                You have passed Congratulations!
              </b>
              <img
                src={CompleteUnit}
                alt="congrats=image"
                height="240"
                widthe="220"
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                {/* {`You have successfully completed the ${topicName}`} */}
              </p>
              {/* <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                {`Score Achieved: ${achievedPercentage}`}
              </p> */}
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                {`Percentage Scored:  ${totalAttempts}`}
              </p>
            </DialogContentText>
          </DialogContent>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "30px",
            }}
          >
            <ReusableButton
              size="large"
              variant="outlined"
              className="candidateDltepageCancelBttn"
              buttonName="Close"
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#000936",
                border: "1px solid #000936",
                padding: "7px 20px",
                borderRadius: "4px",
                marginRight: "8px",
                textTransform: "none",
              }}
              onClick={handleCloseCompletePopup}
            />
            <ReusableButton
              size="large"
              variant="contained"
              className="candidateDltepageDeleteBttn"
              buttonName="Continue"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "10px",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "8px 28px",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={(e) => {
                handleCompleteUnit();
              }}
            >
              Submit
            </ReusableButton>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={showCompletePopUp}
          className="dialogBox"
          keepMounted
          onClose={handleCloseByCancelModal}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: {
              backgroundColor: "black",
              opacity: "0.5",
              boxShadow: "0px 0px 0px 0px !important",
            },
          }}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{
                color: "#000",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // paddingBottom: "40px",
              }}
            >
              <b
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                You have Failed !
              </b>
              <img
                src={FailedUnit}
                alt="congrats=image"
                height="240"
                width="400"
              />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "2px",
                  color: "#000000",
                }}
              >
                {/* {`You have successfully completed the ${topicName}`} */}
                Better luck next time
              </p>
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "29px",
                  color: "#000000",
                }}
              >
                {`Required percentage: ${requiredPercentage}%`}
              </p>
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "2px",
                  color: "#000000",
                }}
              >
                {`Percentage Scoreds:  ${totalAttempts}%`}
              </p>
            </DialogContentText>
          </DialogContent>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "30px",
            }}
          >
            <ReusableButton
              size="large"
              variant="outlined"
              className="candidateDltepageCancelBttn"
              buttonName="Close"
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#000936",
                border: "1px solid #000936",
                padding: "7px 20px",
                borderRadius: "4px",
                marginRight: "8px",
                textTransform: "none",
              }}
              onClick={handleCloseCompletePopup}
            />
            <ReusableButton
              size="large"
              variant="contained"
              className="candidateDltepageDeleteBttn"
              buttonName="Continue"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "10px",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "8px 28px",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={(e) => {
                handleCompleteUnit();
              }}
            >
              Submit
            </ReusableButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
