import React from "react";
import { useLocation } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import quizsuccess from "../../../assets/Images/quizsuccess.png";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import StarsIcon from "@mui/icons-material/Stars";
import "./quiz.css";

export default function QuizResultUser() {
  const location = useLocation();

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self", ""); //bug fix
    window.close();
    localStorage.removeItem("minsecs");
  };

  return (
    <>
      <Box style={{ padding: "36px", marginBottom: "100px" }}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-60px",
            }}
          >
            <h4
              style={{
                fontSize: "1.3750em",
                fontFamily: "Poppins",
                color: "white",
                fontStretch: "normal",
                letterSpacing: "normal",
              }}
            >
              Quiz
            </h4>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "white",
              marginLeft: "15px",
              marginTop: "-20px",
              borderRadius: "35px",
              padding: "0px !important",
              height: "70vh",
            }}
          >
            <Grid
              className="card-img"
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                color: "white",
                minHeight: "95px",
              }}
            >
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                }}
              >
                {location.state.result.topic}
              </Grid>

              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginLeft: "20px",
                  }}
                >
                  <img
                    src={quizsuccess}
                    alt="quizfail"
                    width="267"
                    height="241"
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#0c9251",
                    }}
                  >
                    <StarsIcon style={{ height: "64px", width: "64px" }} />
                  </Grid>
                  <Typography
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <b>Congratulations, you have</b>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <b> successfully completed the quiz.</b>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <b>Results Will Be Updated Soon!!</b>
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ReusableButton
                      size="small"
                      variant="outlined"
                      className="candidateDltepageDeleteBttn"
                      buttonName="Close"
                      style={{
                        width: "98px",
                        height: "44px",
                        flexGrow: 0,
                        margin: "24px 50px 0 20px",
                        padding: "13px 32px 13px 33px",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#000936",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        textTransform: "none",
                        border: "1px solid #000936",
                        backgroundColor: "none",
                      }}
                      onClick={closeTab}
                    ></ReusableButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
