import { React, useState, useEffect, useRef } from "react";
import "./batch.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { AddBatch } from "./add-batch";
import { API } from "../../../Networking/API";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Editpopup from "./editpopupbatch";
import { useNavigate } from "react-router-dom";
import Notification from "../../../components/Custom/Notification";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { DataTableHandler } from "../../../handler/dataTableHandler";
import { SxStyles } from "../../../styles/styles";
import ReactLoading from "react-loading";

const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

export const Batch = () => {
  const [open, setOpen] = useState(false);
  const [batchData, setBatchData] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [done, setDone] = useState(undefined);
  const [fileMerge, setFileMerge] = useState("");
  const [id, setId] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const current = new Date();
  const inputRef = useRef(null);

  let datecurrent;
  datecurrent = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const inputFieldData = {
    name: "",
    startdate: datecurrent,
    endDate: datecurrent,
  };

  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);

  const handleInputNameChange = (event) => {
    setInputFieldValues({ ...inputFieldValues, name: event.target.value });
  };

  const handleInputChange = (value) => {
    setInputFieldValues({ ...inputFieldValues, startdate: value.$d });
  };
  const handleInputChange2 = (event) => {
    setInputFieldValues({ ...inputFieldValues, endDate: event.$d });
  };

  const handleAddButtonClick = () => {
    if (inputFieldValues.name === "") {
      setNotify({
        isOpen: true,
        message: "Please provide bootcamp name",
        type: "error",
      });
    } else if (inputFieldValues.endDate === datecurrent) {
      setNotify({
        isOpen: true,
        message: "Please select End Date",
        type: "error",
      });
    } else if (fileMerge === "") {
      setNotify({
        isOpen: true,
        message: "Please select file to upload",
        type: "error",
      });
    } else {
      const name = inputFieldValues.name;
      const startdates = inputFieldValues.startdate;
      const endDates = inputFieldValues.endDate;

      const date = convert(startdates);
      const date01 = convert(endDates);

      API.addBatchData(name, date, date01, fileMerge).then(({ response }) => {
        const data = response;
        if (data.api_status === 200) {
          setInputFieldValues({
            ...inputFieldValues,
            name: "",
            startdates: datecurrent,
            endDates: datecurrent,
          });
          inputRef.current.value = null;
          setOpen(false);
          setNotify({
            isOpen: true,
            message: "Batch Added Successfully",
            type: "success",
          });
          getBatchData();
        } else if (data.api_status === 502) {
          setNotify({
            isOpen: true,
            message: "Duplicate name found, Try different name",
            type: "warning",
          });
        } else if (data.api_status === 400) {
          const errormsg = data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: errormsg,
            type: "warning",
          });
        } else if (data.api_status === 403) {
          const errormsg = data.error[0].error_msg;
          setNotify({
            isOpen: true,
            message: errormsg,
            type: "warning",
          });
        }
      });
    }
    // }
  };
  const handleChange = (files /*:File*/) => {
    setFileMerge(files.target.files[0]);
    setShowValues(true);
  };

  function convert(startdate) {
    var date = new Date(startdate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };
  const handleCloseByCancelModalButton = () => {
    setOpenModal(false);
  };
  let navigate = useNavigate();
  useEffect(() => {
    getBatchData();
  }, [openModal]);

  const getBatchData = async() => {
    API.getAllBatchData().then(async({ response }) => {
      const data = response.data.data[0].bacthes;
      const UpdatedData = await DataTableHandler.BootCampTableHandler(data);
      setBatchData(UpdatedData);
      setDone(true);
    });
  };

  const handleDelete = async (paramsId) => {
    API.deleteAdminBatch(paramsId).then(({ result }) => {
      const resultInJson = result;
      if (resultInJson.api_status === 200) {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Batch deleted successfully",
          type: "success",
        });
      } else {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Batch  Cannot Be Deleted",
          type: "error",
        });
      }
    });
  };

  const handlePendingDriveClick = async (params) => {
    let id = params.row.id;
    let Bootcampname = params.row.BootcampName;
    navigate("/home/bootcampview", {
      state: {
        batchid: id,
        batch_bootcampname: Bootcampname,
      },
    });
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      width: 30,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "BootcampName",
      headerName: "Bootcamp Name",
      flex: 1,
      width: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <>
          <span
            style={{ cursor: "pointer", color: "#00728E" }}
            onClick={() => handlePendingDriveClick(params)}
          >
            {params.row.BootcampName}
          </span>
        </>
      ),
    },
    {
      field: "StartDate",
      headerName: "start Date",
      flex: 1,
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "EndDate",
      headerName: "End Date",
      flex: 1,
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="delete-button">
            <IconButton aria-label="delete" size="small">
              {" "}
              <DeleteOutlineIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                }}
                onClick={() => handleClickOpenDelModal(params.id)}
              />
            </IconButton>

            <Dialog
              open={openModal}
              className="dialogBox"
              keepMounted
              onClose={handleCloseByCancelModal}
              aria-describedby="alert-dialog-slide-description"
              BackdropProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
              >
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseByCancelModalButton}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    marginRight: "10px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                  onClick={() => handleDelete(id)}
                ></ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];
  return (
    <>
      <Box className="batch-wrapper">
        <Box className="batch-header">
          <Grid container spacing={2}>
            <Grid item xs={5.5}></Grid>
            <Grid
              item
              xs={3.5}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <span className="batch-text">Schedules</span>
            </Grid>
          </Grid>
        </Box>
        <Container
          component="main"
          maxWidth="xxl"
          style={{
            padding: 0,
            marginBottom: 15,
          }}
        >
          <Card
            variant="outlined"
            sx={{ marginTop: "2%", Width: "100%", borderRadiusTopLeft: 2 }}
          >
            <Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  padding: "10px 30px 18px 30px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={2.5}
                    style={{
                      display: "flex",
                      marginTop: "18px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <ReusableInputfield
                      name="firstname"
                      placeholder="New Bootcamp Name"
                      value={inputFieldValues.name}
                      onChange={handleInputNameChange}
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      marginTop: "18px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Start Date"
                        openTo="day"
                        value={inputFieldValues.startdate}
                        onChange={(val) => {
                          handleInputChange(val);
                        }}
                        minDate={new Date()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      marginTop: "18px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="MM/DD/YYYY"
                        value={inputFieldValues.endDate}
                        onChange={handleInputChange2}                       
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().setMonth(new Date().getMonth() + 2)
                          )
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      class="upload-box"
                      ref={inputRef}
                      accept={SheetJSFT}
                      style={{
                        marginTop: "18px",
                        fontSize: "0.8em",
                        fontFamily: "Poppins",
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2.5}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ReusableButton
                      onClick={handleAddButtonClick}
                      size="large"
                      variant="outlined"
                      buttonName="Create"
                      style={{
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "white",
                        backgroundColor: "#FB2485",
                        padding: "7px 20px",
                        borderRadius: "4px",
                        marginRight: "10px",
                        marginBottom: "-17px",
                        border: "none",
                        textTransform: "none",
                      }}
                    ></ReusableButton>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Card>
        </Container>
        <>
          {!done ? (
            <>
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={90}
                  width={100}
                />
              </Box>
            </>
          ) : (
            <>
              {batchData.length === 0 ? (
                <Box className="batch-error-box">
                  <span className="batch-error-text">No Batches Added Yet</span>
                </Box>
              ) : (
                <>
                  <Box style={{ marginTop: "10px", marginBottom: "100px" }}>
                    <DataTable
                      rows={batchData}
                      columns={columns}
                      pageSize={10}
                      rowHeight={40}
                      rowsPerPageOptions={[10]}
                      autoHeight
                      sx={SxStyles.DatGridStyles}
                      width="100%"
                      className="batch-data-table"
                      hideFooterSelectedRowCount={true}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </>

        <AddBatch
          open={open}
          handleClose={handleClickClose}
          setOpen={setOpen}
          getBatchData={getBatchData}
        />
      </Box>
      <Editpopup
        open={openEdit}
        id={editId}
        handleClose={handleEditClose}
        batchData={batchData}
        getBatchData={getBatchData}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
