import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
export default function Phone(props) {
  const {
    name,
    value,
    size,
    label,
    placeholder,
    onChange,
    error = null,
    className,
  } = props;
  return (
    <TextField
      variant={"outlined"}
      onChange={onChange}
      type="text"
      // inputmode="numeric"
      // pattern="[0-9]*"
      size={size}
      onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value))
          .toString()
          .slice(0, 10);
      }}
      inputProps={{ maxLength: 10, inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      }}
      value={value}
      placeholder={label}
      name={name}
      {...(error && { error: true, helperText: error })}
      className={className}
    />
  );
}
