import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./menu-items-user.css";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

export default function MenuItems(props) {
  const location = useLocation();

  const { active, hActive } = props;
  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/user/"
            className={({ isActive }) =>
              isActive ? "link-active" : hActive ? "link-active" : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Home" placement="right" arrow>
                <HomeIcon />
              </Tooltip>
            </ListItemIcon>
            Dashboard
          </NavLink>
        </ListItemText>
      </ListItem>

      {/* //Trainee */}

      {location.pathname === "/user/bootcamp/home/" ||
      location.pathname === "/user/bootcamp/home/changePassword" ||
      location.pathname === "/user/bootcamp/home/Profile/" ||
      location.pathname === "/user/bootcamp/home/quizQuestions" ||
      location.pathname === "/user/bootcamp/home/quizResult" ||
      location.pathname === "/user/bootcamp/home/sessiondetails" ||
      location.pathname === "/user/bootcamp/home/calendardetails" ? (
        <ListItem style={{ padding: "0px" }}>
          <ListItemText style={{ color: "#FFF" }}>
            <NavLink
              to="/user/bootcamp/home/calendardetails"
              className={({ isActive }) =>
                isActive ? "link-active" : active ? "link-active" : "link"
              }
            >
              <ListItemIcon style={{ color: "#FFF" }}>
                <Tooltip title="Sessions" placement="right" arrow>
                  <CalendarMonthIcon />
                </Tooltip>
              </ListItemIcon>
              Calendar Details
            </NavLink>
          </ListItemText>
        </ListItem>
      ) : null}

      {/* //trainer */}

      {location.pathname === "/user/bootcamp/trainer/home/" ||
      location.pathname === "/user/bootcamp/trainer/home/Profile" ||
      location.pathname === "/user/bootcamp/trainer/home/session" ||
      location.pathname === "/user/bootcamp/trainer/home/session-details" ||
      location.pathname === "/user/bootcamp/trainer/home/hackathon" ||
      location.pathname === "/user/bootcamp/trainer/home/hackathon-details" ? (
        <>
          <ListItem style={{ padding: "0px" }}>
            <ListItemText style={{ color: "#FFF" }}>
              <NavLink
                to="/user/bootcamp/trainer/home/session"
                className={({ isActive }) =>
                  isActive ? "link-active" : active ? "link-active" : "link"
                }
              >
                <ListItemIcon style={{ color: "#FFF" }}>
                  <Tooltip title="Sessions" placement="right" arrow>
                    <CalendarMonthIcon />
                  </Tooltip>
                </ListItemIcon>
                Session Details
              </NavLink>
            </ListItemText>
          </ListItem>

          {/* <ListItem style={{ padding: "0px" }}>
            <ListItemText style={{ color: "#FFF" }}>
              <NavLink
                to="/user/bootcamp/trainer/home/hackathon"
                className={({ isActive }) =>
                  isActive ? "link-active" : "link"
                }
              >
                <ListItemIcon style={{ color: "#FFF" }}>
                  <Tooltip title="Hackathon" placement="right" arrow>
                    <PendingActionsIcon />
                  </Tooltip>
                </ListItemIcon>
                Hackathon Details
              </NavLink>
            </ListItemText>
          </ListItem> */}
        </>
      ) : null}

      {location.pathname === "/user/bootcamp/mentor/home/" ||
      location.pathname === "/user/bootcamp/mentor/home/Profile" ||
      location.pathname === "/user/bootcamp/mentor/home/session" ||
      location.pathname === "/user/bootcamp/mentor/home/session-details" ? (
        <>
          <ListItem style={{ padding: "0px" }}>
            <ListItemText style={{ color: "#FFF" }}>
              <NavLink
                to="/user/bootcamp/mentor/home/session"
                className={({ isActive }) =>
                  isActive ? "link-active" : active ? "link-active" : "link"
                }
              >
                <ListItemIcon style={{ color: "#FFF" }}>
                  <Tooltip title="Session Details" placement="right" arrow>
                    <CalendarMonthIcon />
                  </Tooltip>
                </ListItemIcon>
                Session Details
              </NavLink>
            </ListItemText>
          </ListItem>
        </>
      ) : null}
    </div>
  );
}
