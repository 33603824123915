import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./menu-items-mentor.css";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";

export default function MenuItems(props) {
  const { active, hActive } = props;
  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/user/bootcamp/mentor/home/"
            className={({ isActive }) =>
              isActive ? "link-active" : hActive ? "link-active" : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Home" placement="right" arrow>
                <HomeIcon />
              </Tooltip>
            </ListItemIcon>
            Dashboard
          </NavLink>
        </ListItemText>
      </ListItem>

      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/user/bootcamp/mentor/home/session"
            className={({ isActive }) =>
              isActive ? "link-active" : active ? "link-active" : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Session Details" placement="right" arrow>
                <CalendarMonthIcon />
              </Tooltip>
            </ListItemIcon>
            Session Details
          </NavLink>
        </ListItemText>
      </ListItem>
    </div>
  );
}
