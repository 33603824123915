import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PinkFilled from "../../../assets/Images/checkPink.svg";
import completedCheck from "../../../assets/Images/completedCheck.svg";
import uniticonuncheck from "../../../assets/Images/uniticonuncheck.png";

export default function CheckboxUnitList(props) {
  const {
    list,
    selected,
    setSelected,
    courseUnits,
    setUnitDetails,
    courseLoading,
    isCourseStarted,
  } = props;
  const [selectedUnitCompleted, setSelectedUnitCompleted] = React.useState("");
  let listLength = list.length;

  const handleToggle = (value) => {
    if (value) {
      setSelected(value.id);
      setSelectedUnitCompleted(value.isUnitCompleted);
      const filteredData = courseUnits.filter((x) => x.id === value.id);
      setUnitDetails({
        topic: filteredData[0].unitName,
        description: filteredData[0].description,
      });
    }
  };
  React.useEffect(() => {
    if (!isCourseStarted) {
      handleToggle(list[0]);
    }
    const isCurrent = list.findIndex((x) => x.isCurrentUnit === true);
    if (courseLoading && isCurrent >= 0) {
      handleToggle(list[isCurrent]);
    }
  }, [courseLoading]);

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {list.map((value, index) => {
        let disabled;
        if (index === 0) {
          disabled = false;
        } else if (list.length > 1) {
          disabled = list[index - 1].isUnitCompleted === false;
        }

        return (
          <>
            <ListItem
              key={value}
              style={{
                width: "20vw",
                paddingRight: "2%",
                padding: 0,
                margin: 0,
                backgroundColor: selected === value.id ? "#02215B" : "#fff",
                color: selected === value.id ? "#fff" : "#000",
                border: "1px solid #02215B",
                borderBottom:
                  index === 0
                    ? "1px solid #02215B"
                    : index === list.length - 1
                    ? "1px solid #02215B"
                    : "none",
                borderRadius:
                  list.length === 1
                    ? "8px 8px 8px 8px"
                    : index === 0
                    ? "8px 8px 0px 0px"
                    : index === list.length - 1
                    ? "0px 0px 8px 8px"
                    : "0px",
              }}
            >
              <ListItemButton
                role={undefined}
                onClick={() => handleToggle(value)}
                dense
                disabled={disabled}
              >
                <ListItemIcon style={{ minWidth: "30px" }}>
                  <img
                    src={
                      selected === value.id
                        ? PinkFilled
                        : disabled
                        ? uniticonuncheck
                        : completedCheck
                    }
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText id={value.id}> {value.unitName} </ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        );
      })}
    </List>
  );
}
