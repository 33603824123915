import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { Custom } from "../../../components/Custom/Custom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./candidates-count.css";
import { InputCss } from  "../admin-layout/adminStyles";
import { TraineeCol } from  "../admin-layout/adminStyles";
import { CancelBtn } from  "../admin-layout/adminStyles";
import { AddBtn } from  "../admin-layout/adminStyles";


const AddCollabModal = (props) => {
  const {
    FirstName,
    handleInputChange,
    errors,
    Email,
    Designation,
    handleInputChangePh,
    Phone,
    filterDD,
    handlebootcampValue,
    batchesDD,
    Department,
    departments,
    showField,
    College,
    showFieldDesp,
    Description,
    handleCloseByCancel,
    handleClose,
  } = props;
  return (
    <>
      <DialogTitle>Create New Collaborator Record</DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                >
                  Full Name
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Input
                    name="firstname"
                    placeholder="Full Name"
                    value={FirstName}
                    onChange={handleInputChange}
                    error={errors.firstname}
                    size="small"
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Email Address
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Input
                    name="email"
                    value={Email}
                    placeholder="example@email.com"
                    onChange={handleInputChange}
                    error={errors.email}
                    size="small"
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Designation
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Input
                    name="designation"
                    placeholder="Designation"
                    onChange={handleInputChange}
                    value={Designation}
                    error={errors.designation}
                    size="small"
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Phone Number
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Phone
                    name="phone"
                    label="0000000000"
                    value={Phone}
                    onChange={handleInputChangePh}
                    error={errors.phone}
                    size="small"
                    type="text"
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Experience
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Input
                    type={"number"}
                    size="small"
                    name="experience"
                    label="In Years"
                    // value={values.phone}
                    error={errors.experience}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { max: 30 } }}
                    // onChange={(event) =>
                    // event.target.value < 0
                    // ? (event.target.value = 0)
                    // : event.target.value
                    // }
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Certificates
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.Input
                    label="In Nos"
                    type={"number"}
                    size="small"
                    name="certificates"
                    onChange={handleInputChange}
                    // value={values.phone}
                    error={errors.certificates}
                    InputProps={{ inputProps: { max: 30 } }}
                  />
                </span>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Batch Name
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  <Custom.BatchDropdown
                    name="Select Bootcamp"
                    value={filterDD}
                    onChange={handlebootcampValue}
                    options={batchesDD}
                    error={errors.batch}
                    size="small"
                    style={{
                      height: 40,
                      fontFamily: "Poppins",
                    }}
                  ></Custom.BatchDropdown>
                </span>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={InputCss}
                  variant="h6"
                >
                  Role
                </Typography>
                <span style={{ marginLeft: "-7px" }}>
                  { <Custom.Dropdown2
                    name="department"
                    label="Select"
                    value={Department}
                    onChange={handleInputChange}
                    options={departments}
                    error={errors.department}
                    size="small"
                    style={{
                      height: 40,
                      alignItems: "center",
                      display: "flex",
                      color: "black",
                    }}
                  ></Custom.Dropdown2> }
                </span>
              </div>
            </Grid>

            {showField === true ? (
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    style={TraineeCol}
                    variant="h6"
                  >
                    Trainee College
                  </Typography>
                  <span style={{ marginLeft: "-7px" }}>
                    <Custom.Input
                      name="college"
                      placeholder="College Name"
                      value={College}
                      onChange={handleInputChange}
                      error={errors.college}
                      size="small"
                    />
                  </span>
                </div>
              </Grid>
            ) : null}

            {showFieldDesp === true ? (
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    style={TraineeCol}
                    variant="h6"
                  >
                    Description
                  </Typography>
                  <span style={{ marginLeft: "-7px" }}>
                    <Custom.Input
                      name="description"
                      placeholder="Description"
                      value={Description}
                      onChange={handleInputChange}
                      error={errors.description}
                      size="small"
                    />
                  </span>
                </div>
              </Grid>
            ) : (
              <Grid item xs={6}></Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <ReusableButton
          onClick={handleCloseByCancel}
          size="large"
          variant="outlined"
          buttonName="Cancel"
          style={CancelBtn}
        >
          Cancel
        </ReusableButton>
        <ReusableButton
          onClick={handleClose}
          className="addbutton"
          type="submit"
          size="large"
          variant="contained"
          buttonName="Add"
          style={AddBtn}
        >
          Add
        </ReusableButton>
      </DialogActions>
    </>
  );
};
export default AddCollabModal;
