import { APIRequests, BaseURL } from "../../src/Networking/NetworkConstants";

export const UnitSearchHandler = async (selecteddata) => {
  switch (selecteddata) {
    case "ALL":
      return true;
    case "":
      return true;
    default:
      return false;
  }
};

export const UnitQueryParams = (
  isSelectedDifficultyLevel,
  isSeletedTag,
  courseDifficultyLevel,
  unitSelectedTag,
  limit,
  pageCount,
  isUnitorCourse
) => {
  switch (true) {
    case isSelectedDifficultyLevel === true && isSeletedTag === true:
      return (
        (isUnitorCourse == "unit"
          ? APIRequests.GetSearchedUnit
          : APIRequests.GetSearchedCourse) + `?limit=${limit}&page=${pageCount}`
      );
    case isSelectedDifficultyLevel === false && isSeletedTag === true:
      return (
        (isUnitorCourse == "unit"
          ? APIRequests.GetSearchedUnit
          : APIRequests.GetSearchedCourse) +
        `?level_id=${courseDifficultyLevel}&limit=${limit}&page=${pageCount}`
      );
    case isSelectedDifficultyLevel === true && isSeletedTag === false:
      return (
        (isUnitorCourse == "unit"
          ? APIRequests.GetSearchedUnit
          : APIRequests.GetSearchedCourse) +
        `?${
          isUnitorCourse == "unit" ? "tag" : "search"
        }=${unitSelectedTag}&limit=${limit}&page=${pageCount}`
      );
    default:
      return (
        (isUnitorCourse == "unit"
          ? APIRequests.GetSearchedUnit
          : APIRequests.GetSearchedCourse) +
        `?${
          isUnitorCourse == "unit" ? "tag" : "search"
        }=${unitSelectedTag}&level_id=${courseDifficultyLevel}&limit=${limit}&page=${pageCount}`
      );
  }
};

export const AddCourseCheckBoxHandler = async (
  selectedId,
  checkboxId,
  setCheckboxId,
  selectedUnitData,
  setselectedUnitData,
  UnitData
) => {
  if (checkboxId.has(selectedId)) {
    setCheckboxId((prev) => {
      const next = new Set(prev);
      next.delete(selectedId);
      return next;
    });
    let Index;
    checkboxId.forEach((Id, index) => {
      if (Id === selectedId) {
        Index = index;
      }
    });

    let final = selectedUnitData;
    final.forEach((data, index) => {
      if (index.id === selectedId) {
        final.delete(index);
      }
    });
    setselectedUnitData(final);
  } else {
    let SelectedOverallData = {
      id: UnitData.id,
      unitName: UnitData.topic,
      unitDuration: UnitData.duration,
      Status: UnitData.active_status === 1 ? "Active" : "InActive",
      endtime: UnitData.endtime ? UnitData.endtime : "N/A",
      starttime: UnitData.starttime ? UnitData.starttime : "N/A",
      trainer: UnitData.trainer ? UnitData.trainer : "N/A",
      date: UnitData.date ? UnitData.date : "N/A",
    };
    setCheckboxId((prev) => new Set(prev).add(selectedId));
    setselectedUnitData((prev) => new Set(prev).add(SelectedOverallData));
  }
};

export const UnitAddtionHandler = async (
  setPageCount,
  totalUnitData,
  selectedUnitData,
  setTotalUnitData,
  setCourseDifficultyLevel,
  setAddUnitDialog,
  checkboxId
) => {
  setPageCount(0);
  let FinalSelectedUnit = totalUnitData.filter((data) =>
    checkboxId.has(data.id)
  );

  let FinalArray = Array.from(selectedUnitData).concat(FinalSelectedUnit);
  setTotalUnitData(
    FinalArray.filter(
      (item, index) =>
        FinalArray.findIndex((elem) => elem.id === item.id) === index
    )
  );
  setCourseDifficultyLevel("");
  setAddUnitDialog(false);
};

export const CourseHandler = (allCourseData) => {
  if (allCourseData.length > 0) {
    const modifiedCourseData = allCourseData.map((indCourseData) => {
      return {
        courseId: indCourseData.id,
        courseTitle: indCourseData.course_name,
        time: indCourseData.duration,
        courseDetails: indCourseData.about_course,
        courseActive: indCourseData.active_date,
        courseStatus: indCourseData.status,
        courseTechnology: indCourseData.technology,
        units: indCourseData.units.map((indUnitData) => {
          return {
            unitid: indUnitData.id,
            unitTitle: indUnitData.topic,
            unitDetails: indUnitData.description,
            status: indUnitData.status,
            duration: indUnitData.duration,
            endtime: indUnitData.endtime ? indUnitData.endtime : "N/A",
            starttime: indUnitData.starttime ? indUnitData.starttime : "N/A",
            trainer: indUnitData.trainer ? indUnitData.trainer : "N/A",
            date: indUnitData.date ? indUnitData.date : "N/A",
          };
        }),
      };
    });
    return modifiedCourseData;
  } else return [];
};

export const courseUnitCheckboxHandler = (
  courseId,
  unitId,
  selectedCourse,
  isChecked,
  unitsData,
  selctedUnitFromCourseIds
) => {
  let UpdatedUnits = [];
  let CourseUnitIds = [];
  if (isChecked) {
    const courseIndex = selectedCourse.findIndex(
      (course) => course.courseId === courseId
    );
    if (courseIndex !== -1) {
      const unitExists = selectedCourse[courseIndex].units.some(
        (unit) => unit.unitid === unitId
      );
      if (!unitExists) {
        const UpdatedUnits = [...selectedCourse];
        UpdatedUnits[courseIndex].units.push({
          unitid: unitId,
          unitTitle: unitsData.unitTitle,
          unitDetails: unitsData.unitDetails,
          isunitSelected: selctedUnitFromCourseIds.includes(unitId)
            ? false
            : true,
          status: unitsData.status,
          duration: unitsData.duration,
          endtime: unitsData.endtime ? unitsData.endtime : "N/A",
          starttime: unitsData.starttime ? unitsData.starttime : "N/A",
          trainer: unitsData.trainer ? unitsData.trainer : "N/A",
          date: unitsData.date ? unitsData.date : "N/A",
        });
        return UpdatedUnits;
      } else {
        UpdatedUnits = selectedCourse.map((selectedCourseData) => {
          if (selectedCourseData.courseId === courseId) {
            return {
              courseId: selectedCourseData.courseId,
              courseName: selectedCourseData.courseName,
              duration: selectedCourseData.duration,
              status: selectedCourseData.status,
              units: selectedCourseData.units.map((unitData) => {
                if (unitData.unitid === unitId) {
                  return {
                    unitid: unitData.unitid,
                    endtime: unitData.endtime ? unitData.endtime : "N/A",
                    starttime: unitData.starttime ? unitData.starttime : "N/A",
                    date: unitData.date ? unitData.date : "N/A",
                    trainer: unitData.trainer ? unitData.trainer : "N/A",
                    status: unitData.status,
                    duration: unitsData.duration,
                    unitTitle: unitData.unitTitle,
                    unitDetails: unitData.unitDetails,
                    isunitSelected: selctedUnitFromCourseIds.includes(
                      unitData.unitid
                    )
                      ? false
                      : true,
                  };
                } else return unitData;
              }),
            };
          } else return selectedCourseData;
        });
      }
    }
  } else {
    UpdatedUnits = selectedCourse.map((selectedCourseData) => {
      if (selectedCourseData.courseId === courseId) {
        return {
          courseId: selectedCourseData.courseId,
          courseName: selectedCourseData.courseName,
          duration: selectedCourseData.duration,
          status: selectedCourseData.status,
          units: selectedCourseData.units.map((unitData) => {
            if (unitData.unitid === unitId) {
              return {
                unitid: unitData.unitid,
                unitTitle: unitData.unitTitle,
                unitDetails: unitData.unitDetails,
                isunitSelected: false,
                duration: unitData.duration,
                endtime: unitData.endtime ? unitData.endtime : "N/A",
                starttime: unitData.starttime ? unitData.starttime : "N/A",
                date: unitData.date ? unitData.date : "N/A",
                trainer: unitData.trainer ? unitData.trainer : "N/A",
                status: unitData.status,
              };
            } else return unitData;
          }),
        };
      } else return selectedCourseData;
    });
  }
  return UpdatedUnits;
};

export const unitCheckBoxHandler = (
  unitId,
  selectedCourse,
  isChecked,
  selctedUnitFromCourseIds
) => {
  let UpdatedUnits = [];
  let isUnitexist = true;
  let selectedUnitIdArray = [];
  function countOccurrencesOfNumber(number, array) {
    let count = 0;
    array.forEach((element) => {
      if (element === number) {
        count++;
      }
    });
    if (count > 1) {
      return false;
    } else return true;
  }
  if (isChecked) {
    UpdatedUnits = selectedCourse.map((selectedCourseData) => {
      return {
        courseId: selectedCourseData.courseId,
        courseName: selectedCourseData.courseName,
        duration: selectedCourseData.duration,
        status: selectedCourseData.status,
        units: selectedCourseData.units.map((unitData) => {
          if (unitData.unitid === unitId) {
            selectedUnitIdArray.push(unitId);

            isUnitexist = false;
            return {
              unitid: unitData.unitid,
              unitTitle: unitData.unitTitle,
              unitDetails: unitData.unitDetails,
              isunitSelected: countOccurrencesOfNumber(
                unitData.unitid,
                selectedUnitIdArray
              ),
              status: unitData.status,
              duration: unitData.duration,
              endtime: unitData.endtime ? unitData.endtime : "N/A",
              starttime: unitData.starttime ? unitData.starttime : "N/A",
              trainer: unitData.trainer ? unitData.trainer : "N/A",
              date: unitData.date ? unitData.date : "N/A",
            };
          } else return unitData;
        }),
      };
    });
  } else {
    UpdatedUnits = selectedCourse.map((selectedCourseData) => {
      return {
        courseId: selectedCourseData.courseId,
        courseName: selectedCourseData.courseName,
        duration: selectedCourseData.duration,
        status: selectedCourseData.status,
        units: selectedCourseData.units.map((unitData) => {
          if (unitData.unitid === unitId) {
            isUnitexist = false;
            return {
              unitid: unitData.unitid,
              unitTitle: unitData.unitTitle,
              unitDetails: unitData.unitDetails,
              isunitSelected: false,
              duration: unitData.duration,
              endtime: unitData.endtime ? unitData.endtime : "N/A",
              starttime: unitData.starttime ? unitData.starttime : "N/A",
              trainer: unitData.trainer ? unitData.trainer : "N/A",
              date: unitData.date ? unitData.date : "N/A",
              status: unitData.status,
            };
          } else return unitData;
        }),
      };
    });
  }
  return { UpdatedUnits, isUnitexist };
};

export const CheckBoxDisableHandler = (
  courseId,
  unitId,
  selectedCourse,
  selectedCoursewithUnits
) => {
  if (
    !selectedCourse.find(
      (IndSelectedCourse) => IndSelectedCourse?.courseId === courseId
    )
  ) {
    return true;
  } else {
    let selectedData = selectedCoursewithUnits.filter(
      (courseData) => courseData.courseId !== courseId
    );
    if (selectedData.length > 0) {
      let isSelected = false;
      selectedData.forEach((IndData) => {
        if (IndData.units.includes(unitId)) {
          isSelected = true;
        }
      });
      return isSelected;
    } else return false;
  }
};
