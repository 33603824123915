import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import { DialogContentText } from "@mui/material";
import "./user-unit-details.css";
import TrainingMaterialList from "./user-training-material-list";
import MaterialView from "./user-training-materialview";
import PdfContentExtractor from "./user-training-materialview";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Networking/API";
import MandateDocViewver from "./MandatoryTraningLayout/mandatoryDocViewver";
// import MandatoryQuizQusetions from "./MandatoryTraningLayout/MandatoryTraningQuiz/mandatory-quiz-question";
import MandatoryUnitQuizQusetions from "./user-training-video-quiz";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CompleteUnit from "../../../assets/Images/completeUnit.png";
import FailedUnit from "../../../assets/Images/quizfail.png";

export const UserTrainingMaterial = (props) => {
  const [selected, setSelected] = React.useState(0);
  const {
    trainingMaterial,
    unitId,
    courseId,
    getUnitDetails,
    unitdata,
    handleTabChange,
  } = props;
  let [videoTotalTime, setVideoTotalTime] = useState(0);
  const [selectedTrainingMaterial, setSelecetedTrainingMaterial] = useState([]);
  let [currentVideoTime, setCurrentVideoTime] = useState(0);
  let [isVideoQuizEnabled, setIsVideoQuizEnabled] = useState(false);
  let [makeIt, setMakeIt] = useState(false);
  let [isYoutubeVideo, setIfYoutubeVideo] = useState(false);
  let [isVideowithQuiz, setIsVideoWithQuiz] = useState(false);
  const [sucesspopup, setSucesspopup] = useState();
  const [showCompletePopUp, setShowCompletePopup] = useState(false);
  const [totalAttempts, setTotalAttempts] = useState();
  const [requiredPercentage, setRequiredPercentage] = useState();
  const [calculatedPercentage, setCalculatedPercentage] = useState();
  const [NoOfQuestions, setNoOfQuestions] = useState();
  const [openModal, setOpenModal] = useState(false);
  // const [showCompletePopUp, setShowCompletePopup] = useState(false);

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleCloseCompletePopup = () => {
    setShowCompletePopup(false);
    navigate(`/user/`);
    // ClearLocalStorage();
  };
  const handleCompleteUnit = () => {
    setShowCompletePopup(false);
    navigate(`/user/`);
    // ClearLocalStorage();
  };

  // let [isVideoQuizEnabled, setIsVideoQuizEnabled] = useState(false);

  const selectedObject = trainingMaterial.find(
    (item, index) => index === selected
  );
  let navigate = useNavigate();
  const checkBoxData = trainingMaterial?.map((item, index) => {
    return {
      id: index,
      name: item.material_name,
      duration: item.duration,
      pk: item.material_pk,
      isCompleted: item.isCompleted,
    };
  });

  const postTrainingMaterialTrack = () => {
    
    API.TrainingMaterialTrack(unitId, courseId).then(({ response }) => {
      if (response.api_status === 200) {
      }
    });
  };

  const idCourse = localStorage.getItem("courseId");
  const idUnit = localStorage.getItem("UnitId");

  const handleCancelButton = () => {
    if (idCourse) {
      navigate("/user/courses");
    } else if (idUnit) {
      navigate("/user/courses/units");
    } else {
      navigate("/user/");
    }
  };

  const links = trainingMaterial.find((item, index) => index === selected);
  useEffect(() => {
    setCurrentVideoTime(0);
    setVideoTotalTime(0);
    setSelecetedTrainingMaterial(
      trainingMaterial.find((item, index) => index === selected)
    );

    setVideoTotalTime(0);
    setCurrentVideoTime(0);
    if (selectedTrainingMaterial.trainigMaterialQuiz !== null) {
      setIsVideoWithQuiz(true);
      setIsVideoQuizEnabled(false);
    } else {
      setIsVideoWithQuiz(false);
      setIsVideoQuizEnabled(false);
    }
  }, [selected, selectedTrainingMaterial]);

  const UNITID = window.localStorage.getItem("UnitId");
  const handleFinalClick = async (event) => {
    const NoFinalQuiztrack = await API.TrainingMaterialTrack(
      parseInt(props.unitId),
      null
      // UserUnitTrackId
    );
    
    if (NoFinalQuiztrack.response.api_status === 400) {
      setSucesspopup("");
      setSucesspopup(0);
      setShowCompletePopup(true);
      setTotalAttempts(NoFinalQuiztrack.response.data.percentage);
      // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
      setRequiredPercentage(NoFinalQuiztrack.response.data.required_percentage);
    } else if (NoFinalQuiztrack.response.api_status === 200) {
      if (
        NoFinalQuiztrack.response.data.pass_percent >
        NoFinalQuiztrack.response.data.percentage
      ) {
        setNoOfQuestions(NoFinalQuiztrack.response.data.total_qns);
        setSucesspopup(0);
        setShowCompletePopup(true);
        setRequiredPercentage(NoFinalQuiztrack.response.data.pass_percent);
        setTotalAttempts(NoFinalQuiztrack.response.data.percentage);
      } else {
        setSucesspopup("");
        setSucesspopup(1);
        setShowCompletePopup(true);
        setTotalAttempts(NoFinalQuiztrack.response.data.percentage);
        // setAchievedPercentage(NoFinalQuiztrack.response.data.data[0].percentage);
        setRequiredPercentage(NoFinalQuiztrack.response.data.pass_percent);
        setCalculatedPercentage(
          NoFinalQuiztrack.response.data.required_percentage
        );
      }
    }
  };

  const checkIfFinalSubmission = () => {
    const isLast = selected !== -1 && selected === trainingMaterial.length - 1;
    if (
      isLast &&
      unitdata.topicAssignments?.length === 0 &&
      unitdata.topicQuizz.length === 0
    )
      return false;
    else return true;
  };

  return (
    <Box sx={{ height: "70vh" }}>
      <Grid
        container
        spacing={1}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item xs={3}>
          <TrainingMaterialList
            getUnitDetails={getUnitDetails}
            list={checkBoxData}
            selected={selected}
            setSelected={setSelected}
            unitId={unitId}
            courseId={courseId}
            trainingMaterial={trainingMaterial}
            videoTotalTime={videoTotalTime}
            currentVideoTime={currentVideoTime}
            setIsVideoQuizEnabled={setIsVideoQuizEnabled}
            isVideoQuizEnabled={isVideoQuizEnabled}
            setCurrentVideoTime={setCurrentVideoTime}
            setVideoTotalTime={setVideoTotalTime}
            isYoutubeVideo={isYoutubeVideo}
            selectedTrainingMaterial={selectedTrainingMaterial}
          />
        </Grid>
        <div style={{ borderLeft: "1px solid #F0F0F0" }}></div>
        <Grid item xs={8}>
          {isVideoQuizEnabled ? (
            <MandatoryUnitQuizQusetions
              selected={selected}
              getUnitDetails={getUnitDetails}
              trainingMaterial={trainingMaterial}
              setMakeIt={setMakeIt}
              unitdata={unitdata}
              setSelected={setSelected}
              list={checkBoxData}
              courseId={courseId}
              setIsVideoQuizEnabled={setIsVideoQuizEnabled}
              handleTabChange={handleTabChange}
            />
          ) : !isVideowithQuiz ? (
            <PdfContentExtractor
              link={links}
              setSelected={setSelected}
              setVideoTotalTime={setVideoTotalTime}
              setCurrentVideoTime={setCurrentVideoTime}
              setIfYoutubeVideo={setIfYoutubeVideo}
            />
          ) : (
            <>
              <MandateDocViewver
                setCurrentVideoTime={setCurrentVideoTime}
                setVideoTotalTime={setVideoTotalTime}
                // MandatoryTraining={mandatetrainingData}
                selected={selected}
                selectedTrainingModule={selectedTrainingMaterial}
              />
            </>
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
          item
          xs={9}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              borderRadius: "4px",
              height: "35px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={handleCancelButton}
          />
          <Box
            sx={{ display: "flex", float: "right", marginRight: "10%" }}
            className="navigation_button-unit"
          >
            {selected !== 0 && !isVideowithQuiz ? (
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Back"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "white",
                  height: "35px",
                  backgroundColor: "#02215B",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={() => {
                  setSelected(selected - 1);
                  postTrainingMaterialTrack();
                }}
              />
            ) : null}
            {trainingMaterial?.length > 0 &&
            !isVideowithQuiz &&
            checkIfFinalSubmission() ? (
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Next"
                disabled={!selectedObject.isCompleted}
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  height: "35px",
                  letterSpacing: "normal",
                  textAalign: "center",
                  backgroundColor: "#FB2485",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                  border: "none",
                }}
                onClick={() => {
                  if (trainingMaterial?.length > selected + 1) {
                    setSelected(selected + 1);
                    // postTrainingMaterialTrack();
                  } else if (unitdata?.topicQuizz.length > 0) {
                    handleTabChange("", 3);
                  } else if (unitdata?.topicAssignments.length > 0) {
                    handleTabChange("", 2);
                  }
                }}
              />
            ) : null}
            {!checkIfFinalSubmission() && !isVideowithQuiz && (
              <>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName="Finish"
                  // disabled={!selectedObject.isCompleted}
                  style={{
                    fontSize: "16px",
                    color: "white",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    height: "35px",
                    letterSpacing: "normal",
                    textAalign: "center",
                    backgroundColor: "#FB2485",
                    borderRadius: "4px",
                    marginRight: "8px",
                    textTransform: "none",
                    border: "none",
                  }}
                  onClick={(e) => {
                    handleFinalClick();
                  }}
                />
              </>
            )}
            {sucesspopup == 1 ? (
              <Dialog
                open={showCompletePopUp}
                className="dialogBox"
                keepMounted
                onClose={handleCloseByCancelModal}
                aria-describedby="alert-dialog-slide-description"
                BackdropProps={{
                  style: {
                    backgroundColor: "black",
                    opacity: "0.5",
                    boxShadow: "0px 0px 0px 0px !important",
                  },
                }}
              >
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-slide-description"
                    style={{
                      color: "#000",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "24px",
                        lineHeight: "29px",
                        color: "#000000",
                      }}
                    >
                      You have passed Congratulations!
                    </b>
                    <img
                      src={CompleteUnit}
                      alt="congratsimage"
                      height="240"
                      // width="220"
                    />
                    {/* <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "29px",
                        color: "#000000",
                      }}
                    >
                      {`Percentage Scored:  ${
                        totalAttempts ? totalAttempts : "100%"
                      }`}
                    </p> */}
                  </DialogContentText>
                </DialogContent>

                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "30px",
                  }}
                >
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="candidateDltepageCancelBttn"
                    buttonName="Close"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#000936",
                      border: "1px solid #000936",
                      padding: "7px 20px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      textTransform: "none",
                    }}
                    onClick={handleCloseCompletePopup}
                  />
                  <ReusableButton
                    size="large"
                    variant="contained"
                    className="candidateDltepageDeleteBttn"
                    buttonName="Continue"
                    style={{
                      backgroundColor: "#fb2485",
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#fff",
                      padding: "8px 28px",
                      borderRadius: "4px",
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      handleCompleteUnit();
                    }}
                  >
                    Submit
                  </ReusableButton>
                </DialogActions>
              </Dialog>
            ) : (
              <Dialog
                open={showCompletePopUp}
                className="dialogBox"
                keepMounted
                onClose={handleCloseByCancelModal}
                aria-describedby="alert-dialog-slide-description"
                BackdropProps={{
                  style: {
                    backgroundColor: "black",
                    opacity: "0.5",
                    boxShadow: "0px 0px 0px 0px !important",
                  },
                }}
              >
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-slide-description"
                    style={{
                      color: "#000",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      // paddingBottom: "40px",
                    }}
                  >
                    <b
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "24px",
                        lineHeight: "29px",
                        color: "#000000",
                      }}
                    >
                      You have Failed !
                    </b>
                    <img
                      src={FailedUnit}
                      alt="congrats=image"
                      height="240"
                      width="400"
                    />
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "2px",
                        color: "#000000",
                      }}
                    >
                      {/* {`You have successfully completed the ${topicName}`} */}
                      Better luck next time
                    </p>
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "29px",
                        color: "#000000",
                      }}
                    >
                      {`Required percentage: ${requiredPercentage}%`}
                    </p>
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "2px",
                        color: "#000000",
                      }}
                    >
                      {`Percentage Scoreds:  ${totalAttempts}%`}
                    </p>
                  </DialogContentText>
                </DialogContent>

                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "30px",
                  }}
                >
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="candidateDltepageCancelBttn"
                    buttonName="Close"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#000936",
                      border: "1px solid #000936",
                      padding: "7px 20px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      textTransform: "none",
                    }}
                    onClick={handleCloseCompletePopup}
                  />
                  <ReusableButton
                    size="large"
                    variant="contained"
                    className="candidateDltepageDeleteBttn"
                    buttonName="Continue"
                    style={{
                      backgroundColor: "#fb2485",
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#fff",
                      padding: "8px 28px",
                      borderRadius: "4px",
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      handleCompleteUnit();
                    }}
                  >
                    Submit
                  </ReusableButton>
                </DialogActions>
              </Dialog>
            )}

            {/* </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
