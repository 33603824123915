import React from "react";
import { Box } from "@mui/material";
import "./trainer-sessions-details-assessed.css";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import { TrainerSessionsDetailsAssignment } from "./trainer-sessions-details-assignment";
import { TrainerSessionsDetailsQuiz } from "./trainer-sessions-details-quiz";
import { SearchBar } from "./search-bar";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { TrainerSessionsDetailsHackathon } from "./trainer-session-details-hackathon";
import TrainerPreRequisite from "./trainer-pre-requisite";
import TrainerTrainingMaterial from "./trainer-training-materials";

export const TraineeDetails = ({
  scheduleID,
  assignments,
  training_materials,
  scheduleType,
  totalTasks,
  quiz,
  prerequisite,
  scheduleData,
}) => {
  const [value, setValue] = useState("1");
  const [mainValue, setMainValue] = useState("1");
  const [trainerName, setTrainerName] = useState("");
  const [trainerRole, setTrainerRole] = useState("");
  const [trainerID, setTrainerID] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgQ, setErrorMsgQ] = useState("");
  const [assignmentData, setAssignmentData] = useState([]);
  const [taskData, setTaskData] = useState();
  const [assingmentDone, setAssingmentDone] = useState(false);
  const [quizData, setQuizData] = useState();
  const [quizDone, setQuizDone] = useState(null);
  const [status, setStatus] = useState(false);
  const [ErrorMsgAssignment, setErrorMsgAssignment] = useState("");

  const [links, setLinks] = useState(prerequisite[0]);
  const [trainingMaterialLinks, setTrainingMaterialLinks] = useState(
    training_materials[0]
  );
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [VideoTotalTime, setVideoTotalTime] = useState(0);

  const [index, setIndex] = useState(0);
  const [taskindex, setTaskIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeMain = (event, newValue) => {
    setMainValue(newValue);
  };

  const handleTrainingClick = (id) => {
    const selectedTrainingMaterial = training_materials.find(
      (item) => item.material_pk === id
    );
    setTrainingMaterialLinks(selectedTrainingMaterial);
  };

  const handlePreClick = (id) => {
    let selectedPreq = prerequisite.find((item) => item.pre_req_pk === id);
    setLinks(selectedPreq);
  };

  useEffect(() => {
    if (quiz.length !== 0 && trainerID !== undefined) {
      getTrainerQuizData();
    }

    if (assignments.length !== 0 && trainerID !== undefined) {
      getTrainerAssignmentData();
    }
    if (totalTasks.length !== 0 && totalTasks !== undefined) {
      getTrainerTaskData();
    }
  }, [trainerID]);

  useEffect(() => {
    if (totalTasks.length !== 0 && totalTasks !== undefined) {
      getTrainerTaskData();
    }
  }, [index]);

  const handleNext = () => {
    setIndex(index + 1);
    setTaskIndex(index + 1);
  };

  const handlePrev = () => {
    setIndex(index - 1);
    setTaskIndex(index - 1);
  };

  const getTrainerAssignmentData = async () => {
    setAssingmentDone(false);
    if (assignments.length !== 0) {
      const assignment_pkk = assignments[0]?.asn_pk;
      API.getTrainerAssignmentDataFunc(
        trainerID,
        // scheduleID,
        assignment_pkk
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setAssignmentData(response.data[0]);
          setErrorMsgAssignment(response.api_status);
          setAssingmentDone(true);
        } else {
          setAssingmentDone(true);
          setErrorMsg(response.error[0].error_msg);
          setErrorMsgAssignment(response.api_status);
        }
      });
    }
  };

  const Value = trainerID;
  let toRender;

  if (assignments.length === 0) {
    toRender = (
      <Box
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="error-msg">
          No Assignments Available For This Session
        </span>
      </Box>
    );
  } else if (assignments.length !== 0 && ErrorMsgAssignment === 200) {
    toRender = (
      <>
        <TrainerSessionsDetailsAssignment
          assignmentData={assignmentData}
          assignments={assignments}
        />
      </>
    );
  } else if (assignments.length !== 0 && ErrorMsgAssignment !== 200) {
    toRender = (
      <>
        <TrainerSessionsDetailsAssignment assignments={assignments} />

        {ErrorMsgAssignment === 404 ? (
          <Box
            style={{
              height: "45vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="error-msg">User Assignment not found</span>
          </Box>
        ) : null}
      </>
    );
  }

  const getTrainerTaskData = async () => {
    setAssingmentDone(false);
    if (totalTasks.length !== 0 && totalTasks !== undefined) {
      const task_pkk = totalTasks[index].task_pk;

      API.getTrainerTaskDataFunc(trainerID, scheduleID, task_pkk).then(
        ({ response }) => {
          if (response.api_status === 200) {
            setTaskData(response.data[0]);
            setAssingmentDone(true);
            setErrorMsg("");
          } else {
            setAssingmentDone(true);
            setErrorMsg(response.error[0].error_msg);
          }
        }
      );
    }
  };

  const ValueOfTask = trainerID;
  let toRendertask;

  if (totalTasks.length === 0) {
    toRendertask = (
      <Box
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="error-msg">No Tasks Available For This Session</span>
      </Box>
    );
  } else if (ValueOfTask !== 0 && errorMsg.length !== 0) {
    toRendertask = (
      <>
        {!assingmentDone ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={50}
              width={50}
            />
          </Box>
        ) : (
          <>
            <TrainerSessionsDetailsHackathon
              totalTasks={totalTasks}
              index={index}
            />
            <Box
              style={{
                height: "45vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="error-msg">
                {status
                  ? `${trainerName} has not Submitted the Assignment`
                  : null}
              </span>
            </Box>
          </>
        )}
      </>
    );
  } else if (ValueOfTask !== 0 && errorMsg.length === 0) {
    toRendertask = (
      <>
        {!assingmentDone ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={50}
              width={50}
            />
          </Box>
        ) : (
          <>
            <TrainerSessionsDetailsHackathon
              taskData={taskData}
              totalTasks={totalTasks}
              index={index}
            />
          </>
        )}
      </>
    );
  }

  const getTrainerQuizData = async () => {
    const quizId = quiz[0]?.quizz_pk;
    setQuizDone(false);
    API.getTrainerQuizDataFunc(trainerID, quizId).then(({ response }) => {
      if (response.api_status === 200) {
        setQuizData(response.data);
        setQuizDone(true);
        setErrorMsgQ(response.api_status);
      } else {
        setQuizDone(true);
        setErrorMsgQ(response.api_status);
      }
    });
  };

  const ValueOfQuiz = trainerID;
  let toRenderForQuiz;

  if (quiz.length === 0) {
    toRenderForQuiz = (
      <Box
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="error-msg">No Quiz Available For This Session</span>
      </Box>
    );
  } else if (quiz.length !== 0 && errorMsgQ === 200) {
    toRenderForQuiz = (
      <>
        {quizDone === false ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={50}
              width={50}
            />
          </Box>
        ) : (
          <Box>
            <TrainerSessionsDetailsQuiz quizData={quizData} quiz={quiz} />
          </Box>
        )}
      </>
    );
  } else if (quiz.length !== 0 && errorMsgQ !== 200) {
    toRenderForQuiz = (
      <>
        {quizDone === false ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={50}
              width={50}
            />
          </Box>
        ) : (
          <>
            <TrainerSessionsDetailsQuiz quiz={quiz} />

            {errorMsgQ === 400 ? (
              <Box
                style={{
                  height: "45vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="error-msg">User Quiz not found</span>
              </Box>
            ) : null}
          </>
        )}
      </>
    );
  }

  const setClickStatus = (status) => {
    setStatus(status);
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1", background: "#fff" }}>
        <TabContext value={mainValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeMain}
              aria-label="lab API tabs example"
            >
              <Tab label="Participants" value="1" />
              <Tab label="Pre-Requisite" value="2" />
              <Tab label="Training Materials" value="3" />
            </TabList>
          </Box>

          {/* //Participants */}
          <TabPanel value="1">
            {totalTasks.length !== 0 ? (
              <>
                <Box style={{ background: "#fff" }}>
                  <div
                    style={{
                      background: "#fff",

                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="participant-tab-text"
                      style={{
                        paddingBottom: "0px",
                        margin: "20px",
                        paddingLeft: "40px",
                      }}
                    >
                      {`Task ${index + 1}`}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "40px",
                      }}
                    >
                      <Button
                        style={{
                          width: "25 !important",
                          minWidth: 10,

                          height: 25,
                          // backgroundColor:  '#fb2485',
                          backgroundColor: index === 0 ? "#a9a9a9" : "#fb2485",
                        }}
                        disabled={index === 0 ? true : false}
                        onClick={() => {
                          // if (index > 0) {
                          //   setIndex(index - 1);
                          // }
                          handlePrev();
                        }}
                      >
                        <IoIosArrowBack color="#ffffff"></IoIosArrowBack>
                      </Button>
                      <p className={"num"}>{`${index + 1}/${
                        totalTasks.length
                      }`}</p>
                      <Button
                        style={{
                          width: "25 !important",
                          minWidth: 10,

                          height: 25,
                          backgroundColor:
                            index === totalTasks.length - 1
                              ? "#a9a9a9"
                              : "#fb2485",
                        }}
                        disabled={
                          index === totalTasks.length - 1 ? true : false
                        }
                        onClick={() => {
                          handleNext();
                        }}
                      >
                        <IoIosArrowForward color="#ffffff"></IoIosArrowForward>{" "}
                      </Button>
                    </div>
                  </div>
                </Box>
                <Divider className="division" />
              </>
            ) : null}

            <Box style={{ background: "#fff", paddingTop: "16px" }}>
              <Grid container spacing={2} style={{ paddingTop: "16px" }}>
                <Grid item xs={3}>
                  <SearchBar
                    setTrainerName={setTrainerName}
                    setTrainerRole={setTrainerRole}
                    setTrainerID={setTrainerID}
                    clickStatus={setClickStatus}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    {/* here we need to keep the condition for hackathon tasks & schdle assignment & quizz  */}
                    {scheduleType !== 1 ? (
                      <TabContext value={value}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Box>
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Assignments" value="1" />
                                <Tab label="Quiz" value="2" />
                              </TabList>
                            </Box>
                          </Grid>

                          <Grid item xs={8}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "flex-end",
                                flexDirection: "column",
                                marginRight: "17px",
                              }}
                            >
                              <span className="trainer-name">
                                {trainerName}
                              </span>
                              <span className="trainer-bio">{trainerRole}</span>
                            </Box>
                          </Grid>
                        </Grid>

                        <TabPanel value="1">{toRender}</TabPanel>

                        <TabPanel value="2">{toRenderForQuiz}</TabPanel>
                      </TabContext>
                    ) : (
                      <TabContext value={value}>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Hackathon" value="1" />
                              </TabList>
                            </Box>
                          </Grid>

                          <Grid item xs={10}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "flex-end",
                                flexDirection: "column",
                                marginRight: "17px",
                              }}
                            >
                              <span className="trainer-name">
                                {trainerName}
                              </span>
                              <span className="trainer-bio">{trainerRole}</span>
                            </Box>
                          </Grid>
                        </Grid>

                        <TabPanel value="1">{toRendertask}</TabPanel>
                      </TabContext>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          {/* //Pre-requisite */}
          <TabPanel value="2">
            {scheduleData &&
            scheduleData.preq_descp != "" &&
            scheduleData.preq_descp != null ? (
              <>
                <span>
                  <b>Description:</b>
                </span>
                <p
                  className="description-text"
                  dangerouslySetInnerHTML={{
                    __html: scheduleData.preq_descp,
                  }}
                />
              </>
            ) : null}
            {prerequisite && prerequisite.length > 0 ? (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {prerequisite.map((item) => {
                    return (
                      <Button
                        className="preReq-button"
                        onClick={() => handlePreClick(item.pre_req_pk)}
                        style={{
                          backgroundColor:
                            links.pre_req_pk === item.pre_req_pk
                              ? "rgb(0, 9, 54)"
                              : "white",
                          color:
                            links.pre_req_pk === item.pre_req_pk
                              ? "white"
                              : "#7f7d7d",
                          margin: "18px 0px 0px 18px",
                        }}
                      >
                        {item.module_name}
                      </Button>
                    );
                  })}
                </Grid>

                <Grid item xs={10}>
                  <TrainerPreRequisite link={links} />
                </Grid>
              </Grid>
            ) : (
              <>
                {scheduleData &&
                scheduleData.preq_descp != "" &&
                scheduleData.preq_descp != null ? null : (
                  <p style={{ backgroundColor: "white" }}>No Data Found</p>
                )}
              </>
            )}
          </TabPanel>

          {/* //training-materials */}
          <TabPanel value="3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {training_materials.map((item) => {
                  return (
                    <Button
                      className="preReq-button"
                      style={{
                        backgroundColor:
                          trainingMaterialLinks.material_pk === item.material_pk
                            ? "rgb(0, 9, 54)"
                            : "white",
                        color:
                          trainingMaterialLinks.material_pk === item.material_pk
                            ? "white"
                            : "#666666",
                        margin: "18px 0px 0px 18px",
                      }}
                      onClick={() => handleTrainingClick(item.material_pk)}
                    >
                      {item.material_name}
                    </Button>
                  );
                })}
              </Grid>
              <Grid item xs={10}>
                <TrainerTrainingMaterial
                  selectedTrainingModule={trainingMaterialLinks}
                  setVideoTotalTime={setVideoTotalTime}
                  setCurrentVideoTime={setCurrentVideoTime}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
