import { React, useEffect, useState } from "react";
import "./trainer-sessions-details-assessed.css";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import List from "./List";
import { API } from "../../../Networking/API";

import Cookies from "js-cookie";

export const SearchBar = ({
  setTrainerRole,
  setTrainerName,
  setTrainerID,
  clickStatus,
}) => {
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [entryType, setEntryType] = useState("");

  useEffect(() => {
    if (entryType) {
      testUsers();
    }
  }, [entryType]);

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };

    const entryTypeCookie = getCookieValue("user_name");
    setEntryType(entryTypeCookie);
  }, []);
  const testUsers = async (id) => {
    API.getTrainerUsersById(search).then(({ response }) => {
      if (response.data.api_status === 200) {
        const users = response.data.data[0].trainee;
        const filteredOne = users.filter(
          (items) => items.user_name !== entryType
        );
        setContacts(filteredOne);
      }
    });
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    testUsers();
  };
  const onclickstatus = (status) => {
    clickStatus(status);
  };

  return (
    <>
      <div>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            onChange={handleSearchChange}
            value={search}
            autoFocus
            style={{ border: "1px solid #02215b", width: "100%" }}
          />
        </Search>
        <List
          contacts={contacts}
          trainerName={setTrainerName}
          trainerRole={setTrainerRole}
          trainerID={setTrainerID}
          clickStatus={onclickstatus}
        />
      </div>
    </>
  );
};
