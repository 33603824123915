import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import CircularProgressWithLabel from "./circular-progress-with-label";
import { useLocation } from "react-router-dom";
import "./quiz.css";
function Header(props) {
  const { show, subcatnamedisplay, start, callback2 } = props;
  const location = useLocation();

  //************getting the duration from api********//

  const timelimit = localStorage.getItem("countdownfromapi");
  const [timeForTest, setTimeForTest] = useState(timelimit);

  const [MinSecs, setMinSecs] = useState();
  useEffect(() => {
    if (localStorage.getItem("minsecs")) {
      setMinSecs({
        minutes: JSON.parse(localStorage.getItem("minsecs")).Minutes,
        seconds: JSON.parse(localStorage.getItem("minsecs")).Seconds,
      });
    } else {
      setMinSecs({
        minutes: `${timeForTest}`,
        seconds: 0,
      });
    }
  }, []);

  const setFinVal = (data) => {
    callback2(data);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-50px",
        }}
      >
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-50px",
          }}
        >
          Quiz
        </h4>
      </Grid>
      <Grid
        className="card-img"
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          minHeight: "95px",
        }}
      >
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
          }}
        >
          {subcatnamedisplay}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            color: "orange",
          }}
        >
          {show && MinSecs ? (
            <CircularProgressWithLabel
              MinSecs={MinSecs}
              start={start}
              value={100}
              color="inherit"
              callback={setFinVal}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
