import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function ReusableDropdown(props) {
  const {
    name,
    label,
    value,
    size,
    error = null,
    onChange,
    Allvalue,
    style,
    placeholder,
    options,
    menustyle,
    displayEmpty,
    disabled,
    helperText,
    className,
    labelClasses,
  } = props;

  return (
    <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
      <InputLabel className={labelClasses}>{label} </InputLabel>
      <Select
        name={name}
        displayEmpty={displayEmpty}
        value={value}
        blurOnSelect
        size={size}
        onChange={onChange}
        style={style}
        className={className}
        disabled={disabled}
        // displayEmpty
        renderValue={
          value !== ""
            ? undefined
            : () => (
                <div style={{ color: "grey", opacity: "0.5" }}>
                  {placeholder}
                </div>
              )
        }
      >
        {Allvalue ? (
          <MenuItem value="ALL" style={menustyle}>
            All
          </MenuItem>
        ) : null}

        {options?.length > 0 ? (
          options.map((item) => (
            <MenuItem key={item.key} value={item.value}>
              {item.title}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" style={menustyle}>
            None
          </MenuItem>
        )}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {/* {helperTe} */}
    </FormControl>
  );
}
