import { React, useState } from "react";
import Button from "@mui/material/Button";
import "./mentor-sessions-details.css";

export default function List({
  contacts,
  trainerName,
  trainerRole,
  trainerID,
  clickStatus,
}) {
  const [innerText, setInnerText] = useState("");
 

  const handleClick = (event) => {
    
    // trainerName(event.target.innerText);
    // trainerRole(event.target.nextSibling.innerText);
    // trainerID(event.target.nextSibling.nextSibling.innerText);
    setInnerText(event.trainee.user_name);
    trainerName(event.trainee.user_name);
    trainerID(event.trainee.user_pk);
    clickStatus(true);
  };

  return (
    <div
      style={{
        padding: "12px 12px 12px 12px",
        overflowY: "scroll",
        height: "800px",
      }}
    >
      {contacts === null ? (
        "No Trainees Found"
      ) : (
        <>
          {contacts?.map((contact, index) => (
            <>
              <Button
                key={index + 1}
                id={index + 1}
                onClick={() => handleClick(contact)}
                className={
                  innerText?.toLowerCase() ===
                  contact.trainee.user_name?.toLowerCase()
                    ? "selected"
                    : "NotSelected"
                }
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#000",
                  textTransform: "capitalize",
                  padding: "12px 14px",
                  marginTop: "4px",
                }}
              >
                {contact.trainee.user_name}
              </Button>
              <span style={{ display: "none" }}>{contact.role_name}</span>
              <span style={{ display: "none" }}>{contact.user_pk}</span>
            </>
          ))}
        </>
      )}
    </div>
  );
}
